import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"summary\" data-test-order-list-total-price>\n  {{#if this.totalPrice.value}}\n    {{t\n      \"orderList.text.totalPrice\"\n      currency=this.totalPrice.currency\n      value=(format-number this.totalPrice.value format=\"price\")\n    }}\n  {{else}}\n    {{t \"orderList.text.totalPrice\" currency=this.totalPrice.currency value=(t \"layoutPriceBlock.text.priceEmpty\")}}\n  {{/if}}\n</div>", {"contents":"<div local-class=\"summary\" data-test-order-list-total-price>\n  {{#if this.totalPrice.value}}\n    {{t\n      \"orderList.text.totalPrice\"\n      currency=this.totalPrice.currency\n      value=(format-number this.totalPrice.value format=\"price\")\n    }}\n  {{else}}\n    {{t \"orderList.text.totalPrice\" currency=this.totalPrice.currency value=(t \"layoutPriceBlock.text.priceEmpty\")}}\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/order-list/summary.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/order-list/summary.hbs"}});
import { CURRENCIES_DACH } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class OrderListSummaryComponent extends Component {
  @service intl;
  @service user;

  get totalPrice() {
    let selectedCurrencyCountry = this.user.selectedCurrencyCountry || CURRENCIES_DACH.EUR_DE;

    return {
      value: this.args.openOrderList?.totalPrice,
      currency: this.intl.t(`modelProduct.text.currencyCountry.${selectedCurrencyCountry}`),
    };
  }
}
