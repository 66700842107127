export default {
  "button": "_button_o8stay",
  "truncated": "_truncated_o8stay one-line-truncated",
  "caption": "_caption_o8stay",
  "copyright": "_copyright_o8stay",
  "carousel": "_carousel_o8stay",
  "modal": "_modal_o8stay",
  "slide": "_slide_o8stay",
  "no-scaling": "_no-scaling_o8stay",
  "thumbnail-button": "_thumbnail-button_o8stay reset-button",
  "thumbnail-image": "_thumbnail-image_o8stay",
  "thumbnail-image-active": "_thumbnail-image-active_o8stay",
  "thumbnail-index": "_thumbnail-index_o8stay",
  "thumbnail-index-active": "_thumbnail-index-active_o8stay",
  "thumbnail-item": "_thumbnail-item_o8stay",
  "thumbnails": "_thumbnails_o8stay",
  "thumbnails-button-left": "_thumbnails-button-left_o8stay",
  "thumbnails-button-right": "_thumbnails-button-right_o8stay",
  "thumbails-heading": "_thumbails-heading_o8stay",
  "thumbails-image": "_thumbails-image_o8stay",
  "thumbnails-list": "_thumbnails-list_o8stay reset-list"
};
