import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.label}}\n  <label\n    data-input-label={{@name}}\n    data-test-input-label={{@name}}\n    local-class=\"label {{if @hideLabel 'label-hidden'}}\"\n    for={{this.nameWithGuid}}\n  >\n    {{this.label}}\n  </label>\n{{/if}}\n\n<Ui::Input::ChangesetErrors\n  @changeset={{@changeset}}\n  @errorsClass={{@errorsClass}}\n  @name={{@name}}\n  @path={{@path}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  as |hasErrors|\n>\n  <div\n    data-test-editor={{if @name @name \"\"}}\n    local-class=\"editor {{if hasErrors 'has-error'}} {{if @disabled 'disabled'}}\"\n    ...attributes\n    {{did-insert this.initQuill}}\n    {{did-update this.maybeInitQuill this.value}}\n    {{will-destroy this.destroyQuill}}\n  >\n  </div>\n</Ui::Input::ChangesetErrors>", {"contents":"{{#if this.label}}\n  <label\n    data-input-label={{@name}}\n    data-test-input-label={{@name}}\n    local-class=\"label {{if @hideLabel 'label-hidden'}}\"\n    for={{this.nameWithGuid}}\n  >\n    {{this.label}}\n  </label>\n{{/if}}\n\n<Ui::Input::ChangesetErrors\n  @changeset={{@changeset}}\n  @errorsClass={{@errorsClass}}\n  @name={{@name}}\n  @path={{@path}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  as |hasErrors|\n>\n  <div\n    data-test-editor={{if @name @name \"\"}}\n    local-class=\"editor {{if hasErrors 'has-error'}} {{if @disabled 'disabled'}}\"\n    ...attributes\n    {{did-insert this.initQuill}}\n    {{did-update this.maybeInitQuill this.value}}\n    {{will-destroy this.destroyQuill}}\n  >\n  </div>\n</Ui::Input::ChangesetErrors>","moduleName":"@mvb/tix-ui/components/ui/input/editor/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/editor/index.hbs"}});
import { action } from '@ember/object';
import { bind } from '@ember/runloop';
import { hash, task } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import styles from './index.css';
import UiInputBaseComponent from '@mvb/tix-ui/components/ui/input/base';
import UiInputEditorLinkModalComponent from '@mvb/tix-ui/components/ui/input/editor/link-modal';

export default class UiInputEditorComponent extends UiInputBaseComponent {
  @service modals;

  emojiButton;
  emojiElement;
  emojiPicker;
  lastValue;
  quill;
  quillElement;

  get toolbarOptions() {
    let container = [];
    let values = [];

    this.args.disableHeader ? null : values.push({ header: [] });
    this.args.disableBold ? null : values.push('bold');
    this.args.disableItalic ? null : values.push('italic');
    this.args.disableUnderline ? null : values.push('underline');
    this.args.disableColor ? null : values.push({ color: [] });
    this.args.disableListOrdered ? null : values.push({ list: 'ordered' });
    this.args.disableListBullet ? null : values.push({ list: 'bullet' });
    this.args.disableBlockquote ? null : values.push('blockquote');

    if (values.length > 0) {
      container.push(values);
      values = [];
    }

    this.args.disableClean ? null : container.push(['clean']);
    this.args.disableLink ? null : values.push('link');
    this.args.disableEmoji ? null : values.push('emoji');

    if (values.length > 0) {
      container.push(values);
      values = [];
    }

    return {
      container,
      handlers: {
        emoji: bind(this, function () {
          if (this.emojiElement) {
            this.removeEmojiElement();
            return;
          }

          let quillContainer = this.quillElement.querySelector('.ql-container');
          this.emojiElement = document.createElement('div');
          this.emojiElement.classList.add(styles['emoji-picker']);
          this.emojiElement.append(this.emojiPicker);
          quillContainer.append(this.emojiElement);
          this.emojiPicker.shadowRoot?.querySelector('input')?.focus();
        }),
        link: bind(this, async function (value) {
          let href = value ? await this.modals.open(UiInputEditorLinkModalComponent) : false;
          this.quill.format('link', href);
        }),
      },
    };
  }

  @task({ drop: true })
  @waitFor
  *initQuillTask(element) {
    let { EmojiPickerElement, EmojiPickerElementTranslationDe, Quill } = yield hash({
      EmojiPickerElement: this.args.disableEmoji ? null : import('emoji-picker-element'),
      EmojiPickerElementTranslationDe: this.args.disableEmoji ? null : import('emoji-picker-element/i18n/de'),
      Quill: import('quill'),
    });
    EmojiPickerElementTranslationDe = EmojiPickerElementTranslationDe?.default;
    Quill = Quill.default;

    this.destroyQuill(element);

    this.quillElement = element;
    this.lastValue = this.value;

    let quillElement = document.createElement('div');
    quillElement.innerHTML = htmlSafe(this.value);
    this.quillElement.append(quillElement);

    this.quill = new Quill(quillElement, {
      modules: {
        toolbar: this.toolbarOptions,
      },
      placeholder: this.args.placeholder ?? '',
      theme: 'snow',
      ...this.args.quillOptions,
    });
    this.quill.on('text-change', this.onChange);

    if (!this.args.disableEmoji) {
      this.emojiButton = this.quillElement.querySelector('.ql-emoji');
      this.emojiButton.innerHTML =
        '<svg viewbox="0 0 18 18"><circle class="ql-fill" cx="7" cy="7" r="1"></circle><circle class="ql-fill" cx="11" cy="7" r="1"></circle><path class="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle class="ql-stroke" cx="9" cy="9" r="6"></circle></svg>';
      this.emojiPicker = new EmojiPickerElement.Picker({
        dataSource: '/app/assets/emoji-picker-element-data/de/cldr-native/data.json',
      });
      this.emojiPicker.classList.add('light');
      this.emojiPicker.i18n = EmojiPickerElementTranslationDe;
      this.emojiPicker.addEventListener('emoji-click', this.onEmojiClick);
      document.addEventListener('click', this.onDocumentClick);
    }

    if (!this.args.disableAutoFocus) {
      this.quill.focus();
      this.quill.setSelection(this.quill.getLength(), 0);
    }

    if (this.args.disabled) {
      this.quill.disable();
    }
  }

  @action
  destroyQuill(element) {
    if (this.emojiPicker) {
      document.removeEventListener('click', this.onDocumentClick);
      this.emojiPicker.removeEventListener('emoji-click', this.onEmojiClick);
      this.emojiPicker = null;
      this.emojiElement = null;
      this.emojiButton = null;
    }

    if (this.quill) {
      this.quill.off('text-change', this.onChange);
      this.quill = null;
      this.quillElement = null;
    }

    element.innerHTML = '';
  }

  @action
  initQuill(element) {
    this.initQuillTask.perform(element);
  }

  @action
  maybeInitQuill(element) {
    if (this.value !== this.lastValue) {
      this.initQuill(element);
    }
  }

  @action
  onChange() {
    let value = this.quill.root.textContent.trim() ? this.quill.root.innerHTML : '';
    this.lastValue = value;

    super.onChange({ target: { value } });
  }

  @action
  onDocumentClick(event) {
    let composedPath = event.composedPath();

    if (this.emojiElement && !composedPath.includes(this.emojiButton) && !composedPath.includes(this.emojiElement)) {
      this.removeEmojiElement();
    }
  }

  @action
  onEmojiClick(event) {
    let selection = this.quill?.getSelection(true);
    this.quill?.insertText(selection.index, event?.detail?.unicode ?? '');
    this.removeEmojiElement();
  }

  @action
  removeEmojiElement() {
    this.emojiElement?.remove();
    this.emojiElement = null;
  }

  willDestroy() {
    this.emojiPicker = null;
    this.quill = null;

    super.willDestroy(...arguments);
  }
}
