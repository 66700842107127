export default {
  "font-xs": "_font-xs_yrqhid",
  "wrapper": "_wrapper_yrqhid",
  "upper-wrapper": "_upper-wrapper_yrqhid",
  "lower-wrapper": "_lower-wrapper_yrqhid",
  "pagination": "_pagination_yrqhid",
  "bordered": "_bordered_yrqhid",
  "buttons": "_buttons_yrqhid",
  "icons": "_icons_yrqhid",
  "checkbox": "_checkbox_yrqhid",
  "preview": "_preview_yrqhid",
  "result-wrapper": "_result-wrapper_yrqhid",
  "page-size-wrapper": "_page-size-wrapper_yrqhid",
  "sort-order-wrapper": "_sort-order-wrapper_yrqhid",
  "title": "_title_yrqhid h3"
};
