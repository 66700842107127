import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper\">\n\n  <Ui::Input::Checkbox\n    data-test-groups-all\n    @name=\"groups-all\"\n    @label={{t \"notesGroups.label.all\"}}\n    @onChange={{fn (mut this.allGroupsToggleIsActive)}}\n    @value={{this.allGroupsToggleIsActive}}\n  />\n\n  <hr local-class=\"hr\" />\n\n  <div local-class=\"groups\">\n    {{#each this.groups as |group|}}\n      <Ui::Input::Checkbox\n        data-test-group={{group.id}}\n        @checkboxValue={{group.id}}\n        @label={{group.name}}\n        @name=\"groups\"\n        @onChange={{fn this.onGroupChange group}}\n        @value={{includes group.id (changeset-get @changeset \"groups\")}}\n      />\n    {{/each}}\n  </div>\n\n</div>\n\n{{#if this.validationMessage}}\n  <div local-class=\"validation-message\" data-test-groups-validation-message>{{this.validationMessage}}</div>\n{{/if}}", {"contents":"<div local-class=\"wrapper\">\n\n  <Ui::Input::Checkbox\n    data-test-groups-all\n    @name=\"groups-all\"\n    @label={{t \"notesGroups.label.all\"}}\n    @onChange={{fn (mut this.allGroupsToggleIsActive)}}\n    @value={{this.allGroupsToggleIsActive}}\n  />\n\n  <hr local-class=\"hr\" />\n\n  <div local-class=\"groups\">\n    {{#each this.groups as |group|}}\n      <Ui::Input::Checkbox\n        data-test-group={{group.id}}\n        @checkboxValue={{group.id}}\n        @label={{group.name}}\n        @name=\"groups\"\n        @onChange={{fn this.onGroupChange group}}\n        @value={{includes group.id (changeset-get @changeset \"groups\")}}\n      />\n    {{/each}}\n  </div>\n\n</div>\n\n{{#if this.validationMessage}}\n  <div local-class=\"validation-message\" data-test-groups-validation-message>{{this.validationMessage}}</div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/notes/groups.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/notes/groups.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class NotesGroupsComponent extends Component {
  @service user;

  get allGroupsToggleIsActive() {
    return this.args.changeset.get('groups').length === this.groups.length;
  }

  set allGroupsToggleIsActive(checked) {
    let groups = checked ? this.groups.map((group) => group.id) : [];
    this.args.changeset.set('groups', groups);
  }

  get groups() {
    return this.user.currentGroups;
  }

  get validationMessage() {
    return this.args.changeset.get('groups').length > 0 ? null : this.args.validationMessage;
  }

  @action
  onGroupChange(entry, checked) {
    let groups = new Set(this.args.changeset.get('groups'));
    groups[checked ? 'add' : 'delete'](entry.id);
    this.args.changeset.set('groups', [...groups]);
  }
}
