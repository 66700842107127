import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class PartySettingsController extends Controller {
  @service errors;
  @service intl;
  @service notifications;

  @action
  async saveSettings(changeset, partySetting, event) {
    event.preventDefault();

    this.saveTask.perform(changeset, partySetting);
  }

  @task
  @waitFor
  *saveTask(changeset, partySetting) {
    try {
      yield changeset.validate();
      if (changeset.isInvalid) {
        return;
      }

      partySetting.set('advertisingAddress', changeset.email);
      yield partySetting.save();
      this.notifications.success(this.intl.t('partySettings.notification.saveSuccess'));
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
