import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.productInfo}}\n  <Layout::AsList\n    local-class=\"info\"\n    data-test-list-view-bibliography-info\n    ...attributes\n    @list={{this.productInfo}}\n    as |detail|\n  >\n    {{#if detail.icon}}\n      <Product::Icon @code={{detail.icon}} local-class=\"icon\" />\n    {{/if}}\n    {{#if detail.code}}\n      {{cl detail.list detail.code~}}\n    {{/if}}\n    {{~detail.text~}}\n  </Layout::AsList>\n{{/if}}", {"contents":"{{#if this.productInfo}}\n  <Layout::AsList\n    local-class=\"info\"\n    data-test-list-view-bibliography-info\n    ...attributes\n    @list={{this.productInfo}}\n    as |detail|\n  >\n    {{#if detail.icon}}\n      <Product::Icon @code={{detail.icon}} local-class=\"icon\" />\n    {{/if}}\n    {{#if detail.code}}\n      {{cl detail.list detail.code~}}\n    {{/if}}\n    {{~detail.text~}}\n  </Layout::AsList>\n{{/if}}","moduleName":"@mvb/tix-ui/components/list-view/bibliography/info.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/list-view/bibliography/info.hbs"}});
import {
  getActualProductFormDetails,
  getProductFormTypeTranslationPath,
} from '@mvb/tix-ui/utils/internal-codelist-services';
import { getExtentInfoByType } from '../../product/bibliography/info';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ListViewBibliographyInfoComponent extends Component {
  @service intl;

  get productInfo() {
    if (!this.args.searchProduct) {
      return [];
    }

    let {
      productFormId,
      primaryContentType,
      productFormDetail,
      pages,
      pagesArabic,
      digitalPagesArabic: pagesDigital,
      duration,
      numDiscs: discs,
      fileSize,
      pieces,
      retailSale,
    } = this.args.searchProduct;

    let info = [];
    let composition = retailSale?.replace(productFormId, '');

    let productFormType = getProductFormTypeTranslationPath(productFormId, productFormDetail, composition);
    if (productFormType) {
      info.push({
        icon: productFormId,
        text: this.intl.t(productFormType),
      });
    }

    let productFormDetails = getActualProductFormDetails(productFormId, productFormDetail);
    info.push(...productFormDetails.map((detail) => ({ code: detail, list: '175' })));

    if (primaryContentType) {
      info.push({
        code: primaryContentType,
        list: '81',
      });
    }

    let extentInfo = getExtentInfoByType({
      pages,
      pagesArabic,
      pagesDigital,
      discs,
      duration,
      fileSize,
      pieces,
      intl: this.intl,
    });

    if (extentInfo) {
      for (let extent of extentInfo) {
        info.push({
          text: extent,
        });
      }
    }

    return info;
  }
}
