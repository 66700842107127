import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';
import ENV from '@mvb/tix-ui/config/environment';

export default {
  mvbId: [
    validatePresence({ presence: true, description: 'intl:validation.mvbId.descriptionLong' }),
    validateLength({
      min: ENV.APP.minMvbIdLength,
      max: ENV.APP.maxMvbIdLength,
      description: 'intl:validation.mvbId.description',
    }),
  ],
};
