import { action } from '@ember/object';
import { all } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import { isChangeset } from 'validated-changeset';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const pseudoMachineComponent = {
  send: () => {},
  stop: () => {},
};

/**
 * Base-class for components that should be part of a given parentMachine.
 * Inheriting this class will automatically spawn a child-machine and notify the parentMachine about it.
 *
 * If changes have been made, the machine provided by the getter below needs to be notified about them to transition in the correct states:
 *   1. right after a change has been made, an 'UPDATE'-event should be send
 *   2. possibly await async operations (e.g. file-uploads)
 *   3. after the change has finished, it should be validated
 *   4. evaluate if the changeset is in a dirty state after the change:
 *     a. if dirty, send a 'CHANGE'-event
 *     b. if pristine, send a 'REVERT'-event
 *   5. notify about the end of the onChange-operation with a 'RESOLVE'-event
 */
export default class MachineEditModeChildComponent extends Component {
  @service objectStore;

  id = guidFor(this);

  constructor() {
    super(...arguments);

    next(() => {
      if (this.args.machine) {
        // event params will be stringified, so we store the object somewhere else
        this.objectStore.setObject(this.id, this);
        this.args.machine.send('ADD', { id: this.id });
      }
    });
  }

  get machine() {
    if (!this.args.machine) {
      return pseudoMachineComponent;
    }
    if (!this.parentMachineIsReady) {
      return {
        send: () => {},
        stop: () => {},
      };
    }
    return this.args.machine?.state.context.sections.find((section) => {
      return section.id === this.id;
    });
  }

  get parentMachineIsReady() {
    let workingStates = ['initializing', 'saving', 'unpublishing', 'publishing'];
    return !workingStates.includes(this.args.machine?.state.value);
  }

  /**
   * Optional method to validate all changes made in the extending component.
   * @param {Proxy|Proxy[]} [changeset=this.changeset] - the changeset or a list of changesets to validate, defaults to this.changeset
   * @return {Promise<boolean>} - return true if all changes are valid or no changes have been made, false for invalid changes.
   */
  async validate(changesets = this.changeset) {
    if (!changesets) {
      return true;
    }

    if (!Array.isArray(changesets)) {
      changesets = [changesets];
    }

    await all[changesets.map((changeset) => changeset.validate())];
    return changesets.every((changeset) => changeset.isValid);
  }

  /**
   * Action to be called when a file has been uploaded and the upload has finished.
   * @param {Proxy} changeset - the changeset to validate
   * @fires resolveUpdate()
   * Ember will send a string as the first argument if none is given, but we need the changeset here.
   */
  @action
  onChangeMedia(changesetParam) {
    let changeset = isChangeset(changesetParam) ? changesetParam : this.changeset;
    this.resolveUpdate(changeset);
  }

  /**
   * Action to be called when a text input or a selection was made.
   * @param {Proxy|String} changesetParam - the changeset to validate. Ember will send a string if none is given, but we need the changeset here.
   * @fires resolveUpdate()
   */
  @action
  onChangeText(changesetParam) {
    let changeset = isChangeset(changesetParam) ? changesetParam : this.changeset;
    this.machine?.send('UPDATE');
    this.resolveUpdate(changeset);
  }

  /**
   * Action to be called when a file upload has failed.
   */
  @action
  onError() {
    this.machine.send('RESOLVE');
    this.args?.onError();
  }

  /**
   * Action to be called when a file has been removed.
   * @param {Proxy|String} changesetParam - the changeset to validate. Ember will send a string if none is given, but we need the changeset here.
   */
  @action
  onFileRemove(changesetParam) {
    let changeset = isChangeset(changesetParam) ? changesetParam : this.changeset;
    this.machine?.send('UPDATE');
    this.resolveUpdate(changeset);
  }

  /**
   * Action to be called when a file has been uploaded.
   */
  @action
  onUpload() {
    // upload started
    this.machine?.send('UPDATE');
  }

  /**
   * This Method will send either a 'CHANGE' or a 'REVERT' action to the current child machine.
   * @param {Proxy} changeset - the changeset to check weather it is dirty or not
   */
  resolveUpdate(changeset) {
    this.validate(changeset);
    this.machine.send(changeset.isDirty || changeset.data?.isNew ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  sendInitialChange() {
    this.machine.send('UPDATE');
    this.machine.send('CHANGE');
    this.machine.send('RESOLVE');
  }

  willDestroy() {
    if (this.machine?.state?.value.changesState === 'dirty') {
      this.machine.send('UPDATE');
      this.machine.send('REVERT');
      this.machine.send('RESOLVE');
    }
    this.machine?.stop();
    super.willDestroy(...arguments);
  }
}
