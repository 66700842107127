import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  comment: validateLength({ description: 'intl:validation.customerContact.description.comment', max: 255 }),
  contactType: validatePresence({
    description: 'intl:validation.customerContact.description.contactTypes',
    ignoreBlank: true,
    presence: true,
  }),
  party: validatePresence({ description: 'intl:validation.customerContact.description.party', presence: true }),
  user: validatePresence({ description: 'intl:validation.customerContact.description.user', presence: true }),
};
