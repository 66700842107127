import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header ...attributes local-class=\"header {{if @hide 'hidden'}}\">\n  <h2 local-class=\"title {{if @truncated 'truncated'}}\" data-title-headline data-test-title-headline>\n    {{@title}}\n  </h2>\n\n  {{#if (and @route @routeTitle)}}\n    <div local-class=\"link\" data-test-title-link>\n      {{#if @model}}\n        <LinkTo @model={{@model}} @query={{this.query}} @route={{@route}}>\n          {{@routeTitle}}\n        </LinkTo>\n      {{else if @query}}\n        <LinkTo @query={{@query}} @route={{@route}}>\n          {{@routeTitle}}\n        </LinkTo>\n      {{else}}\n        <LinkTo @query={{this.query}} @route={{@route}}>\n          {{@routeTitle}}\n        </LinkTo>\n      {{/if}}\n\n      <FaIcon @icon=\"chevron-right\" @size=\"xs\" />\n    </div>\n  {{else if (has-block)}}\n    {{yield}}\n  {{/if}}\n</header>", {"contents":"<header ...attributes local-class=\"header {{if @hide 'hidden'}}\">\n  <h2 local-class=\"title {{if @truncated 'truncated'}}\" data-title-headline data-test-title-headline>\n    {{@title}}\n  </h2>\n\n  {{#if (and @route @routeTitle)}}\n    <div local-class=\"link\" data-test-title-link>\n      {{#if @model}}\n        <LinkTo @model={{@model}} @query={{this.query}} @route={{@route}}>\n          {{@routeTitle}}\n        </LinkTo>\n      {{else if @query}}\n        <LinkTo @query={{@query}} @route={{@route}}>\n          {{@routeTitle}}\n        </LinkTo>\n      {{else}}\n        <LinkTo @query={{this.query}} @route={{@route}}>\n          {{@routeTitle}}\n        </LinkTo>\n      {{/if}}\n\n      <FaIcon @icon=\"chevron-right\" @size=\"xs\" />\n    </div>\n  {{else if (has-block)}}\n    {{yield}}\n  {{/if}}\n</header>","moduleName":"@mvb/tix-ui/components/layout/title/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/title/index.hbs"}});
import { assert, runInDebug } from '@ember/debug';
import Component from '@glimmer/component';

export default class UiTitleComponent extends Component {
  get query() {
    return this.args.query ?? {};
  }

  constructor() {
    super(...arguments);
    runInDebug(() => {
      assert('@title is a required argument', this.args.title !== undefined);
    });
  }
}
