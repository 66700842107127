import { isEmpty } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';

export default function validateDateByType(/* options = {} */) {
  return (key, newValue) => {
    newValue = newValue?.trim() ?? newValue;

    if (!applicationUtil.hasInstance || isEmpty(newValue)) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');

    // in case we have a date in the format dd.MM.yyyy --> remove all delimiters
    newValue = newValue.replaceAll('.', '');

    // if we have null in the string there might not be a month or year selected
    if (newValue.includes('null')) {
      let indexOfNull = newValue.indexOf('null');
      let message = indexOfNull === 0 ? 'validation.date.year' : 'validation.date.month';
      return intlService.t(message);
    }

    // check if the format matches the requirements
    if (!verifyDateFormat(newValue)) {
      return intlService.t('validation.date.format');
    }

    return true;
  };
}

/**
 * Will return true if the passed in date has one of the following formats: yyyyMMdd, yyyyMM, yyyy.
 * @param {String} dateString date as string
 * @returns {boolean}
 */
function verifyDateFormat(dateString) {
  // date should only include numbers
  let containsOnlyNumbers = /^\d+$/.test(dateString);
  if (!containsOnlyNumbers) {
    return false;
  }

  let isFullDate = dateString.length === 8;
  let isMonthYear = dateString.length === 6;
  let isYear = dateString.length === 4;

  return isFullDate || isMonthYear || isYear;
}
