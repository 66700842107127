import ApplicationSerializer from './application';

export default class NoteSerializer extends ApplicationSerializer {
  serialize(snapshot) {
    let payload = super.serialize(...arguments);

    // Extract product id if it exists (for notes created via search products on list views)
    if (snapshot.record.productId) {
      payload.data.relationships = payload.data.relationships || {};
      payload.data.relationships.product = payload.data.relationships.product ?? {
        data: {
          id: snapshot.record.productId,
          type: 'product',
        },
      };
    }

    return payload;
  }
}
