import { CUSTOM_MARKETING_CONTENT_ORDER_QUANTITY_UNIT } from '@mvb/tix-ui/constants';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateThaliaPackagingUnit() {
  if (!applicationUtil.hasInstance) {
    return true;
  }

  let intlService = applicationUtil.lookup('service:intl');

  return (key, newValue, _oldValue, changes, content) => {
    let hasOrderQuantityUnitChanges = 'orderQuantityUnit' in changes;
    let orderQuantityUnit = hasOrderQuantityUnitChanges ? changes?.orderQuantityUnit : content?.orderQuantityUnit;

    if (!newValue && orderQuantityUnit === CUSTOM_MARKETING_CONTENT_ORDER_QUANTITY_UNIT.KAR) {
      return buildMessage(key, { message: intlService.t('validation.thalia.packagingUnit') });
    }

    return true;
  };
}
