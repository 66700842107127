import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<footer local-class=\"footer\">\n  <iframe\n    data-test-footer-iframe\n    title={{t \"layoutFooter.text.copyright\" currentYear=(now \"yyyy\")}}\n    {{did-insert this.iFrameResizerTask.perform}}\n  >\n  </iframe>\n\n  <p local-class=\"version\" data-test-version>\n    {{t \"layoutFooter.text.version\" version=(app-version)}}\n  </p>\n</footer>", {"contents":"<footer local-class=\"footer\">\n  <iframe\n    data-test-footer-iframe\n    title={{t \"layoutFooter.text.copyright\" currentYear=(now \"yyyy\")}}\n    {{did-insert this.iFrameResizerTask.perform}}\n  >\n  </iframe>\n\n  <p local-class=\"version\" data-test-version>\n    {{t \"layoutFooter.text.version\" version=(app-version)}}\n  </p>\n</footer>","moduleName":"@mvb/tix-ui/components/layout/footer/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/footer/index.hbs"}});
import { all, task } from 'ember-concurrency';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import ENV from '@mvb/tix-ui/config/environment';
import window from 'ember-window-mock';

export default class LayoutFooterComponent extends Component {
  @service intl;
  @service notifications;

  @task
  @waitFor
  *iFrameResizerTask(element) {
    if (ENV.environment === 'test' && !window.FORCE_FOOTER) {
      return;
    }

    // Load iFrame contents and iframe-resizer library in parallel
    yield all([
      new Promise((resolve, reject) => {
        element.addEventListener('load', (event) => resolve(event));
        element.addEventListener('error', (error) => reject(error));
        element.src = 'https://www.vlbtix.de/export/footer';
      }),
      import('iframe-resizer'),
    ]);

    // Needs to use global instance that was created as a side effect to the import of `iframe-resizer`
    // As the resulting exports do not provide the correct function for unknown reasons
    window.iFrameResize(
      {
        bodyMargin: '0',
        bodyPadding: '0',
        heightCalculationMethod: 'taggedElement',
        log: false, // enable as needed, iFrameResize is quite chatty
        widthCalculationMethod: 'documentElementOffset',
        warningTimeout: 0,
      },
      element
    );

    this._element = element;
  }

  willDestroy() {
    this._element && this._element.iFrameResizer.removeListeners();
    this._element = null;

    super.willDestroy(...arguments);
  }
}
