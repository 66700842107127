import { isEmpty } from '@ember/utils';
import { WEBSHOP_LINK_PLACEHOLDER_REGEX, WEBSHOP_LINK_STRUCTURE } from '@mvb/tix-ui/constants';

export function generateWebshopLink(type, storeId, ean, partialLink = false) {
  if ((isEmpty(type) || isEmpty(storeId) || isEmpty(ean)) && !partialLink) {
    return '';
  }

  let webshopLinkStructure = WEBSHOP_LINK_STRUCTURE[type];
  let generatedWebshopLink = webshopLinkStructure?.replace(
    new RegExp(WEBSHOP_LINK_PLACEHOLDER_REGEX.EAN),
    ean.replaceAll('-', '')
  );

  if (storeId) {
    let storeIdRegex = new RegExp(`${WEBSHOP_LINK_PLACEHOLDER_REGEX.NAME}|${WEBSHOP_LINK_PLACEHOLDER_REGEX.STORE_ID}`);
    generatedWebshopLink = generatedWebshopLink.replace(storeIdRegex, storeId);
  }

  return generatedWebshopLink;
}
