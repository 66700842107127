export default {
  "carousel": "_carousel_1d86cr",
  "items": "_items_1d86cr reset-list",
  "button": "_button_1d86cr reset-button",
  "icon": "_icon_1d86cr",
  "previous": "_previous_1d86cr",
  "next": "_next_1d86cr",
  "dots": "_dots_1d86cr reset-list",
  "dot": "_dot_1d86cr reset-button",
  "active": "_active_1d86cr"
};
