import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Search::Filter\n  local-class=\"filter\"\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterProductAnnouncementDate.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <Ui::Input::SwitchOnOff\n      @name={{@filter}}\n      @onChange={{this.onFilterChange}}\n      @stateFirst={{t \"searchFilterProductAnnouncementDate.options.onlyDraftTitles\"}}\n      @stateSecond={{t \"searchFilterProductAnnouncementDate.options.all\"}}\n      @switchValueFirst={{true}}\n      @switchValueSecond={{null}}\n      @value={{this.value}}\n    />\n  </:content>\n</Search::Filter>", {"contents":"<Search::Filter\n  local-class=\"filter\"\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterProductAnnouncementDate.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <Ui::Input::SwitchOnOff\n      @name={{@filter}}\n      @onChange={{this.onFilterChange}}\n      @stateFirst={{t \"searchFilterProductAnnouncementDate.options.onlyDraftTitles\"}}\n      @stateSecond={{t \"searchFilterProductAnnouncementDate.options.all\"}}\n      @switchValueFirst={{true}}\n      @switchValueSecond={{null}}\n      @value={{this.value}}\n    />\n  </:content>\n</Search::Filter>","moduleName":"@mvb/tix-ui/components/search/filter/product-announcement-date/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/product-announcement-date/index.hbs"}});
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class searchFilterProductAnnouncementDateComponent extends SearchFilterBaseComponent {
  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();
  }
}
