export default {
  "actions": "_actions_scwri4",
  "branches-dropdown": "_branches-dropdown_scwri4",
  "button-edit": "_button-edit_scwri4",
  "column-branches": "_column-branches_scwri4",
  "column-collection-of-goods": "_column-collection-of-goods_scwri4",
  "column-delete": "_column-delete_scwri4",
  "column-delivery-date": "_column-delivery-date_scwri4",
  "column-dispo-list": "_column-dispo-list_scwri4",
  "column-note": "_column-note_scwri4",
  "column-order-code": "_column-order-code_scwri4",
  "column-quantity": "_column-quantity_scwri4",
  "column-recommended-amount": "_column-recommended-amount_scwri4",
  "form-saving": "_form-saving_scwri4",
  "group": "_group_scwri4",
  "table": "_table_scwri4",
  "table-container": "_table-container_scwri4",
  "table-loading": "_table-loading_scwri4",
  "table-wrapper": "_table-wrapper_scwri4",
  "table-sticky-with-quantity": "_table-sticky-with-quantity_scwri4",
  "table-sticky-with-quantity-and-recommended-amount": "_table-sticky-with-quantity-and-recommended-amount_scwri4"
};
