import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showFilter}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{this.onReset}}\n    {{did-update\n      this.onValueArgumentChange\n      @value.contributorGnd\n      @value.contributorIsni\n      @value.contributorOrcId\n      @value.contributorIdentifierName\n    }}\n  >\n    <:content>\n      {{this.content}}\n    </:content>\n  </Search::Filter>\n{{/if}}", {"contents":"{{#if this.showFilter}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{this.onReset}}\n    {{did-update\n      this.onValueArgumentChange\n      @value.contributorGnd\n      @value.contributorIsni\n      @value.contributorOrcId\n      @value.contributorIdentifierName\n    }}\n  >\n    <:content>\n      {{this.content}}\n    </:content>\n  </Search::Filter>\n{{/if}}","moduleName":"@mvb/tix-ui/components/search/filter/contributor/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/contributor/index.hbs"}});
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterContributorComponent extends SearchFilterBaseComponent {
  @service intl;

  get content() {
    let numberIdentifiers = 0;
    let hasGnd = false;
    let hasIsni = false;
    let hasOrcId = false;

    if (isPresent(this.gnd)) {
      numberIdentifiers++;
      hasGnd = true;
    }
    if (isPresent(this.isni)) {
      numberIdentifiers++;
      hasIsni = true;
    }
    if (isPresent(this.orcId)) {
      numberIdentifiers++;
      hasOrcId = true;
    }

    return this.intl.t('searchFilterContributor.text.contributorIdentifiers', {
      contributor: this.contributorName,
      hasGnd,
      hasIsni,
      hasOrcId,
      numIdentifiers: numberIdentifiers,
      gnd: this.gnd,
      isni: this.isni,
      orcId: this.orcId,
    });
  }

  get contributorName() {
    return this.args.value.contributorIdentifierName;
  }

  get defaultValue() {
    let defaultValueObject = {};

    for (let key in this.args.value) {
      defaultValueObject[key] = this.args.searchService?.queryParamsDefaults?.[key];
    }

    return defaultValueObject;
  }

  get gnd() {
    return this.args.value.contributorGnd;
  }

  get isni() {
    return this.args.value.contributorIsni;
  }

  get orcId() {
    return this.args.value.contributorOrcId;
  }

  get showFilter() {
    return Object.values(this.args.value).some((v) => isPresent(v));
  }

  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();
  }

  @action
  onReset() {
    this.value = this.defaultValue ?? null;
    this.search(this.value);
  }
}
