import { all } from 'ember-concurrency';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class OrderDispoListsDispoListRoute extends Route {
  @service branches;
  @service collectionOfGoods;
  @service dispoLists;
  @service router;
  @service session;
  @service store;
  @service userState;

  async beforeModel() {
    await all([this.branches.load(), this.collectionOfGoods.load(), this.dispoLists.load(), this.userState.load()]);
  }

  async model({ dispo_list_id: dispoListId }) {
    try {
      return await this.store.findRecord('dispo-list', dispoListId);
    } catch {
      await this.router.transitionTo('order.dispo-lists', {
        queryParams: {
          page: 1,
        },
      });
      return undefined;
    }
  }
}
