import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  instance.element?.removeEventListener('load', instance.onLoad);
}

export default class ImageFromFileModifier extends Modifier {
  element;

  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  modify(element, [file]) {
    this.element?.removeEventListener('load', this.onLoad);
    element.addEventListener('load', this.onLoad);

    element.dataset['loading'] = true;
    element.src = URL.createObjectURL(file);

    this.element = element;
  }

  @action
  onLoad() {
    if (this.element) {
      URL.revokeObjectURL(this.element.src);
      delete this.element.dataset['loading'];
    }
  }
}
