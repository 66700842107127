import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class BackofficeUsersDetailRoute extends Route {
  @service store;

  queryParams = {
    sort: {
      refreshModel: false,
    },
  };

  async model({ user_id: userId }) {
    return await this.store.findRecord('user', userId, {
      include: 'address,avatar,assignedParties.party,assignedParties.permissionSet,blogInfo,registrationInput',
    });
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
