import { DateTime } from 'luxon';
import { service } from '@ember/service';
import { URL_SET_PASSWORD } from '@mvb/tix-ui/constants';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class SetPasswordRoute extends Route {
  @service api;

  queryParams = {
    hash: {
      refreshModel: true,
    },
  };

  async model(params) {
    let { expiration, termsAccepted, corporate } = await this.api.get(`${URL_SET_PASSWORD}?hash=${params.hash}`);
    if (DateTime.fromISO(expiration).toUTC < DateTime.utc()) {
      throw new Error('Hash expired');
    }
    return { termsAccepted, isCorporate: corporate };
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
