import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export function getFormattedErrorMessage(error, intl) {
  // if the error is the raw error object
  if (isRawErrorObject(error)) {
    if (error.context?.message) {
      return getCorrectTextFromContext(error.context.message, intl);
    }

    error.context.description = getCorrectTextFromContext(error.context?.description, intl);
    return intl.t(error.message, { ...error.context });
  }

  // if the error is a translation path
  if (intl.exists(error)) {
    return intl.t(error);
  }

  // if the error is already a string
  return error;
}

function getCorrectTextFromContext(text, intl) {
  text = text ?? '';

  if (text.startsWith('intl:')) {
    let path = text.split(':')?.[1];
    return intl.t(path);
  }

  return text;
}

function isRawErrorObject(error) {
  return error?.message && error?.context;
}

export default class ErrorMessageHelper extends Helper {
  @service intl;

  compute([error]) {
    return getFormattedErrorMessage(error, this.intl);
  }
}
