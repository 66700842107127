import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { USER_PLATFORMS } from '@mvb/tix-ui/constants';
import Model from './-base';

export default class BlogInfo extends Model {
  @service intl;

  @attr('string') name;
  @attr('string') url;
  @attr('number') visitors;
  @attr('string') additional;
  @attr({ defaultValue: () => [] }) platforms;

  @belongsTo('user', { async: false, inverse: 'blogInfo' }) user;

  get platformsTextSorted() {
    return this.platforms
      ?.sort((a, b) => {
        if (a === USER_PLATFORMS.OTHERS) {
          return 1;
        }

        if (b === USER_PLATFORMS.OTHERS) {
          return -1;
        }

        return this.intl
          .t(`modelBlogInfo.text.platforms.${a}`)
          .localeCompare(this.intl.t(`modelBlogInfo.text.platforms.${b}`));
      })
      .map((platform) => this.intl.t(`modelBlogInfo.text.platforms.${platform}`));
  }
}
