import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class ErpSalesBranchSetupModel extends Model {
  @attr() created;
  @attr() identifier;
  @attr() username;
  @attr() password;

  @belongsTo('bookstore-branch', { async: false, inverse: null }) bookstoreBranch;
  @belongsTo('erp-sales-setup', { async: false, inverse: 'erpSalesBranchSetups' }) erpSalesSetup;
}
