import { assert, runInDebug } from '@ember/debug';
import { helper } from '@ember/component/helper';

/**
 * checks if a given Set has a specific value
 */

export default helper(function has([_set, value]) {
  runInDebug(() => {
    assert('`set` must be a Set()', _set instanceof Set);
  });
  return _set.has(value);
});
