import { action } from '@ember/object';
import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class PresentationsDispoListProductController extends Controller {
  queryParams = ['asset', 'publishedStatus'];

  @tracked asset = null;
  @tracked publishedStatus = SECTION_STATUS.DRAFT;

  @action
  updateSelectedAsset(assetId) {
    this.asset = assetId;
  }
}
