import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import ModalUserResetPassword from '@mvb/tix-ui/components/user/modal-reset-password';

export default class RegistrationInfoController extends Controller {
  @service modals;

  @action
  async forgotPassword() {
    this.modals.open(ModalUserResetPassword);
  }
}
