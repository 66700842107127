import { isPresent } from '@ember/utils';

export default function queryParamsReset({ exclude = [], route }) {
  if (isPresent(route)) {
    for (let queryParam of route._qp.qps) {
      if (route.controller && !exclude.includes(queryParam.prop)) {
        route.controller[queryParam.prop] = queryParam.defaultValue;
      }
    }
  }
}
