export default {
  // Default messages
  accepted: 'validation.defaultMessages.accepted',
  after: 'validation.defaultMessages.after',
  before: 'validation.defaultMessages.before',
  between: 'validation.defaultMessages.between',
  blank: 'validation.defaultMessages.blank',
  collection: 'validation.defaultMessages.collection',
  confirmation: 'validation.defaultMessages.confirmation',
  date: 'validation.defaultMessages.date',
  email: 'validation.defaultMessages.email',
  empty: 'validation.defaultMessages.empty',
  equalTo: 'validation.defaultMessages.equalTo',
  even: 'validation.defaultMessages.even',
  exclusion: 'validation.defaultMessages.exclusion',
  greaterThan: 'validation.defaultMessages.greaterThan',
  greaterThanOrEqualTo: 'validation.defaultMessages.greaterThanOrEqualTo',
  inclusion: 'validation.defaultMessages.inclusion',
  invalid: 'validation.defaultMessages.invalid',
  lessThan: 'validation.defaultMessages.lessThan',
  lessThanOrEqualTo: 'validation.defaultMessages.lessThanOrEqualTo',
  multipleOf: 'validation.defaultMessages.multipleOf',
  notAnInteger: 'validation.defaultMessages.notAnInteger',
  notANumber: 'validation.defaultMessages.notANumber',
  odd: 'validation.defaultMessages.odd',
  onOrAfter: 'validation.defaultMessages.onOrAfter',
  onOrBefore: 'validation.defaultMessages.onOrBefore',
  otherThan: 'validation.defaultMessages.otherThan',
  phone: 'validation.defaultMessages.phone',
  positive: 'validation.defaultMessages.positive',
  present: 'validation.defaultMessages.present',
  singular: 'validation.defaultMessages.singular',
  tooLong: 'validation.defaultMessages.tooLong',
  tooShort: 'validation.defaultMessages.tooShort',
  url: 'validation.defaultMessages.url',
  wrongDateFormat: 'validation.defaultMessages.wrongDateFormat',
  wrongLength: 'validation.defaultMessages.wrongLength',

  // Custom messages
  decimal: 'validation.decimal.decimalOnly',
  decimalMax: 'validation.decimal.decimalMax',
  decimalMaxOnly: 'validation.decimal.decimalMaxOnly',
  emailMultiple: 'validation.email.emailMultiple',
  hasValidImageDimensions: 'validation.image.hasValidImageDimensions',
  hasValidImageDimensionsExact: 'validation.image.hasValidImageDimensionsExact',
  hasValidImageDimensionsMin: 'validation.image.hasValidImageDimensionsMin',
  hasValidImageHeight: 'validation.image.hasValidImageHeight',
  hasValidImageHeightExact: 'validation.image.hasValidImageHeightExact',
  hasValidImageHeightMin: 'validation.image.hasValidImageHeightMin',
  hasValidImageWidth: 'validation.image.hasValidImageWidth',
  hasValidImageWidthExact: 'validation.image.hasValidImageWidthExact',
  hasValidImageWidthMin: 'validation.image.hasValidImageWidthMin',
  hasValidImageRatio: 'validation.image.hasValidImageRatio',
  isValidDateTime: 'validation.date.isValidDateTime',
  isValidFileSize: 'validation.file.isValidFileSize',
  isValidFileType: 'validation.file.isValidFileType',
  mvbIdDoesNotExist: 'validation.mvbId.doesNotExist',
  mvbIdExists: 'validation.mvbId.exists',
  userIsNotBoUser: 'validation.user.isNotBoUser',
  upperLimitBelowLowerLimit: 'validation.priceFilter.upperLimitInvalid',
};
