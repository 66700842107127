import { isPresent } from '@ember/utils';
import { SEARCH_MODE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchPreviewsService extends SearchBaseService {
  @service abilities;
  @service features;
  @service intl;
  @service store;
  @service user;

  get queryParamsDefaults() {
    return { ...this.queryParamsDefaultsForPreviews, sort: '-sortDate' };
  }

  get queryParamsFilters() {
    return this.queryParamsFiltersForPreviews;
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    return [
      {
        text: this.intl.t('previewsIndex.text.sorting.dateDown'),
        value: '-sortDate',
      },
      {
        text: this.intl.t('previewsIndex.text.sorting.senderNameUp'),
        value: 'senderNameSort',
      },
      {
        text: this.intl.t('previewsIndex.text.sorting.senderNameDown'),
        value: '-senderNameSort',
      },
    ];
  }

  createSearchQuery({ queryParams }) {
    let { advancedSearchCode, page, q, size, sort } = queryParams;
    let query = {
      filter: {
        onList: true,
        searchMode: SEARCH_MODE.QUICK,
      },
      include: 'previewEditStatus',
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersToSearchQuery({ query, queryParams });

    if (Object.keys(query.filter).length > 2 || isPresent(advancedSearchCode)) {
      query.filter.searchMode = SEARCH_MODE.ADVANCED;
    }

    if (isPresent(q)) {
      query.filter.searchTerm = isPresent(advancedSearchCode) ? `${advancedSearchCode}${q}` : q;
    }

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('search-preview', query);
  }

  overrideQueryParameters(queryParamsOverride) {
    return this.overrideQueryParametersForPreviews(queryParamsOverride);
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFilters(queryParamsOverride) {
    return this.overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForPreviews(queryParamsOverride);
  }
}
