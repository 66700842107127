export default {
  "upload": "_upload_i92njg",
  "button": "_button_i92njg",
  "table": "_table_i92njg _table_1edbot",
  "thead": "_thead_i92njg _thead_1edbot",
  "tbody": "_tbody_i92njg _tbody_1edbot",
  "tr": "_tr_i92njg _tr_1xynbj",
  "th": "_th_i92njg _th_zg5hnr",
  "info": "_info_i92njg"
};
