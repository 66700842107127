import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (gt this.mappedPrices.length 0)}}\n  <Ui::Table\n    @columns={{array\n      (hash name=(t \"sectionViewPrice.th.currency\"))\n      (hash name=(t \"sectionViewPrice.th.priceGross\"))\n      (hash name=(t \"sectionViewPrice.th.priceDiscount\"))\n    }}\n    @rows={{this.mappedPrices}}\n    data-test-price-table\n    as |price row|\n  >\n    <row.tr local-class=\"price-row\" data-test-price-row>\n      <Sections::View::Prices::Item @price={{price}} @index={{row.rowIndex}} />\n    </row.tr>\n  </Ui::Table>\n{{else}}\n  <p data-test-price-no-prices>\n    {{t \"sectionViewPrice.text.noPrices\"}}\n  </p>\n{{/if}}", {"contents":"{{#if (gt this.mappedPrices.length 0)}}\n  <Ui::Table\n    @columns={{array\n      (hash name=(t \"sectionViewPrice.th.currency\"))\n      (hash name=(t \"sectionViewPrice.th.priceGross\"))\n      (hash name=(t \"sectionViewPrice.th.priceDiscount\"))\n    }}\n    @rows={{this.mappedPrices}}\n    data-test-price-table\n    as |price row|\n  >\n    <row.tr local-class=\"price-row\" data-test-price-row>\n      <Sections::View::Prices::Item @price={{price}} @index={{row.rowIndex}} />\n    </row.tr>\n  </Ui::Table>\n{{else}}\n  <p data-test-price-no-prices>\n    {{t \"sectionViewPrice.text.noPrices\"}}\n  </p>\n{{/if}}","moduleName":"@mvb/tix-ui/components/sections/view/prices/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/view/prices/index.hbs"}});
import { CURRENCIES_DACH, SECTION_TYPES } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class SectionsViewPricesComponent extends Component {
  get containedPrices() {
    let containedPricesSection = this.args.product?.sortedSections?.find(
      (section) => section.type === SECTION_TYPES.CONTAINED_PRICES
    );
    let sectionContentPrices = containedPricesSection?.contents[0]?.prices;
    return sectionContentPrices ?? [];
  }
  get mappedPrices() {
    let prices = this.args.product?.prices;
    let currencies = Object.values(CURRENCIES_DACH);
    let results = [];

    for (let currency of currencies) {
      let price = prices?.[currency];
      let isSamePrice = price?.retail?.value === price?.discount?.value;
      let visible =
        this.containedPrices.find(
          (containedPrice) => `${containedPrice.currency}_${containedPrice.country}` === currency
        )?.visible ?? true;

      let result = {
        value: price?.retail?.value,
        discountedValue: isSamePrice ? null : price?.discount?.value,
        currencyCountry: currency,
        visible,
      };

      let skipResult = (!result.value && !result.discountedValue) || !result.visible;
      if (skipResult) {
        continue;
      }
      results.push(result);
    }
    return results;
  }
}
