import { validateBoolean } from '@mvb/tix-ui/validators';
import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';
import ENV from '@mvb/tix-ui/config/environment';

export default {
  party: {
    name: [validatePresence({ presence: true, description: 'intl:validation.party.description.name' })],
    mvbId: [
      validateLength({
        min: ENV.APP.minMvbIdLength,
        max: ENV.APP.maxMvbIdLength,
        description: 'intl:validation.party.description.mvbId',
        allowBlank: true,
      }),
    ],
  },
  additional: [
    validateBoolean({
      expected: true,
      message: 'intl:validation.commissionedDataProcessing.accept',
    }),
  ],
};
