import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class DispoListAbility extends Ability {
  @service user;

  get exportFoxxy() {
    return this.user.hasPermission(PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT);
  }

  get handleReferenceProducts() {
    return this.user.hasPermission(PARTY_PERMISSIONS.THALIA_IMPORT_EXPORT_REFERENCE_PRODUCTS);
  }

  get doMassOperations() {
    return (
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_DISPOLISTS) && !this.useHugOrderLines && !this.useThaliaOrderGrid
    );
  }

  get useHugOrderLines() {
    return this.user.hasPermission(PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT);
  }

  get useThaliaOrderGrid() {
    return this.user.hasAnyPermission([
      PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
      PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
    ]);
  }

  get viewDispoListsInProductPages() {
    return this.user.selectedParty?.party?.isBookstore && this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_DISPOLISTS);
  }

  get viewDispoListsPage() {
    return this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_DISPOLISTS);
  }
}
