import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class BlacklistController extends Controller {
  @service api;
  @service errors;

  queryParams = ['partyId', 'emailAddress'];

  @tracked hasUnsubscribed = false;

  @action
  onUnsubscribe() {
    this.unsubscribeTask.perform();
  }

  @task({ drop: true })
  @waitFor
  *unsubscribeTask() {
    if (isNone(this.emailAddress)) {
      return;
    }

    let emailAddress = this.emailAddress.endsWith('/') ? this.emailAddress.slice(0, -1) : this.emailAddress;

    try {
      yield this.api.postJSON('/blacklist', {
        partyId: this.partyId,
        emailAddress,
      });
      this.hasUnsubscribed = true;
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
