import { all } from 'ember-concurrency';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PreviewsRoute extends Route {
  @service branches;
  @service abilities;
  @service collectionOfGoods;
  @service dispoLists;

  async beforeModel() {
    if (this.abilities.can('dispoList.viewDispoListsInProductPages')) {
      await all([this.branches.load(), this.dispoLists.load(), this.collectionOfGoods.load()]);
    }
  }
}
