import { camelize } from '@ember/string';
import { service } from '@ember/service';
import ENV from '@mvb/tix-ui/config/environment';
import JSONAPIAdapter from '@ember-data/adapter/json-api';

function generateHasManyUrl({ namespace, recordToUpdate, relationshipName, relationshipRecord }) {
  let relationshipModelName = camelize(relationshipRecord.constructor.modelName);
  relationshipName = relationshipName ?? `${relationshipModelName}s`;

  let url = `/${recordToUpdate.constructor.modelName}s/${recordToUpdate.id}/relationships/${relationshipName}`;

  return namespace ? `/${namespace}${url}` : url;
}

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service api;

  host = ENV.APP.api;
  namespace = 'jsonapi';

  get headers() {
    return {
      ...this.api.headers,
      'crnk-compact': true,
    };
  }

  addHasMany({ recordToUpdate, relationshipName, relationshipRecord }) {
    let url = generateHasManyUrl({ namespace: this.namespace, recordToUpdate, relationshipName, relationshipRecord });

    return this.api.postVND(url, {
      data: [
        {
          type: relationshipName,
          id: relationshipRecord.id,
        },
      ],
    });
  }

  deleteHasMany({ recordToUpdate, relationshipName, relationshipRecord }) {
    let url = generateHasManyUrl({ namespace: this.namespace, recordToUpdate, relationshipName, relationshipRecord });

    return this.api.deleteVND(url, {
      data: [
        {
          type: relationshipName,
          id: relationshipRecord.id,
        },
      ],
    });
  }

  buildQuery(snapshot) {
    let query = super.buildQuery(snapshot);
    let customParams = snapshot.adapterOptions?.query;

    if (customParams) {
      query = {
        ...query,
        ...customParams,
      };
    }
    return query;
  }

  shouldBackgroundReloadAll() {
    return false;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  shouldReloadAll() {
    return true;
  }

  shouldReloadRecord() {
    return true;
  }

  _fetchRequest(options) {
    return super._fetchRequest({
      ...options,
      credentials: 'include',
      mode: 'cors',
    });
  }
}
