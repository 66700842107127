import { attr } from '@ember-data/model';
import Model from './-base';

export default class PremiumHistoryModel extends Model {
  @attr() statusOld;
  @attr() statusNew;
  @attr('date') expirationOld;
  @attr('date') expirationNew;
  @attr('date-time') modifiedAt;
  @attr() modifiedBy;
}
