import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class BackofficeAdminsAddRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('user');
  }

  deactivate() {
    if (this.store.isDestroyed || this.store.isDestroying) {
      return;
    }

    if (this.model.isNew) {
      this.model.unloadRecord();
    }
  }
}
