import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class OrderAbility extends Ability {
  @service user;

  get access() {
    return this.user.current?.hasAssignedParties ?? false;
  }

  get order() {
    return (
      this.access &&
      this.user.hasPermission(PARTY_PERMISSIONS.ORDER) &&
      !this.user.hasAnyPermission([
        PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
        PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT,
        PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
      ])
    );
  }

  get viewOrderHistory() {
    return !this.user.hasAnyPermission([
      PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
      PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT,
      PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
    ]);
  }

  get viewOrderHistoryFoxxyCallout() {
    return this.user.hasPermission(PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT);
  }

  get viewOrderHistoryThaliaCallout() {
    return this.user.hasAnyPermission([
      PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
      PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
    ]);
  }

  get viewOrderList() {
    return !this.user.hasAnyPermission([
      PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
      PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT,
      PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
    ]);
  }

  get viewOrderListFoxxyCallout() {
    return this.user.hasPermission(PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT);
  }

  get viewOrderListThaliaCallout() {
    return this.user.hasAnyPermission([
      PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
      PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
    ]);
  }
}
