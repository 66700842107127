import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class RegistrationInputModel extends Model {
  @attr('string') partyName;
  @attr('string') mvbId;
  @attr('string') boevId; // Verkehrsnummer
  @attr('string') userType;

  @belongsTo('user', { async: false, inverse: 'registrationInput' }) user;
}
