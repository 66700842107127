import { PARTY_TYPE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

const PARTY_TYPES_FOR_PREMIUM = [PARTY_TYPE.BOOK_GROUP, PARTY_TYPE.PUBLISHER, PARTY_TYPE.SALES_COOP];

export default class PremiumAbility extends Ability {
  @service features;
  @service session;
  @service user;

  get upgradeTo() {
    if (this.features.isEnabled('bookstore-premium')) {
      PARTY_TYPES_FOR_PREMIUM.push(PARTY_TYPE.BOOKSTORE);
    }

    return (
      !this.user.selectedPartyHasPremiumStatus && PARTY_TYPES_FOR_PREMIUM.includes(this.user.selectedParty?.party?.type)
    );
  }

  get seeIndicator() {
    if (this.features.isEnabled('bookstore-premium')) {
      PARTY_TYPES_FOR_PREMIUM.push(PARTY_TYPE.BOOKSTORE);
    }

    return (
      this.user.selectedPartyHasPremiumStatus && PARTY_TYPES_FOR_PREMIUM.includes(this.user.selectedParty?.party?.type)
    );
  }
}
