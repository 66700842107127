import { determineHighlightLabelPathForPartyAndUser } from '@mvb/tix-ui/utils/internal-codelist-services';
import { FLAG_GROUP, FLAG_TRANSLATION_KEY } from '@mvb/tix-ui/constants';
import { isEmpty } from '@ember/utils';
import Service, { service } from '@ember/service';

export default class ProductFlagsService extends Service {
  @service features;
  @service intl;
  @service user;

  getProductFlags(model) {
    let flags = [];

    // evaluate PromotionalPackage flags
    if (model?.isPromotionalPackage) {
      flags.push(this.createFlag(FLAG_GROUP.CAMPAIGN, this.intl.t('productsProduct.text.promotionalPackage')));
    }

    // evaluate Themenspecial flags
    if (model?.isThemenspecial) {
      flags.push(this.createFlag(FLAG_GROUP.CAMPAIGN, this.intl.t('productsProduct.text.themenspecial')));
    }

    // evaluate highlight flags
    if (model?.highlighting?.length > 0) {
      let path = determineHighlightLabelPathForPartyAndUser({
        highlights: model.highlighting,
        partyType: this.user.selectedParty?.party?.type,
        userType: this.user.current?.type,
      });
      if (path) {
        flags.push(this.createFlag(FLAG_GROUP.HIGHLIGHT, this.intl.t(path)));
      }
    }

    // evaluate product flags
    if (Array.isArray(model?.productFlags)) {
      for (let productFlag of this.getSortedProductFlags(model.productFlags)) {
        flags.push(this.createFlag(productFlag.flagGroup, productFlag.value, productFlag.translationKey));
      }
    }

    // evaluate book groups
    if (!this.user.currentUserSettings?.hideHighlightsBookGroups && Array.isArray(model?.highlightingBookGroups)) {
      for (let highlightingBookGroup of model.highlightingBookGroups) {
        flags.push(this.createFlag(FLAG_GROUP.BOOK_GROUP, highlightingBookGroup));
      }
    }

    return flags;
  }

  getSortedProductFlags(productFlags) {
    let untouchedFlags = productFlags.filter((flag) => flag.flagGroup !== FLAG_GROUP.THALIA_RECOMMENDATION);
    let thaliaFlags = productFlags.filter((flag) => flag.flagGroup === FLAG_GROUP.THALIA_RECOMMENDATION);
    let sortedThaliaFlags = thaliaFlags.sort((a) => {
      return a.translationKey === FLAG_TRANSLATION_KEY.STRING ? -1 : 1;
    });
    return [...untouchedFlags, ...sortedThaliaFlags];
  }

  createFlag(flagGroup, value, translationKey = undefined) {
    if (!isEmpty(translationKey)) {
      return { flagGroup, value: this.intl.t(`${translationKey}.${value}`) };
    }
    return {
      flagGroup,
      value,
    };
  }
}
