import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"jump\" {{did-insert this.onInsert}} {{will-destroy this.onDestroy}}></div>", {"contents":"<div local-class=\"jump\" {{did-insert this.onInsert}} {{will-destroy this.onDestroy}}></div>","moduleName":"@mvb/tix-ui/components/jump.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/jump.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class JumpComponent extends Component {
  @service jump;
  @service observerManager;

  @action
  onInsert(element) {
    if (element) {
      let callback = ({ isIntersecting }) => {
        this.jump.firstScreenVisible = isIntersecting;
      };

      this.observerManager.addEnterCallback(element, callback);
      this.observerManager.addExitCallback(element, callback);
      this.observerManager.observe(element);
    }
  }

  @action
  onDestroy(element) {
    if (element) {
      this.observerManager.unobserve(element, {});
    }
  }
}
