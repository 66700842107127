import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { TIX_LIST_TYPE } from '@mvb/tix-ui/constants';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchPreviewListsService extends SearchBaseService {
  @service store;
  @service user;

  _maxEntitiesForSearch = Number.POSITIVE_INFINITY;

  get queryParamsDefaults() {
    return {
      page: 1,
      sort: '-lastUpdated',
    };
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortKeys = ['created', 'lastUpdated', 'name', 'owner', 'shared'];
    return [{ value: null }].concat(...sortKeys.map((value) => [{ value }, { value: `-${value}` }]));
  }

  createSearchQuery({ queryParams }) {
    let { page, size, sort } = queryParams;
    let query = {
      filter: {
        listType: TIX_LIST_TYPE.PREVIEW_LIST,
      },
      include: 'owner',
      page: {
        number: page,
        size,
      },
    };

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('list', query);
  }
}
