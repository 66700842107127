import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PreviewListsPreviewListRoute extends Route {
  @service router;
  @service store;

  async model({ list_id: listId }) {
    try {
      return await this.store.findRecord('list', listId);
    } catch {
      await this.router.transitionTo('preview-lists', {
        queryParams: {
          page: 1,
        },
      });
      return undefined;
    }
  }
}
