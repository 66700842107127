import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class OrderListProductModel extends Model {
  @attr('date') created;
  @attr() productIsbn;

  @belongsTo('order-list', { async: false, inverse: 'orderListProducts' }) orderList;
  @belongsTo('product', { async: false, inverse: null }) product;

  @hasMany('order-list-product-entry', { async: false, inverse: 'orderListProduct' }) orderListProductEntries;
}
