import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes local-class=\"position {{if @sticky 'position-sticky'}}\" data-test-iconbar>\n  <div\n    local-class=\"toolbar {{if @sticky 'toolbar-sticky'}}\"\n    {{did-insert this.didInsert}}\n    {{observe-mutation this.didMutate childList=true}}\n  >\n    {{yield}}\n  </div>\n</div>", {"contents":"<div ...attributes local-class=\"position {{if @sticky 'position-sticky'}}\" data-test-iconbar>\n  <div\n    local-class=\"toolbar {{if @sticky 'toolbar-sticky'}}\"\n    {{did-insert this.didInsert}}\n    {{observe-mutation this.didMutate childList=true}}\n  >\n    {{yield}}\n  </div>\n</div>","moduleName":"@mvb/tix-ui/components/layout/iconbar/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/iconbar/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class LayoutIconbarIndexComponent extends Component {
  @action
  didInsert() {
    return this.args.onDidInsert?.(...arguments);
  }

  @action
  didMutate() {
    return this.args.onDidMutate?.(...arguments);
  }
}
