import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.value}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{fn this.onFilterChange null}}\n    {{did-update this.onValueArgumentChange @value}}\n    {{did-update this.loadCollectionNameTask.perform @searchService.results}}\n  >\n    <:content>\n      {{t \"searchFilterProductCollections.text.active\" name=this.collectionName}}\n    </:content>\n  </Search::Filter>\n{{/if}}", {"contents":"{{#if this.value}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{fn this.onFilterChange null}}\n    {{did-update this.onValueArgumentChange @value}}\n    {{did-update this.loadCollectionNameTask.perform @searchService.results}}\n  >\n    <:content>\n      {{t \"searchFilterProductCollections.text.active\" name=this.collectionName}}\n    </:content>\n  </Search::Filter>\n{{/if}}","moduleName":"@mvb/tix-ui/components/search/filter/product-collections/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/product-collections/index.hbs"}});
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchProductCollectionsComponent extends SearchFilterBaseComponent {
  @service store;

  get collectionName() {
    return this.loadCollectionNameTask.lastSuccessful?.value ?? this.value;
  }

  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();

    next(this, () => {
      this.loadCollectionNameTask.perform();
    });
  }

  @task
  @waitFor
  *loadCollectionNameTask() {
    let results = yield this.args.searchService.results ?? [];
    let firstResult = results[0]?.searchProduct;

    return firstResult?.collections?.[0]?.title ?? null;
  }
}
