import { attr } from '@ember-data/model';
import { service } from '@ember/service';
import countries from 'i18n-iso-countries';
import Model from './-base';

export default class AddressModel extends Model {
  @service intl;

  @attr('date') created;

  @attr('string') street;
  @attr('string') streetNumber;
  @attr('string') zipcode;
  @attr('string') city;
  @attr('string') country;

  get cityInfo() {
    return [this.zipcode?.trim(), this.city?.trim()].filter(Boolean).join(' ');
  }

  get countryLabel() {
    return this.country ? countries.getName(this.country, this.intl.primaryLocale.split('-')[0]) : '';
  }

  get fullAddressOneLine() {
    return [this.streetInfo.trim(), this.cityInfo.trim()].filter(Boolean).join(', ');
  }

  get fullAddressMultiLine() {
    return this.fullAddressOneLine.replaceAll(', ', '\n');
  }

  get streetInfo() {
    return [this.street?.trim(), this.streetNumber?.trim()].filter(Boolean).join(' ');
  }
}
