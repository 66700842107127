import Model, { attr, belongsTo } from '@ember-data/model';

export default class ProductInfoModel extends Model {
  @attr() createdAt;
  @attr('string') createdBy;
  @attr('string') description;
  @attr('string') identifier;
  @attr('string') subtitle;
  @attr('string') title;
  @attr('string') backgroundColor;

  @belongsTo('section', { async: false, inverse: 'productInfo' }) section;
}
