import { isPresent } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import evValidateFormat from 'ember-validators/format';

export default function validateEmailMultiple() {
  return (key, value) => {
    if (isPresent(value)) {
      let values = trimAll(value.split(';')).filter(Boolean);

      if (values.length === 0 || isValueInvalid(key, values)) {
        return buildMessage(key, { type: 'emailMultiple' });
      }
    }

    return true;
  };
}

function trimAll(values) {
  return values.map((value) => value.trim());
}

function isValueInvalid(key, values) {
  return values.some((value) => true !== evValidateFormat(value, { type: 'email' }, null, key));
}
