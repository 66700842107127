import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Layout::Title @title={{@dispoList.name}} data-test-dispo-list-title />\n\n{{#if (cannot \"dispoList.useHugOrderLines\")}}\n  <p data-test-dispo-list-total-price>\n    {{#if (and (cannot \"dispoList.useThaliaOrderGrid\") this.totalPrice.value)}}\n      {{t\n        \"dispoList.summary.text.totalPrice\"\n        currency=this.totalPrice.currency\n        value=(format-number this.totalPrice.value format=\"price\")\n      }}\n    {{else}}\n      {{t\n        \"dispoList.summary.text.totalPrice\"\n        currency=this.totalPrice.currency\n        value=(t \"layoutPriceBlock.text.priceEmpty\")\n      }}\n    {{/if}}\n  </p>\n\n  {{! TODO insert branches overview here #28134}}\n{{/if}}", {"contents":"<Layout::Title @title={{@dispoList.name}} data-test-dispo-list-title />\n\n{{#if (cannot \"dispoList.useHugOrderLines\")}}\n  <p data-test-dispo-list-total-price>\n    {{#if (and (cannot \"dispoList.useThaliaOrderGrid\") this.totalPrice.value)}}\n      {{t\n        \"dispoList.summary.text.totalPrice\"\n        currency=this.totalPrice.currency\n        value=(format-number this.totalPrice.value format=\"price\")\n      }}\n    {{else}}\n      {{t\n        \"dispoList.summary.text.totalPrice\"\n        currency=this.totalPrice.currency\n        value=(t \"layoutPriceBlock.text.priceEmpty\")\n      }}\n    {{/if}}\n  </p>\n\n  {{! TODO insert branches overview here #28134}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/dispo-lists/summary.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/dispo-lists/summary.hbs"}});
import { CURRENCIES_DACH } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class DispoListsSummaryComponent extends Component {
  @service intl;
  @service user;

  get totalPrice() {
    let selectedCurrencyCountry = this.user.selectedCurrencyCountry || CURRENCIES_DACH.EUR_DE;

    return {
      value: this.args.dispoList.totalPrice,
      currency: this.intl.t(`modelProduct.text.currencyCountry.${selectedCurrencyCountry}`),
    };
  }
}
