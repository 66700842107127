import { DateTime } from 'luxon';

export default function parsePublicationDate(publicationDate) {
  // the publication date looks like '20210317'
  // but does not necessarily include month and/or day
  if (publicationDate.length === 4) {
    return DateTime.fromFormat(publicationDate, 'yyyy');
  }
  if (publicationDate.length === 6) {
    return DateTime.fromFormat(publicationDate, 'yyyyLL');
  }
  if (publicationDate.length === 7) {
    return DateTime.fromFormat(publicationDate, 'LL.yyyy');
  }
  if (publicationDate.length === 8) {
    return DateTime.fromFormat(publicationDate, 'yyyyLLdd');
  }
  return null;
}
