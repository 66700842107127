export default {
  "ember-cli-notifications-notification__container": "_ember-cli-notifications-notification__container_1cb8hy",
  "c-notification": "_c-notification_1cb8hy",
  "c-notification--error": "_c-notification--error_1cb8hy",
  "c-notification--info": "_c-notification--info_1cb8hy",
  "c-notification--success": "_c-notification--success_1cb8hy",
  "c-notification--warning": "_c-notification--warning_1cb8hy",
  "c-notification__close": "_c-notification__close_1cb8hy",
  "c-notification__icon": "_c-notification__icon_1cb8hy",
  "c-notification--clickable": "_c-notification--clickable_1cb8hy",
  "c-notification__content": "_c-notification__content_1cb8hy"
};
