import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';

export default class BackofficeAdminsDetailController extends Controller {
  @service intl;
  @service router;

  get title() {
    return this.intl.t('backofficeAdminsDetail.title', { name: this.model.fullName });
  }

  @action
  toAdminsOverview() {
    this.router.transitionTo('backoffice.admins');
  }
}
