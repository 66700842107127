export default {
  "header": "_header_1buy7g",
  "nav": "_nav_1buy7g",
  "browse-section": "_browse-section_1buy7g",
  "logo": "_logo_1buy7g",
  "title": "_title_1buy7g",
  "toggle": "_toggle_1buy7g reset-button",
  "overview": "_overview_1buy7g",
  "up": "_up_1buy7g",
  "login": "_login_1buy7g",
  "browse": "_browse_1buy7g",
  "divider": "_divider_1buy7g",
  "with-up": "_with-up_1buy7g",
  "previous": "_previous_1buy7g",
  "next": "_next_1buy7g"
};
