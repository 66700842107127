import Model, { attr, belongsTo } from '@ember-data/model';

export default class DeliveryInfoModel extends Model {
  @attr() availability;
  @attr() availabilityDate;
  @attr('string') availabilityStatus;
  @attr('date-time') onSaleDate;
  @attr() publicationDate;

  @belongsTo('section', { async: false, inverse: 'deliveryInfo' }) section;
}
