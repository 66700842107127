import { isPresent } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import ObjectProxy from '@ember/object/proxy';

export default function validateOrderRowDispoListHug(options) {
  return (key, newValue, _oldValue, changes, content) => {
    let collectionOfGoods = changes?.collectionOfGoods ?? content.collectionOfGoods;
    let hugListingRecommendationCodes =
      changes?.hugListingRecommendationCodes ?? content.hugListingRecommendationCodes ?? [];
    let note = changes?.note ?? content.note;

    if (collectionOfGoods instanceof ObjectProxy) {
      collectionOfGoods = collectionOfGoods?.content;
    }

    if (
      // already saved order row and no dispolist selected
      (!content.isNew && !isPresent(newValue)) ||
      // new order row and no dispolist selected but valid listing or any of the optional fields is set
      (content.isNew &&
        !isPresent(newValue) &&
        (isPresent(collectionOfGoods) || isPresent(note) || hugListingRecommendationCodes.length > 0))
    ) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
