import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class ProgressHelper extends Helper {
  @service progress;

  instance = null;

  compute(_, options) {
    options = { ...options };

    this.instance = this.progress.add(options);
  }

  willDestroy() {
    this.instance.remove();
  }
}
