import BackofficePartiesPartyRoute from './party';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';

export default class BackofficePartiesBookstoreRoute extends BackofficePartiesPartyRoute {
  modelName = 'bookstore';

  queryParams = {
    page: {
      refreshModel: true,
    },
    premiumStatus: {
      refreshModel: false,
    },
  };

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
