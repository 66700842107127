import { validateFormat, validateLength, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  username: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.username' }),
    validateFormat({ type: 'email', description: 'intl:validation.user.description.username' }),
    validateLength({ min: 5, max: 255, description: 'intl:validation.user.description.username' }),
  ],
  gender: [validatePresence({ presence: true, description: 'intl:validation.user.description.gender' })],
  firstname: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.firstname' }),
    validateLength({ max: 255, description: 'intl:validation.user.description.firstname' }),
  ],
  lastname: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.name' }),
    validateLength({ max: 255, description: 'intl:validation.user.description.name' }),
  ],
};
