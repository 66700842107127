import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.productInfo}}\n  <div data-test-bibliography-product-info>\n    <Layout::AsList @list={{this.productInfo}} as |detail|>\n      {{#if detail.code}}\n        {{cl detail.list detail.code~}}\n      {{/if}}\n      {{#if detail.icon}}\n        <Product::Icon @code={{detail.icon}} local-class=\"icon\" />\n      {{/if}}\n      {{~detail.text~}}\n    </Layout::AsList>\n  </div>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.productInfo}}\n  <div data-test-bibliography-product-info>\n    <Layout::AsList @list={{this.productInfo}} as |detail|>\n      {{#if detail.code}}\n        {{cl detail.list detail.code~}}\n      {{/if}}\n      {{#if detail.icon}}\n        <Product::Icon @code={{detail.icon}} local-class=\"icon\" />\n      {{/if}}\n      {{~detail.text~}}\n    </Layout::AsList>\n  </div>\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/bibliography/info.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/bibliography/info.hbs"}});
import { Duration } from 'luxon';
import {
  getActualProductFormDetails,
  getProductFormTypeTranslationPath,
} from '@mvb/tix-ui/utils/internal-codelist-services';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export function getExtentInfoByType({ pages, pagesArabic, pagesDigital, discs, duration, fileSize, pieces, intl }) {
  let extent = [];

  if (!isEmpty(pages)) {
    extent.push(intl.t('productBibliography.text.pages', { pages }));
  } else if (!isEmpty(pagesArabic)) {
    extent.push(intl.t('productBibliography.text.pages', { pages: pagesArabic }));
  } else if (!isEmpty(pagesDigital)) {
    extent.push(intl.t('productBibliography.text.pages', { pages: pagesDigital }));
  }

  if (!isEmpty(fileSize)) {
    let formattedFileSize = Number.parseFloat((fileSize / 1024).toFixed(3));
    extent.push(intl.t('productBibliography.text.fileSize', { size: formattedFileSize }));
  }

  if (!isEmpty(discs)) {
    extent.push(intl.t('productBibliography.text.numDiscs', { numDiscs: discs }));
  }

  if (!isEmpty(duration)) {
    let normalizedDuration = Duration.fromObject({ hours: 0, minutes: duration }).normalize();
    extent.push(
      intl.t('productBibliography.text.durationInHours', {
        hours: normalizedDuration.hours,
        minutes: normalizedDuration.minutes,
      })
    );
  }

  if (!isEmpty(pieces)) {
    extent.push(intl.t('productBibliography.text.pieces', { pieces }));
  }

  return extent;
}

export default class ProductBibliographyInfoComponent extends Component {
  @service intl;

  get productFormType() {
    return getProductFormTypeTranslationPath(this.args.product.form?.typeO3, this.args.product.form?.detailO3);
  }

  get productFormDetails() {
    return getActualProductFormDetails(this.args.product.form?.typeO3, this.args.product.form?.detailO3);
  }

  get languages() {
    return this.args.product.languages?.filter((lang) => lang.type === '01') || [];
  }

  get extentInfo() {
    let pages = this.args.product.extent?.pages;
    let pagesArabic = this.args.product.extent?.pagesArabic;
    let pagesDigital = this.args.product.extent?.digitalPagesArabic;
    let discs = this.args.product.extent?.numDiscs;
    let duration = this.args.product.extent?.duration;
    let fileSize = this.args.product.extent?.fileSize;
    let pieces = this.args.product.numberOfPieces;

    return getExtentInfoByType({
      pages,
      pagesArabic,
      pagesDigital,
      discs,
      duration,
      fileSize,
      pieces,
      intl: this.intl,
    });
  }

  get productInfo() {
    let form = this.args.product?.form;
    let productContentTypes = form?.productContentType?.trim().split(', ') ?? [];
    let drmCode = this.args.product?.extent?.drmCode ?? null;
    let edition = this.args.product?.edition;
    let info = [];
    if (this.productFormType) {
      info.push({
        icon: form['typeO3'],
        text: this.intl.t(this.productFormType),
      });
    }

    info = info.concat(this.productFormDetails.map((detail) => ({ code: detail, list: '175' })));

    if (form?.primaryContentType) {
      info.push({
        code: form.primaryContentType,
        list: '81',
      });
    }

    info = info.concat(productContentTypes.map((type) => ({ code: type, list: '81' })));

    if (drmCode) {
      info.push({
        code: drmCode,
        list: '144',
      });
    }

    info = info.concat(this.languages.map((language) => ({ code: language.value, list: '74' })));

    if (this.extentInfo) {
      for (let extent of this.extentInfo) {
        info.push({
          text: extent,
        });
      }
    }

    if (edition?.number) {
      info.push({
        text: this.intl.t('productBibliography.text.edition', { num: edition.number }),
      });
    }

    if (edition?.type) {
      let types = edition.type.split(',');
      let editionTypesList = types.map((t) => ({ code: t.trim(), list: '21' }));
      info = info.concat(editionTypesList);
    }

    if (edition?.text) {
      info.push({
        text: edition.text,
      });
    }
    return info;
  }
}
