import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
import { TIX_ITEM_SOURCE_TYPE, URL_CMCS_LOCKED } from '@mvb/tix-ui/constants';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';

export default class CustomMarketingContentLockedService extends Service {
  @service api;
  @service errors;
  @service session;

  /**
   * This array contains the previewId as key and an array of all the locked products for this preview as value.
   */
  @tracked allPreviewsWithLockedProductsOnly = new Map();

  /**
   * Get all the locked status infos for the cmcs of the products and their previews either from the passed previews or list items
   * @param {Preview|Preview[]} previews a single preview or an array of previews, defaults to an empty Array
   * @param {DispoListItem[]|ListItem[]} listItems an array of list items (products) coming from a dispo-list or product-list, defaults to an empty Array
   */
  @task
  @waitFor
  *getCMCsLockedStatusForPreviewsTask({ previews = [], listItems = [] }) {
    try {
      if (!this.session.isAuthenticated) {
        return;
      }

      if (!Array.isArray(previews)) {
        previews = [previews];
      }

      // in case we don't want to resolve listItems, we don't need to collect preview.referencedProductIds because
      // backend will handle that
      let payload = isEmpty(listItems) ? [] : this.getPayloadDataFromListProducts(listItems);
      for (let preview of previews) {
        if (preview) {
          payload.push({
            previewId: preview.id,
            referencedProductIds: isEmpty(listItems) ? [] : preview.referencedProductIds,
          });
        }
      }

      // no need to tickle the BE if we don't have any data to send
      if (isEmpty(payload)) {
        return;
      }

      let results = yield this.api.postJSON(URL_CMCS_LOCKED, payload);

      if (Array.isArray(results)) {
        for (let data of results) {
          let lockedProducts = data.results.filter((product) => product.locked);
          this.allPreviewsWithLockedProductsOnly.set(data.previewId, lockedProducts);
        }
      }

      return results;
    } catch (error) {
      this.errors.handle(error);
    }
  }

  /**
   * @param {string} previewId - the id of the preview we filter the locked products for
   * @returns only the locked products for the passed previewId
   */
  getLockedProductsForPreview(previewId) {
    return this.allPreviewsWithLockedProductsOnly.get(previewId);
  }

  /**
   * Maps the data coming from a product-list or dispo-list to the format needed for getting the locked status for the CMCs in the list
   *
   * @param {ListItem[]} listItems - items coming from a product-list or dispo-list
   * @returns array of previews for the payload with the format {previewId: previewId, referencedProductIds: [productIds]}
   */
  getPayloadDataFromListProducts(listItems) {
    let payload = [];
    let previewItems = listItems.filter((item) => item.sourceType === TIX_ITEM_SOURCE_TYPE.PREVIEW);

    for (let item of previewItems) {
      let existingRecord = payload.find((preview) => preview.previewId === item.sourceId);

      if (existingRecord) {
        existingRecord.referencedProductIds.push(item.searchProduct.get('id'));
      } else if (item.sourceId) {
        payload.push({
          previewId: item.sourceId,
          referencedProductIds: [item.searchProduct.get('id')],
        });
      }
    }

    return payload;
  }
}
