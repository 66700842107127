import { bind } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchProductsInDispoListService extends SearchBaseService {
  @service abilities;
  @service('custom-marketing-content-locked') customMarketingContentLockedService;
  @service('dispo-list-product-entries') dispoListProductEntriesService;
  @service features;
  @service intl;
  @service store;
  @service user;

  get queryParamsDefaults() {
    return {
      ...this.queryParamsDefaultsForProducts,
      ...this.queryParamsDefaultsForAdditionalInformation,
      selectedDispoListProductIds: '',
      inverse: false,
      sort: 'created',
    };
  }

  get queryParamsFilters() {
    return this.queryParamsFiltersForProducts;
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortOrders = [
      {
        text: this.intl.t('productsIndex.text.sorting.creationDateUp'),
        value: '',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.creationDateDown'),
        value: '-created',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateUp'),
        value: 'publicationDate',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateDown'),
        value: '-publicationDate',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.publisherSortUp'),
        value: 'publisherName',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.publisherSortDown'),
        value: '-publisherName',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.currentPriceUp'),
        value: 'currentPrice',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.currentPriceDown'),
        value: '-currentPrice',
      },
    ];

    if (this.abilities.cannot('dispoList.useHugOrderLines') && this.abilities.cannot('dispoList.useThaliaOrderGrid')) {
      sortOrders.push(
        {
          text: this.intl.t('productsIndex.text.sorting.quantityUp'),
          value: 'totalQuantity',
        },
        {
          text: this.intl.t('productsIndex.text.sorting.quantityDown'),
          value: '-totalQuantity',
        }
      );
    }

    return sortOrders;
  }

  createSearchQuery({ model: dispoList, queryParams }) {
    let { page, size, sort } = queryParams;
    let query = {
      filter: {
        'dispoList.id': dispoList.id,
        onList: true,
      },
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersOfAdditionalInformationToSearchQuery({ query, queryParams });
    this.addFiltersToSearchQuery({ query, queryParams });

    query.include = this.abilities.can('dispoList.useThaliaOrderGrid')
      ? 'thaliaDispoListProductEntries,searchProduct,searchProduct.notes'
      : 'dispoListProductEntries.bookstoreBranch,dispoListProductEntries.collectionOfGoods,searchProduct,searchProduct.notes';

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('dispo-list-product', query);
  }

  async mapResults({ results: dispoListProducts }) {
    let { customMarketingContentLockedService, dispoListProductEntriesService } = this;
    let updateAdditionalInformationFilters = bind(this, this.updateAdditionalInformationFilters);

    // get all the locked infos needed for the cmcs
    customMarketingContentLockedService.getCMCsLockedStatusForPreviewsTask.perform({ listItems: dispoListProducts });

    dispoListProductEntriesService.cleanup();

    return (
      dispoListProducts
        ?.filter((dispoListProduct) => !!dispoListProduct.searchProduct)
        ?.map((dispoListProduct) => {
          dispoListProduct = new (class {
            dispoListProduct = dispoListProduct;
            // eslint-disable-next-line unicorn/consistent-function-scoping
            onSaveAdditionalInformationTask = task(async ({ cmcModel } = {}) => {
              if (cmcModel?.hugListingRecommendations?.length > 0 && cmcModel?.preview?.content?.isHugendubel) {
                await dispoListProductEntriesService.createForDefaultDispoListTask.perform({
                  collectionOfGoods: cmcModel.assortment?.content ?? null,
                  hugListingRecommendationCodes:
                    cmcModel.hugListingRecommendations?.map((listing) => listing.code) ?? [],
                  preview: cmcModel.preview.content,
                  productId: cmcModel.referencedProductId,
                  productIsbn: cmcModel.referencedProductIsbn,
                });
              }
              updateAdditionalInformationFilters();
            });
            searchProduct = dispoListProduct.searchProduct;
          })();

          return dispoListProduct;
        }) ?? []
    );
  }

  overrideQueryParameters(queryParamsOverride) {
    return this.overrideQueryParametersForProducts(queryParamsOverride);
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFilters(queryParamsOverride) {
    return this.overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForProducts(queryParamsOverride);
  }
}
