// Polyfill for ember-intl, adding locales as necessary
import './custom-icons';
import './sentry';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/polyfill';

import Application from '@ember/application';
import config from '@mvb/tix-ui/config/environment';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import window from 'ember-window-mock';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

// disable automatic browser scroll on history changes (browser back/forward buttons)
if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}

loadInitializers(App, config.modulePrefix);
