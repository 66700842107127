import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class PreviewsPreviewEditController extends Controller {
  queryParams = ['page'];

  @tracked page = 1;

  @action
  setPage(page) {
    this.page = page || 1;
  }
}
