export default {
  "ember-basic-dropdown": "_ember-basic-dropdown_v154xl",
  "ember-basic-dropdown-trigger": "_ember-basic-dropdown-trigger_v154xl",
  "ember-basic-dropdown-content": "_ember-basic-dropdown-content_v154xl",
  "ember-basic-dropdown-content--above": "_ember-basic-dropdown-content--above_v154xl",
  "ember-basic-dropdown-content--left": "_ember-basic-dropdown-content--left_v154xl",
  "ember-basic-dropdown-content--right": "_ember-basic-dropdown-content--right_v154xl",
  "ember-basic-dropdown-overlay": "_ember-basic-dropdown-overlay_v154xl",
  "ember-basic-dropdown-content-wormhole-origin": "_ember-basic-dropdown-content-wormhole-origin_v154xl"
};
