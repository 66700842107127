import { isEmpty } from '@ember/utils';

export default function checkFieldValidity(fieldValidation) {
  if (!isEmpty(fieldValidation[0]?.validation)) {
    // check if there are validation messages
    return false;
  }

  // check if the field value is empty (should return a false then)
  return !isEmpty(fieldValidation[0]) || !isEmpty(fieldValidation[0]?.value);
}
