import { isEmpty } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateErpSetupIdentifier(options = {}) {
  return async (key, newValue, _oldValue, _changes, content) => {
    let erpBranchSetups = await content?.get('erpBranchSetups');
    if (erpBranchSetups?.length <= 0) {
      return !isEmpty(newValue) || buildMessage(key, { type: 'present', value: newValue, context: options });
    }
    return true;
  };
}
