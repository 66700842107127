import { CUSTOM_MARKETING_CONTENT_KEY } from '@mvb/tix-ui/constants';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import CustomMarketingContentBaseService from '@mvb/tix-ui/services/custom-marketing-content-base';
import validateEditorLinkUrl from '@mvb/tix-ui/validators/editor-link-url';

export default class CustomMarketingContentBookstoreService extends CustomMarketingContentBaseService {
  // overwrite abstract method from base service
  getDataForChangeset(model, isNew = false) {
    let { id, note, webshopLink: webshopLink, tags, type } = model;

    return {
      id: isNew ? null : id,
      isNew,
      note,
      webshopLink,
      tags: tags ?? [],
      type,
    };
  }

  // overwrite abstract method from base service
  getValidationsForChangeset() {
    return {
      webshopLink: validateEditorLinkUrl({ allowBlank: true }),
    };
  }

  @task
  @waitFor
  /* eslint-disable require-yield */
  *updateRecordsAndModelForSavingTask(changeset, model) {
    try {
      for (let change of changeset.changes) {
        switch (change.key) {
          case CUSTOM_MARKETING_CONTENT_KEY.WEBSHOP_LINK: {
            model.set(change.key, change.value);
            break;
          }
          default: {
            break;
          }
        }
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
