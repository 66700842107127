/**
 * updates the position of the passed sections, if we have fixed positions, we start with the highest fixed position + 1
 *
 * @param {*} sections - array of sections that need to be updated
 * @param {*} fixedPositions - array of positions (numbers) for fixed sections
 */
export default function updatePositions(sections, fixedPositions = []) {
  let defaultPosition = 0;
  if (fixedPositions.length > 0) {
    defaultPosition = Math.max(...fixedPositions) + 1;
  }

  for (let [index, section] of sections.entries()) {
    section.position = defaultPosition + index;
  }
}
