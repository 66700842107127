import EmberPromiseModalsService from 'ember-promise-modals/services/modals';
import UiModalConfirm from '@mvb/tix-ui/components/ui/modal/confirm';
import UiModalNotify from '@mvb/tix-ui/components/ui/modal/notify';
import UiModalPrompt from '@mvb/tix-ui/components/ui/modal/prompt';

export default class ModalsService extends EmberPromiseModalsService {
  focusTrapOptions = {
    clickOutsideDeactivates: false,
    returnFocusOnDeactivate: false,
  };

  /**
   * @typedef {Object} ConfirmDialogOptions
   * @property {String} [title] A optional title for the dialog, rendered in the same line as the close button
   * @property {String} message Message describing what needs to be confirmed or cancelled
   * @property {String} confirm
   * @property {String} cancel
   * @property {String} [name] A optional name for the dialog, used for accessing the modal while testing
   */

  /**
   *
   * @param {ConfirmDialogOptions} dialogOptions
   */
  async confirm(dialogOptions) {
    return this.open(UiModalConfirm, dialogOptions);
  }

  /**
   *
   * @param {ConfirmDialogOptions} dialogOptions
   */
  async notify(dialogOptions) {
    return this.open(UiModalNotify, dialogOptions);
  }

  /**
   *
   * @param {ConfirmDialogOptions} dialogOptions
   */
  async prompt(dialogOptions) {
    return this.open(UiModalPrompt, dialogOptions);
  }
}
