import { attr, belongsTo } from '@ember-data/model';
import { BESTSELLER_STATE, BESTSELLER_URLS } from '../constants';
import { service } from '@ember/service';
import Model from './-base';

const multipleChartThemes = ['Sachbuch', 'Belletristik'];

export default class BestsellerModel extends Model {
  @service intl;

  @attr('string') isbn;
  @attr('number') position;
  @attr('string') state;
  @attr('string') chart;
  @attr('string') theme;

  @belongsTo('product', { async: false, inverse: 'bestsellers' }) product;

  hasMultipleCharts() {
    return multipleChartThemes.includes(this.theme);
  }

  get url() {
    if (this.hasMultipleCharts()) {
      return BESTSELLER_URLS[this.theme][this.chart];
    }
    return BESTSELLER_URLS[this.theme];
  }

  get icon() {
    switch (this.state) {
      case BESTSELLER_STATE.UP: {
        return 'arrow-up';
      }
      case BESTSELLER_STATE.SAME: {
        return 'arrow-right';
      }
      case BESTSELLER_STATE.DOWN: {
        return 'arrow-down';
      }
      default: {
        return null;
      }
    }
  }

  get text() {
    if (this.hasMultipleCharts()) {
      return `${this.theme} ${this.additionalText}`;
    }
    return this.theme;
  }

  get additionalText() {
    if (['HC', 'TB', 'PB'].includes(this.chart)) {
      return this.intl.t(`modelBestseller.text.charts.${this.chart}`);
    }
    return '';
  }

  get isNew() {
    return this.state === BESTSELLER_STATE.NEW;
  }
}
