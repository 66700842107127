import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-additional-information-filter={{@filter.name}}>\n  <Ui::Input::Select\n    @disabled={{@disabled}}\n    @hideSelected={{true}}\n    @label={{@filter.text}}\n    @multiple={{true}}\n    @name={{@filter.name}}\n    @onChange={{this.onChange}}\n    @options={{@filter.filterItems}}\n    @placeholder={{t \"searchFilterAdditionalInformationMultiple.placeholder\"}}\n    @value={{@value}}\n  />\n</div>", {"contents":"<div data-test-additional-information-filter={{@filter.name}}>\n  <Ui::Input::Select\n    @disabled={{@disabled}}\n    @hideSelected={{true}}\n    @label={{@filter.text}}\n    @multiple={{true}}\n    @name={{@filter.name}}\n    @onChange={{this.onChange}}\n    @options={{@filter.filterItems}}\n    @placeholder={{t \"searchFilterAdditionalInformationMultiple.placeholder\"}}\n    @value={{@value}}\n  />\n</div>","moduleName":"@mvb/tix-ui/components/search/filter/additional-information-multiple/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/additional-information-multiple/index.hbs"}});
import { action } from '@ember/object';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterAdditionalInformationMultipleIndexComponent extends SearchFilterBaseComponent {
  @action
  onChange(value) {
    this.search({
      [this.args.filter.name]: value,
    });
  }
}
