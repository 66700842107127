import { assert, runInDebug } from '@ember/debug';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import window from 'ember-window-mock';

/**
 * @typedef {Object} ValidateAssetFileTypeOptions
 * @property {Set.<String>} types the types to check for as Set, e.g. new Set(['image/jpeg', 'image/png']);
 * @property {String} typeDescription the description to use in the placeholder for the validation message
 */

/**
 * it validates if the given asset file has the correct types
 * @param {ValidateAssetFileTypeOptions} options
 */
export default function validateAssetFileType(options = {}) {
  runInDebug(() => {
    assert('`acceptedMimeTypes` must be a Set() of accepted mime types', options?.acceptedMimeTypes instanceof Set);
    assert('`typeDescription` must be set', options?.typeDescription);
  });

  return (key, newValue /* , oldValue, changes, content */) => {
    let isFile = newValue instanceof window.File;
    let isOfAcceptedMimeType = options.acceptedMimeTypes.has(newValue?.type);

    if (!newValue || !isFile || isOfAcceptedMimeType) {
      return true;
    }

    return buildMessage(key, { type: 'isValidFileType', value: newValue, context: options });
  };
}
