import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Modal::Base\n  data-test-modal-thalia-import\n  @title={{t (concat @data.translationPath \".text.title\")}}\n  @onClose={{fn @close false}}\n>\n  <p data-test-description>\n    {{t (concat @data.translationPath \".text.body\")}}\n  </p>\n  <div local-class=\"input-fields\">\n    <Ui::Input::File::Excel\n      data-test-file-upload\n      local-class=\"input-file\"\n      @onChange={{this.onFileUploadSuccess}}\n      @onError={{this.onFileUploadError}}\n      @onUpload={{this.onFileUploadStart}}\n      @overlayProgressEnabled={{true}}\n      @overlayProgressMessage={{t (concat @data.translationPath \".notification.uploadStart\")}}\n      @payload={{this.payload}}\n      @url={{this.uploadUrl}}\n    />\n  </div>\n</Ui::Modal::Base>", {"contents":"<Ui::Modal::Base\n  data-test-modal-thalia-import\n  @title={{t (concat @data.translationPath \".text.title\")}}\n  @onClose={{fn @close false}}\n>\n  <p data-test-description>\n    {{t (concat @data.translationPath \".text.body\")}}\n  </p>\n  <div local-class=\"input-fields\">\n    <Ui::Input::File::Excel\n      data-test-file-upload\n      local-class=\"input-file\"\n      @onChange={{this.onFileUploadSuccess}}\n      @onError={{this.onFileUploadError}}\n      @onUpload={{this.onFileUploadStart}}\n      @overlayProgressEnabled={{true}}\n      @overlayProgressMessage={{t (concat @data.translationPath \".notification.uploadStart\")}}\n      @payload={{this.payload}}\n      @url={{this.uploadUrl}}\n    />\n  </div>\n</Ui::Modal::Base>","moduleName":"@mvb/tix-ui/components/modals/modal-thalia-import/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/modals/modal-thalia-import/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import config from '@mvb/tix-ui/config/environment';

export default class ModalsModalThaliaImportIndexComponent extends Component {
  @service intl;
  @service notifications;

  get payload() {
    return {
      dispoListId: this.args.data.dispoListId,
      previewListId: this.args.data.previewListId,
    };
  }

  get uploadUrl() {
    return `${config.APP.api}${this.args.data.endpoint}`;
  }

  @action
  onFileUploadError() {
    let message = this.intl.t(`${this.args.data.translationPath}.notification.uploadError`);
    this.notifications.error(message, { autoClear: false });
  }

  @action
  onFileUploadStart() {
    this.args.close?.();
  }

  @action
  onFileUploadSuccess() {
    let message = this.intl.t(`${this.args.data.translationPath}.notification.uploadSuccess`);
    this.notifications.success(message, { autoClear: false });
  }
}
