import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class CompaniesCompanyIndexRoute extends Route {
  @service metrics;

  async model() {
    return this.modelFor('companies/company');
  }

  afterModel(model) {
    let {
      party: { id, mvbId, name },
    } = model;

    let data = {
      area: 'Unternehmensprofil',
      title: {
        'COMPANY.MVBID': mvbId,
        'COMPANY.NAME': name,
        'COMPANY.URLCODE': id,
      },
    };

    this.metrics.trackSpecific(data);

    return super.afterModel(...arguments);
  }
}
