import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class PreviewListAbility extends Ability {
  @service user;

  get handleReferenceProducts() {
    return this.user.hasPermission(PARTY_PERMISSIONS.THALIA_IMPORT_EXPORT_REFERENCE_PRODUCTS);
  }
}
