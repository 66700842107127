import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import ModalTransferUserRelatedData from '@mvb/tix-ui/components/user/modal-transfer-user-related-data';
import Service, { service } from '@ember/service';

export default class UsersService extends Service {
  @service api;
  @service errors;
  @service modals;

  @task({ drop: true })
  *deleteUserTask({ changeset }) {
    let transferPreviewToUserId = changeset.get('transferPreviewToUserId');
    let transferTixDataToUserId = changeset.get('transferTixDataToUserId');
    let userId = changeset.get('user.id');
    let data = {};

    if (transferPreviewToUserId) {
      data.transferPreviewToUserId = transferPreviewToUserId;
    }

    if (transferTixDataToUserId) {
      data.transferTixDataToUserId = transferTixDataToUserId;
    }

    try {
      yield this.api.deleteJSON(`/user/${userId}/delete`, data);
      changeset.rollback();
      return true;
    } catch (error) {
      this.errors.handle(error);
      return false;
    }
  }

  @action
  async deleteUser(user) {
    let changeset = createChangeset({
      transferPreviewToUserId: null,
      transferTixDataToUserId: null,
      user,
    });

    let result = await this.modals.open(ModalTransferUserRelatedData, {
      changeset,
    });

    if (!result) {
      changeset.rollback();
      return;
    }

    return await this.deleteUserTask.perform({ changeset });
  }
}
