import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import saveBlob from '@mvb/tix-ui/utils/save-blob';

export default class BackofficeExportsIndexController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service notifications;

  get anyExportRunning() {
    return this.kpiExportTask.isRunning || this.contractStatusExportTask.isRunning;
  }

  @task({ drop: true })
  @waitFor
  *contractStatusExportTask() {
    try {
      let { attachmentName, blob } = yield this.api.postJSON('/party/contract-status-report');
      saveBlob(blob, attachmentName);
      this.notifications.success(this.intl.t('backofficeExportsIndex.notification.success'));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  @waitFor
  *kpiExportTask() {
    try {
      let { attachmentName, blob } = yield this.api.postJSON('/party/kpi-report');
      saveBlob(blob, attachmentName);
      this.notifications.success(this.intl.t('backofficeExportsIndex.notification.success'));
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
