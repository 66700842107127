import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import Modifier from 'ember-in-viewport/modifiers/in-viewport';

export default class InViewportModifier extends Modifier {
  @service inViewport;

  constructor() {
    super(...arguments);

    registerDestructor(this, (instance) => {
      this.destroyWatcher(instance.element);
      instance.element = null;
    });
  }

  // overwrite default implementation to allow usage of in-viewport in "each" lists for rerendered elements
  setupWatcher(element) {
    this.inViewport.watchElement(element, this.options, this.onEnter, this.onExit);
    this.lastOptions = this.options;
  }
}
