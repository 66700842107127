import { loopArray } from '@mvb/tix-ui/utils/loop';

// Find the next item in a list that doesn't have disabled:true
export function nextEnabledIndex(list, start, direction = 1) {
  if (list.length === 0) {
    return -1;
  }

  let index = loopArray(list, start, direction);

  while (index !== start) {
    if (!list[index].disabled) {
      return index;
    }

    index = loopArray(list, index, direction);
  }

  return -1;
}

export function normalizeKeyEvent(event) {
  let input = event.target;
  let value = input.textContent.trim();

  switch (event.key) {
    case 'ArrowDown': {
      event.preventDefault();
      return { type: 'down', value };
    }
    case 'ArrowUp': {
      event.preventDefault();
      return { type: 'up', value };
    }
    case 'Enter': {
      return { type: 'select', value };
    }
    case 'Escape': {
      event.preventDefault();
      return { type: 'escape', value };
    }
    default: {
      return { type: 'default', value };
    }
  }
}
