import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import emailValidations from '../validations/email';

// this validator checks if the email already belongs to a bo-user
export default function userIsNotBoUserValidator(options = {}) {
  return async (key, newValue, oldValue /*, changes, content*/) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let apiService = applicationUtil.lookup('service:api');
    let intlService = applicationUtil.lookup('service:intl');
    let errorsService = applicationUtil.lookup('service:errors');

    let email = newValue?.trim();

    // return quickly if the email is invalid, saves on API calls
    let isInvalidEmail = emailValidations.email.map((v) => v('email', email)).find((b) => b !== true);
    if (isInvalidEmail) {
      return true;
    }

    if (newValue === oldValue) {
      return true;
    }

    try {
      let boUsers = await apiService.get(
        `/jsonapi/users?filter%5Busername%5D=${encodeURIComponent(email)}&filter%5Broles%5D=TIX_BO&page%5Bnumber%5D=1`
      );
      if (boUsers.data.length > 0) {
        return buildMessage(key, { type: 'userIsNotBoUser', email, context: options });
      }
    } catch (error) {
      let message = error.messages?.find((m) => m.code?.includes('user.exists'));
      if (message && intlService.exists(message.code)) {
        return buildMessage(key, { message: intlService.t(message.code, { email }) });
      }

      errorsService.handle(error);
      return intlService.t('error.generic');
    }

    return true;
  };
}
