import { isEmpty } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateErpSetupBranchIdentifierLength(options = {}) {
  return async (key, newValue) => {
    let selectedErp = options.erpService.erp;

    if (isEmpty(selectedErp) || isEmpty(newValue)) {
      return true;
    }

    let maxLength = selectedErp.orderFileFormat === 'BWA' ? 10 : 15;

    if (newValue.length > maxLength) {
      return buildMessage(key, {
        type: 'tooLong',
        value: newValue,
        context: { description: options.description, max: maxLength },
      });
    }

    return true;
  };
}
