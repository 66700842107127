import { service } from '@ember/service';
import Helper from '@ember/component/helper';

/**
 * With this helper we can easily access the translation for a given code from a given codelist in a template itself.
 *
 * A codelist is a list of objects with a code and a description/translation. As some of the data we get from the API is provided as a code, we need to display the translation.
 * This can be done via this helper (in templates) or via the intl service (in components, controllers, etc.).
 *
 * The structure we need to get the right translation for the given list/code combination was introduced in {@link packages\tix-ui\app\services\codelists.js}.
 * In this service all the needed codelists for this application are defined and will be added to the translation files on build time, so we can access them anywhere in the application when needed.
 *
 * @param {Array} list - The number of the codelist we want the translation from
 * @param {Array} code - The code that needs to be translated
 * @returns {String} The translation for the given code from the given code list or the code itself if no translation is available
 */
export default class CodeListHelper extends Helper {
  @service intl;

  compute([list, code]) {
    try {
      let key = `cl_${list}_${code}`;
      let description = this.intl.t(key);
      return description.includes('Missing translation for key "cl_') ? code : description;
    } catch (error) {
      // an error will be thrown in development and test environments, see packages\tix-ui\app\utils\intl\missing-message.js
      return error.message.replace('[ember-intl] ', '');
    }
  }
}
