import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

/**
 * @typedef {Object} ProgressOptions
 * @property {string} [title]
 * @property {string} [message]
 * @property {number} [step]
 * @property {number} [steps]
 */

export class ProgressState {
  @tracked title = this.options?.title ?? '';
  @tracked message = this.options?.message ?? '';
  @tracked step = this.options?.step ?? 0;
  @tracked steps = this.options?.steps ?? 1;

  /**
   *
   * @param {ProgressOptions} options
   */
  constructor(options, removeCallback) {
    this.options = options || {};
    this.id = guidFor(this);
    this.removeCallback = removeCallback;
  }

  get isComplete() {
    return this.step === this.steps;
  }

  get percent() {
    return (this.step / this.steps) * 100;
  }

  /**
   *
   * @param {ProgressOptions} options
   */
  update(options = {}) {
    this.title = options?.title ?? this.title;
    this.message = options?.message ?? this.message;
    this.step = options?.step ?? this.step;
    this.steps = options?.steps ?? this.steps;
  }

  /**
   * Update the progress state & increase the step by 1. Removes the progress state if the step is equal to the steps.
   *
   * @param {ProgressOptions} options
   */
  next(options) {
    this.update(options);
    this.step = Math.min(this.step + 1, this.steps);

    if (this.step === this.steps) {
      this.remove();
    }
  }

  remove() {
    this.removeCallback(this);
  }
}

export default class ProgressService extends Service {
  @tracked queue = [];

  /**
   *
   * @param {ProgressOptions} options
   * @return {ProgressState}
   */
  add(options) {
    let progress = new ProgressState(options, this._remove.bind(this));

    this.queue = [progress, ...this.queue];

    return progress;
  }

  clear() {
    this.queue = [];
  }

  _remove(instance) {
    this.queue = this.queue.filter((state) => state !== instance);
  }

  get current() {
    return this.queue[0];
  }
}
