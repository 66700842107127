import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class ErpSalesSetupModel extends Model {
  @attr active;
  @attr username;
  @attr password;

  @belongsTo('party-setting', { async: false, inverse: 'erpSalesSetup' }) partySettings;

  @hasMany('erp-sales-branch-setup', { async: false, inverse: 'erpSalesSetup' }) erpSalesBranchSetups;
}
