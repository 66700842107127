import { didCancel } from 'ember-concurrency';
import { isEmpty, isPresent } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';

export default function validateEan(options = {}) {
  return async (key, newValue, oldValue) => {
    newValue = newValue?.trim() ?? newValue;

    if (!applicationUtil.hasInstance || options.disabled || isEmpty(newValue)) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let mdsService = applicationUtil.lookup('service:mds');

    try {
      let validationMessages = await mdsService.validateEansTask.perform(newValue, oldValue);

      // little workaround as the oldValue sometimes does not get updated
      mdsService.oldEanValue = newValue;

      return isPresent(validationMessages) ? validationMessages.join(' ') : true;
    } catch (error) {
      if (!didCancel(error)) {
        return intlService.t('error.generic');
      }
    }
  };
}
