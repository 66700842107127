export default {
  "modal": "_modal_2azjcz",
  "spinner": "_spinner_2azjcz",
  "body": "_body_2azjcz",
  "group": "_group_2azjcz",
  "results": "_results_2azjcz",
  "list": "_list_2azjcz reset-list",
  "info": "_info_2azjcz",
  "divider": "_divider_2azjcz",
  "actions": "_actions_2azjcz",
  "group-edit": "_group-edit_2azjcz",
  "group-add": "_group-add_2azjcz",
  "group-header": "_group-header_2azjcz"
};
