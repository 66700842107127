import { isChangeset } from 'validated-changeset';
import { isPresent } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validatePermissionSetNameUniqueness(options = {}) {
  return async (key, newValue, _oldValue, _changes, content) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');

    let permissionSets = options?.permissionSets || [];
    let value = String(newValue).trim().toLowerCase();

    let permissionSetNames = [];
    for (let permissionSet of filterPermissionSets(permissionSets, content)) {
      if (isChangeset(permissionSet)) {
        permissionSetNames.push(
          String(permissionSet.get('name')).trim().toLowerCase(),
          String(permissionSet.get('label')).trim().toLowerCase()
        );
      }
      permissionSetNames.push(
        String(permissionSet.name).trim().toLowerCase(),
        String(permissionSet.label).trim().toLowerCase()
      );
    }

    if (permissionSetNames.includes(value)) {
      return buildMessage(key, {
        message: intlService.t('validation.uniqueness.notUnique', {
          gender: 'other',
          value: intlService.t('partyPermissions.text.permissionSet'),
        }),
      });
    }

    return true;
  };
}

function filterPermissionSets(permissionSets, content) {
  return permissionSets.filter((permissionSet) => {
    if (isChangeset(permissionSet)) {
      return permissionSet.data !== content && isPresent(permissionSet.get('name'));
    }
    return permissionSet !== content && isPresent(permissionSet.name);
  });
}
