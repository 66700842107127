import { PREVIEW_STATUS, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class PreviewsAddRoute extends Route {
  @service abilities;
  @service router;
  @service seasons;
  @service('section-contents-preview') sectionContents;
  @service session;
  @service store;
  @service user;

  intlNamespace = 'previewsAdd';

  queryParams = {
    page: {
      refreshModel: false,
    },
    type: {
      refreshModel: true,
    },
    cmcType: {
      refreshModel: true,
    },
  };

  activate() {
    super.activate();
    this.sectionContents.activate(...arguments);
  }

  deactivate() {
    this.sectionContents.deactivate(...arguments);
    super.deactivate();
  }

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (!this.session.requireAuthentication(transition)) {
      return;
    }

    if (this.abilities.cannot('preview.create')) {
      this.router.transitionTo('previews');
      // FIXME: implement this as an error when https://github.com/emberjs/ember.js/issues/14875 is fixed
    }
  }

  async model({ type, cmcType }) {
    let selectedParty = await this.user.selectedParty?.party;

    let previewInfo = this.store.createRecord('preview-info');

    let infoSection = this.store.createRecord('section', {
      type: SECTION_TYPES.INFO,
      position: 0,
      previewInfo,
    });

    let referenceProductsSection = this.store.createRecord('section', {
      type: SECTION_TYPES.REFERENCETITLE,
      position: 1,
    });

    return this.store.createRecord('preview', {
      type,
      cmcType,
      sender: selectedParty,
      status: PREVIEW_STATUS.DRAFT,
      sections: [infoSection, referenceProductsSection],
      creatorName: this.user.current?.fullName,
      creatorId: this.user.current?.id,
    });
  }

  setupController(_, model) {
    super.setupController(...arguments);
    this.sectionContents.setupController(model, this.intlNamespace);
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
    this.sectionContents.resetController(...arguments);
  }
}
