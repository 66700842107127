import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { USER_ROLES, USER_STATUS } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import orderBy from 'lodash-es/sortBy';

export default class BackofficeUsersDetailController extends Controller {
  @service abilities;
  @service api;
  @service errors;
  @service intl;
  @service location;
  @service notifications;
  @service progress;
  @service router;
  @service session;
  @service user;
  @service users;

  queryParams = ['sort'];

  @tracked sort = 'party.name';

  get title() {
    return this.intl.t('backofficeUsersDetail.title', { name: this.model.fullName });
  }

  get isActiveUser() {
    return this.model.status === USER_STATUS.CONFIRMED;
  }

  get sortedAssignedParties() {
    if (!this.sort || !this.model?.assignedParties?.length) {
      return this.model.assignedParties;
    }

    return this.sort.startsWith('-')
      ? orderBy(this.model.assignedParties, [this.sort.split('-')[1]], ['desc'])
      : orderBy(this.model.assignedParties, [this.sort]);
  }

  @action
  async deleteUser() {
    try {
      let wasDeletionSuccessful = false;

      if (this.model.roles.includes(USER_ROLES.TIX_BO)) {
        this.model.roles = [USER_ROLES.TIX_BO];
        await this.model.save();
        wasDeletionSuccessful = true;
      } else if (
        this.model.isCorporateUser &&
        this.abilities.can('party.deleteUser', { assignedPartiesOfUserToDelete: this.model.assignedParties })
      ) {
        wasDeletionSuccessful = await this.users.deleteUser(this.model);
      } else {
        await this.model.destroyRecord();
        wasDeletionSuccessful = true;
      }

      if (wasDeletionSuccessful) {
        this.notifications.success(
          this.intl.t('backofficeUsersDetail.notifications.success', { user: this.model.fullName })
        );
        this.router.transitionTo('backoffice.users');
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  @waitFor
  *approveForBackofficeTask() {
    try {
      yield this.api.post(`/user/${this.model.id}/approve`);
      yield this.router.refresh();
      this.notifications.success(
        this.intl.t('backofficeUsersDetail.notifications.approved', { user: this.model.fullName })
      );
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  approveForBackoffice() {
    this.approveForBackofficeTask.perform();
  }

  @action
  async takeIdentity() {
    let progress = this.progress.add({
      message: this.intl.t('backofficeUsersDetail.progress.impersonate', {
        fullName: this.model.fullName,
      }),
    });

    try {
      await this.session.authenticate('authenticator:application', { userId: this.model.id });
      await this.location.goToRoute('index');
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }
  }
}
