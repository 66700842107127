import * as Sentry from '@sentry/ember';
import { all, didCancel, task } from 'ember-concurrency';
import { camelize } from '@ember/string';
import { DateTime } from 'luxon';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import ENV from '@mvb/tix-ui/config/environment';
import Service from 'ember-simple-auth/services/session';
import window from 'ember-window-mock';

const LAST_USER_STORAGE_KEY = 'tix-last-user';
const TIX_BANNER_STORAGE_KEY_MUTED_SINCE = 'tix-banner-muted-since';
const TIX_BANNER_STORAGE_KEY_MUTED_VERSION = 'tix-banner-muted-version';
const TIX_BANNER_STORAGE_KEY_ACKNOWLEDGED_VERSION = 'tix-banner-acknowledged-version';
const BANNER_TIMEOUT = 14400000; // 4 hours

export default class SessionService extends Service {
  @service api;
  @service banner;
  @service codelists;
  @service errors;
  @service features;
  @service intl;
  @service metrics;
  @service modals;
  @service progress;
  @service router;
  @service user;
  @service userState;

  @tracked _impersonatorId = null;
  @tracked _userId = null;

  get isImpersonated() {
    return !isEmpty(this._impersonatorId);
  }

  get _lastUserId() {
    return window.localStorage.getItem(LAST_USER_STORAGE_KEY) ?? null;
  }

  set _lastUserId(value) {
    if (value === null) {
      window.localStorage.removeItem(LAST_USER_STORAGE_KEY);
    } else {
      window.localStorage.setItem(LAST_USER_STORAGE_KEY, value);
    }
  }

  async handleApplicationLoad() {
    this._setSessionValues();

    await all([this.loadFeatureFlags(), this.codelists.load(), this.user.load(), this.userState.load()]);

    if (this.user.current) {
      this.checkPostLoginBannerTask.perform();
    }
    this._setupMetrics();
  }

  async handleAuthentication() {
    let wasImpersonated = this.isImpersonated;

    this._setSessionValues();

    let isImpersonated = this.isImpersonated;

    if (isImpersonated !== wasImpersonated) {
      return;
    }

    await super.handleAuthentication(...arguments);
    await all([this.user.load(), this.userState.load()]);

    this.checkPostLoginBannerTask.perform(true);
    this._setupMetrics();
    this._lastUserId = this._userId;
  }

  async handleInvalidation() {
    await super.handleInvalidation(...arguments);

    this.metrics.identify({ userId: null });
    this._lastUserId = null;
    window.localStorage.removeItem(LAST_USER_STORAGE_KEY);
    window.localStorage.removeItem(TIX_BANNER_STORAGE_KEY_MUTED_SINCE);
    window.localStorage.removeItem(TIX_BANNER_STORAGE_KEY_ACKNOWLEDGED_VERSION);
  }

  requireAuthentication(transition, routeNameOrCallback) {
    if (!routeNameOrCallback) {
      routeNameOrCallback = 'login';
    }

    return super.requireAuthentication(transition, routeNameOrCallback);
  }

  _setSessionValues() {
    // Needs to be done this way as changes to the session don't seem to happen in a tracked context
    this._impersonatorId = this.data?.authenticated?.impersonatorId;
    this._userId = this.data?.authenticated?.userId;
  }

  _setupMetrics() {
    let userId = this._userId;

    if (this.isImpersonated) {
      userId = this._impersonatorId;
    }

    if (!userId) {
      Sentry.setUser(null);
      this.metrics.unidentify();

      return;
    }

    Sentry.setUser({ id: userId, username: this.user.current?.firstname });
    this.metrics.identify({ userId });
  }

  @task({ drop: true })
  *checkPostLoginBannerTask(ignoreAcknowledgement = false) {
    let mutedSince = window.localStorage.getItem(TIX_BANNER_STORAGE_KEY_MUTED_SINCE);
    let mutedVersion = Number.parseInt(window.localStorage.getItem(TIX_BANNER_STORAGE_KEY_MUTED_VERSION));
    let acknowledgedVersion = Number.parseInt(window.localStorage.getItem(TIX_BANNER_STORAGE_KEY_ACKNOWLEDGED_VERSION));

    try {
      yield this.banner.load();
      if (this.banner.active) {
        // bail out if version of active banner was already acknowledged
        if (!ignoreAcknowledgement && acknowledgedVersion === this.banner.active.version) {
          return;
        }

        // bail out if version of active banner was already muted in the last 4 hours
        if (
          !ignoreAcknowledgement &&
          mutedVersion === this.banner.active.version &&
          mutedSince &&
          Date.now() - mutedSince < BANNER_TIMEOUT
        ) {
          return;
        }

        let confirmed = yield this.banner.displayBanner();

        if (confirmed) {
          window.localStorage.setItem(TIX_BANNER_STORAGE_KEY_ACKNOWLEDGED_VERSION, this.banner.active.version);
        } else {
          window.localStorage.setItem(TIX_BANNER_STORAGE_KEY_MUTED_VERSION, this.banner.active.version);
          window.localStorage.setItem(TIX_BANNER_STORAGE_KEY_MUTED_SINCE, Date.now());
        }
      }
    } catch (error) {
      //  no notification, no sentry entry
      this.errors.handle(error, { showNotification: false, skipSentry: true });
    }
  }

  async loadFeatureFlags() {
    try {
      await this.loadFeatureFlagsTask.perform();
    } catch (error) {
      if (!didCancel(error)) {
        this.errors.handle(error);
      }
    }
  }

  // This belongs in the "features" service, but trying to extend that leads to recursion errors
  @task({ drop: true })
  @waitFor
  *loadFeatureFlagsTask() {
    let response = yield this.api.get('/feature-flags');
    let flags = new Object(null);
    let features = [];

    if (Array.isArray(response)) {
      for (let { uid: name, description, enable: isEnabled, customProperties } of response) {
        let creationDate = customProperties[`${name}-creation-date`]?.value;
        // Ensure the creation date is a valid DateTime, changing "YYYY-MM-DD HH:mm" to "YYYY-MM-DDT00:00"
        creationDate = creationDate ? DateTime.fromISO(creationDate.replace(' ', 'T')) : null;
        name = camelize(name);
        features.push({ name, description, isEnabled, creationDate });
        flags[name] = isEnabled;
      }
    }

    this.features.features = features; // used in backoffice/featureflags route to render a table of all feature flags

    if (ENV.environment === 'test') {
      return;
    }

    // Running .setup() will override existing flags, which will override flags enabled by a test helper
    this.features.setup(flags);
  }
}
