export default {
  "filters": "_filters_19wc3o",
  "container": "_container_19wc3o _container_1edbot",
  "table": "_table_19wc3o _table_1edbot",
  "thead": "_thead_19wc3o _thead_1edbot",
  "tbody": "_tbody_19wc3o _tbody_1edbot",
  "tr": "_tr_19wc3o _tr_1xynbj",
  "user-amount": "_user-amount_19wc3o",
  "full-name": "_full-name_19wc3o",
  "no-permissions-icon": "_no-permissions-icon_19wc3o",
  "notice": "_notice_19wc3o"
};
