import { attr, belongsTo } from '@ember-data/model';
import { PREVIEW_EDIT_STATE } from '@mvb/tix-ui/constants';
import Model from './-base';

export default class PreviewEditStatusModel extends Model {
  @attr({
    defaultValue: PREVIEW_EDIT_STATE.NEW,
  })
  status;

  @belongsTo('user', { async: false, inverse: null }) user;
  @belongsTo('search-preview', { async: true, inverse: 'previewEditStatus' }) searchPreview;
}
