import Helper from '@ember/component/helper';

export default class AtHelper extends Helper {
  compute([separator, array]) {
    if (!Array.isArray(array)) {
      array = [array];
    }
    return (array ?? [])?.join?.(separator);
  }
}
