import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class BranchRecommendedOrderAmountModel extends Model {
  @attr() recommendedOrderAmount;

  @belongsTo('bookstore-branch', { async: false, inverse: null }) branch;
  @belongsTo('custom-marketing-content', { async: false, inverse: 'branchRecommendedOrderAmounts' })
  customMarketingContent;
}
