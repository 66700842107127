import { attr } from '@ember-data/model';
import Model from './-base';

export default class BannerModel extends Model {
  @attr('boolean') active;
  @attr('string') locale;
  @attr('string') header;
  @attr('string') body;
  @attr() version;
}
