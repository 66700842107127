import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchDispoListsService extends SearchBaseService {
  @service store;
  @service user;

  _maxEntitiesForSearch = Number.POSITIVE_INFINITY;

  get queryParamsDefaults() {
    return {
      page: 1,
      sort: '-lastUpdated',
    };
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortKeys = ['created', 'lastUpdated', 'name', 'owner', 'productCount', 'priceSum', 'defaultByUser'];
    return [{ value: null }].concat(...sortKeys.map((value) => [{ value }, { value: `-${value}` }]));
  }

  createSearchQuery({ queryParams }) {
    let { page, size, sort } = queryParams;
    let query = {
      include: 'owner',
      page: {
        number: page,
        size,
      },
    };

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('dispo-list', query);
  }
}
