import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';
import emailValidations from '@mvb/tix-ui/validations/email';
import validateUserIsNotBoUser from '@mvb/tix-ui/validators/user-is-not-bo-user';

export default {
  username: [...emailValidations.email, validateUserIsNotBoUser()],
  gender: [validatePresence({ presence: true, description: 'intl:validation.user.description.gender' })],
  firstname: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.firstname' }),
    validateLength({ max: 50, description: 'intl:validation.user.description.firstname' }),
  ],
  lastname: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.lastname' }),
    validateLength({ max: 50, description: 'intl:validation.user.description.lastname' }),
  ],
};
