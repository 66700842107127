import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import { isChangeset } from 'validated-changeset';
import { isEmpty, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import ENV from '@mvb/tix-ui/config/environment';
import Service, { service } from '@ember/service';
import styles from '@mvb/tix-ui/components/ui/input/changeset-errors.css';
import window from 'ember-window-mock';

export class SectionContentValidationError extends Error {}

/**
 * Base-service for common edit-operations on models that contain sections and sectionContents.
 * Specialised services per logical entities (e.g. previews, common products, promotionalPackages etc.) will handle specific functionality for saving, publishing etc.
 *
 * Responsibilities of this base-service include:
 *  * error-handling: all sub-services are expected to throw exceptions in overloaded methods that will be handled here
 *      to ensure proper transitions in state-machines, consistency on handling the current progress-banner and notifications shown to the user
 *  * stateMachine-transitions: transitions between states are handled here as far as possible as the saving-processes are all following the same stateMachine.
 *      should this change in the future, state-handling might need to be moved to the extending sub-services.
 *  * watching route-transitions and prompting the user for possibly unsaved changes
 *
 *  Responsibilities of the extending sub-services include:
 *    * injecting the model and intlNamespace
 *    * filling in the specific logic for saving, publishing, unpublishing or cancelling changes
 *    * optionally transition to new routes or reloading models after the tasks mentioned above if necessary
 */
export default class SectionContentsBaseService extends Service {
  @service api;
  @service errors;
  @service intl;
  @service jump;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service store;
  @service user;

  @tracked modifiedRecords = new Set();
  @tracked parentMachine = null;
  @tracked productIsDeleting = false;

  /**
   * @typedef SectionOwner
   * @property {SectionModel[]} sections - sections with nested {@link SectionContentModel}s that are the base of all the processes in this service
   * @property {SectionModel[]} draftSections - getter that filters the sections by DRAFT-status
   * @property {string} publicationDate - date that can be used to determine if the model should be published directly with the publish-action or on a fixed future date
   *
   * The model of the currently active route which injects the SectionContentsService
   * @type {SectionOwner}
   */
  model = null;

  intlNamespace = '';
  storedTransition = null;

  /**
   * The current progress state of the saving process
   * @type {ProgressState}
   */
  progressState = null;

  // ==== Route Delegates (enforced via tix-ui/delegates ESLint rule) ====

  /**
   * Must be called from the Route class that injects the service
   */
  activate() {
    window.addEventListener('beforeunload', this.beforeunload);
  }

  /**
   * Must be called from the Route class that injects the service
   */
  deactivate() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  /**
   * Must be called from the Route class that injects the service
   */
  resetController() {
    this.modifiedRecords = new Set();
    this.model = null;
  }

  constructor() {
    super(...arguments);
    this.router.on('routeWillChange', this, this._routeWillChange);
  }

  willDestroy() {
    this.router.off('routeWillChange', this, this._routeWillChange);
    super.willDestroy(...arguments);
  }

  @action
  beforeunload(event) {
    let selectedPartySyncRequired = window.localStorage.getItem('sync-selected-party') !== this.user.selectedParty;

    // Selected Party needs to be synced, return early to prevent the forced refresh that needs to happen to do so.
    if (selectedPartySyncRequired) {
      return;
    }

    if (!this.hasChanges) {
      return;
    }

    event.preventDefault();
  }

  // state machine getters

  get canValidate() {
    return this.parentMachine?.state.nextEvents.includes('VALIDATE');
  }

  get canSave() {
    return (
      this.parentMachine?.state.nextEvents.includes('VALIDATE') || this.parentMachine?.state.nextEvents.includes('SAVE')
    );
  }

  get canPublish() {
    let modelIsNotNew = !this.model?.isNew;
    let machineCanPublish = this.parentMachine?.state.nextEvents.includes('PUBLISH');

    return (
      this.canSave ||
      (this.model?.isDraft && modelIsNotNew && machineCanPublish) ||
      (this.hasUnpublishedSections && modelIsNotNew && machineCanPublish)
    );
  }

  get canUnPublish() {
    return this.model?.isPublished && this.parentMachine?.state.nextEvents.includes('UNPUBLISH');
  }

  get hasPendingTasks() {
    return this.parentMachine?.state.context.pendingCount;
  }

  get dirtyMachineSections() {
    return (
      this.parentMachine?.state.context.sections.filter((section) => section.state.value.changesState === 'dirty') ?? []
    );
  }

  // ==== Implementation

  // old getters
  get hasChanges() {
    if (this.parentMachine) {
      return this.canValidate;
    }

    // Fall back to the old logic if the state machine is not present
    for (let changeset of this.modifiedRecords) {
      if (changeset.isDirty) {
        return true;
      }
    }

    return this.modifiedSections.length > 0;
  }

  get modifiedSections() {
    return (
      this.model?.sortedSections?.filter(
        (section) => section.isNew || section.isDeleted || (section.hasDirtyAttributes && !section.isNew)
      ) ?? []
    );
  }

  get changesAreInvalid() {
    for (let changeset of this.modifiedRecords) {
      if (changeset.isInvalid) {
        return true;
      }
    }

    return false;
  }

  get hasUnpublishedSections() {
    return !isEmpty(this.model?.draftSections);
  }

  /**
   * This task validates all changesets in section contents. If a validation fails, an error warning notification is thrown.
   * @returns {Boolean} are all changes valid?
   */
  @task
  @waitFor
  *validateAllTask() {
    //if we have a parentMachine and are not in a state where validation is necessary, there are no changes that need validation
    if (this.parentMachine && !this.canValidate) {
      return true;
    }
    this.parentMachine?.send('VALIDATE');
    try {
      for (let changeset of this.modifiedRecords) {
        yield changeset.validate?.();

        if (changeset.isInvalid) {
          throw new SectionContentValidationError();
        }
      }
      for (let machineSection of this.dirtyMachineSections) {
        let sectionComponent = yield machineSection.state.context.component;

        // probe if the section component has a validate method, and if so run it
        let sectionIsValid = (yield sectionComponent?.validate?.()) ?? true;
        if (!sectionIsValid) {
          throw new SectionContentValidationError();
        }
      }
      return true;
    } catch (error) {
      this.handleErrors(error);
      /*
      Propagating the error may lead to simpler and cleaner code, but due to a bug in ember-qunit,
      this will lead to global issues in tests: https://github.com/emberjs/ember-qunit/issues/592
       */
      return false;
    }
  }

  /**
   * This task is used to collect and commit changes into the `modifiedRecords` to prepare them for saving.
   * @fires childMachine#COMMIT - for each childMachine whose changes have been successfully prepared to being saved
   * @fires parentMachine#REJECT - if any errors occurred during committing
   */
  @task
  @waitFor
  *commitAllTask() {
    yield this.commitProductSectionsTask.perform();

    for (let machineSection of this.dirtyMachineSections) {
      let sectionComponent = yield machineSection.state.context.component;
      // now adding the component changeset to the modifiedRecords
      if (sectionComponent?.changeset) {
        this.addRecordsForSave(sectionComponent.changeset);
      }
      machineSection?.send('COMMIT');
    }
  }

  @task
  @waitFor
  *commitProductSectionsTask() {
    // add product section contents to modified records
    let productsSection = yield this.dirtyMachineSections.find(
      (section) => section.state.context.component?.allSectionContents
    );

    if (productsSection) {
      let productRecords = productsSection.state.context.component.allSectionContents;
      for (let record of productRecords) {
        if (record.isNew || record.isDeleted || record.hasDirtyAttributes) {
          this.modifiedRecords.add(record);
        }
      }
      productsSection.send('COMMIT');
    }
  }

  /**
   * Adds valid changes to the Set of modified Records
   * @param {(Array|Object)} records - single record or list of records to save.
   * @param {Boolean} checkForPristine - if true, only records that are not pristine will be added to the set
   */
  addRecordsForSave(records, checkForPristine = true) {
    if (Array.isArray(records)) {
      for (let record of records) {
        if (record?.isPristine && checkForPristine) {
          continue;
        }
        this.modifiedRecords.add(record);
      }
    } else {
      this.modifiedRecords.add(records);
    }
  }

  async confirmCancelAndUndo() {
    let confirmed = await this.modals.confirm({
      title: this.intl.t(`${this.intlNamespace}.text.confirmCancelTitle`),
      message: this.intl.t(`${this.intlNamespace}.text.confirmCancelMessage`),
      confirm: this.intl.t(`${this.intlNamespace}.action.confirmCancelConfirm`),
      cancel: this.intl.t(`${this.intlNamespace}.action.confirmCancelCancel`),
    });

    if (!confirmed) {
      return false;
    }

    // Commit all changes in dirty child machines to prepare them for rollback or unload
    await this.commitAllTask.perform();
    this.undoChanges();

    return true;
  }

  @task
  *saveActionTask() {
    try {
      let allValid = yield this.prepareSaving();
      if (!allValid) {
        return;
      }

      yield this.commitAllTask.perform();
      this.parentMachine?.send('SAVE');
      yield this.save();
      this.parentMachine?.send('DONE');
      yield this.notifications.success(this.intl.t(`${this.intlNamespace}.notification.saveSuccess`), {
        clearDuration: ENV.environment === 'test' ? 1 : 10000,
      });
    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.progressState?.remove();
    }
  }

  @task
  *saveAndCloseActionTask() {
    try {
      let allValid = yield this.prepareSaving();
      if (!allValid) {
        return;
      }

      yield this.commitAllTask.perform();
      this.parentMachine?.send('SAVE');
      yield this.saveAndClose();
      this.parentMachine?.send('DONE');
      yield this.notifications.success(this.intl.t(`${this.intlNamespace}.notification.saveSuccess`), {
        clearDuration: ENV.environment === 'test' ? 1 : 10000,
      });
    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.progressState?.remove();
    }
  }

  async prepareSaving() {
    let allValid = await this.validateAllTask.perform();
    if (!allValid) {
      return false;
    }

    this.progressState = this.progress.add({
      message: this.intl.t(`${this.intlNamespace}.text.saving`),
    });

    return true;
  }

  @task
  *publishActionTask() {
    let allValid = yield this.validateAllTask.perform();
    if (!allValid) {
      return;
    }

    let doPublish = yield this.modals.confirm({
      message: this.intl.t(`${this.intlNamespace}.text.confirmPublishMessage`, { htmlSafe: true }),
      confirm: this.intl.t(`${this.intlNamespace}.action.confirmPublishConfirm`),
      cancel: this.intl.t(`${this.intlNamespace}.action.confirmPublishCancel`),
    });
    if (!doPublish) {
      return;
    }

    this.progressState = this.progress.add({
      message: this.intl.t(`${this.intlNamespace}.text.saving`),
    });

    try {
      yield this.commitAllTask.perform();
      this.parentMachine?.send('PUBLISH');
      yield this.publish();

      if (this.model?.isDraft) {
        this.notifications.success(
          this.intl.t(`${this.intlNamespace}.notification.publishSuccessCustomised`, {
            publishDate: this.model?.publicationDate,
          })
        );
      } else {
        this.notifications.success(this.intl.t(`${this.intlNamespace}.notification.publishSuccess`));
      }

      this.parentMachine?.send('DONE');
    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.progressState?.remove();
    }
  }

  @task
  *unpublishActionTask() {
    let allValid = yield this.validateAllTask.perform();
    if (!allValid) {
      return;
    }

    let doUnpublish = yield this.modals.confirm({
      message: this.intl.t(`${this.intlNamespace}.text.confirmUnpublishMessage`),
      confirm: this.intl.t(`${this.intlNamespace}.action.confirmUnpublishConfirm`),
      cancel: this.intl.t(`${this.intlNamespace}.action.confirmUnpublishCancel`),
    });

    if (!doUnpublish) {
      return;
    }

    this.progressState = this.progress.add({
      message: this.intl.t(`${this.intlNamespace}.text.saving`),
    });

    try {
      yield this.commitAllTask.perform();
      this.parentMachine?.send('UNPUBLISH');
      yield this.unpublish();

      this.notifications.success(this.intl.t(`${this.intlNamespace}.notification.unpublishSuccess`));
      this.parentMachine?.send('DONE');
    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.progressState?.remove();
    }
  }

  @task({
    enqueue: true,
  })
  @waitFor
  *saveSection(section) {
    yield section.save();
  }

  /**
   * Saves changes on sectionContents, but will ignore all arguments that do not belong to sectionContents and return them.
   * @param {(Model|Changeset)} record a model or changeset to save
   * @return {(Model|Changeset)} if the provided argument is neither a {@link SectionContentModel} nor a changeset of one, it will be returned
   */
  @task
  @waitFor
  *saveSectionContent(record) {
    let recordToSkip = this.prepareSectionContentsForSaving(record);
    if (recordToSkip) {
      return recordToSkip;
    }

    let sectionContent = isChangeset(record) ? record.data : record;

    if (record.get('markedForDeletion')) {
      record?.execute();
      yield sectionContent.destroyRecord();
      return null;
    } else {
      yield record.save();
    }
    this.modifiedRecords.delete(record);
  }

  prepareSectionContentsForSaving(record) {
    let sectionContent = isChangeset(record) ? record.data : record;

    // skip it here, if it is not section-content
    if (sectionContent.constructor.modelName !== 'section-content') {
      return record;
    }

    if (record?.isPristine || sectionContent?.isVLBContent) {
      this.modifiedRecords.delete(record);
    }
  }

  handleErrors(error) {
    this.parentMachine?.send('FAIL');

    if (error instanceof SectionContentValidationError) {
      this.notifications.warning(this.intl.t(`${this.intlNamespace}.notification.validationError`));

      let firstError = document.querySelector(`.${styles['error-list']}`);
      if (firstError) {
        this.jump.scrollIntoView({ element: firstError.parentElement, scrollOffsetY: 224 }); // 14 rem
      }

      return;
    }

    if (error.errors?.[0]?.code?.includes('isLocked')) {
      error.messages = error.errors;
      this.errors.handle(error);
    } else {
      this.notifications.error(this.intl.t(`${this.intlNamespace}.notification.saveError`));
      this.errors.handleGenericError(error, { showNotification: false });
    }
  }

  reloadCurrentRoute() {
    let currentRouteName = this.router.get('currentRouteName');
    let currentRoute = getOwner(this).lookup(`route:${currentRouteName}`);
    currentRoute.refresh();
  }

  undoChanges() {
    // Skip undo if store is already going to be destroyed
    if (this.store.isDestroying || this.store.isDestroyed) {
      return;
    }

    // modifiedRecords contains records of all types, such as prices, section-contents, delivery-info, product-info, etc.
    for (let modifiedRecord of this.modifiedRecords) {
      let record = isChangeset(modifiedRecord) ? modifiedRecord.data : modifiedRecord;
      if (isPresent(record?.constructor?.modelName)) {
        record.rollbackAttributes();
      }
    }

    this.modifiedRecords.clear();

    for (let section of this.modifiedSections) {
      section.rollbackAttributes();
    }

    if (this.model?.isNew) {
      this.model.unloadRecord();
    }
  }

  async _routeWillChange(transition) {
    let noModel = this.model === null;
    let noChanges = !this.hasChanges;
    //save the current transition as this callback will be executed multiple times by the router-service, but should be handled only once
    let hasPreviousTransition = this.storedTransition !== null;
    let sameRoute = transition.from?.name === transition.to?.name;

    if (noModel || noChanges || hasPreviousTransition || sameRoute || this.productIsDeleting) {
      return;
    }

    this.storedTransition = transition;

    transition.abort();

    let undone = await this.confirmCancelAndUndo();

    if (undone) {
      transition.retry();
    }

    this.storedTransition = null;
  }

  //
  // abstract functions
  //

  /**
   * Saves the current model and all related entities.
   * @return {Promise<void>}
   * @throws {Error} - is expected to throw occurring errors in the saving-process
   */
  async save() {
    //save the current model
  }

  /**
   * Saves the current model and all related entities and transitions to the respective view-page of the model.
   * @return {Promise<void>}
   * @throws {Error} - is expected to throw occurring errors in the saving-process
   */
  async saveAndClose() {
    //save the current model and close the edit-mode
  }

  /**
   * Saves the current model and all related entities and publishes them.
   * If the entities have been published successfully, a transition to the respective view-page of the model should be started.
   * @return {Promise<void>}
   * @throws {Error} - is expected to throw occurring errors in the publishing-process
   */
  async publish() {
    //save potential changes and publish the given model in the BE and handle transitions afterwards if necessary
  }

  /**
   * Saves the current model and all related entities and unpublishes them afterwards.
   * @return {Promise<void>}
   * @throws {Error} - is expected to throw occurring errors in the unpublishing-process
   */
  async unpublish() {
    //revert local changes and unpublish the given model in the BE and handle transitions afterwards if necessary
  }

  /**
   * Exit the edit-mode:
   *   * if the model has already been persisted: transition to the last respective view-page of the model (this may be wrapped in a presentation-mode)
   *   * if the model is a new entity, transition to the respective search-/overview-page
   */
  cancel() {
    //exit the edit-mode
  }
}
