import { helper } from '@ember/component/helper';
import { htmlSafe as HTMLSafe } from '@ember/template';

/**
 * Helper turn standard string into an htmlSafe string
 * @param {Array} string - The string to be converted
 */
export default helper(function htmlSafe([string]) {
  return HTMLSafe(string);
});
