import { isPresent, typeOf } from '@ember/utils';

export default function queryParamSetDefault({ name, route, value }) {
  if (isPresent(name) && isPresent(route)) {
    let queryParam = route._qp.qps.find((param) => param.prop === name);
    let type = 'string';

    queryParam.defaultValue = value;
    queryParam.serializedDefaultValue = String(value);
    queryParam.undecoratedDefaultValue = value;

    if (Array.isArray(value)) {
      type = 'array';
    } else if (typeOf(value) === 'boolean') {
      type = 'boolean';
    } else if (typeOf(value) === 'number') {
      type = 'number';
    }

    queryParam.type = type;
  }
}
