import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class CompaniesCompanyEditRoute extends Route {
  @service('party') partyService;
  @service router;
  @service session;

  async model() {
    return this.modelFor('companies/company');
  }

  async afterModel(model) {
    if (!this.session.isAuthenticated) {
      this.router.replaceWith('companies.company', model.party.id);
      return;
    }
  }

  setupController(_, model) {
    super.setupController(...arguments);
    this.partyService.setupController(model);
  }

  resetController() {
    this.partyService.resetController(...arguments);
  }

  activate() {
    super.activate();
    this.partyService.activate(...arguments);
  }

  deactivate() {
    super.deactivate(...arguments);
    this.partyService.deactivate(...arguments);
  }
}
