export default {
  "upload": "_upload_1u7q6n",
  "button": "_button_1u7q6n",
  "table": "_table_1u7q6n _table_1edbot",
  "thead": "_thead_1u7q6n _thead_1edbot",
  "tbody": "_tbody_1u7q6n _tbody_1edbot",
  "tr": "_tr_1u7q6n _tr_1xynbj",
  "th": "_th_1u7q6n _th_zg5hnr",
  "info": "_info_1u7q6n"
};
