import { FACETS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import ApplicationSerializer from './application';

export default class SearchSerializer extends ApplicationSerializer {
  @service codelists;
  @service intl;

  normalizeArrayResponse() {
    let normalized = super.normalizeArrayResponse(...arguments);

    // Add missing label to genrecode facet values based on the translations we have in our codelists
    // This works because codelists are expected to be requested in the application beforeModel hook
    // and should be loaded before a search-product record is requested.
    for (let facet of normalized.meta?.facets ?? []) {
      if (facet.name === FACETS.GENRE_CODE) {
        facet.values = facet.values.map((value) => {
          value.label = value.key;

          if (this.codelists.codeListsTask.lastSuccessful) {
            value.label += ` · ${this.intl.t(`cl_302_${value.key}`)}`;
          }

          return value;
        });
      }

      if (facet.name === FACETS.PARTY_GENRE) {
        facet.values = facet.values.map((value) => {
          value.label = `${value.key} · ${value.label}`;

          return value;
        });
      }
    }

    return normalized;
  }
}
