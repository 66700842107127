import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Tags\n  @changeset={{@changeset}}\n  @deserialize={{this.deserialize}}\n  @disabled={{@disabled}}\n  @label={{@label}}\n  @max={{@max}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @path={{@path}}\n  @required={{@required}}\n  @separator={{this.separator}}\n  @serialize={{this.serialize}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @value={{@value}}\n  @valueKey={{this.valueKey}}\n  ...attributes\n/>", {"contents":"<Ui::Input::Tags\n  @changeset={{@changeset}}\n  @deserialize={{this.deserialize}}\n  @disabled={{@disabled}}\n  @label={{@label}}\n  @max={{@max}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @path={{@path}}\n  @required={{@required}}\n  @separator={{this.separator}}\n  @serialize={{this.serialize}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @value={{@value}}\n  @valueKey={{this.valueKey}}\n  ...attributes\n/>","moduleName":"@mvb/tix-ui/components/ui/input/tags/to-string.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/tags/to-string.hbs"}});
import { action } from '@ember/object';
import { DEFAULT_TAG_KEY, TAG_SEPARATOR } from '.';
import { runInDebug } from '@ember/debug';
import Component from '@glimmer/component';

export default class UiInputTagsAsStringComponent extends Component {
  get separator() {
    return this.args.separator || TAG_SEPARATOR;
  }

  get valueKey() {
    return this.args.valueKey ?? DEFAULT_TAG_KEY;
  }

  @action
  serialize(tags) {
    return tags.map((tag) => tag[this.valueKey]).join(this.separator);
  }

  @action
  deserialize(value) {
    runInDebug(() => {
      if (typeof value !== 'string') {
        throw new TypeError(`Expected value to be a string, got ${value}`);
      }
    });

    return value
      .split(this.separator)
      .filter((tag) => !!tag)
      .map((tag) => ({ [this.valueKey]: tag }));
  }
}
