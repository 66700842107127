import { validatePresence } from 'ember-changeset-validations/validators/index';
import mvbIdValidations from '@mvb/tix-ui/validations/mvb-id';
import validateLocsMvbid from '@mvb/tix-ui/validators/locs-mvbid';
import validateMvbIdDoesNotExist from '@mvb/tix-ui/validators/mvbid-does-not-exist';

const partyValidations = {
  mvbId: [...mvbIdValidations.mvbId],
  type: [validatePresence({ presence: true, description: 'intl:validation.party.description.type' })],
};

export default partyValidations;

export const newPartyValidations = {
  ...partyValidations,
  mvbId: [...partyValidations.mvbId, validateLocsMvbid(), validateMvbIdDoesNotExist()],
};

export const partyStatusValidations = {
  status: [validatePresence({ presence: true, description: 'intl:validation.party.description.status' })],
  expiration: [],
};
