import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.previewExportActions}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{t \"uiFunctionBar.tooltip.exportToFile\"}}\n  />\n{{/if}}", {"contents":"{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.previewExportActions}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{t \"uiFunctionBar.tooltip.exportToFile\"}}\n  />\n{{/if}}","moduleName":"@mvb/tix-ui/components/ui/function-bar/export/previews.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/function-bar/export/previews.hbs"}});
import { action } from '@ember/object';
import { EXPORT_FILE_TYPES, EXPORT_TYPE, TIX_ITEM_SOURCE_TYPE, URL_PRODUCT_EXPORT } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import saveBlob from '@mvb/tix-ui/utils/save-blob';

export default class UiFunctionBarExportPreviewsComponent extends Component {
  @service abilities;
  @service api;
  @service errors;
  @service features;
  @service manageList;
  @service modals;
  @service intl;
  @service notifications;
  @service user;
  @service store;
  @service('search-products-in-preview') searchService;

  get previewExportActions() {
    let actions = {};

    if (this.features.isEnabled('previewDownload')) {
      actions.excel = { label: this.intl.t('uiFunctionBarExport.action.previewExcel'), action: this.onExportExcel };
    }

    if (this.features.isEnabled('productsPdfExport')) {
      actions.pdf = { label: this.intl.t('uiFunctionBarExport.action.pdf'), action: this.onPdfExport };
    }

    if (this.abilities.can('preview.exportWebToPrint', this.args.preview)) {
      actions.webToPrint = {
        label: this.intl.t('uiFunctionBarExport.action.webToPrint'),
        action: this.onExportWebToPrint,
      };
    }

    return actions;
  }

  get hasActions() {
    return Object.keys(this.previewExportActions).length > 0;
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  @action
  onExportExcel() {
    this.exportProductsTask.perform({
      allowForEmptyList: true,
      exportFileType: EXPORT_FILE_TYPES.XLSX,
      exportType: EXPORT_TYPE.XLSX,
    });
  }

  @action
  onExportWebToPrint() {
    this.exportProductsTask.perform({
      allowForEmptyList: true,
      exportFileType: EXPORT_FILE_TYPES.XML,
      exportType: EXPORT_TYPE.WEB_TO_PRINT,
    });
  }

  @action
  onPdfExport() {
    this.exportProductsTask.perform({ exportFileType: EXPORT_FILE_TYPES.PDF, exportType: EXPORT_TYPE.PDF });
  }

  @task
  @waitFor
  *exportProductsTask({ allowForEmptyList = false, exportFileType, exportType }) {
    if (this.isItemsSelectionEmpty && !(allowForEmptyList && this.searchService.meta.total === 0)) {
      return this.modals.notify({
        message: this.intl.t('previewExport.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    try {
      let filters = this.searchService.searchQuery.filter || {};
      delete filters['onList'];
      delete filters['preview.id'];

      let body = {
        exportFileType,
        filters,
        identifiers: Array.from(this.manageList.selectedItems),
        invertedSelection: this.manageList.inverse,
        previewType: this.args.preview.type,
        sort: this.searchService.searchQuery.sort,
        sourceId: this.args.preview.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.PREVIEW,
      };

      switch (exportType) {
        case EXPORT_TYPE.PDF: {
          this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportPdf'));
          break;
        }
        case EXPORT_TYPE.WEB_TO_PRINT: {
          this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportWebToPrint'));
          break;
        }
        default: {
          this.notifications.success(this.intl.t('uiFunctionBarExport.notification.export'));
        }
      }
      let { blob, attachmentName } = yield this.api.postJSON(URL_PRODUCT_EXPORT, body);
      saveBlob(blob, attachmentName);
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
