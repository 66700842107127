import { assert } from '@ember/debug';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import {
  FALLBACK_COVER_URL,
  PREVIEW_STATUS,
  PREVIEW_TYPE,
  PREVIEW_TYPES_INDIVIDUAL,
  SECTION_STATUS,
  SECTION_TYPES,
  STATE_MACHINE_VALUES,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Model from './-base';
import sortBy from 'lodash-es/sortBy';

export default class PreviewModel extends Model {
  @service intl;

  @attr('string') cmcType;
  @attr('date') created;
  @attr('string') creatorId;
  @attr('string') creatorName;
  @attr() isReceivedByCurrentUser;
  @attr('boolean') locked;
  @attr('boolean') onList;
  @attr('boolean') sentOnBehalfOfSelectedParty;
  @attr('date') publishingDate;
  @attr('string') status;
  @attr('string') type;

  @belongsTo('background-process', { async: false, inverse: 'preview' }) backgroundProcess;
  @belongsTo('party', { async: false, inverse: null }) sender;
  @hasMany('section', { async: false, inverse: 'preview' }) sections;

  get abilityModel() {
    return {
      creator: this.creatorId,
      isDraft: this.isDraft,
      isReceivedByCurrentUser: this.isReceivedByCurrentUser,
      sender: this.sender?.get('mvbId'),
      sentOnBehalfOfSelectedParty: this.sentOnBehalfOfSelectedParty,
      type: this.type,
      cmcType: this.cmcType,
    };
  }

  get coverUrl() {
    let coverContent = this.info?.get('contents')?.[0];
    return coverContent?.get('file.downloadUrl') ?? FALLBACK_COVER_URL;
  }

  get descriptionListView() {
    return this.info?.previewInfo?.descriptionListView;
  }

  get description() {
    let description = this.info?.previewInfo?.description;

    let isHTML = false;
    let text = description ? description.trim().replaceAll(/^\s*(<br\s*\/?\s*>)+|(<br\s*\/?\s*>)+\s*$/gi, '') : '';

    return text
      ? {
          text,
          isHTML,
        }
      : undefined;
  }

  get draftSections() {
    return this.sections?.filter((section) => section.status === SECTION_STATUS.DRAFT);
  }

  get firstReferenceProductId() {
    return this.referenceTitles?.firstContent?.referencedProductId;
  }

  get info() {
    let info = this.sections?.find((section) => section.type === SECTION_TYPES.INFO);

    assert('Section of type INFO must always exist on a preview', this.isDestroying || this.isDestroyed || info);

    return info;
  }

  // state machine initialState here
  get initialState() {
    switch (this.status) {
      case PREVIEW_STATUS.DRAFT: {
        return STATE_MACHINE_VALUES.INITIAL.UNPUBLISHED;
      }
      case PREVIEW_STATUS.PUBLISHED:
      case PREVIEW_STATUS.PUBLISHED_NOT_SENT:
      case PREVIEW_STATUS.PUBLISHED_SENT: {
        return STATE_MACHINE_VALUES.INITIAL.PUBLISHED;
      }
      default: {
        return STATE_MACHINE_VALUES.INITIAL.UNPUBLISHED;
      }
    }
  }

  get isBookstorePreview() {
    return this.type === PREVIEW_TYPE.BOOKSTORE_PREVIEW;
  }

  get isCustomPreview() {
    return PREVIEW_TYPES_INDIVIDUAL.includes(this.type);
  }

  get isDraft() {
    return this.status === PREVIEW_STATUS.DRAFT;
  }

  get isHugendubel() {
    return this.type === PREVIEW_TYPE.HUGENDUBEL;
  }

  get isPublished() {
    return !this.isDraft;
  }

  get isThalia() {
    return this.type === PREVIEW_TYPE.THALIA;
  }

  get name() {
    let info = this.sections?.find((section) => section.type === SECTION_TYPES.INFO);

    return info?.previewInfo?.name;
  }

  get referenceTitles() {
    return this.sections?.find((section) => section.type === SECTION_TYPES.REFERENCETITLE) ?? {};
  }

  get referencedProductIds() {
    return this.referenceTitles?.contents?.map((content) => content.referencedProductId) ?? [];
  }

  get sortedProductsForPresentation() {
    let referenceTitlesSection = this.sections?.find((section) => section.type === SECTION_TYPES.REFERENCETITLE);

    return sortBy(referenceTitlesSection?.contents ?? [], 'position');
  }

  get sortedSections() {
    return sortBy(this.sections ?? [], 'position');
  }

  get typeTranslation() {
    return this.type ? this.intl.t(`cl_511_${this.type}`) + this.cmcTypeTranslation : '';
  }

  get cmcTypeTranslation() {
    return this.cmcType ? ` ${this.intl.t(`modelPreview.text.cmcType.${this.cmcType}`)}` : '';
  }
}
