import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (or this.shortTitle this.distributorTitle this.amount this.duration)}}\n  <h4 data-test-info>\n    {{t \"productAdvancedBib.label.info\"}}\n  </h4>\n\n  {{#if this.shortTitle.title}}\n    <p data-test-title-short>\n      {{t \"productAdvancedBib.text.shortTitle\" title=this.shortTitle.title}}\n    </p>\n  {{/if}}\n\n  {{#if this.distributorTitle}}\n    <p data-test-title-distributor>\n      {{t \"productAdvancedBib.text.distributorTitle\" title=this.distributorTitle.title}}\n      {{this.distributorTitle.subtitle}}\n    </p>\n  {{/if}}\n\n  {{#if this.players}}\n    <p data-test-players>\n      {{t \"productAdvancedBib.text.gamePlayers\" num=this.players}}\n    </p>\n  {{/if}}\n\n  {{#if this.duration}}\n    <p data-test-duration>\n      {{t \"productAdvancedBib.text.gameDuration\" num=this.duration}}\n    </p>\n  {{/if}}\n\n  {{yield}}\n{{/if}}", {"contents":"{{#if (or this.shortTitle this.distributorTitle this.amount this.duration)}}\n  <h4 data-test-info>\n    {{t \"productAdvancedBib.label.info\"}}\n  </h4>\n\n  {{#if this.shortTitle.title}}\n    <p data-test-title-short>\n      {{t \"productAdvancedBib.text.shortTitle\" title=this.shortTitle.title}}\n    </p>\n  {{/if}}\n\n  {{#if this.distributorTitle}}\n    <p data-test-title-distributor>\n      {{t \"productAdvancedBib.text.distributorTitle\" title=this.distributorTitle.title}}\n      {{this.distributorTitle.subtitle}}\n    </p>\n  {{/if}}\n\n  {{#if this.players}}\n    <p data-test-players>\n      {{t \"productAdvancedBib.text.gamePlayers\" num=this.players}}\n    </p>\n  {{/if}}\n\n  {{#if this.duration}}\n    <p data-test-duration>\n      {{t \"productAdvancedBib.text.gameDuration\" num=this.duration}}\n    </p>\n  {{/if}}\n\n  {{yield}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/advanced-bib/info.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/advanced-bib/info.hbs"}});
import Component from '@glimmer/component';

import { FORM_FEATURE_TYPES, TITLE_TYPES } from '@mvb/tix-ui/constants';

export default class ProductAdvancedBibInfoComponent extends Component {
  get shortTitle() {
    let titles = this.args.product?.titles?.filter((title) => title.titleType === TITLE_TYPES.SHORT) ?? [];
    return titles ? titles[0] : null;
  }

  get distributorTitle() {
    let titles = this.args.product?.titles?.filter((title) => title.titleType === TITLE_TYPES.DISTRIBUTOR) ?? [];
    return titles ? titles[0] : null;
  }

  get players() {
    let formFeatures =
      this.args.product?.formFeatures?.filter((feature) => feature.type === FORM_FEATURE_TYPES.PLAYERS) ?? [];
    return formFeatures.length > 0 ? formFeatures[0].code : null;
  }

  get duration() {
    let formFeatures =
      this.args.product?.formFeatures?.filter((feature) => feature.type === FORM_FEATURE_TYPES.DURATION) ?? [];
    return formFeatures.length > 0 ? formFeatures[0].code : null;
  }
}
