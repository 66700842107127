import { action } from '@ember/object';
import { ERPS, ORDER_CHANNEL_TYPES } from '@mvb/tix-ui/constants';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class OrderOrderListFinalizeController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service modals;
  @service router;

  get isOrderChannelTypeAgentDistributionPublisher() {
    return (
      this.model.finalizeOrderListChangeset.selectedOrderChannelType ===
      ORDER_CHANNEL_TYPES.AGENT_DISTRIBUTION_PUBLISHER
    );
  }

  get isOrderChannelTypeErp() {
    return this.model.finalizeOrderListChangeset.selectedOrderChannelType === ORDER_CHANNEL_TYPES.ERP;
  }

  get isOrderChannelTypeSwissErp() {
    return [ORDER_CHANNEL_TYPES.PRESTO, ORDER_CHANNEL_TYPES.AVA, ORDER_CHANNEL_TYPES.BUCHZENTRUM].includes(
      this.model.finalizeOrderListChangeset.selectedOrderChannelType
    );
  }

  get isErpJwws() {
    return this.model.erpDetails.erpSetup?.get('erp.name') === ERPS.JWWS ?? false;
  }

  get isErpPreventingOrder() {
    if (
      !this.isOrderChannelTypeErp &&
      !this.isOrderChannelTypeAgentDistributionPublisher &&
      !this.isOrderChannelTypeSwissErp
    ) {
      return false;
    }
    return !this.model.erpDetails.isErpSetupValid || this.model.hasInvalidErpBranchSetups;
  }

  createOrderRequestData() {
    let changeset = this.model.finalizeOrderListChangeset;

    let recipients = isEmpty(changeset.get('recipients'))
      ? null
      : changeset
          .get('recipients')
          .split(';')
          .map((value) => value.trim())
          .filter(Boolean);

    let deliveryDate = isEmpty(changeset.get('deliveryDate')) ? null : changeset.get('deliveryDate').toISODate();

    return {
      comment:
        this.isOrderChannelTypeAgentDistributionPublisher || this.isOrderChannelTypeSwissErp ? changeset.note : null,
      customerNumber:
        this.isOrderChannelTypeAgentDistributionPublisher || this.isOrderChannelTypeSwissErp
          ? changeset.customerNumber
          : null,
      deliveryDate:
        this.isOrderChannelTypeAgentDistributionPublisher || this.isOrderChannelTypeSwissErp ? deliveryDate : null,
      orderCode:
        this.isOrderChannelTypeAgentDistributionPublisher || this.isOrderChannelTypeSwissErp
          ? changeset.orderCode
          : null,
      recipients: this.isOrderChannelTypeAgentDistributionPublisher ? recipients : null,
      orderChannelType: changeset.selectedOrderChannelType,
      orderChannelId: this.isErpJwws ? changeset.selectedOrderChannelId : null,
      copyOrderToErp: this.isOrderChannelTypeAgentDistributionPublisher ? changeset.copyToErp : false,
    };
  }

  @action
  async selectOrderChannelType(value) {
    // needed for validation of order channel id
    let erpName = this.model.erpDetails.erpSetup?.get('erp.name');

    this.model.finalizeOrderListChangeset.erpName = value === ORDER_CHANNEL_TYPES.ERP && erpName ? erpName : null;

    await this.model.finalizeOrderListChangeset.validate();
  }

  @action
  async submitOrder(event) {
    event.preventDefault();

    let countProductsOrderable;
    let countProductsTotal;
    let containsZeroQuantityProductEntries;

    try {
      ({ countProductsOrderable, countProductsTotal, containsZeroQuantityProductEntries } = await this.api.postJSON(
        '/order-lists/order-check',
        {
          orderChannelType: this.model.finalizeOrderListChangeset.selectedOrderChannelType,
          orderListId: this.model.openOrderList.id,
        }
      ));
    } catch (error) {
      return this.errors.handle(error);
    }

    if (countProductsOrderable === 0) {
      return await this.modals.notify({
        title: this.intl.t('orderList.text.noOrderableProducts.title'),
        message: this.intl.t('orderList.text.noOrderableProducts.message'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    } else if (countProductsTotal > countProductsOrderable || containsZeroQuantityProductEntries) {
      let confirmation = await this.modals.confirm({
        title: this.intl.t('orderList.text.containsUnorderableProducts.title'),
        message: this.intl.t('orderList.text.containsUnorderableProducts.message', { htmlSafe: true }),
      });
      if (!confirmation) {
        return;
      }
    }

    return this.saveTask.perform();
  }

  @task({ drop: true })
  @waitFor
  *saveTask() {
    try {
      yield this.model.finalizeOrderListChangeset.validate();
      if (this.model.finalizeOrderListChangeset.isInvalid) {
        return;
      }

      let orderRequest = this.createOrderRequestData();

      yield this.api.postJSON('/order-lists/order', orderRequest);

      this.router.transitionTo('order.success', orderRequest.orderChannelType);
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
