import { isPresent } from '@ember/utils';
import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class PreviewsPreviewEditRoute extends Route {
  @service intl;
  @service notifications;
  @service router;
  @service('section-contents-preview') sectionContents;
  @service store;
  @service searchProductsInPreview;

  intlNamespace = 'previewsPreviewEdit';

  queryParams = {
    page: {
      refreshModel: false,
    },
  };

  async model() {
    let { preview_id } = this.paramsFor('previews.preview');

    let preview = await this.store.findRecord('preview', preview_id, {
      include: ['sender', 'sections.contents.file', 'sections.contents', 'sections.previewInfo'].join(','),
    });

    if (preview.locked) {
      this.router.transitionTo('previews.preview.index', preview_id);
      this.notifications.info(this.intl.t('previewClone.notification.editLockedInfo'), { autoClear: false });
    }

    let referenceTitleSection = preview.sections?.find((section) => section.type === SECTION_TYPES.REFERENCETITLE);

    if (isPresent(referenceTitleSection)) {
      let query = {
        filter: {
          ...this.searchProductsInPreview.searchQuery.filter,
          'section.id': referenceTitleSection.id,
          selectAll: true,
        },
        include: [
          'customMarketingContent.hugListingRecommendations',
          'customMarketingContent.hugMarketingRecommendations',
          'customMarketingContent.listingRecommendations',
          'customMarketingContent.marketingRecommendations',
          'customMarketingContent.branchRecommendedOrderAmounts',
          'customMarketingContent.comparativeProducts',
          'customMarketingContent.marketingLinks',
          'customMarketingContent.files',
          'customMarketingContent',
          'file',
          'searchProduct',
        ].join(','),
      };
      let filteredSectionContents = await this.store.query('section-content', query);

      referenceTitleSection?.set('contents', filteredSectionContents);
    }

    return preview;
  }

  setupController(_, model) {
    super.setupController(...arguments);
    this.sectionContents.setupController(model, this.intlNamespace);
  }

  resetController(_, isExiting) {
    this.sectionContents.resetController(...arguments);

    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }

  activate() {
    super.activate();
    this.sectionContents.activate(...arguments);
  }

  deactivate() {
    super.deactivate(...arguments);
    this.sectionContents.deactivate(...arguments);
  }
}
