import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Tags\n  @changeset={{@changeset}}\n  @deserialize={{this.deserialize}}\n  @disabled={{@disabled}}\n  @label={{@label}}\n  @max={{@max}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @path={{@path}}\n  @required={{@required}}\n  @separator={{@separator}}\n  @serialize={{this.serialize}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @suggestions={{@suggestions}}\n  @value={{@value}}\n  @valueKey={{this.valueKey}}\n  ...attributes\n/>", {"contents":"<Ui::Input::Tags\n  @changeset={{@changeset}}\n  @deserialize={{this.deserialize}}\n  @disabled={{@disabled}}\n  @label={{@label}}\n  @max={{@max}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @path={{@path}}\n  @required={{@required}}\n  @separator={{@separator}}\n  @serialize={{this.serialize}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @suggestions={{@suggestions}}\n  @value={{@value}}\n  @valueKey={{this.valueKey}}\n  ...attributes\n/>","moduleName":"@mvb/tix-ui/components/ui/input/tags/to-array.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/tags/to-array.hbs"}});
import { action } from '@ember/object';
import { DEFAULT_TAG_KEY } from '.';
import { runInDebug } from '@ember/debug';
import Component from '@glimmer/component';

export default class UiInputTagsToArrayComponent extends Component {
  get valueKey() {
    return this.args.valueKey ?? DEFAULT_TAG_KEY;
  }

  @action serialize(tags) {
    return tags.map((tag) => tag[this.valueKey]);
  }

  @action deserialize(value) {
    runInDebug(() => {
      if (!Array.isArray(value)) {
        throw new TypeError(`Expected value to be an array, got ${value}`);
      }
    });

    return value.map((tag) => ({ [this.valueKey]: tag }));
  }
}
