import { DateTime } from 'luxon';
import { helper } from '@ember/component/helper';

export default helper(function now([format]) {
  let dateTime = DateTime.local();

  if (typeof format === 'string') {
    return dateTime.toFormat(format);
  }

  return dateTime;
});
