import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { service } from '@ember/service';
import Controller from '@ember/controller';

export default class NotesOverviewIndexController extends Controller {
  @service('products') productsService;
  @service productList;
  @service router;
  @service('search-notes-overview') searchService;
  @service user;

  queryParams = ['qp'];
  qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  get addToDispoListLabel() {
    return this.productsService.getAddToDispoListLabel(this.maxProductCountExceeded);
  }

  get addToProductListLabel() {
    return this.productsService.getAddToProductListLabel(this.maxProductCountExceeded);
  }

  get isItemsSelectionEmpty() {
    return this.productsService.getIsItemsSelectionEmpty(this.searchService.meta.total);
  }

  get maxProductCountExceeded() {
    return this.productsService.getMaxProductCountExceeded(this.searchService.meta.total);
  }

  @action
  async onAddToDispoList() {
    let affectsResults = await this.productsService.onAddToDispoList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter
    );

    if (affectsResults) {
      this.reloadResults();
    }
  }

  @action
  onAddToProductList() {
    this.productList.addProductsToList(this.searchService.meta.total, this.searchService.searchQuery?.filter);
  }

  @action
  onCreatePromotionalPackage() {
    this.router.transitionTo('products.promotional-package.add');
  }

  @action
  onDownload() {
    this.productsService.onDownload();
  }

  @action
  onSaveSearch() {
    // TODO - comes with implementation of saving a search (see epic #33237)
  }

  @action
  onStartPresentation() {
    this.productsService.onStartPresentation();
  }

  @action
  reloadResults() {
    this.searchService.search();
  }
}
