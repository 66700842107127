import { all, task } from 'ember-concurrency';
import { isChangeset } from 'validated-changeset';
import { isEmpty, isPresent } from '@ember/utils';
import { SECTION_CONTENT_TYPES, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import SectionContentsBaseService, { SectionContentValidationError } from '@mvb/tix-ui/services/section-contents-base';

export default class SectionContentsProductCollectionBaseService extends SectionContentsBaseService {
  /**
   * This task is used to collect and commit changes into the `modifiedRecords` to prepare them for saving.
   * It overrides the parent method to also collect changes from child machines that are not dirty.
   * This is necessary for promotionalPackages and specials as they are saved with all their sections/contents in one request. The serializer will clean up the data before saving.
   * The method cleanupStoreAfterSave() relies on the modifiedRecords to be filled with all the changesets to update the store correctly after saving.
   *
   * @fires childMachine#COMMIT - for each childMachine whose changes have been successfully prepared to being saved
   * @fires parentMachine#REJECT - if any errors occurred during committing
   */
  @task
  @waitFor
  *commitAllTask() {
    // For promotionalPackages and specials we need to commit all changesets even if they are not dirty.
    let allMachineSections = this.parentMachine?.state.context.sections ?? [];
    for (let machineSection of allMachineSections) {
      let sectionComponent = yield machineSection.state.context.component;
      let allSectionContents = sectionComponent?.allSectionContents ?? [];

      // now adding the component changeset to the modifiedRecords
      if (sectionComponent?.changeset) {
        this.addRecordsForSave(sectionComponent.changeset, false);
      } else if (isPresent(allSectionContents)) {
        // this should only concern sections of the type: SECTION_TYPES.CONTAINED_TITLES and SECTION_TYPES.PACKAGE_SIZES
        this.addRecordsForSave(allSectionContents, false);
      }
      machineSection?.send('COMMIT');
    }
  }

  @task
  @waitFor
  *prepareDataForSavingTask() {
    // there is no need to save the sections or execute their data from the changeset to the model as we actually don't have the section itself as a changeset
    // (just in case you were wondering why we skip the sections here entirely, trust me they will be saved when the product gets saved)

    // prepare keywords for saving --> needs to be done before section contents as we turn the keywords into section contents
    let keywordsToSave = [...this.modifiedRecords].find((record) => record.type === SECTION_TYPES.KEYWORDS);
    if (keywordsToSave) {
      this.prepareKeywordsForSavingTask.perform(keywordsToSave);
    }

    // prepare sectionContents for saving
    let skippedChangesets = yield all(
      [...this.modifiedRecords].map((changeset) => this.prepareSectionContentsForSaving(changeset))
    );

    // prepare prices for saving
    skippedChangesets = yield all(
      [...skippedChangesets].map((changeset) => this.preparePricesForSavingTask.perform(changeset))
    );

    yield this.prepareDataForSavingByType('delivery-info', skippedChangesets);
    yield this.prepareDataForSavingByType('product-info', skippedChangesets);
  }

  @task
  @waitFor
  *prepareKeywordsForSavingTask(record) {
    //Execute the changeset to "save" the keywords in the underlying object
    record.execute();

    let keywords = record.data.keywords;
    let section = record.data.section;
    let sectionContents = section.sortedContents;
    let keywordsFromSection = sectionContents.map((keyword) => keyword.body);
    let newKeywords = [];

    for (let content of sectionContents) {
      if (!keywords.includes(content.body)) {
        content.set('markedForDeletion', true);
      }

      this.modifiedRecords.add(content);
    }

    newKeywords = keywords.filter((keyword) => !keywordsFromSection.includes(keyword));

    for (let keyword of newKeywords) {
      let newKeyword = yield this.store.createRecord('section-content', {
        section,
        body: keyword,
        contentType: SECTION_CONTENT_TYPES.TIX,
        position: keywords.indexOf(keyword) + 1,
      });
      this.modifiedRecords.add(newKeyword);
    }

    // delete the record from the modified record as we do not need it anymore
    this.modifiedRecords.delete(record);
  }

  @task
  @waitFor
  *preparePricesForSavingTask(record) {
    let price = isChangeset(record) ? record?.data : record;

    // skip it here, if it is not a price
    if (price?.constructor?.modelName !== 'price') {
      return record;
    }

    // we only execute the changes on a changeset for promotionalPackages as all gets saved with the product due to a custom endpoint
    if (isChangeset(record)) {
      yield record?.execute();
    }
  }

  prepareSectionContentsForSaving(record) {
    let sectionContent = isChangeset(record) ? record.data : record;

    // skip it here, if it is not section-content
    if (sectionContent?.constructor?.modelName !== 'section-content') {
      return record;
    }

    // we only execute the changes on a changeset for promotionalPackages as all gets saved with the product due to a custom endpoint
    if (isChangeset(record)) {
      record?.execute();
    }
  }

  async prepareDataForSavingByType(modelName, skippedChangesets) {
    let dataToSave = skippedChangesets.find(
      (skippedChangeset) => skippedChangeset?.data?.constructor.modelName === modelName
    );

    if (dataToSave) {
      dataToSave.execute();
    }
  }

  @task({ drop: true })
  @waitFor
  *saveTask(options = {}) {
    if (this.hasChanges || !!this.dirtyMachineSections) {
      yield this.prepareDataForSavingTask.perform();
    }
    // because of the custom endpoint we need to use for product collections we need to save the product at the end
    // this will ensure that all changes on sections and contents will be prepared for saving beforehand
    yield this.model.save(options);
    // after saving a product collection we still have the unsaved versions of the freshly saved records in the store, we need to clean up
    // yeah, I could have used {@link undoChanges} here, but this somehow leads to issues with the correct mapping of contents to their sections
    // these issues do not occur when using ths method that only cleans up the new records from the store.
    yield this.cleanupStoreAfterSave();
  }

  /**
   * Validate product info and delivery info section before validating all other sections
   * This prevents the user from saving a promo or special with invalid or missing product info (and delivery info)
   */
  @task
  @waitFor
  *validateAllTask() {
    //if we have a parentMachine and are not in a state where validation is necessary, there are no changes that need validation
    if (this.parentMachine && !this.canValidate) {
      return true;
    }

    try {
      yield this.checkAndValidateSectionByType('product-info');
      yield this.checkAndValidateSectionByType('delivery-info');
    } catch (error) {
      this.handleErrors(error);
      return false;
    }

    return yield super.validateAllTask.perform();
  }

  async checkAndValidateSectionByType(type) {
    let dirtySection = await this.dirtyMachineSections.find((section) => {
      let childComponentChangeset = section.state.context.component?.changeset;
      let modelName = childComponentChangeset?.data?.constructor?.modelName;
      return modelName === type;
    });

    if (isEmpty(dirtySection)) {
      let section = this.findSection(type);

      let changeset = section?.state.context.component?.changeset;
      await changeset?.validate();
      if (changeset?.isInvalid) {
        this.parentMachine?.send('VALIDATE');
        throw new SectionContentValidationError();
      }
    }
  }

  /**
   * After saving a product collection we still have the unsaved versions of the freshly saved records in the store.
   * To clean them up, we need to unload every record that is new.
   * Already saved records do not matter for the cleanup process as they are correctly update in the store anyway
   * This cleanup routine is only called after saving/publishing/unpublishing a product collection.
   */
  cleanupStoreAfterSave() {
    // modifiedRecords contains records of all types, such as prices, section-contents, delivery-info, product-info, etc.
    for (let modifiedRecord of this.modifiedRecords) {
      let record = isChangeset(modifiedRecord) ? modifiedRecord.data : modifiedRecord;
      if (record.isNew) {
        record.unloadRecord();
      }
    }

    for (let section of this.modifiedSections) {
      if (section.isNew) {
        section.unloadRecord();
      }
    }

    this.modifiedRecords.clear();
  }

  findSection(type) {
    return this.parentMachine?.state.context.sections.find((section) => {
      let childComponentChangeset = section.state.context.component?.changeset;
      let modelName = childComponentChangeset?.data?.constructor?.modelName;
      return modelName === type;
    });
  }

  async reloadModel() {
    return this.store.findRecord(this.model.constructor.modelName, this.model.id, {
      include: [
        'contributors',
        'supportingResources',
        'textContents',
        'sections',
        'sections.contents',
        'sections.contents.file',
        'sections.contents.prices',
        'sections.deliveryInfo',
        'sections.productInfo',
        'notes.creator',
      ].join(','),
    });
  }

  undoChanges() {
    super.undoChanges();

    // Since the changesets are not saved to the store, we need to rollback the attributes of the SECTION_TYPES.CONTAINED_TITLES section-contents manually
    let containedTitleRecords = this.store
      .peekAll('section-content')
      .filter((record) => record.sectionType === SECTION_TYPES.CONTAINED_TITLES);
    for (let record of containedTitleRecords) {
      record.rollbackAttributes();
    }

    // Since the changesets are not saved to the store, we need to rollback the attributes of the SECTION_TYPES.PACKAGE_SIZES section-contents manually
    let packageSizeRecords = this.store
      .peekAll('section-content')
      .filter((record) => record.sectionType === SECTION_TYPES.PACKAGE_SIZES);
    for (let record of packageSizeRecords) {
      record.rollbackAttributes();
    }
  }
}
