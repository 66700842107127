import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyUsersRoute extends Route {
  @service abilities;
  @service features;
  @service router;
  @service store;
  @service user;

  beforeModel() {
    if (!this.features.isEnabled('showManageUserTab')) {
      return this.router.replaceWith('party.index');
    }
  }

  async model() {
    if (this.abilities.cannot('party.viewUsers')) {
      return;
    }

    let permissionSets = await this.store.query('permission-set', {
      filter: { manageableUserParties: true },
      include: 'party',
    });

    return { permissionSets };
  }
}
