import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  data-test-jump-button\n  type=\"button\"\n  local-class=\"jump {{if (not this.jump.firstScreenVisible) 'show'}} {{if @stick 'stick'}}\"\n  {{on \"click\" this.jumpToTop}}\n>\n  <span>\n    {{t \"layoutJumpToTop.label.jump\"}}\n  </span>\n  <FaIcon @icon=\"chevron-up\" />\n</button>", {"contents":"<button\n  data-test-jump-button\n  type=\"button\"\n  local-class=\"jump {{if (not this.jump.firstScreenVisible) 'show'}} {{if @stick 'stick'}}\"\n  {{on \"click\" this.jumpToTop}}\n>\n  <span>\n    {{t \"layoutJumpToTop.label.jump\"}}\n  </span>\n  <FaIcon @icon=\"chevron-up\" />\n</button>","moduleName":"@mvb/tix-ui/components/layout/jump-to-top/button.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/jump-to-top/button.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class LayoutJumpToTopButtonComponent extends Component {
  @service jump;

  @action jumpToTop() {
    this.jump.scrollTo(0, 0);
  }

  @tracked showButton = false;

  @action toggle(state) {
    this.showButton = state;
  }
}
