import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes local-class=\"edit\" data-test-preview-edit-component>\n  <Ui::Input::Text\n    @name=\"name\"\n    @label={{t \"previewEditForm.label.name\"}}\n    @changeset={{@changeset}}\n    @path=\"name\"\n    @required={{true}}\n    @disabled={{@disabled}}\n    @onChange={{this.onChange}}\n  />\n\n  <Ui::Input::Text\n    @name=\"descriptionListView\"\n    @label={{t \"previewEditForm.label.descriptionListView\"}}\n    @changeset={{@changeset}}\n    @path=\"descriptionListView\"\n    @required={{false}}\n    @disabled={{@disabled}}\n    @onChange={{this.onChange}}\n  />\n  <Ui::Input::Textarea\n    data-test-input-description\n    rows=\"10\"\n    @changeset={{@changeset}}\n    @label={{t \"previewEditForm.label.description\"}}\n    @name=\"description\"\n    @path=\"description\"\n    @onChange={{this.onChange}}\n  />\n</div>", {"contents":"<div ...attributes local-class=\"edit\" data-test-preview-edit-component>\n  <Ui::Input::Text\n    @name=\"name\"\n    @label={{t \"previewEditForm.label.name\"}}\n    @changeset={{@changeset}}\n    @path=\"name\"\n    @required={{true}}\n    @disabled={{@disabled}}\n    @onChange={{this.onChange}}\n  />\n\n  <Ui::Input::Text\n    @name=\"descriptionListView\"\n    @label={{t \"previewEditForm.label.descriptionListView\"}}\n    @changeset={{@changeset}}\n    @path=\"descriptionListView\"\n    @required={{false}}\n    @disabled={{@disabled}}\n    @onChange={{this.onChange}}\n  />\n  <Ui::Input::Textarea\n    data-test-input-description\n    rows=\"10\"\n    @changeset={{@changeset}}\n    @label={{t \"previewEditForm.label.description\"}}\n    @name=\"description\"\n    @path=\"description\"\n    @onChange={{this.onChange}}\n  />\n</div>","moduleName":"@mvb/tix-ui/components/preview/edit-form/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/preview/edit-form/index.hbs"}});
import { action } from '@ember/object';
import MachineEditModeChildComponent from '../../machine-edit-mode/child';

export default class PreviewEditFormIndexComponent extends MachineEditModeChildComponent {
  // uniform naming for component changeset
  get changeset() {
    return this.args.changeset;
  }

  async validate() {
    await this.args.changeset.validate();
    return this.args.changeset.isValid;
  }

  @action
  async onChange() {
    this.machine.send('UPDATE');
    await this.args.changeset.validate();
    this.machine.send(this.args.changeset.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }
}
