import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this.parentMachine}}", {"contents":"{{yield this.parentMachine}}","moduleName":"@mvb/tix-ui/components/machine-edit-mode/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/machine-edit-mode/index.hbs"}});
import { assert, runInDebug } from '@ember/debug';
import { service } from '@ember/service';
import { useMachine } from 'ember-statecharts';
import Component from '@glimmer/component';

import parentMachine from '@mvb/tix-ui/machines/edit-mode-parent-machine';

export default class MachineEditModeComponent extends Component {
  @service sectionContentsProduct;
  @service sectionContentsPreview;
  @service sectionContentsPromotionalPackage;
  @service sectionContentsSpecial;

  parentMachine = null;

  constructor() {
    super(...arguments);
    this.setupParentMachine(this.args.state);
  }

  setupParentMachine(initialState) {
    this.parentMachine = useMachine(this, () => ({
      machine: parentMachine.withContext({
        sections: [],
        pendingCount: 0,
        changesCount: 0,
        initialState,
      }),
      onTransition: (state) => {
        runInDebug(() => {
          assert(
            `Transition from "${state.historyValue.current}" with event "${state.event.type}" not allowed!`,
            state.changed !== false
          );
        });
      },
    }));
    this.sectionContentsProduct.parentMachine = this.parentMachine;
    this.sectionContentsPreview.parentMachine = this.parentMachine;
    this.sectionContentsPromotionalPackage.parentMachine = this.parentMachine;
    this.sectionContentsSpecial.parentMachine = this.parentMachine;
  }

  willDestroy() {
    this.sectionContentsProduct.parentMachine = null;
    this.sectionContentsPreview.parentMachine = null;
    this.sectionContentsPromotionalPackage.parentMachine = null;
    this.sectionContentsSpecial.parentMachine = null;
    super.willDestroy();
  }
}
