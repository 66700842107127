import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function twoCharactersAfterTheComma() {
  return (key, newValue /* , oldValue, changes, content */) => {
    let decimalNumberWithTwoDigits = String(newValue).split('.')[1]?.length >= 3;
    if (!decimalNumberWithTwoDigits) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let message = buildMessage(key, {
      message: intlService.t('validation.price.decimal'),
    });

    return message;
  };
}
