import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { URL_PURCHASE_RECOMMENDATIONS_UPLOAD } from '@mvb/tix-ui/constants';
import config from '@mvb/tix-ui/config/environment';
import Controller from '@ember/controller';

export default class PartyCentralListController extends Controller {
  @service intl;
  @service notifications;
  @service store;
  @service user;

  @tracked errors;

  uploadUrl = `${config.APP.api}${URL_PURCHASE_RECOMMENDATIONS_UPLOAD}`;

  get uploadedFileName() {
    return this.user.selectedParty?.party?.prFileName;
  }

  @action
  onFileUploadError() {
    let message = this.intl.t('partyCentralList.notification.uploadError');

    this.errors = undefined;
    this.notifications.error(message, { autoClear: false });
  }

  @action
  onFileUploadSuccess(file, response) {
    if (response?.length > 0) {
      let message = this.intl.t('partyCentralList.notification.uploadSuccessWithWarnings');

      this.errors = response;
      this.notifications.warning(message, { autoClear: false });
    } else {
      let message = this.intl.t('partyCentralList.notification.uploadSuccess');

      this.errors = undefined;
      this.notifications.success(message, { autoClear: false });
    }

    if (this.user.selectedParty?.party?.id) {
      this.store.pushRecordAttributes('party', this.user.selectedParty.party.id, { prFileName: file.name });
    }
  }

  reset() {
    this.errors = undefined;
  }
}
