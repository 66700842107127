import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable link-href-attributes no-invalid-link-text }}\n{{#if (or @href @title)}}\n  <div\n    ...attributes\n    local-class=\"anchor-base\"\n    data-test-anchor-base\n    {{did-insert this.onUpdate}}\n    {{did-update this.onUpdate @title}}\n    {{did-intersect\n      onEnter=this.onIntersect\n      onExit=this.onIntersect\n      options=(hash rootMargin=\"-250px 0px 0px 0px\" threshold=0)\n    }}\n  >\n    {{! when @hideAnchor is set to true the section itself provides an anchor for the jumpNav}}\n    {{#if (not @hideAnchor)}}\n      <a\n        data-test-anchor={{this.guid}}\n        data-test-anchor-isActive={{eq this.guid this.jump.activeId}}\n        id={{this.anchorId}}\n        local-class=\"anchor\"\n        data-jump-anchor\n        data-test-jump-anchor={{@title}}\n      ></a>\n    {{/if}}\n\n    {{yield}}\n  </div>\n{{/if}}", {"contents":"{{! template-lint-disable link-href-attributes no-invalid-link-text }}\n{{#if (or @href @title)}}\n  <div\n    ...attributes\n    local-class=\"anchor-base\"\n    data-test-anchor-base\n    {{did-insert this.onUpdate}}\n    {{did-update this.onUpdate @title}}\n    {{did-intersect\n      onEnter=this.onIntersect\n      onExit=this.onIntersect\n      options=(hash rootMargin=\"-250px 0px 0px 0px\" threshold=0)\n    }}\n  >\n    {{! when @hideAnchor is set to true the section itself provides an anchor for the jumpNav}}\n    {{#if (not @hideAnchor)}}\n      <a\n        data-test-anchor={{this.guid}}\n        data-test-anchor-isActive={{eq this.guid this.jump.activeId}}\n        id={{this.anchorId}}\n        local-class=\"anchor\"\n        data-jump-anchor\n        data-test-jump-anchor={{@title}}\n      ></a>\n    {{/if}}\n\n    {{yield}}\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/layout/jump-anchor/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/jump-anchor/index.hbs"}});
import { action } from '@ember/object';
import { assert, runInDebug } from '@ember/debug';
import { dasherize } from '@ember/string';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const MAX_CHARS = 20;

export default class LayoutJumpAnchorComponent extends Component {
  @service jump;

  guid = guidFor(this);

  get shortGuid() {
    return this.guid.slice(-8);
  }

  get anchorId() {
    return (
      this.args.href ??
      `${dasherize(this.args.title)
        ?.replaceAll('ü', 'ue')
        ?.replaceAll('ä', 'ae')
        ?.replaceAll('ö', 'oe')
        ?.replaceAll('ß', 'ss')
        ?.replace(/[^\dA-Za-z-]+/g, '')
        ?.replaceAll('--', '-')}-${this.shortGuid}`
    );
  }

  constructor() {
    super(...arguments);
    runInDebug(() => {
      assert(`@href or @title must be passed to the ${this.constructor.name}`, !!this.args.href || !!this.args.title);
    });
  }

  @action
  onUpdate() {
    // when hideAnchor is set to true we don't want to update the jumpNav
    if (this.args.hideAnchor) {
      return;
    }

    let { title, truncate, flag } = this.args;

    if (truncate && title.length > MAX_CHARS) {
      let truncated = title.slice(0, MAX_CHARS);
      title = `${truncated}…`;
    }

    this.jump.add(this.guid, { title, href: this.anchorId, flag });
  }

  @action
  onIntersect(event) {
    this.jump.setActive(this.guid, event.isIntersecting);
  }

  willDestroy() {
    this.jump.remove(this.guid);

    super.willDestroy(...arguments);
  }
}
