import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class DispoListModel extends Model {
  @attr('date-time') created;
  @attr('date-time') lastUpdated;

  @attr('string') name;
  @attr() defaultByAnyone;
  @attr() defaultByUser;

  // productCount and total price will be null when loading via include
  @attr() productCount;
  @attr() totalPrice;

  @belongsTo('user', { async: true, inverse: null }) owner;
  @hasMany('dispo-list-product', { async: true, inverse: 'dispoList' }) dispoListProducts;
}
