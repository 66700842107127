import { action } from '@ember/object';
import { all, task, timeout } from 'ember-concurrency';
import { URL_PRODUCT_PUBLISH } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import arrayToChunks from '@mvb/tix-ui/utils/array-to-chunks';
import SectionContentsBaseService from '@mvb/tix-ui/services/section-contents-base';

const CHUNK_SIZE = 4;

export default class SectionContentsProductService extends SectionContentsBaseService {
  intlNamespace = 'productsProductEdit';
  presentationPreview = null;

  setupController(model, presentationPreview) {
    this.model = model;
    this.presentationPreview = presentationPreview;
  }

  get canUnPublish() {
    //common products can not be unpublished via TIX
    return false;
  }

  async save() {
    await this.saveTask.perform();
    this.reloadCurrentRoute();
  }

  async saveAndClose() {
    //save the current model and close the edit-mode
    await this.saveTask.perform();
    this.transitionToViewPage();
  }

  @task({ drop: true })
  @waitFor
  *saveTask() {
    let advertisingMediumPricesToSave = [];

    for (let section of this.modifiedSections) {
      yield this.saveSection.perform(section);
    }

    let chunks = arrayToChunks(Array.from(this.modifiedRecords), CHUNK_SIZE);
    for (let records of chunks) {
      let skippedRecords = yield all(records.map((record) => this.saveSectionContent.perform(record)));
      // timeout so that the browser doesn't get overloaded with the requests and doesn't kill them
      yield timeout(100 + Math.random() * 100);

      let skippedAdvertisingMedia = skippedRecords.filter(
        (skippedRecord) => skippedRecord?.data?.constructor.modelName === 'price'
      );

      advertisingMediumPricesToSave = advertisingMediumPricesToSave.concat(skippedAdvertisingMedia);
    }
    if (advertisingMediumPricesToSave) {
      yield this.saveAdvertisingMediumPrices.perform(advertisingMediumPricesToSave);
    }
  }

  @task({
    enqueue: true,
  })
  @waitFor
  *saveAdvertisingMediumPrices(changesets) {
    if (changesets.length === 0) {
      return;
    }
    for (let changeset of changesets) {
      if (changeset.isPristine) {
        continue;
      }

      if (changeset.get('markedForDeletion')) {
        this.modifiedRecords.delete(changeset);
        changeset.execute();
        yield changeset.data.destroyRecord();
        continue;
      }
      this.modifiedRecords.delete(changeset);
      yield changeset.save();
    }
  }

  async publish() {
    //save potential changes and publish the given model in the BE and handle transitions afterwards if necessary
    await this.publishTask.perform();
    await this.reloadModel();
    this.transitionToViewPage();
  }

  @task
  @waitFor
  *publishTask() {
    if (this.hasChanges || !!this.dirtyMachineSections) {
      yield this.saveTask.perform();
    }

    let url = URL_PRODUCT_PUBLISH.replace('{ID}', this.model.id);
    yield this.api.postJSON(url, {
      id: this.model.id,
    });
  }

  @action
  cancel() {
    if (!this.presentationPreview?.id && !this.model?.id) {
      this.router.transitionTo('products');
    } else {
      this.transitionToViewPage();
    }
  }

  async reloadModel() {
    return this.store.findRecord(this.model.constructor.modelName, this.model.id, {
      include: [
        'contributors',
        'supportingResources',
        'textContents',
        'sections',
        'sections.contents',
        'sections.contents.file',
        'sections.contents.prices',
        'sections.productInfo',
        'notes.creator',
      ].join(','),
    });
  }

  transitionToViewPage() {
    if (this.presentationPreview?.id) {
      this.router.transitionTo('presentations.preview.product', this.presentationPreview.id, this.model.id);
    } else {
      this.router.transitionTo('products.product.index', this.model.id);
    }
  }
}
