import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes {{did-update this.initializeSectionContents @section}}>\n  <h4 data-test-image-header local-class=\"header\">\n    {{t \"sectionsEditStage.text.titleStatic\"}}\n  </h4>\n  {{#if @showSource}}\n    <span data-test-image-source local-class=\"source\">\n      {{this.staticSourceLabel}}\n    </span>\n  {{/if}}\n  <Ui::Input::Asset::Image\n    @changeset={{this.staticChangeset}}\n    @dimensions={{this.imageDimensions}}\n    @disabled={{this.isDisabled}}\n    @name=\"static-stage-{{this.id}}\"\n    @onChange={{this.onChange}}\n    @onError={{this.onError}}\n    @onUpload={{this.onUpload}}\n    @onFileRemove={{this.onFileRemove}}\n    @path=\"file\"\n    @payload={{this.filePayload}}\n    @ratio={{this.imageRatio}}\n    @showPreview={{true}}\n    @tooltip={{this.tooltip}}\n  />\n</div>", {"contents":"<div ...attributes {{did-update this.initializeSectionContents @section}}>\n  <h4 data-test-image-header local-class=\"header\">\n    {{t \"sectionsEditStage.text.titleStatic\"}}\n  </h4>\n  {{#if @showSource}}\n    <span data-test-image-source local-class=\"source\">\n      {{this.staticSourceLabel}}\n    </span>\n  {{/if}}\n  <Ui::Input::Asset::Image\n    @changeset={{this.staticChangeset}}\n    @dimensions={{this.imageDimensions}}\n    @disabled={{this.isDisabled}}\n    @name=\"static-stage-{{this.id}}\"\n    @onChange={{this.onChange}}\n    @onError={{this.onError}}\n    @onUpload={{this.onUpload}}\n    @onFileRemove={{this.onFileRemove}}\n    @path=\"file\"\n    @payload={{this.filePayload}}\n    @ratio={{this.imageRatio}}\n    @showPreview={{true}}\n    @tooltip={{this.tooltip}}\n  />\n</div>","moduleName":"@mvb/tix-ui/components/sections/edit/static-stage/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/static-stage/index.hbs"}});
import { action } from '@ember/object';
import {
  ASSET_FORM_CODES,
  ASSET_MODE_CODES,
  ASSET_TYPE_CODES,
  DIMENSIONS,
  FILE_UPLOAD_TARGET_TYPE,
  SECTION_CONTENT_TYPES,
} from '@mvb/tix-ui/constants';
import { isNone, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import MachineEditModeChildComponent from '@mvb/tix-ui/components/machine-edit-mode/child';

export default class SectionsEditStaticStageIndexComponent extends MachineEditModeChildComponent {
  @service intl;
  @service features;
  @service store;

  @tracked staticChangeset = null;
  @tracked staticSectionContent = null;

  filePayload = {
    resourceContentType: ASSET_TYPE_CODES.STAGE,
    resourceMode: ASSET_MODE_CODES.IMAGE,
    ean: this.args.section?.ean,
    targetType: isPresent(this.args.section?.preview)
      ? FILE_UPLOAD_TARGET_TYPE.PREVIEW
      : FILE_UPLOAD_TARGET_TYPE.PRODUCT,
  };

  // uniform naming for component changeset
  get changeset() {
    return this.staticChangeset;
  }

  get imageDimensions() {
    // the dimensions for the products, promos, specials
    if (!isNone(this.args.product)) {
      return { minWidth: DIMENSIONS.STAGE_WIDTH, minHeight: DIMENSIONS.STAGE_HEIGHT };
    }

    // the dimensions for previews
    return { width: DIMENSIONS.STAGE_WIDTH, height: DIMENSIONS.STAGE_HEIGHT };
  }

  get imageRatio() {
    // the ratio for the products, promos, specials
    if (!isNone(this.args.product)) {
      return { ratioWidth: DIMENSIONS.STAGE_WIDTH, ratioHeight: DIMENSIONS.STAGE_HEIGHT };
    }

    // the ratio for previews (we do not check right now)
    return null;
  }

  get isDisabled() {
    if (this.staticChangeset?.isVLBContent) {
      return true;
    }

    if (!this.args.product?.isProductCollection || this.features.isEnabled('coverStageEditProductCollections')) {
      return false;
    }

    return !!this.staticSectionContent?.file;
  }

  get staticSourceLabel() {
    return this.intl.t('sectionsEditStage.text.source', {
      source: this.intl.t(
        this.staticSectionContent?.isVLBContent
          ? 'modelSectionContent.contentType.VLB'
          : 'modelSectionContent.contentType.TIX'
      ),
    });
  }

  get tooltip() {
    // the tooltip for the products, promos, specials
    if (!isNone(this.args.product)) {
      return this.intl.t('sectionsEditStage.text.formatMinWithRatio');
    }

    // the tooltip for previews
    return this.intl.t('sectionsEditStage.text.format');
  }

  constructor() {
    super(...arguments);

    this.initializeSectionContents.perform();
  }

  // could also be just an async method
  @task
  @waitFor
  *initializeSectionContents() {
    let contents = yield this.args.section.contents;

    this.staticSectionContent =
      contents.find(
        (content) =>
          content.resourceContentType === ASSET_TYPE_CODES.STAGE &&
          content.resourceMode === ASSET_MODE_CODES.IMAGE &&
          content.resourceForm !== ASSET_FORM_CODES.EMBEDDABLE
      ) ??
      this.store.createRecord('section-content', {
        contentType: SECTION_CONTENT_TYPES.TIX,
        resourceMode: ASSET_MODE_CODES.IMAGE,
        resourceContentType: ASSET_TYPE_CODES.STAGE,
        section: this.args.section,
        position: 0,
      });

    this.staticChangeset = createChangeset(this.staticSectionContent, {});
  }

  @action
  onChange() {
    // upload finished
    super.onChangeMedia();
    this.staticChangeset.set('markedForDeletion', false);
  }

  @action
  onFileRemove() {
    super.onFileRemove();
    this.staticChangeset.set('markedForDeletion', true);
  }

  willDestroy() {
    this.staticChangeset = null;

    if (this.store.isDestroyed || this.store.isDestroying) {
      return;
    }

    if (this.staticSectionContent.isNew) {
      this.staticSectionContent.unloadRecord();
    }

    super.willDestroy(...arguments);
  }
}
