import { PARTY_TYPES_FOR_COMPANY_PAGES, PUBLISHING_STATUS, SECTION_STATUS, UUID_LENGTH } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import ENV from '@mvb/tix-ui/config/environment';
import Route from '@ember/routing/route';

export class PartyInfoNotPremiumError extends Error {}

export function handlePartyInfoError(originalError) {
  let error = (originalError.errors ?? [])[0] ?? {};
  if (error.code === 'error.partyInfo.notPremium') {
    throw new PartyInfoNotPremiumError();
  }
}

export default class CompaniesCompanyRoute extends Route {
  @service api;
  @service router;
  @service store;

  // do not use "refreshModel: true" here, see:
  // https://github.com/emberjs/ember.js/issues/16921
  // https://github.com/emberjs/ember.js/issues/16992
  queryParams = {
    publishedStatus: { refreshModel: false },
  };

  async model({ party_id: partyId, publishedStatus }) {
    try {
      let filter = {};
      let include = 'logo,party,stage';
      let partyIdIsMvbId = this.partyIdIsMvbId(partyId);

      // ADB wants to link to our company pages, but they only have the MVB ID, so we need to support that
      if (partyIdIsMvbId) {
        filter.mvbId = partyId;
      } else {
        filter['party.id'] = partyId;
      }

      if (publishedStatus === SECTION_STATUS.PUBLISHED) {
        filter.publishingStatus = PUBLISHING_STATUS.ACTIVE;
      }

      let partyInfos = await this.store.query('party-info', {
        filter,
        include,
      });

      let partyInfo = partyInfos?.[0] ?? null;
      let party = partyInfo?.party;

      if (!party) {
        if (partyIdIsMvbId && ENV.environment !== 'test') {
          let parties = await this.store.query('party', {
            filter: {
              active: true,
              mvbId: partyId,
              type: PARTY_TYPES_FOR_COMPANY_PAGES,
            },
          });
          party = parties?.[0] ?? null;
        } else {
          party = await this.store.findRecord('party', partyId);
        }
      }

      return { party, partyId, partyInfo, publishedStatus };
    } catch (error) {
      handlePartyInfoError(error);
    }
  }

  afterModel(model) {
    if (this.partyIdIsMvbId(model.partyId) && ENV.environment !== 'test') {
      this.router.replaceWith({ ...model, partyId: model.party.id });
    }
  }

  partyIdIsMvbId(partyId) {
    return partyId.length < UUID_LENGTH;
  }

  serialize(model) {
    return { party_id: model.party.id };
  }
}
