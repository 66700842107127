import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.videos}}\n  <p local-class=\"cookie\" data-test-cookie-notice>\n    {{t \"productVideos.text.cookieNotice\"}}\n  </p>\n\n  <ul local-class=\"video-list\" data-test-videos>\n    {{#each this.videos as |video|}}\n      <Product::Videos::Video @video={{video}} local-class=\"video\" />\n    {{/each}}\n  </ul>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.videos}}\n  <p local-class=\"cookie\" data-test-cookie-notice>\n    {{t \"productVideos.text.cookieNotice\"}}\n  </p>\n\n  <ul local-class=\"video-list\" data-test-videos>\n    {{#each this.videos as |video|}}\n      <Product::Videos::Video @video={{video}} local-class=\"video\" />\n    {{/each}}\n  </ul>\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/videos/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/videos/index.hbs"}});
import { ASSET_MODE_CODES, EXPECTED_VIDEO_SOURCES } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';
import sortBy from 'lodash-es/sortBy';

export default class ProductVideosComponent extends Component {
  get videos() {
    let videos =
      this.args.section?.contents.filter(
        (content) =>
          content.resourceMode === ASSET_MODE_CODES.VIDEO &&
          (content.urlContent?.includes(EXPECTED_VIDEO_SOURCES.YOUTUBE) ||
            content.urlContent?.includes(EXPECTED_VIDEO_SOURCES.VIMEO))
      ) ?? [];

    return sortBy(videos, 'resourceContentType');
  }
}
