import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class PartySettingModel extends Model {
  // @attr() version;
  @attr() advertisingAddress;

  @belongsTo('erp-setup', { async: false, inverse: 'partySettings' }) erpSetup;
  @belongsTo('erp-sales-setup', { async: false, inverse: 'partySettings' }) erpSalesSetup;
  @belongsTo('party', { async: true, inverse: 'partySetting' }) party;
}
