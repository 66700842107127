import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchGroupsService extends SearchBaseService {
  @service store;
  @service user;

  _maxEntitiesForSearch = Number.POSITIVE_INFINITY;

  get queryParamsDefaults() {
    return {
      page: 1,
      searchTerm: '',
      sort: 'name',
    };
  }

  get queryParamsFilters() {
    return ['searchTerm'];
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortKeys = ['name', 'isMember'];
    return [{ value: null }].concat(...sortKeys.map((value) => [{ value }, { value: `-${value}` }]));
  }

  createSearchQuery({ queryParams }) {
    let { page, size, sort } = queryParams;
    let query = {
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersToSearchQuery({ query, queryParams });

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('group', query);
  }
}
