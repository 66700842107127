import { action } from '@ember/object';
import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class PresentationsPreviewProductController extends Controller {
  @service dispoLists;
  @service errors;
  @service router;

  queryParams = ['asset', 'publishedStatus'];

  @tracked asset = null;
  @tracked publishedStatus = SECTION_STATUS.DRAFT;

  @action
  updateSelectedAsset(assetId) {
    this.asset = assetId;
  }

  @task
  @waitFor
  *saveOrderLinesTask(searchProduct, entryChangesets) {
    try {
      let shouldReload = yield this.dispoLists.saveDispoListEntriesTask.perform(searchProduct, entryChangesets);

      if (shouldReload) {
        return this.router.refresh();
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
