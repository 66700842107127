import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<select local-class=\"select {{if @hasErrors 'has-error'}}\" {{on \"change\" @onChange}} ...attributes>\n  {{#each this.options key=@valueKey as |opt|}}\n    <option\n      data-test-select-option={{opt.value}}\n      disabled={{opt.option.disabled}}\n      selected={{opt.selected}}\n      value={{opt.value}}\n    >{{opt.option.text}}</option>\n  {{/each}}\n</select>", {"contents":"<select local-class=\"select {{if @hasErrors 'has-error'}}\" {{on \"change\" @onChange}} ...attributes>\n  {{#each this.options key=@valueKey as |opt|}}\n    <option\n      data-test-select-option={{opt.value}}\n      disabled={{opt.option.disabled}}\n      selected={{opt.selected}}\n      value={{opt.value}}\n    >{{opt.option.text}}</option>\n  {{/each}}\n</select>","moduleName":"@mvb/tix-ui/components/ui/input/select/single.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/select/single.hbs"}});
// eslint-disable-next-line import/named
import { cached } from '@glimmer/tracking';
import { get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class UiInputSelectSingleComponent extends Component {
  @service intl;

  @cached
  get options() {
    let options = [];

    if (this.placeholder) {
      let value = '';
      let selected = value === this.args.value;

      options.push({ option: { text: this.placeholder }, selected, value });
    }

    let passedOptions = this.args.options ?? [];
    options.push(
      ...passedOptions.map((option) => {
        let value = get(option, this.args.valueKey);
        let selected = value === this.args.value;

        return { option, selected, value };
      })
    );

    return options;
  }

  @cached
  get placeholder() {
    if (this.args.placeholder === false) {
      return false;
    }

    if (typeof this.args.placeholder === 'string') {
      return this.args.placeholder;
    }

    return this.intl.t('uiInputSelect.placeholder.single');
  }
}
