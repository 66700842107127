import { PARTY_TYPE, PREMIUM_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchPremiumAssignedPartiesForUserService extends SearchBaseService {
  @service store;
  @service user;

  _maxEntitiesForSearch = Number.POSITIVE_INFINITY;

  get queryParamsDefaults() {
    return {
      page: 1,
      term: '',
      sort: 'party.name',
    };
  }

  get queryParamsFilters() {
    return ['term'];
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortKeys = ['party.name', 'party.mvbId'];
    return [{ value: null }].concat(...sortKeys.map((value) => [{ value }, { value: `-${value}` }]));
  }

  createSearchQuery({ queryParams }) {
    let { page, size, sort } = queryParams;
    let query = {
      filter: {
        'user.id': this.user.current.id,
        'party.type': [PARTY_TYPE.PUBLISHER, PARTY_TYPE.SALES_COOP, PARTY_TYPE.BOOK_GROUP].join(','),
        'party.premiumStatus': PREMIUM_STATUS.PREMIUM,
      },
      include: 'party',
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersToSearchQuery({ query, queryParams });

    if (sort) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('assigned-party', query);
  }
}
