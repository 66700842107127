import { action } from '@ember/object';
import {
  COG_TEMPLATEFILE_WITH_INDEX,
  COG_TEMPLATEFILE_WITHOUT_INDEX,
  URL_COG_DELETE,
  URL_COG_DOWNLOAD,
  URL_COG_UPLOAD,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import ENV from '@mvb/tix-ui/config/environment';

export default class PartyCategoryOfGoodsController extends Controller {
  @service api;
  @service file;
  @service intl;
  @service modals;
  @service notifications;
  @service store;
  @service user;

  @tracked errors;

  uploadUrl = `${ENV.APP.api}${URL_COG_UPLOAD}`;

  get uploadedFileName() {
    return this.user.selectedParty?.party?.cogFileName;
  }

  @task
  @waitFor
  *downloadTask(filename) {
    try {
      let { blob: file } = yield this.api.post(`${URL_COG_DOWNLOAD}?filename=${filename}`);
      yield this.file.saveAs(file, filename);
    } catch {
      let message = this.intl.t('partyCategoryOfGoods.notification.downloadError');
      this.notifications.error(message, { autoClear: false });
    }

    return true;
  }

  @action
  downloadWithIndex(event) {
    event.preventDefault();
    return this.downloadTask.perform(COG_TEMPLATEFILE_WITH_INDEX);
  }

  @action
  downloadWithoutIndex(event) {
    event.preventDefault();
    return this.downloadTask.perform(COG_TEMPLATEFILE_WITHOUT_INDEX);
  }

  @action
  async deleteFile() {
    let message = this.intl.t('partyCategoryOfGoods.notification.deleteSuccess');
    let result = await this.modals.confirm({
      title: this.intl.t('partyCategoryOfGoods.text.confirmDeleteTitle'),
      message: this.intl.t('partyCategoryOfGoods.text.confirmDeleteMessage'),
      confirm: this.intl.t('partyCategoryOfGoods.action.confirmDelete'),
      cancel: this.intl.t('partyCategoryOfGoods.action.cancelDelete'),
    });

    if (!result) {
      return;
    }

    await this.api.delete(URL_COG_DELETE);

    this.errors = undefined;
    this.notifications.success(message);

    if (this.user.selectedParty?.party?.id) {
      this.store.pushRecordAttributes('party', this.user.selectedParty.party.id, { cogFileName: null });
    }
  }

  @action
  onFileUploadError(error) {
    let message = this.intl.t('partyCategoryOfGoods.notification.uploadError');

    this.errors = error.errors?.[0]?.meta?.validations;
    this.notifications.error(message, { autoClear: false });
  }

  @action
  onFileUploadSuccess(file) {
    let message = this.intl.t('partyCategoryOfGoods.notification.uploadSuccess');

    this.errors = undefined;
    this.notifications.success(message, { autoClear: false });

    if (this.user.selectedParty?.party?.id) {
      this.store.pushRecordAttributes('party', this.user.selectedParty.party.id, { cogFileName: file.name });
    }
  }

  reset() {
    this.errors = undefined;
  }
}
