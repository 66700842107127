export default {
  "spinner": "_spinner_14jtnk",
  "basic-info": "_basic-info_14jtnk",
  "metadata": "_metadata_14jtnk",
  "metadata-one": "_metadata-one_14jtnk",
  "metadata-two": "_metadata-two_14jtnk",
  "season-year": "_season-year_14jtnk",
  "season": "_season_14jtnk",
  "year": "_year_14jtnk",
  "lists": "_lists_14jtnk",
  "approval": "_approval_14jtnk",
  "max-width": "_max-width_14jtnk"
};
