import { isPresent } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import evValidateFormat from 'ember-validators/format';
import get from 'lodash-es/get';

export default function validateEmailGroups(options) {
  return (key, value) => {
    let mails = get(options.content, options.pathMailsOther)
      ? [...get(options.content, options.pathMailsOther)].filter(Boolean)
      : [];
    let mailsBlacklist = get(options.content, options.pathMailsBlacklist)
      ? [...get(options.content, options.pathMailsBlacklist)].filter(Boolean)
      : [];

    if (isPresent(value)) {
      let values = trimAll(value.split(';')).filter(Boolean);

      if (isValueInvalid(key, values)) {
        return buildMessage(key, { type: 'emailMultiple' });
      }

      mails.push(...values);
    }

    let mailsViable = [...new Set(mails)].filter((mail) => !mailsBlacklist.includes(mail));

    if (mailsViable.length === 0) {
      return options.description;
    }

    return true;
  };
}

function trimAll(values) {
  return values.map((value) => value.trim());
}

function isValueInvalid(key, values) {
  return values.some((value) => true !== evValidateFormat(value, { type: 'email' }, null, key));
}
