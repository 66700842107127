import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import ENV from '@mvb/tix-ui/config/environment';

export default function validateLocsMvbid(options = {}) {
  return async (key, newValue /* , oldValue, changes, content */) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let apiService = applicationUtil.lookup('service:api');

    let value = newValue?.trim();
    let message = buildMessage(key, { type: 'mvbIdDoesNotExist', value, context: options });

    // return quickly if there's no value or the mvbId is too small/big, saves on API calls
    if (!value || value.length < ENV.APP.minMvbIdLength || value.length > ENV.APP.maxMvbIdLength) {
      return message;
    }

    try {
      await apiService.get(`/locs/party/${value}/name`);
    } catch {
      // noop
      return message;
    }

    return true;
  };
}
