import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class ProductsProductController extends Controller {
  queryParams = ['mvbId', 'type', 'publishedStatus'];

  @tracked mvbId = '';
  @tracked type = '';
  @tracked publishedStatus = SECTION_STATUS.DRAFT;
}
