import { DateTime } from 'luxon';
import { service } from '@ember/service';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import finalizeOrderListValidations from '@mvb/tix-ui/validations/order-list-finalize';
import Route from '@ember/routing/route';

export default class OrderOrderListFinalizeRoute extends Route {
  @service abilities;
  @service api;
  @service erp;
  @service intl;
  @service router;
  @service store;

  createFinalizeOrderListChangeset(orderChannelId = null) {
    return createChangeset(
      {
        copyToErp: false,
        customerNumber: '',
        deliveryDate: '',
        erpName: null,
        note: '',
        orderCode: '',
        recipients: '',
        selectedOrderChannelId: orderChannelId,
        selectedOrderChannelType: '',
      },
      finalizeOrderListValidations
    );
  }

  async model() {
    if (!this.abilities.can('order.order')) {
      return;
    }

    let { openOrderList } = this.modelFor('order.order-list');

    if (!openOrderList || openOrderList.productCount === 0) {
      return this.router.replaceWith('order.order-list.index');
    }

    let orderChannelTypes = await this.api.get('/order-channel-types');
    let orderChannelTypesOptions = orderChannelTypes.map((orderChannelType) => ({
      text: this.intl.t(`orderChannelType.${orderChannelType}`),
      value: orderChannelType,
    }));

    await this.erp.loadDetails();

    let hasInvalidErpBranchSetups = false;

    if (this.erp.currentDetails.isErpPresent) {
      await this.erp.loadInvalidErpBranchSetups(this.erp.currentDetails.erpSetup);
      hasInvalidErpBranchSetups = this.erp.areCurrentBranchSetupsInvalid;
    }

    let defaultOrderChannelId = (await this.erp.currentDetails?.erpSetup?.get('orderChannels')?.[0]?.id) ?? null;
    let finalizeOrderListChangeset = this.createFinalizeOrderListChangeset(defaultOrderChannelId);

    return {
      erpDetails: this.erp.currentDetails,
      finalizeOrderListChangeset,
      hasInvalidErpBranchSetups,
      minDate: DateTime.local(),
      openOrderList,
      orderChannelTypesOptions,
    };
  }
}
