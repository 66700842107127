import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @product.salesNotes}}\n  <ul local-class=\"sales-notes\">\n    {{#each this.salesNotes as |salesNote index|}}\n      <li data-test-sales-note={{index}}>\n        {{nl2br salesNote}}\n      </li>\n    {{/each}}\n  </ul>\n{{/if}}\n\n{{#each this.salesNotesWithHTML as |salesNote index|}}\n  <p data-test-sales-note-html={{index}} local-class=\"formatted\">\n    {{nl2br salesNote.text}}\n  </p>\n{{/each}}", {"contents":"{{#if @product.salesNotes}}\n  <ul local-class=\"sales-notes\">\n    {{#each this.salesNotes as |salesNote index|}}\n      <li data-test-sales-note={{index}}>\n        {{nl2br salesNote}}\n      </li>\n    {{/each}}\n  </ul>\n{{/if}}\n\n{{#each this.salesNotesWithHTML as |salesNote index|}}\n  <p data-test-sales-note-html={{index}} local-class=\"formatted\">\n    {{nl2br salesNote.text}}\n  </p>\n{{/each}}","moduleName":"@mvb/tix-ui/components/product/sales-notes/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/sales-notes/index.hbs"}});
import Component from '@glimmer/component';

import { TEXT_FORMATS } from '@mvb/tix-ui/constants';

export default class SalesNotesComponent extends Component {
  get salesNotes() {
    let salesNotesAsText = [];
    let salesNotes =
      this.args.product?.salesNotes
        ?.filter((note) => note.textFormat === TEXT_FORMATS.PLAINTEXT)
        .sort((a, b) => {
          return a.sequence - b.sequence;
        }) ?? [];

    for (let salesNote of salesNotes) {
      if (salesNote.text.includes('\n')) {
        let lines = salesNote.text.split('\n');
        for (let oneLine of lines) {
          salesNotesAsText.push(oneLine);
        }
      } else {
        salesNotesAsText.push(salesNote.text);
      }
    }

    return salesNotesAsText;
  }

  get salesNotesWithHTML() {
    return (
      this.args.product?.salesNotes
        ?.filter((note) => note.textFormat === TEXT_FORMATS.XHTML)
        .sort((a, b) => {
          return a.sequence - b.sequence;
        }) ?? []
    );
  }
}
