import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class BackofficePartiesIndexController extends Controller {
  queryParams = ['filter', 'page', 'sort'];

  @tracked filter = '';
  @tracked page = 1;
  @tracked sort = 'name';

  @action
  onFilter(event) {
    event.preventDefault();

    this.filter = event.target.elements[0].value;
    this.page = 1;
  }
}
