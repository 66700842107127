import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class OrderIndexRoute extends Route {
  @service router;

  redirect() {
    this.router.replaceWith('order.dispo-lists');
  }
}
