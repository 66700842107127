import { action } from '@ember/object';
import { TABLE_SORT_ORDERS } from '@mvb/tix-ui/constants';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import get from 'lodash-es/get';

export default class ProfileUserIndexController extends Controller {
  @tracked sortKey;
  @tracked sortOrder;

  defaultSortKey = 'party.name';
  defaultSortOrder = TABLE_SORT_ORDERS.ASCENDING;

  constructor() {
    super(...arguments);
    this.reset();
  }

  get sortedAssignedParties() {
    let compareOptions = { numeric: true, sensitivity: 'base' };

    if (this.sortOrder === '') {
      return [...this.model.assignedParties].sort((a, b) =>
        (get(a, this.sortKey) ?? '').localeCompare(get(b, this.sortKey) ?? '', compareOptions)
      );
    }

    if (this.sortOrder === '-') {
      return [...this.model.assignedParties].sort((a, b) =>
        (get(b, this.sortKey) ?? '').localeCompare(get(a, this.sortKey) ?? '', compareOptions)
      );
    }

    return this.model.assignedParties;
  }

  reset() {
    this.sortKey = this.defaultSortKey;
    this.sortOrder = this.defaultSortOrder === TABLE_SORT_ORDERS.DESCENDING ? '-' : '';
  }

  @action
  sortTable(column, sorting) {
    this.sortKey = column.sortKey;
    this.sortOrder = sorting;
  }
}
