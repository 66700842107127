import { bind } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchProductsInListService extends SearchBaseService {
  @service abilities;
  @service('custom-marketing-content-locked') customMarketingContentLockedService;
  @service('dispo-list-product-entries') dispoListProductEntriesService;
  @service intl;
  @service store;
  @service user;

  get queryParamsDefaults() {
    return {
      ...this.queryParamsDefaultsForProducts,
      ...this.queryParamsDefaultsForAdditionalInformation,
      selectedProductListProductIds: '',
      inverse: false,
      // to get the correct order of products coming from a preview
      // we sort the list by position as default
      sort: 'position',
    };
  }

  get queryParamsFilters() {
    return this.queryParamsFiltersForProducts;
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    return [
      {
        text: this.intl.t('productsIndex.text.sorting.positionUp'),
        value: 'position',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.positionDown'),
        value: '-position',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateUp'),
        value: 'product.publicationDate',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateDown'),
        value: '-product.publicationDate',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.publisherSortUp'),
        value: 'product.publisherName',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.publisherSortDown'),
        value: '-product.publisherName',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.currentPriceUp'),
        value: 'currentPrice',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.currentPriceDown'),
        value: '-currentPrice',
      },
    ];
  }

  createSearchQuery({ model: list, queryParams }) {
    let { page, size, sort } = queryParams;
    let query = {
      filter: {
        listId: list.id,
      },
      include: 'searchProduct,searchProduct.notes',
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersOfAdditionalInformationToSearchQuery({ query, queryParams });
    this.addFiltersToSearchQuery({ query, queryParams });

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('list-item', query);
  }

  async mapResults({ results: listItems }) {
    let { abilities, customMarketingContentLockedService, dispoListProductEntriesService } = this;
    let dlpes = [];
    let updateAdditionalInformationFilters = bind(this, this.updateAdditionalInformationFilters);

    listItems = listItems ?? [];

    let isbns = listItems.map((listItem) => listItem.searchProduct.get('gtin'));
    isbns = [...new Set(isbns.filter(Boolean))];

    if (abilities.can('dispoList.viewDispoListsInProductPages')) {
      dlpes = await dispoListProductEntriesService.fetchByIsbns(isbns);
      dispoListProductEntriesService.cleanup();
    }

    // get all the locked infos needed for the cmcs
    customMarketingContentLockedService.getCMCsLockedStatusForPreviewsTask.perform({ listItems });

    return (
      listItems
        ?.filter((listItem) => !!listItem.searchProduct)
        ?.map((listItem) => {
          listItem = new (class {
            @tracked dispoListProductEntries;
            listItem = listItem;
            onSaveAdditionalInformationTask = task(async ({ cmcModel } = {}) => {
              if (cmcModel?.hugListingRecommendations?.length > 0 && cmcModel?.preview?.content?.isHugendubel) {
                let { refreshDispoListProductEntries } = this;
                await dispoListProductEntriesService.createForDefaultDispoListTask.perform({
                  collectionOfGoods: cmcModel.assortment?.content ?? null,
                  hugListingRecommendationCodes:
                    cmcModel.hugListingRecommendations?.map((listing) => listing.code) ?? [],
                  onChange: refreshDispoListProductEntries,
                  preview: cmcModel.preview.content,
                  productId: cmcModel.referencedProductId,
                  productIsbn: cmcModel.referencedProductIsbn,
                });
              }
              updateAdditionalInformationFilters();
            });
            searchProduct = listItem.searchProduct;
          })();

          listItem.dispoListProductEntries = dispoListProductEntriesService.mapSearchProduct({
            dlpes,
            searchProduct: listItem.searchProduct,
          }).dispoListProductEntries;

          listItem.refreshDispoListProductEntries = bind(listItem, async function () {
            if (abilities.can('dispoList.viewDispoListsInProductPages') && listItem.searchProduct.get('gtin')) {
              let dlpesForSearchProduct = await dispoListProductEntriesService.fetchByIsbns(
                listItem.searchProduct.gtin
              );
              this.dispoListProductEntries = dispoListProductEntriesService.mapSearchProduct({
                dlpes: dlpesForSearchProduct,
                searchProduct: listItem.searchProduct,
              }).dispoListProductEntries;
            }
          });

          return listItem;
        }) ?? []
    );
  }

  overrideQueryParameters(queryParamsOverride) {
    return this.overrideQueryParametersForProducts(queryParamsOverride);
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFilters(queryParamsOverride) {
    return this.overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForProducts(queryParamsOverride);
  }
}
