export default {
  "actions": "_actions_2bg419",
  "button-edit": "_button-edit_2bg419",
  "column-delete": "_column-delete_2bg419",
  "column-collection-of-goods": "_column-collection-of-goods_2bg419",
  "column-dispo-list": "_column-dispo-list_2bg419",
  "column-hug-listing-recommendation-codes": "_column-hug-listing-recommendation-codes_2bg419",
  "column-note": "_column-note_2bg419",
  "form-saving": "_form-saving_2bg419",
  "group": "_group_2bg419",
  "table": "_table_2bg419",
  "table-container": "_table-container_2bg419",
  "table-loading": "_table-loading_2bg419",
  "table-wrapper": "_table-wrapper_2bg419"
};
