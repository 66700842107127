import { all } from 'ember-concurrency';
import { HUGENDUBEL_MVB_ID, SECTION_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export class PreviewDraftError extends Error {}
export class PreviewLoadingError extends Error {}
export class PreviewNotFoundError extends Error {}

export function handlePreviewError(originalError) {
  let error = (originalError.errors ?? [])[0] ?? {};

  if (error.code === 'error.common.notAllowed') {
    throw new PreviewDraftError();
  }

  if (error.code === 'error.preview.notFound') {
    throw new PreviewNotFoundError();
  }

  throw new PreviewLoadingError();
}

export default class PreviewsPreviewRoute extends Route {
  @service categoryOfGoods;
  @service collectionOfGoods;
  @service customMarketingContent;
  @service customMarketingContentLocked;
  @service store;
  @service session;
  @service router;

  // do not use "refreshModel: true" here, see:
  // https://github.com/emberjs/ember.js/issues/16921
  // https://github.com/emberjs/ember.js/issues/16992
  queryParams = {
    mvbId: { refreshModel: false },
    publishedStatus: { refreshModel: false },
  };

  beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      let loginController = this.controllerFor('login');
      loginController.previousTransition = transition;
      this.router.transitionTo('login');
    }
  }

  async model({ mvbId, preview_id: previewId, publishedStatus }) {
    let include = [
      'sections',
      'sections.contents',
      'sections.contents.file',
      'sections.previewInfo',
      'sender',
      'backgroundProcess',
    ].join(',');
    let preview;

    try {
      if (mvbId) {
        let filter = { identifier: previewId, identifierType: 'PROPRIETARY', mvbId };
        if (publishedStatus === SECTION_STATUS.PUBLISHED) {
          filter.section = {
            status: publishedStatus,
          };
        }
        let previews = await this.store.query('preview', {
          filter,
          include,
        });
        preview = previews?.[0];
      } else {
        let options = { include };
        if (publishedStatus === SECTION_STATUS.PUBLISHED) {
          options.adapterOptions = {
            query: {
              filter: {
                section: {
                  status: publishedStatus,
                },
              },
            },
          };
        }
        preview = await this.store.findRecord('preview', previewId, options);
      }

      if (!preview) {
        throw new PreviewNotFoundError();
      }

      let customMarketingContentService = this.customMarketingContent.getCMCServiceForPreviewType(preview.type);
      this.customMarketingContentLocked.getCMCsLockedStatusForPreviewsTask.perform({ previews: [preview] });

      return { preview, previewId, publishedStatus, customMarketingContentService, assortmentGroups: [] };
    } catch (error) {
      handlePreviewError(error);
    }
  }

  async afterModel(model) {
    let { mvbId } = this.paramsFor(this.routeName);

    if (mvbId || (model.previewId !== undefined && model.previewId !== model.preview.id)) {
      this.router.replaceWith({ ...model, previewId: model.preview.id }, { queryParams: { mvbId: '' } });
      return;
    }

    if (!this.session.isAuthenticated) {
      this.router.replaceWith('presentations.preview.cover', { preview: model.preview });
      return;
    }

    if (model.preview.isHugendubel) {
      await all([this.categoryOfGoods.load(HUGENDUBEL_MVB_ID), this.collectionOfGoods.load(HUGENDUBEL_MVB_ID)]);
      model.assortmentGroups = await this.collectionOfGoods.getSortedAssortmentGroups();
    }
  }

  serialize(model) {
    return {
      preview_id: model.preview.id,
    };
  }
}
