import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateBranchIdUniqueness(/* options = {} */) {
  return (key, newValue /* oldValue, changes, content*/) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let branchesService = applicationUtil.lookup('service:branches');

    let value = String(newValue).trim();

    if (branchesService.current.map((branch) => branch.identificationNumber.trim()).includes(value)) {
      return buildMessage(key, {
        message: intlService.t('validation.uniqueness.notUnique', {
          gender: 'female',
          value: intlService.t('validation.party.branches.identificationNumber.description'),
        }),
      });
    }

    return true;
  };
}
