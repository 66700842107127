import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

/**
 * Pass in a callback function that will be called when the element's children are modified.
 * Pass in options for the MutationObserver instance via an `options` hash or by setting the options
 * on the modifier directly.
 *
 * Usage example:
 * ```hbs
 *  <div {{observe-mutation this.someActionName subtree=true childList=true}}>...</div>
 */

function cleanup(instance) {
  instance.observer?.disconnect();
  instance.callback = null;
}

export default class ObserveMutationModifier extends Modifier {
  callback;
  observer;

  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  modify(element, [_callback, options], hash) {
    let observerOptions = {
      attributeFilter: undefined,
      attributeOldValue: false,
      attributes: false,
      characterData: false,
      characterDataOldValue: false,
      childList: false,
      subtree: false,
      ...options,
      ...hash,
    };

    this.callback = (changes, instance) => _callback(element, changes, instance);
    this.observer = new MutationObserver(this.callback);
    this.observer.observe(element, observerOptions);
  }
}
