import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<td data-test-order-history-order-date>\n  <LinkTo @route=\"order.order-history.order\" @model={{@order.id}} data-test-order-link>\n    {{format-date @order.orderDate format=\"ddMMyyyyhhmmss\" hourCycle=\"h23\"}}\n  </LinkTo>\n</td>\n<td data-test-order-history-dispo-lists>\n  {{@order.dispoLists}}\n</td>\n<td data-test-order-history-ordered-by-name>\n  {{@order.orderedByName}}\n</td>\n<td data-test-order-history-order-channel-type>\n  {{this.orderChannelType}}\n</td>\n<td data-test-order-history-product-count>\n  {{this.productCount}}\n</td>\n<td data-test-order-history-total-amount>\n  <span local-class=\"currency\">\n    {{this.totalAmount.currency}}\n  </span>\n  <span>\n    {{#if this.totalAmount.value}}\n      {{format-number this.totalAmount.value format=\"price\"}}\n    {{else}}\n      {{t \"layoutPriceBlock.text.priceEmpty\"}}\n    {{/if}}\n  </span>\n</td>", {"contents":"<td data-test-order-history-order-date>\n  <LinkTo @route=\"order.order-history.order\" @model={{@order.id}} data-test-order-link>\n    {{format-date @order.orderDate format=\"ddMMyyyyhhmmss\" hourCycle=\"h23\"}}\n  </LinkTo>\n</td>\n<td data-test-order-history-dispo-lists>\n  {{@order.dispoLists}}\n</td>\n<td data-test-order-history-ordered-by-name>\n  {{@order.orderedByName}}\n</td>\n<td data-test-order-history-order-channel-type>\n  {{this.orderChannelType}}\n</td>\n<td data-test-order-history-product-count>\n  {{this.productCount}}\n</td>\n<td data-test-order-history-total-amount>\n  <span local-class=\"currency\">\n    {{this.totalAmount.currency}}\n  </span>\n  <span>\n    {{#if this.totalAmount.value}}\n      {{format-number this.totalAmount.value format=\"price\"}}\n    {{else}}\n      {{t \"layoutPriceBlock.text.priceEmpty\"}}\n    {{/if}}\n  </span>\n</td>","moduleName":"@mvb/tix-ui/components/order-history/index-row.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/order-history/index-row.hbs"}});
import { CURRENCIES_DACH } from '@mvb/tix-ui/constants';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class OrderHistoryIndexRowComponent extends Component {
  @service intl;
  @service user;

  get orderChannelType() {
    return isPresent(this.args.order?.orderChannelType)
      ? this.intl.t(`orderChannelType.${this.args.order.orderChannelType}`)
      : '';
  }

  get productCount() {
    return this.args.order?.productCount ?? 0;
  }

  get totalAmount() {
    let selectedCurrencyCountry = this.user.selectedCurrencyCountry || CURRENCIES_DACH.EUR_DE;

    return {
      value: this.args.order?.totalAmount ?? 0,
      currency: this.intl.t(`modelProduct.text.currencyCountry.${selectedCurrencyCountry}`),
    };
  }
}
