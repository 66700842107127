import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  if (instance.target) {
    instance.target.container = null;
  }
}

export default class SaveElementModifier extends Modifier {
  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  modify(element, [target]) {
    target.container = element;
  }
}
