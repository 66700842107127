import { validateLength } from 'ember-changeset-validations/validators/index';
import validateOrderRowDispoListHug from '@mvb/tix-ui/validators/order-row-dispo-list-hug';
import validateOrderRowHugListingRecommendationCodes from '@mvb/tix-ui/validators/order-row-hug-listing-recommendation-codes';

export default {
  // no validation rules for collectionOfGoods
  dispoList: [validateOrderRowDispoListHug({ description: 'intl:validation.dispolist.description.dispolist' })],
  hugListingRecommendationCodes: [
    validateOrderRowHugListingRecommendationCodes({ description: 'intl:validation.dispolist.description.listing' }),
  ],
  note: [validateLength({ description: 'intl:validation.dispolist.description.note', max: 35 })],
};
