import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

const ORIGIN_TO_AREA = {
  'search.index': 'Suche',
  'order.dispo-lists.dispo-list.index': 'Dispoliste',
  'order.order-list.index': 'Warenkorb',
  unknown: 'Produkt',
};

export default class ProductsProductIndexRoute extends Route {
  @service abilities;
  @service dispoListProductEntries;
  @service jump;
  @service metrics;
  @service sessionStorage;
  @service store;
  @service theme;

  queryParams = {
    asset: {
      refreshModel: false,
    },
  };

  async model() {
    let isSpecialsRoute = this.routeName.startsWith('specials');
    let productModel = isSpecialsRoute ? this.modelFor('specials.product') : this.modelFor('products.product');
    let product = productModel.product;
    let publishedStatus = productModel.publishedStatus;

    let dispoListProductEntries =
      this.abilities.can('dispoList.viewDispoListsInProductPages') && !isSpecialsRoute
        ? await this.dispoListProductEntries.fetchByIsbns(product.gtin)
        : [];

    this.dispoListProductEntries.cleanup();
    dispoListProductEntries = this.dispoListProductEntries.mapSearchProduct({
      dlpes: dispoListProductEntries,
      searchProduct: product,
    }).dispoListProductEntries;

    return {
      dispoListProductEntries,
      product,
      publishedStatus,
    };
  }

  async afterModel(model, transition) {
    let {
      product: {
        gtin,
        id,
        isThemenspecial,
        mainPublisher: { idValue: mvbId },
        mainTitle: { title: name },
        productInfo,
      },
    } = model;
    let data = {
      area: ORIGIN_TO_AREA[transition.from?.name ?? 'unknown'],
      MVBIDPRODUCT: mvbId,
      title: {
        'BOOK.MVBID': mvbId,
        'BOOK.ISBN': gtin,
        'BOOK.NAME': name,
        'BOOK.URLCODE': id,
      },
    };

    if (isThemenspecial) {
      this.theme.rootBackgroundColor = productInfo?.backgroundColor ?? null;

      // Adjust tracking data
      data.area = 'Special';
      data.title = {
        'SPECIAL.MVBID': mvbId,
        'SPECIAL.ISBN': gtin,
        'SPECIAL.NAME': name,
        'SPECIAL.URLCODE': id,
      };
    }

    this.metrics.trackSpecific(data);

    return super.afterModel(...arguments);
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }

    this.sessionStorage.notesDraggablePosition = null;
    this.sessionStorage.wawiBoxDraggablePosition = null;
    this.theme.rootBackgroundColor = null;
  }
}
