import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::SearchBar\n  ...attributes\n  data-test-inline-search-term={{@filter}}\n  @name={{@filter}}\n  @label={{@label}}\n  @onSearch={{this.onSearch}}\n  @placeholder={{@placeholder}}\n  @style=\"default\"\n  @minLength={{@minLength}}\n  @value={{@value}}\n/>", {"contents":"<Ui::SearchBar\n  ...attributes\n  data-test-inline-search-term={{@filter}}\n  @name={{@filter}}\n  @label={{@label}}\n  @onSearch={{this.onSearch}}\n  @placeholder={{@placeholder}}\n  @style=\"default\"\n  @minLength={{@minLength}}\n  @value={{@value}}\n/>","moduleName":"@mvb/tix-ui/components/search/filter/inline-search-term/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/inline-search-term/index.hbs"}});
import { action } from '@ember/object';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterInlineSearchTermIndexComponent extends SearchFilterBaseComponent {
  @action
  onSearch(value) {
    this.search({
      [this.args.filter]: value || null,
    });
  }
}
