import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Button\n  data-test-mvbid={{@mvbId}}\n  data-test-toggle-favorite={{if this.active \"active\" \"inactive\"}}\n  @icon={{if this.active \"square-check\" \"square\"}}\n  @label={{t (if this.active \"partyFavoriteToggle.label.active\" \"partyFavoriteToggle.label.inactive\")}}\n  @onClick={{this.toggleFavorite}}\n  @style=\"link\"\n/>", {"contents":"<Ui::Button\n  data-test-mvbid={{@mvbId}}\n  data-test-toggle-favorite={{if this.active \"active\" \"inactive\"}}\n  @icon={{if this.active \"square-check\" \"square\"}}\n  @label={{t (if this.active \"partyFavoriteToggle.label.active\" \"partyFavoriteToggle.label.inactive\")}}\n  @onClick={{this.toggleFavorite}}\n  @style=\"link\"\n/>","moduleName":"@mvb/tix-ui/components/party/toggle-favorite/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/party/toggle-favorite/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PartyToggleFavoriteComponent extends Component {
  @service party;
  @service user;

  get active() {
    return this.user.favoritePublishers?.includes(this.args.mvbId) ?? false;
  }

  @action
  async toggleFavorite() {
    await this.party.toggleFavoriteTask.perform(this.args.mvbId, this.active);
  }
}
