import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @elements}}\n  {{#if (gt @elements.length 1)}}\n    <button type=\"button\" local-class=\"button\" {{on \"click\" this.onToggle}} data-test-bookgroup-labels-button>\n      {{t \"productLabels.text.bookgroups\" count=@elements.length}}\n      <FaIcon @icon={{if this.isOpen \"chevron-up\" \"chevron-down\"}} local-class=\"icon\" @prefix=\"far\" />\n    </button>\n  {{/if}}\n\n  <div local-class=\"labels {{if this.isOpen 'open'}}\" data-test-bookgroup-labels>\n    {{#each @elements as |label|}}\n      <Ui::Label @label={{label}} @isPlain={{true}} />\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if @elements}}\n  {{#if (gt @elements.length 1)}}\n    <button type=\"button\" local-class=\"button\" {{on \"click\" this.onToggle}} data-test-bookgroup-labels-button>\n      {{t \"productLabels.text.bookgroups\" count=@elements.length}}\n      <FaIcon @icon={{if this.isOpen \"chevron-up\" \"chevron-down\"}} local-class=\"icon\" @prefix=\"far\" />\n    </button>\n  {{/if}}\n\n  <div local-class=\"labels {{if this.isOpen 'open'}}\" data-test-bookgroup-labels>\n    {{#each @elements as |label|}}\n      <Ui::Label @label={{label}} @isPlain={{true}} />\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/labels/bookgroup.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/labels/bookgroup.hbs"}});
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class ProductLabelsBookgroupComponent extends Component {
  @tracked isOpen = this.args.elements.length === 1;

  @action
  onToggle() {
    this.isOpen = !this.isOpen;
  }
}
