import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { PREVIEW_TYPE, SEARCH_MODE, SECTION_TYPES, URL_PRODUCT_EXPORT_PREVIEW_HUGENDUBEL } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import saveBlob from '@mvb/tix-ui/utils/save-blob';

export default class PreviewsPreviewIndexController extends Controller {
  @service abilities;
  @service api;
  @service dispoLists;
  @service errors;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service('products') productsService;
  @service productList;
  @service router;
  @service('search-products-in-preview') searchService;
  @service store;
  @service user;

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;
  SECTION_TYPES = SECTION_TYPES;

  queryParams = ['qp'];
  @tracked qp = '';

  get abilityModel() {
    return {
      sender: this.model.preview.sender?.mvbId,
      creator: this.model.preview.creatorId,
      type: this.model.preview.type,
    };
  }

  get addToDispoListLabel() {
    return this.productsService.getAddToDispoListLabel(this.maxProductCountExceeded);
  }

  get addToProductListLabel() {
    return this.productsService.getAddToProductListLabel(this.maxProductCountExceeded);
  }

  get isItemsSelectionEmpty() {
    return this.productsService.getIsItemsSelectionEmpty(this.searchService.meta.total);
  }

  get maxProductCountExceeded() {
    return this.productsService.getMaxProductCountExceeded(this.searchService.meta.total);
  }

  get showWebToPrintTemplateSelect() {
    return this.abilities.can('preview.selectWebToPrintTemplate', this.model.preview?.abilityModel);
  }

  get selectedIds() {
    return this.store
      .peekAll('search-product')
      .filter((product) => this.manageList.selectedItems.has(product.id))
      .map((product) => product.id);
  }

  get showCustomMarketingContent() {
    return ![PREVIEW_TYPE.STANDARDVORSCHAU, PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model.preview?.type);
  }

  @action
  editPreview() {
    this.router.transitionTo('previews.preview.edit', this.model.preview.id);
  }

  @action
  onClonePreviewCompleted() {
    // reset the locked state of the preview
    this.store.pushRecordAttributes('preview', this.model.preview.id, { locked: false });
  }

  @action
  async onExportExcelHugendubel() {
    await this.exportExcelHugTask.perform();
  }

  @action
  async onAddRecommendedAmount() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('modalAddToDispoList.notification.noItemsSelectedForRecommendedAmounts'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    let affectsResults = false;

    try {
      let filters = this.searchService.searchQuery.filter || {};
      //remove filter that is only needed for rendering products
      delete filters.onList;

      filters.searchMode = SEARCH_MODE.FIXED_BATCH;
      delete filters['preview.id'];

      affectsResults = await this.dispoLists.addToDispoList({
        description: this.intl.t('modalAddToDispoList.text.descriptionForRecommendedAmounts'),
        filters,
        invertedSelection: this.manageList.inverse,
        previewId: this.model.preview.id,
        selectedItems: this.selectedIds,
        successMessage: this.intl.t('modalAddToDispoList.notification.successForRecommendedAmounts'),
        title: this.intl.t('modalAddToDispoList.text.titleForRecommendedAmounts'),
      });
    } catch (error) {
      this.errors.handle(error);
    }

    if (affectsResults) {
      this.reloadResults();
    }
  }

  @action
  async onAddToDispoList() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('modalAddToDispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    let affectsResults = false;

    try {
      let filters = this.searchService.searchQuery.filter || {};
      //remove filters that are only needed for rendering products
      delete filters.onList;
      delete filters['section.id'];

      filters.searchMode = SEARCH_MODE.FIXED_BATCH;

      affectsResults = await this.dispoLists.addToDispoList({
        filters: {
          ...filters,
          'preview.id': this.model.preview.id,
        },
        invertedSelection: this.manageList.inverse,
        selectedItems: [...this.manageList.selectedItems],
        successMessage: this.intl.t('modalAddToDispoList.notification.success'),
        title: this.intl.t('modalAddToDispoList.text.title'),
      });
    } catch (error) {
      this.errors.handle(error);
    }

    if (affectsResults) {
      this.reloadResults();
    }
  }

  @action
  onAddToProductList() {
    let filters = this.searchService.searchQuery.filter;
    //remove filters that are only needed for rendering products
    delete filters.onList;
    delete filters['section.id'];
    filters['preview.id'] = this.model.preview.id;
    this.productList.addProductsToList(this.searchService.meta.total, filters);
  }

  @action
  onBookmark() {
    // TODO: this is the action for the function bar --> see user story #26730
  }

  @action
  onPreview() {}

  @action
  onSetBookmark(bookmarked) {
    // eslint-disable-next-line no-console
    console.log(bookmarked);
    // TODO: this is the action for the single element, actual logic comes with different ticket --> see user story #26730
  }

  @action
  reloadResults() {
    this.searchService.search();
  }

  @task
  @waitFor
  *onUpdateTemplateTask(sectionContent, template) {
    sectionContent.set('webToPrintTemplate', template === '' ? null : template);
    try {
      let previewId = sectionContent.section?.preview?.get('id');
      if (previewId != null) {
        yield this.api.postJSON('/section-content/web-to-print-template', {
          previewId,
          products: [
            {
              referencedProductId: sectionContent.referencedProductId,
              webToPrintTemplate: sectionContent.webToPrintTemplate,
            },
          ],
        });
      }
      return this.notifications.success(this.intl.t('webToPrintTemplate.save.success'));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *exportExcelHugTask() {
    try {
      let previewId = this.model.preview?.id;

      let url = `${URL_PRODUCT_EXPORT_PREVIEW_HUGENDUBEL}${previewId}`;

      let { blob, attachmentName } = yield this.api.get(url);
      saveBlob(blob, attachmentName);

      return this.notifications.success(this.intl.t('uiFunctionBarExport.notification.export'));
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
