import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateProductPriceFilter() {
  return (key, newValue, _oldValue, changes, content) => {
    let intlService = applicationUtil.lookup('service:intl');
    let priceLimitLower = Number.parseFloat(changes.priceLimitLower ?? content.priceLimitLower);
    if (priceLimitLower > Number.parseFloat(newValue)) {
      return buildMessage(key, { message: intlService.t('validation.priceFilter.upperLimitInvalid') });
    }
    return true;
  };
}
