import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SearchFiltersBaseComponent extends Component {
  @service queryParams;
  @service user;

  get assignedMvbIds() {
    return this.user.assignedPartiesMvbIds;
  }

  get currentQueryParams() {
    return this.queryParams.currentQueryParamsWithDefaults;
  }

  get favoritePublishers() {
    return this.user.favoritePublishers;
  }
}
