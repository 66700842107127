import Service from '@ember/service';

export default class ThemeService extends Service {
  manageRootStyleProperties = new Map();

  /**
   * Set `--custom-properties:...` on the <html> element
   * @param {string} property - name of the CSS custom property to set, including `--` prefix
   * @param {string|null} value - a valid CSS value definition for the given property or null to remove the property
   */
  updateRootStyle(property, value) {
    let root = document.documentElement;

    if (value !== null && value !== undefined) {
      this.manageRootStyleProperties.set(property, value);
      root.style.setProperty(property, value);
    } else {
      this.manageRootStyleProperties.delete(property);
      root.style.removeProperty(property);
    }
  }

  /**
   * Set the background color of the document element to the given value.
   * @param {string} value - a valid CSS value definition for the 'background' property
   */
  set rootBackgroundColor(value) {
    this.updateRootStyle('--root-bg', value);
  }

  willDestroy() {
    for (let [property] of this.manageRootStyleProperties) {
      this.updateRootStyle(property, null);
    }

    super.willDestroy(...arguments);
  }
}
