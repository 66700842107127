import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';

export default helper(function nl2br([text]) {
  if (isEmpty(text)) {
    return null;
  }
  let breakTag = '<br />';
  let replacedString = text.replaceAll(/(\r\n|\n\r|\r|\n)/g, breakTag);

  return htmlSafe(replacedString);
});
