import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @headline}}\n  <h4 local-class=\"label\" for={{@name}} data-label={{@name}}>\n    {{@headline}}\n  </h4>\n{{/if}}\n<Ui::Input::Checkbox\n  data-test-checkbox={{@name}}\n  @label={{@label}}\n  @name={{@name}}\n  @onChange={{this.onSearch}}\n  @value={{@value}}\n/>", {"contents":"{{#if @headline}}\n  <h4 local-class=\"label\" for={{@name}} data-label={{@name}}>\n    {{@headline}}\n  </h4>\n{{/if}}\n<Ui::Input::Checkbox\n  data-test-checkbox={{@name}}\n  @label={{@label}}\n  @name={{@name}}\n  @onChange={{this.onSearch}}\n  @value={{@value}}\n/>","moduleName":"@mvb/tix-ui/components/search/filter/checkbox/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/checkbox/index.hbs"}});
import { action } from '@ember/object';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterCheckboxComponent extends SearchFilterBaseComponent {
  @action
  onSearch(value) {
    this.search({
      [this.args.filter]: value || null,
    });
  }
}
