export default {
  time: {
    hhmmss: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
  date: {
    hhmm: {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
    },
    ddMMyyyy: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    ddMMyyyyhhmm: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
    },
    ddMMyyyyhhmmss: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h24',
    },
  },
  number: {
    price: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};
