import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class OrderListModel extends Model {
  @attr('date') created;
  @attr('date') lastUpdated;

  @attr('boolean') open;
  @attr() totalPrice;
  @attr() productCount;

  @belongsTo('party', { async: true, inverse: null }) party;

  @hasMany('order-list-product', { async: true, inverse: 'orderList' }) orderListProducts;
}
