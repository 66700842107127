import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyWebshopLinkRoute extends Route {
  @service abilities;
  @service store;
  @service user;

  async model() {
    if (this.abilities.can('party.viewWebshopLinkSettings')) {
      let party = this.user.selectedParty?.party;
      let webshopLinks = await this.store.query('webshop-link', {
        filter: {
          partyId: party?.id,
        },
      });
      let webshopLink = webshopLinks[0];

      return { webshopLink };
    }
  }
}
