export default {
  "reference-product": "_reference-product_103n0g",
  "cover": "_cover_103n0g",
  "cover-link": "_cover-link_103n0g",
  "bibliography": "_bibliography_103n0g",
  "purchase": "_purchase_103n0g",
  "price-block": "_price-block_103n0g",
  "order-button": "_order-button_103n0g",
  "sales-figures": "_sales-figures_103n0g",
  "star-icon": "_star-icon_103n0g",
  "order-section": "_order-section_103n0g"
};
