import { didCancel, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';

export default class CategoryOfGoodsService extends Service {
  @service errors;
  @service user;
  @service store;

  get current() {
    return (
      this.loadTask.lastSuccessful?.value ?? {
        categories: [],
        mvbId: null,
      }
    );
  }

  @task({ drop: true })
  @waitFor
  *loadTask(mvbId) {
    let lastSuccessfulValue = this.loadTask.lastSuccessful?.value;
    if (lastSuccessfulValue?.mvbId === mvbId) {
      return lastSuccessfulValue;
    }

    try {
      let categories = [];

      if (mvbId) {
        categories = yield this.store.query('category-of-goods', {
          filter: {
            mvbId,
          },
        });
      }

      return {
        categories,
        mvbId,
      };
    } catch (error) {
      this.errors.handle(error);
    }
  }

  async load() {
    try {
      return await this.loadTask.perform(...arguments);
    } catch (error) {
      if (didCancel(error)) {
        return this.loadTask.last;
      } else {
        throw error;
      }
    }
  }
}
