import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-total-block local-class=\"total-block\" ...attributes>\n  <h3 data-test-total-items>\n    {{t \"listViewProductTotal.text.items\" quantity=this.totalItems}}\n  </h3>\n  <p data-test-total-cost>\n    {{t\n      (concat \"listViewProductTotal.text.price.\" @totalParams.currency)\n      price=(format-number this.totalPrice format=\"price\")\n    }}\n  </p>\n</div>", {"contents":"<div data-test-total-block local-class=\"total-block\" ...attributes>\n  <h3 data-test-total-items>\n    {{t \"listViewProductTotal.text.items\" quantity=this.totalItems}}\n  </h3>\n  <p data-test-total-cost>\n    {{t\n      (concat \"listViewProductTotal.text.price.\" @totalParams.currency)\n      price=(format-number this.totalPrice format=\"price\")\n    }}\n  </p>\n</div>","moduleName":"@mvb/tix-ui/components/list-view/product/total-block.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/list-view/product/total-block.hbs"}});
import Component from '@glimmer/component';

export default class ListViewProductTotalBlockComponent extends Component {
  get totalItems() {
    if (!this.args?.totalParams?.entries?.length) {
      return 0;
    }

    let quantities = this.args.totalParams.entries.map((entries) => entries.quantity);
    let totalItems = 0;

    for (let quantity of quantities) {
      quantity = Number.isInteger(quantity) ? quantity : Number.parseInt(quantity, 10);
      totalItems += quantity;
    }

    return totalItems;
  }

  get totalPrice() {
    let chosenPrice = this.args.product?.aggregatedPrices?.find((price) =>
      this.args.totalParams.currency.toLowerCase().includes(price.currency.toLowerCase())
    )?.value;
    return chosenPrice * this.totalItems;
  }
}
