export default function isProductOnDispoList({ dispoListProducts, dispoListProductEntries, productId }) {
  return dispoListProducts.some(
    (dispoListProduct) =>
      (dispoListProduct.belongsTo('product').id() === productId ||
        dispoListProduct.belongsTo('searchProduct').id() === productId) &&
      dispoListProductEntries.some(
        (dispoListProductEntry) => dispoListProductEntry.belongsTo('dispoListProduct').id() === dispoListProduct.id
      )
  );
}
