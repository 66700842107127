import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class BackofficeAdminsDetailRoute extends Route {
  @service store;

  async model({ admin_id: adminId }) {
    return this.store.findRecord('user', adminId);
  }
}
