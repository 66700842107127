import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class BackgroundProcessModel extends Model {
  @attr('string') userId;
  @attr('string') partyId;
  @attr('string') type;
  @attr() startTime;
  @attr() endTime;
  @attr('string') status; /** can have any of the values of {@link BACKGROUND_PROCESS_STATUS} */
  @attr('number') currentStep;
  @attr('number') totalSteps;
  @attr('string') currentMessage;
  @attr('string') currentTitle;
  @attr() result;

  @belongsTo('preview', { async: false, inverse: 'backgroundProcess' }) preview;
}
