import { DateTime } from 'luxon';
import { isEmpty } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';

export default class DateTransform extends Transform {
  deserialize(serialized) {
    return isEmpty(serialized) ? serialized : DateTime.fromISO(serialized);
  }

  serialize(deserialized) {
    return isEmpty(deserialized) ? deserialized : deserialized.toISODate();
  }
}
