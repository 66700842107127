import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.editionInfo}}\n  <h4 data-test-edition>\n    {{t \"productAdvancedBib.label.edition\"}}\n  </h4>\n\n  <Layout::AsList data-test-edition-list @list={{this.editionInfo}} as |element|>\n    {{#if element.code}}\n      {{cl element.list element.code~}}\n    {{/if}}\n    {{~element.text~}}\n  </Layout::AsList>\n\n  {{yield}}\n{{/if}}", {"contents":"{{#if this.editionInfo}}\n  <h4 data-test-edition>\n    {{t \"productAdvancedBib.label.edition\"}}\n  </h4>\n\n  <Layout::AsList data-test-edition-list @list={{this.editionInfo}} as |element|>\n    {{#if element.code}}\n      {{cl element.list element.code~}}\n    {{/if}}\n    {{~element.text~}}\n  </Layout::AsList>\n\n  {{yield}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/advanced-bib/edition.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/advanced-bib/edition.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ProductAdvancedBibEditionComponent extends Component {
  @service intl;

  get editionInfo() {
    let edition = this.args.product?.edition;
    let publicationCity = this.args.product?.publicationCity;
    let publicationCountry = this.args.product?.publicationCountry;
    let info = [];

    if (edition?.number) {
      info.push({
        text: this.intl.t('productAdvancedBib.text.edition', { num: edition.number }),
      });
    }

    if (edition?.type) {
      let types = edition.type.split(',');
      info = info.concat(types.map((t) => ({ code: t.trim(), list: '21' })));
    }

    if (edition?.text) {
      info.push({
        text: edition.text,
      });
    }

    if (publicationCity) {
      info.push({
        text: publicationCity,
      });
    }

    if (publicationCountry) {
      info.push({
        code: publicationCountry,
        list: '91',
      });
    }

    return info;
  }
}
