export default {
  "profile": "_profile_11s99x",
  "image-wrapper": "_image-wrapper_11s99x",
  "label": "_label_11s99x _label_laqy2u",
  "form": "_form_11s99x",
  "space-before": "_space-before_11s99x",
  "space-after-row": "_space-after-row_11s99x",
  "space-after-section": "_space-after-section_11s99x",
  "even-split-wrapper": "_even-split-wrapper_11s99x",
  "tooltip": "_tooltip_11s99x",
  "tooltip-open": "_tooltip-open_11s99x",
  "additional-wrapper": "_additional-wrapper_11s99x",
  "options": "_options_11s99x",
  "actions": "_actions_11s99x",
  "btn-delete": "_btn-delete_11s99x",
  "btn-back": "_btn-back_11s99x",
  "btn-save": "_btn-save_11s99x",
  "blog-url-validations": "_blog-url-validations_11s99x"
};
