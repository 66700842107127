import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateIntegerWithoutCommaOrDot() {
  return (key, newValue) => {
    let inputAsString = String(newValue);
    let regExp = new RegExp(/,|\./g);
    let stringIncludesComma = inputAsString.match(regExp);
    if (!stringIncludesComma) {
      return true;
    }
    let intlService = applicationUtil.lookup('service:intl');
    let message = intlService.t('validation.numbers.wholeNumbersOnly');
    return buildMessage(key, {
      message,
    });
  };
}
