import { DEBOUNCE_SMALL, URL_VALIDATE_EAN, URL_VALIDATE_PROMOTIONAL_PACKAGE_IDENTIFIER } from '@mvb/tix-ui/constants';
import { isEmpty, isPresent } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class MdsService extends Service {
  @service api;
  @service intl;

  @tracked oldEanValue = null;

  /**
   * Validates if the passed in identifiers are valid
   *
   * @param {*} newValue the new value of the input
   * @param {*} oldValue the old value of the input
   * @returns
   */
  @task({ restartable: true })
  *validateEansTask(newValue, oldValue) {
    // sleep for some time so the user can type in more than one char --> results in fewer requests to the MDS
    yield timeout(DEBOUNCE_SMALL);

    if (isEmpty(oldValue) && isPresent(this.oldEanValue)) {
      oldValue = this.oldEanValue;
    }

    // if the value did not change, return existing messages
    let existingMessages = this.validateEansTask.lastSuccessful?.value ?? [];
    if (newValue === oldValue && !existingMessages.includes(this.intl.t('error.generic'))) {
      return existingMessages;
    }

    let identifiers = newValue;
    if (!Array.isArray(identifiers)) {
      identifiers = [identifiers];
    }

    try {
      let validationResponse = yield this.api.post(`${URL_VALIDATE_EAN}?isbns=${identifiers.join(',')}`);
      let someIdentifiersAreInvalid = validationResponse.some((response) => isPresent(response.cause));

      if (someIdentifiersAreInvalid) {
        let validationMessages = validationResponse.map(({ cause }) => {
          if (isPresent(cause)) {
            return this.intl.t(cause);
          }
        });

        return validationMessages;
      } else {
        return [];
      }
    } catch {
      return [this.intl.t('error.generic')];
    }
  }

  /**
   * Validates if the serialized product has a valid identifier.
   *
   * @param {*} product the product that should be validated
   * @param {*} newValue the new value of the input
   * @param {*} oldValue the old value of the input
   * @param {*} productInfo the productInfo that holds the changeset
   * @returns array of messages or empty array
   */
  @task({ restartable: true })
  *validateIdentifierTask(product, newValue, oldValue, productInfo) {
    // sleep for some time so the user can type in more than one char --> results in fewer requests to the MDS
    yield timeout(DEBOUNCE_SMALL);

    // if the value did not change, return existing messages
    let existingMessages = this.validateIdentifierTask.lastSuccessful?.value ?? [];
    if (newValue === oldValue && !existingMessages.includes(this.intl.t('error.generic'))) {
      return existingMessages;
    }

    // set the identifier on the productInfo
    productInfo.set('identifier', newValue);

    // serialize the product --> will run the same serializer as the one for the save process
    let serializedProduct = product.serialize();

    try {
      let validationResponse = yield this.api.postJSON(URL_VALIDATE_PROMOTIONAL_PACKAGE_IDENTIFIER, serializedProduct);

      if (isPresent(validationResponse?.identifier)) {
        let messages = validationResponse.identifier.map((code) => this.intl.t(code));
        return messages;
      } else {
        return [];
      }
    } catch {
      return [this.intl.t('error.generic')];
    }
  }
}
