import { isEmpty } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateErpSetupIdentifierLength(options = {}) {
  return async (key, newValue, _oldValue, changes, content) => {
    //changes.erp can be an empty object, so check for properties here
    let selectedErp = isEmpty(changes?.erp?.name) ? await content?.get('erp') : changes.erp;

    if (isEmpty(selectedErp) || isEmpty(newValue)) {
      return true;
    }

    let maxLength = selectedErp.orderFileFormat === 'BWA' ? 10 : 15;

    if (newValue.length > maxLength) {
      return buildMessage(key, {
        type: 'tooLong',
        value: newValue,
        context: { description: options.description, max: maxLength },
      });
    }

    return true;
  };
}
