import { PREVIEW_TYPE } from '@mvb/tix-ui/constants';
import Service, { service } from '@ember/service';

export default class CustomMarketingContentService extends Service {
  @service('custom-marketing-content-bookstore') cmcServiceForBookstore;
  @service('custom-marketing-content-hugendubel') cmcServiceForHugendubel;
  @service('custom-marketing-content-individual') cmcServiceForIndividual;
  @service('custom-marketing-content-thalia') cmcServiceForThalia;

  getCMCServiceForPreviewType(previewType) {
    switch (previewType) {
      case PREVIEW_TYPE.BOOKSTORE_PREVIEW: {
        return this.cmcServiceForBookstore;
      }
      case PREVIEW_TYPE.HUGENDUBEL: {
        return this.cmcServiceForHugendubel;
      }
      case PREVIEW_TYPE.THALIA: {
        return this.cmcServiceForThalia;
      }
      case PREVIEW_TYPE.INDIVIDUALVORSCHAU:
      default: {
        return this.cmcServiceForIndividual;
      }
    }
  }

  unloadServices() {
    this.cmcServiceForBookstore.unload();
    this.cmcServiceForHugendubel.unload();
    this.cmcServiceForIndividual.unload();
    this.cmcServiceForThalia.unload();
  }
}
