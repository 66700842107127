import { ASSET_MAX_SIZE } from '@mvb/tix-ui/constants';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import window from 'ember-window-mock';

const toMegaByte = (value) => value / (1024 * 1024);

/**
 * @typedef {Object} ValidateAssetFileSizeOptions
 * @property {number} maxSize=ASSET_MAX_SIZE the filesize to check for in bytes
 */

/**
 * validates if the size given in the options is greater or equal to the size of the file to check
 * @param {ValidateAssetFileSizeOptions} options
 */
export default function validateAssetFileSize(options = {}) {
  return (key, newValue /* , oldValue, changes, content */) => {
    let maxSize = options?.maxSize ?? ASSET_MAX_SIZE;

    if (!newValue || !(newValue instanceof window.File) || newValue.size <= maxSize) {
      return true;
    }

    options.maxSizeMb = toMegaByte(maxSize);
    options.size = toMegaByte(newValue.size ?? 0);

    let message = buildMessage(key, { type: 'isValidFileSize', value: newValue, context: options });

    return message;
  };
}
