import { isPresent } from '@ember/utils';
import { PRODUCT_COMPOSITION_TYPES, PRODUCT_FORM_TYPES } from '@mvb/tix-ui/constants';
import ApplicationSerializer from './application';

/**
 * the passed data gets normalized and prepared to be pushed in the store, including relationships
 *
 * @param {*} store ember data store, passed from normalizeResponse
 * @returns an array of contentIds, needed for relationships
 */
function normalizeContents(section, store) {
  let contentIds = [];

  for (let content of section.contents) {
    let contentId = content.id;
    delete content.id;

    contentIds.push(contentId);

    // prepare the belongsTo relationship
    let relationships = store.prepareRelationship({ modelName: 'section', id: section.id, belongsTo: true });
    store.pushRecordAttributesWithRelationships('section-content', contentId, content, relationships);
  }

  return contentIds;
}

/**
 * the passed data gets normalized and prepared to be pushed in the store, including relationships
 *
 * @param {*} sections sections data to be normalized
 * @param {*} store ember data store, passed from normalizeResponse
 * @returns an array of sectionIds, needed for relationships
 */
function normalizeSections(sections, store) {
  let sectionIds = [];
  let sectionRelationships = {};

  for (let section of sections) {
    let sectionId = section.id;

    if (isPresent(section.contents)) {
      let contentIds = normalizeContents(section, store);

      if (isPresent(contentIds)) {
        for (let contentId of contentIds) {
          sectionRelationships = store.prepareRelationship({
            modelName: 'section-content',
            id: contentId,
            relationships: sectionRelationships,
            relationshipName: 'contents',
          });
        }
      }

      delete section.contents;
    }

    if (isPresent(section.productInfo)) {
      let productInfoId = section.productInfo.id;
      delete section.productInfo.id;

      let productInfoRelationships = store.prepareRelationship({
        modelName: 'section',
        id: section.id,
        belongsTo: true,
      });
      store.pushRecordAttributesWithRelationships(
        'product-info',
        productInfoId,
        section.productInfo,
        productInfoRelationships
      );

      // add the relationship for the productInfo to the section relationships
      sectionRelationships = store.prepareRelationship({
        modelName: 'product-info',
        id: productInfoId,
        relationships: sectionRelationships,
        belongsTo: true,
      });

      delete section.productInfo;
    }

    if (isPresent(section.deliveryInfo)) {
      let deliveryInfoId = section.deliveryInfo.id;
      delete section.deliveryInfo.id;

      let deliveryInfoRelationships = store.prepareRelationship({
        modelName: 'section',
        id: section.id,
        belongsTo: true,
      });
      store.pushRecordAttributesWithRelationships(
        'delivery-info',
        deliveryInfoId,
        section.deliveryInfo,
        deliveryInfoRelationships
      );

      // add the relationship for the deliveryInfo to the section relationships
      sectionRelationships = store.prepareRelationship({
        modelName: 'delivery-info',
        id: deliveryInfoId,
        relationships: sectionRelationships,
        belongsTo: true,
      });

      delete section.deliveryInfo;
    }

    sectionRelationships = store.prepareRelationship({
      modelName: 'product',
      id: section.productId,
      relationships: sectionRelationships,
      belongsTo: true,
    });

    delete section.id;
    delete section.productId;

    store.pushRecordAttributesWithRelationships('section', sectionId, section, sectionRelationships);
  }

  return sectionIds;
}

export default class ProductSerializer extends ApplicationSerializer {
  // eslint-disable-next-line max-params
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let isPromotionalPackage =
      payload.form?.typeO3 === PRODUCT_FORM_TYPES.PACKAGE &&
      payload.productComposition === PRODUCT_COMPOSITION_TYPES.PROMOTIONAL_PACKAGE;
    let isThemenspecial =
      payload.form?.typeO3 === PRODUCT_FORM_TYPES.PACKAGE &&
      payload.productComposition === PRODUCT_COMPOSITION_TYPES.SPECIAL;

    if ((isPromotionalPackage || isThemenspecial) && ['createRecord', 'updateRecord'].includes(requestType)) {
      let payloadId = payload.id;

      // normalize relationships
      let sectionIds = normalizeSections(payload.sections, store);
      let productRelationships = {};

      for (let sectionId of sectionIds) {
        productRelationships = store.prepareRelationship({
          modelName: 'section',
          id: sectionId,
          relationships: productRelationships,
        });
      }

      // delete not needed attributes in the payload
      delete payload.id;
      delete payload.sections;

      // create a json:api conform payload
      return {
        data: {
          type: 'product',
          id: payloadId,
          attributes: payload,
          relationships: productRelationships,
        },
      };
    }

    return super.normalizeResponse(...arguments);
  }

  serialize(snapshot) {
    let payload = super.serialize(...arguments);
    payload.data.attributes.id = snapshot.id;

    if (snapshot.record.isProductCollection) {
      return payload.data.attributes;
    }

    return payload;
  }

  serializeHasMany(snapshot, json, relationship) {
    let key = relationship.key;

    if (!snapshot.record.isProductCollection) {
      return;
    }

    if (key === 'sections') {
      let sectionSnapshots = snapshot.hasMany(key);
      let serializedSections = [];

      for (let sectionSnapshot of sectionSnapshots) {
        let serializedSection = sectionSnapshot.serialize({ includeId: true });
        let serializedSectionAttributes = serializedSection.data.attributes;
        let hasContents = Object.prototype.hasOwnProperty.call(serializedSectionAttributes, 'contents');
        let hasDeliveryInfo = Object.prototype.hasOwnProperty.call(serializedSectionAttributes, 'deliveryInfo');
        let hasProductInfo = Object.prototype.hasOwnProperty.call(serializedSectionAttributes, 'productInfo');

        // only add new sections that actually have contents, deliveryInfo or productInfo so we don't save empty sections
        if (!sectionSnapshot.isNew || hasContents || hasDeliveryInfo || hasProductInfo) {
          serializedSectionAttributes.id = sectionSnapshot.id;
          serializedSections.push(serializedSectionAttributes);
        }
      }

      json.attributes[key] = serializedSections;
    } else {
      super.serializeHasMany(...arguments);
    }
  }
}
