import Route from '@ember/routing/route';

export default class ProfileUserIndexRoute extends Route {
  model() {
    return this.modelFor('profile.user');
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.reset();
    }
  }
}
