/**
 * takes the enum object (from the constants) and returns an array with objects ready to use for selects
 * @param {*} intl intl-service to lookup translations
 * @param {*} arrayOrObject enum object from tix/constants.js or an array with values
 * @param {*} translationPath something like `foo.bar.baz`
 */
export default function createOptions(intl, arrayOrObject, translationPath) {
  let values = Array.isArray(arrayOrObject) ? arrayOrObject : Object.values(arrayOrObject);

  return values.map((value) => {
    let text = translationPath.startsWith('cl_')
      ? intl.t(`${translationPath}_${value}`)
      : intl.t(`${translationPath}.${value}`);

    return {
      value,
      text,
    };
  });
}
