import { SEARCH_MODE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchProductsInOrderListService extends SearchBaseService {
  @service store;
  @service user;

  get queryParamsDefaults() {
    return {
      page: 1,
    };
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  createSearchQuery({ model: orderList, queryParams }) {
    let { page, size } = queryParams;
    return {
      filter: {
        'orderList.id': orderList.id,
      },
      include: [
        'orderListProductEntries',
        'orderListProductEntries.bookstoreBranch',
        'orderListProductEntries.collectionOfGoods',
        'orderListProductEntries.dispoList',
      ].join(','),
      page: {
        number: page,
        size,
      },
    };
  }

  async executeSearchQuery(query) {
    return await this.store.query('order-list-product', query);
  }

  async mapResults({ results: orderListProducts }) {
    let isbns = orderListProducts?.map((olp) => olp.productIsbn) ?? [];
    isbns = [...new Set(isbns.filter(Boolean))];

    let searchProducts =
      isbns.length > 0
        ? await this.store.query('search-product', {
            filter: {
              batchSearchIds: isbns.join(','),
              searchMode: SEARCH_MODE.FIXED_BATCH,
              unpaged: true,
            },
            include: 'notes',
          })
        : [];

    return (
      orderListProducts
        ?.map((orderListProduct) => ({
          orderListProduct,
          searchProduct: searchProducts.find((searchProduct) => searchProduct.gtin === orderListProduct.productIsbn),
        }))
        .filter((mappedProduct) => {
          return !!mappedProduct.searchProduct;
        }) ?? []
    );
  }
}
