import { attr, belongsTo } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import Model from './-base';

export default class OrderListProductEntryModel extends Model {
  @attr('dateTime') created;
  @attr('dateTime') deliveryDate;
  @attr() orderCode;
  @attr() note;
  @attr('number') quantity;
  @attr() dispoListName;

  @belongsTo('bookstore-branch', { async: false, inverse: null }) bookstoreBranch;
  @belongsTo('collection-of-goods', { async: false, inverse: null }) collectionOfGoods;
  @belongsTo('dispo-list', { async: false, inverse: null }) dispoList;
  @belongsTo('order-list-product', { async: false, inverse: 'orderListProductEntries' }) orderListProduct;

  get hasZeroQuantity() {
    return !this.quantity || isBlank(this.quantity);
  }
}
