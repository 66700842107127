import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { USER_ROLES } from '@mvb/tix-ui/constants';
import Controller from '@ember/controller';

export default class BackofficeAdminsIndexController extends Controller {
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service router;

  queryParams = ['page', 'sort'];

  @tracked page = 1;
  @tracked sort = 'lastname';
  @tracked users;

  @action
  async onDelete(user, event) {
    event.preventDefault();

    try {
      let deleteBoUser = await this.modals.confirm({
        title: this.intl.t('backofficeAdmins.modal.title'),
        message: this.intl.t('backofficeAdmins.modal.text'),
        cancel: this.intl.t('modalConfirm.action.cancel'),
        confirm: this.intl.t('modalConfirm.action.confirm'),
      });

      if (deleteBoUser) {
        if (user.roles.length > 1) {
          let boRoleIndex = user.roles.indexOf(USER_ROLES.TIX_BO);
          user.roles.splice(boRoleIndex, 1);
          await user.save();
          await this.router.refresh();
        } else {
          await user.destroyRecord();
        }
        this.notifications.success(this.intl.t('backofficeAdmins.notifications.success'));
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
