export default {
  "tag": "_tag_1thlqb",
  "editing": "_editing_1thlqb",
  "hidden": "_hidden_1thlqb hidden-aria",
  "input": "_input_1thlqb reset-button",
  "value": "_value_1thlqb reset-button",
  "icon": "_icon_1thlqb reset-button",
  "disabled": "_disabled_1thlqb",
  "value-disabled": "_value-disabled_1thlqb"
};
