import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  permissionSetName: validatePresence({
    description: 'intl:validation.assignedParties.permission',
    ignoreBlank: true,
    presence: true,
  }),
  parties: validateLength({ description: 'intl:validation.assignedParties.parties', min: 1 }),
};
