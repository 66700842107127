import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes local-class=\"advertising-list\">\n  {{#if (not this.isLoading)}}\n    {{#each @section.sortedContents as |content index|}}\n      <Product::Advertising::Card\n        @content={{content}}\n        @mailData={{this.mailData}}\n        @showAmount={{@showAmount}}\n        data-test-advertising-card={{index}}\n      />\n    {{/each}}\n  {{/if}}\n</div>", {"contents":"<div ...attributes local-class=\"advertising-list\">\n  {{#if (not this.isLoading)}}\n    {{#each @section.sortedContents as |content index|}}\n      <Product::Advertising::Card\n        @content={{content}}\n        @mailData={{this.mailData}}\n        @showAmount={{@showAmount}}\n        data-test-advertising-card={{index}}\n      />\n    {{/each}}\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/product/advertising/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/advertising/index.hbs"}});
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export default class ProductAdvertisingIndexComponent extends Component {
  @service api;
  @service user;
  @service errors;

  @tracked mailData;

  get isLoading() {
    return this.loadMailDataTask.isRunning;
  }

  constructor() {
    super(...arguments);

    this.loadMailDataTask.perform();
  }

  @task({ restartable: true })
  @waitFor
  *loadMailDataTask() {
    let mvbId = this.user.selectedParty?.party?.get('mvbId') || null;
    if (mvbId) {
      try {
        let mailData = yield this.api.get(`/locs/party/${mvbId}/mailData`);
        this.mailData = mailData;
      } catch (error) {
        this.errors.handle(error);
      }
    }
  }
}
