import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    passwordWasReset=this.passwordWasReset\n    changeset=this.changeset\n    submitTask=this.submitTask\n    resetPassword=this.resetPassword\n  )\n}}", {"contents":"{{yield\n  (hash\n    passwordWasReset=this.passwordWasReset\n    changeset=this.changeset\n    submitTask=this.submitTask\n    resetPassword=this.resetPassword\n  )\n}}","moduleName":"@mvb/tix-ui/components/user/reset-password/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/user/reset-password/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { URL_RESET_PASSWORD } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import resetPasswordValidations from '@mvb/tix-ui/validations/password-reset';

export default class UserResetPasswordComponent extends Component {
  @service api;

  changeset = createChangeset(
    {
      username: '',
    },
    resetPasswordValidations
  );

  @task
  @waitFor
  *submitTask() {
    yield this.changeset.validate();
    if (!this.changeset.isValid) {
      return;
    }

    try {
      yield this.api.postJSON(URL_RESET_PASSWORD, {
        username: this.changeset.username,
      });

      // Let users wait a little bit longer, makes it feel more important
      yield timeout(700);
    } catch (error) {
      this.errors.handle(error);
    }

    return true;
  }

  @action
  resetPassword(event) {
    event.preventDefault();

    return this.submitTask.perform();
  }

  get passwordWasReset() {
    return !!this.submitTask.lastSuccessful?.value;
  }
}
