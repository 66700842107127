export default {
  "tray": "_tray_1kf6el",
  "open": "_open_1kf6el",
  "carousel": "_carousel_1kf6el",
  "items": "_items_1kf6el",
  "measure": "_measure_1kf6el",
  "list": "_list_1kf6el",
  "jump": "_jump_1kf6el reset-button",
  "prev": "_prev_1kf6el",
  "next": "_next_1kf6el"
};
