import { action } from '@ember/object';
import { service } from '@ember/service';
import { USER_TYPE } from '../../constants';
import Controller from '@ember/controller';

export default class BackofficeAgbController extends Controller {
  @service api;
  @service intl;
  @service modals;
  @service notifications;
  @service errors;

  get userTypes() {
    return Object.values(USER_TYPE);
  }

  @action
  async resetTosForUserType(userType) {
    let messageKey = `registration.text.userType.${userType}`;
    let confirmed = await this.modals.confirm({
      title: this.intl.t('backofficeAgb.modal.title'),
      message: this.intl.t('backofficeAgb.modal.message', { userType: this.intl.t(messageKey) }),
      confirm: this.intl.t('modalConfirm.action.confirm'),
      cancel: this.intl.t('modalConfirm.action.cancel'),
    });

    if (!confirmed) {
      return;
    }

    try {
      await this.api.post(`/reset-tos-agreement/${userType}`);
      this.notifications.success(this.intl.t('backofficeAgb.notification.success'));
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
