import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyCustomerContactsUserRoute extends Route {
  @service abilities;
  @service router;
  @service store;

  async model({ user_id: userId }) {
    try {
      if (this.abilities.cannot('party.viewCustomerContacts')) {
        return;
      }

      let user = await this.store.findRecord('user', userId, {
        include: 'address,assignedParties.party,avatar,customerContacts.party',
      });

      return { user };
    } catch {
      this.router.replaceWith('party.customer-contacts.index');
    }
  }

  serialize(model) {
    return { user_id: model.user.id };
  }
}
