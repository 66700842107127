import {
  MAX_PRODUCTS_FOR_BATCH_ADD,
  MAX_PRODUCTS_FOR_EXPORT,
  MAX_PRODUCTS_FOR_PRESENTATION,
} from '@mvb/tix-ui/constants';
import queryParamsPack from '@mvb/tix-ui/utils/query-params-pack';
import Service, { service } from '@ember/service';

/**
 * This service holds all the functionality you can find in the function bar for products that would otherwise be duplicated.
 */
export default class ProductsService extends Service {
  @service dispoLists;
  @service errors;
  @service features;
  @service intl;
  @service modals;
  @service manageList;
  @service notifications;
  @service router;
  @service('search-products-in-list') searchService;
  @service('query-params') queryParamsService;

  MAX_PRODUCTS_FOR_BATCH_ADD = MAX_PRODUCTS_FOR_BATCH_ADD;
  MAX_PRODUCTS_FOR_EXPORT = MAX_PRODUCTS_FOR_EXPORT;
  MAX_PRODUCTS_FOR_PRESENTATION = MAX_PRODUCTS_FOR_PRESENTATION;

  getAddToDispoListLabel(maxProductCountExceeded) {
    return maxProductCountExceeded
      ? this.intl.t('uiFunctionBar.tooltip.dispoListMaxProductCountExceeded')
      : this.intl.t('uiFunctionBar.tooltip.addToDispolist');
  }

  getAddToProductListLabel(maxProductCountExceeded) {
    let defaultTooltip = this.features.isEnabled('manage-lists-in-modal')
      ? this.intl.t('uiFunctionBar.tooltip.addToProductLists')
      : this.intl.t('uiFunctionBar.tooltip.addToProductList');
    return maxProductCountExceeded
      ? this.intl.t('uiFunctionBar.tooltip.productListMaxProductCountExeeded')
      : defaultTooltip;
  }

  getExportLabel(maxProductCountExceeded) {
    return maxProductCountExceeded
      ? this.intl.t('uiFunctionBar.tooltip.maxProductCountForExportExceeded')
      : this.intl.t('uiFunctionBar.tooltip.exportToFile');
  }

  getIsItemsSelectionEmpty(total) {
    return this.manageList.isSelectionEmpty || total === 0;
  }

  getMaxProductCountExceeded(total) {
    let selectedProductCount = this.manageList.determineSelectedElementsCount(total);
    return selectedProductCount > this.MAX_PRODUCTS_FOR_BATCH_ADD;
  }

  getMaxProductCountForExportExceeded(total) {
    let selectedProductCount = this.manageList.determineSelectedElementsCount(total);
    return selectedProductCount > this.MAX_PRODUCTS_FOR_EXPORT;
  }

  async onAddToDispoList(total, filter) {
    if (this.getIsItemsSelectionEmpty(total)) {
      return this.modals.notify({
        message: this.intl.t('modalAddToDispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    try {
      let filters = filter || {};

      return await this.dispoLists.addToDispoList({
        filters,
        invertedSelection: this.manageList.inverse,
        selectedItems: [...this.manageList.selectedItems],
        successMessage: this.intl.t('modalAddToDispoList.notification.success'),
        title: this.intl.t('modalAddToDispoList.text.title'),
      });
    } catch (error) {
      this.errors.handle(error);
    }

    return false;
  }

  onDownload() {}

  onStartPresentation(id, openInNewTab = false) {
    // TODO --> #29915
    // could be the same for product search page and product list detail page
    let selectedElementsCount = this.manageList.determineSelectedElementsCount(this.searchService.meta.total);

    // if we have nothing selected, we assume we want to open the presentation mode with all products
    // this should have the same behaviour as the preview presentation mode
    if (selectedElementsCount === 0) {
      selectedElementsCount = this.searchService.meta.total;
    }

    // if we still have no elements, we show the notification so the user does not get confused why the presentation mode redirects back to the product list
    if (selectedElementsCount === 0) {
      this.modals.notify({
        message: this.intl.t('error.productList.noSelection'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
      return;
    }

    if (selectedElementsCount > this.MAX_PRODUCTS_FOR_PRESENTATION) {
      this.modals.notify({
        message: this.intl.t('error.productList.tooManySelected', {
          selected: this.intl.formatNumber(selectedElementsCount),
        }),
        close: this.intl.t('modalNotify.action.confirm'),
      });
      return;
    }

    let url = this.router.urlFor('presentations.product-list', id);
    let queryParams = {
      ...this.queryParamsService.currentQueryParams,
      selectedProductListProductIds: Array.from(this.manageList.selectedItems).join(','),
      inverse: this.manageList.inverse,
    };
    queryParams = queryParamsPack(queryParams);

    if (openInNewTab) {
      window.open(`${url}?qp=${queryParams.qp}`, '_blank').focus();
      return;
    }

    this.router.transitionTo('presentations.product-list', id, { queryParams });
  }

  async onDeleteProduct(product, sectionContentService) {
    let productTypeTranslationKey = product.isThemenspecial ? 'specials' : 'productsPromotionalPackages';

    let shouldDeleteProduct = await this.modals.confirm({
      title: this.intl.t(`${productTypeTranslationKey}.text.modalDeleteTitle`),
      message: this.intl.t(`${productTypeTranslationKey}.text.modalDeleteMessage`),
      confirm: this.intl.t(`${productTypeTranslationKey}.action.modalDeleteConfirm`),
      cancel: this.intl.t(`${productTypeTranslationKey}.action.modalDeleteCancel`),
    });

    if (!shouldDeleteProduct) {
      return;
    }

    try {
      if (sectionContentService) {
        sectionContentService.productIsDeleting = true;
      }
      this.router.transitionTo(product.isThemenspecial ? 'specials.index' : 'products.index');
      await product.destroyRecord();
      this.notifications.success(this.intl.t(`${productTypeTranslationKey}.notification.deleteSuccess`));
    } catch (error) {
      this.errors.handle(error);
      if (sectionContentService) {
        sectionContentService.productIsDeleting = false;
      }
    } finally {
      if (sectionContentService) {
        sectionContentService.productIsDeleting = false;
      }
    }
  }
}
