import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  comment: validateLength({ description: 'intl:validation.customerContact.description.comment', max: 255 }),
  contactType: validatePresence({
    description: 'intl:validation.customerContact.description.contactType',
    ignoreBlank: true,
    presence: true,
  }),
  parties: validateLength({ description: 'intl:validation.customerContact.description.parties', min: 1 }),
  user: validatePresence({ description: 'intl:validation.customerContact.description.user', presence: true }),
};
