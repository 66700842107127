import { all } from 'ember-concurrency';
import { REGEX_PHONE } from '@mvb/tix-ui/constants';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import { validateFormat, validateLength, validatePresence } from 'ember-changeset-validations/validators/index';
import BlogInfoValidations from '@mvb/tix-ui/validations/blog-info';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import Route from '@ember/routing/route';

export default class ProfileUserEditRoute extends Route {
  @service intl;
  @service store;

  async model() {
    let user = this.modelFor('profile.user');

    let userChangeset = createChangeset(user, {
      firstname: [
        validatePresence({ presence: true, description: this.intl.t('validation.user.description.firstname') }),
        validateLength({ max: 255, description: this.intl.t('validation.user.description.firstname') }),
      ],
      lastname: [
        validatePresence({ presence: true, description: this.intl.t('validation.user.description.lastname') }),
        validateLength({ max: 255, description: this.intl.t('validation.user.description.lastname') }),
      ],
      phone: [
        validateFormat({
          regex: REGEX_PHONE,
          allowBlank: true,
          message: this.intl.t('validation.user.phone'),
        }),
      ],
      mobile: [
        validateFormat({
          regex: REGEX_PHONE,
          allowBlank: true,
          message: this.intl.t('validation.user.phone'),
        }),
      ],
      fax: [
        validateFormat({
          regex: REGEX_PHONE,
          allowBlank: true,
          message: this.intl.t('validation.user.phone'),
        }),
      ],
    });

    let { address, blogInfo, isBlogger } = user;

    if (!address) {
      address = this.store.createRecord('address');
      userChangeset.address = address;
      scheduleOnce('afterRender', this, this.initializeAddress);
    }

    if (!blogInfo && isBlogger) {
      blogInfo = this.store.createRecord('blog-info', { user });
      userChangeset.blogInfo = blogInfo;
      scheduleOnce('afterRender', this, this.initializeBlogInfo);
    }

    this.addressChangeset = createChangeset(address);

    if (isBlogger) {
      this.blogInfoChangeset = createChangeset(blogInfo, BlogInfoValidations);
    }

    // to show validation errors for previously wrongly saved entities
    await all([this.blogInfoChangeset?.validate?.(), userChangeset?.validate?.()]);

    return {
      address: this.addressChangeset,
      blogInfo: this.blogInfoChangeset,
      user: userChangeset,
      userInStore: user,
    };
  }

  // for a new address model instance we set the properties to empty string so all attributes will be validated after the first change.
  initializeAddress() {
    this.addressChangeset.setProperties({
      street: '',
      zipcode: '',
      city: '',
      country: '',
      streetNumber: '',
    });
  }

  // for a new blogInfo model instance we set the properties to empty string so all attributes will be validated after the first change.
  initializeBlogInfo() {
    this.blogInfoChangeset?.setProperties({
      name: '',
      url: '',
      platforms: [],
    });
  }
}
