import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::File::Layout\n  {{did-update this.initFileTypeValidator @accept}}\n  {{did-update this.initFileSizeValidator @maxSize}}\n  {{did-update this.initImageDimensionsValidator @dimensions}}\n  {{did-update this.initImageRatioValidator @ratio}}\n  @accept={{@accept.types}}\n  @disabled={{@disabled}}\n  @fileName={{@fileName}}\n  @hideNativeErrors={{@hideNativeErrors}}\n  @multiple={{@multiple}}\n  @name={{@name}}\n  @onFileRemove={{@onFileRemove}}\n  @uploadTask={{if @multiple this.uploadMultipleTask this.uploadTask}}\n  @value={{@value}}\n  ...attributes\n/>", {"contents":"<Ui::Input::File::Layout\n  {{did-update this.initFileTypeValidator @accept}}\n  {{did-update this.initFileSizeValidator @maxSize}}\n  {{did-update this.initImageDimensionsValidator @dimensions}}\n  {{did-update this.initImageRatioValidator @ratio}}\n  @accept={{@accept.types}}\n  @disabled={{@disabled}}\n  @fileName={{@fileName}}\n  @hideNativeErrors={{@hideNativeErrors}}\n  @multiple={{@multiple}}\n  @name={{@name}}\n  @onFileRemove={{@onFileRemove}}\n  @uploadTask={{if @multiple this.uploadMultipleTask this.uploadTask}}\n  @value={{@value}}\n  ...attributes\n/>","moduleName":"@mvb/tix-ui/components/ui/input/file/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/file/index.hbs"}});
import { action } from '@ember/object';
import { getFormattedErrorMessage } from '@mvb/tix-ui/helpers/error-message';
import { isNone } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import Unorm from 'unorm';
import validateAssetFileImageDimensions from '@mvb/tix-ui/validators/asset-file-image-dimensions';
import validateAssetFileImageRatio from '@mvb/tix-ui/validators/asset-file-image-ratio';
import validateAssetFileSize from '@mvb/tix-ui/validators/asset-file-size';
import validateAssetFileType from '@mvb/tix-ui/validators/asset-file-type';

export class UploadError extends Error {
  constructor(message, file) {
    super(message);

    this.file = file;
  }
}

export default class UiInputFileComponent extends Component {
  @service api;
  @service intl;
  @service progress;

  fileSizeValidator;
  fileTypeValidator;
  overlayProgressState;
  ImageDimensionsValidator;
  ImageRatioValidator;

  constructor() {
    super(...arguments);

    this.initValidators();
  }

  initValidators() {
    this.initFileTypeValidator();
    this.initFileSizeValidator();
    this.initImageDimensionsValidator();
    this.initImageRatioValidator();
  }

  @action
  initFileTypeValidator() {
    if (!isNone(this.args.accept)) {
      let acceptedTypes = this.args.accept.types?.split(',') ?? [];
      this.fileTypeValidator = validateAssetFileType({
        acceptedMimeTypes: new Set(acceptedTypes),
        typeDescription: this.args.accept.description,
      });
    }
  }

  @action
  initFileSizeValidator() {
    if (!isNone(this.args.maxSize)) {
      this.fileSizeValidator = validateAssetFileSize({
        maxSize: this.args.maxSize,
      });
    }
  }

  @action
  initImageDimensionsValidator() {
    if (!isNone(this.args.dimensions)) {
      this.ImageDimensionsValidator = validateAssetFileImageDimensions(this.args.dimensions);
    }
  }

  @action
  initImageRatioValidator() {
    if (!isNone(this.args.ratio)) {
      this.ImageRatioValidator = validateAssetFileImageRatio(this.args.ratio);
    }
  }

  @task({ restartable: true })
  @waitFor
  *uploadTask(file) {
    return yield this.uploadFile(file);
  }

  @task
  @waitFor
  *uploadMultipleTask(file) {
    return yield this.uploadFile(file);
  }

  async uploadFile(file) {
    try {
      if (this.fileSizeValidator) {
        let validationResultSize = this.fileSizeValidator('file', file.file);
        if (validationResultSize !== true) {
          let validationResultSizeMessage = getFormattedErrorMessage(validationResultSize, this.intl);
          throw new UploadError(validationResultSizeMessage, file);
        }
      }

      if (this.fileTypeValidator) {
        let validationResultType = this.fileTypeValidator('file', file.file);
        if (validationResultType !== true) {
          let validationResultTypeMessage = getFormattedErrorMessage(validationResultType, this.intl);
          throw new UploadError(validationResultTypeMessage, file);
        }
      }

      if (this.ImageDimensionsValidator) {
        let validationResultDimensions = await this.ImageDimensionsValidator('file', file.file);
        if (validationResultDimensions !== true) {
          let validationResultDimensionsMessage = getFormattedErrorMessage(validationResultDimensions, this.intl);
          throw new UploadError(validationResultDimensionsMessage, file);
        }
      }

      if (this.ImageRatioValidator) {
        let validationResultRatio = await this.ImageRatioValidator('file', file.file);
        if (validationResultRatio !== true) {
          let validationResultRatioMessage = getFormattedErrorMessage(validationResultRatio, this.intl);
          throw new UploadError(validationResultRatioMessage, file);
        }
      }

      if (this.args.overlayProgressEnabled) {
        this.overlayProgressState = this.progress.add({ message: this.args.overlayProgressMessage });
      }

      this.args.onUpload?.(file);

      let payload = { filename: file.name, ...this.args.payload };

      // normalize name
      file.name = Unorm.nfc(file.name).replaceAll(/[^\w.]/gi, '-');
      payload.filename = Unorm.nfc(payload.filename).replaceAll(/[^\w.]/gi, '-');

      for (let key in payload) {
        if (isNone(payload[key])) {
          delete payload[key];
        }
      }

      let response = await file.upload(this.args.url, {
        headers: this.api.headers,
        fileKey: this.args.fileKey,
        data: payload,
        withCredentials: true,
      });

      let responseBody;
      try {
        responseBody = await response.json();
      } catch {
        responseBody = {};
      }

      this.overlayProgressState?.remove();
      this.args.onChange?.(file, responseBody);

      return { file, response };
    } catch (error) {
      file.queue?.remove(file);

      let errorResponse;
      try {
        errorResponse = await error.json();
      } catch {
        errorResponse = error?.body ?? error;
      }

      this.overlayProgressState?.remove();
      this.args.onError?.(errorResponse, file);

      throw new UploadError(errorResponse?.message, file);
    }
  }
}
