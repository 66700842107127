import { service } from '@ember/service';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import emailValidations from '@mvb/tix-ui/validations/email';
import Route from '@ember/routing/route';

export default class PartySettingsRoute extends Route {
  @service abilities;
  @service store;
  @service user;

  createPartySettingsChangeset(advertisingAddress = '') {
    return createChangeset(
      {
        email: advertisingAddress,
      },
      emailValidations
    );
  }

  async model() {
    if (this.abilities.can('party.viewSettings')) {
      let partyId = this.user.selectedParty?.belongsTo('party')?.id();
      let partySetting = partyId ? await this.store.findRecord('party-setting', partyId) : null;

      return {
        changeset: this.createPartySettingsChangeset(partySetting?.advertisingAddress),
        partySetting,
      };
    }
  }
}
