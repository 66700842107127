import { all } from 'ember-concurrency';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { Settings } from 'luxon';
import countries from 'i18n-iso-countries';
import CountriesDE from 'i18n-iso-countries/langs/de.json';
import CountriesES from 'i18n-iso-countries/langs/es.json';
import ENV from '@mvb/tix-ui/config/environment';
import Route from '@ember/routing/route';

export default class ApplicationRoute extends Route {
  @service api;
  @service darkmode;
  @service intl;
  @service jump;
  @service metrics;
  @service router;
  @service session;

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', this, this._trackPage);
  }

  willDestroy() {
    this.router.off('routeDidChange', this, this._trackPage);
    super.willDestroy(...arguments);
  }
  async beforeModel() {
    Settings.defaultLocale = ENV.i18n.defaultLocale;
    // we need to load the needed countries for every locale, there is unfortunately no way to only load the needed ones
    countries.registerLocale(CountriesDE);
    countries.registerLocale(CountriesES);

    await all([
      this.darkmode.setup(),
      this.intl.setLocale([ENV.i18n.locale]),
      this.jump.setup(),
      (async () => {
        await this.session.setup();
        await this.session.handleApplicationLoad();
      })(),
    ]);

    return super.beforeModel(...arguments);
  }

  _trackPage() {
    // needs to happen on next tick to ensure the correct page title is sent
    next(() => {
      this.metrics.trackPage({
        page: this.router.currentURL,
        title: document.title || this.router.currentRouteName || 'unknown',
      });
    });
  }
}
