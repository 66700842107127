export default {
  "label": "_label_iqt4e9 _label_laqy2u",
  "switch-outer": "_switch-outer_iqt4e9",
  "disabled": "_disabled_iqt4e9",
  "state-first": "_state-first_iqt4e9",
  "state-second": "_state-second_iqt4e9",
  "d-none": "_d-none_iqt4e9",
  "switch": "_switch_iqt4e9",
  "aria": "_aria_iqt4e9",
  "toggler": "_toggler_iqt4e9",
  "has-error": "_has-error_iqt4e9"
};
