export default {
  "input": "_input_jn0pq4",
  "label": "_label_jn0pq4 _label_laqy2u",
  "levels": "_levels_jn0pq4",
  "level": "_level_jn0pq4 reset-list",
  "placeholder": "_placeholder_jn0pq4",
  "divider": "_divider_jn0pq4",
  "path": "_path_jn0pq4",
  "clear": "_clear_jn0pq4",
  "info": "_info_jn0pq4"
};
