import applicationUtil from '@mvb/tix-ui/utils/application';

export default function validateErpSetupOrderChannels(/* options = {} */) {
  return async (_key, _newValue, _oldValue, changes, content) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');

    let isNoErpOptionSelected =
      changes && Object.prototype.hasOwnProperty.call(changes, 'erp') && changes.erp === undefined;
    if (isNoErpOptionSelected) {
      return true;
    }

    let erp = changes?.erp && Object.keys(changes.erp).length > 0 ? changes.erp : await content?.get('erp');
    let orderChannels = await content?.get('orderChannels');

    if (erp?.manageOrderChannels) {
      return orderChannels.length > 0 || intlService.t('validation.party.erp.orderChannels.tooFew');
    }

    return true;
  };
}
