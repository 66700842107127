import { attr } from '@ember-data/model';
import Model from './-base';

export default class OrderModel extends Model {
  @attr('date-time') orderDate;

  @attr('string', { defaultValue: '' }) orderKey;
  @attr('string', { defaultValue: '' }) dispoLists;
  @attr('string', { defaultValue: '' }) orderedByName;
  @attr('string', { defaultValue: '' }) orderChannelType;

  @attr() productCount;
  @attr() totalQuantity;
  @attr() totalAmount;
}
