import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template file is required for ember-css-modules to work }}", {"contents":"{{! template file is required for ember-css-modules to work }}","moduleName":"@mvb/tix-ui/components/ui/input/base.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/base.hbs"}});
import { action } from '@ember/object';
import { assert, runInDebug } from '@ember/debug';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

export default class UiInputBaseComponent extends Component {
  constructor() {
    super(...arguments);

    runInDebug(() => {
      assert(`Please pass the required @name argument to the ${this.constructor.name} component`, this.args.name);
    });
  }

  get label() {
    let labelString = this.args.label;
    let requiredSign = this.args.required ? ' * ' : '';

    if (labelString) {
      return labelString + requiredSign;
    }

    if (this.args.name) {
      return this.args.name + requiredSign;
    }

    return '';
  }

  get nameWithGuid() {
    return `${this.args.name}-${guidFor(this)}`;
  }

  get value() {
    if (this.args.value !== undefined) {
      return this.args.value;
    }

    let changesetValue = this.args.changeset?.get?.(this.args.path);

    // FIXME: Ember Changeset converts complex values like Luxons DateTime into Proxies,
    // which can only be accessed by looking into the .content property for some reason - Last checked: 2021-06-29
    return changesetValue?.content ?? changesetValue ?? undefined;
  }

  @action
  onChange({ target: { value } }) {
    if (this.args.changeset && this.args.path) {
      this.args.changeset.set(this.args.path, value);
    }

    this.args.onChange?.(value);
  }

  @action
  onBeforeInput(event) {
    this.args.onBeforeInput?.(event);
  }
}
