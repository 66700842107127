import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  document.body.removeEventListener('click', instance.onClick);
  instance.element?.removeEventListener('beforeinput', instance.onBeforeInput);
  instance.element?.removeEventListener('blur', instance.onBlur);
  instance.element?.removeEventListener('focus', instance.onFocus);
  instance.element?.removeEventListener('keydown', instance.onKeyDown);
  instance.element?.removeEventListener('keyup', instance.onKeyUp);
  instance.element?.removeEventListener('input', instance.onInput);

  if (instance.target) {
    instance.target.inputElement = null;
  }
}

export default class SuggestEventsModifier extends Modifier {
  element;
  target;

  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  modify(element, [target]) {
    document.body.removeEventListener('click', this.onClick);
    this.element?.removeEventListener('beforeinput', this.onBeforeInput);
    this.element?.removeEventListener('blur', this.onBlur);
    this.element?.removeEventListener('focus', this.onFocus);
    this.element?.removeEventListener('keydown', this.onKeyDown);
    this.element?.removeEventListener('keyup', this.onKeyUp);
    this.element?.removeEventListener('input', this.onInput);

    document.body.addEventListener('click', this.onClick);
    element.addEventListener('beforeinput', this.onBeforeInput);
    element.addEventListener('blur', this.onBlur);
    element.addEventListener('focus', this.onFocus);
    element.addEventListener('keydown', this.onKeyDown);
    element.addEventListener('keyup', this.onKeyUp);
    element.addEventListener('input', this.onInput);

    target.inputElement = element;

    this.element = element;
    this.target = target;
  }

  @action
  onBeforeInput(event) {
    this.target?.beforeInput?.(event);
  }

  @action
  onBlur(event) {
    this.target?.blur?.(event);
  }

  @action
  onClick(event) {
    if (this.target && !this.target.container?.contains(event.target ?? null)) {
      this.target.showSuggest = false;
    }
  }

  @action
  onFocus(event) {
    this.target?.focus?.(event);
  }

  @action
  onKeyDown(event) {
    this.target?.keyDown?.(event);
  }

  @action
  onKeyUp(event) {
    this.target?.keyUp?.(event);
  }

  @action
  onInput(event) {
    this.target?.input?.(event);
  }
}
