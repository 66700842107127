import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"status\">\n  <span data-test-status>\n    <strong>\n      {{t \"backofficePremiumStatus.text.status\"}}\n    </strong>\n    {{@model.translatedPremiumStatus}}\n  </span>\n\n  <Ui::Button @onClick={{fn (mut @showPremiumStatusModal) true}} @style=\"primary\" data-test-status-button>\n    {{t \"backofficePremiumStatus.action.changeStatus\"}}\n  </Ui::Button>\n\n  {{#if @showPremiumStatusModal}}\n    {{open-modal this.PartyStatusModal this.modalData close=this.onPremiumStatusEdit}}\n  {{/if}}\n</div>", {"contents":"<div local-class=\"status\">\n  <span data-test-status>\n    <strong>\n      {{t \"backofficePremiumStatus.text.status\"}}\n    </strong>\n    {{@model.translatedPremiumStatus}}\n  </span>\n\n  <Ui::Button @onClick={{fn (mut @showPremiumStatusModal) true}} @style=\"primary\" data-test-status-button>\n    {{t \"backofficePremiumStatus.action.changeStatus\"}}\n  </Ui::Button>\n\n  {{#if @showPremiumStatusModal}}\n    {{open-modal this.PartyStatusModal this.modalData close=this.onPremiumStatusEdit}}\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/backoffice/premium-status/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/backoffice/premium-status/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import PartyStatusModal from '@mvb/tix-ui/components/backoffice/party-status-modal';

export default class BackofficePremiumStatusComponent extends Component {
  @service modals;
  @service router;

  PartyStatusModal = PartyStatusModal;

  get modalData() {
    return {
      id: this.args.model.id,
      status: this.args.model.premiumStatus,
      expiration: this.args.model.premiumExpiration,
    };
  }

  @action
  async onPremiumStatusEdit(success) {
    if (success) {
      let transition = this.router.refresh();
      await transition.promise;
    }
    this.args.onSetPremiumStatus?.(false);
  }
}
