import { isEmpty } from '@ember/utils';
/**
 * takes the object from product.audiences and returns an array filtered with the given rangeCode
 * @param {*} audiences from product.audiences
 * @param {*} rangeCode something like `18`, `17` or `16`
 */
export default function audienceAge(audiences, rangeCode) {
  return (
    audiences?.filter(
      (audience) => audience.rangeCode === rangeCode && (!isEmpty(audience.ageFrom) || !isEmpty(audience.ageTo))
    ) ?? []
  );
}
