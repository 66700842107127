export default {
  "order-history-page": "_order-history-page_glo5i8",
  "details-table": "_details-table_glo5i8",
  "detail-row": "_detail-row_glo5i8",
  "label": "_label_glo5i8",
  "value": "_value_glo5i8",
  "divider": "_divider_glo5i8",
  "order-detail": "_order-detail_glo5i8",
  "recipient": "_recipient_glo5i8"
};
