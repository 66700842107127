import { getOwner } from '@ember/application';
import { ROUTES_WITH_PACKED_QUERY_PARAMS } from '@mvb/tix-ui/constants';
import { typeOf } from '@ember/utils';
import EmberRouterService from '@ember/routing/router-service';
import queryParamsPack from '@mvb/tix-ui/utils/query-params-pack';

export default class RouterService extends EmberRouterService {
  // Refreshes only the current route model hooks.
  refreshCurrentRoute({ withoutIndex = false } = {}) {
    let routeName = withoutIndex ? this.currentRouteName.replace(/\.index$/, '') : this.currentRouteName;

    return getOwner(this).lookup(`route:${routeName}`).refresh();
  }

  // This extends the `transitionTo` and `replaceWith` methods of the ember router service.
  // Query parameters of routes - with usage of packed query parameters - will automatically be packed,
  // otherwise the given query parameters will just be passed through.
  //
  // There is a new optional argument `useDefaults`: If set to true, the given query parameters will be
  // added to the default and user specific query param values of the routes search service,
  // otherwise they will be added to the current - last used - query parameters of that route.
  //
  // Default query parameters will always be removed from the resulting packed query parameters.
  transitionTo(...args) {
    if (
      ROUTES_WITH_PACKED_QUERY_PARAMS.includes(args[0]) ||
      (typeOf(args[0]) !== 'string' && ROUTES_WITH_PACKED_QUERY_PARAMS.includes(this.currentRouteName))
    ) {
      let qp = {};
      let qpUnchanged = {};
      let queryParams;
      let queryParamsIndex;
      let routeName = typeOf(args[0]) === 'string' ? args[0] : this.currentRouteName;
      let route = getOwner(this).lookup(`route:${routeName}`);
      let useDefaults = false;

      // get query params: depending on how transitionTo is called,
      // the query params can be inside first, second or third argument
      for (let i = 0; i < 3; i++) {
        if (args?.[i]?.queryParams) {
          queryParams = args[i].queryParams;
          queryParamsIndex = i;
          useDefaults = args[i].useDefaults;
        }
      }

      if (queryParams && Object.entries(queryParams).length > 0) {
        qp = useDefaults
          ? {
              ...route.searchService?.queryParamsDefaults,
              ...route.searchService?.queryParamsUserSpecific,
              ...queryParams,
            }
          : { ...route.searchService?.queryParams, ...queryParams };

        for (let queryParam in qp) {
          // remove query params which should not be changed
          if (route[queryParam]) {
            qpUnchanged[queryParam] = qp[queryParam];
            delete qp[queryParam];
          }

          // substract defaults from query params
          if (qp[queryParam] === route.searchService?.queryParamsDefaults?.[queryParam]) {
            delete qp[queryParam];
          }
        }

        // replace query params in transition with packed ones
        args[queryParamsIndex].queryParams = { ...qpUnchanged, ...queryParamsPack(qp) };
      }

      // remove empty qp param from url and save useDefaults options
      let transition = super.transitionTo(...args);
      transition['_keepDefaultQueryParamValues'] = false;
      transition.data.useQueryParamDefaults = useDefaults;

      return transition;
    }

    return super.transitionTo(...args);
  }
}
