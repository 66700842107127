import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FaIcon\n  @icon={{this.productIcon}}\n  @prefix={{this.prefix}}\n  @size={{this.size}}\n  data-test-product-icon={{this.productIcon}}\n  ...attributes\n/>\n\n{{yield}}", {"contents":"<FaIcon\n  @icon={{this.productIcon}}\n  @prefix={{this.prefix}}\n  @size={{this.size}}\n  data-test-product-icon={{this.productIcon}}\n  ...attributes\n/>\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/icon/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/icon/index.hbs"}});
import { ICONS } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class ProductIconComponent extends Component {
  get productIcon() {
    return this.args.code ? ICONS[this.args.code.toLowerCase()] : 'square-question';
  }

  get prefix() {
    //The playstation icon comes from a different icon package than the rest and therefore needs its own prefix
    if (this.productIcon === 'playstation') {
      return 'fab';
    }

    return this.args.prefix ?? 'far';
  }

  get size() {
    return this.args.size ?? 'sm';
  }
}
