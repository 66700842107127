import { isPresent } from '@ember/utils';
import { RETAIL_SALE_TYPES } from '../constants';
import { SEARCH_MODE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchProductsService extends SearchBaseService {
  @service abilities;
  @service dispoListProductEntries;
  @service features;
  @service intl;
  @service store;
  @service user;

  get queryParamsDefaults() {
    return this.queryParamsDefaultsForProducts;
  }

  get queryParamsFilters() {
    return this.queryParamsFiltersForProducts;
  }

  get queryParamsUserSpecific() {
    return {
      backlist: this.user.canAccessBacklist && this.user.useBacklist,
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortOrders = [
      {
        text: this.intl.t('productsIndex.text.sorting.relevance'),
        value: '',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateUp'),
        value: 'explicitPublicationDate',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateDown'),
        value: '-explicitPublicationDate',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.publisherSortUp'),
        value: 'publisherSort',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.publisherSortDown'),
        value: '-publisherSort',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.currentPriceUp'),
        value: 'currentPrice',
      },
      {
        text: this.intl.t('productsIndex.text.sorting.currentPriceDown'),
        value: '-currentPrice',
      },
    ];

    return sortOrders;
  }

  createSearchQuery({ queryParams }) {
    let { backlist, contributorIdentifierName, page, q, size, sort } = queryParams;
    let query = {
      filter: {
        backlist,
        onList: true,
        searchMode: SEARCH_MODE.ADVANCED,
        retailSale: `-${RETAIL_SALE_TYPES.SPECIAL}`,
      },
      include: 'notes',
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersToSearchQuery({ query, queryParams });

    // remove not supported filters for contributor identifiers
    if (isPresent(contributorIdentifierName)) {
      delete query.filter.contributorIdentifierName;
    }

    if (this.features.isEnabled('changesInPromotionalPackage')) {
      query.filter.changes = true;
    }

    if (isPresent(q)) {
      query.filter.searchTerm = q;
    }

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    if (this.isSearchFor12DigitGtin(query?.filter?.searchTerm)) {
      query.filter.searchTerm = `${query.filter.searchTerm}*`;
    }
    return await this.store.query('search-product', query);
  }

  async mapResults({ results: searchProducts }) {
    let dlpes = [];
    searchProducts = searchProducts ?? [];

    let isbns = searchProducts.map((searchProduct) => searchProduct.gtin);
    isbns = [...new Set(isbns.filter(Boolean))];

    if (this.abilities.can('dispoList.viewDispoListsInProductPages')) {
      dlpes = await this.dispoListProductEntries.fetchByIsbns(isbns);
      this.dispoListProductEntries.cleanup();
    }

    return this.dispoListProductEntries.mapSearchProducts({ dlpes, searchProducts });
  }

  isSearchFor12DigitGtin(searchTerm) {
    return /^\d{12}$/.test(searchTerm?.replaceAll('-', ''));
  }

  overrideQueryParameters(queryParamsOverride) {
    queryParamsOverride = this.overrideQueryParametersForProducts(queryParamsOverride);

    if (!this.user.canAccessBacklist) {
      queryParamsOverride.backlist = false;
    }

    return queryParamsOverride;
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFilters(queryParamsOverride) {
    return this.overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForProducts(queryParamsOverride);
  }
}
