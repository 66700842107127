import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export const ALLOWED_EMAIL_TEMPLATES = ['reset-password', 'premium'];

export const safeEmailTemplate = (template) => {
  if (!ALLOWED_EMAIL_TEMPLATES.includes(template)) {
    throw new Error(`Unknown email template identifier: ${template}`);
  }

  return template;
};

export default class BackofficeSendMailRoute extends Route {
  @service api;
  @service user;

  queryParams = {
    template: {
      refreshModel: true,
    },
    uid: {
      refreshModel: true,
    },
  };

  beforeModel(transition) {
    let controller = this.controllerFor('backoffice.send-mail');
    controller.previousPageTransition = transition.from;

    return super.beforeModel(transition);
  }

  async model(params) {
    try {
      let { uid, template } = params;
      let message = await this.api.get(`/user/${uid}/templates/${safeEmailTemplate(template)}`);

      return { message };
    } catch (error) {
      throw isEmpty(params.uid) ? new Error('No user provided') : new Error(error);
    }
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
