import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import Model from '@ember-data/model';

export default class BaseModel extends Model {
  @service store;

  ignoredFields = new Set();

  /**
   * @typedef RelationshipOptions
   * @property {relationshipName} name of the relationship if it differs from the camelized and pluralized version of the modelName
   * @property {relationshipRecord} record that should be added to or removed from the relationship
   */

  /**
   * Adds a record to a hasMany relationship
   * @param {RelationshipOptions} options
   */
  addHasMany({ relationshipName, relationshipRecord }) {
    this.store.addHasMany({ recordToUpdate: this, relationshipName, relationshipRecord });
  }

  /**
   * Removes a record from a hasMany relationship
   * @param {RelationshipOptions} options
   */
  deleteHasMany({ relationshipName, relationshipRecord }) {
    this.store.deleteHasMany({ recordToUpdate: this, relationshipName, relationshipRecord });
  }

  /**
   * Function that checks all defined attributes and relationships in models that extend from this class if they are filled with actual values.
   * Uses the property ignoredFields to check which fields should be ignored, e.g. metadata that is always filled
   * @returns {*[]} an array of the names of properties that are filled with actual values
   */
  filledDataFieldNames() {
    let existingFieldNames = [];

    try {
      this.eachAttribute((name) => {
        if (this.ignoredFields.has(name)) {
          return;
        }

        let value = this[name];

        if (isPresent(value)) {
          existingFieldNames.push(name);
        }
      });

      this.eachRelationship((name, meta) => {
        if (this.ignoredFields.has(name)) {
          return;
        }

        if (meta.kind === 'belongsTo') {
          let reference = this.belongsTo(name);

          if (!reference?.value()) {
            return;
          }

          existingFieldNames.push(name);
          return;
        }

        if (meta.kind === 'hasMany') {
          let reference = this.hasMany(name);

          if (!reference?.value()?.length) {
            return;
          }

          existingFieldNames.push(name);
          return;
        }
      });
    } catch {
      // do nothing
    }

    return existingFieldNames;
  }
}
