import { validatePresence } from 'ember-changeset-validations/validators/index';
import validateErpSetupBranchIdentifierLength from '@mvb/tix-ui/validators/erp-setup-branch-identifier-length';
import validateErpSetupBranchIdUniqueness from '@mvb/tix-ui/validators/erp-setup-branch-id-uniqueness';

export default function validateErpBranchSetup(erpService) {
  return {
    identifier: [
      validateErpSetupBranchIdentifierLength({
        erpService,
        description: 'intl:validation.party.erp.identifier.description',
      }),
      validatePresence({
        presence: true,
        description: 'intl:validation.party.erp.branch.identifier.description',
        ignoreBlank: true,
      }),
      validateErpSetupBranchIdUniqueness(erpService),
    ],
  };
}
