// cached not exported in @glimmer/tracking
// https://github.com/ember-polyfills/ember-cached-decorator-polyfill#typescript-usage
// eslint-disable-next-line import/named
import { cached, tracked } from '@glimmer/tracking';
import { next, schedule } from '@ember/runloop';
import ENV from '@mvb/tix-ui/config/environment';
import Service, { service } from '@ember/service';
import smoothscroll from 'smoothscroll-polyfill';
import sortBy from 'lodash-es/sortBy';
import window from 'ember-window-mock';

// Using 96px/6rem is enough to skip the jump navigation and a little extra, which is a purely a aesthetic decision.
const SCROLL_OFFSET_Y = 96;

export default class JumpService extends Service {
  @service features;
  @service router;

  @tracked _list = new Map();
  @tracked firstScreenVisible = true;

  hash = window.location.hash.slice(1);
  needsScrollToHash = this.hash !== '';
  preferReducedMotionMediaQuery = false;
  setupIsDone = false;

  willDestroy() {
    this.reset();
    super.willDestroy(...arguments);
  }

  @cached
  get list() {
    let jumpAnchors = document.querySelectorAll('a[data-jump-anchor]');
    let anchorPositions = new Object(null);

    for (let [i, jumpAnchor] of jumpAnchors.entries()) {
      anchorPositions[jumpAnchor.id] = i;
    }

    let list = [...this._list.entries()].map(([id, anchor]) => ({
      ...anchor,
      id,
      position: anchorPositions[anchor.href],
    }));
    return sortBy(list, 'position');
  }

  get activeId() {
    return this.list.find((anchor) => anchor.onScreen)?.id;
  }

  get ids() {
    return this.list;
  }

  get prefersReducedMotion() {
    return (
      ENV.environment === 'test' || !this.preferReducedMotionMediaQuery || this.preferReducedMotionMediaQuery.matches
    );
  }

  add(id, values /* { title, position, href } */) {
    let anchor = this._list.get(id);
    let list = new Map(this._list);

    list.set(id, {
      id,
      ...anchor,
      onScreen: false,
      ...values,
    });

    this._list = list;
  }

  remove(id) {
    let list = new Map(this._list);

    list.delete(id);

    this._list = list;
  }

  setActive(id, onScreen) {
    let list = new Map(this._list);

    list.set(id, {
      ...this._list.get(id),
      onScreen,
    });

    this._list = list;
  }

  reset() {
    if (this.setupIsDone) {
      this.router.off('routeDidChange', this, this._routeDidChange);
      this.router.off('routeWillChange', this, this._routeWillChange);
    }
  }

  // setup method for ensuring router events are always instantiated
  setup() {
    if (!this.setupIsDone) {
      smoothscroll.polyfill();

      // matchMedia is being overwritten in tests
      if (ENV.environment !== 'test') {
        this.preferReducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
      }

      this.router.on('routeDidChange', this, this._routeDidChange);
      this.router.on('routeWillChange', this, this._routeWillChange);

      this.setupIsDone = true;
    }
  }

  _scrollTo(top, left) {
    window.scroll({
      left,
      top,
      behavior: this.prefersReducedMotion ? 'auto' : 'smooth',
    });
  }

  /**
   * window.scroll() with smooth scrolling
   *
   * @param {Number} left end scroll position
   * @param {Number} top end scroll position
   */
  scrollTo(left, top) {
    schedule('afterRender', this, '_scrollTo', left, top);
  }

  _scrollIntoView({ behavior, element, scrollOffsetX, scrollOffsetY, useHeaderOffset }) {
    if (!element) {
      return;
    }

    behavior = behavior ?? this.prefersReducedMotion ? 'auto' : 'smooth';
    scrollOffsetX = scrollOffsetX ?? 0;
    scrollOffsetY = element.getBoundingClientRect().top + window.pageYOffset - (scrollOffsetY ?? SCROLL_OFFSET_Y);

    if (useHeaderOffset) {
      scrollOffsetY -= document.getElementById('header-sticky')?.offsetHeight ?? 0;
    }

    window.scroll({ behavior, left: scrollOffsetX, top: scrollOffsetY });
  }

  /**
   * element.scrollIntroView() with smooth scrolling and a Y offset to overwrite the default
   *
   * @param {string} behavior (auto or smooth)
   * @param {HTMLElement} element to scroll to
   * @param {number} scrollOffsetX
   * @param {number} scrollOffsetY
   * @param {boolean} useHeaderOffset
   */
  scrollIntoView() {
    schedule('afterRender', this, '_scrollIntoView', ...arguments);
  }

  scrollElementIntoView(element) {
    this.scrollIntoView({
      behavior: 'auto',
      element,
      scrollOffsetY: 0,
      useHeaderOffset: true,
    });
  }

  /**
   * Scroll to an element by id and set the location hash
   * @param {String | Object} identifier if an object is passed, it should have a fuzzyId property
   */
  scrollToAnchor(identifier) {
    let element = identifier?.fuzzyId
      ? document.querySelector(`[id*="${identifier.fuzzyId}"]`)
      : document.getElementById(identifier);

    if (!element) {
      return;
    }

    schedule('afterRender', () => {
      // having a timeout of 1 tick makes the scrollIntoView much more accurate
      this.overrideLocationHash(element.id);
      setTimeout(() => {
        this.scrollIntoView({ element });
      }, 1);
    });
  }

  overrideLocationHash(value) {
    schedule('afterRender', () => {
      window.history.replaceState(null, null, `#${value}`);
      this.hash = value;
    });
  }

  scrollToPosition(position) {
    schedule('afterRender', () => {
      let anchor = this.list[position];
      this.scrollToAnchor(anchor.href);
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  _routeDidChange() {
    next(() => {
      if (!this.needsScrollToHash || !document.getElementById(this.hash)) {
        return;
      }

      this.scrollToAnchor(this.hash);
      this.needsScrollToHash = false;
    });
  }

  _routeWillChange(transition) {
    // instantly scroll to top on route name changes
    if (transition.to?.name !== this.router.currentRouteName) {
      this.scrollToTop();
    }
  }
}
