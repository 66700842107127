import { isNone, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class OrderOrderHistoryOrderRoute extends Route {
  @service abilities;
  @service api;
  @service router;
  @service session;
  @service store;
  @service user;

  async model({ order_id: orderId }) {
    if (!this.abilities.can('order.order')) {
      return;
    }

    let order = this.store.peekRecord('order', orderId);

    if (isNone(order)) {
      return this.router.transitionTo('order.order-history');
    }

    let orderDetails = await this.api.fetch(`/order/${order.id}/details`);

    let shouldShowOrderHead =
      isPresent(orderDetails?.comment) ||
      orderDetails?.copyOrderToErp ||
      isPresent(orderDetails?.customerNumber) ||
      isPresent(orderDetails?.deliveryDate) ||
      isPresent(orderDetails?.orderCode) ||
      isPresent(orderDetails?.recipients);

    return {
      currency: this.user.selectedCurrencyCountry,
      shouldShowOrderHead,
      order,
      orderDetails,
    };
  }
}
