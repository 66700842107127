import { service } from '@ember/service';
import Route from '@ember/routing/route';

/**
 * When changing the url of this route, remember to adapt the link that is generated for preview-emails in the BE!
 */
export default class PresentationsIndexRoute extends Route {
  @service router;

  redirect() {
    this.router.replaceWith('previews');
  }
}
