import { isPresent } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import ObjectProxy from '@ember/object/proxy';

export default function validateOrderRowHugListingRecommendationCodes(options) {
  return (key, newValue, _oldValue, changes, content) => {
    let collectionOfGoods = changes?.collectionOfGoods ?? content.collectionOfGoods;
    let note = changes?.note ?? content.note;
    let hugListingRecommendationCodes = newValue ?? [];

    if (collectionOfGoods instanceof ObjectProxy) {
      collectionOfGoods = collectionOfGoods?.content;
    }

    if (
      // already saved order row and hugListingRecommendationCodes is not valid
      (!content.isNew && hugListingRecommendationCodes.length === 0) ||
      // new order row and any of the optional fields is set
      (content.isNew && hugListingRecommendationCodes.length === 0 && (isPresent(collectionOfGoods) || isPresent(note)))
    ) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
