import { all, task } from 'ember-concurrency';
import { CUSTOM_MARKETING_CONTENT_KEY } from '@mvb/tix-ui/constants';
import { isEmpty } from '@ember/utils';
import { validateLength } from 'ember-changeset-validations/validators/index';
import { waitFor } from '@ember/test-waiters';
import CustomMarketingContentBaseService from '@mvb/tix-ui/services/custom-marketing-content-base';
import positiveIntegerWithDecimal from '@mvb/tix-ui/validators/positive-integer-with-decimal';

export default class CustomMarketingContentIndividualService extends CustomMarketingContentBaseService {
  // overwrite abstract method from base service
  getDataForChangeset(model, isNew = false) {
    let {
      id,
      note,
      recommendedOrderAmount,
      sortedListingRecommendations: listing,
      sortedMarketingRecommendations: marketing,
      tags,
      files,
      type,
    } = model;

    return {
      // TODO: refactor with #30180
      hasComparativeProducts: false, // component for comparative products handles the state of this, therefore defaults to false
      id: isNew ? null : id,
      isNew,
      listingRecommendations: isEmpty(listing) ? '[]' : this.getStringyfiedRecommendations(listing, isNew),
      marketingRecommendations: isEmpty(marketing) ? '[]' : this.getStringyfiedRecommendations(marketing, isNew),
      note,
      recommendedOrderAmount,
      tags: tags ?? [],
      files: files ?? [],
      type,
    };
  }

  getStringyfiedRecommendations(recommendations, isNew) {
    let mappedRecommendations = recommendations?.filter(Boolean).map((recommendation) => {
      return {
        id: isNew ? null : recommendation.id,
        value: recommendation.text,
      };
    });

    return JSON.stringify(mappedRecommendations);
  }

  // overwrite abstract method from base service
  getValidationsForChangeset() {
    return {
      recommendedOrderAmount: [positiveIntegerWithDecimal(), validateLength(this.maxLengthValidation)],
    };
  }

  // overwrite abstract task from base service
  @task
  @waitFor
  *saveRelatedEntitiesTask(model, recordsToSave) {
    //TODO should be done in chunks --> see ticket #30151
    let cleanedUpRecordsToSave = this.cleanupRecordsToSave(recordsToSave);
    yield all(
      cleanedUpRecordsToSave.map((record) => (record.get('markedForDeletion') ? record.destroyRecord() : record.save()))
    );
  }

  // overwrite abstract task from base service
  @task
  @waitFor
  *updateRecordsAndModelForSavingTask(changeset, model, recordsToSave) {
    try {
      for (let change of changeset.changes) {
        switch (change.key) {
          case CUSTOM_MARKETING_CONTENT_KEY.LISTING_RECOMMENDATIONS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'listing-recommendation'
            );
            if (Array.isArray(recommendations)) {
              recordsToSave.push(...recommendations);
            }
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.MARKETING_RECOMMENDATIONS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'marketing-recommendation'
            );
            if (Array.isArray(recommendations)) {
              recordsToSave.push(...recommendations);
            }
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.BRANCH_AMOUNTS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'branch-recommended-order-amount'
            );
            if (Array.isArray(recommendations)) {
              recordsToSave.push(...recommendations);
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
