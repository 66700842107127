import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::File\n  @accept={{this.accepted}}\n  @disabled={{@disabled}}\n  @fileName={{@fileName}}\n  @maxSize={{@maxSize}}\n  @multiple={{@multiple}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @onError={{@onError}}\n  @onFileRemove={{@onFileRemove}}\n  @onUpload={{@onUpload}}\n  @overlayProgressEnabled={{@overlayProgressEnabled}}\n  @overlayProgressMessage={{@overlayProgressMessage}}\n  @payload={{@payload}}\n  @url={{@url}}\n  @value={{@value}}\n  ...attributes\n/>", {"contents":"<Ui::Input::File\n  @accept={{this.accepted}}\n  @disabled={{@disabled}}\n  @fileName={{@fileName}}\n  @maxSize={{@maxSize}}\n  @multiple={{@multiple}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @onError={{@onError}}\n  @onFileRemove={{@onFileRemove}}\n  @onUpload={{@onUpload}}\n  @overlayProgressEnabled={{@overlayProgressEnabled}}\n  @overlayProgressMessage={{@overlayProgressMessage}}\n  @payload={{@payload}}\n  @url={{@url}}\n  @value={{@value}}\n  ...attributes\n/>","moduleName":"@mvb/tix-ui/components/ui/input/file/excel.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/file/excel.hbs"}});
import { ASSET_XLSX } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class UiInputExcelComponent extends Component {
  accepted = {
    types: ASSET_XLSX,
    description: 'xlsx',
  };
}
