import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class Notification extends Helper {
  @service notifications;

  compute([message], { autoClear, onClick, style = 'info' }) {
    let options = {};

    if (autoClear !== undefined) {
      options.autoClear = autoClear;
    }

    if (onClick !== undefined) {
      options.onClick = onClick;
    }

    this.notifications[style]?.(message, options);
  }
}
