import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! This file is ignored from prettier, see .prettierignore }}\n{{#if this.contributorsLists}}\n  <p data-test-bibliography-contributors>\n    {{#each this.contributorsLists as |list|}}\n      {{list.label}}\n      <Layout::AsList local-class=\"contributors\" @list={{list.contributors}} as |contributor|>\n        <a href=\"#contributor-{{contributor.id}}\" {{on \"click\" (fn this.jumpTo contributor.id)}}>\n          {{~contributor.name~}}\n        </a>{{! Do not remove me! I'm necessary to kill whitespace! ~FP 01/2021\n      }}</Layout::AsList>\n      {{#if list.hasMore}}\n        {{t \"productBibliography.text.listingFlag\"}}\n      {{/if}}\n    {{~/each~}}\n  </p>\n{{/if}}\n\n{{yield}}", {"contents":"{{! This file is ignored from prettier, see .prettierignore }}\n{{#if this.contributorsLists}}\n  <p data-test-bibliography-contributors>\n    {{#each this.contributorsLists as |list|}}\n      {{list.label}}\n      <Layout::AsList local-class=\"contributors\" @list={{list.contributors}} as |contributor|>\n        <a href=\"#contributor-{{contributor.id}}\" {{on \"click\" (fn this.jumpTo contributor.id)}}>\n          {{~contributor.name~}}\n        </a>{{! Do not remove me! I'm necessary to kill whitespace! ~FP 01/2021\n      }}</Layout::AsList>\n      {{#if list.hasMore}}\n        {{t \"productBibliography.text.listingFlag\"}}\n      {{/if}}\n    {{~/each~}}\n  </p>\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/bibliography/contributors.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/bibliography/contributors.hbs"}});
import { action } from '@ember/object';
import { generateLabelForContributor } from '@mvb/tix-ui/utils/internal-codelist-services';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';

const MAX_CONTRIBUTORS = 4;

export default class ProductBibliographyContributorsComponent extends Component {
  @service intl;
  @service jump;
  @service codelists;

  get contributorsLists() {
    let codelist = this.codelists.getCodeListWithDeprecated('contributorRoleCodes');
    let contributors = this.args.product?.contributors ?? [];
    let sortedContribs = sortBy(contributors, 'sequenceNumber');
    let relevantContribs = sortedContribs;
    let cuttedContribs = [];

    if (contributors.length > MAX_CONTRIBUTORS) {
      relevantContribs = sortedContribs.slice(0, MAX_CONTRIBUTORS);
      cuttedContribs = sortedContribs.slice(MAX_CONTRIBUTORS);
    }

    relevantContribs = groupBy(relevantContribs, 'type');

    return Object.entries(relevantContribs).map(([type, contribs]) => ({
      type,
      contributors: contribs,
      label: generateLabelForContributor(codelist.find((element) => element.code === type)),
      // informs if contributors have been cut for each type
      hasMore: cuttedContribs.some((cont) => cont.type === type),
    }));
  }

  @action
  jumpTo(id, event) {
    event.preventDefault();
    this.jump.scrollToAnchor(`contributor-${id}`);
  }
}
