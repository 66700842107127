import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import userValidations from '../validations/user';

export default function TixUserDoesNotExistValidator(options) {
  return async (key, newValue /*, oldValue, changes, content*/) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let apiService = applicationUtil.lookup('service:api');
    let intlService = applicationUtil.lookup('service:intl');
    let errorsService = applicationUtil.lookup('service:errors');

    let username = newValue?.trim();

    // return quickly if the username is invalid, saves on API calls
    let isInvalidUsername = userValidations.username.map((v) => v('username', username)).find((b) => b !== true);
    if (isInvalidUsername) {
      return true;
    }

    try {
      let response = await apiService.postJSON(`/user/exists`, {
        username,
        role: options.role,
      });
      if (response?.userExists) {
        return buildMessage(key, { message: intlService.t('error.user.exists', { email: username }) });
      }
    } catch (error) {
      errorsService.handle(error);
      return intlService.t('error.generic');
    }
  };
}
