import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"fixed\" {{did-insert this.disableBodyScroll}} {{will-destroy this.enableBodyScroll}}>\n  <header local-class=\"header\">\n    <div local-class=\"title\">\n      {{@title}}\n    </div>\n    <Ui::Button\n      local-class=\"close\"\n      @icon=\"xmark\"\n      @iconPrefix=\"fal\"\n      @label={{t \"layoutDraggable.action.close\"}}\n      @onClick={{this.close}}\n      @style=\"link\"\n    />\n  </header>\n\n  <div local-class=\"content\">\n    {{yield}}\n  </div>\n</div>", {"contents":"<div local-class=\"fixed\" {{did-insert this.disableBodyScroll}} {{will-destroy this.enableBodyScroll}}>\n  <header local-class=\"header\">\n    <div local-class=\"title\">\n      {{@title}}\n    </div>\n    <Ui::Button\n      local-class=\"close\"\n      @icon=\"xmark\"\n      @iconPrefix=\"fal\"\n      @label={{t \"layoutDraggable.action.close\"}}\n      @onClick={{this.close}}\n      @style=\"link\"\n    />\n  </header>\n\n  <div local-class=\"content\">\n    {{yield}}\n  </div>\n</div>","moduleName":"@mvb/tix-ui/components/layout/fixed/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/fixed/index.hbs"}});
import { action } from '@ember/object';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Component from '@glimmer/component';

export default class LayoutFixedComponent extends Component {
  @action
  close() {
    this.args.onClose?.();
  }

  @action
  disableBodyScroll(element) {
    disableBodyScroll(element.querySelector('[data-scrollable]'));
  }

  @action
  enableBodyScroll(element) {
    enableBodyScroll(element.querySelector('[data-scrollable]'));
  }
}
