import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  local-class=\"collapsible\"\n  data-test-collapsible={{if this.isOpen \"true\" \"false\"}}\n  {{did-update this.onUpdate @isOpen}}\n>\n  <button\n    type=\"button\"\n    local-class=\"summary\"\n    aria-expanded={{this.isOpen \"true\" \"false\"}}\n    aria-controls={{this.uniqueId}}\n    data-summary\n    data-test-collapsible-summary\n    {{on \"click\" this.onToggle}}\n  >\n    {{yield this.isOpen to=\"heading\"}}\n  </button>\n  {{yield this.isOpen (hash isOpen=this.isOpen id=this.uniqueId) to=\"content\"}}\n</div>", {"contents":"<div\n  ...attributes\n  local-class=\"collapsible\"\n  data-test-collapsible={{if this.isOpen \"true\" \"false\"}}\n  {{did-update this.onUpdate @isOpen}}\n>\n  <button\n    type=\"button\"\n    local-class=\"summary\"\n    aria-expanded={{this.isOpen \"true\" \"false\"}}\n    aria-controls={{this.uniqueId}}\n    data-summary\n    data-test-collapsible-summary\n    {{on \"click\" this.onToggle}}\n  >\n    {{yield this.isOpen to=\"heading\"}}\n  </button>\n  {{yield this.isOpen (hash isOpen=this.isOpen id=this.uniqueId) to=\"content\"}}\n</div>","moduleName":"@mvb/tix-ui/components/layout/collapsible/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/collapsible/index.hbs"}});
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class LayoutCollapsibleIndexComponent extends Component {
  @tracked isOpen = this.args.isOpen ?? false;

  uniqueId = guidFor(this);

  @action
  onUpdate() {
    this.isOpen = this.args.isOpen;
  }

  @action
  onToggle() {
    this.isOpen = !this.isOpen;
    this.args.onToggle?.(this.isOpen);
  }
}
