import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';

export default class ProductListsProductListIndexController extends Controller {
  @service('products') productsService;
  @service productList;
  @service('search-products-in-list') searchService;
  @service user;

  queryParams = ['qp'];
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  get addToDispoListLabel() {
    return this.productsService.getAddToDispoListLabel(this.maxProductCountExceeded);
  }

  get addToProductListLabel() {
    return this.productsService.getAddToProductListLabel(this.maxProductCountExceeded);
  }

  get isItemsSelectionEmpty() {
    return this.productsService.getIsItemsSelectionEmpty(this.searchService.meta.total);
  }

  get maxProductCountExceeded() {
    return this.productsService.getMaxProductCountExceeded(this.searchService.meta.total);
  }

  get updatedFilter() {
    let filter = this.searchService.searchQuery.filter;
    filter['productList.id'] = this.model.list.id;
    return filter;
  }

  @action
  async onAddToDispoList() {
    let affectsResults = await this.productsService.onAddToDispoList(this.searchService.meta.total, this.updatedFilter);

    if (affectsResults) {
      this.reloadResults();
    }
  }

  @action
  onAddToProductList() {
    this.productList.addProductsToList(this.searchService.meta.total, this.updatedFilter);
  }

  @action
  onDeleteFromList() {
    this.productList.deleteProductsFromList(this.searchService.meta.total, this.updatedFilter, this.model.list.id);
  }

  @action
  onDownload() {
    this.productsService.onDownload();
  }

  @action
  onStartPresentation() {
    this.productsService.onStartPresentation(this.model.list.id, true);
  }

  @action
  reloadResults() {
    this.searchService.search();
  }

  @action
  async onUpdateProductLists(product, preview) {
    let { itemOnList } = await this.productList.updateListsForProduct(product, preview);

    if (!itemOnList.get(this.model.list.id)) {
      this.reloadResults();
    }
  }
}
