import { attr } from '@ember-data/model';
import Model from './-base';

export default class PartyInviteModel extends Model {
  @attr('date') created;
  @attr('string') invitedFirstname;
  @attr('string') invitedLastname;
  @attr('string') invitingFirstname;
  @attr('string') invitingLastname;
  @attr('string') partyName;

  get fullNameInviting() {
    return `${this.invitingFirstname} ${this.invitingLastname}`;
  }
}
