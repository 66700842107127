import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.groupedAndSortedContributorContents}}\n  {{#each-in this.groupedAndSortedContributorContents as |group items|}}\n    <Layout::Subtitle @title={{cl \"17\" group}} local-class=\"headline\" data-test-contributors-headline={{group}} />\n    <div local-class=\"list\" data-test-contributors-list={{group}}>\n      {{#each items as |content index|}}\n        <Sections::View::Contributors::Contributor @content={{content}} data-test-contributor={{index}} />\n      {{/each}}\n    </div>\n  {{/each-in}}\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.groupedAndSortedContributorContents}}\n  {{#each-in this.groupedAndSortedContributorContents as |group items|}}\n    <Layout::Subtitle @title={{cl \"17\" group}} local-class=\"headline\" data-test-contributors-headline={{group}} />\n    <div local-class=\"list\" data-test-contributors-list={{group}}>\n      {{#each items as |content index|}}\n        <Sections::View::Contributors::Contributor @content={{content}} data-test-contributor={{index}} />\n      {{/each}}\n    </div>\n  {{/each-in}}\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/sections/view/contributors/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/view/contributors/index.hbs"}});
import { service } from '@ember/service';
import { sortByCodelist } from '@mvb/tix-ui/utils/internal-codelist-services';
import Component from '@glimmer/component';
import groupBy from 'lodash-es/groupBy';

export default class SectionsViewContributorsComponent extends Component {
  @service codelists;

  get groupedAndSortedContributorContents() {
    let sortedContributors = this.args.section.sortedContents;
    let groupedContributors = groupBy(sortedContributors, 'contributorType');
    let codelist = this.codelists.getCodeListWithDeprecated('contributorRoleCodes');

    return sortByCodelist(groupedContributors, codelist);
  }
}
