export default {
  "btn-reset-all": "_btn-reset-all_m2yby0",
  "btn-reset-all-disabled": "_btn-reset-all-disabled_m2yby0",
  "container": "_container_m2yby0",
  "filters": "_filters_m2yby0",
  "loading": "_loading_m2yby0",
  "tag": "_tag_m2yby0",
  "tag-icon": "_tag-icon_m2yby0 reset-button",
  "tag-icon-disabled": "_tag-icon-disabled_m2yby0",
  "tag-value": "_tag-value_m2yby0 reset-button",
  "tags": "_tags_m2yby0"
};
