import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class UserSettingModel extends Model {
  @attr('boolean') backlistChecked;
  @attr('boolean') editDispoListEntries;
  @attr() favoritePublishers; // readonly list of MVBIDs
  @attr('boolean') hideHighlightsBookGroups;
  @attr('number') pageSize;
  @attr('string') notesDefault; // can be any value of NOTES_TYPES (see constants.js)

  @belongsTo('assigned-party', { async: false, inverse: null }) selectedParty;
}
