import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import {
  URL_PROMOTIONAL_PACKAGE_DELETE,
  URL_PROMOTIONAL_PACKAGE_SAVE,
  URL_SPECIAL_DELETE,
  URL_SPECIAL_SAVE,
} from '@mvb/tix-ui/constants';
import ApplicationAdapter from './application';

export default class ProductAdapter extends ApplicationAdapter {
  @service api;

  // this runs when a product was saved for the first time
  createRecord(store, type, snapshot) {
    let body = this.serialize(snapshot);

    if (snapshot.record.isProductCollection) {
      let { options, queryParams } = this._getPublishActionQueryParams(snapshot);
      let url = snapshot.record.isPromotionalPackage ? URL_PROMOTIONAL_PACKAGE_SAVE : URL_SPECIAL_SAVE;

      // the serializer will return the correct data for promotional packages or specials
      return this.api.postJSON(url, body, options, queryParams);
    }

    return super.createRecord(...arguments);
  }

  deleteRecord(store, type, snapshot) {
    if (snapshot.record.isPromotionalPackage) {
      let deleteURL = URL_PROMOTIONAL_PACKAGE_DELETE.replace('{ID}', snapshot.id);
      return this.api.delete(deleteURL);
    }

    if (snapshot.record.isThemenspecial) {
      let deleteURL = URL_SPECIAL_DELETE.replace('{ID}', snapshot.id);
      return this.api.delete(deleteURL);
    }
  }

  // every time the product record gets saved this will run.
  // depending on the type of the product record we need to call different endpoints for this.
  updateRecord(store, type, snapshot) {
    let body = this.serialize(snapshot);

    if (snapshot.record.isProductCollection) {
      let { options, queryParams } = this._getPublishActionQueryParams(snapshot);
      let url = snapshot.record.isPromotionalPackage ? URL_PROMOTIONAL_PACKAGE_SAVE : URL_SPECIAL_SAVE;

      // the serializer will return the correct data for promotional packages or specials
      return this.api.putJSON(`${url}/${snapshot.id}`, body, options, queryParams);
    }

    // regular products should not be saved via TIX as of now
    return super.updateRecord(...arguments);
  }

  _getPublishActionQueryParams(snapshot) {
    let options = {};
    let queryParams = {};
    let publishAction = snapshot.adapterOptions?.publishAction;

    if (isPresent(publishAction)) {
      queryParams.publishAction = publishAction;
    }

    return {
      options,
      queryParams,
    };
  }
}
