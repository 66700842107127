import validateErpSetupIdentifier from '@mvb/tix-ui/validators/erp-setup-identifier';
import validateErpSetupIdentifierLength from '@mvb/tix-ui/validators/erp-setup-identifier-length';
import validateErpSetupOrderChannels from '@mvb/tix-ui/validators/erp-setup-order-channels';
import validateErpSetupUsername from '@mvb/tix-ui/validators/erp-setup-username';

export default function validateErpSetup(erpSetup, userOptions) {
  return {
    orderChannels: validateErpSetupOrderChannels({ erpSetup }),
    identifier: [
      validateErpSetupIdentifierLength({
        description: 'intl:validation.party.erp.identifier.description',
      }),
      validateErpSetupIdentifier({
        description: 'intl:validation.party.erp.identifier.description',
      }),
    ],
    username: validateErpSetupUsername(userOptions),
  };
}
