export default {
  "actions": "_actions_ber1i0",
  "checked": "_checked_ber1i0",
  "container": "_container_ber1i0 _container_1edbot",
  "icon": "_icon_ber1i0",
  "permission-set-name": "_permission-set-name_ber1i0",
  "permission-set-name-input": "_permission-set-name-input_ber1i0",
  "premium-status-tooltip": "_premium-status-tooltip_ber1i0",
  "table": "_table_ber1i0 _table_1edbot",
  "tbody": "_tbody_ber1i0 _tbody_1edbot",
  "header-row": "_header-row_ber1i0",
  "premium-status-row": "_premium-status-row_ber1i0",
  "tbody-category": "_tbody-category_ber1i0",
  "thead": "_thead_ber1i0 _thead_1edbot",
  "editing": "_editing_ber1i0",
  "symbol": "_symbol_ber1i0",
  "tr": "_tr_ber1i0 _tr_1xynbj",
  "unchecked": "_unchecked_ber1i0",
  "permission-set-header": "_permission-set-header_ber1i0",
  "permission-set-header-wrapper": "_permission-set-header-wrapper_ber1i0",
  "permission-set-icon-wrapper": "_permission-set-icon-wrapper_ber1i0"
};
