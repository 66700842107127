import { ERP_SETUP_TYPES } from '@mvb/tix-ui/constants';
import applicationUtil from '@mvb/tix-ui/utils/application';

export default function validateErpSetupUsername(userOptions) {
  return async (_key, _newValue, _oldValue, changes, content) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');

    let erp = Object.prototype.hasOwnProperty.call(changes, 'erp') ? changes.erp : await content?.get('erp');

    if (erp?.setupType === ERP_SETUP_TYPES.FTP) {
      let username = changes.username ?? content.username;
      return (
        userOptions.some((option) => option.value.toLowerCase() === username?.toLowerCase()) ||
        intlService.t('validation.party.erp.username.presentOnFtp')
      );
    }
    return true;
  };
}
