import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"color\">\n  <Ui::Input::Text\n    local-class=\"color-input\"\n    @label={{t \"sectionsEditColor.label.backgroundColor\"}}\n    @changeset={{@changeset}}\n    @onChange={{this.onColorChange}}\n    @name={{@name}}\n    @path={{@path}}\n    {{did-update this.initColorPicker this.value}}\n  />\n\n  <Ui::Input::Text\n    local-class=\"color-picker\"\n    @type=\"color\"\n    @hideLabel={{true}}\n    @onChange={{this.onColorChange}}\n    @value={{this.colorValue}}\n    @name=\"colorPicker\"\n  />\n</div>", {"contents":"<div local-class=\"color\">\n  <Ui::Input::Text\n    local-class=\"color-input\"\n    @label={{t \"sectionsEditColor.label.backgroundColor\"}}\n    @changeset={{@changeset}}\n    @onChange={{this.onColorChange}}\n    @name={{@name}}\n    @path={{@path}}\n    {{did-update this.initColorPicker this.value}}\n  />\n\n  <Ui::Input::Text\n    local-class=\"color-picker\"\n    @type=\"color\"\n    @hideLabel={{true}}\n    @onChange={{this.onColorChange}}\n    @value={{this.colorValue}}\n    @name=\"colorPicker\"\n  />\n</div>","moduleName":"@mvb/tix-ui/components/ui/input/color/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/color/index.hbs"}});
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import UiInputBaseComponent from '../base';

export default class UiInputColorComponent extends UiInputBaseComponent {
  @tracked colorValue;

  constructor() {
    super(...arguments);
  }

  @action
  initColorPicker() {
    this.colorValue = this.value;
  }

  @action
  onColorChange(value) {
    this.colorValue = value;
    super.onChange({ target: { value } });
    this.args.onChange?.(this.args.changeset);
  }
}
