export default {
  "item": "_item_1sw7j4",
  "form": "_form_1sw7j4",
  "with-tooltip": "_with-tooltip_1sw7j4",
  "caption-validations": "_caption-validations_1sw7j4",
  "tooltip": "_tooltip_1sw7j4",
  "muted": "_muted_1sw7j4",
  "resource-content-type-validations": "_resource-content-type-validations_1sw7j4",
  "copyright-validations": "_copyright-validations_1sw7j4"
};
