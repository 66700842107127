import { ASSET_TYPES, FALLBACK_COVER_URL } from '@mvb/tix-ui/constants';
import { attr, hasMany } from '@ember-data/model';
import Model from './-base';

export default class FileModel extends Model {
  @attr() assetId;
  @attr('date-time') createdAt;
  @attr() createdBy;
  @attr('string', FALLBACK_COVER_URL) downloadUrl;
  @attr() ean;
  @attr() filename;
  @attr() assetfileType;
  @attr() assetType;
  @attr() resourceContentType;
  @attr() resourceMode;
  @attr('number') imageHeight;
  @attr('number') imageWidth;
  @attr() originalLink1;

  @hasMany('custom-marketing-content', { async: false, inverse: 'files' }) customMarketingContents;

  get width() {
    return this.imageWidth === 0 ? undefined : this.imageWidth;
  }

  get height() {
    return this.imageHeight === 0 ? undefined : this.imageHeight;
  }

  get isProprietaryAsset() {
    return this.assetType === ASSET_TYPES.PROPRIETARY;
  }

  get isCommonAsset() {
    return this.assetType === ASSET_TYPES.COMMON;
  }
}
