import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateCollectionOfGoodsNameUniqueness() {
  return (key, newValue) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let collectionOfGoodsService = applicationUtil.lookup('service:collection-of-goods');
    let intlService = applicationUtil.lookup('service:intl');

    let value = newValue.toLowerCase().trim();

    if (
      collectionOfGoodsService.current.default
        .map((collectionOfGoods) => collectionOfGoods.name.toLowerCase().trim())
        .includes(value)
    ) {
      return buildMessage(key, {
        message: intlService.t('validation.uniqueness.notUnique', {
          gender: 'other',
          value: intlService.t('validation.party.collectionOfGoods.name.description'),
        }),
      });
    }

    return true;
  };
}
