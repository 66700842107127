import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

/**
 * @typedef Changeset
 * @property {Object} data
 */

export default function createChangeset(data, validations, options) {
  return new Changeset(data, lookupValidator(validations), validations, { ...options });
}
