export default {
  "base": "_base_977tgk",
  "with-caret": "_with-caret_977tgk",
  "caret": "_caret_977tgk",
  "empty": "_empty_977tgk _empty_1bf1x4",
  "hidden": "_hidden_977tgk hidden-aria",
  "icon": "_icon_977tgk _icon_1thlqb reset-button",
  "input": "_input_977tgk _input_1bf1x4",
  "disabled": "_disabled_977tgk",
  "options": "_options_977tgk _tags_1bf1x4 _input_fam08e",
  "option": "_option_977tgk _tag_1thlqb",
  "option-disabled": "_option-disabled_977tgk _disabled_1thlqb",
  "value": "_value_977tgk _value_1thlqb reset-button",
  "value-disabled": "_value-disabled_977tgk _value-disabled_1thlqb"
};
