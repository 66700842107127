import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.queryParams.hasActiveFilters}}\n  <Ui::Button data-test-filter-reset-all @icon=\"xmark\" @onClick={{this.onResetAll}} @style=\"link\">\n    {{t \"searchFilters.label.reset\"}}\n  </Ui::Button>\n{{/if}}\n\n{{yield this.filterComponents}}", {"contents":"{{#if this.queryParams.hasActiveFilters}}\n  <Ui::Button data-test-filter-reset-all @icon=\"xmark\" @onClick={{this.onResetAll}} @style=\"link\">\n    {{t \"searchFilters.label.reset\"}}\n  </Ui::Button>\n{{/if}}\n\n{{yield this.filterComponents}}","moduleName":"@mvb/tix-ui/components/search/filters/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filters/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SearchFiltersIndexComponent extends Component {
  @service manageList;
  @service queryParams;

  filterComponents = [];

  @action
  onResetAll() {
    this.manageList.reset();

    for (let filterComponent of this.filterComponents) {
      filterComponent?.onReset();
      filterComponent?.onToggleFilter(false);
    }

    this.args.searchService?.search({ page: 1 }, { useFilterDefaults: true });
  }
}
