import { action } from '@ember/object';
import { PUBLISH_ACTION } from '@mvb/tix-ui/constants';
import SectionContentsProductCollectionBaseService from '@mvb/tix-ui/services/section-contents-product-collection-base';

export default class SectionContentsPromotionalPackageService extends SectionContentsProductCollectionBaseService {
  intlNamespace = 'productsPromotionalPackagesEdit';

  setupController(model) {
    this.model = model;
    this.validationMessagesIdentifier = [];
  }

  async save() {
    await this.saveTask.perform();

    if (this.router.get('currentRouteName') === 'products.promotional-package.add') {
      this.router.transitionTo('products.product.edit', this.model.id);
    } else if (!this.model.active) {
      this.router.transitionTo('products.index');
    }

    // we always want to reload the route, even if we had a transition first
    this.reloadCurrentRoute();
  }

  async saveAndClose() {
    await this.saveTask.perform();

    if (this.model.active) {
      await this.reloadModel();
      this.router.transitionTo('products.product.index', this.model.id);
    } else {
      this.router.transitionTo('products.index');
    }
  }

  @action
  async publish() {
    await this.saveTask.perform({ adapterOptions: { publishAction: PUBLISH_ACTION.PUBLISH } });

    if (this.model.active) {
      await this.reloadModel();
      this.router.transitionTo('products.product.index', this.model.id);
    } else {
      this.router.transitionTo('products.index');
    }
  }

  @action
  async unpublish() {
    await this.saveTask.perform({ adapterOptions: { publishAction: PUBLISH_ACTION.DRAFT } });

    if (this.model.active) {
      this.reloadCurrentRoute();
    } else {
      this.router.transitionTo('products.index');
    }
  }

  @action
  cancel() {
    if (this.model?.id) {
      this.router.transitionTo('products.product.index', this.model.id);
    } else {
      this.router.transitionTo('products');
    }
  }
}
