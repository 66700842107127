import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';

export default class BackofficeAdminsAddController extends Controller {
  @service router;

  @action
  toAdminsOverview() {
    this.router.transitionTo('backoffice.admins');
  }
}
