import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import Model from './-base';

export function icon(predefined, name) {
  if (predefined) {
    if (name === 'admin') {
      return 'user-gear';
    } else if (name === 'member') {
      return 'user';
    }
  }

  return '';
}

export function iconPrefix(predefined) {
  return predefined ? 'far' : '';
}

export default class PermissionSetModel extends Model {
  @service intl;

  @attr() name;
  @attr() symbol;
  @attr() partyType;
  @attr({ defaultValue: () => [] }) permissions;
  @attr('boolean') predefined;

  @belongsTo('party', { async: false, inverse: 'permissionSets' }) party;

  get icon() {
    return icon(this.predefined, this.name);
  }

  get iconPrefix() {
    return iconPrefix(this.predefined);
  }

  get label() {
    if (this.predefined && this.intl.exists(`partyPermissions.label.${this.name}`)) {
      return this.intl.t(`partyPermissions.label.${this.name}`);
    }

    return this.name;
  }
}
