import { didCancel, task } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';

export default class BannerService extends Service {
  @service intl;
  @service modals;
  @service store;

  get current() {
    return this.loadTask.lastSuccessful?.value ?? [];
  }

  get active() {
    return this.current.find((banner) => banner.active);
  }

  @task({ drop: true })
  @waitFor
  *loadTask() {
    let banners = yield this.store.findAll('banner');
    return banners;
  }

  async load() {
    if (this.loadTask.last?.isRunning) {
      return this.loadTask.last;
    }
    try {
      return await this.loadTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  async displayBanner() {
    let banner = this.active;
    let confirmed = await this.modals.confirm({
      title: banner.header,
      message: htmlSafe(banner.body),
      cancel: this.intl.t('bannerService.action.cancel'),
    });

    return confirmed;
  }
}
