import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"web-to-print-select\">\n  <Ui::Input::Select\n    @label={{t \"webToPrintTemplate.label\"}}\n    @name=\"webToPrintTemplate-{{@product.id}}\"\n    @onChange={{perform @onChange @sectionContent}}\n    @options={{this.templateOptions}}\n    @disabled={{this.isDisabled}}\n    @value={{this.templateValue}}\n    @placeholder={{t \"webToPrintTemplate.placeholder\"}}\n  />\n  {{#if this.showInfo}}\n    <div local-class=\"small-font\">\n      {{t \"webToPrintTemplate.text.info\"}}\n      <Ui::Tooltip local-class=\"tooltip\" @text={{t \"webToPrintTemplate.text.tooltip\"}} />\n    </div>\n  {{/if}}\n</div>", {"contents":"<div local-class=\"web-to-print-select\">\n  <Ui::Input::Select\n    @label={{t \"webToPrintTemplate.label\"}}\n    @name=\"webToPrintTemplate-{{@product.id}}\"\n    @onChange={{perform @onChange @sectionContent}}\n    @options={{this.templateOptions}}\n    @disabled={{this.isDisabled}}\n    @value={{this.templateValue}}\n    @placeholder={{t \"webToPrintTemplate.placeholder\"}}\n  />\n  {{#if this.showInfo}}\n    <div local-class=\"small-font\">\n      {{t \"webToPrintTemplate.text.info\"}}\n      <Ui::Tooltip local-class=\"tooltip\" @text={{t \"webToPrintTemplate.text.tooltip\"}} />\n    </div>\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/layout/web-to-print-template-select/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/web-to-print-template-select/index.hbs"}});
import { service } from '@ember/service';
import { WEB2PRINT_TEMPLATE } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';
import sortBy from 'lodash-es/sortBy';

export default class WebToPrintTemplateSelectComponent extends Component {
  @service codelists;
  @service features;

  get isDisabled() {
    return this.templateOptions.length === 1;
  }

  get showInfo() {
    let templatesForInfo = [WEB2PRINT_TEMPLATE.STANDARD];

    if (this.features.isEnabled('w2pProductTemplates')) {
      templatesForInfo.push(WEB2PRINT_TEMPLATE.PRODUCTS2, WEB2PRINT_TEMPLATE.PRODUCTS3, WEB2PRINT_TEMPLATE.PRODUCTS4);
    }

    return templatesForInfo.includes(this.args.sectionContent.webToPrintTemplate);
  }

  get sortedAndMappedCodelist() {
    let codelist = this.codelists.getCodeList('w2pTemplates');
    let sortedCodelist = sortBy(codelist, 'sortOrder');
    return sortedCodelist.map(({ code, description }) => ({ value: code, text: description }));
  }

  get templateOptions() {
    let product = this.args.product;
    let validCodes = [
      WEB2PRINT_TEMPLATE.STANDARD,
      WEB2PRINT_TEMPLATE.MARKETING,
      WEB2PRINT_TEMPLATE.INTERIOR,
      WEB2PRINT_TEMPLATE.CONTRIBUTORS,
    ];

    if (this.features.isEnabled('w2pProductTemplates')) {
      validCodes.push(WEB2PRINT_TEMPLATE.PRODUCTS2, WEB2PRINT_TEMPLATE.PRODUCTS3, WEB2PRINT_TEMPLATE.PRODUCTS4);
    }

    if (product.isPromotionalPackage) {
      validCodes = [WEB2PRINT_TEMPLATE.PROMO];
    } else if (product.isThemenspecial) {
      validCodes = [WEB2PRINT_TEMPLATE.SPECIAL, WEB2PRINT_TEMPLATE.BACKLIST];
    }

    return this.sortedAndMappedCodelist.filter((element) => validCodes.includes(element.value));
  }

  get templateValue() {
    let sectionContent = this.args.sectionContent;

    if (sectionContent.webToPrintTemplate) {
      return sectionContent.webToPrintTemplate;
    }

    let options = this.templateOptions;
    if (options.length === 1) {
      let value = options[0].value;
      this.args.onChange?.perform(sectionContent, value);
      return value;
    }

    return null;
  }
}
