import { action } from '@ember/object';
import { service } from '@ember/service';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Route from '@ember/routing/route';

export default class OrderOrderListIndexRoute extends Route {
  @service abilities;
  @service('search-products-in-order-list') searchService;

  queryParams = {
    qp: {
      refreshModel: true,
    },
  };

  @action
  didTransition() {
    if (this.abilities.can('order.order')) {
      this.search();
    }
  }

  model() {
    return this.modelFor('order/order-list');
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.searchService.reset();
    }
  }

  search() {
    let model = this.modelFor(this.routeName).openOrderList;
    let params = this.paramsFor(this.routeName);
    this.searchService.search(null, { model, replaceRoute: true }, queryParamsUnpack(params));
  }
}
