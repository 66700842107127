import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { URL_IMPORT_THALIA_REFERENCE_PRODUCTS } from '@mvb/tix-ui/constants';
import Controller from '@ember/controller';
import ModalThaliaImport from '@mvb/tix-ui/components/modals/modal-thalia-import';

export default class PreviewListsPreviewListIndexController extends Controller {
  @service errors;
  @service modals;
  @service('previews') previewsService;
  @service previewList;
  @service router;
  @service('search-previews-in-list') searchService;
  @service store;

  queryParams = ['qp'];
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  @action
  onAddToList() {
    this.previewList.addPreviewsToList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter,
      this.searchService.searchQuery?.sort
    );
  }

  @action
  onDeleteFromList() {
    this.previewList.deletePreviewsFromList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter,
      this.searchService.searchQuery?.sort,
      this.model.list.id
    );
  }

  @action
  onSendPreview() {
    this.previewsService.onSendPreview(this.searchService.meta.total, this.searchService.searchQuery?.filter);
  }

  @action
  onImportReferenceProducts() {
    return this.modals.open(ModalThaliaImport, {
      translationPath: 'previewListsPreviewList.modal.referenceProductsImport',
      endpoint: URL_IMPORT_THALIA_REFERENCE_PRODUCTS,
      previewListId: this.model.list.id,
    });
  }

  @action
  async onUpdatePreviewLists(preview) {
    let { itemOnList } = await this.previewList.updateListsForPreview(preview, this.model.list);

    if (!itemOnList.get(this.model.list.id)) {
      this.searchService.search();
    }
  }
}
