import { PREVIEW_TYPE } from '../constants';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validatePreviewTypeAndCmcType() {
  return (key, newValue, oldValue, changes, content) => {
    if (content.type === PREVIEW_TYPE.THALIA && !newValue) {
      let intlService = applicationUtil.lookup('service:intl');
      return buildMessage(key, {
        message: intlService.t('validation.preview.previewTypeCmcType'),
      });
    }
    return true;
  };
}
