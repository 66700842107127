export default {
  "wrapper": "_wrapper_1pvi4",
  "intro": "_intro_1pvi4",
  "completed-msg": "_completed-msg_1pvi4",
  "error-msg": "_error-msg_1pvi4",
  "progress": "_progress_1pvi4",
  "progress-bar": "_progress-bar_1pvi4",
  "in-progress": "_in-progress_1pvi4",
  "value": "_value_1pvi4",
  "indeterminable": "_indeterminable_1pvi4",
  "completed": "_completed_1pvi4",
  "error": "_error_1pvi4",
  "progress-bar-slide": "_progress-bar-slide_1pvi4"
};
