import { belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import Model from './-base';

export default class AssignedPartyModel extends Model {
  @service intl;

  @belongsTo('party', { async: false, inverse: null }) party;
  @belongsTo('permission-set', { async: false, inverse: null }) permissionSet;
  @belongsTo('user', { async: false, inverse: 'assignedParties' }) user;

  get isPremium() {
    return !!this.party?.isPremium;
  }

  get permissionsLabel() {
    let name = this.permissionSet?.name ?? 'undefined';

    if (this.intl.exists(`modelAssignedParty.text.permission.${name}`)) {
      return this.intl.t(`modelAssignedParty.text.permission.${name}`);
    }

    return name;
  }

  hasPermission(permission) {
    return this.permissionSet?.permissions?.includes(permission);
  }
}
