import { REGEX_VIDEO } from '@mvb/tix-ui/constants';
import { validateFormat, validateLength, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  resourceContentType: [
    validatePresence({
      presence: true,
      description: 'intl:validation.video.description.resourceContentType',
    }),
  ],
  urlContent: [
    validatePresence({
      presence: true,
      description: 'intl:validation.video.description.urlContent',
    }),
    validateFormat({
      regex: REGEX_VIDEO,
      message: 'intl:validation.video.message.urlContent',
      allowBlank: true,
    }),
  ],
  caption: [validateLength({ max: 255, description: 'intl:validation.video.description.caption' })],
  copyright: [validateLength({ max: 255, description: 'intl:validation.video.description.copyright' })],
};
