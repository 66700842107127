import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class PreviewsPreviewProductController extends Controller {
  @service dispoLists;
  @service errors;

  queryParams = ['asset'];

  @tracked asset = null;

  @action
  updateSelectedAsset(assetId) {
    this.asset = assetId;
  }
}
