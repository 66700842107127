import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<img\n  ...attributes\n  src={{this.src}}\n  alt={{@alt}}\n  width={{this.width}}\n  height={{this.height}}\n  local-class=\"img\"\n  data-test-lazy-image\n  data-loading={{this.isLoading}}\n  {{on \"load\" this.onLoad}}\n  {{on \"error\" this.onError}}\n  {{did-intersect onEnter=this.onIntersection options=this.options maxEnter=1}}\n  {{prevent-contextmenu}}\n/>", {"contents":"<img\n  ...attributes\n  src={{this.src}}\n  alt={{@alt}}\n  width={{this.width}}\n  height={{this.height}}\n  local-class=\"img\"\n  data-test-lazy-image\n  data-loading={{this.isLoading}}\n  {{on \"load\" this.onLoad}}\n  {{on \"error\" this.onError}}\n  {{did-intersect onEnter=this.onIntersection options=this.options maxEnter=1}}\n  {{prevent-contextmenu}}\n/>","moduleName":"@mvb/tix-ui/components/ui/lazy-img/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/lazy-img/index.hbs"}});
import { action } from '@ember/object';
import { assert, runInDebug } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

const placeholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export default class LazyLoadingComponent extends Component {
  @tracked src = placeholder;
  @tracked isLoading = true;

  options;

  constructor() {
    super(...arguments);

    runInDebug(() => {
      assert('@alt is a required argument', this.args.alt !== undefined);
    });

    this.options = {
      rootMargin: `${window.innerHeight}px`,
    };
  }

  get height() {
    return Number.parseInt(this.args.file?.get('height') ?? this.args.height, 10) || null;
  }

  get width() {
    return Number.parseInt(this.args.file?.get('width') ?? this.args.width, 10) || null;
  }

  @action
  onIntersection() {
    this.src = this.args.file?.get('downloadUrl') ?? this.args.src;
  }

  @action
  onLoad(event) {
    this.isLoading = event.target.src === placeholder;
  }

  @action
  onError() {
    if (this.args.fallbackUrl) {
      this.src = this.args.fallbackUrl;
    }
  }
}
