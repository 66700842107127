export default {
  "additional-information-edit": "_additional-information-edit_74xocm",
  "saving": "_saving_74xocm",
  "input-row": "_input-row_74xocm",
  "tag-input": "_tag-input_74xocm",
  "template-section": "_template-section_74xocm",
  "flex-input": "_flex-input_74xocm",
  "recommended-order-amount": "_recommended-order-amount_74xocm",
  "form-actions": "_form-actions_74xocm",
  "help": "_help_74xocm"
};
