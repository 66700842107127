import { DateTime } from 'luxon';
import { isEmpty } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';

export default class DateTimeTransform extends Transform {
  deserialize(serialized) {
    if (isEmpty(serialized)) {
      return serialized;
    }
    // format to valid UTC, backend may send quirky timestamps
    let serializedUTC = serialized.toString().match(/(z|Z|\d{2}:\d{2})$/) === null ? `${serialized}Z` : serialized;
    return DateTime.fromISO(serializedUTC);
  }

  serialize(deserialized) {
    return isEmpty(deserialized) ? deserialized : deserialized.toUTC().toISO();
  }
}
