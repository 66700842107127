import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.productListExportActions}}\n    @disabled={{this.maxProductCountForExportExceeded}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{this.exportLabel}}\n  />\n{{/if}}", {"contents":"{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.productListExportActions}}\n    @disabled={{this.maxProductCountForExportExceeded}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{this.exportLabel}}\n  />\n{{/if}}","moduleName":"@mvb/tix-ui/components/ui/function-bar/export/product-list.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/function-bar/export/product-list.hbs"}});
import { action } from '@ember/object';
import { EXPORT_FILE_TYPES, TIX_ITEM_SOURCE_TYPE, URL_PRODUCT_EXPORT } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import saveBlob from '@mvb/tix-ui/utils/save-blob';

export default class UiFunctionBarExportProductListComponent extends Component {
  @service abilities;
  @service api;
  @service errors;
  @service features;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service('products') productsService;
  @service router;
  @service('search-products-in-list') searchService;

  get productListExportActions() {
    let actions = {};

    if (this.features.isEnabled('productsPdfExport') && this.features.isEnabled('productlistProductsPdfExport')) {
      actions.pdf = { label: this.intl.t('uiFunctionBarExport.action.pdf'), action: this.onPdfExport };
    }
    actions.excel = { label: this.intl.t('uiFunctionBarExport.action.excel'), action: this.onExcelExport };
    if (this.abilities.can('productList.requestExcelWithOrderDataExport')) {
      actions.excelWithOrderData = {
        label: this.intl.t('uiFunctionBarExport.action.excelWithOrderData'),
        action: this.onExcelWithOrderDataExport,
      };
    }
    if (this.abilities.can('productList.requestThaliaExport')) {
      actions.thaliaExport = {
        label: this.intl.t('uiFunctionBarExport.action.thaliaProductList'),
        action: this.onExcelWithOrderDataExport,
      };
    }

    return actions;
  }

  get hasActions() {
    return Object.keys(this.productListExportActions).length > 0;
  }

  get exportLabel() {
    return this.productsService.getExportLabel(this.maxProductCountForExportExceeded);
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  get maxProductCountForExportExceeded() {
    return this.productsService.getMaxProductCountForExportExceeded(this.searchService.meta.total);
  }

  @action
  onPdfExport() {
    this.exportProductsTask.perform(EXPORT_FILE_TYPES.PDF);
  }

  @action
  onExcelExport() {
    this.exportProductsTask.perform(EXPORT_FILE_TYPES.XLSX, false);
  }

  @action
  onExcelWithOrderDataExport() {
    this.exportProductsTask.perform(EXPORT_FILE_TYPES.XLSX);
  }

  @task
  @waitFor
  *exportProductsTask(exportFileType, includeOrderInformation = true) {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('productListsProductExport.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    let filters = this.searchService.searchQuery.filter || {};
    delete filters['productList.id'];
    delete filters['onList'];

    try {
      let body = {
        exportFileType,
        filters,
        identifiers: Array.from(this.manageList.selectedItems),
        includeOrderInformation,
        invertedSelection: this.manageList.inverse,
        sort: this.searchService.searchQuery.sort,
        sourceId: this.args.productList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.PRODUCT_LIST,
      };

      switch (exportFileType) {
        case EXPORT_FILE_TYPES.PDF: {
          this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportPdf'));
          break;
        }
        case EXPORT_FILE_TYPES.XLSX: {
          this.notifications.success(
            this.intl.t(
              this.abilities.can('productList.requestThaliaExport') && includeOrderInformation
                ? 'uiFunctionBarExport.notification.exportViaMail'
                : 'uiFunctionBarExport.notification.exportXlsx'
            )
          );
          break;
        }
        default: {
          this.notifications.success(this.intl.t('uiFunctionBarExport.notification.export'));
          break;
        }
      }
      let { blob, attachmentName } = yield this.api.postJSON(URL_PRODUCT_EXPORT, body);
      saveBlob(blob, attachmentName);
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
