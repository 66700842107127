import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validatePreviewSeasonYear(options = {}) {
  return (key, newValue, _oldValue, changes, content) => {
    let season = changes?.season ?? content?.season;

    if (!newValue && season) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
