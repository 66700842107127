import { isBlank } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateOrderListFinalizeOrderChannelTypeRequired(options = {}) {
  return (key, newValue, _oldValue, changes, content) => {
    let selectedOrderChannelType = changes?.selectedOrderChannelType ?? content?.selectedOrderChannelType;

    if (options?.dependentOrderChannelTypes?.includes(selectedOrderChannelType) && isBlank(newValue)) {
      return buildMessage(key, { type: 'present', value: newValue, context: options });
    }
    return true;
  };
}
