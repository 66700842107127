export default {
  "price-block": "_price-block_1yn8ba",
  "identifier-at-bottom": "_identifier-at-bottom_1yn8ba",
  "small": "_small_1yn8ba",
  "price-info": "_price-info_1yn8ba",
  "price": "_price_1yn8ba",
  "previous": "_previous_1yn8ba",
  "discount": "_discount_1yn8ba",
  "currency": "_currency_1yn8ba",
  "unpriced": "_unpriced_1yn8ba",
  "campain": "_campain_1yn8ba",
  "dates": "_dates_1yn8ba",
  "identifier": "_identifier_1yn8ba",
  "availability": "_availability_1yn8ba",
  "orderquantity": "_orderquantity_1yn8ba",
  "age": "_age_1yn8ba"
};
