import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @product.groupedAndSortedContributors}}\n  {{#each-in @product.groupedAndSortedContributors as |group items|}}\n    <h4 data-test-contributor-group={{group}}>\n      {{cl \"17\" group}}\n    </h4>\n\n    <Layout::AsList data-test-contributor-list={{group}} @list={{items}} as |contributor|>\n      <a href=\"#contributor-{{contributor.id}}\" {{on \"click\" (fn this.jumpTo contributor.id)}}>\n        {{~contributor.name~}}\n      </a>\n    </Layout::AsList>\n  {{/each-in}}\n{{/if}}\n\n{{yield}}", {"contents":"{{#if @product.groupedAndSortedContributors}}\n  {{#each-in @product.groupedAndSortedContributors as |group items|}}\n    <h4 data-test-contributor-group={{group}}>\n      {{cl \"17\" group}}\n    </h4>\n\n    <Layout::AsList data-test-contributor-list={{group}} @list={{items}} as |contributor|>\n      <a href=\"#contributor-{{contributor.id}}\" {{on \"click\" (fn this.jumpTo contributor.id)}}>\n        {{~contributor.name~}}\n      </a>\n    </Layout::AsList>\n  {{/each-in}}\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/advanced-bib/contributors.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/advanced-bib/contributors.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ProductAdvancedBibContributorsComponent extends Component {
  @service jump;

  @action
  jumpTo(id, event) {
    event.preventDefault();
    this.jump.scrollToAnchor(`contributor-${id}`);
  }
}
