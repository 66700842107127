import { didCancel, task } from 'ember-concurrency';
import { TIX_ITEM_SOURCE_TYPE } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import DispoListsModalAddToDispoListIndexComponent from '@mvb/tix-ui/components/dispo-lists/modal-add-to-dispo-list';
import DispoListsModalEditDispoListIndexComponent from '@mvb/tix-ui/components/dispo-lists/modal-edit-dispo-list';
import orderBy from 'lodash-es/orderBy';
import Service, { service } from '@ember/service';

export default class DispoListsService extends Service {
  @service api;
  @service errors;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service store;
  @service user;

  get defaultDispoList() {
    return this.current.find((list) => list.defaultByUser);
  }

  get current() {
    let value = this.loadTask.lastSuccessful?.value;

    return value ?? [];
  }

  get selectOptions() {
    let result = this.loadTask.lastSuccessful?.value.map(({ name: text, id: value }) => ({
      text,
      value,
    }));
    return result ?? [];
  }

  get selectOptionsByLastUpdated() {
    let options = this.loadTask.lastSuccessful?.value ?? [];

    return orderBy(options, 'lastUpdated.ts', 'desc').map(({ name: text, id: value }) => ({
      text,
      value,
    }));
  }

  get selectOptionsRecords() {
    let options = this.loadTask.lastSuccessful?.value ?? [];

    return options.map((record) => ({
      text: record.name,
      value: record,
    }));
  }

  get selectOptionsRecordsByName() {
    let options = this.loadTask.lastSuccessful?.value ?? [];
    return orderBy(
      options,
      [(record) => record.defaultByUser, (record) => record.name.toLowerCase()],
      ['desc', 'asc']
    ).map((record) => {
      let recordName = record.defaultByUser ? record.name + this.intl.t('dispoList.defaultByUser') : record.name;
      return {
        text: recordName,
        value: record,
      };
    });
  }

  @task({ drop: true })
  @waitFor
  *loadTask() {
    if (!this.user.selectedParty) {
      return [];
    }

    try {
      return yield this.store.query('dispo-list', {
        filter: {
          unpaged: true,
        },
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  *deleteDispoListTask(dispoList) {
    let confirmation = yield this.modals.confirm({
      title: this.intl.t('dispoList.deleteDispoListConfirmation.text.title'),
      message: this.intl.t('dispoList.deleteDispoListConfirmation.text.message', { name: dispoList.name }),
      confirm: this.intl.t('dispoList.deleteDispoListConfirmation.action.confirm'),
    });
    if (!confirmation) {
      return;
    }
    try {
      yield dispoList.destroyRecord();
      this.notifications.success(this.intl.t('dispoList.notification.dispoListDeleted'));
      // this is currently required because the router will throw a "transitionAborted" error for an unknown reason at this point and having a catch that does nothing seemed like a better option than nesting try/catches to essentially do the same thing
      return this.router.replaceWith('order.dispo-lists.index').catch((error) => {
        if (error.message !== 'TransitionAborted') {
          this.errors.handle(error);
        }
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *addPreviewToDispoListTask(data) {
    try {
      yield this.api.postJSON('/dispo-lists/assign-recommended-amounts', {
        filters: data.filters ?? {},
        identifiers: data.selectedItems ?? [],
        invertedSelection: data.invertedSelection,
        sourceId: data.previewId,
        sourceType: TIX_ITEM_SOURCE_TYPE.PREVIEW,
        targetId: data.dispoListId,
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *addSpecialToDispoListTask(data) {
    try {
      yield this.api.postJSON('/dispo-lists/add-products-of-special', {
        amount: data.amount,
        branchIds: data.branchIds,
        targetId: data.dispoListId,
        sourceId: data.specialId,
        sourceType: TIX_ITEM_SOURCE_TYPE.SPECIAL,
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *deleteFromDispoListTask(data) {
    try {
      yield this.api.deleteJSON('/dispo-lists/remove-products', {
        filters: data.filters ?? {},
        identifiers: data.selectedItems ?? [],
        invertedSelection: data.invertedSelection ?? false,
        sourceId: data.dispoList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.DISPO_LIST,
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *moveOrderListToDispoListTask(data) {
    try {
      yield this.api.postJSON('/order-lists/move-to-dispo-list', {
        branchId: data.branchId,
        filters: data.filters ?? {},
        identifiers: data.selectedItems ?? [],
        invertedSelection: data.invertedSelection ?? false,
        targetId: data.dispoListId,
      });
      this.manageList.reset();
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *addProductsToDispoListTask(data) {
    try {
      yield this.api.postJSON('/dispo-lists/add-products', {
        amount: data.amount,
        branchIds: data.branchIds,
        filters: data.filters ?? {},
        identifiers: data.selectedItems ?? [],
        invertedSelection: data.invertedSelection ?? false,
        targetId: data.dispoListId,
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *moveToOrderListTask(data) {
    try {
      yield this.api.postJSON('/dispo-lists/move-to-order-list', {
        filters: data.filters ?? {},
        identifiers: data.selectedItems ?? [],
        invertedSelection: data.invertedSelection ?? false,
        sourceId: data.dispoList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.DISPO_LIST,
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  async addToDispoList(data) {
    let result = await this.modals.open(DispoListsModalAddToDispoListIndexComponent, data);

    if (!result) {
      return false;
    }

    let progress = this.progress.add({
      message: this.intl.t('modalAddToDispoList.text.progress'),
    });

    try {
      if (data.previewId) {
        await this.addPreviewToDispoListTask.perform({ ...data, ...result });
      } else if (data.specialId) {
        await this.addSpecialToDispoListTask.perform({ ...data, ...result });
      } else if (data.orderListId) {
        await this.moveOrderListToDispoListTask.perform({ ...data, ...result });
      } else {
        await this.addProductsToDispoListTask.perform({ ...data, ...result });
      }

      progress.remove();

      if (data.successMessage) {
        this.notifications.success(data.successMessage);
      }
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }

    return true;
  }

  async deleteFromDispoList(data) {
    let confirmed = await this.modals.confirm({
      title: this.intl.t('modalDeleteFromDispoList.text.title'),
      message: this.intl.t('modalDeleteFromDispoList.text.body'),
      confirm: this.intl.t('modalDeleteFromDispoList.action.confirm'),
      cancel: this.intl.t('modalDeleteFromDispoList.action.cancel'),
      name: 'delete-from-dispo-list',
    });

    if (!confirmed) {
      return false;
    }

    let progress = this.progress.add({
      message: this.intl.t('modalDeleteFromDispoList.text.progress'),
    });

    try {
      await this.deleteFromDispoListTask.perform(data);

      progress.remove();

      if (data.successMessage) {
        this.notifications.success(data.successMessage);
      }
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }

    return true;
  }

  async moveToOrderList(data) {
    let confirmed = await this.modals.confirm({
      title: this.intl.t('modalMoveToOrderList.text.title'),
      message: this.intl.t('modalMoveToOrderList.text.body'),
      confirm: this.intl.t('modalConfirm.action.confirm'),
      cancel: this.intl.t('modalConfirm.action.cancel'),
      name: 'move-to-order-list',
    });

    if (!confirmed) {
      return false;
    }

    let progress = this.progress.add({
      message: this.intl.t('modalMoveToOrderList.text.progress'),
    });

    try {
      await this.moveToOrderListTask.perform(data);

      progress.remove();

      if (data.successMessage) {
        this.notifications.success(data.successMessage);
      }
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }

    return true;
  }

  async editDispoList() {
    let result = await this.modals.open(DispoListsModalEditDispoListIndexComponent, ...arguments);

    if (!result) {
      return false;
    }

    let progress = this.progress.add({
      message: this.intl.t('modalEditDispoList.text.progress'),
    });

    try {
      await result.changeset.save();

      progress.remove();
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }

    return true;
  }

  async load() {
    try {
      return await this.loadTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }
}
