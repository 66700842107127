import { isEmpty } from '@ember/utils';
import Helper from '@ember/component/helper';

export default class DateTimeFormatHelper extends Helper {
  compute([value, format]) {
    if (isEmpty(value)) {
      return '';
    }

    return value.toFormat(format);
  }
}
