import { createMachine, sendParent } from 'xstate';

/**
 * @namespace childMachine
 * @event childMachine#UPDATE
 * @event childMachine#CHANGE
 * @event childMachine#REVERT
 * @event childMachine#RESOLVE
 * @event childMachine#COMMIT
 * @xstate-layout N4IgpgJg5mDOIC5SQJYBcCyB7CYDCAFigDYQYCGAxkQHZgB0ArgA4Tloo1T0oTFgBiAKoAFACIBBACoBRANoAGALqJQzLLHQosNVSAAeiAMwBOE-QAcAFgUA2AEwL7thSYsmAjABoQAT0QAtEZWFvQA7CYh9ib2YR4mcbYAvkk+qJg4+ESkFNScDCxsHFz0zGA0EJxQwuLS8sp66pocOnqGCACspvSOkbZWYTYdHv0+-ghBYfY9Hh4hJkZT-bYjKWmVGbiEJGRUtAWs7FWl5ZVcAgBKMgDKAPIAMgBq9SpIIE1arW-tCfSRHmEXPZ7IsPB0zGNAo4PPRbDEovEEsEwmtwBtsFtsrs8nQmIditwyhUqpcbg9nnIPK81BpPrpvohPPQjEZ7DYFEYOvYutZbJCJrMFD1TCYVkZXBYFPFUekMVkdrl9vRqOQuHBrmh2AxmAAnFCwYqCPAACQkADkAOIvRq0lr00DtcFWcIWYJWWxGDzAtkWfkjcwOSJWYNhDoRGLJVJo9By7Y5Pb5ZUEVUwWAarX0So6tC+UnPC5SRTU9627T2gyICxhcLAsEgszckwKML8ob0d1WNkdCyOWySlFR2WZOPYpUqtVpzVoBhZnMCPC3DAYACShYabw+draiA8VfowxsIxZVd3HX5numYOb7tdZlmHUjUZomXgbyHmIVCdxhSOJV4-BtZoy23BBdxhUUqwGIxbEGA8Wz8QIjAsDoeimZDbA6d0pUWGV0WHLFFUTH8CROYkuEAukQKQlCrFMQFuTsV1Bn5AJd2dMMvQ9UUoisUVcJjfDPxxA4iiqCitwZBBaOZVl2U5bkkPdFiBmdWJ7AsfpXClFxkP4zZ5XjYSkxTdUp21PUDXycTgMkplT1sDCOQYkZ7D9YJwgc4JYmGBzeIsPTYwIr8GHHVN02nTMUGzcYaSAr4HUQFxqw9UMuQGBZuT5BCpPUyxJRiMETEwgZ7ACwTDLHZMJ3CsBrPiisEG6KZ7DrEVG2bVsUMBD1aOCBRsNmMqPwqqyN1Ler2gCUJYjCaxAX6wZXFiFiHyMVD1NMDwFFo2IUhSIA
 */
export default createMachine(
  {
    predictableActionArguments: true,
    id: 'editModeChildMachine',
    type: 'parallel',
    states: {
      updating: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              UPDATE: {
                target: 'pending',
              },
            },
          },
          pending: {
            entry: ['addPendingTask', sendParent('CHANGE')],
            exit: sendParent('RESOLVE'),
            on: {
              UPDATE: {
                actions: 'addPendingTask',
              },
              RESOLVE: [
                {
                  target: 'idle',
                  cond: 'allDone',
                  actions: 'removePendingTask',
                },
                {
                  actions: 'removePendingTask',
                },
              ],
            },
          },
        },
      },
      changesState: {
        initial: 'pristine',
        states: {
          pristine: {
            on: {
              CHANGE: {
                target: 'dirty',
                actions: sendParent('INC_CHANGES'),
              },
            },
          },
          dirty: {
            on: {
              REVERT: {
                target: 'pristine',
                actions: sendParent('DEC_CHANGES'),
              },
              COMMIT: {
                target: 'pristine',
                actions: sendParent('DEC_CHANGES'),
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      allDone: (context) => context.pendingCount === 1,
    },
    actions: {
      addPendingTask: (context) => context.pendingCount++,
      removePendingTask: (context) => context.pendingCount--,
    },
  }
);
