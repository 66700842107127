export default {
  "media-object": "_media-object_1xul6l",
  "content": "_content_1xul6l",
  "image": "_image_1xul6l",
  "preview-wrapper": "_preview-wrapper_1xul6l",
  "preview": "_preview_1xul6l",
  "invisible": "_invisible_1xul6l",
  "hint": "_hint_1xul6l",
  "source": "_source_1xul6l",
  "editor": "_editor_1xul6l"
};
