import { service } from '@ember/service';
import facetLabel from '@mvb/tix-ui/utils/facet-label';
import Helper from '@ember/component/helper';

export default class FacetLabelHelper extends Helper {
  @service intl;

  compute([list, code]) {
    return facetLabel({ code, intl: this.intl, list });
  }
}
