import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  local-class=\"truncate\"\n  data-test-truncate\n  {{did-insert this.setup}}\n  {{observe-mutation this.setup childList=true characterData=true}}\n  {{did-update (fn this.toggle @truncate) @truncate}}\n>\n  {{yield to=\"content\"}}\n</div>\n\n{{#if (has-block \"more\")}}\n  {{yield\n    (hash neededTruncating=this.neededTruncating isTruncated=this.isTruncated toggle=this.toggleOnClick)\n    to=\"more\"\n  }}\n{{else}}\n  {{#if this.neededTruncating}}\n    <p local-class=\"more\" data-more data-test-more>\n      <Ui::Button @style=\"link\" @onClick={{this.toggleOnClick}}>\n        {{#if this.isTruncated}}\n          {{t \"layoutTruncate.text.more\"}}\n        {{else}}\n          {{t \"layoutTruncate.text.less\"}}\n        {{/if}}\n      </Ui::Button>\n    </p>\n  {{/if}}\n{{/if}}", {"contents":"<div\n  ...attributes\n  local-class=\"truncate\"\n  data-test-truncate\n  {{did-insert this.setup}}\n  {{observe-mutation this.setup childList=true characterData=true}}\n  {{did-update (fn this.toggle @truncate) @truncate}}\n>\n  {{yield to=\"content\"}}\n</div>\n\n{{#if (has-block \"more\")}}\n  {{yield\n    (hash neededTruncating=this.neededTruncating isTruncated=this.isTruncated toggle=this.toggleOnClick)\n    to=\"more\"\n  }}\n{{else}}\n  {{#if this.neededTruncating}}\n    <p local-class=\"more\" data-more data-test-more>\n      <Ui::Button @style=\"link\" @onClick={{this.toggleOnClick}}>\n        {{#if this.isTruncated}}\n          {{t \"layoutTruncate.text.more\"}}\n        {{else}}\n          {{t \"layoutTruncate.text.less\"}}\n        {{/if}}\n      </Ui::Button>\n    </p>\n  {{/if}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/layout/truncate.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/truncate.hbs"}});
import { action } from '@ember/object';
import { Ellipsis } from 'ftellipsis';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class LayoutTruncateComponent extends Component {
  ellipsis = null;

  @tracked neededTruncating = false;
  @tracked isTruncated = false;

  @action
  setup(element) {
    this._element = element;
    this.ellipsis = new Ellipsis(element);

    this.lineHeight = Number.parseInt(getComputedStyle(this._element).lineHeight, 10);
    this.offsetHeight = this._element.offsetHeight;
    this.neededTruncating = typeof this.args.height === 'number' && this.offsetHeight > this.args.height;

    if (this.neededTruncating && this.args.truncate) {
      this.truncate();
    }
  }

  @action
  toggle(force) {
    let truncate = typeof force === 'boolean' ? force : !this.isTruncated;
    if (truncate) {
      this.truncate();
    } else {
      this.untruncate();
    }
  }

  @action
  toggleOnClick() {
    this.toggle(!this.isTruncated);
    this.args.onToggle?.(!this.isTruncated);
  }

  updateElementStyle(add = true) {
    // Ellipsis does not play well with the Ember render cycle,
    // which requires us to set the style on the element directly.
    if (add) {
      this._element.lineHeight = this.lineHeight;
      this._element.style.maxHeight = `${this.args.height}px`;
    } else {
      this._element.style.lineHeight = null;
      this._element.style.maxHeight = null;
    }
  }

  truncate() {
    this.isTruncated = true;
    this.updateElementStyle(true);
    this.ellipsis.calc();
    this.ellipsis.set();
    this.updateElementStyle(false);
  }

  untruncate() {
    this.isTruncated = false;
    this.updateElementStyle(false);
    this.ellipsis.unset();
  }

  willDestroy() {
    this._element = null;
    this.ellipsis = null;
    super.willDestroy(...arguments);
  }
}
