import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"container\" data-test-page-actions-container {{did-insert this.setContainer}}>\n  {{yield}}\n</div>", {"contents":"<div local-class=\"container\" data-test-page-actions-container {{did-insert this.setContainer}}>\n  {{yield}}\n</div>","moduleName":"@mvb/tix-ui/components/layout/page-actions-container/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/page-actions-container/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class UiPageActionsContainerComponent extends Component {
  @service pageActions;

  @action
  setContainer(element) {
    if (element !== this.pageActions.targetElement) {
      this.pageActions.targetElement = element;
    }
  }

  willDestroy() {
    this.pageActions.targetElement = null;
    super.willDestroy(...arguments);
  }
}
