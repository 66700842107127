import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Search::Filter\n  local-class=\"filter\"\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterPreviewCustomerContact.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <Ui::Input::Typeahead\n      @isHtmlSafe={{true}}\n      @isResettable={{true}}\n      @minCharsToTrigger={{3}}\n      @name={{join \"-\" @filter}}\n      @noResultsMessage={{t \"searchFilterPreviewCustomerContact.text.nothingFound\"}}\n      @onSelect={{this.onFilterChange}}\n      @placeholder={{t \"searchFilterPreviewCustomerContact.label.placeholder\"}}\n      @searchForSuggestionsTask={{this.searchForSuggestionsTask}}\n      @searchQuery={{this.term}}\n      @termProperty=\"fullName\"\n    />\n  </:content>\n</Search::Filter>", {"contents":"<Search::Filter\n  local-class=\"filter\"\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterPreviewCustomerContact.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <Ui::Input::Typeahead\n      @isHtmlSafe={{true}}\n      @isResettable={{true}}\n      @minCharsToTrigger={{3}}\n      @name={{join \"-\" @filter}}\n      @noResultsMessage={{t \"searchFilterPreviewCustomerContact.text.nothingFound\"}}\n      @onSelect={{this.onFilterChange}}\n      @placeholder={{t \"searchFilterPreviewCustomerContact.label.placeholder\"}}\n      @searchForSuggestionsTask={{this.searchForSuggestionsTask}}\n      @searchQuery={{this.term}}\n      @termProperty=\"fullName\"\n    />\n  </:content>\n</Search::Filter>","moduleName":"@mvb/tix-ui/components/search/filter/preview-customer-contact/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/preview-customer-contact/index.hbs"}});
import { action } from '@ember/object';
import { CONTACT_TYPES } from '@mvb/tix-ui/constants';
import { isBlank, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterPreviewCustomerContactComponent extends SearchFilterBaseComponent {
  @service errors;
  @service store;

  @tracked term;

  constructor() {
    super(...arguments);

    this.onReset();
    this.onValueArgumentChange();
  }

  get count() {
    return isPresent(this.term) ? 1 : 0;
  }

  @task({ restartable: true })
  @waitFor
  *searchForSuggestionsTask(query) {
    if (isBlank(query)) {
      return [];
    }

    try {
      return yield this.store.query('user', {
        filter: {
          'customerContacts.contactType': CONTACT_TYPES.AGENT,
          'customerContacts.party.id': 'all',
          hasCustomerContact: true,
          searchTerm: query,
          unpaged: true,
        },
        include: 'customerContacts.party',
        sort: 'lastname',
      });
    } catch (error) {
      this.errors.handle(error);
    }

    return [];
  }

  @action
  onChange() {
    let query = {
      customerContact: this.term,
      sender: this.value.join(','),
    };

    this.args.onChange?.(query);
    this.search(query);
  }

  @action
  onFilterChange(user) {
    let term = user?.fullName ?? '';

    this.term = term;
    this.value = [
      ...new Set(
        user.customerContacts
          .filter((customerContact) => customerContact.contactType === CONTACT_TYPES.AGENT)
          .map((customerContact) => customerContact.party.mvbId)
          .filter(Boolean)
      ),
    ];
    this.onChange();

    return term;
  }

  @action
  onReset() {
    this.term = '';
    this.value = [];
  }

  @action
  onValueArgumentChange() {
    let { customerContact, sender } = this.args.value || {};

    if (undefined !== customerContact) {
      this.term = customerContact;
    }

    if (undefined !== sender) {
      this.value = [...new Set(sender.split(',')?.filter(Boolean))];
    }
  }
}
