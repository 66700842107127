import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Sortable\n  ...attributes\n  local-class=\"sortable\"\n  @items={{@contents}}\n  @onReorder={{this.onReorder}}\n  @onRemove={{this.onRemove}}\n  @permissions={{this.permissions}}\n>\n  <:body as |item index|>\n    {{yield item index to=\"body\"}}\n  </:body>\n  <:actions as |item index|>\n    {{yield item index to=\"actions\"}}\n  </:actions>\n</Ui::Sortable>", {"contents":"<Ui::Sortable\n  ...attributes\n  local-class=\"sortable\"\n  @items={{@contents}}\n  @onReorder={{this.onReorder}}\n  @onRemove={{this.onRemove}}\n  @permissions={{this.permissions}}\n>\n  <:body as |item index|>\n    {{yield item index to=\"body\"}}\n  </:body>\n  <:actions as |item index|>\n    {{yield item index to=\"actions\"}}\n  </:actions>\n</Ui::Sortable>","moduleName":"@mvb/tix-ui/components/sections/edit/sortable/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/sortable/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class SectionsEditSortableIndexComponent extends Component {
  @action
  onRemove(index) {
    this.args.onRemove?.(index);
    if (this.args.onRemoveLastItem && this.args.contents.length === 0) {
      this.args.onRemoveLastItem();
    }
  }

  @action
  onReorder(oldIndex, newIndex) {
    if (this.args.onReorder) {
      this.args.onReorder(oldIndex, newIndex);
      return;
    }
    let contents = [...this.args.contents];
    let [contentToChange] = contents.splice(oldIndex, 1);

    contents.splice(newIndex, 0, contentToChange);

    this.updatePositions(contents);
  }

  @action
  permissions(index) {
    let item = this.args.contents[index];
    let isNotLastItem = this.args.contents.length !== 1;
    return {
      delete: !item.isVLBContent && (this.args.allowRemoveAll || isNotLastItem),
    };
  }

  updatePositions(changesets) {
    for (let [index, changeset] of changesets.entries()) {
      changeset.set('position', index);
    }
  }
}
