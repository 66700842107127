import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Tags\n  @changeset={{@changeset}}\n  @deserialize={{this.deserialize}}\n  @disabled={{@disabled}}\n  @label={{@label}}\n  @max={{@max}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @path={{@path}}\n  @required={{@required}}\n  @separator={{@separator}}\n  @serialize={{this.serialize}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @value={{@value}}\n  @valueKey={{this.valueKey}}\n  ...attributes\n/>", {"contents":"<Ui::Input::Tags\n  @changeset={{@changeset}}\n  @deserialize={{this.deserialize}}\n  @disabled={{@disabled}}\n  @label={{@label}}\n  @max={{@max}}\n  @name={{@name}}\n  @onChange={{@onChange}}\n  @path={{@path}}\n  @required={{@required}}\n  @separator={{@separator}}\n  @serialize={{this.serialize}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @value={{@value}}\n  @valueKey={{this.valueKey}}\n  ...attributes\n/>","moduleName":"@mvb/tix-ui/components/ui/input/tags/to-json.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/tags/to-json.hbs"}});
import { action } from '@ember/object';
import { runInDebug } from '@ember/debug';
import Component from '@glimmer/component';

export default class UiInputTagsAsJsonComponent extends Component {
  @action serialize(tags) {
    return JSON.stringify(tags);
  }

  @action deserialize(value) {
    runInDebug(() => {
      try {
        JSON.parse(value);
      } catch {
        throw new Error(
          `Expected value to be a valid JSON string, got ${typeof value}${Array.isArray(value) ? ' (Array)' : ''}`
        );
      }
    });

    return JSON.parse(value);
  }
}
