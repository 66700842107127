export default {
  "item": "_item_192vlz",
  "mode": "_mode_192vlz",
  "form": "_form_192vlz",
  "with-tooltip": "_with-tooltip_192vlz",
  "upload": "_upload_192vlz",
  "filename": "_filename_192vlz",
  "caption-validations": "_caption-validations_192vlz",
  "tooltip": "_tooltip_192vlz",
  "muted": "_muted_192vlz",
  "error": "_error_192vlz _error_zhaoi9",
  "type-validations": "_type-validations_192vlz",
  "no-media-type": "_no-media-type_192vlz"
};
