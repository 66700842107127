import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Search::Filter\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterProductBranches.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <div data-test-branches local-class=\"branches\">\n      {{#each this.productBranchesOptions key=\"value\" as |option|}}\n        <Ui::Input::Checkbox\n          @checkboxValue={{option.value}}\n          @label={{option.text}}\n          @name={{@filter}}\n          @onChange={{fn this.onFilterChange option}}\n          @value={{option.checked}}\n        />\n      {{/each}}\n    </div>\n  </:content>\n</Search::Filter>", {"contents":"<Search::Filter\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterProductBranches.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <div data-test-branches local-class=\"branches\">\n      {{#each this.productBranchesOptions key=\"value\" as |option|}}\n        <Ui::Input::Checkbox\n          @checkboxValue={{option.value}}\n          @label={{option.text}}\n          @name={{@filter}}\n          @onChange={{fn this.onFilterChange option}}\n          @value={{option.checked}}\n        />\n      {{/each}}\n    </div>\n  </:content>\n</Search::Filter>","moduleName":"@mvb/tix-ui/components/search/filter/product-branches/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/product-branches/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterProductBranchesComponent extends SearchFilterBaseComponent {
  @service branches;

  constructor() {
    super(...arguments);

    this.onReset();
    this.onValueArgumentChange();
  }

  get productBranchesOptions() {
    return this.branches.currentByCreated.map((branch) => {
      return {
        checked: this.value.includes(branch.id),
        text: branch.name,
        value: branch.id,
      };
    });
  }

  @action
  onReset() {
    this.value = [];
  }

  @action
  onValueArgumentChange() {
    if (undefined !== this.args.value) {
      this.value = [...new Set(this.args.value?.split(',')?.filter(Boolean))];
    }
  }
}
