import { parse } from 'content-disposition-attachment';
import ENV from '@mvb/tix-ui/config/environment';
import fetch from 'fetch';
import filenamify from 'filenamify/browser';
import Service, { service } from '@ember/service';

export class ApiError extends Error {
  constructor(errorTitle, messages, status) {
    super(errorTitle);
    this.messages = messages;
    this.status = status;
  }
}

const generateQueryParams = (params) => {
  if (Object.keys(params).length > 0) {
    let requestParams = new URLSearchParams(params);
    return `?${requestParams.toString()}`;
  }

  return '';
};

export default class ApiService extends Service {
  @service intl;
  @service user;
  @service session;

  get headers() {
    let requestHeader = {
      'accept-language': 'de-DE',
    };

    let selectedPartyId = this.user.selectedParty?.party?.id;
    if (selectedPartyId) {
      requestHeader['tix-selected-account'] = selectedPartyId;
    }

    return requestHeader;
  }

  async fetch(path, options = {}) {
    options = { ...options, credentials: 'include', headers: { ...this.headers, ...options.headers } };

    let response = await fetch(`${ENV.APP.api}${path}`, options);
    let responseType = response.headers.get('content-type');

    if (response.status >= 400) {
      if (response.status === 401) {
        this.session.invalidate();

        return;
      }

      let errorResponse = await (responseType.startsWith('text') ? response.text() : response.json());
      let errors = errorResponse.errors;
      throw new ApiError(`Unable to request ${path}`, errors, response.status);
    }

    if (responseType.startsWith('text')) {
      return response.text();
    }

    if (responseType.includes('json')) {
      return response.json();
    }

    if (responseType.startsWith('application/')) {
      let attachmentHeader = response.headers.get('content-disposition');
      let attachmentName;

      try {
        let attachment = parse(attachmentHeader);
        attachmentName = filenamify(attachment?.filename ?? '', { maxLength: 300, replacement: '_' });
      } catch {
        attachmentName = '';
      }

      return {
        blob: await response.blob(),
        attachmentName,
      };
    }

    return response.body;
  }

  async delete(path, data = {}, options = {}) {
    options = { ...options, method: 'DELETE' };

    path = `${path}${generateQueryParams(data)}`;

    return this.fetch(path, options);
  }

  async get(path, options = {}) {
    options = { ...options, method: 'GET' };

    return this.fetch(path, options);
  }

  async post(path, options = {}) {
    options = { ...options, method: 'POST' };

    return this.fetch(path, options);
  }

  async put(path, data = {}, options = {}) {
    options = { ...options, method: 'PUT' };

    path = `${path}${generateQueryParams(data)}`;

    return this.fetch(path, options);
  }

  // JSON
  async postJSON(path, data, options = {}, queryParams = {}) {
    options = {
      ...options,
      headers: {
        'content-type': 'application/json;',
      },
      method: 'POST',
      body: JSON.stringify(data),
    };

    path = `${path}${generateQueryParams(queryParams)}`;

    return this.fetch(path, options);
  }

  async putJSON(path, data, options = {}, queryParams = {}) {
    options = {
      ...options,
      headers: {
        'content-type': 'application/json;',
      },
      method: 'PUT',
      body: JSON.stringify(data),
    };

    path = `${path}${generateQueryParams(queryParams)}`;

    return this.fetch(path, options);
  }

  // JSON + API
  async deleteVND(path, data = {}, options = {}, queryParams = {}) {
    options = {
      ...options,
      headers: {
        'content-type': 'application/vnd.api+json;charset=utf-8',
      },
      method: 'DELETE',
      body: JSON.stringify(data),
    };

    path = `${path}${generateQueryParams(queryParams)}`;

    return this.fetch(path, options);
  }

  async postVND(path, data, options = {}, queryParams = {}) {
    options = {
      ...options,
      headers: {
        'content-type': 'application/vnd.api+json;charset=utf-8',
      },
      method: 'POST',
      body: JSON.stringify(data),
    };

    path = `${path}${generateQueryParams(queryParams)}`;

    return this.fetch(path, options);
  }

  async deleteJSON(path, data, options = {}, queryParams = {}) {
    options = {
      ...options,
      headers: {
        'content-type': 'application/json;',
      },
      method: 'DELETE',
      body: JSON.stringify(data),
    };

    path = `${path}${generateQueryParams(queryParams)}`;

    return this.fetch(path, options);
  }
}
