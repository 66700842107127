import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.path as |segment index|}}\n  {{#if (gt index 0)}}\n    <FaIcon @icon=\"chevron-right\" @size=\"xs\" local-class=\"divider\" data-test-icon />\n  {{/if}}\n  <span data-test-segment>\n    {{segment}}\n  </span>\n{{/each}}", {"contents":"{{#each this.path as |segment index|}}\n  {{#if (gt index 0)}}\n    <FaIcon @icon=\"chevron-right\" @size=\"xs\" local-class=\"divider\" data-test-icon />\n  {{/if}}\n  <span data-test-segment>\n    {{segment}}\n  </span>\n{{/each}}","moduleName":"@mvb/tix-ui/components/layout/path/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/path/index.hbs"}});
import Component from '@glimmer/component';

export default class LayoutPathComponent extends Component {
  delimiter = this.args.delimiter ?? '\\';

  get path() {
    if (!this.args.path) {
      return [];
    }

    if (typeof this.args.path === 'string') {
      return this.args.path?.split(this.delimiter);
    }

    return this.args.path;
  }
}
