import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class ListItemModel extends Model {
  @attr('date-time') created;
  @attr('number') position;
  @attr('string') productIdentifier;
  @attr('string') sourceId;
  @attr('string') sourceName;
  @attr('string') sourceType;

  @belongsTo('list', { async: true, inverse: null }) list;
  @belongsTo('search-preview', { async: true, inverse: null }) searchPreview;
  @belongsTo('search-product', { async: false, inverse: null }) searchProduct;
}
