import { all } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { SECTION_STATUS, TIX_ITEM_SOURCE_TYPE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PresentationsProductListProductRoute extends Route {
  @service abilities;
  @service customMarketingContent;
  @service customMarketingContentLocked;
  @service dispoListProductEntries;
  @service errors;
  @service intl;
  @service manageList;
  @service metrics;
  @service presentation;
  @service router;
  @service store;
  @service theme;
  @service user;

  // do not use "refreshModel: true" here, see:
  // https://github.com/emberjs/ember.js/issues/16921
  // https://github.com/emberjs/ember.js/issues/16992
  queryParams = {
    asset: {
      refreshModel: false,
    },
    publishedStatus: {
      refreshModel: false,
    },
  };

  async model({ presentation_product_id: productId, publishedStatus }) {
    let { productList, mappedProductListProducts } = this.modelFor('presentations.product-list');

    try {
      let listItem = PresentationsProductListProductRoute.findProduct(mappedProductListProducts, productId);

      if (!listItem) {
        throw new Error(this.intl.t('error.productList.productIsNotPart'));
      }

      let previewId = listItem.sourceType === TIX_ITEM_SOURCE_TYPE.PREVIEW ? listItem.sourceId : null;
      let previewPromise = previewId
        ? this.store.findRecord('preview', previewId, {
            backgroundReload: false,
            include: 'sender,sections.contents', // needed so we can calculate the edit right and locked status for the custom-marketing-content (if present)
          })
        : null;

      let adapterOptions = {
        query: {
          filter: {
            'list.id': productList.id,
          },
        },
      };

      if (isPresent(previewId)) {
        adapterOptions.query.filter['preview.id'] = previewId;
      }

      if (publishedStatus === SECTION_STATUS.PUBLISHED) {
        adapterOptions.query.filter.section = {
          status: publishedStatus,
        };
      }

      let productPromise = this.store.findRecord('product', productId, {
        include: [
          'contributors',
          'customMarketingContent',
          'customMarketingContent.marketingRecommendations',
          'customMarketingContent.hugMarketingRecommendations',
          'customMarketingContent.listingRecommendations',
          'customMarketingContent.hugListingRecommendations',
          'supportingResources',
          'textContents',
          'sections',
          'sections.contents',
          'sections.contents.file',
          'sections.contents.prices',
          'sections.deliveryInfo',
          'sections.productInfo',
          'notes.creator',
        ].join(','),
        reload: true,
        adapterOptions,
      });

      let [preview, product] = await all([previewPromise, productPromise]);

      if (!product) {
        throw new Error(this.intl.t('error.productList.productNotFound'));
      }

      let dispoListProductEntries = this.abilities.can('dispoList.viewDispoListsInProductPages')
        ? await this.dispoListProductEntries.fetchByIsbns(product.gtin)
        : [];

      this.dispoListProductEntries.cleanup();
      dispoListProductEntries = this.dispoListProductEntries.mapSearchProduct({
        dlpes: dispoListProductEntries,
        searchProduct: product,
      }).dispoListProductEntries;

      let customMarketingContentService = this.customMarketingContent.getCMCServiceForPreviewType(preview?.type);
      this.customMarketingContentLocked.getCMCsLockedStatusForPreviewsTask.perform({ previews: [preview] });

      return {
        product,
        preview,
        dispoListProductEntries,
        mappedProductListProducts,
        publishedStatus,
        customMarketingContentService,
      };
    } catch {
      this.router.replaceWith('product-lists.product-list', productList.id);
      return {
        product: null,
        preview: null,
        dispoListProductEntries: [],
        mappedProductListProducts: [],
        publishedStatus: SECTION_STATUS.DRAFT,
        customMarketingContentService: null,
      };
    }
  }

  static findProduct(productListProducts, productId) {
    return productListProducts.find((listItem) => listItem.referencedProductId === productId);
  }

  afterModel(model) {
    let {
      product: { gtin, isThemenspecial, productInfo },
    } = model;

    this.metrics.trackSpecific({
      area: 'Merkliste',
      title: { 'BOOK.ISBN': gtin },
    });

    if (isThemenspecial) {
      this.theme.rootBackgroundColor = productInfo?.backgroundColor ?? null;
    }

    return super.afterModel(...arguments);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    let { customMarketingContentService, product } = model;

    customMarketingContentService.unload();
    this.presentation.currentProduct = product;
  }

  resetController() {
    this.theme.rootBackgroundColor = null;
  }
}
