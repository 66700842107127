export default {
  "bio": "_bio_1lq62b _two-line-truncated_1ju3hu",
  "contributor": "_contributor_1lq62b",
  "hint": "_hint_1lq62b",
  "info": "_info_1lq62b",
  "source": "_source_1lq62b",
  "preview-wrapper": "_preview-wrapper_1lq62b",
  "preview": "_preview_1lq62b",
  "upload": "_upload_1lq62b"
};
