export default {
  "actions": "_actions_cvmfli",
  "filters": "_filters_cvmfli",
  "form": "_form_cvmfli",
  "form-actions": "_form-actions_cvmfli",
  "table": "_table_cvmfli",
  "new-user-button": "_new-user-button_cvmfli",
  "filters-header": "_filters-header_cvmfli",
  "header": "_header_cvmfli",
  "user-types": "_user-types_cvmfli",
  "other-checkboxes": "_other-checkboxes_cvmfli",
  "buttons": "_buttons_cvmfli",
  "clear-and-toggle-buttons": "_clear-and-toggle-buttons_cvmfli"
};
