import { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import Model from './-base';
import sortBy from 'lodash-es/sortBy';

export default class CustomMarketingContentModel extends Model {
  ignoredFields = new Set(['type', 'parent', 'preview', 'party', 'referencedProductId', 'referencedProductIsbn']);

  @service user;

  @attr() display;
  @attr({
    defaultValue() {
      return [];
    },
  })
  keywords;
  @attr({
    defaultValue() {
      return [];
    },
  })
  marketingSubsidyStatuses;
  @attr('string') mediaIndex;
  @attr('string') note;
  @attr('string') orderQuantityUnit;
  @attr('string') packagingEan;
  @attr() packagingUnit;
  @attr() recommendedOrderAmount;
  @attr() recommendedOrderAmountMarketingExpectation;
  @attr() recommendedOrderAmountPotential;
  @attr('string') seasonCode;
  @attr('string') webshopLink;
  @attr({
    defaultValue() {
      return [];
    },
  })
  tags;
  @attr('string') trcCategoryOfGoods;
  @attr() type;

  @attr() referencedProductId;
  @attr() referencedProductIsbn;

  // hugendubel central account specific fields
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugAges;
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugComments;
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugDispoListNotes;
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugMarketingEvents;
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugRegions;
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugSeasons;

  @belongsTo('category-of-goods', { async: true, inverse: null }) category;
  @belongsTo('collection-of-goods', { async: true, inverse: null }) assortment;
  @belongsTo('party', { async: true, inverse: null }) party;
  @belongsTo('preview', { async: true, inverse: null }) preview;

  @hasMany('branch-recommended-order-amount', { async: false, inverse: 'customMarketingContent' })
  branchRecommendedOrderAmounts;
  @hasMany('comparative-product', { async: false, inverse: 'customMarketingContent' }) comparativeProducts;
  @hasMany('listing-recommendation-hugendubel', { async: false, inverse: 'customMarketingContent' })
  hugListingRecommendations;
  @hasMany('marketing-recommendation-hugendubel', { async: false, inverse: 'customMarketingContent' })
  hugMarketingRecommendations;
  @hasMany('listing-recommendation', { async: false, inverse: 'customMarketingContent' }) listingRecommendations;
  @hasMany('marketing-recommendation', { async: false, inverse: 'customMarketingContent' }) marketingRecommendations;
  @hasMany('marketing-recommendation-trc', { async: false, inverse: 'customMarketingContent' })
  trcMarketingRecommendations;
  @hasMany('file', { async: false, inverse: 'customMarketingContents' }) files;

  get hasOnlyComparativeProducts() {
    let changedFields = this.filledDataFieldNames();

    return changedFields.includes('comparativeProducts') && changedFields.length === 1;
  }

  get sortedComparativeProducts() {
    return sortBy(this.comparativeProducts ?? [], 'position');
  }

  get sortedListingRecommendations() {
    return sortBy(this.listingRecommendations ?? [], 'position');
  }

  get sortedListingRecommendationsHugendubel() {
    return sortBy(this.hugListingRecommendations ?? [], 'position');
  }

  get sortedMarketingLinks() {
    return sortBy(this.marketingLinks ?? [], 'position');
  }

  get sortedMarketingRecommendations() {
    return sortBy(this.marketingRecommendations ?? [], 'position');
  }

  get sortedMarketingRecommendationsHugendubel() {
    return sortBy(this.hugMarketingRecommendations ?? [], 'position');
  }

  get sortedMarketingRecommendationsTrc() {
    return sortBy(this.trcMarketingRecommendations ?? [], 'position');
  }

  get isNotEmpty() {
    return this.filledDataFieldNames().length > 0;
  }

  get belongsToCurrentUser() {
    return this.party?.get('id') === this.user.selectedParty?.get('party').get('id');
  }
}
