import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Tree::Tree\n  @delimiter={{@delimiter}}\n  @disabled={{@disabled}}\n  @hideSearch={{@hideSearch}}\n  @label={{@label}}\n  @noDataMessage={{@noDataMessage}}\n  @noFirstLevelSelection={{@noFirstLevelSelection}}\n  @onChange={{this.onChange}}\n  @options={{@options}}\n  @pathKey={{@pathKey}}\n  @value={{this.value}}\n  @valueKey={{@valueKey}}\n/>", {"contents":"<Ui::Input::Tree::Tree\n  @delimiter={{@delimiter}}\n  @disabled={{@disabled}}\n  @hideSearch={{@hideSearch}}\n  @label={{@label}}\n  @noDataMessage={{@noDataMessage}}\n  @noFirstLevelSelection={{@noFirstLevelSelection}}\n  @onChange={{this.onChange}}\n  @options={{@options}}\n  @pathKey={{@pathKey}}\n  @value={{this.value}}\n  @valueKey={{@valueKey}}\n/>","moduleName":"@mvb/tix-ui/components/ui/input/tree/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/tree/index.hbs"}});
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export default class InputTreeIndexComponent extends Component {
  get value() {
    if (isPresent(this.args.value)) {
      return this.args.value;
    }

    return this.args.changeset?.get?.(this.args.path);
  }

  @action
  onChange(value) {
    if (this.args.changeset) {
      this.args.changeset.set(this.args.path, value);
    }

    this.args.onChange?.(value);
  }
}
