import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { URL_SET_PASSWORD } from '@mvb/tix-ui/constants';
import Controller from '@ember/controller';

export default class SetPasswordController extends Controller {
  @service api;

  queryParams = ['hash'];

  @tracked hash = null;

  @action
  async changePassword(password) {
    await this.api.postJSON(URL_SET_PASSWORD, {
      password,
      hash: this.hash,
      termsAccepted: true,
    });
  }
}
