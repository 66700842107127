import Service from '@ember/service';

/**
 * This services stores objects.
 * Objects can be retrieved only once to not make this service another memory leak.
 */
export default class ObjectStoreService extends Service {
  objectMap = new Map();

  getObject(id) {
    let value = this.objectMap.get(id);
    this.objectMap.delete(id);

    return value;
  }

  setObject(id, value) {
    this.objectMap.set(id, value);
  }
}
