/**
 * Returns the name of the list along with default and standard infos
 *
 * @param {*} list object with listName/name, shared and defaultList/defaultByUser information
 * @param {*} intl intl-service to lookup translations
 */
export function determineListName(intl, list) {
  let listName = (list.listName || list.name) ?? '';
  let isDefaultList = list.defaultList || list.defaultByUser;

  if (isDefaultList && list.shared) {
    return intl.t('modelList.text.defaultShared', { name: listName });
  }

  if (isDefaultList) {
    return intl.t('modelList.text.default', { name: listName });
  }

  if (list.shared) {
    return intl.t('modelList.text.shared', { name: listName });
  }

  return listName;
}
