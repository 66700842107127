import { ERPS, ORDER_CHANNEL_TYPES } from '@mvb/tix-ui/constants';
import { validatePresence } from 'ember-changeset-validations/validators/index';
import validateEmailMultiple from '@mvb/tix-ui/validators/email-multiple';
import validateOrderListFinalizeOrderChannelId from '@mvb/tix-ui/validators/order-list-finalize-order-channel-id';
import validateOrderListFinalizeOrderChannelTypeLength from '@mvb/tix-ui/validators/order-list-finalize-order-channel-type-length';
import validateOrderListFinalizeOrderChannelTypeRequired from '@mvb/tix-ui/validators/order-list-finalize-order-channel-type-required';

export default {
  selectedOrderChannelId: [
    validateOrderListFinalizeOrderChannelId({
      dependentErpName: ERPS.JWWS,
      dependentOrderChannelType: ORDER_CHANNEL_TYPES.ERP,
      description: 'intl:validation.dispolist.description.orderChannelId',
    }),
  ],
  selectedOrderChannelType: [
    validatePresence({ presence: true, description: 'intl:validation.dispolist.description.orderChannel' }),
  ],
  recipients: [
    validateOrderListFinalizeOrderChannelTypeRequired({
      description: 'intl:validation.dispolist.description.recipients',
      dependentOrderChannelTypes: [ORDER_CHANNEL_TYPES.AGENT_DISTRIBUTION_PUBLISHER],
    }),
    validateEmailMultiple(),
  ],
  customerNumber: [
    validateOrderListFinalizeOrderChannelTypeLength({
      description: 'intl:validation.dispolist.description.customerNumber',
      max: 10,
      dependentOrderChannelTypes: [
        ORDER_CHANNEL_TYPES.AGENT_DISTRIBUTION_PUBLISHER,
        ORDER_CHANNEL_TYPES.PRESTO,
        ORDER_CHANNEL_TYPES.AVA,
        ORDER_CHANNEL_TYPES.BUCHZENTRUM,
      ],
    }),
  ],
  orderCode: [
    validateOrderListFinalizeOrderChannelTypeLength({
      description: 'intl:validation.dispolist.description.orderCodeLong',
      max: 35,
      dependentOrderChannelTypes: [
        ORDER_CHANNEL_TYPES.AGENT_DISTRIBUTION_PUBLISHER,
        ORDER_CHANNEL_TYPES.PRESTO,
        ORDER_CHANNEL_TYPES.AVA,
        ORDER_CHANNEL_TYPES.BUCHZENTRUM,
      ],
    }),
  ],
  note: [
    validateOrderListFinalizeOrderChannelTypeLength({
      description: 'intl:validation.dispolist.description.orderNote',
      max: 1024,
      dependentOrderChannelTypes: [
        ORDER_CHANNEL_TYPES.AGENT_DISTRIBUTION_PUBLISHER,
        ORDER_CHANNEL_TYPES.PRESTO,
        ORDER_CHANNEL_TYPES.AVA,
        ORDER_CHANNEL_TYPES.BUCHZENTRUM,
      ],
    }),
  ],
};
