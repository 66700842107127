import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::File\n  @file={{@item.file}}\n  @caption={{this.caption}}\n  @index={{@index}}\n  @onDelete={{this.onFileRemove}}\n  {{require-authentication}}\n  ...attributes\n/>", {"contents":"<Ui::File\n  @file={{@item.file}}\n  @caption={{this.caption}}\n  @index={{@index}}\n  @onDelete={{this.onFileRemove}}\n  {{require-authentication}}\n  ...attributes\n/>","moduleName":"@mvb/tix-ui/components/sections/view/additional/item.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/view/additional/item.hbs"}});
import { getAssetTypeTranslationPath } from '@mvb/tix-ui/utils/internal-codelist-services';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SectionsViewAdditionalItemComponent extends Component {
  @service intl;

  get caption() {
    let headline = this.args.item?.headline;
    let caption = this.args.item?.caption;
    let type = this.args.item?.resourceContentType;
    let mode = this.args.item?.resourceMode;

    return headline || caption || this.intl.t(getAssetTypeTranslationPath(type, mode));
  }
}
