import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Modal::Dialog\n  @cancelLabel={{t \"dispoListsModalSelectCollectionOfGoods.action.cancel\"}}\n  @continueLabel={{t \"dispoListsModalSelectCollectionOfGoods.action.confirm\"}}\n  @onClose={{fn @close false}}\n  @onContinue={{fn @close this.selectedAssortment}}\n  @title={{t \"dispoListsModalSelectCollectionOfGoods.text.title\"}}\n  data-test-dialog=\"selectCollectionOfGoods\"\n  local-class=\"modal\"\n>\n  {{#if this.isLoading}}\n    <Layout::Loading />\n  {{else}}\n    <Ui::Input::Tree\n      @label={{t \"dispoListsModalSelectCollectionOfGoods.label.assortment\"}}\n      @name=\"assortment\"\n      @noDataMessage={{t \"dispoListsModalSelectCollectionOfGoods.text.noAssortmentsFound\"}}\n      @onChange={{this.onSelectAssortment}}\n      @options={{this.assortmentGroups}}\n      @value={{this.selectedAssortment}}\n    />\n  {{/if}}\n</Ui::Modal::Dialog>", {"contents":"<Ui::Modal::Dialog\n  @cancelLabel={{t \"dispoListsModalSelectCollectionOfGoods.action.cancel\"}}\n  @continueLabel={{t \"dispoListsModalSelectCollectionOfGoods.action.confirm\"}}\n  @onClose={{fn @close false}}\n  @onContinue={{fn @close this.selectedAssortment}}\n  @title={{t \"dispoListsModalSelectCollectionOfGoods.text.title\"}}\n  data-test-dialog=\"selectCollectionOfGoods\"\n  local-class=\"modal\"\n>\n  {{#if this.isLoading}}\n    <Layout::Loading />\n  {{else}}\n    <Ui::Input::Tree\n      @label={{t \"dispoListsModalSelectCollectionOfGoods.label.assortment\"}}\n      @name=\"assortment\"\n      @noDataMessage={{t \"dispoListsModalSelectCollectionOfGoods.text.noAssortmentsFound\"}}\n      @onChange={{this.onSelectAssortment}}\n      @options={{this.assortmentGroups}}\n      @value={{this.selectedAssortment}}\n    />\n  {{/if}}\n</Ui::Modal::Dialog>","moduleName":"@mvb/tix-ui/components/dispo-lists/modal-select-collection-of-goods/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/dispo-lists/modal-select-collection-of-goods/index.hbs"}});
import { action } from '@ember/object';
import { HUGENDUBEL_MVB_ID } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export default class DispoListsModalSelectCollectionOfGoodsComponent extends Component {
  @service collectionOfGoods;

  @tracked assortmentGroups = [];
  @tracked selectedAssortment = null;

  get isLoading() {
    return this.setupAssortmentGroupsTask.isRunning;
  }

  constructor() {
    super(...arguments);

    this.setupAssortmentGroupsTask.perform();
  }

  @task
  @waitFor
  *setupAssortmentGroupsTask() {
    // load the assortment groups
    yield this.collectionOfGoods.load(HUGENDUBEL_MVB_ID);
    this.assortmentGroups = yield this.collectionOfGoods.getSortedAssortmentGroups();

    // load the selected assortment
    this.selectedAssortment = yield this.args.data?.value?.get('value');
  }

  @action
  onSelectAssortment(assortment) {
    this.selectedAssortment = assortment;
  }
}
