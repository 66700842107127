import { ROUTES_WITH_PACKED_QUERY_PARAMS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Helper from '@ember/component/helper';
import queryParamsPack from '@mvb/tix-ui/utils/query-params-pack';

// This helper creates a query argument for usage of the LinkTo component.
// It creates a packed query parameter for the routes which are using packed query parameters, otherwise
// it just passes through the passed query argument. For these routes it will add the passed query
// argument to the default and user specific query parameter values of the passed route name.
// The route argument is optional, if not set, the current route name is assumed.
//
// Example:
// <LinkTo
//   @query={{query-params-to-defaults query=(hash page=1) route="products.index"}}
//   @route="products.index"
// >
// This will create a link to "products.index" with query parameters for page set to 1
// and will set all other query parameter to the default and user specific values.

export default class QueryParamsToDefaultsHelper extends Helper {
  @service queryParams;
  @service router;

  compute(_, { query = {}, route = null }) {
    if (!route) {
      route = this.router.currentRouteName;
    }

    if (route && ROUTES_WITH_PACKED_QUERY_PARAMS.includes(route)) {
      return queryParamsPack({ ...this.queryParams.getQueryParamsOfRouteUserSpecific({ route }), ...query });
    }

    return query;
  }
}
