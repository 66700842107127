import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<td data-test-member-username>\n  {{@member.username}}\n</td>\n<td data-test-member-firstname>\n  {{@member.firstname}}\n</td>\n<td data-test-member-lastname>\n  {{@member.lastname}}\n</td>\n{{#if (can \"party.manageGroups\")}}\n  <td local-class=\"delete\" data-test-member-delete>\n    <Ui::Button\n      data-test-button-delete\n      @icon=\"trash-can\"\n      @label={{t \"partyGroupsMembers.action.delete\"}}\n      @onClick={{fn this.deleteMember @member}}\n      @style=\"link\"\n    />\n  </td>\n{{/if}}", {"contents":"<td data-test-member-username>\n  {{@member.username}}\n</td>\n<td data-test-member-firstname>\n  {{@member.firstname}}\n</td>\n<td data-test-member-lastname>\n  {{@member.lastname}}\n</td>\n{{#if (can \"party.manageGroups\")}}\n  <td local-class=\"delete\" data-test-member-delete>\n    <Ui::Button\n      data-test-button-delete\n      @icon=\"trash-can\"\n      @label={{t \"partyGroupsMembers.action.delete\"}}\n      @onClick={{fn this.deleteMember @member}}\n      @style=\"link\"\n    />\n  </td>\n{{/if}}","moduleName":"@mvb/tix-ui/components/party/groups/members/row.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/party/groups/members/row.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PartyGroupsMembersRowComponent extends Component {
  @service intl;
  @service modals;

  @action
  async deleteMember(member) {
    let confirmed = await this.modals.confirm({
      confirm: this.intl.t('partyGroupsMembers.modal.deleteConfirm'),
      message: this.intl.t('partyGroupsMembers.modal.deleteMessage', { name: member.username }),
      title: this.intl.t('partyGroupsMembers.modal.deleteTitle'),
    });

    if (!confirmed) {
      return;
    }

    this.args.onDelete?.(member);
  }
}
