import { isEmpty } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';

export default class StringTransform extends Transform {
  deserialize(serialized) {
    return serialized;
  }

  serialize(deserialized) {
    return isEmpty(deserialized) ? null : deserialized;
  }
}
