import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class OrderDispoListsIndexController extends Controller {
  @service dispoLists;
  @service intl;
  @service notifications;
  @service router;
  @service('search-dispo-lists') searchService;
  @service store;

  queryParams = ['qp'];
  @tracked qp = '';

  @task({ drop: true })
  @waitFor
  *changeDefaultListTask(dispoList, setAsDefault) {
    dispoList.defaultByUser = setAsDefault;

    yield dispoList.save();
    yield this.router.refreshCurrentRoute();

    let notificationMessage = setAsDefault
      ? this.intl.t('dispoLists.notification.setAsDefaultSuccess')
      : this.intl.t('dispoLists.notification.unsetAsDefaultSuccess');

    this.notifications.success(notificationMessage);
  }

  @action
  async createDispoList() {
    let newDispoList = this.store.createRecord('dispo-list');
    let addedList = await this.dispoLists.editDispoList(newDispoList);

    if (!addedList) {
      newDispoList.unloadRecord();
      return;
    }

    await this.router.refreshCurrentRoute();
  }
}
