export default {
  "order-channel-type-section": "_order-channel-type-section_1svaof",
  "order-channel-type-title": "_order-channel-type-title_1svaof",
  "order-channel-type-legal": "_order-channel-type-legal_1svaof",
  "bottom-button": "_bottom-button_1svaof",
  "bottom-button-bar": "_bottom-button-bar_1svaof",
  "error": "_error_1svaof _error_zhaoi9",
  "error-list": "_error-list_1svaof _error-list_zhaoi9",
  "btn-configure-erp": "_btn-configure-erp_1svaof"
};
