import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<form local-class=\"form\" data-test-hint-unauthenticated>\n  <div local-class=\"hint\">\n    <span data-test-hint>\n      {{t \"layoutHintUnauthenticated.text.hintUnauthenticated\"}}\n    </span>\n\n    <Ui::Button @style=\"primary\" @onClick={{this.onLogin}} data-test-button-login>\n      {{t \"layoutHintUnauthenticated.action.login\"}}\n    </Ui::Button>\n\n    <Ui::Button @type=\"submit\" @style=\"primary\" formaction={{constant \"URL_TIX_REGISTER\"}} data-test-button-register>\n      {{t \"layoutHintUnauthenticated.action.register\"}}\n    </Ui::Button>\n  </div>\n</form>", {"contents":"<form local-class=\"form\" data-test-hint-unauthenticated>\n  <div local-class=\"hint\">\n    <span data-test-hint>\n      {{t \"layoutHintUnauthenticated.text.hintUnauthenticated\"}}\n    </span>\n\n    <Ui::Button @style=\"primary\" @onClick={{this.onLogin}} data-test-button-login>\n      {{t \"layoutHintUnauthenticated.action.login\"}}\n    </Ui::Button>\n\n    <Ui::Button @type=\"submit\" @style=\"primary\" formaction={{constant \"URL_TIX_REGISTER\"}} data-test-button-register>\n      {{t \"layoutHintUnauthenticated.action.register\"}}\n    </Ui::Button>\n  </div>\n</form>","moduleName":"@mvb/tix-ui/components/layout/hint-unauthenticated.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/hint-unauthenticated.hbs"}});
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class LayoutHintUnauthenticatedComponent extends Component {
  @service router;

  @action
  onLogin() {
    let owner = getOwner(this);
    let loginController = owner.lookup('controller:login');
    loginController.nextTransitionTo = this.router.currentRoute;
    this.router.transitionTo('login');
  }
}
