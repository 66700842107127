import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyCollectionOfGoodsRoute extends Route {
  @service abilities;
  @service collectionOfGoods;

  async model() {
    if (this.abilities.can('party.viewCollectionOfGoods')) {
      return this.collectionOfGoods.load();
    }
  }
}
