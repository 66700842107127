import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import ENV from '@mvb/tix-ui/config/environment';
import Service, { service } from '@ember/service';

export class ChangesetValidationError extends Error {}

export default class PartyService extends Service {
  @service api;
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service store;
  @service user;

  @tracked partyInfoChangeset = null;

  /**
   * The model of the party info we want to update
   * @type {PartyInfo}
   */
  partyInfoModel = null;
  /**
   * The current progress state of the saving process
   * @type {ProgressState}
   */
  progressState = null;
  /**
   * The model of the party we want to update
   * @type {Party}
   */
  partyModel = null;
  /**
   * The transaction we need to store in case the user wants to leave the page
   */
  storedTransition = null;

  get cannotSave() {
    return this.partyInfoChangeset?.isInvalid || this.partyInfoChangeset?.isPristine;
  }

  constructor() {
    super(...arguments);
    this.router.on('routeWillChange', this, this._routeWillChange);
  }

  willDestroy() {
    this.router.off('routeWillChange', this, this._routeWillChange);
    super.willDestroy(...arguments);
  }

  @task
  @waitFor
  *initPartyInfoTask() {
    let partyInfo = this.partyInfoModel;
    if (!partyInfo) {
      partyInfo = yield this.store.createRecord('party-info', {
        party: this.partyModel,
      });
    }

    this.partyInfoChangeset = createChangeset(partyInfo, {});
  }

  @task({ drop: true })
  @waitFor
  *onSaveTask() {
    try {
      this.partyInfoChangeset.validate();
      if (this.partyInfoChangeset.isInvalid) {
        return;
      }

      this.progressState = this.progress.add({
        message: this.intl.t('companiesCompanyEdit.text.saveProgress'),
      });

      yield this.partyInfoChangeset.save();

      yield this.notifications.success(this.intl.t('companiesCompanyEdit.notification.saveSuccess'), {
        clearDuration: ENV.environment === 'test' ? 1 : 10000,
      });
    } catch (error) {
      this.errors.handle(error);
    } finally {
      this.progressState?.remove();
    }
  }

  @task({ drop: true })
  @waitFor
  *toggleFavoriteTask(mvbId, isFavorite) {
    try {
      if (mvbId) {
        yield this.api.postJSON('/user/favorite-company', {
          favorite: !isFavorite,
          mvbid: mvbId,
        });
        yield this.user.reloadUserSettings();
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  cancel() {
    this.transitionToIndexAndReload();
  }

  reloadCurrentRoute() {
    let currentRouteName = this.router.get('currentRouteName');
    let currentRoute = getOwner(this).lookup(`route:${currentRouteName}`);
    currentRoute?.refresh();
  }

  /**
   * Reloading the parent route is necessary to reload the party-info data again, if the user saved the party-info for the first time
   * Reloading the parent route reloads all child routes as well
   */
  reloadCurrentAndParentRoute() {
    let currentRouteName = this.router.get('currentRouteName');
    let parentRouteName = currentRouteName.split('.').slice(0, -1).join('.');
    let parentRoute = getOwner(this).lookup(`route:${parentRouteName}`);
    parentRoute?.refresh();
  }

  async save() {
    await this.onSaveTask.perform();

    // reload the current route
    this.reloadCurrentRoute();
  }

  async saveAndClose() {
    await this.onSaveTask.perform();

    this.transitionToIndexAndReload();
  }

  transitionToIndexAndReload() {
    // transition to detail page of company
    this.router.transitionTo('companies.company.index', this.partyModel.id);

    // reload the current and parent route just in case we saved the partyInfo for the first time
    this.reloadCurrentAndParentRoute();
  }

  async confirmCancelAndUndo() {
    let confirmed = await this.modals.confirm({
      title: this.intl.t(`companiesCompanyEdit.text.confirmCancelTitle`),
      message: this.intl.t(`companiesCompanyEdit.text.confirmCancelMessage`),
      confirm: this.intl.t(`companiesCompanyEdit.action.confirmCancelConfirm`),
      cancel: this.intl.t(`companiesCompanyEdit.action.confirmCancelCancel`),
    });

    if (!confirmed) {
      return false;
    }

    this.undoChanges();

    return true;
  }

  undoChanges() {
    // Skip undo if store is already going to be destroyed
    if (this.store.isDestroying || this.store.isDestroyed) {
      return;
    }

    this.partyInfoChangeset?.data?.rollbackAttributes();
    this.partyInfoChangeset?.rollback();

    this.partyInfoChangeset = null;
  }

  async _routeWillChange(transition) {
    let hasPreviousTransition = this.storedTransition !== null;
    let isTransitioningToSelf = transition.from?.name === transition.to?.name;
    // somehow we need to check for this because this routine also runs on detail page
    let transitionFromEditPage = transition.from?.name === 'companies.company.edit';

    if (
      this.partyInfoChangeset?.isPristine ||
      hasPreviousTransition ||
      isTransitioningToSelf ||
      !transitionFromEditPage
    ) {
      return;
    }

    this.storedTransition = transition;
    transition.abort();

    let undone = await this.confirmCancelAndUndo();
    if (undone) {
      transition.retry();
    }

    this.storedTransition = null;
  }

  // ==== Route Delegates (enforced via tix-ui/delegates ESLint rule) ====
  /**
   * Must be called from the Route class that injects the service
   */
  activate() {
    window.addEventListener('beforeunload', this.beforeunload);
  }

  /**
   * Must be called from the Route class that injects the service
   */
  deactivate() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  /**
   * Must be called from the Route class that injects the service
   */
  resetController() {
    this.partyModel = null;
    this.partyInfoModel = null;
    this.partyInfoChangeset = null;
  }

  setupController(model) {
    this.partyModel = model.party;
    this.partyInfoModel = model.partyInfo;
  }

  @action
  beforeunload(event) {
    let selectedPartySyncRequired = window.localStorage.getItem('sync-selected-party') !== this.user.selectedParty;

    // Selected Party needs to be synced, return early to prevent the forced refresh that needs to happen to do so.
    if (selectedPartySyncRequired) {
      return;
    }

    if (!this.hasChanges) {
      return;
    }

    event.preventDefault();
  }
}
