import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateOrderListFinalizeOrderChannelTypeLength(options = {}) {
  return (key, newValue, _oldValue, changes, content) => {
    let selectedOrderChannelType = changes?.selectedOrderChannelType ?? content?.selectedOrderChannelType;

    if (options?.dependentOrderChannelTypes?.includes(selectedOrderChannelType) && newValue?.length > options.max) {
      return buildMessage(key, { type: 'tooLong', value: newValue, context: options });
    }
    return true;
  };
}
