export default {
  "component": "_component_1j7uhj",
  "summary": "_summary_1j7uhj",
  "label": "_label_1j7uhj",
  "container": "_container_1j7uhj",
  "input": "_input_1j7uhj",
  "validations": "_validations_1j7uhj",
  "valid-identifier": "_valid-identifier_1j7uhj",
  "invalid-identifier": "_invalid-identifier_1j7uhj",
  "max-entries-exceeded": "_max-entries-exceeded_1j7uhj _error_zhaoi9",
  "max-entries-reached": "_max-entries-reached_1j7uhj",
  "button": "_button_1j7uhj",
  "truncated": "_truncated_1j7uhj _one-line-truncated_1ju3hu"
};
