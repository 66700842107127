import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Search::Filter\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterPreviewEditStatus.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    {{#each this.previewEditStatusOptions key=\"value\" as |option|}}\n      <Ui::Input::Radio\n        @label={{option.text}}\n        @name={{@filter}}\n        @onChange={{fn this.onFilterChange option.value}}\n        @radioValue={{option.value}}\n        @value={{this.value}}\n      />\n    {{/each}}\n  </:content>\n</Search::Filter>", {"contents":"<Search::Filter\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{t \"searchFilterPreviewEditStatus.text.headline\"}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    {{#each this.previewEditStatusOptions key=\"value\" as |option|}}\n      <Ui::Input::Radio\n        @label={{option.text}}\n        @name={{@filter}}\n        @onChange={{fn this.onFilterChange option.value}}\n        @radioValue={{option.value}}\n        @value={{this.value}}\n      />\n    {{/each}}\n  </:content>\n</Search::Filter>","moduleName":"@mvb/tix-ui/components/search/filter/preview-edit-status/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/preview-edit-status/index.hbs"}});
import { PREVIEW_EDIT_STATE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import createOptions from '@mvb/tix-ui/utils/create-options';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterPreviewEditStatusComponent extends SearchFilterBaseComponent {
  @service intl;

  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();
  }

  get previewEditStatusOptions() {
    return createOptions(
      this.intl,
      [PREVIEW_EDIT_STATE.NEW, PREVIEW_EDIT_STATE.EDIT, PREVIEW_EDIT_STATE.DONE],
      'searchFilterPreviewEditStatus.options'
    );
  }
}
