import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.previewListExportActions}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{t \"uiFunctionBar.tooltip.exportToFile\"}}\n  />\n{{/if}}", {"contents":"{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.previewListExportActions}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{t \"uiFunctionBar.tooltip.exportToFile\"}}\n  />\n{{/if}}","moduleName":"@mvb/tix-ui/components/ui/function-bar/export/preview-list.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/function-bar/export/preview-list.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import {
  TIX_ITEM_SOURCE_TYPE,
  URL_PRODUCT_EXPORT_REFERENCE_PRODUCTS,
  URL_PRODUCT_EXPORT_THALIA,
} from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export default class UiFunctionBarExportPreviewListComponent extends Component {
  @service abilities;
  @service api;
  @service errors;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service('search-previews-in-list') searchService;
  @service store;

  get previewListExportActions() {
    let actions = {};

    if (this.abilities.can('previewList.handleReferenceProducts')) {
      actions.comparativeTitle = {
        label: this.intl.t('uiFunctionBarExport.action.comparativeTitle'),
        action: this.onExportComparativeTitles,
      };
      actions.thaliaProducts = {
        label: this.intl.t('uiFunctionBarExport.action.thalia'),
        action: this.onExportThaliaProducts,
      };
    }

    return actions;
  }

  get hasActions() {
    return Object.keys(this.previewListExportActions).length > 0;
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  @action
  onExportComparativeTitles() {
    this.exportComparativeTitlesTask.perform();
  }

  @action
  onExportThaliaProducts() {
    this.exportThaliaProductsTask.perform();
  }

  @task
  @waitFor
  *exportComparativeTitlesTask() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('previewListsPreviewList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }
    this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportViaMail'));

    try {
      let filters = this.searchService.searchQuery.filter || {};

      let body = {
        filters,
        identifiers: Array.from(this.manageList.selectedItems),
        invertedSelection: this.manageList.inverse,
        sourceId: this.args.previewList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.PREVIEW_LIST,
      };

      yield this.api.postJSON(URL_PRODUCT_EXPORT_REFERENCE_PRODUCTS, body);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *exportThaliaProductsTask() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('previewListsPreviewList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }
    this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportViaMail'));

    try {
      let filters = this.searchService.searchQuery.filter || {};

      let body = {
        filters,
        identifiers: Array.from(this.manageList.selectedItems),
        invertedSelection: this.manageList.inverse,
        sort: this.searchService.searchQuery.sort,
        sourceId: this.args.previewList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.PREVIEW_LIST,
      };

      yield this.api.postJSON(URL_PRODUCT_EXPORT_THALIA, body);
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
