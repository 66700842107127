import { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import Model from './-base';

export default class MarketingRecommendationTrcModel extends Model {
  @attr('string') code;
  @attr('number') position;

  @belongsTo('custom-marketing-content', { async: false, inverse: 'trcMarketingRecommendations' })
  customMarketingContent;

  @tracked markedForDeletion = false;
}
