export default {
  "advertising-card": "_advertising-card_14gqhc",
  "image-wrapper": "_image-wrapper_14gqhc",
  "copyright": "_copyright_14gqhc",
  "card-content": "_card-content_14gqhc",
  "card-headline": "_card-headline_14gqhc",
  "card-price": "_card-price_14gqhc",
  "card-body": "_card-body_14gqhc",
  "card-action": "_card-action_14gqhc",
  "email-link": "_email-link_14gqhc",
  "amount": "_amount_14gqhc"
};
