import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class PreviewInfoModel extends Model {
  @attr('date') approvalDate;
  @attr('string') audienceId;
  @attr('boolean', { defaultValue: true }) bloggerAudience;
  @attr('boolean', { defaultValue: true }) bookGroupAudience;
  @attr('boolean', { defaultValue: true }) booksellerAudience;
  @attr('boolean', { defaultValue: true }) cooperationAudience;
  @attr('boolean', { defaultValue: true }) journalistAudience;
  @attr('boolean', { defaultValue: true }) publisherAudience;
  @attr('boolean', { defaultValue: true }) readerAudience;
  @attr('string') description;
  @attr('string') descriptionListView;
  @attr('string') name;
  @attr('string') season;
  @attr('string') year;

  @belongsTo('section', { async: false, inverse: 'previewInfo' }) section;
}
