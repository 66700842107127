import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import window from 'ember-window-mock';

export default function validateAssetFileImageDimensions({ width, height, maxWidth, maxHeight, minWidth, minHeight }) {
  return async (key, newValue /*, oldValue, changes, content */) => {
    let isString = typeof newValue === 'string';
    let isImageFile = newValue instanceof window.File && newValue.type?.startsWith('image');

    if (!newValue || !(isString || isImageFile)) {
      return true;
    }

    // this will also correctly validate pdf strings in safari
    let { actualWidth, actualHeight } = await new Promise((resolve) => {
      let img = new Image();

      img.addEventListener('load', ({ target: { naturalWidth, naturalHeight } }) => {
        URL.revokeObjectURL(img.src);

        resolve({
          actualWidth: naturalWidth,
          actualHeight: naturalHeight,
        });
      });

      img.addEventListener('error', () => {
        resolve({});
      });

      if (newValue instanceof window.File) {
        img.src = URL.createObjectURL(newValue);
      } else if (typeof newValue === 'string') {
        img.src = newValue;
      }
    });

    if (!actualWidth || !actualHeight) {
      return true;
    }

    if (maxWidth || maxHeight) {
      return verifyMaxDimensions(key, newValue, actualWidth, actualHeight, maxWidth, maxHeight);
    } else if (minWidth || minHeight) {
      return verifyMinDimensions(key, newValue, actualWidth, actualHeight, minWidth, minHeight);
    } else if (width || height) {
      return verifyExactDimensions(key, newValue, actualWidth, actualHeight, width, height);
    } else {
      return true;
    }
  };
}

function verifyExactDimensions(key, newValue, actualWidth, actualHeight, width, height) {
  let result = true;
  let messageType;

  if (actualWidth && width) {
    result = actualWidth === width;
    messageType = 'hasValidImageWidthExact';
  }

  if (result && actualHeight && height) {
    result = actualHeight === height;
    messageType = 'hasValidImageHeightExact';
  }

  if (result) {
    return result;
  }

  if (width && height) {
    messageType = 'hasValidImageDimensionsExact';
  }

  return buildMessage(key, { type: messageType, value: newValue, context: { width, height } });
}

function verifyMaxDimensions(key, newValue, actualWidth, actualHeight, maxWidth, maxHeight) {
  let result = true;
  let messageType;

  if (actualWidth && maxWidth) {
    result = actualWidth <= maxWidth;
    messageType = 'hasValidImageWidth';
  }

  if (result && actualHeight && maxHeight) {
    result = actualHeight <= maxHeight;
    messageType = 'hasValidImageHeight';
  }

  if (result) {
    return result;
  }

  if (maxWidth && maxHeight) {
    messageType = 'hasValidImageDimensions';
  }

  return buildMessage(key, { type: messageType, value: newValue, context: { maxWidth, maxHeight } });
}

function verifyMinDimensions(key, newValue, actualWidth, actualHeight, minWidth, minHeight) {
  let result = true;
  let messageType;

  if (actualWidth && minWidth) {
    result = actualWidth >= minWidth;
    messageType = 'hasValidImageWidthMin';
  }

  if (result && actualHeight && minHeight) {
    result = actualHeight >= minHeight;
    messageType = 'hasValidImageHeightMin';
  }

  if (result) {
    return result;
  }

  if (minWidth && minHeight) {
    messageType = 'hasValidImageDimensionsMin';
  }

  return buildMessage(key, { type: messageType, value: newValue, context: { minWidth, minHeight } });
}
