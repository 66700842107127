import { attr, belongsTo } from '@ember-data/model';
import { htmlSafe } from '@ember/template';
import { TEXT_FORMATS } from '@mvb/tix-ui/constants';
import Model from './-base';
import parsePublicationDate from '@mvb/tix-ui/utils/parse-publication-date';

export default class TextContentModel extends Model {
  @attr('string') language;
  @attr('string') publicationDate;
  @attr('number') sequence;
  @attr('string') sourceTitle;
  @attr('string') text;
  @attr('string') textAuthor1;
  @attr('string') textAuthor2;
  @attr('string') textAuthor3;
  @attr('string') textFormat;
  @attr('string') textSourceCorporate;
  @attr('string') textType;
  @attr() textContentAudience;

  @belongsTo('product', { async: false, inverse: 'textContents' }) product;

  get isHTML() {
    return this.textFormat === TEXT_FORMATS.XHTML;
  }

  get formattedPublicationDate() {
    let publicationDateLength = this.publicationDate ? this.publicationDate.length : 0;

    if (publicationDateLength === 4) {
      return parsePublicationDate(this.publicationDate).toFormat('yyyy');
    }
    if (publicationDateLength === 6) {
      return parsePublicationDate(this.publicationDate).toFormat('LLLL yyyy');
    }
    if (publicationDateLength === 7) {
      return parsePublicationDate(this.publicationDate).toFormat('LLLL yyyy');
    }
    if (publicationDateLength === 8) {
      return parsePublicationDate(this.publicationDate).toFormat('dd.LL.yyyy');
    }
    return this.publicationDate;
  }

  get formattedText() {
    let text = this.text || '';

    if (!this.isHTML) {
      text = text?.replaceAll(/(\r\n|\n\r|\r|\n)/g, '<br>') || '';
    }

    return htmlSafe(text);
  }
}
