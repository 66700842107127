export default {
  "product": "_product_zw2rse",
  "with-total": "_with-total_zw2rse",
  "special": "_special_zw2rse",
  "with-price-block": "_with-price-block_zw2rse",
  "validation-container": "_validation-container_zw2rse",
  "warning": "_warning_zw2rse",
  "error": "_error_zw2rse",
  "draft": "_draft_zw2rse",
  "unpublished-content": "_unpublished-content_zw2rse",
  "cover": "_cover_zw2rse",
  "actions": "_actions_zw2rse",
  "action-icon": "_action-icon_zw2rse",
  "bibliography": "_bibliography_zw2rse",
  "price": "_price_zw2rse",
  "price-content": "_price-content_zw2rse",
  "price-block-content": "_price-block-content_zw2rse",
  "product-in-dispo-list": "_product-in-dispo-list_zw2rse",
  "green-check": "_green-check_zw2rse",
  "total": "_total_zw2rse",
  "source": "_source_zw2rse",
  "notes": "_notes_zw2rse",
  "additional-information": "_additional-information_zw2rse",
  "dispo-list": "_dispo-list_zw2rse"
};
