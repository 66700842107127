export default {
  "actions": "_actions_1hjkm8",
  "group": "_group_1hjkm8",
  "group-expand": "_group-expand_1hjkm8",
  "action": "_action_1hjkm8",
  "button": "_button_1hjkm8",
  "button-move": "_button-move_1hjkm8",
  "checkbox": "_checkbox_1hjkm8",
  "content": "_content_1hjkm8",
  "draft": "_draft_1hjkm8",
  "item": "_item_1hjkm8",
  "item-spaced": "_item-spaced_1hjkm8",
  "header": "_header_1hjkm8",
  "header-left": "_header-left_1hjkm8",
  "header-right": "_header-right_1hjkm8"
};
