import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<h4 data-test-party-logo-label>\n  {{t \"sectionsEditPartyInfo.label.logo\"}}\n</h4>\n\n<div local-class=\"preview-wrapper\">\n  {{#if @changeset.logo}}\n    <img\n      data-test-party-logo\n      local-class=\"preview\"\n      src={{@changeset.logo.downloadUrl}}\n      alt={{t \"sectionsEditPartyInfo.text.logoAlt\" text=@party.name}}\n      {{prevent-contextmenu}}\n    />\n  {{/if}}\n</div>\n\n<Ui::Input::Asset::Image\n  data-test-party-logo-upload\n  @changeset={{@changeset}}\n  @dimensions={{this.imageDimensions}}\n  @name=\"logo-{{this.id}}\"\n  @path=\"logo\"\n  @payload={{this.filePayload}}\n  @onChange={{this.onChange}}\n  @onFileRemove={{this.onChange}}\n/>\n\n<p local-class=\"hint\" data-test-party-logo-hint>\n  {{t \"sectionsEditPartyInfo.text.logoHint\"}}\n</p>", {"contents":"<h4 data-test-party-logo-label>\n  {{t \"sectionsEditPartyInfo.label.logo\"}}\n</h4>\n\n<div local-class=\"preview-wrapper\">\n  {{#if @changeset.logo}}\n    <img\n      data-test-party-logo\n      local-class=\"preview\"\n      src={{@changeset.logo.downloadUrl}}\n      alt={{t \"sectionsEditPartyInfo.text.logoAlt\" text=@party.name}}\n      {{prevent-contextmenu}}\n    />\n  {{/if}}\n</div>\n\n<Ui::Input::Asset::Image\n  data-test-party-logo-upload\n  @changeset={{@changeset}}\n  @dimensions={{this.imageDimensions}}\n  @name=\"logo-{{this.id}}\"\n  @path=\"logo\"\n  @payload={{this.filePayload}}\n  @onChange={{this.onChange}}\n  @onFileRemove={{this.onChange}}\n/>\n\n<p local-class=\"hint\" data-test-party-logo-hint>\n  {{t \"sectionsEditPartyInfo.text.logoHint\"}}\n</p>","moduleName":"@mvb/tix-ui/components/sections/edit/party-info/logo.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/party-info/logo.hbs"}});
import { action } from '@ember/object';
import { ASSET_MODE_CODES, ASSET_TYPE_CODES, DIMENSIONS } from '@mvb/tix-ui/constants';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

export default class SectionsEditPartyInfoLogoComponent extends Component {
  filePayload = {
    resourceMode: ASSET_MODE_CODES.IMAGE,
    resourceContentType: ASSET_TYPE_CODES.MISC,
  };

  id = guidFor(this);

  get imageDimensions() {
    return { maxWidth: DIMENSIONS.LOGO_WIDTH };
  }

  @action
  onChange() {
    this.args.changeset.validate();
  }
}
