import { REGEX_PASSWORD } from '@mvb/tix-ui/constants';
import { validateBoolean } from '@mvb/tix-ui/validators';
import {
  validateConfirmation,
  validateFormat,
  validateLength,
  validatePresence,
} from 'ember-changeset-validations/validators/index';
import config from '@mvb/tix-ui/config/environment';

const userRegistrationValidations = {
  type: [validatePresence({ presence: true, description: 'intl:validation.user.description.type' })],
  gender: [validatePresence({ presence: true, description: 'intl:validation.user.description.gender' })],
  firstname: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.firstname' }),
    validateLength({ max: 50, description: 'intl:validation.user.description.firstname' }),
  ],
  lastname: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.lastname' }),
    validateLength({ max: 50, description: 'intl:validation.user.description.lastname' }),
  ],
  username: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.usernameShort' }),
    validateFormat({ type: 'email', description: 'intl:validation.user.description.usernameShort' }),
    validateLength({ min: 5, max: 255, description: 'intl:validation.user.description.usernameShort' }),
  ],
  password: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.password' }),
    validateFormat({
      regex: REGEX_PASSWORD,
      message: 'intl:validation.user.message.password',
    }),
  ],
  passwordConfirmed: [
    validatePresence({ presence: true, description: 'intl:validation.user.description.passwordConfirmed' }),
    validateConfirmation({
      on: 'password',
      message: 'intl:validation.user.message.passwordConfirmed',
    }),
  ],

  agb: [
    validateBoolean({
      expected: true,
      message: 'intl:validation.user.message.agb',
    }),
  ],
  privacy: [
    validateBoolean({
      expected: true,
      message: 'intl:validation.user.message.privacy',
    }),
  ],
};

const captchaValidation = [
  validatePresence({
    presence: true,
    message: 'intl:validation.user.message.captcha',
  }),
];

// disable captcha tests for ember tests (acceptance tests)
userRegistrationValidations.reCaptchaResponse =
  config.environment === 'test' || config.APP.TARGET === 'e2e' ? [] : captchaValidation;

export default userRegistrationValidations;
