import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class BackofficePartiesIndexRoute extends Route {
  @service store;

  queryParams = {
    filter: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
  };

  model({ page, sort, filter }) {
    let queryParams = {
      page: {
        number: page,
      },
    };

    if (sort) {
      queryParams.sort = sort;
    }

    if (filter) {
      queryParams.filter = {
        term: filter,
      };
    }

    return this.store.query('party', queryParams);
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
