import { helper } from '@ember/component/helper';

export function preventDefault([handler]) {
  return function (event) {
    event?.preventDefault?.();
    handler?.(event);
  };
}

export default helper(preventDefault);
