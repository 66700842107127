import Model, { attr, belongsTo } from '@ember-data/model';

export default class PartyInfoModel extends Model {
  @attr('string') description;
  @attr('string') headline;
  @attr('string') stageUrl;

  @belongsTo('file', { async: false, inverse: null }) logo;
  @belongsTo('file', { async: false, inverse: null }) stage;

  @belongsTo('party', { async: false, inverse: 'partyInfo' }) party;
}
