import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PresentationsPreviewCoverRoute extends Route {
  @service presentation;

  async model() {
    let preview = this.modelFor('presentations.preview').preview;
    let product = { id: 'cover', referencedProductId: 'cover' };

    return { preview, product };
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    this.presentation.allProducts = model.preview.sortedProductsForPresentation;
    this.presentation.currentProduct = model.product;
  }
}
