import get from 'lodash-es/get';

export function sortNatural(options, sortKey) {
  let compareOptions = { numeric: true, sensitivity: 'base' };

  if (sortKey) {
    return options.sort((a, b) => (get(a, sortKey) ?? '').localeCompare(get(b, sortKey) ?? '', compareOptions));
  }

  return options.sort((a, b) => (a ?? '').localeCompare(b ?? '', compareOptions));
}
