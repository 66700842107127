import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class GroupModel extends Model {
  @attr('date-time') created;
  @attr('string') name;
  @attr('number') memberCount;
  @attr('boolean') isMember;
  @attr() type;

  @belongsTo('party', { async: false, inverse: null }) party;

  @hasMany('user', { async: false, inverse: 'groups' }) members;
}
