import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class PartyCompaniesController extends Controller {
  @service('search-premium-assigned-parties-for-user') searchService;

  queryParams = ['qp'];
  @tracked qp = '';
}
