import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class BookstoreBranchModel extends Model {
  @attr() type;
  @attr('date-time') created;
  @attr('string') name;
  @attr('string') identificationType;
  @attr('string') identificationNumber;

  @belongsTo('party', { async: false, inverse: null }) party;
}
