import { getLuxonDate } from '@mvb/tix-ui/utils/luxon-date';
import { isEmpty } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validatePromotionalPackageDates(options = {}) {
  return (key, newValue, oldValue, changes, content) => {
    let newDate = getLuxonDate(newValue);
    let publicationDate = getLuxonDate(changes.publicationDate ?? content.publicationDate);

    if (
      !applicationUtil.hasInstance ||
      isEmpty(newValue) ||
      (typeof newValue === 'string' && newValue.includes('null')) ||
      key === 'publicationDate' ||
      isEmpty(publicationDate)
    ) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let description = options.description ?? key;

    if (!newDate.isValid) {
      return buildMessage(key, {
        type: 'invalid',
        value: newValue,
        context: { description },
      });
    }

    if (newDate < publicationDate) {
      return buildMessage(key, {
        type: 'after',
        value: newValue,
        context: { after: intlService.t('sectionsEditDate.label.publicationDate'), description },
      });
    }

    return true;
  };
}
