import { CODELISTS } from '@mvb/tix-ui/constants';
import { getProductFormTypeTranslationPath } from '@mvb/tix-ui/utils/internal-codelist-services';

export default function facetLabel({ code, intl, list, translationPath }) {
  let key;

  if (code && [CODELISTS.PRODUCT_FORM, CODELISTS.PRODUCT_FORM_DETAILS].includes(list)) {
    let [type, details] = code.split('_');
    key = getProductFormTypeTranslationPath(type, details);
  } else if (translationPath) {
    key = `${translationPath}${code}`;
  } else {
    key = `cl_${list}_${code}`;
  }

  let desc = intl.t(key);
  return desc.startsWith('Missing translation "cl_') ? code : desc;
}
