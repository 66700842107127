import { all, task } from 'ember-concurrency';
import { CURRENCIES_DACH } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Route from '@ember/routing/route';

const CURRENCY_OPTIONS = {
  [CURRENCIES_DACH.CHF_CH]: 'CHF',
  [CURRENCIES_DACH.EUR_AT]: 'EurA',
  [CURRENCIES_DACH.EUR_DE]: 'EurD',
};

export default class OrderOrderListRoute extends Route {
  @service abilities;
  @service branches;
  @service collectionOfGoods;
  @service store;
  @service user;
  @service userState;

  async model() {
    if (!this.abilities.can('order.order')) {
      return;
    }

    let [openOrderList] = await all([
      this.loadOrderListTask.perform(),
      this.branches.load(),
      this.collectionOfGoods.load(),
      this.userState.load(),
    ]);

    return {
      currency: CURRENCY_OPTIONS[this.user.selectedCurrencyCountry] ?? '',
      openOrderList,
    };
  }

  @task
  @waitFor
  *loadOrderListTask() {
    let openOrderLists = yield this.store.query('order-list', { filter: { open: true } });
    let openOrderList = openOrderLists?.[0] ?? (yield this.store.createRecord('order-list', { open: true }).save());

    return openOrderList;
  }
}
