import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import Model from './-base';

export default class ListModel extends Model {
  @service user;

  @attr('date-time') created;
  @attr('boolean') defaultBySomeoneElse;
  @attr('boolean') defaultByUser;
  @attr() itemCount;
  @attr('date-time') lastUpdated;
  @attr() listType;
  @attr('string') name;
  @attr('boolean') orderable;
  @attr('boolean') shared;

  @belongsTo('user', { async: true, inverse: null }) owner;
  @belongsTo('party', { async: true, inverse: null }) party;

  get userIsOwner() {
    return this.owner.get('id') === this.user.current?.id;
  }
}
