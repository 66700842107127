export default {
  "actions": "_actions_x4xj57",
  "button": "_button_x4xj57",
  "compose": "_compose_x4xj57",
  "delete": "_delete_x4xj57",
  "editor": "_editor_x4xj57",
  "errors": "_errors_x4xj57",
  "list": "_list_x4xj57",
  "loading": "_loading_x4xj57",
  "notes": "_notes_x4xj57",
  "types": "_types_x4xj57",
  "ul": "_ul_x4xj57 reset-list"
};
