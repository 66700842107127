import {
  ASSET_TYPE_CODES,
  HIGHLIGHT_AUDIENCES,
  PRODUCT_COMPOSITION_TYPES,
  PRODUCT_FORM_DETAILS,
  PRODUCT_FORM_TYPES,
} from '@mvb/tix-ui/constants';
import { isEmpty } from '@ember/utils';

/**
 * Returns the correct translation path for the given asset type code according to the mode.
 *
 * @param {string} type the code for the asset type - needed for the correct translation from the codelist
 * @param {string} mode the code for the asset mode - to determine if its audio, image, text or video
 */
export function getAssetTypeTranslationPath(type, mode) {
  if (!type) {
    return '';
  }

  if (ASSET_TYPE_CODES.EXCERPT === type && mode) {
    return `codelist.assetTypes.mode_${mode}`;
  }

  if (ASSET_TYPE_CODES.MISC === type && mode) {
    return `codelist.assetTypes.misc_${mode}`;
  }

  return `cl_158_${type}`;
}

/**
 * @typedef {Object} GroupedList
 * @property {String} key The objects are grouped by; mostly its the type code (e.g. A01) also used in a codelist
 * @property {Array} array Of the grouped objects
 */

/**
 * An array containing objects with at least the following structure: {code: 'A01', description: 'some description'}
 * @typedef {array} Codelist
 */

/**
 * Sorts a list according to a given codelist
 *
 * @param {GroupedList} listToSort - list to be sorted
 * @param {Codelist} codelist - list with sort order to sort by
 */
export function sortByCodelist(listToSort, codelist) {
  if (isEmpty(listToSort) || isEmpty(codelist)) {
    return listToSort;
  }

  // prepare codelist for sorting
  let list = codelist.map((item) => item.code);
  let sorted = Object.keys(listToSort).sort((a, b) => {
    return list.indexOf(a) > list.indexOf(b) ? 1 : -1;
  });

  let ordered = {};
  for (let key of sorted) {
    ordered[key] = listToSort[key];
  }

  return ordered;
}

/**
 * Returns the correct translation path for the product form according to the given type and detail codes
 *
 * @param {string} type the code for the product form type
 * @param {string} details a list of codes for the product form details
 */
export function getProductFormTypeTranslationPath(type, details, composition) {
  if (isEmpty(type)) {
    return '';
  }

  // check for softcover
  if (PRODUCT_FORM_TYPES.SOFTCOVER === type && !isEmpty(details)) {
    if (details.includes(PRODUCT_FORM_DETAILS.PAPERBACK)) {
      return `cl_175_${PRODUCT_FORM_DETAILS.PAPERBACK}`;
    }

    if (details.includes(PRODUCT_FORM_DETAILS.POCKETBOOK)) {
      return `cl_175_${PRODUCT_FORM_DETAILS.POCKETBOOK}`;
    }
  }

  // check for ebooks
  let detailsForEbooks = details?.split(',').map((detail) => detail.startsWith(PRODUCT_FORM_DETAILS.EBOOK)) ?? [];
  if (type.startsWith(PRODUCT_FORM_TYPES.DIGITAL_SHORT) && detailsForEbooks.length > 0) {
    return `cl_150_${PRODUCT_FORM_TYPES.DIGITAL_SHORT}`;
  }

  // check for ebooks in after search filter
  if (PRODUCT_FORM_TYPES.DIGITAL_SHORT === type && !isEmpty(details) && details.includes(PRODUCT_FORM_DETAILS.EBOOK)) {
    // return E_E1 as pseudocode E (E-Book), hardcoded in packages/tix-ui/translations/codelists/de-de.yaml
    return `cl_150_${PRODUCT_FORM_TYPES.DIGITAL_SHORT}`;
  }

  // check for promotional package
  if (PRODUCT_FORM_TYPES.PACKAGE === type && PRODUCT_COMPOSITION_TYPES.PROMOTIONAL_PACKAGE === composition) {
    return `cl_150_${PRODUCT_FORM_TYPES.PACKAGE}_A`;
  }

  // check for themenspecial
  if (PRODUCT_FORM_TYPES.PACKAGE === type && PRODUCT_COMPOSITION_TYPES.SPECIAL === composition) {
    return `cl_150_${PRODUCT_FORM_TYPES.PACKAGE}_T`;
  }

  return `cl_150_${type}`;
}

/**
 * Returns an array of details that should actually be shown on the product page (or elsewhere) according to the given type and detail codes
 *
 * @param {string} type the code for the product form type
 * @param {string} details a list of codes for the product form details
 */
export function getActualProductFormDetails(type, details) {
  details = details?.trim().split(',') ?? [];
  details = details.map((detail) => detail.trim());
  let filteredDetails = details;

  if (PRODUCT_FORM_TYPES.SOFTCOVER === type && !isEmpty(details)) {
    filteredDetails = details.filter(
      (item) => ![PRODUCT_FORM_DETAILS.PAPERBACK, PRODUCT_FORM_DETAILS.POCKETBOOK].includes(item)
    );
  }

  return filteredDetails;
}

/**
 * Returns the label for the contributor from the codelistItem. In case of any changes please also check
 * "getContributorRole" method in rest-api/src/main/java/de/mvbonline/tix/restapi/product/service/export/ContributorsRepresentationResolver.java
 *
 * @param {Object} codelistItem a list of codes for the contributors
 */
export function generateLabelForContributor(codelistItem) {
  if (isEmpty(codelistItem) || isEmpty(codelistItem.description)) {
    return '';
  }

  let priority = codelistItem.priority;
  let description = codelistItem.description;

  if (priority === 'k') {
    return description.charAt(0).toLowerCase() + description.slice(1);
  } else if (isEmpty(priority)) {
    return description;
  } else {
    return priority;
  }
}

/**
 * Returns the correct translation path for highlight depending on user type
 *
 * @param {string} highlights object highlights as string
 * @param {string} partyType type of party
 * @param {string} userType type of user
 */
export function determineHighlightLabelPathForPartyAndUser({ highlights, partyType, userType }) {
  if (!highlights) {
    return undefined;
  }

  let parsedHighlights;

  try {
    parsedHighlights = JSON.parse(highlights);
  } catch {
    // no need for error handling
  }

  if (!parsedHighlights) {
    return undefined;
  }

  let code = parsedHighlights[HIGHLIGHT_AUDIENCES.ALL];

  if (!code && (partyType || userType)) {
    let audience = HIGHLIGHT_AUDIENCES[partyType ?? userType] ?? HIGHLIGHT_AUDIENCES.ALL;
    code = parsedHighlights[audience];
  }

  return code ? `cl_506_${code}` : undefined;
}
