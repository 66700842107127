import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper\">\n  <div local-class=\"info\">\n    <Ui::Input::Text\n      local-class=\"headline\"\n      @changeset={{@changeset}}\n      @label={{t \"sectionsEditPartyInfo.label.headline\"}}\n      @name=\"headline\"\n      @onChange={{this.onChange}}\n      @path=\"headline\"\n    />\n    <Ui::Input::Editor\n      local-class=\"editor\"\n      @changeset={{@changeset}}\n      @disableColor={{true}}\n      @disableHeader={{true}}\n      @label={{t \"sectionsEditPartyInfo.label.description\"}}\n      @name=\"description\"\n      @onChange={{this.onChange}}\n      @path=\"description\"\n    />\n  </div>\n  <div>\n    <Sections::Edit::PartyInfo::Logo @changeset={{@changeset}} @party={{@party}} />\n    <Party::LocsData @locsData={{@locsData}} @party={{@party}} @showTooltip={{true}} />\n  </div>\n</div>", {"contents":"<div local-class=\"wrapper\">\n  <div local-class=\"info\">\n    <Ui::Input::Text\n      local-class=\"headline\"\n      @changeset={{@changeset}}\n      @label={{t \"sectionsEditPartyInfo.label.headline\"}}\n      @name=\"headline\"\n      @onChange={{this.onChange}}\n      @path=\"headline\"\n    />\n    <Ui::Input::Editor\n      local-class=\"editor\"\n      @changeset={{@changeset}}\n      @disableColor={{true}}\n      @disableHeader={{true}}\n      @label={{t \"sectionsEditPartyInfo.label.description\"}}\n      @name=\"description\"\n      @onChange={{this.onChange}}\n      @path=\"description\"\n    />\n  </div>\n  <div>\n    <Sections::Edit::PartyInfo::Logo @changeset={{@changeset}} @party={{@party}} />\n    <Party::LocsData @locsData={{@locsData}} @party={{@party}} @showTooltip={{true}} />\n  </div>\n</div>","moduleName":"@mvb/tix-ui/components/sections/edit/party-info/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/party-info/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class SectionsEditPartyInfoComponent extends Component {
  @action
  onChange() {
    this.args.changeset.validate();
  }
}
