import { action } from '@ember/object';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class IndexRoute extends Route {
  @service metrics;
  @service session;
  @service store;
  @service user;

  beforeModel(transition) {
    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }

  async model() {
    let searchPreviews = [];

    try {
      searchPreviews = await this.store.query('search-preview', {
        page: {
          number: 1,
          size: 8,
        },
      });
    } catch {
      //no need to throw an error here
    }

    return { searchPreviews };
  }

  @action
  didTransition() {
    if (this.session.isAuthenticated) {
      this.metrics.trackSpecific({
        area: 'Start',
        title: 'Start',
      });
    }
  }
}
