import { action } from '@ember/object';
import { service } from '@ember/service';
import { SWAP_BRANCH_MODAL_RESULTS, SWAP_BRANCH_MODAL_TYPES } from '@mvb/tix-ui/constants';
import { task } from 'ember-concurrency';
import { validateFormat, validateNumber, validatePresence } from 'ember-changeset-validations/validators/index';
import Controller from '@ember/controller';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import ModalPartyBranch from '@mvb/tix-ui/components/party/branch-modal';
import validateBranchIdUniqueness from '@mvb/tix-ui/validators/branch-id-uniqueness';
import validateUniqueBranchName from '@mvb/tix-ui/validators/unique-branch-name';

export default class PartyBookstoreBranchesController extends Controller {
  @service api;
  @service branches;
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service store;
  @service user;

  changeset = createChangeset(
    {
      name: '',
      identificationType: this.branches.DEFAULT_IDENTIFICATION_TYPE,
      identificationNumber: '',
    },
    {
      name: [
        validatePresence({
          presence: true,
          ignoreBlank: true,
          on: ['identificationNumber'],
          message: this.intl.t('validation.party.branches.name.required'),
        }),
        validateFormat({
          regex: /^[\d\s&A-Za-zÄÖÜßäöü-]+$/,
          allowBlank: true,
          message: this.intl.t('validation.party.branches.name.format'),
        }),
        validateUniqueBranchName(),
      ],
      identificationNumber: [
        validatePresence({
          presence: true,
          ignoreBlank: true,
          on: ['name'],
          message: this.intl.t('validation.party.branches.identificationNumber.required'),
        }),
        validateNumber({
          integer: true,
          allowBlank: true,
          message: this.intl.t('validation.party.branches.identificationNumber.format'),
        }),
        validateBranchIdUniqueness(),
      ],
    },
    { skipValidate: true }
  );

  @action
  onDelete(branch) {
    this.deleteBranchTask.perform(branch);
  }

  @action
  onValidate() {
    this.changeset.validate();
  }

  @task({ drop: true })
  *createBookstoreBranchTask(event) {
    event?.preventDefault();

    try {
      yield this.changeset.validate();

      if (this.changeset.isInvalid) {
        return;
      }
      let branch = yield this.store.createRecord('bookstore-branch', {
        identificationNumber: this.changeset.identificationNumber.trim().replaceAll(/\s+/g, ' '),
        identificationType: this.changeset.identificationType,
        name: this.changeset.name.trim().replaceAll(/\s+/g, ' '),
        party: this.user.selectedParty?.party,
      });

      yield branch.save();
      yield this.branches.load();

      // if it's the first branch, give option to transfer existing order lines to this branch
      if (this.branches.current.length === 1) {
        let shiftToBranch = yield this.modals.confirm({
          title: this.intl.t('party.branchModal.text.title'),
          message: this.intl.t('party.branchModal.text.initial', { htmlSafe: true }),
          cancel: this.intl.t('party.branchModal.label.delete'),
          confirm: this.intl.t('party.branchModal.label.transfer'),
        });

        yield this.api.postJSON('/entries/swap', {
          removeSourceBranch: false,
          sourceBranchToShiftEntriesFrom: null,
          targetBranchToShiftEntriesTo: shiftToBranch ? branch.id : null,
        });
      }

      this.notifications.info(this.intl.t('partyBookstoreBranches.notification.branchCreated'), {
        autoClear: false,
      });

      this.changeset.identificationNumber = '';
      this.changeset.name = '';
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  *deleteBranchTask(branch) {
    try {
      let modalAttributes = {
        modalType: SWAP_BRANCH_MODAL_TYPES.INTERMEDIATE,
        currentBranchId: branch.id,
      };

      let branches = this.branches.current;
      let isLast = false;
      if (branches.length === 1) {
        isLast = true;
        modalAttributes.modalType = SWAP_BRANCH_MODAL_TYPES.LAST;
      }

      let modalResult = yield this.modals.open(ModalPartyBranch, modalAttributes);

      if (modalResult.result === SWAP_BRANCH_MODAL_RESULTS.TRANSFER) {
        yield this.api.postJSON('/entries/swap', {
          removeSourceBranch: true,
          sourceBranchToShiftEntriesFrom: branch.id,
          targetBranchToShiftEntriesTo: modalResult.targetBranchId,
        });
        if (isLast) {
          this.notifications.info(this.intl.t('partyBookstoreBranches.notification.successfulDeletionOfLast'), {
            autoClear: false,
          });
        } else {
          this.notifications.success(this.intl.t('partyBookstoreBranches.notification.successfulDeletion'));
        }
      }
      if (modalResult.result === SWAP_BRANCH_MODAL_RESULTS.DELETE) {
        // Deleting the branch will trigger the BE to delete all of the associated DLPs, OLPs, OLPEs & DLPEs
        yield branch.destroyRecord();
        if (isLast) {
          this.notifications.info(this.intl.t('partyBookstoreBranches.notification.successfulDeletionOfLast'), {
            autoClear: false,
          });
        } else {
          this.notifications.success(this.intl.t('partyBookstoreBranches.notification.successfulDeletion'));
        }
      }
      yield this.branches.load();
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
