import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class BackofficeUsersAddRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('user');
  }

  deactivate() {
    if (this.store.isDestroyed || this.store.isDestroying) {
      return;
    }

    if (this.model.isNew) {
      this.model.unloadRecord();
    }
  }

  resetController(controller) {
    controller.showForm = false;
    controller.showMvbId = false;
    controller.emailDisabled = false;
    controller.fieldsDisabled = false;
    controller.permissionSetOptions = [];
    controller.permissionSetChangeset.rollback();
    controller.blogInfoChangeset.rollback();
  }
}
