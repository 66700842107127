import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section data-test-preview-links>\n  {{#each this.links as |link|}}\n    <Ui::CopyLink\n      @inline={{true}}\n      @label={{link.label}}\n      @url={{link.url}}\n      @withIcon={{true}}\n      data-test-preview-link={{link.type}}\n    />\n  {{/each}}\n</section>\n\n<Layout::Divider />", {"contents":"<section data-test-preview-links>\n  {{#each this.links as |link|}}\n    <Ui::CopyLink\n      @inline={{true}}\n      @label={{link.label}}\n      @url={{link.url}}\n      @withIcon={{true}}\n      data-test-preview-link={{link.type}}\n    />\n  {{/each}}\n</section>\n\n<Layout::Divider />","moduleName":"@mvb/tix-ui/components/sections/edit/preview-links/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/preview-links/index.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import window from 'ember-window-mock';

export const previewLinks = [
  {
    type: 'presentation',
    route: 'presentations.preview',
  },
  {
    type: 'list',
    route: 'previews.preview',
  },
];

export default class SectionsEditPreviewLinksComponent extends Component {
  @service intl;
  @service router;

  get links() {
    let baseUrl = window.location.origin;
    let links = [];

    for (let previewLink of previewLinks) {
      let path = this.router.urlFor(previewLink.route, this.args.preview.id);
      links.push({
        label: this.intl.t(`sectionsEditPreviewLinks.label.${previewLink.type}`),
        type: previewLink.type,
        url: `${baseUrl}${path}`,
      });
    }

    return links;
  }
}
