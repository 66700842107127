import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Text\n  @changeset={{@changeset}}\n  @disabled={{@disabled}}\n  @errorsClass={{@errorsClass}}\n  @hideLabel={{@hideLabel}}\n  @inlineErrors={{@inlineErrors}}\n  @label={{@label}}\n  @name={{@name}}\n  @path={{@path}}\n  @placeholder={{@placeholder}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @step={{@step}}\n  @type=\"text\"\n  @value={{this.value}}\n  @model={{@model}}\n  {{on \"blur\" this.onBlur}}\n/>", {"contents":"<Ui::Input::Text\n  @changeset={{@changeset}}\n  @disabled={{@disabled}}\n  @errorsClass={{@errorsClass}}\n  @hideLabel={{@hideLabel}}\n  @inlineErrors={{@inlineErrors}}\n  @label={{@label}}\n  @name={{@name}}\n  @path={{@path}}\n  @placeholder={{@placeholder}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @step={{@step}}\n  @type=\"text\"\n  @value={{this.value}}\n  @model={{@model}}\n  {{on \"blur\" this.onBlur}}\n/>","moduleName":"@mvb/tix-ui/components/ui/input/number-natural.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/number-natural.hbs"}});
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import UiInputBaseComponent from './base';

/*
This component allows the input of numbers and non-number characters but removes the non-number characters, when "onBlur"
event is called.
 */
export default class UiInputNumberNaturalComponent extends UiInputBaseComponent {
  get value() {
    if (isNone(this.args.value) && isNone(this.args.changeset)) {
      return 0;
    }
    return this.args.value;
  }

  @action
  onBlur() {
    let attributeName = this.args.name.split('-')[0];
    let formattedValue = this.removeNonNumberCharacters(this.args.changeset.get(attributeName));
    this.args.changeset.set(attributeName, formattedValue);
  }

  removeNonNumberCharacters(value) {
    let regexAllNonNumberCharacters = /\D/g;
    return value ? String(value).replaceAll(regexAllNonNumberCharacters, '') : null;
  }
}
