import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class CollectionOfGoodsModel extends Model {
  @attr('string') name;
  @attr('string') value;

  @belongsTo('collection-of-goods', { async: true, inverse: 'subCollections' }) parent;
  @belongsTo('party', { async: true, inverse: null }) party;
  @hasMany('collection-of-goods', { async: true, inverse: 'parent' }) subCollections;
}
