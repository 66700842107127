import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import ModalUserRequireAuthentication from '@mvb/tix-ui/components/user/modal-require-authentication';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  instance.element?.removeEventListener('click', instance.onClick);
}

export default class RequireAuthenticationModifier extends Modifier {
  @service modals;
  @service session;

  element;
  hasHref;
  href;
  initialCursor;

  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  modify(element) {
    this.element?.removeEventListener('click', this.onClick);
    element.addEventListener('click', this.onClick);

    this.element = element;

    if (this.session.isAuthenticated) {
      this.onAuthenticate();
    } else {
      this.onInvalidate();
    }
  }

  @action
  onAuthenticate() {
    if (this.element && this.hasHref) {
      this.element.setAttribute('href', this.href);
    }
    if (this.element && this.initialCursor) {
      this.element.style.cursor = this.initialCursor;
    }
  }

  @action
  onClick(event) {
    if (!this.session.isAuthenticated) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.modals.open(ModalUserRequireAuthentication);
    }
  }

  @action
  onInvalidate() {
    if (this.element) {
      this.hasHref = this.element.hasAttribute('href');

      if (this.hasHref) {
        this.href = this.element.href;
        this.element.removeAttribute('href');

        this.initialCursor = this.element.style.cursor;
        this.element.style.cursor = 'pointer';
      }
    }
  }
}
