export default {
  "input-row": "_input-row_kl7500 _input-row_74xocm",
  "tag-input": "_tag-input_kl7500 _tag-input_74xocm",
  "flex-input": "_flex-input_kl7500 _flex-input_74xocm",
  "loading": "_loading_kl7500 _loading_1lhvuh",
  "input-tree": "_input-tree_kl7500",
  "small-input": "_small-input_kl7500",
  "medium-input": "_medium-input_kl7500",
  "no-top-border": "_no-top-border_kl7500",
  "hint": "_hint_kl7500",
  "display": "_display_kl7500"
};
