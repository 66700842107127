import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Sections::Edit::Sortable\n  @allowRemoveAll={{true}}\n  @contents={{this.visibleVideoChangesets}}\n  @onReorder={{this.onReorder}}\n  @onRemove={{this.onRemove}}\n  @onRemoveLastItem={{this.onAdd}}\n>\n  <:body as |item index|>\n    <Sections::Edit::Videos::Item @video={{item}} @index={{index}} @onChange={{this.onChangeText}} />\n  </:body>\n  <:actions as |item index|>\n    {{#if (eq (add index 1) this.visibleVideoChangesets.length)}}\n      <Ui::Button data-test-add-more @style=\"link-secondary\" @icon=\"circle-plus\" @onClick={{this.onAdd}}>\n        {{t \"sectionsEditVideos.action.addVideo\"}}\n      </Ui::Button>\n    {{/if}}\n  </:actions>\n</Sections::Edit::Sortable>", {"contents":"<Sections::Edit::Sortable\n  @allowRemoveAll={{true}}\n  @contents={{this.visibleVideoChangesets}}\n  @onReorder={{this.onReorder}}\n  @onRemove={{this.onRemove}}\n  @onRemoveLastItem={{this.onAdd}}\n>\n  <:body as |item index|>\n    <Sections::Edit::Videos::Item @video={{item}} @index={{index}} @onChange={{this.onChangeText}} />\n  </:body>\n  <:actions as |item index|>\n    {{#if (eq (add index 1) this.visibleVideoChangesets.length)}}\n      <Ui::Button data-test-add-more @style=\"link-secondary\" @icon=\"circle-plus\" @onClick={{this.onAdd}}>\n        {{t \"sectionsEditVideos.action.addVideo\"}}\n      </Ui::Button>\n    {{/if}}\n  </:actions>\n</Sections::Edit::Sortable>","moduleName":"@mvb/tix-ui/components/sections/edit/videos/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/videos/index.hbs"}});
import { action } from '@ember/object';
import { ASSET_MODE_CODES, SECTION_CONTENT_TYPES } from '@mvb/tix-ui/constants';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import MachineEditModeChildComponent from '../../../machine-edit-mode/child';
import videoEditValidations from '@mvb/tix-ui/validations/video-edit';

export default class SectionsEditVideosComponent extends MachineEditModeChildComponent {
  @service store;

  @tracked videoChangesets = [];

  get changeset() {
    return this.videoChangesets;
  }

  get sectionContents() {
    return this.args.section.sortedContents;
  }

  get visibleVideoChangesets() {
    return this.videoChangesets.filter((changeset) => !changeset.get('markedForDeletion'));
  }

  get isDirty() {
    let hasDeletedChangesets = this.videoChangesets.some((changeset) => changeset.get('markedForDeletion'));
    let hasNewChangesets = this.videoChangesets.some((changeset) => changeset.get('isNew'));
    let hasDirtyChangesets = this.videoChangesets.some((changeset) => changeset.isDirty);

    return hasDeletedChangesets || hasNewChangesets || hasDirtyChangesets;
  }

  constructor() {
    super(...arguments);

    next(() => {
      this.initVideoSection();
      if (this.videoChangesets.length === 0 && this.parentMachineIsReady) {
        this.onAdd();
      }
    });
  }

  @action
  onAdd() {
    this.machine.send('UPDATE');
    let newRecord = this.store.createRecord('section-content', {
      section: this.args.section,
      resourceMode: ASSET_MODE_CODES.VIDEO,
      contentType: SECTION_CONTENT_TYPES.TIX,
      position: Number.POSITIVE_INFINITY,
    });

    let newChangeset = createChangeset(newRecord, newRecord.isVLBContent ? {} : videoEditValidations);
    this.videoChangesets = [...this.videoChangesets, newChangeset];

    this.updatePositions(this.videoChangesets);
    this.machine.send(this.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  @action
  onRemove(indexToRemove) {
    this.machine.send('UPDATE');
    let changesetToBeRemoved = this.visibleVideoChangesets[indexToRemove];

    if (changesetToBeRemoved.data.isNew) {
      let temporaryChangesets = [...this.videoChangesets];
      changesetToBeRemoved.data.unloadRecord();
      let marketingChangesetsTargetIndex = temporaryChangesets.indexOf(changesetToBeRemoved);
      temporaryChangesets.splice(marketingChangesetsTargetIndex, 1);
      this.videoChangesets = temporaryChangesets;
    } else {
      changesetToBeRemoved.set('markedForDeletion', true);
    }

    this.updatePositions(this.visibleVideoChangesets);
    this.machine.send(this.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  @action
  onReorder(oldIndex, newIndex) {
    this.machine.send('UPDATE');
    let sectionContentChangesets = [...this.visibleVideoChangesets];
    let [contentChangesetsToChange] = sectionContentChangesets.splice(oldIndex, 1);
    sectionContentChangesets.splice(newIndex, 0, contentChangesetsToChange);

    this.videoChangesets = sectionContentChangesets;

    this.updatePositions(this.visibleVideoChangesets);
    this.machine.send(this.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  initVideoSection() {
    for (let sectionContent of this.sectionContents) {
      let changeset = createChangeset(sectionContent, sectionContent.isVLBContent ? {} : videoEditValidations);
      this.videoChangesets = [...this.videoChangesets, changeset];
    }
  }

  updatePositions(changesets) {
    for (let [index, changeset] of changesets.entries()) {
      changeset.set('position', index);
    }
  }
}
