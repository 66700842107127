export default {
  instance: null,

  get hasInstance() {
    return !!this.instance && this.instance.isDestroyed !== true && this.instance.isDestroying !== true;
  },

  get isDestroying() {
    return this.instance?.isDestroying ?? false;
  },

  get isDestroyed() {
    return this.instance?.isDestroyed ?? false;
  },

  lookup() {
    if (!this.hasInstance) {
      return null;
    }

    return this.instance.lookup(...arguments);
  },
};
