import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.claim}}\n  <p local-class=\"claim\" data-test-content-claim>\n    <strong>\n      {{this.claim}}\n    </strong>\n  </p>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.claim}}\n  <p local-class=\"claim\" data-test-content-claim>\n    <strong>\n      {{this.claim}}\n    </strong>\n  </p>\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/content/claim.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/content/claim.hbs"}});
import { TEXT_CONTENT_TYPE_CODES } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class ProductContentClaimComponent extends Component {
  get claim() {
    let claim = this.args.product?.textContents?.find(
      (textContent) => textContent.textType === TEXT_CONTENT_TYPE_CODES.CLAIM
    );
    return claim?.text ?? '';
  }
}
