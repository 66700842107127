import { isPresent } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import ObjectProxy from '@ember/object/proxy';

export default function validateOrderRowDispoList(options) {
  return (key, newValue, _oldValue, changes, content) => {
    let collectionOfGoods = changes?.collectionOfGoods ?? content.collectionOfGoods;
    let deliveryDate = changes?.deliveryDate ?? content.deliveryDate;
    let note = changes?.note ?? content.note;
    let orderCode = changes?.orderCode ?? content.orderCode;
    let orderListProduct = changes?.orderListProduct ?? content.orderListProduct;
    let quantity = Number.parseInt(changes?.quantity ?? content.quantity, 10);

    if (collectionOfGoods instanceof ObjectProxy) {
      collectionOfGoods = collectionOfGoods?.content;
    }

    if (
      // not needed inside orderlist
      !isPresent(orderListProduct) &&
      // already saved order row and no dispolist selected
      ((!content.isNew && !isPresent(newValue)) ||
        // new order row and no dispolist selected but valid quantity or any of the optional fields is set
        (content.isNew &&
          !isPresent(newValue) &&
          (!Number.isNaN(quantity) ||
            isPresent(collectionOfGoods) ||
            isPresent(deliveryDate) ||
            isPresent(note) ||
            isPresent(orderCode))))
    ) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
