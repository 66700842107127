import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.dispoListExportActions}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{t \"uiFunctionBar.tooltip.exportToFile\"}}\n  />\n{{/if}}", {"contents":"{{#if this.hasActions}}\n  <Ui::FunctionBar::Export\n    @actions={{this.dispoListExportActions}}\n    @showDropdown={{true}}\n    @showTooltip={{true}}\n    @label={{t \"uiFunctionBar.tooltip.exportToFile\"}}\n  />\n{{/if}}","moduleName":"@mvb/tix-ui/components/ui/function-bar/export/dispo-list.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/function-bar/export/dispo-list.hbs"}});
import { action } from '@ember/object';
import {
  EXPORT_FILE_TYPES,
  MAX_PRODUCTS_FOR_BATCH_ADD,
  TIX_ITEM_SOURCE_TYPE,
  URL_DISPO_LIST_EXPORT_FOXXY,
  URL_PRODUCT_EXPORT,
  URL_PRODUCT_EXPORT_THALIA,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import saveBlob from '@mvb/tix-ui/utils/save-blob';

export default class UiFunctionBarExportDispoListComponent extends Component {
  @service abilities;
  @service api;
  @service errors;
  @service features;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service router;
  @service('search-products-in-dispo-list') searchService;
  @service store;

  get dispoListExportActions() {
    let actions = {};

    actions.excel = { label: this.intl.t('uiFunctionBarExport.action.excel'), action: this.onExportExcel };

    if (this.abilities.cannot('dispoList.useHugOrderLines') && this.abilities.cannot('dispoList.useThaliaOrderGrid')) {
      actions.excelWithOrderData = {
        label: this.intl.t('uiFunctionBarExport.action.excelWithOrderData'),
        action: this.onExportExcelWithOrderData,
      };
    }

    if (this.abilities.can('dispoList.exportFoxxy')) {
      actions.foxxy = {
        label: this.intl.t('uiFunctionBarExport.action.foxxy'),
        action: this.onExportFoxxy,
      };
    }

    if (this.features.isEnabled('productsPdfExport')) {
      actions.pdf = { label: this.intl.t('uiFunctionBarExport.action.pdf'), action: this.onPdfExport };
    }

    if (this.abilities.can('dispoList.handleReferenceProducts')) {
      actions.thaliaProducts = {
        label: this.intl.t('uiFunctionBarExport.action.thalia'),
        action: this.onExportThaliaProducts,
      };
    }

    return actions;
  }

  get hasActions() {
    return Object.keys(this.dispoListExportActions).length > 0;
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  @action
  onExportExcel() {
    this.exportProductsTask.perform(EXPORT_FILE_TYPES.XLSX);
  }

  @action
  onExportExcelWithOrderData() {
    this.exportProductsTask.perform(EXPORT_FILE_TYPES.XLSX, true);
  }

  @action
  onExportFoxxy() {
    this.exportFoxxyTask.perform();
  }

  @action
  onPdfExport() {
    this.exportProductsTask.perform(EXPORT_FILE_TYPES.PDF);
  }

  @action
  onExportThaliaProducts() {
    this.exportThaliaProductsTask.perform();
  }

  @task
  @waitFor
  *exportProductsTask(exportFileType, includeOrderInformation = false) {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('dispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    //TODO we should probably simply select the product ids in a dispolist instead of the DLP ids (will be investigated in #32475)
    let dlps =
      this.store
        .peekAll('dispo-list-product')
        ?.filter((dlp) => this.args.dispoList?.id && dlp.dispoList?.get('id') === this.args.dispoList?.id) ?? [];

    let filters = this.searchService.searchQuery.filter || {};
    delete filters['dispoList.id'];
    delete filters['onList'];

    try {
      let body = {
        exportFileType,
        filters,
        identifiers: Array.from(this.manageList.selectedItems)
          .map((dlpId) => dlps.find((dlp) => dlp.id === dlpId))
          .map((dlp) => dlp.searchProduct?.id)
          .filter(Boolean),
        includeOrderInformation,
        invertedSelection: this.manageList.inverse,
        sort: this.searchService.searchQuery.sort,
        sourceId: this.args.dispoList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.DISPO_LIST,
      };

      exportFileType === EXPORT_FILE_TYPES.PDF
        ? this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportPdf'))
        : this.notifications.success(this.intl.t('uiFunctionBarExport.notification.export'));
      let { blob, attachmentName } = yield this.api.postJSON(URL_PRODUCT_EXPORT, body);
      saveBlob(blob, attachmentName);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *exportFoxxyTask() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('dispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }

    if (this.manageList.determineSelectedElementsCount(this.searchService.meta.total) > MAX_PRODUCTS_FOR_BATCH_ADD) {
      return this.notifications.info(this.intl.t('uiFunctionBarExport.notification.foxxy.productCountExceeded'));
    }

    try {
      let filters = this.searchService.searchQuery.filter || {};

      delete filters['dispoList.id'];
      delete filters['onList'];

      let body = {
        filters,
        identifiers: Array.from(this.manageList.selectedItems),
        invertedSelection: this.manageList.inverse,
        sourceId: this.args.dispoList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.DISPO_LIST,
      };

      yield this.api.postJSON(URL_DISPO_LIST_EXPORT_FOXXY, body);

      this.notifications.success(this.intl.t('uiFunctionBarExport.notification.foxxy.success'));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *exportThaliaProductsTask() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('dispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    }
    this.notifications.success(this.intl.t('uiFunctionBarExport.notification.exportViaMail'));

    try {
      //TODO we should probably simply select the product ids in a dispolist instead of the DLP ids (will be investigated in #32475)
      let dlps =
        this.store.peekAll('dispo-list-product')?.filter((dlp) => dlp.dispoList.get('id') === this.args.dispoList.id) ??
        [];

      let filters = this.searchService.searchQuery.filter || {};

      delete filters['dispoList.id'];
      delete filters['searchMode'];

      let body = {
        filters,
        identifiers: Array.from(this.manageList.selectedItems)
          .map((dlpId) => dlps.find((dlp) => dlp.id === dlpId))
          .map((dlp) => dlp.searchProduct?.id)
          .filter(Boolean),
        invertedSelection: this.manageList.inverse,
        sort: this.searchService.searchQuery.sort,
        sourceId: this.args.dispoList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.DISPO_LIST,
      };

      yield this.api.postJSON(URL_PRODUCT_EXPORT_THALIA, body);
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
