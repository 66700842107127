import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @truncate}}\n  <span ...attributes local-class=\"truncate\" {{did-insert this.didInsert}} data-test-tooltip-container>\n    {{yield}}\n\n    {{#if this.showTooltip}}\n      <Tippy data-test-tooltip>\n        {{yield}}\n      </Tippy>\n    {{/if}}\n  </span>\n{{else}}\n  {{yield}}\n{{/if}}", {"contents":"{{#if @truncate}}\n  <span ...attributes local-class=\"truncate\" {{did-insert this.didInsert}} data-test-tooltip-container>\n    {{yield}}\n\n    {{#if this.showTooltip}}\n      <Tippy data-test-tooltip>\n        {{yield}}\n      </Tippy>\n    {{/if}}\n  </span>\n{{else}}\n  {{yield}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/layout/truncated-single-line/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/truncated-single-line/index.hbs"}});
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class LayoutTruncatedSingleLineComponent extends Component {
  @tracked showTooltip = false;

  @action
  didInsert(element) {
    // Needs to run after the runloop passes so the width calculation happens correctly
    next(() => {
      if (!this.args.truncate) {
        return;
      }

      this.showTooltip = element.scrollWidth > element.offsetWidth;
    });
  }
}
