import { isPresent } from '@ember/utils';
import { REGEX_CURRENCY } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { validateFormat, validatePresence } from 'ember-changeset-validations/validators/index';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import Route from '@ember/routing/route';

export default class PartyWkzRoute extends Route {
  @service abilities;
  @service api;
  @service intl;

  async model() {
    if (this.abilities.can('party.viewWkz')) {
      let marketingSubsidies = await this.api.get('/marketing-subsidies/hugendubel');
      return marketingSubsidies?.map((marketingSubsidy) => {
        // unfortunately this is required to allow the format validation to work
        marketingSubsidy.amount = isPresent(marketingSubsidy.amount) ? `${marketingSubsidy.amount}` : null;

        let changeset = createChangeset(marketingSubsidy, {
          amount: [
            validatePresence({
              presence: true,
              ignoreBlank: true,
              message: this.intl.t('partyWkz.validation.priceMissing'),
            }),
            validateFormat({
              allowBlank: true,
              regex: REGEX_CURRENCY,
              description: this.intl.t('partyWkz.validation.description'),
            }),
          ],
        });
        changeset.validate();
        return changeset;
      });
    }
  }
}
