import { AVAILABILITY_STATES } from '@mvb/tix-ui/constants';
import { DateTime } from 'luxon';
import { getLuxonDate } from '@mvb/tix-ui/utils/luxon-date';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateAvailabilityForPublishingDate() {
  return (key, newValue, oldValue, changes, content) => {
    let intlService = applicationUtil.lookup('service:intl');
    let now = DateTime.now();
    let publicationDate = getLuxonDate(changes.publicationDate ?? content.publicationDate);

    if (newValue === AVAILABILITY_STATES.NOT_YET_AVAILABLE && publicationDate <= now) {
      return buildMessage(key, {
        message: intlService.t('validation.availability.publicationInPast'),
      });
    }

    return true;
  };
}
