import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class PresentationsPreviewProductRoute extends Route {
  @service abilities;
  @service customMarketingContent;
  @service dispoListProductEntries;
  @service intl;
  @service metrics;
  @service presentation;
  @service searchProductsInPreview;
  @service router;
  @service store;
  @service theme;
  @service user;

  // do not use "refreshModel: true" here, see:
  // https://github.com/emberjs/ember.js/issues/16921
  // https://github.com/emberjs/ember.js/issues/16992
  queryParams = {
    asset: {
      refreshModel: false,
    },
    publishedStatus: {
      refreshModel: false,
    },
  };

  async model({ presentation_product_id: productId, publishedStatus }) {
    let preview = this.modelFor('presentations.preview').preview;
    let assortmentGroups = this.modelFor('presentations.preview').assortmentGroups;
    let contents = preview?.referenceTitles?.contents;

    if (productId === 'cover') {
      this.router.replaceWith('presentations.preview.cover');
      return;
    }

    if (!contents || contents.length === 0) {
      throw new Error(this.intl.t('error.preview.contentsAreMissing'));
    }

    if (!contents.some((content) => content.referencedProductId === productId)) {
      throw new Error(this.intl.t('error.preview.productIsNotPart'));
    }

    let adapterOptions = {
      query: {
        filter: {
          'preview.id': preview.id,
        },
      },
    };

    if (publishedStatus === SECTION_STATUS.PUBLISHED) {
      adapterOptions.query.filter.section = {
        status: publishedStatus,
      };
    }

    let product = await this.store.findRecord('product', productId, {
      include: [
        'contributors',
        'customMarketingContent',
        'customMarketingContent.marketingRecommendations',
        'customMarketingContent.hugMarketingRecommendations',
        'customMarketingContent.listingRecommendations',
        'customMarketingContent.hugListingRecommendations',
        'notes.creator',
        'sections',
        'sections.contents',
        'sections.contents.file',
        'sections.contents.prices',
        'sections.deliveryInfo',
        'sections.productInfo',
        'supportingResources',
        'textContents',
      ].join(','),
      reload: true,
      adapterOptions,
    });

    if (!product) {
      throw new Error(this.intl.t('error.preview.productNotFound'));
    }

    let dispoListProductEntries = this.abilities.can('dispoList.viewDispoListsInProductPages')
      ? await this.dispoListProductEntries.fetchByIsbns(product.gtin)
      : [];

    this.dispoListProductEntries.cleanup();
    dispoListProductEntries = this.dispoListProductEntries.mapSearchProduct({
      dlpes: dispoListProductEntries,
      searchProduct: product,
    }).dispoListProductEntries;

    let mvbId = await preview.sender?.mvbId;

    this.metrics.trackSpecific({
      area: 'Vorschauen',
      MVBIDPREVIEW: mvbId,
      title: {
        'BOOK.MVBID': product.mainPublisher.idValue,
        'BOOK.URLCODE': product.id,
        'BOOK.NAME': product.mainTitle.title,
        'BOOK.ISBN': product.gtin,
        'PREVIEW.MVBID': mvbId,
        'PREVIEW.NAME': preview.name,
        'PREVIEW.URLCODE': preview.id,
      },
    });

    let customMarketingContentService = this.customMarketingContent.getCMCServiceForPreviewType(preview.type);

    return {
      dispoListProductEntries,
      preview,
      product,
      publishedStatus,
      customMarketingContentService,
      assortmentGroups,
    };
  }

  async afterModel(model) {
    let {
      product: { isThemenspecial, productInfo },
    } = model;

    if (isThemenspecial) {
      this.theme.rootBackgroundColor = productInfo?.backgroundColor ?? null;
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    let {
      product,
      preview: { sortedProductsForPresentation },
    } = model;

    this.presentation.allProducts = sortedProductsForPresentation;
    this.presentation.currentProduct = product;
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
      controller.model.customMarketingContentService.unload();
    }
    this.theme.rootBackgroundColor = null;
  }
}
