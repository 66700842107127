export default {
  "h1": "_h1_ljnede",
  "h2": "_h2_ljnede",
  "h3": "_h3_ljnede",
  "h4": "_h4_ljnede",
  "h5": "_h5_ljnede",
  "h6": "_h6_ljnede",
  "larger": "_larger_ljnede",
  "link": "_link_ljnede",
  "text-wrapped": "_text-wrapped_ljnede"
};
