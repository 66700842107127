import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class OrderRoute extends Route {
  @service session;

  beforeModel(transition) {
    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }
}
