export default {
  "button": "_button_1jm5b1",
  "loading": "_loading_1jm5b1",
  "link": "_link_1jm5b1 _button_1jm5b1 reset-button",
  "link-secondary": "_link-secondary_1jm5b1 _link_1jm5b1 _button_1jm5b1 reset-button",
  "icon": "_icon_1jm5b1",
  "default": "_default_1jm5b1 _button_1jm5b1",
  "primary": "_primary_1jm5b1 _button_1jm5b1",
  "primary-light": "_primary-light_1jm5b1"
};
