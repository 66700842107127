import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper\">\n  <a\n    href={{@file.downloadUrl}}\n    download={{this.caption}}\n    target=\"_blank\"\n    local-class=\"downloadable\"\n    data-test-file-item={{@index}}\n    rel=\"noopener noreferrer\"\n    ...attributes\n  >\n    <span local-class=\"icon {{if @small 'small'}}\" data-test-file-icon={{this.resourceIcon}}>\n      <FaIcon @icon={{this.resourceIcon}} @size=\"lg\" />\n    </span>\n    <span local-class=\"name\" data-test-file-caption={{@index}}>\n      {{this.caption}}\n    </span>\n  </a>\n  {{#if @onDelete}}\n    <Ui::Button\n      local-class=\"button\"\n      @style=\"link\"\n      @icon=\"trash-can\"\n      @onClick={{fn @onDelete @file}}\n      data-test-file-delete={{@index}}\n    />\n  {{/if}}\n</div>", {"contents":"<div local-class=\"wrapper\">\n  <a\n    href={{@file.downloadUrl}}\n    download={{this.caption}}\n    target=\"_blank\"\n    local-class=\"downloadable\"\n    data-test-file-item={{@index}}\n    rel=\"noopener noreferrer\"\n    ...attributes\n  >\n    <span local-class=\"icon {{if @small 'small'}}\" data-test-file-icon={{this.resourceIcon}}>\n      <FaIcon @icon={{this.resourceIcon}} @size=\"lg\" />\n    </span>\n    <span local-class=\"name\" data-test-file-caption={{@index}}>\n      {{this.caption}}\n    </span>\n  </a>\n  {{#if @onDelete}}\n    <Ui::Button\n      local-class=\"button\"\n      @style=\"link\"\n      @icon=\"trash-can\"\n      @onClick={{fn @onDelete @file}}\n      data-test-file-delete={{@index}}\n    />\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/ui/file/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/file/index.hbs"}});
import { ASSET_MODE_CODES } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

const FILE_ICONS = {
  [ASSET_MODE_CODES.TEXT]: 'file-lines',
  [ASSET_MODE_CODES.AUDIO]: 'file-audio',
  [ASSET_MODE_CODES.IMAGE]: 'file-image',
  [ASSET_MODE_CODES.MISC]: 'file',
};

const FILE_ICON_DEFAULT = 'square-question';

export default class UiFileIndexComponent extends Component {
  get caption() {
    return this.args.caption ?? this.args.file?.get('filename');
  }

  get resourceIcon() {
    if (this.args.icon) {
      return this.args.icon;
    }

    let mode = this.args.file?.get('resourceMode');
    return FILE_ICONS[mode] ?? FILE_ICON_DEFAULT;
  }
}
