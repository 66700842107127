import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class SpecialsIndexController extends Controller {
  @service router;
  @service('search-specials') searchService;

  queryParams = ['qp'];
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  @action
  onAddToDispoList() {
    // TODO - should be removed (and all references, including possible feature flags/abilities/etc.) according to Kirsten (#31618)
  }

  @action
  onAddToProductlist() {
    // TODO - should be removed (and all references, including possible feature flags/abilities/etc.) according to Kirsten (#31618)
  }

  @action
  onCreateSpecial() {
    this.router.transitionTo('specials.add');
  }
}
