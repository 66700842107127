import { isBlank, isNone, isPresent } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateUserHasDefaultDispolist({ defaultDispoList }) {
  if (!applicationUtil.hasInstance) {
    return true;
  }

  let intlService = applicationUtil.lookup('service:intl');

  return (key, newValue, oldValue) => {
    if (isNone(defaultDispoList) && isPresent(newValue) && (isBlank(oldValue) || oldValue === '[]')) {
      return buildMessage(key, { message: intlService.t('validation.dispolist.noDefaultDispoList') });
    }

    return true;
  };
}
