import ENV from '@mvb/tix-ui/config/environment';

// this is for errors during execution, for errors on build see config/ember-intl.js
export default function missingMessage(key, locales) {
  let localeNames = locales.join(', ');
  let errorString = `[ember-intl] Missing translation for key "${key}" on locale "${localeNames}"`;

  if (['e2e'].includes(ENV.APP.TARGET) || ['development', 'test'].includes(ENV.environment)) {
    throw new Error(errorString);
  }

  return errorString;
}
