import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class=\"wrapper\"\n  ...attributes\n  {{did-update this.onEntriesLengthChange @entries.length}}\n  {{did-update this.onValueChange @value}}\n>\n  {{#if this.isVisible}}\n    <FaIcon local-class=\"icon\" @icon=\"magnifying-glass\" @size=\"sm\" />\n    <Ui::Input::Text\n      @clear={{true}}\n      @hideLabel={{true}}\n      @label={{t \"uiInputSearchFilter.label\"}}\n      @name={{concat \"search-filter-\" this.guid}}\n      @placeholder={{t \"uiInputSearchFilter.label\"}}\n      @onChange={{perform this.changeTask}}\n      @onClear={{fn this.onValueChange null (array \"\")}}\n      @value={{this.value}}\n    />\n  {{/if}}\n</div>\n\n{{yield this.entriesFiltered}}", {"contents":"<div\n  local-class=\"wrapper\"\n  ...attributes\n  {{did-update this.onEntriesLengthChange @entries.length}}\n  {{did-update this.onValueChange @value}}\n>\n  {{#if this.isVisible}}\n    <FaIcon local-class=\"icon\" @icon=\"magnifying-glass\" @size=\"sm\" />\n    <Ui::Input::Text\n      @clear={{true}}\n      @hideLabel={{true}}\n      @label={{t \"uiInputSearchFilter.label\"}}\n      @name={{concat \"search-filter-\" this.guid}}\n      @placeholder={{t \"uiInputSearchFilter.label\"}}\n      @onChange={{perform this.changeTask}}\n      @onClear={{fn this.onValueChange null (array \"\")}}\n      @value={{this.value}}\n    />\n  {{/if}}\n</div>\n\n{{yield this.entriesFiltered}}","moduleName":"@mvb/tix-ui/components/ui/input/search-filter.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/search-filter.hbs"}});
import { action, get } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

const DEBOUNCE_TIME = 250;
const INPUT_VALUE = '';
const MIN_ENTRIES = 6;
const LABEL_PATH = 'label';

export default class UiInputSearchFilterComponent extends Component {
  @tracked value;
  @tracked valueDebounced;

  guid = guidFor(this);

  constructor() {
    super(...arguments);
    this.value = this.args.value ?? INPUT_VALUE;
    this.setValueDebounced();
  }

  get debounceTime() {
    return this.args.debounceTime ?? DEBOUNCE_TIME;
  }

  get entriesFiltered() {
    let filterValues = this.valueDebounced?.trim()?.toLowerCase()?.split(/\s+/) ?? [];

    if (filterValues.length === 0 || !this.isVisible) {
      return this.args.entries;
    }

    return (
      this.args.entries?.filter((entry) => {
        let label = String(get(entry, this.labelPath))?.toLowerCase() ?? '';
        return filterValues.every((filterValue) => label.includes(filterValue));
      }) ?? []
    );
  }

  get labelPath() {
    return this.args.labelPath ?? LABEL_PATH;
  }

  get isVisible() {
    return this.args.isVisible && this.args.entries?.length >= MIN_ENTRIES;
  }

  @action
  onEntriesLengthChange(_element, [length]) {
    if (length < MIN_ENTRIES) {
      this.value = INPUT_VALUE;
      this.setValueDebounced();
    }
  }

  @action
  onValueChange(_element, [value]) {
    this.value = value;
    this.setValueDebounced();
  }

  @task({ restartable: true })
  *changeTask(value) {
    this.value = value;
    yield timeout(this.debounceTime);
    this.setValueDebounced();
  }

  setValueDebounced() {
    this.valueDebounced = this.value;
  }
}
