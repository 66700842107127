import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import window from 'ember-window-mock';

export default class RedirectService extends Service {
  @service router;

  goToCurrentRoute() {
    if (this.router.currentRouteName) {
      this.goToRoute(this.router.currentRouteName);
    }
  }

  @action
  goToRoute(route, ...models) {
    window.location.href = this.router.urlFor(route, ...models);
  }
}
