import { isEmpty, isPresent } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateDecimal(options = {}) {
  return (key, newValue /*, oldValue, changes, content*/) => {
    if (isEmpty(newValue)) {
      return true;
    }

    // just in case: check that we don't have an empty string or a string of whitespaces
    newValue = `${newValue}`;
    newValue = newValue?.trim();

    if (!applicationUtil.hasInstance || isEmpty(newValue)) {
      return true;
    }

    // always work with a positive number for the checks
    if (newValue.startsWith('-')) {
      newValue = newValue.slice(1);
    }

    let description = options.description ?? key;
    let decimal = options.decimal;
    let max = options.maxInt;

    if (newValue.includes('.')) {
      let match = newValue.match(/^(0*)(\d*)+.?(\d+)$/);
      let matchesMax = isPresent(max) ? match[2].length <= max : true;
      let matchesDecimal = isPresent(decimal) ? match[3].length <= decimal : true;

      if (!matchesMax && !matchesDecimal) {
        return buildMessage(key, {
          type: 'decimalMax',
          value: newValue,
          context: { description, max, decimal },
        });
      }

      if (!matchesDecimal) {
        return buildMessage(key, {
          type: 'decimal',
          value: newValue,
          context: { description, decimal },
        });
      }

      if (!matchesMax) {
        return buildMessage(key, {
          type: 'decimalMaxOnly',
          value: newValue,
          context: { description, max },
        });
      }
    } else {
      if (isPresent(max) && newValue.length > max) {
        return buildMessage(key, {
          type: 'decimalMaxOnly',
          value: newValue,
          context: { description, max },
        });
      }
    }

    return true;
  };
}
