import { assert, runInDebug } from '@ember/debug';
import { service } from '@ember/service';
import Helper from '@ember/component/helper';

/**
 * Helper to get the url for a route
 * @param {String} route - The full name of the route. i.e. 'backoffice.party.users'
 * @param {Array} [models] - An array of models required to render the route
 * @param {Object} [query] - The query params to be passed into the route
 */
export default class UrlFor extends Helper {
  @service router;

  compute([route, models = []], { queryParams = {} }) {
    runInDebug(() => {
      assert(`'route' must be passed in as the first positional argument to the 'url-for' helper`, !!route);
    });
    return this.router.urlFor(route, ...models, { queryParams });
  }
}
