import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-date-range={{@name}} local-class=\"date-range-picker\">\n  <div>\n    <Ui::Input::Date\n      @changeset={{@changeset}}\n      @disabled={{@disabled}}\n      @errorsClass={{@errorsClass}}\n      @label={{t \"uiInput.dateRange.label.dateFrom\"}}\n      @name=\"dateFrom\"\n      @onChange={{fn this.onChange \"dateFrom\"}}\n      @path=\"dateFrom\"\n      @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n    />\n  </div>\n\n  <div>\n    <Ui::Input::Date\n      @changeset={{@changeset}}\n      @disabled={{@disabled}}\n      @errorsClass={{@errorsClass}}\n      @label={{t \"uiInput.dateRange.label.dateTo\"}}\n      @name=\"dateTo\"\n      @onChange={{fn this.onChange \"dateTo\"}}\n      @path=\"dateTo\"\n      @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n    />\n  </div>\n</div>\n{{yield}}", {"contents":"<div data-test-date-range={{@name}} local-class=\"date-range-picker\">\n  <div>\n    <Ui::Input::Date\n      @changeset={{@changeset}}\n      @disabled={{@disabled}}\n      @errorsClass={{@errorsClass}}\n      @label={{t \"uiInput.dateRange.label.dateFrom\"}}\n      @name=\"dateFrom\"\n      @onChange={{fn this.onChange \"dateFrom\"}}\n      @path=\"dateFrom\"\n      @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n    />\n  </div>\n\n  <div>\n    <Ui::Input::Date\n      @changeset={{@changeset}}\n      @disabled={{@disabled}}\n      @errorsClass={{@errorsClass}}\n      @label={{t \"uiInput.dateRange.label.dateTo\"}}\n      @name=\"dateTo\"\n      @onChange={{fn this.onChange \"dateTo\"}}\n      @path=\"dateTo\"\n      @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n    />\n  </div>\n</div>\n{{yield}}","moduleName":"@mvb/tix-ui/components/ui/input/date-range.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/date-range.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class UiInputDateRangeComponent extends Component {
  @service intl;

  constructor() {
    super(...arguments);
  }

  @action
  onChange(input) {
    if (
      this.args.changeset.dateFrom &&
      this.args.changeset.dateTo &&
      this.args.changeset.dateFrom.startOf('day') > this.args.changeset.dateTo.startOf('day')
    ) {
      this.args.changeset.pushErrors(input, this.intl.t(`uiInput.dateRange.text.error.${input}`));
      return;
    }

    //re-set the fields with error to trigger the validation
    for (let error of this.args.changeset.errors) {
      this.args.changeset.set(error.key, error.value);
    }

    this.args?.onChange();
  }
}
