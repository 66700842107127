import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @address}}\n  <div data-test-address local-class=\"address\">\n    {{this.addressLine}}\n  </div>\n{{/if}}", {"contents":"{{#if @address}}\n  <div data-test-address local-class=\"address\">\n    {{this.addressLine}}\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/layout/address/line.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/address/line.hbs"}});
import Component from '@glimmer/component';

export default class LayoutAddressLineComponent extends Component {
  get addressLine() {
    if (!this.args.address) {
      return '';
    }
    let street = [this.args.address.street?.trim(), this.args.address.streetNumber?.trim()].filter(Boolean).join(' ');
    let city = [this.args.address.zipcode?.trim(), this.args.address.city?.trim()].filter(Boolean).join(' ');
    return [street, city, this.args.address.countryLabel?.trim()].filter(Boolean).join(', ');
  }
}
