export default {
  "color-error": "var(--red-100)",
  "table-row": "_table-row_50zf27",
  "erp-sales-credentials": "_erp-sales-credentials_50zf27",
  "sales-branch-table-row": "_sales-branch-table-row_50zf27",
  "sales-table-row": "_sales-table-row_50zf27",
  "error-first-column": "_error-first-column_50zf27",
  "error-second-column": "_error-second-column_50zf27",
  "error-third-column": "_error-third-column_50zf27",
  "identification-table": "_identification-table_50zf27",
  "order-channel-inputs": "_order-channel-inputs_50zf27",
  "order-channel-add-button": "_order-channel-add-button_50zf27",
  "order-channels-table": "_order-channels-table_50zf27",
  "delete-button-wrapper": "_delete-button-wrapper_50zf27",
  "error-message": "_error-message_50zf27",
  "ftp-input": "_ftp-input_50zf27",
  "url-input-row": "_url-input-row_50zf27",
  "copy-link-button": "_copy-link-button_50zf27",
  "sftp-hint": "_sftp-hint_50zf27"
};
