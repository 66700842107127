// eslint-disable-next-line import/named
import { cached } from '@glimmer/tracking';
import { didCancel, task } from 'ember-concurrency';
import { IDENTIFICATION_TYPE } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';

export default class BranchesService extends Service {
  @service errors;
  @service intl;
  @service store;
  @service user;

  DEFAULT_IDENTIFICATION_TYPE = IDENTIFICATION_TYPE.GERMAN;

  identificationTypes = [
    {
      value: IDENTIFICATION_TYPE.GERMAN,
      text: this.intl.t('partyBookstoreBranches.option.identificationType.GERMAN'),
    },
    {
      value: IDENTIFICATION_TYPE.AUSTRIAN,
      text: this.intl.t('partyBookstoreBranches.option.identificationType.AUSTRIAN'),
    },
    {
      value: IDENTIFICATION_TYPE.SWISS,
      text: this.intl.t('partyBookstoreBranches.option.identificationType.SWISS'),
    },
    {
      value: IDENTIFICATION_TYPE.MVB,
      text: this.intl.t('partyBookstoreBranches.option.identificationType.MVB'),
    },
    {
      value: IDENTIFICATION_TYPE.CUSTOM,
      text: this.intl.t('partyBookstoreBranches.option.identificationType.CUSTOM'),
    },
  ];

  getDefaultIdentificationType() {
    return IDENTIFICATION_TYPE.GERMAN;
  }

  @cached
  get current() {
    return this.loadTask.lastSuccessful?.value ?? [];
  }

  @cached
  get currentByCreated() {
    return sortBy(this.current, 'created.ts');
  }

  @cached
  get currentByName() {
    let compareOptions = { numeric: true, sensitivity: 'base' };

    return [...this.current].sort((a, b) => a.name.localeCompare(b.name, compareOptions));
  }

  @cached
  get selectOptions() {
    return this.currentByName.map(({ name: text, id: value }) => ({
      text,
      value,
    }));
  }

  @cached
  get selectOptionsByCreated() {
    return this.currentByCreated.map(({ name: text, id: value }) => ({
      text,
      value,
    }));
  }

  @cached
  get selectOptionsRecords() {
    return this.current.map((record) => ({
      text: record.name,
      value: record,
    }));
  }

  @task({ drop: true })
  @waitFor
  *loadTask() {
    try {
      let partyId = this.user.selectedParty?.party?.id || null;

      if (!partyId) {
        return [];
      }

      let query = {
        filter: {
          partyId,
          unpaged: true,
        },
      };

      return yield this.store.query('bookstore-branch', query);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  async load() {
    try {
      return await this.loadTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }
}
