import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

/**
 * This services manages selected state for a list
 */
export default class ListService extends Service {
  @service router;

  ICON_TYPES = ICON_TYPES;
  BUTTON_TYPES = BUTTON_TYPES;

  @tracked inverse = false;
  @tracked selectedItems = new Set();

  get allSelected() {
    return this.inverse && this.selectedCount === 0;
  }

  get selectedCount() {
    return this.selectedItems.size;
  }

  get isSelectionEmpty() {
    return this.selectedCount === 0 && !this.allSelected;
  }

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', this, this._routeDidChange);
  }

  willDestroy() {
    this.router.off('routeDidChange', this, this._routeDidChange);
    super.willDestroy(...arguments);
  }

  /**
   * Calculates and returns the selected elements count
   *
   * @param {number} total - the meta.total information or the total amount of elements possible for the list
   */
  determineSelectedElementsCount(total) {
    // check if all elements are selected
    if (this.allSelected) {
      return total;
    }

    // check if select all in function bar is checked (not visible on page) and elements are deselected
    if (this.inverse && this.selectedCount > 0) {
      return total - this.selectedCount;
    }

    // if select all was not checked and you manually selected elements or nothing at all
    return this.selectedCount;
  }

  /**
   * Check if item is selected
   * If the state is normal, it checks if the item is on the list
   * If the state is inversed, it checks if the item is not on the list
   *
   * @param {*} item
   * @returns
   */
  isSelected(item) {
    let inSelected = this.selectedItems.has(item);
    let inverse = this.inverse;

    return (inverse && !inSelected) || (!inverse && inSelected);
  }

  addToSelectedList(productId, checked) {
    let inverse = this.inverse;
    // update selected products for further usage (e.g. download or add to cart)
    // only add to selectedElements when
    // * the inverse checkbox in the function bar is selected and the current checkbox (of element) would be not
    // * the inverse checkbox in the function bar is not selected and the current checkbox (of element) would be
    let selectedItems = new Set(this.selectedItems);

    if ((checked && !inverse) || (!checked && inverse)) {
      selectedItems.add(productId);
    } else {
      selectedItems.delete(productId);
    }

    this.selectedItems = selectedItems;
  }

  toggleSelection() {
    let inverse = this.inverse;
    let selectedCount = Number(this.selectedCount);
    let isSelectedAndHasElements = inverse && selectedCount > 0;
    let isNotSelectedAndHasNoElements = !inverse && selectedCount === 0;
    let isNotSelectedAndHasElements = !inverse && selectedCount > 0;

    this.inverse = isSelectedAndHasElements || isNotSelectedAndHasNoElements || isNotSelectedAndHasElements;
    // always clear the selected and reset the size
    // reason: we only need the selected when inverse is selected and elements are deselected (or the other way around)
    this.selectedItems = new Set();
  }

  reset() {
    this.inverse = false;
    this.selectedItems = new Set();
  }

  _routeDidChange(transition) {
    let transitionToName = transition.to?.name ?? '';

    if (
      transition.from?.name !== transitionToName &&
      !transitionToName.includes('presentation') &&
      !transitionToName.includes('dispo-list.index') &&
      !transitionToName.includes('product-list.index')
    ) {
      // Automatically reset the selection state when switching routes, but keep the information when transitioning to presentation mode or in the dispo-list context
      this.reset();
    }
  }
}
