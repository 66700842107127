import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { USER_GENDER, USER_GENRE, USER_PLATFORMS } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import createOptions from '@mvb/tix-ui/utils/create-options';

export default class ProfileUserEditController extends Controller {
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service router;
  @service session;
  @service store;

  get genderOptions() {
    return createOptions(this.intl, USER_GENDER, 'modelUser.text.gender');
  }

  get genreOptions() {
    return createOptions(this.intl, USER_GENRE, 'modelUser.text.genres').sort((a, b) => a.text.localeCompare(b.text));
  }

  get platformOptions() {
    return createOptions(this.intl, USER_PLATFORMS, 'modelBlogInfo.text.platforms').sort((a, b) => {
      if (a.value === USER_PLATFORMS.OTHERS) {
        return 1;
      }

      if (b.value === USER_PLATFORMS.OTHERS) {
        return -1;
      }

      return a.text.localeCompare(b.text);
    });
  }

  @action
  updateGenres(genre, value) {
    if (value) {
      this.model.user.genres = [...this.model.user.genres, genre];
    } else {
      this.model.user.genres.splice(this.model.user.genres.indexOf(genre), 1);
      this.model.user.genres = [...this.model.user.genres];
    }
  }

  @action
  updatePlatforms(platform, value) {
    //FIXME find better solution here
    if (value) {
      this.model.blogInfo.platforms = this.model.blogInfo.platforms
        ? [...this.model.blogInfo.platforms, platform]
        : [platform];
    } else {
      this.model.blogInfo.platforms.splice(this.model.blogInfo.platforms.indexOf(platform), 1);
      this.model.blogInfo.platforms = [...this.model.blogInfo.platforms];
    }
  }

  @action
  saveUser(event) {
    event.preventDefault();

    return this.saveUserTask.perform();
  }

  @task({ drop: true })
  @waitFor
  *saveUserTask() {
    try {
      yield this.model.blogInfo?.validate?.();
      yield this.model.user.validate();

      if (this.model.blogInfo?.isInvalid || this.model.user.isInvalid) {
        return;
      }

      let address = this.model.address;
      let { street, zipcode, city, country } = yield address.execute();
      let addressEmpty = [street, zipcode, city, country].join('').trim() === '';

      if (addressEmpty) {
        this.model.user.set('address', null);
      } else if (address.isDirty) {
        yield address.save();
      }

      if (this.model.blogInfo?.isDirty) {
        yield this.model.blogInfo.save();
      }

      yield this.model.user.save();

      // refetch model else transition to user profile will run into errors
      yield this.store.findRecord('user', this.model.user.id, {
        include: 'address,avatar,registrationInput,blogInfo',
      });

      this.notifications.success(this.intl.t('profileUserEdit.notifications.saveSuccess'));
      this.router.transitionTo('profile.user.index');
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  async deleteUser(event) {
    event.preventDefault();

    try {
      let deleteBoUser = await this.modals.confirm({
        title: this.intl.t('profileUserEdit.modal.title'),
        message: this.model.user.hasAssignedParties
          ? this.intl.t('profileUserEdit.modal.text') + this.intl.t('profileUserEdit.modal.textExtensionForCorporate')
          : this.intl.t('profileUserEdit.modal.text'),
        confirm: this.intl.t('profileUserEdit.modal.confirm'),
      });

      if (deleteBoUser) {
        await this.model.userInStore.destroyRecord();
        this.notifications.success(this.intl.t('profileUserEdit.notifications.deleteSuccess'));
        this.session.invalidate();
        this.router.transitionTo('application');
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  onCancel() {
    if (this.model.address.data?.isNew) {
      this.model.address.data.unloadRecord();
    }

    this.router.transitionTo('profile.user');
  }
}
