export default {
  "stage": "_stage_rvvsnc",
  "switch": "_switch_rvvsnc reset-button",
  "label": "_label_rvvsnc",
  "content": "_content_rvvsnc",
  "text": "_text_rvvsnc",
  "cover": "_cover_rvvsnc",
  "iframe": "_iframe_rvvsnc",
  "img": "_img_rvvsnc"
};
