import { isEmpty } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateHexCode() {
  return (key, newValue /* , oldValue, changes, content */) => {
    let hexCodeValidation = String(newValue).match(/^#([\da-f]{6})$/i);

    if (hexCodeValidation || isEmpty(newValue)) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let message = buildMessage(key, {
      message: intlService.t('validation.color.hexCode'),
    });

    return message;
  };
}
