import { fromUint8Array } from 'js-base64';
import { pack } from 'msgpackr';
import { zlibSync } from 'fflate';

export default function queryParamsPack(queryParams = {}) {
  if (!queryParams || Object.entries(queryParams).length === 0) {
    return { qp: '' };
  }

  let packed = pack(queryParams);
  let compressed = zlibSync(packed, { level: 9 });
  let qp = fromUint8Array(compressed, true);

  return { qp };
}
