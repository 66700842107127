import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.value}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{fn this.onFilterChange null}}\n    {{did-update this.onValueArgumentChange @value}}\n  >\n    <:content>\n      {{this.content}}\n    </:content>\n  </Search::Filter>\n{{/if}}", {"contents":"{{#if this.value}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{fn this.onFilterChange null}}\n    {{did-update this.onValueArgumentChange @value}}\n  >\n    <:content>\n      {{this.content}}\n    </:content>\n  </Search::Filter>\n{{/if}}","moduleName":"@mvb/tix-ui/components/search/filter/contained-items/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/contained-items/index.hbs"}});
import { service } from '@ember/service';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterContainedItemsComponent extends SearchFilterBaseComponent {
  @service intl;

  get content() {
    if (this.args.onSpecialsPage) {
      return this.intl.t('searchFilterContainedItems.text.activeSpecials', { identifier: this.value });
    }
    return this.intl.t('searchFilterContainedItems.text.activePromos', { identifier: this.value });
  }

  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();
  }
}
