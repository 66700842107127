import { service } from '@ember/service';
import { USER_ROLES } from '@mvb/tix-ui/constants';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class BackofficeAdminsIndexRoute extends Route {
  @service store;

  queryParams = {
    page: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
  };

  async model({ page, sort }) {
    let queryParams = {
      page: {
        number: page,
      },
    };

    queryParams.filter = { roles: USER_ROLES.TIX_BO };

    if (sort) {
      queryParams.sort = sort;
    }

    let users = this.store.query('user', queryParams);
    return users;
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
