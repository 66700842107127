import { isPresent } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import ObjectProxy from '@ember/object/proxy';

export default function validateOrderRowQuantity(options) {
  return (key, newValue, _oldValue, changes, content) => {
    let collectionOfGoods = changes?.collectionOfGoods ?? content.collectionOfGoods;
    let deliveryDate = changes?.deliveryDate ?? content.deliveryDate;
    let note = changes?.note ?? content.note;
    let orderCode = changes?.orderCode ?? content.orderCode;
    let quantity = Number.parseInt(newValue, 10);

    if (collectionOfGoods instanceof ObjectProxy) {
      collectionOfGoods = collectionOfGoods?.content;
    }

    if (
      // already saved order row and quantity is not valid
      (!content.isNew && Number.isNaN(quantity)) ||
      // new order row and any of the optional fields is set
      (content.isNew &&
        Number.isNaN(quantity) &&
        (isPresent(collectionOfGoods) || isPresent(deliveryDate) || isPresent(note) || isPresent(orderCode)))
    ) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
