import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @description}}\n  <div local-class=\"description-wrapper\" data-test-content-description {{did-insert this.setElement}}>\n    <Layout::Truncate\n      @height={{if @truncate (if @height @height 220)}}\n      @truncate={{@truncate}}\n      @onToggle={{this.onToggle}}\n    >\n      <:content>\n        <Product::Content::DescriptionText @description={{@description}} />\n      </:content>\n    </Layout::Truncate>\n  </div>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if @description}}\n  <div local-class=\"description-wrapper\" data-test-content-description {{did-insert this.setElement}}>\n    <Layout::Truncate\n      @height={{if @truncate (if @height @height 220)}}\n      @truncate={{@truncate}}\n      @onToggle={{this.onToggle}}\n    >\n      <:content>\n        <Product::Content::DescriptionText @description={{@description}} />\n      </:content>\n    </Layout::Truncate>\n  </div>\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/content/description.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/content/description.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ProductContentDescriptionComponent extends Component {
  @service user;
  @service jump;

  _element = null;

  @action
  setElement(element) {
    this._element = element;
  }

  @action
  onToggle(truncate) {
    if (truncate) {
      this.jump.scrollIntoView({ element: this._element });
    }
  }

  willDestroy() {
    this._element = null;
    super.willDestroy(...arguments);
  }
}
