import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class ComparativeProductModel extends Model {
  @attr('string') ean;
  @attr('string') note;
  @attr() position;

  @attr() salesLast1Month;
  @attr() salesLast2Months;
  @attr() salesLast3Months;
  @attr() salesLast6Months;

  @attr() acquisition3Months;
  @attr() fullAcquisition;
  @attr() marketShare;

  @belongsTo('custom-marketing-content', { async: false, inverse: 'comparativeProducts' }) customMarketingContent;
  @belongsTo('search-product', { async: false, inverse: null }) searchProduct;

  get identifier() {
    let isbn = this.searchProduct?.isbn;
    let gtin = this.searchProduct?.gtin;

    return isbn ?? gtin ?? this.ean;
  }

  get title() {
    return this.searchProduct?.title;
  }
}
