import { service } from '@ember/service';
import Ability from './-base';

export default class BackofficeAbility extends Ability {
  @service user;

  get access() {
    return this.user.current?.isBackofficeUser;
  }

  get editPartyMoreRights() {
    return (
      this.editPartyMoreRightsPreviewImport ||
      this.editPartyMoreRightsWebToPrint ||
      this.editPartyMoreRightsManageBookstorePreviews
    );
  }

  get editPartyMoreRightsPreviewImport() {
    let party = this.model?.party;
    return party?.isBookGroup || party?.isPublisher || party?.isSalesCoop;
  }

  get editPartyMoreRightsManageBookstorePreviews() {
    let party = this.model?.party;
    return party?.isBookstore;
  }

  get editPartyMoreRightsWebToPrint() {
    let party = this.model?.party;
    return party?.isPublisher;
  }
}
