import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-progress local-class=\"progress\" style={{this.style}}></div>", {"contents":"<div data-test-progress local-class=\"progress\" style={{this.style}}></div>","moduleName":"@mvb/tix-ui/components/ui/input/file/progress.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/file/progress.hbs"}});
import { assert } from '@ember/debug';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class UiInputFileProgressComponent extends Component {
  get style() {
    assert('progress is a number', typeof this.args.progress === 'number');
    assert('progress is between 0 and 100', this.args.progress >= 0 || this.args.progress <= 100);

    return htmlSafe(`transform: scaleX(${this.args.progress / 100})`);
  }
}
