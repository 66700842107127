import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"image\" ...attributes>\n  {{#if @user.avatar}}\n    <img\n      src={{@user.avatar.downloadUrl}}\n      alt={{t \"userProfileImage.text.imageAlt\" user=@user.fullName}}\n      data-test-avatar-image\n      {{prevent-contextmenu}}\n    />\n  {{else}}\n    <FaIcon data-test-icon=\"user\" @icon=\"user\" @size=\"7x\" local-class=\"icon\" />\n  {{/if}}\n</div>\n\n{{#if (eq this.mode \"edit\")}}\n  <Ui::Input::Asset::Image\n    local-class=\"upload\"\n    @maxSize={{this.maxSize}}\n    @dimensions={{this.dimensions}}\n    @multiple={{false}}\n    @payload={{this.payload}}\n    @onFileRemove={{this.onFileRemove}}\n    @onChange={{this.afterAssetUpload}}\n    @value={{@user.avatar}}\n  />\n\n  <div local-class=\"hint\" data-test-hint>\n    {{t \"userProfileImage.text.hint\"}}\n  </div>\n{{/if}}\n\n{{yield}}", {"contents":"<div local-class=\"image\" ...attributes>\n  {{#if @user.avatar}}\n    <img\n      src={{@user.avatar.downloadUrl}}\n      alt={{t \"userProfileImage.text.imageAlt\" user=@user.fullName}}\n      data-test-avatar-image\n      {{prevent-contextmenu}}\n    />\n  {{else}}\n    <FaIcon data-test-icon=\"user\" @icon=\"user\" @size=\"7x\" local-class=\"icon\" />\n  {{/if}}\n</div>\n\n{{#if (eq this.mode \"edit\")}}\n  <Ui::Input::Asset::Image\n    local-class=\"upload\"\n    @maxSize={{this.maxSize}}\n    @dimensions={{this.dimensions}}\n    @multiple={{false}}\n    @payload={{this.payload}}\n    @onFileRemove={{this.onFileRemove}}\n    @onChange={{this.afterAssetUpload}}\n    @value={{@user.avatar}}\n  />\n\n  <div local-class=\"hint\" data-test-hint>\n    {{t \"userProfileImage.text.hint\"}}\n  </div>\n{{/if}}\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/user/profile/image/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/user/profile/image/index.hbs"}});
import { action } from '@ember/object';
import { ASSET_MAX_SIZE, ASSET_MODE_CODES, DIMENSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const PROFILE_IMAGE_TYPE = 'PROFILE_IMAGE';

export default class UserProfileImageComponent extends Component {
  @service errors;
  @service modals;
  @service store;
  @service intl;

  mode = this.args.mode || 'show';
  maxSize = ASSET_MAX_SIZE;

  dimensions = {
    maxWidth: DIMENSIONS.PROFILE_WIDTH,
    maxHeight: DIMENSIONS.PROFILE_HEIGHT,
  };

  payload = {
    assetfileType: PROFILE_IMAGE_TYPE,
    resourceMode: ASSET_MODE_CODES.IMAGE,
  };

  @action
  afterAssetUpload(result) {
    this.args.user.avatar = result;
    this.args.user.save();
  }

  @action
  async onFileRemove() {
    let confirmed = await this.modals.confirm({
      message: this.intl.t('userProfileImage.delete.text'),
      title: this.intl.t('userProfileImage.delete.title'),
    });
    if (confirmed) {
      let avatar = await this.store.findRecord('file', this.args.user.avatar.id);
      await avatar.destroyRecord();
      this.args.user.avatar = null;
      this.args.user.save();
    }
  }
}
