import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class DispoListProductModel extends Model {
  @attr() created;
  @attr() coverUrl;
  @attr() productIsbn;

  @attr() sourceType; // TIX_ITEM_SOURCE_TYPE
  @attr() sourceId; // e.g. preview.id
  @attr() sourceName; // e.g. preview.name

  @belongsTo('dispo-list', { async: false, inverse: 'dispoListProducts' }) dispoList;
  @belongsTo('product', { async: false, inverse: null }) product;
  @belongsTo('search-product', { async: false, inverse: null }) searchProduct;

  @hasMany('dispo-list-product-entry', { async: false, inverse: 'dispoListProduct' }) dispoListProductEntries;
  @hasMany('thalia-dispo-list-product-entry', { async: false, inverse: 'dispoListProduct' })
  thaliaDispoListProductEntries;
}
