import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { validatePresence } from 'ember-changeset-validations/validators/index';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import validateCollectionOfGoodsNameUniqueness from '@mvb/tix-ui/validators/collection-of-goods-name-uniqueness';
import validateCollectionOfGoodsValueUniqueness from '@mvb/tix-ui/validators/collection-of-goods-value-uniqueness';

export default class PartyCollectionOfGoodsController extends Controller {
  @service api;
  @service collectionOfGoods;
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service store;
  @service user;

  changeset = createChangeset(
    {
      name: '',
      value: '',
    },
    {
      name: [
        validatePresence({
          presence: true,
          ignoreBlank: true,
          on: ['value'],
          message: this.intl.t('validation.party.collectionOfGoods.name.required'),
        }),
        validateCollectionOfGoodsNameUniqueness(),
      ],
      value: [
        validatePresence({
          presence: true,
          ignoreBlank: true,
          on: ['name'],
          message: this.intl.t('validation.party.collectionOfGoods.value.required'),
        }),
        validateCollectionOfGoodsValueUniqueness(),
      ],
    },
    { skipValidate: true }
  );

  @action
  onChange() {
    this.changeset.validate();
  }

  @action
  onDelete(collection) {
    this.deleteCollectionOfGoodsTask.perform(collection);
  }

  @task({ drop: true })
  @waitFor
  *createCollectionOfGoodsTask(event) {
    event?.preventDefault();

    try {
      yield this.changeset.validate();

      if (this.changeset.isInvalid) {
        return;
      }

      let collection = yield this.store.createRecord('collection-of-goods', {
        name: this.changeset.name.trim(),
        value: this.changeset.value.trim(),
        party: this.user.selectedParty?.party,
      });

      yield collection.save();
      // load the collection of goods, but force an update (so the new collection of goods is loaded)
      yield this.collectionOfGoods.load(null, true);

      this.changeset.name = '';
      this.changeset.value = '';
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  *deleteCollectionOfGoodsTask(collection) {
    try {
      let deleteCollection = yield this.modals.confirm({
        title: this.intl.t('partyCollectionOfGoods.modal.title'),
        message: this.intl.t('partyCollectionOfGoods.modal.text'),
        confirm: this.intl.t('partyCollectionOfGoods.modal.confirm'),
      });

      if (deleteCollection) {
        yield collection.destroyRecord();
        this.notifications.success(this.intl.t('partyCollectionOfGoods.notification.success'));
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
