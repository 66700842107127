import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PresentationsProductListIndexRoute extends Route {
  @service router;

  beforeModel() {
    let { mappedProductListProducts, productList } = this.modelFor('presentations.product-list');

    if (mappedProductListProducts?.[0]?.referencedProductId) {
      // redirect to first product
      this.router.replaceWith(
        'presentations.product-list.product',
        productList.id,
        mappedProductListProducts[0].referencedProductId
      );
    } else {
      // redirect back to the product list so we are not stuck in presentation mode nirvana
      this.router.replaceWith('product-lists.product-list', productList.id);
    }
  }
}
