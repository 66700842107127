import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-update this.excerptUrlTask.perform @product}}></span>\n{{#if this.excerptURL}}\n  <Ui::Button\n    ...attributes\n    @style=\"link\"\n    @onClick={{this.openExcerpt}}\n    {{require-authentication}}\n    data-test-excerpt-button\n  >\n    {{t \"productExcerpt.action.read\"}}\n    <FaIcon @icon=\"share\" @rotation={{90}} />\n  </Ui::Button>\n\n  {{yield}}\n{{/if}}", {"contents":"<span {{did-update this.excerptUrlTask.perform @product}}></span>\n{{#if this.excerptURL}}\n  <Ui::Button\n    ...attributes\n    @style=\"link\"\n    @onClick={{this.openExcerpt}}\n    {{require-authentication}}\n    data-test-excerpt-button\n  >\n    {{t \"productExcerpt.action.read\"}}\n    <FaIcon @icon=\"share\" @rotation={{90}} />\n  </Ui::Button>\n\n  {{yield}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/excerpt/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/excerpt/index.hbs"}});
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import fetch from 'fetch';
import ModalProductExcerpt from '@mvb/tix-ui/components/product/excerpt/modal';
import sortBy from 'lodash-es/sortBy';

import {
  ASSET_FORM_CODES,
  ASSET_MODE_CODES,
  ASSET_TYPE_CODES,
  EXCERPT_EXTERNAL_PROVIDERS,
  EXCERPT_EXTERNAL_URLS,
} from '@mvb/tix-ui/constants';

/**
 * Retrieve Midvox preview iframe URL
 *
 * @param {Object} options
 * @param {string} options.partnerId
 * @param {string} options.isbn
 * @param {string} options.outputId
 * @param {number} [options.targetId]
 * @param {boolean} [options.includeDiv]
 * @param {string} [options.buttonId]
 * @param {string} [options.flashId]
 * @param {string} [options.trafficId]
 * @param {string} [options.lang]
 * @param {string} [options.cntId]
 * @param {boolean} [options.fillParent]
 */
export async function midvoxIframe(options) {
  let rnd = `MX_${Date.now() + Math.round(Math.random() * 65536)}`;
  let params = {
    partnerId: '',
    isbn: '',
    outputId: '',
    targetId: 1,
    targetDivId: '',
    includeDiv: rnd,
    buttonId: '',
    flashId: '',
    trafficId: '',
    lang: '',
    cntId: '',
    fillParent: false,
    ...options,
    loc: escape(window.location.href),
    rnd,
  };

  if (options.targetId === 1) {
    params.targetDivId = options.targetDivId;
  }

  // API expects underscores
  for (let [key, value] of Object.entries(params)) {
    delete params[key];
    if (value !== undefined && value !== '' && value !== false) {
      params[underscore(key)] = value;
    }
  }

  let response = await fetch(`//www.blickinsbuch.de/gateway/check.php?${new URLSearchParams(params)}`);
  let text = await response.text();
  let [, url] = text.match(/src="(.*)"\s/) ?? [];

  return url;
}

/**
 * Returns the URL to an external excerpt provider or an empty String
 */
export async function getExternalExcerptUrl(identifier, api) {
  try {
    identifier = encodeURIComponent(identifier.replace('-', ''));

    if (isEmpty(identifier)) {
      return '';
    }

    let provider = await api.get(`/read-sample/get-provider/${identifier}`);

    if (provider === EXCERPT_EXTERNAL_PROVIDERS.BICMEDIA) {
      return EXCERPT_EXTERNAL_URLS.BICMEDIA.replace('{IDENTIFIER}', identifier);
    }

    if (provider === EXCERPT_EXTERNAL_PROVIDERS.MIDVOX) {
      return await midvoxIframe({
        partnerId: '1204088334',
        isbn: identifier,
        outputId: '5',
        targetId: 3,
      });
    }

    if (provider === EXCERPT_EXTERNAL_PROVIDERS.BOOK2LOOK) {
      return EXCERPT_EXTERNAL_URLS.BOOK2LOOK.replace('{IDENTIFIER}', identifier);
    }

    if (provider === EXCERPT_EXTERNAL_PROVIDERS.LIBREKA) {
      return EXCERPT_EXTERNAL_URLS.LIBREKA.replace('{IDENTIFIER}', identifier);
    }
  } catch {
    // no need for error handling here, we just return an empty string and don't show an excerpt at all
    // the backend should return null or an empty string when an error occurs, so we might never run into this catch
    return '';
  }
}
export default class ProductExcerptComponent extends Component {
  @service api;
  @service intl;
  @service modals;

  constructor() {
    super(...arguments);

    this.excerptUrlTask.perform();
  }

  get excerptURL() {
    if (this.args.product.isProductCollection) {
      return '';
    }

    return this.excerptUrlTask.lastSuccessful?.value;
  }

  @task({ drop: true })
  @waitFor
  *excerptUrlTask() {
    if (this.args.product.isProductCollection) {
      return '';
    }

    try {
      let external = yield getExternalExcerptUrl(this.args.product?.gtin, this.api);
      if (external) {
        return external;
      }

      let assets = yield this.args.product?.supportingResources;

      // check if we have a widget from the publisher
      let widgets =
        assets?.filter(
          (asset) =>
            asset.resourceContentType === ASSET_TYPE_CODES.EXCERPT_WIDGET &&
            asset.resourceForm === ASSET_FORM_CODES.LINKABLE &&
            asset.resourceMode === ASSET_MODE_CODES.WIDGET
        ) || [];
      let sortedWidgets = sortBy(widgets, 'sequence');
      let widget = sortedWidgets[0]?.exportedLink;
      if (!isEmpty(widget)) {
        return widget;
      }

      // check if we have excerpts stored with the product in VLB
      let excerpts =
        assets?.filter(
          (asset) =>
            asset.resourceContentType === ASSET_TYPE_CODES.EXCERPT &&
            asset.resourceForm === ASSET_FORM_CODES.DOWNLOADABLE &&
            asset.resourceMode === ASSET_MODE_CODES.TEXT
        ) || [];
      let sortedExcerpts = sortBy(excerpts, 'sequence');
      let excerpt = sortedExcerpts[0]?.exportedLink;
      if (!isEmpty(excerpt)) {
        return excerpt;
      }

      return '';
    } catch {
      // no need for error handling here, we just return an empty string and don't show an excerpt at all
      // the backend should return null or an empty string when an error occurs, so we might never run into this catch
      return '';
    }
  }

  @action
  openExcerpt() {
    this.modals.open(ModalProductExcerpt, {
      title: this.intl.t('productExcerpt.text.read'),
      excerpt: this.excerptURL,
    });
  }
}
