import { validatePresence } from 'ember-changeset-validations/validators/index';

export default function validateErpSalesBranchSetup() {
  return {
    username: [
      validatePresence({
        presence: true,
        description: 'intl:validation.party.erpSales.branch.username.description',
      }),
    ],
    password: [
      validatePresence({
        presence: true,
        description: 'intl:validation.party.erpSales.branch.password.description',
      }),
    ],
  };
}
