import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  aria-pressed={{if this.useBacklist \"true\" \"false\"}}\n  data-test-checkbox-backlist\n  disabled={{this.disabled}}\n  local-class=\"checkbox\"\n  type=\"button\"\n  ...attributes\n  {{on \"click\" this.onChange}}\n>\n  <FaIcon local-class=\"icon\" @icon={{if this.useBacklist \"square-check\" \"square\"}} />\n  <span>{{@label}}</span>\n</button>", {"contents":"<button\n  aria-pressed={{if this.useBacklist \"true\" \"false\"}}\n  data-test-checkbox-backlist\n  disabled={{this.disabled}}\n  local-class=\"checkbox\"\n  type=\"button\"\n  ...attributes\n  {{on \"click\" this.onChange}}\n>\n  <FaIcon local-class=\"icon\" @icon={{if this.useBacklist \"square-check\" \"square\"}} />\n  <span>{{@label}}</span>\n</button>","moduleName":"@mvb/tix-ui/components/layout/header/backlist.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/header/backlist.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const BACKLIST_SEARCH_ROUTES = ['products.index', 'specials.index', 'notes-overview.index'];

export default class LayoutHeaderBacklistComponent extends Component {
  @service jump;
  @service router;
  @service user;

  get disabled() {
    return !this.user.canAccessBacklist || this.user.updateBacklistCheckedTask.isRunning;
  }

  get useBacklist() {
    let userCanAccessBacklist = this.user.canAccessBacklist;
    let useBacklist = userCanAccessBacklist && this.user.useBacklist;

    if (undefined !== this.args.useBacklist) {
      useBacklist = userCanAccessBacklist && this.args.useBacklist;
    }

    return useBacklist;
  }

  @action
  onChange() {
    let currentRouteName = this.router.currentRouteName;
    let useBacklist = !this.useBacklist;

    this.user.updateBacklistCheckedTask.perform(useBacklist);

    if (BACKLIST_SEARCH_ROUTES.includes(currentRouteName)) {
      this.router.transitionTo({ queryParams: { backlist: useBacklist } });
      this.jump.scrollToTop();
    }
  }
}
