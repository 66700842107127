import { action } from '@ember/object';
import { service } from '@ember/service';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Route from '@ember/routing/route';

export default class PreviewsPreviewIndexRoute extends Route {
  @service metrics;
  @service('search-products-in-preview') searchService;

  queryParams = {
    qp: {
      refreshModel: true,
    },
  };

  @action
  didTransition() {
    this.track();
    this.search();
  }

  async model() {
    return this.modelFor('previews.preview');
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.searchService.reset();
    }
  }

  search() {
    let model = this.modelFor(this.routeName).preview;
    let params = this.paramsFor(this.routeName);
    this.searchService.search(null, { model, replaceRoute: true }, queryParamsUnpack(params));
  }

  track() {
    let preview = this.modelFor(this.routeName).preview;
    let mvbId = preview.sender?.mvbId;

    this.metrics.trackSpecific({
      MVBIDPREVIEW: mvbId,
      area: 'Vorschauen',
      title: {
        'PREVIEW.MVBID': mvbId,
        'PREVIEW.NAME': preview.name,
        'PREVIEW.URLCODE': preview.id,
      },
    });
  }
}
