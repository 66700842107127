import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.groupedReferenceProducts}}\n  {{#each-in this.groupedReferenceProducts as |group items|}}\n    <Layout::Subtitle\n      @title={{cl \"51\" group}}\n      local-class=\"group-label\"\n      data-test-related-products-headline={{group}}\n    />\n\n    <div data-test-related-product-list={{group}}>\n      {{#each items as |referenceProduct index|}}\n        {{#if this.productInfos}}\n          <Product::ReferenceProducts::ReferenceProduct\n            @index={{index}}\n            @relatedProduct={{referenceProduct.productData}}\n          />\n        {{else}}\n          <div>\n            {{t \"listView.text.placeholder\"}}\n          </div>\n        {{/if}}\n      {{/each}}\n    </div>\n  {{/each-in}}\n{{/if}}", {"contents":"{{#if this.groupedReferenceProducts}}\n  {{#each-in this.groupedReferenceProducts as |group items|}}\n    <Layout::Subtitle\n      @title={{cl \"51\" group}}\n      local-class=\"group-label\"\n      data-test-related-products-headline={{group}}\n    />\n\n    <div data-test-related-product-list={{group}}>\n      {{#each items as |referenceProduct index|}}\n        {{#if this.productInfos}}\n          <Product::ReferenceProducts::ReferenceProduct\n            @index={{index}}\n            @relatedProduct={{referenceProduct.productData}}\n          />\n        {{else}}\n          <div>\n            {{t \"listView.text.placeholder\"}}\n          </div>\n        {{/if}}\n      {{/each}}\n    </div>\n  {{/each-in}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/reference-products/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/reference-products/index.hbs"}});
import { isEmpty } from '@ember/utils';
import { PUBLISHERS_SALES_EXPECTATION_CODE, SEARCH_MODE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export const REFERENCE_PRODUCT_REL_CODES = ['03', '05', '12', '22', '23', '30', '36'];

export default class ProductReferenceProductsComponent extends Component {
  @service errors;
  @service store;

  @tracked groupedReferenceProducts = [];

  constructor() {
    super(...arguments);
    this.fetchData.perform();
  }

  get productInfos() {
    return this.requestProductInfos.lastSuccessful?.value ?? [];
  }

  @task
  @waitFor
  *fetchData() {
    // determine all the reference products
    let allReferenceProducts = this.args.product.groupedAndSortedReferenceProducts;
    this.addSalesExpectations(allReferenceProducts);

    // get the corresponding infos and group them
    let productInfos = yield this.requestProductInfos.perform(allReferenceProducts);
    this.groupedReferenceProducts = this.mapReferenceProductsToGroup(allReferenceProducts, productInfos);
  }

  @task
  @waitFor
  *requestProductInfos(referenceProducts) {
    try {
      let batchSearchIds = this.getGtinsOfReferenceProducts(referenceProducts);
      batchSearchIds += this.getPublishersSalesExpectationGtins() ?? '';
      let searchResults = yield this.loadEntireRelatedProducts(batchSearchIds);

      return searchResults || [];
    } catch (error) {
      this.errors.handle(error);
    }
  }

  // load entire products to get the correct forms
  loadEntireRelatedProducts(batchSearchIds) {
    if (batchSearchIds) {
      let queryParams = {};

      queryParams.filter = {
        batchSearchIds,
        searchMode: SEARCH_MODE.BATCH_ARCH,
        backlist: true,
      };

      return this.store.query('search-product', queryParams);
    }
    return [];
  }

  // collect GTINs of all reference products into a comma-separated string
  getGtinsOfReferenceProducts(referenceProducts) {
    let gtins = '';
    let relationCodes = Object.keys(referenceProducts);

    for (let relationCode of relationCodes) {
      gtins += `${referenceProducts[relationCode].map((referenceProduct) => referenceProduct.productId).join(',')},`;
    }

    return gtins.slice(0, -1);
  }

  getPublishersSalesExpectationGtins() {
    if (isEmpty(this.args.product.publishersSalesExpectation)) {
      return null;
    }
    return `,${this.args.product.publishersSalesExpectation.join(',')}`;
  }

  addSalesExpectations(allReferenceProducts) {
    let salesExpectation = this.args.product.publishersSalesExpectation.map((gtin) => ({
      productId: gtin,
      productRelationCode: PUBLISHERS_SALES_EXPECTATION_CODE,
    }));

    allReferenceProducts[PUBLISHERS_SALES_EXPECTATION_CODE] = salesExpectation;
  }

  mapReferenceProductsToGroup(referenceProducts, productInfos) {
    for (let key of Object.keys(referenceProducts)) {
      for (let product of referenceProducts[key]) {
        product.productData = productInfos?.find((productInfo) => productInfo.identifier === product.productId) ?? null;
      }

      referenceProducts[key] = referenceProducts[key].filter(
        (referenceProduct) => !isEmpty(referenceProduct.productData)
      );

      if (isEmpty(referenceProducts[key])) {
        delete referenceProducts[key];
      }
    }
    return referenceProducts;
  }
}
