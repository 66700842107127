import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class PresentationService extends Service {
  @service router;

  @tracked allProducts = [];
  @tracked currentProduct = null;
  @tracked filteredProductIds = [];
  @tracked showAdditionalInfo = true;
  @tracked showOrderLines = true;
  @tracked showOverview = false;

  constructor() {
    super(...arguments);
    this.router.on('routeWillChange', this, this._routeWillChange);
  }

  willDestroy() {
    this.router.off('routeWillChange', this, this._routeWillChange);
    super.willDestroy(...arguments);
  }

  reset() {
    this.resetAdditionalInfo();
    this.resetOverview();
  }

  resetAdditionalInfo() {
    this.showAdditionalInfo = true;
  }

  resetOverview() {
    this.showOverview = false;
  }

  @action
  onToggleAdditionalInfo(isOpen) {
    this.showAdditionalInfo = isOpen;
  }

  toggleOrderLines(isOpen) {
    this.showOrderLines = isOpen;
  }

  _routeWillChange() {
    let {
      isDestroyed,
      isDestroying,
      router: { currentRouteName },
    } = this;

    if (
      isDestroying ||
      isDestroyed ||
      (!currentRouteName.startsWith('products') &&
        !currentRouteName.startsWith('specials') &&
        !currentRouteName.startsWith('presentations'))
    ) {
      return;
    }

    this.resetOverview();
  }
}
