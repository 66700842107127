import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label local-class=\"label\" for={{this.nameWithGuid}} data-test-textarea-label={{@name}}>\n  {{this.label}}\n</label>\n\n<Ui::Input::ChangesetErrors\n  @changeset={{@changeset}}\n  @errorsClass={{@errorsClass}}\n  @name={{@name}}\n  @path={{@path}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  as |hasErrors|\n>\n  <textarea\n    ...attributes\n    local-class=\"textarea {{if hasErrors 'has-error'}}\"\n    id={{this.nameWithGuid}}\n    name={{@name}}\n    placeholder={{@placeholder}}\n    value={{this.value}}\n    disabled={{@disabled}}\n    data-test-textarea={{@name}}\n    required={{@required}}\n    {{on \"input\" this.onChange}}\n    {{on \"keydown\" this.onKey}}\n  ></textarea>\n</Ui::Input::ChangesetErrors>", {"contents":"<label local-class=\"label\" for={{this.nameWithGuid}} data-test-textarea-label={{@name}}>\n  {{this.label}}\n</label>\n\n<Ui::Input::ChangesetErrors\n  @changeset={{@changeset}}\n  @errorsClass={{@errorsClass}}\n  @name={{@name}}\n  @path={{@path}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  as |hasErrors|\n>\n  <textarea\n    ...attributes\n    local-class=\"textarea {{if hasErrors 'has-error'}}\"\n    id={{this.nameWithGuid}}\n    name={{@name}}\n    placeholder={{@placeholder}}\n    value={{this.value}}\n    disabled={{@disabled}}\n    data-test-textarea={{@name}}\n    required={{@required}}\n    {{on \"input\" this.onChange}}\n    {{on \"keydown\" this.onKey}}\n  ></textarea>\n</Ui::Input::ChangesetErrors>","moduleName":"@mvb/tix-ui/components/ui/input/textarea.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/textarea.hbs"}});
import { action } from '@ember/object';
import UiInputBaseComponent from './base';

const ENTER_KEY = 13;

export default class UiInputTextareaComponent extends UiInputBaseComponent {
  @action
  onKey(event) {
    // Trigger a passed in @onSubmit action when the user presses shift & enter
    if (event.keyCode === ENTER_KEY && event.shiftKey && typeof this.args.onSubmit === 'function') {
      event.preventDefault();
      event.stopPropagation();
      this.args.onSubmit(event);
    }
  }
}
