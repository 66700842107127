import * as Sentry from '@sentry/ember';
import config from '@mvb/tix-ui/config/environment';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';

Sentry.init({
  release: config.APP.version,

  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'navigation') {
      let urlSearchParamsFrom = new URLSearchParams(breadcrumb.data?.from?.split('?')[1] ?? '');
      let urlSearchParamsTo = new URLSearchParams(breadcrumb.data?.to?.split('?')[1] ?? '');

      if (urlSearchParamsFrom.has('qp')) {
        breadcrumb.data.fromQp = queryParamsUnpack({ qp: urlSearchParamsFrom.get('qp') });
      }

      if (urlSearchParamsTo.has('qp')) {
        breadcrumb.data.toQp = queryParamsUnpack({ qp: urlSearchParamsTo.get('qp') });
      }
    }

    return breadcrumb;
  },

  beforeSend(event, hint) {
    // Ignoring these errors due to https://github.com/emberjs/ember.js/issues/12505
    // and https://github.com/emberjs/ember.js/issues/18416
    // via https://github.com/rust-lang/crates.io/blob/master/app/sentry.js
    if (hint?.originalException?.name === 'TransitionAborted') {
      return null;
    }

    return event;
  },
});
