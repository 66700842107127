import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Text\n  local-class={{if @hideArrows \"hide-arrows\"}}\n  ...attributes\n  @changeset={{@changeset}}\n  @disabled={{@disabled}}\n  @errorsClass={{@errorsClass}}\n  @hideLabel={{@hideLabel}}\n  @inlineErrors={{@inlineErrors}}\n  @label={{@label}}\n  @max={{@max}}\n  @min={{@min}}\n  @name={{@name}}\n  @onBeforeInput={{this.onBeforeInput}}\n  @onChange={{@onChange}}\n  @onSubmit={{@onSubmit}}\n  @path={{@path}}\n  @placeholder={{@placeholder}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @step={{@step}}\n  @type=\"number\"\n  @value={{this.value}}\n  {{on \"blur\" this.onBlur}}\n/>", {"contents":"<Ui::Input::Text\n  local-class={{if @hideArrows \"hide-arrows\"}}\n  ...attributes\n  @changeset={{@changeset}}\n  @disabled={{@disabled}}\n  @errorsClass={{@errorsClass}}\n  @hideLabel={{@hideLabel}}\n  @inlineErrors={{@inlineErrors}}\n  @label={{@label}}\n  @max={{@max}}\n  @min={{@min}}\n  @name={{@name}}\n  @onBeforeInput={{this.onBeforeInput}}\n  @onChange={{@onChange}}\n  @onSubmit={{@onSubmit}}\n  @path={{@path}}\n  @placeholder={{@placeholder}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n  @step={{@step}}\n  @type=\"number\"\n  @value={{this.value}}\n  {{on \"blur\" this.onBlur}}\n/>","moduleName":"@mvb/tix-ui/components/ui/input/number.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/number.hbs"}});
import { action } from '@ember/object';
import { assert, runInDebug } from '@ember/debug';
import { isNone } from '@ember/utils';
import UiInputBaseComponent from './base';

export default class UiInputNumberComponent extends UiInputBaseComponent {
  forcedStepRegex;

  countDecimalPlaces(number) {
    let decimalIndex = number.toString().indexOf('.');
    return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
  }

  setNumber(newValue) {
    // run super so the value on the changeset gets updated
    // super expects an event.target.value
    super.onChange({ target: { value: newValue } });
  }

  constructor() {
    super(...arguments);

    runInDebug(() => {
      if (this.args.forceStep === true) {
        assert(
          `Please pass the required @step argument to the UiInputNumberComponent component when forcing steps.`,
          this.args.step
        );
        assert(
          `Please pass a decimal number as a @step argument to the UiInputNumberComponent component when forcing steps.`,
          typeof this.args.step === 'number' && this.args.step.toString().includes('.')
        );
        assert(
          `Please pass a positive number as a @step argument to the UiInputNumberComponent component when forcing steps.`,
          typeof this.args.step === 'number' && this.args.step >= 0
        );
      }
    });

    if (this.args.forceStep === true) {
      this.forcedStepRegex = new RegExp(`^\\d+(\\.\\d{1,${this.countDecimalPlaces(this.args.step)}})?$`);
    }
  }

  get value() {
    if (isNone(this.args.value) && isNone(this.args.changeset)) {
      return 0;
    }
    return this.args.value;
  }

  @action
  onBeforeInput(event) {
    // since Chrome passes a dot (.) inside event.data even when a comma (,) is displayed, the following regex permits both
    // we do not want the user to be able so input a dot though, which is prevented by the last part of the condition
    if (
      typeof this.args.min === 'number' &&
      this.args.min >= 0 &&
      event.data &&
      (!/^\d*[,.]?\d*$/.test(event.data) || event.data === '.')
    ) {
      event.preventDefault();
    }
  }

  @action
  onBlur(event) {
    if (this.args.forceStep === true && !this.forcedStepRegex.test(event.target.value) && event.target.value !== '') {
      let newValue = 0;
      try {
        newValue = event.target.value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      } finally {
        event.target.value = newValue;
        this.setNumber(newValue);
      }
    }
    this.args.onBlur?.();
  }
}
