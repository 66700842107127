import { assert } from '@ember/debug';
import { helper } from '@ember/component/helper';

const ALLOWED_SIZES = new Set(['s', 'm', 'l', 'original']);

export class AssetURL extends URL {
  constructor(url, size, base) {
    super(url, base);

    this.size = size ?? 'l';
    this.url = url;
    this.setPathname(super.pathname);
  }

  get size() {
    return this._size;
  }

  get originalSize() {
    return this.url;
  }

  set size(value) {
    assert(`size "${value}" must be one of ${[...ALLOWED_SIZES.values()]}`, ALLOWED_SIZES.has(value));

    this._size = value;
    this.setPathname(super.pathname);
  }

  setPathname(pathname) {
    let segments = pathname.split('/');
    let pathPrefix = [];
    let pathSegments = [];
    let assetId;
    let nextIsAssetId = false;

    while (segments.length > 0) {
      let segment = segments.shift();

      switch (segment) {
        case '': {
          (pathPrefix.length === 0 ? pathPrefix : pathSegments).push(segment);
          break;
        }
        case 'api': {
          (pathPrefix.length === 1 ? pathPrefix : pathSegments).push(segment);
          break;
        }
        case 'v1': {
          (pathPrefix.length === 2 ? pathPrefix : pathSegments).push(segment);
          break;
        }
        case 'asset':
        case 'cover':
        case 'thumbnail': {
          (pathPrefix.length === 3 ? pathPrefix : pathSegments).push(segment);
          break;
        }
        case 'draft':
        case 'proprietary':
        case 'mmo':
        case 'tix': {
          (pathPrefix.length === 4 ? pathPrefix : pathSegments).push(segment);
          break;
        }
        case 'file': {
          (pathPrefix.length === 5 ? pathPrefix : pathSegments).push(segment);
          break;
        }
        default: {
          break;
        }
      }

      if (nextIsAssetId) {
        assetId = segment;
        break;
      }

      let isThumbnailAsset = pathPrefix.length === 4 && pathPrefix[pathPrefix.length - 1] === 'thumbnail';
      let isOtherAsset = pathPrefix.length === 6 && pathPrefix[pathPrefix.length - 1] === 'file';
      let isCoverGtin = pathPrefix.length === 5 && pathPrefix[pathPrefix.length - 1] === 'tix';

      if (isThumbnailAsset || isOtherAsset || isCoverGtin) {
        nextIsAssetId = true;
      }
    }

    // if we don't have an assetId (or gtin) this should return the url that was passed
    if (!assetId) {
      return;
    }

    this.assetId = assetId;
    this.pathPrefix = pathPrefix;

    let isCover = this.pathPrefix[3] === 'cover';
    let isDraft = this.pathPrefix[4] === 'draft';
    let isProprietary = this.pathPrefix[4] === 'proprietary';

    let supportsNativeSizes = isCover || isDraft || isProprietary;

    this.pathname =
      this.size === 'original'
        ? this.pathPrefix.concat([this.assetId]).join('/')
        : (supportsNativeSizes
            ? this.pathPrefix.concat([this.assetId, this.size])
            : ['api', 'v1', 'thumbnail', this.assetId, this.size]
          ).join('/');
  }
}

/**
 * Helper to generate a thumbnail url with the desired size for the image
 *
 * @param {String} url - current url of the image, holds infos for assetFileId and accessToken
 * @param {String} size - desired size for the image (S/M/L), defaults to L if nothing was passed
 */
export function thumbnailUrl([url, size]) {
  try {
    return new AssetURL(url, size);
  } catch {
    return url;
  }
}

export default helper(thumbnailUrl);
