export default {
  "color-primary": "var(--orange-100)",
  "color-text": "var(--gray-100)",
  "links": "_links_ew763o",
  "link-with-icon": "_link-with-icon_ew763o",
  "icon": "_icon_ew763o",
  "actions": "_actions_ew763o",
  "form": "_form_ew763o",
  "status": "_status_ew763o",
  "space-after": "_space-after_ew763o",
  "fieldset": "_fieldset_ew763o"
};
