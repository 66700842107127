export default {
  "button-edit": "_button-edit_k5pk64",
  "cell-wrapper": "_cell-wrapper_k5pk64",
  "centered": "_centered_k5pk64",
  "check": "_check_k5pk64",
  "delete": "_delete_k5pk64",
  "select": "_select_k5pk64",
  "default": "_default_k5pk64",
  "shared": "_shared_k5pk64"
};
