import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  instance.element?.removeEventListener('contextmenu', instance.onContextmenu);
}

export default class PreventContextmenuModifier extends Modifier {
  element;

  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  modify(element) {
    this.element?.removeEventListener('contextmenu', this.onContextmenu);
    element.addEventListener('contextmenu', this.onContextmenu);

    this.element = element;
  }

  @action
  onContextmenu(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
