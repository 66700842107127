export default {
  "upload": "_upload_wnzwge",
  "button": "_button_wnzwge",
  "table": "_table_wnzwge _table_1edbot",
  "thead": "_thead_wnzwge _thead_1edbot",
  "tbody": "_tbody_wnzwge _tbody_1edbot",
  "tr": "_tr_wnzwge _tr_1xynbj",
  "th": "_th_wnzwge _th_zg5hnr",
  "info": "_info_wnzwge"
};
