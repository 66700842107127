import Evented from '@ember/object/evented';
import Service from '@ember/service';

/*
  This is a simple service to trigger and receive events between different components.
  First, you need to initialize the eventBus in the component, where you want to trigger the event via '@service eventBus'
  After that, you can trigger an event via 'this.eventBus.trigger("<eventName>")'

  To receive this event in another component, you first to initialize it like above.
  Then you need to register this event in the constructor, e.g. 'this.eventBus.on("<eventName>", this, "<methodYouWantToCall>");'
  You also need to cancel the event subscription in the destructor, e.g. 'this.eventBus.off("<eventName>", this, "<methodYouWantToCall>");'
*/
export default class EventBusService extends Service.extend(Evented) {}
