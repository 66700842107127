import { isEmpty, isPresent } from '@ember/utils';
import ApplicationSerializer from './application';

function transformDateString(date) {
  if (isEmpty(date)) {
    return date;
  }

  if (date.includes('.')) {
    let dateArray = date.split('.');

    if (dateArray.length === 2) {
      return `${dateArray[1]}${dateArray[0]}`;
    } else if (dateArray.length === 3) {
      return `${dateArray[2]}${dateArray[1]}${dateArray[0]}`;
    }
  }

  // if nothing from the above matches, we assume it has the right format
  return date;
}

export default class DeliveryInfoSerializer extends ApplicationSerializer {
  normalize() {
    let payload = super.normalize(...arguments);
    let deliveryInfoAttributes = payload.data.attributes;

    // ensure that the FE always gets the following dates in one of these formats: yyyy, yyyyMM, yyyyMMdd
    payload.data.attributes.publicationDate = transformDateString(deliveryInfoAttributes.publicationDate);
    payload.data.attributes.availabilityDate = transformDateString(deliveryInfoAttributes.availabilityDate);

    return payload;
  }

  serialize(snapshot) {
    let payload = super.serialize(...arguments);
    let deliveryInfoAttributes = payload.data.attributes;

    // the BE expects a full date
    let onSaleDate = snapshot.record.onSaleDate;
    if (isPresent(onSaleDate) && typeof onSaleDate === 'object') {
      // as the datepicker does not provide time data like hours and minutes, with switching to UTC we go back a day
      // for the correct date we need to add the offset to the onSaleDate, so when switching to UTC we stay on the same day
      // bit hacky, but helps us with the actual selected date the BE needs to store
      let offsetInMinutes = onSaleDate.offset;
      payload.data.attributes.onSaleDate = onSaleDate.plus({ minute: offsetInMinutes }).toUTC().toISO();
    }

    // the BE expects a date in the following format: yyyy, yyyyMM, yyyyMMdd
    payload.data.attributes.publicationDate = transformDateString(deliveryInfoAttributes.publicationDate);
    payload.data.attributes.availabilityDate = transformDateString(deliveryInfoAttributes.availabilityDate);

    return payload;
  }
}
