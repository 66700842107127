import { didCancel, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';

export default class UserStateService extends Service {
  @service api;
  @service errors;
  @service session;

  get userState() {
    return this.loadTask?.lastSuccessful?.value;
  }

  get orderListProductsCount() {
    return this.userState?.orderListProductsCount ?? 0;
  }

  async load() {
    if (!this.session.isAuthenticated) {
      return;
    }

    try {
      await this.loadTask.perform();
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  @task
  @waitFor
  *loadTask() {
    try {
      return yield this.api.get('/user/user-state');
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
