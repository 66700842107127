import { assert, runInDebug } from '@ember/debug';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import sortBy from 'lodash-es/sortBy';

/**
 * Used in very specific scenarios when you need to get {party, mvbId, name, (and optionally premiumStatus)} of a `@hasMany` relationship on a party. e.g. displaying the `party.imprints` in a table. `assignedParties` is required as it will be updated with the mapped parties and there is no return from this function. This function will push an object with:
 * columns: dependent on which columns should be shown in the table,
 * name: name of the relationship, same as the `@name` parameter,
 * rows: the mapped relationships used to populate the table
 * @param {string} name - Name of the relationship - used to distinguish which relationship type is in the array of `rows`
 * @param {Party[]} [parties]  - The `@hasMany` relationship that we want to map
 * @param {Object[] | []} assignedParties - The focus of this function, this can be passed in as an empty array or a filled array, this function will map the passed in `parties` into an array (`rows`) of object { party, mvbId, name, status (depending on `@includeStatus` flags)}
 * @param {boolean} [includeStatus=false] -  set to true if you want the parties to be packed with its `premiumStatus`
 * @param {boolean} [calculateStatus=false] -  set to true if you want the `includeStatus` flag to be set depending on whether any `@parties` have a `premiumStatus`. i.e. if 1 party has a `premiumStatus`, pack the `premiumStatus` of all `@parties`
 * @returns undefined - The return of this function is unimportant, as the focus here is packing the passed in `@assignedParties` array
 */
export function packArray({
  name,
  parties,
  arrangedParties,
  includeStatus = false,
  calculateStatus = false,
  includeInactive = false,
}) {
  runInDebug(() => {
    assert(
      `arrangedParties must be passed in as an array to the 'packArray' function`,
      !!Array.isArray(arrangedParties)
    );
  });

  if (isEmpty(parties)) {
    return;
  }

  parties = parties ?? [];

  if (calculateStatus) {
    includeStatus = parties.some((party) => isPresent(party.get('premiumStatus')));
  }

  if (Array.isArray(parties)) {
    let rows = sortBy(
      parties.map((party) => ({
        party,
        mvbId: party.get('mvbId'),
        name: party.get('name'),
        status: includeStatus ? party.get('translatedPremiumStatus') : undefined,
      })),
      (party) => party.name.toLowerCase()
    );

    let columns = createColumns(includeStatus, includeInactive);

    return arrangedParties.push({ columns, name, rows });
  }
}

/**
 * Used in very specific scenarios when you need to get {party, mvbId, name, (and optionally premiumStatus)} of a `@belongsTo` relationship on a party. e.g. displaying the `party.parent` in a table. `assignedParties` is required as it will be updated with the mapped party and there is no return from this function. This function will push an object with:
 * columns: dependent on which columns should be shown in the table,
 * name: name of the relationship, same as the `@name` parameter,
 * rows: the mapped relationships used to populate the table
 * @param {string} name - Name of the relationship - used to distinguish which relationship type is in the array of `rows`
 * @param {PartyModel} [party]  - The `@belongsTo` relationship that we want to map
 * @param {Object[] | []} assignedParties - The focus of this function, this can be passed in as an empty array or a filled array, this function will map the passed in `party` into an array (`rows`) of object { party, mvbId, name, status (depending on `@includeStatus` flags)}
 * @param {boolean} [includeStatus=false] -  set to true if you want the party to be packed with its `premiumStatus`
 * @param {boolean} [calculateStatus=false] -  set to true if you want the `includeStatus` flag to be set depending on whether the `@party` has a `premiumStatus`. i.e. if the party has a `premiumStatus`, pack the `premiumStatus`
 * @returns undefined - The return of this function is unimportant, as the focus here is packing the passed in `@assignedParties` array
 */
export function packSingle({
  name,
  party,
  arrangedParties,
  includeStatus = false,
  calculateStatus = false,
  includeInactive = false,
}) {
  runInDebug(() => {
    assert(
      `arrangedParties must be passed in as an array to the 'packSingle' function`,
      !!Array.isArray(arrangedParties)
    );
  });

  // the party can be a proxy at this point, so we need to check the content as well as the party itself
  if (isNone(party) || isNone(party?.content)) {
    return;
  }

  if (calculateStatus) {
    includeStatus = isPresent(party.get('premiumStatus'));
  }

  let rows = [
    {
      party,
      mvbId: party.get('mvbId'),
      name: party.get('name'),
      status: includeStatus ? party.get('translatedPremiumStatus') : undefined,
    },
  ];

  let columns = createColumns(includeStatus, includeInactive);

  return arrangedParties.push({ columns, name, rows });
}

function createColumns(includeStatus, includeInactive) {
  let columns = ['mvbId', 'name'];
  if (includeStatus) {
    columns.push('status');
  }
  if (includeInactive) {
    columns = ['inactive', ...columns];
  }
  return columns;
}
