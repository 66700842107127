import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateEditorLinkUrl(options = {}) {
  return (key, newValue /*, oldValue, changes, content */) => {
    let intlService = applicationUtil.lookup('service:intl');
    let messageUrl = intlService.t('validation.editor.link');
    let expressionUrl =
      /(https?:\/\/(?:www\.|(?!www))[\da-z][\da-z-]+[\da-z]\.\S{2,}|www\.[\da-z][\da-z-]+[\da-z]\.\S{2,}|https?:\/\/(?:www\.|(?!www))[\da-z]+\.\S{2,}|www\.[\da-z]+\.\S{2,})/gi;
    let regex = new RegExp(expressionUrl);

    let allowBlank = options.allowBlank || false;

    if (
      (allowBlank && !newValue) ||
      ((newValue?.startsWith('https://') || newValue?.startsWith('http://')) && regex.test(newValue))
    ) {
      return true;
    }

    return buildMessage(key, {
      message: messageUrl,
    });
  };
}
