import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validatePreviewSeasonSeason(options = {}) {
  return (key, newValue, _oldValue, changes, content) => {
    let year = changes?.seasonYear ?? content?.seasonYear;

    if (!newValue && year) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
