import { didCancel, task, timeout } from 'ember-concurrency';
import { isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { validatePresence } from 'ember-changeset-validations/validators/index';
import { waitFor } from '@ember/test-waiters';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import Evented from '@ember/object/evented';
import Service, { service } from '@ember/service';
import validateEmailMultiple from '@mvb/tix-ui/validators/email-multiple';

export default class GroupOfRecipientsService extends Service.extend(Evented) {
  @service api;
  @service errors;
  @service intl;
  @service store;
  @service notifications;

  @tracked showEditSectionOfGroupMail = false;
  @tracked selectedGroups = [];
  @tracked blacklistedEmails = [];

  groupChangesets = createChangeset(
    {
      recipientGroup: '',
      recipientGroupEmails: '',
    },
    {
      recipientGroup: [
        validatePresence({ presence: true, description: this.intl.t('previewSendPreviewsModal.label.recipientGroup') }),
      ],
      recipientGroupEmails: [
        validatePresence({
          description: this.intl.t('previewSendPreviewsModal.label.recipientGroupEmails'),
          ignoreBlank: true,
          presence: true,
        }),
        validateEmailMultiple(),
      ],
    }
  );

  get selectedEmails() {
    // this needs to be separated otherwise the linter will try to combine it into a `flatMap` which breaks some of the tests
    let emails = this.lastSuccessfulValue.map((emailGroup) => {
      if (this.selectedGroups.includes(emailGroup.id)) {
        return emailGroup.emails.map((email) => email.trim());
      }
    });

    return emails.flat();
  }

  get lastSuccessfulValue() {
    return this.loadTask.lastSuccessful?.value ?? [];
  }

  @task
  @waitFor
  *loadTask() {
    try {
      return yield this.store.findAll('group-of-recipients');
    } catch (error) {
      this.errors.handle(error);
    }
  }

  async load() {
    this.showEditSectionOfGroupMail = false;
    this.selectedGroups = [];
    try {
      return this.loadTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  @task({ restartable: true })
  @waitFor
  *validateBlacklistedEmailsTask() {
    try {
      this.blacklistedEmails = [];

      let recipients = this.groupChangesets.get('recipientGroupEmails');
      let selectedGroupIds = [];

      if (isBlank(recipients) && isBlank(selectedGroupIds)) {
        return;
      }

      recipients = recipients.split(';').map((recipient) => recipient.trim());

      let response = yield this.api.postJSON('/previews/validate-recipients', {
        recipients,
        groupOfRecipientsIds: selectedGroupIds,
      });
      this.blacklistedEmails = response.blacklistedEmails ?? [];

      yield this.groupChangesets.validate();
      return response;
    } catch (error) {
      if (!didCancel(error)) {
        if (error.messages?.[0]?.code === 'error.recipients.tooMany') {
          this.groupChangesets.addError(
            'recipientGroupEmails',
            this.intl.t('previewSendPreviewsModal.validation.tooManyRecipients')
          );
        } else {
          this.errors.handle(error);
        }
      }
    }
  }

  @task
  @waitFor
  *saveGroup(saveGroup) {
    try {
      yield this.groupChangesets.validate();

      if (this.groupChangesets.isValid) {
        while (this.validateBlacklistedEmailsTask?.isRunning) {
          yield timeout(1);
        }

        if (!this.validateBlacklistedEmailsTask.lastSuccessful?.value) {
          return;
        }

        if (saveGroup.id) {
          //if it has a id then just update the record
          let group = yield this.store.findRecord('group-of-recipients', saveGroup.id);
          group.emails = saveGroup?.emails;
          group.name = saveGroup?.name;
          yield group.save();
        } else {
          let group = this.store.createRecord('group-of-recipients', {
            emails: saveGroup?.emails,
            name: saveGroup?.name,
          });
          yield group.save();
          this.load();
          this.groupChangesets.rollback();
        }
        this.showEditSectionOfGroupMail = false;
      }
      this.trigger('groupOfRecipientsSaved');
    } catch (error) {
      if (error.errors?.[0]?.code === 'error.recipients.tooMany') {
        this.groupChangesets.pushErrors(
          'recipientGroupEmails',
          this.intl.t('previewSendPreviewsModal.validation.tooManyRecipients')
        );
        this.notifications.error(this.intl.t('previewSendPreviewsModal.validation.tooManyRecipients'), {
          autoClear: true,
        });
      } else {
        this.errors.handle(error);
      }
    }
  }

  @task
  @waitFor
  *deleteGroup(groupId) {
    try {
      // fetch and delete the group-of-recipients
      let group = yield this.store.findRecord('group-of-recipients', groupId);
      group.destroyRecord();
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
