import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export class ProductLoadingError extends Error {}
export class ProductNotFoundError extends Error {}
export class SpecialLoadingError extends Error {}
export class SpecialNotFoundError extends Error {}

export function handleProductError(originalError) {
  let error = (originalError.errors ?? [])[0] ?? {};

  // not yet used by the BE
  if (error.code === 'error.product.notFound') {
    throw new ProductNotFoundError();
  }

  throw new ProductLoadingError();
}

export function handleSpecialError(originalError) {
  let error = (originalError.errors ?? [])[0] ?? {};

  // not yet used by the BE
  if (error.code === 'error.product.notFound') {
    throw new SpecialNotFoundError();
  }

  throw new SpecialLoadingError();
}

export default class ProductsProductRoute extends Route {
  @service router;
  @service store;

  // do not use "refreshModel: true" here, see:
  // https://github.com/emberjs/ember.js/issues/16921
  // https://github.com/emberjs/ember.js/issues/16992
  queryParams = {
    mvbId: { refreshModel: false },
    publishedStatus: { refreshModel: false },
    type: { refreshModel: false },
  };

  async model({ mvbId, product_id: productId, publishedStatus, type }) {
    let include = [
      'categoriesOfGoods',
      'contributors',
      'notes',
      'notes.creator',
      'notes.creator.avatar',
      'sections',
      'sections.contents',
      'sections.contents.file',
      'sections.contents.prices',
      'sections.deliveryInfo',
      'sections.productInfo',
      'supportingResources',
      'textContents',
    ].join(',');
    let product;

    try {
      if (mvbId) {
        let filter = { identifier: productId, identifierType: 'PROPRIETARY', publisherId: mvbId };
        if (publishedStatus === SECTION_STATUS.PUBLISHED) {
          filter.section = {
            status: publishedStatus,
          };
        }
        let products = await this.store.query('product', {
          filter,
          include,
        });
        product = products?.[0];
      } else if (type) {
        let filter = { identifier: productId, identifierType: type };
        if (publishedStatus === SECTION_STATUS.PUBLISHED) {
          filter.section = {
            status: publishedStatus,
          };
        }
        let products = await this.store.query('product', {
          filter,
          include,
        });
        product = products?.[0];
      } else {
        let options = { include, reload: true };
        if (publishedStatus === SECTION_STATUS.PUBLISHED) {
          options.adapterOptions = {
            query: {
              filter: {
                section: {
                  status: publishedStatus,
                },
              },
            },
          };
        }
        product = await this.store.findRecord('product', productId, options);
      }

      if (!product) {
        if (this.routeName.startsWith('specials') || type?.toLowerCase() === 'specials') {
          throw new SpecialNotFoundError();
        }
        throw new ProductNotFoundError();
      }

      return { product, productId, publishedStatus };
    } catch (error) {
      if (this.routeName.startsWith('specials') || type?.toLowerCase() === 'specials') {
        handleSpecialError(error);
      }
      handleProductError(error);
    }
  }

  afterModel(model) {
    let { mvbId, type } = this.paramsFor(this.routeName);

    if (mvbId || type || (model.productId !== undefined && model.productId !== model.product.id)) {
      this.router.replaceWith({ ...model, productId: model.product.id }, { queryParams: { mvbId: '', type: '' } });
      return;
    }

    if (this.routeName.startsWith('products') && model.product.isThemenspecial) {
      this.router.replaceWith('specials.product', { ...model, productId: model.product.id });
      return;
    }

    if (this.routeName.startsWith('specials') && !model.product.isThemenspecial) {
      this.router.replaceWith('products.product', { ...model, productId: model.product.id });
      return;
    }
  }

  serialize(model) {
    return {
      product_id: model.product.id,
    };
  }
}
