export default {
  "color-error": "var(--red-100)",
  "error-list": "_error-list_zhaoi9",
  "error-tooltip-error-list": "_error-tooltip-error-list_zhaoi9",
  "error": "_error_zhaoi9",
  "error-tooltip-error": "_error-tooltip-error_zhaoi9",
  "error-tooltip-container": "_error-tooltip-container_zhaoi9",
  "error-tooltip-container-number": "_error-tooltip-container-number_zhaoi9",
  "error-tooltip-container-select": "_error-tooltip-container-select_zhaoi9",
  "error-tooltip-trigger": "_error-tooltip-trigger_zhaoi9"
};
