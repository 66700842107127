import { camelize } from '@ember/string';
import { isEmpty } from '@ember/utils';
// https://github.com/emberjs/data/issues/8491
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import Service from 'ember-data/store';

export default class StoreService extends Service {
  addHasMany() {
    this.adapterFor('application').addHasMany(...arguments);
  }

  deleteHasMany() {
    this.adapterFor('application').deleteHasMany(...arguments);
  }

  /**
   * Updates records in the store without marking them as dirty.
   * Ignores records that are not in the store.
   *
   * @param {String} modelName the model type of the record that should be updated
   * @param {String} id the identifier of the record
   * @param {Object} attributes a POJO of attributes that should be updated
   */
  pushRecordAttributes(modelName, id, attributes) {
    if (this.peekRecord(modelName, id)) {
      return this.push({
        data: [
          {
            id,
            type: modelName,
            attributes,
          },
        ],
      });
    }
  }

  /**
   * Creates new records or updates exising ones in the store without marking them as dirty.
   *
   * @param {String} modelName the model type of the record that should be updated
   * @param {String} id the identifier of the record
   * @param {Object} attributes a POJO of attributes that should be updated
   * @param {Object} relationships a POJO of relationships belonging to the record
   */
  pushRecordAttributesWithRelationships(modelName, id, attributes, relationships = {}) {
    return this.push({
      data: [
        {
          id,
          type: modelName,
          attributes,
          relationships,
        },
      ],
    });
  }

  /**
   * @typedef {Object} prepareRelationshipOptions
   * @property {String} modelName the model type of the relationship
   * @property {String} id the id of the relationship
   * @property {String} relationshipName (optional) the name of the relationship (only needed if it differs from a camelized and pluralized version of the modelName)
   * @property {Object} relationships a POJO of relationships, defaults to an empty object
   * @property {Boolean} belongsTo indicates that the relationship to be prepared is a belongsTo relationship. if false (default) a hasMany relationship will be assumed
   */

  /**
   *
   * @param {prepareRelationshipOptions} options
   * @returns prepared and updated relationships POJO
   */
  prepareRelationship({ modelName, id, relationshipName, relationships = {}, belongsTo = false }) {
    // generate the relationshipName if none was passed
    if (isEmpty(relationshipName)) {
      relationshipName = camelize(modelName);
      if (!belongsTo) {
        relationshipName = `${relationshipName}s`;
      }
    }

    if (belongsTo) {
      // we have a belongs-to-relationship
      relationships[relationshipName] = {
        data: {
          id,
          type: modelName,
        },
      };
    } else {
      // we have a has-many-relationship
      if (Object.prototype.hasOwnProperty.call(relationships, relationshipName)) {
        relationships[relationshipName].data.push({
          id,
          type: modelName,
        });
      } else {
        relationships[relationshipName] = {
          data: [
            {
              id,
              type: modelName,
            },
          ],
        };
      }
    }

    return relationships;
  }

  /**
   * unloads only the records with no existing id for the passed types
   *
   * @param {*} types an array of modelTypes
   */
  unloadRecordsWithNoId(types = []) {
    for (let type of types) {
      let records = this.peekAll(type);
      for (let record of records) {
        if (isEmpty(record.id)) {
          this.unloadRecord(record);
        }
      }
    }
  }
}
