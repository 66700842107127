export default {
  "color-primary": "var(--orange-100)",
  "color-success": "var(--teal-100)",
  "form": "_form_ztwipc",
  "spacer": "_spacer_ztwipc",
  "captcha-validations": "_captcha-validations_ztwipc",
  "label": "_label_ztwipc _label_laqy2u",
  "name-wrapper": "_name-wrapper_ztwipc",
  "street-wrapper": "_street-wrapper_ztwipc",
  "city-wrapper": "_city-wrapper_ztwipc",
  "password-wrapper": "_password-wrapper_ztwipc",
  "blog-wrapper": "_blog-wrapper_ztwipc",
  "journalist-wrapper": "_journalist-wrapper_ztwipc",
  "additional-wrapper": "_additional-wrapper_ztwipc",
  "options": "_options_ztwipc",
  "gender-validations": "_gender-validations_ztwipc",
  "street-validations": "_street-validations_ztwipc",
  "zipcode-validations": "_zipcode-validations_ztwipc",
  "password-validations": "_password-validations_ztwipc",
  "blog-name-validations": "_blog-name-validations_ztwipc",
  "company-validations": "_company-validations_ztwipc",
  "firstname-validations": "_firstname-validations_ztwipc",
  "street-number-validations": "_street-number-validations_ztwipc",
  "city-validations": "_city-validations_ztwipc",
  "password-confirmed-validations": "_password-confirmed-validations_ztwipc",
  "blog-url-validations": "_blog-url-validations_ztwipc",
  "lastname-validations": "_lastname-validations_ztwipc",
  "premium-wrapper": "_premium-wrapper_ztwipc",
  "list-item": "_list-item_ztwipc",
  "icon": "_icon_ztwipc",
  "actions": "_actions_ztwipc",
  "radio": "_radio_ztwipc",
  "primary": "_primary_ztwipc"
};
