import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"edit-status\">\n  <Ui::Input::Select\n    data-test-list-view-edit-status-select\n    local-class=\"select\"\n    @disabled={{or this.setupTask.isRunning this.updateTask.isRunning}}\n    @label={{t \"listViewPreviewEditStatus.label.status\"}}\n    @name=\"edit-state\"\n    @onChange={{perform this.updateTask}}\n    @options={{this.options}}\n    @placeholder={{false}}\n    @value={{this.value}}\n  />\n\n  {{#if this.updateTask.isRunning}}\n    <FaIcon @icon=\"circle-notch\" @spin={{true}} />\n  {{/if}}\n</div>\n\n{{yield}}", {"contents":"<div local-class=\"edit-status\">\n  <Ui::Input::Select\n    data-test-list-view-edit-status-select\n    local-class=\"select\"\n    @disabled={{or this.setupTask.isRunning this.updateTask.isRunning}}\n    @label={{t \"listViewPreviewEditStatus.label.status\"}}\n    @name=\"edit-state\"\n    @onChange={{perform this.updateTask}}\n    @options={{this.options}}\n    @placeholder={{false}}\n    @value={{this.value}}\n  />\n\n  {{#if this.updateTask.isRunning}}\n    <FaIcon @icon=\"circle-notch\" @spin={{true}} />\n  {{/if}}\n</div>\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/list-view/preview/edit-status.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/list-view/preview/edit-status.hbs"}});
import { PREVIEW_EDIT_STATE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export default class ListViewPreviewEditStatusComponent extends Component {
  @service intl;
  @service store;
  @service user;

  constructor() {
    super(...arguments);

    this.setupTask.perform();
  }

  get options() {
    return Object.keys(PREVIEW_EDIT_STATE).map((key) => ({
      value: PREVIEW_EDIT_STATE[key],
      text: this.intl.t(`listViewPreviewEditStatus.option.${PREVIEW_EDIT_STATE[key]}`),
    }));
  }

  get value() {
    return this.setupTask.lastSuccessful?.value?.status ?? PREVIEW_EDIT_STATE.NEW;
  }

  @task
  @waitFor
  *setupTask() {
    let previewEditStatus = yield this.args.searchPreview.get('previewEditStatus');

    if (!previewEditStatus) {
      previewEditStatus = this.store.createRecord('preview-edit-status', {
        user: this.user.current,
        searchPreview: this.args.searchPreview,
      });
    }

    return previewEditStatus;
  }

  @task
  @waitFor
  *updateTask(value) {
    let previewStatus = yield this.setupTask.last;
    previewStatus.status = value;
    yield previewStatus.save();
  }
}
