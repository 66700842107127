import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span ...attributes local-class=\"indicator-wrapper\" tabindex=\"0\" data-test-delivery-state={{this.deliveryState}}>\n  <label\n    local-class=\"indicator {{if this.isAvailable 'available'}} {{if this.isLimited 'limited'}}\n    {{if this.isUnavailable 'unavailable'}}\"\n    data-test-delivery-state-label\n  >\n    {{#if @message}}\n      {{@message}}\n    {{else}}\n      {{cl \"65\" @code}}\n    {{/if}}\n  </label>\n</span>", {"contents":"<span ...attributes local-class=\"indicator-wrapper\" tabindex=\"0\" data-test-delivery-state={{this.deliveryState}}>\n  <label\n    local-class=\"indicator {{if this.isAvailable 'available'}} {{if this.isLimited 'limited'}}\n    {{if this.isUnavailable 'unavailable'}}\"\n    data-test-delivery-state-label\n  >\n    {{#if @message}}\n      {{@message}}\n    {{else}}\n      {{cl \"65\" @code}}\n    {{/if}}\n  </label>\n</span>","moduleName":"@mvb/tix-ui/components/product/delivery-state/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/delivery-state/index.hbs"}});
import { AVAILABILITY } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class ProductDeliveryStateComponent extends Component {
  get isAvailable() {
    return AVAILABILITY.AVAILABLE === this.args.availabilityStatus;
  }

  get isLimited() {
    return AVAILABILITY.LIMITED === this.args.availabilityStatus;
  }

  get isUnavailable() {
    return AVAILABILITY.UNAVAILABLE === this.args.availabilityStatus;
  }

  get deliveryState() {
    return this.args.availabilityStatus || 'undefined';
  }
}
