import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes local-class=\"wrapper\">\n  <button\n    data-test-cover\n    local-class=\"cover\"\n    type=\"button\"\n    {{on \"click\" (fn @didSelectAsset this.firstImage.assetFileId)}}\n  >\n    <img\n      src={{thumbnail-url this.firstImage.exportedLink}}\n      alt={{t \"productImages.text.cover\"}}\n      width={{this.firstImage.imageWidth}}\n      height={{this.firstImage.imageHeight}}\n      {{on \"error\" this.onError}}\n      {{prevent-contextmenu}}\n    />\n  </button>\n\n  {{#if this.thumbnails}}\n    <Product::Images::Thumbnails @thumbnails={{this.thumbnails}} @didSelectAsset={{@didSelectAsset}} />\n  {{/if}}\n</div>\n\n{{#if @selectedAsset}}\n  {{open-modal this.ModalProductImages this close=this.onClose}}\n{{/if}}", {"contents":"<div ...attributes local-class=\"wrapper\">\n  <button\n    data-test-cover\n    local-class=\"cover\"\n    type=\"button\"\n    {{on \"click\" (fn @didSelectAsset this.firstImage.assetFileId)}}\n  >\n    <img\n      src={{thumbnail-url this.firstImage.exportedLink}}\n      alt={{t \"productImages.text.cover\"}}\n      width={{this.firstImage.imageWidth}}\n      height={{this.firstImage.imageHeight}}\n      {{on \"error\" this.onError}}\n      {{prevent-contextmenu}}\n    />\n  </button>\n\n  {{#if this.thumbnails}}\n    <Product::Images::Thumbnails @thumbnails={{this.thumbnails}} @didSelectAsset={{@didSelectAsset}} />\n  {{/if}}\n</div>\n\n{{#if @selectedAsset}}\n  {{open-modal this.ModalProductImages this close=this.onClose}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/images/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/images/index.hbs"}});
import { action } from '@ember/object';
import { ASSET_MODE_CODES, ASSET_TYPE_CODES, FALLBACK_COVER_URL } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ModalProductImages from '@mvb/tix-ui/components/ui/modal/images';
import sortBy from 'lodash-es/sortBy';

export default class ProductImagesComponent extends Component {
  @service intl;

  ModalProductImages = ModalProductImages;

  showImageInfo = true;
  showPositionInTitle = true;
  title = this.intl.t('productImages.text.modalTitle');

  get selectedAssetFileId() {
    return this.args.selectedAsset ?? null;
  }

  get assets() {
    let interiors = this.filteredAssets.filter((asset) => asset.resourceContentType === ASSET_TYPE_CODES.INTERIOR);
    let productImages = this.filteredAssets.filter((asset) => asset.resourceContentType != ASSET_TYPE_CODES.INTERIOR);

    return [...productImages, ...interiors];
  }

  get filteredAssets() {
    // we only filter for the assets needed for the cover component and gallery modal
    let assets = this.args.product?.supportingResources ?? [];

    let filteredAssets = assets.filter(
      (asset) =>
        asset.resourceMode === ASSET_MODE_CODES.IMAGE &&
        [
          ASSET_TYPE_CODES.BACKCOVER,
          ASSET_TYPE_CODES.MORECOVER,
          ASSET_TYPE_CODES.IMAGES,
          ASSET_TYPE_CODES.INTERIOR,
        ].includes(asset.resourceContentType)
    );
    filteredAssets = sortBy(filteredAssets, ['resourceContentType', 'sequence']);

    // add gallery images
    filteredAssets.push(...this.galleryFilesAsInteriorAssets);

    // add wrapper images
    let assetsWrapper = assets.filter(
      (asset) => asset.resourceMode === ASSET_MODE_CODES.IMAGE && asset.resourceContentType === ASSET_TYPE_CODES.WRAPPER
    );

    filteredAssets.push(...sortBy(assetsWrapper, ['sequence']));

    // add the cover to the images on first position
    filteredAssets.unshift(
      this.args.product?.cover ?? {
        resourceContentType: ASSET_TYPE_CODES.COVER,
        exportedLink: FALLBACK_COVER_URL,
        assetFileId: 'no-cover-available',
        imageHeight: 280,
        imageWidth: 200,
      }
    );

    return filteredAssets;
  }

  get firstImage() {
    return this.assets[0].resourceContentType === ASSET_TYPE_CODES.COVER ? this.assets[0] : '';
  }

  get galleryFilesAsInteriorAssets() {
    return this.args.product.galleryFiles.map((file) => {
      return {
        assetFileId: file.id,
        exportedLink: file.downloadUrl,
        imageHeight: file.imageHeight,
        imageWidth: file.imageWidth,
        resourceContentType: ASSET_TYPE_CODES.INTERIOR,
      };
    });
  }

  get thumbnails() {
    return this.assets.splice(1);
  }

  @action
  onClose() {
    this.args.didSelectAsset?.(null);
  }

  @action
  onError(event) {
    event.target.src = FALLBACK_COVER_URL;
  }
}
