import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ProfileIndexRoute extends Route {
  @service router;

  redirect() {
    return this.router.replaceWith('profile.user');
  }
}
