import { all } from 'ember-concurrency';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PreviewListsRoute extends Route {
  @service abilities;
  @service branches;
  @service collectionOfGoods;
  @service dispoLists;
  @service session;

  async beforeModel(transition) {
    if (!this.session.requireAuthentication(transition)) {
      return;
    }

    if (this.abilities.can('dispoList.viewDispoListsInProductPages')) {
      await all([this.branches.load(), this.dispoLists.load(), this.collectionOfGoods.load()]);
    }
  }
}
