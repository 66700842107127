import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{page-title (if @title @title (t \"loading.title\"))}}\n\n{{#if this.hasProgressOverlay}}\n  <div data-test-placeholder {{did-insert this.scrollToTop}}></div>\n{{else}}\n  <Layout::Loading local-class=\"loading\" @message={{@message}} {{did-insert this.scrollToTop}} />\n{{/if}}", {"contents":"{{page-title (if @title @title (t \"loading.title\"))}}\n\n{{#if this.hasProgressOverlay}}\n  <div data-test-placeholder {{did-insert this.scrollToTop}}></div>\n{{else}}\n  <Layout::Loading local-class=\"loading\" @message={{@message}} {{did-insert this.scrollToTop}} />\n{{/if}}","moduleName":"@mvb/tix-ui/components/layout/loading-route/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/loading-route/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class LayoutLoadingRouteComponent extends Component {
  @service jump;
  @service progress;

  get hasProgressOverlay() {
    return this.progress.queue.length > 0;
  }

  @action
  scrollToTop() {
    this.jump.scrollToTop();
  }
}
