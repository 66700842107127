export default {
  "header": "_header_1oxptp",
  "link-premium": "_link-premium_1oxptp",
  "logo": "_logo_1oxptp",
  "logo-bar": "_logo-bar_1oxptp container",
  "account-list": "_account-list_1oxptp reset-list",
  "dropdown-trigger": "_dropdown-trigger_1oxptp",
  "active": "_active_1oxptp",
  "nav": "_nav_1oxptp",
  "nav-header": "_nav-header_1oxptp",
  "flush-top": "_flush-top_1oxptp",
  "nav-list": "_nav-list_1oxptp _nav-list_13rxu8 container",
  "nav-list-offset": "_nav-list-offset_1oxptp",
  "nav-list-link": "_nav-list-link_1oxptp _nav-list-link_13rxu8",
  "nav-mobile-trigger": "_nav-mobile-trigger_1oxptp reset-button",
  "nav-mobile-content": "_nav-mobile-content_1oxptp",
  "nav-mobile-login": "_nav-mobile-login_1oxptp",
  "header-backoffice": "_header-backoffice_1oxptp",
  "search-bar": "_search-bar_1oxptp",
  "nav-backoffice": "_nav-backoffice_1oxptp"
};
