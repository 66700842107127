import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (or (not-eq @existingErpReferencedProducts.length 0) (not-eq @existingSuggestedProducts.length 0))}}\n  <Layout::Divider local-class=\"divider\" />\n{{/if}}\n<Ui::Input::Text\n  @changeset={{this.changeset}}\n  @isResettable={{true}}\n  @label={{t \"wawiBox.referencedProductAdd.title\"}}\n  @name=\"isbn\"\n  @onClear={{changeset-set this.changeset \"isbn\" null}}\n  @path=\"isbn\"\n  @clear={{true}}\n  @showErrorsForUnchanged={{true}}\n  @placeholder={{t \"wawiBox.referencedProductAdd.placeholder\"}}\n/>\n\n<div local-class=\"check-button-wrapper\">\n  <Ui::Button @onClick={{this.checkProductIsbnAndAddProduct}} data-test-referenced-product-check-and-add>\n    {{t \"wawiBox.referencedProductAdd.action.checkAndAdd\"}}\n  </Ui::Button>\n</div>", {"contents":"{{#if (or (not-eq @existingErpReferencedProducts.length 0) (not-eq @existingSuggestedProducts.length 0))}}\n  <Layout::Divider local-class=\"divider\" />\n{{/if}}\n<Ui::Input::Text\n  @changeset={{this.changeset}}\n  @isResettable={{true}}\n  @label={{t \"wawiBox.referencedProductAdd.title\"}}\n  @name=\"isbn\"\n  @onClear={{changeset-set this.changeset \"isbn\" null}}\n  @path=\"isbn\"\n  @clear={{true}}\n  @showErrorsForUnchanged={{true}}\n  @placeholder={{t \"wawiBox.referencedProductAdd.placeholder\"}}\n/>\n\n<div local-class=\"check-button-wrapper\">\n  <Ui::Button @onClick={{this.checkProductIsbnAndAddProduct}} data-test-referenced-product-check-and-add>\n    {{t \"wawiBox.referencedProductAdd.action.checkAndAdd\"}}\n  </Ui::Button>\n</div>","moduleName":"@mvb/tix-ui/components/wawi-box/referenced-product-add/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/wawi-box/referenced-product-add/index.hbs"}});
import { action } from '@ember/object';
import {
  PREVIEW_TYPE,
  PRODUCT_FILTER_TO_IGNORE,
  PRODUCTS_UPLOAD_TARGET,
  URL_SEARCH_AND_VALIDATE_PRODUCTS,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { validatePresence } from 'ember-changeset-validations/validators';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';

export default class ReferencedProductAddComponent extends Component {
  @service api;
  @service errors;
  @service intl;

  constructor() {
    super(...arguments);
    this.changeset = createChangeset(
      { isbn: '' },
      {
        isbn: validatePresence({
          message: this.intl.t('wawiBox.referencedProductAdd.validation.empty'),
          ignoreBlank: true,
          presence: true,
        }),
      }
    );
  }

  @action
  async checkProductIsbnAndAddProduct() {
    if (typeof this.changeset.get('isbn') != 'string') {
      this.changeset.set('isbn', '');
    }

    await this.changeset.validate();

    if (this.changeset.isInvalid) {
      return;
    }

    let result = await this.searchAndValidateIsbn.perform();

    let hasError = this.hasError(result[0]);

    if (hasError) {
      this.changeset.pushErrors('isbn', this.intl.t('wawiBox.referencedProductAdd.validation.invalid'));
      return;
    }

    if (
      this.args.existingErpReferencedProducts.some(
        (referenceProduct) => referenceProduct.referencedProduct.id === result[0].product.id
      )
    ) {
      this.changeset.pushErrors('isbn', this.intl.t('wawiBox.referencedProductAdd.validation.alreadyPresent'));
      return;
    }

    this.changeset.rollback();

    this.args.updateNewAddedReferencedProducts(result[0]);
  }

  @task({ restartable: true })
  @waitFor
  *searchAndValidateIsbn() {
    let isbn = this.changeset.get('isbn');
    try {
      return yield this.api.postJSON(
        URL_SEARCH_AND_VALIDATE_PRODUCTS,
        {
          identifiers: [isbn],
        },
        {},
        {
          target: PRODUCTS_UPLOAD_TARGET.PREVIEW,
          targetPreviewType: PREVIEW_TYPE.INDIVIDUALVORSCHAU,
          filterToIgnore: [
            PRODUCT_FILTER_TO_IGNORE.PRODUCT_FILTER_UNASSIGNED_PUBLISHER,
            PRODUCT_FILTER_TO_IGNORE.PRODUCT_FILTER_NON_PREMIUM,
            PRODUCT_FILTER_TO_IGNORE.PRODUCT_FILTER_IS_INACTIVE,
            PRODUCT_FILTER_TO_IGNORE.PRODUCT_FILTER_IS_SPECIAL,
          ],
        }
      );
    } catch (error) {
      this.errors.handle(error);
    }
  }

  hasError(response) {
    return !!response.cause;
  }
}
