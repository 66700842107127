import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import ENV from '@mvb/tix-ui/config/environment';

// this validator checks if a party with the given mvbId already exists
export default function MvbIdDoesNotExistValidator(options = {}) {
  return async (key, newValue /*, oldValue, changes, content*/) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let apiService = applicationUtil.lookup('service:api');
    let intlService = applicationUtil.lookup('service:intl');
    let errorsService = applicationUtil.lookup('service:errors');

    let mvbId = newValue?.trim();
    // return quickly if there's no value or the mvbId is too small/big, saves on API calls
    if (!mvbId || mvbId.length < ENV.APP.minMvbIdLength || mvbId.length > ENV.APP.maxMvbIdLength) {
      return true;
    }

    let response;
    try {
      response = await apiService.get(`/jsonapi/parties?filter[mvbId]=${mvbId}`);
      if (response.data.length > 0) {
        return buildMessage(key, { type: 'mvbIdExists', mvbId, context: options });
      }
    } catch (error) {
      errorsService.handle(error);
      return intlService.t('error.generic');
    }

    return true;
  };
}
