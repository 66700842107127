import { all, task } from 'ember-concurrency';
import { CUSTOM_MARKETING_CONTENT_KEY } from '@mvb/tix-ui/constants';
import { isEmpty, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { validateLength } from 'ember-changeset-validations/validators/index';
import { waitFor } from '@ember/test-waiters';
import CustomMarketingContentBaseService from '@mvb/tix-ui/services/custom-marketing-content-base';
import positiveIntegerWithDecimal from '@mvb/tix-ui/validators/positive-integer-with-decimal';
import validateThaliaPackagingUnit from '@mvb/tix-ui/validators/thalia-packaging-unit';

export default class CustomMarketingContentThaliaService extends CustomMarketingContentBaseService {
  @service intl;

  // overwrite abstract method from base service
  getDataForChangeset(model, isNew = false) {
    let {
      id,
      category,
      display,
      files,
      keywords,
      mediaIndex,
      note,
      orderQuantityUnit,
      packagingEan,
      packagingUnit,
      recommendedOrderAmount,
      recommendedOrderAmountMarketingExpectation,
      recommendedOrderAmountPotential,
      seasonCode,
      sortedListingRecommendations: listing,
      sortedMarketingRecommendations: marketing,
      sortedMarketingRecommendationsTrc: marketingTrc,
      tags,
      trcCategoryOfGoods,
      type,
    } = model;

    return {
      category: category?.get('code'),
      display,
      files: files ?? [],
      hasComparativeProducts: false, // component for comparative products handles the state of this, therefore defaults to false --> TODO: refactor with #30180
      id: isNew ? null : id,
      isNew,
      keywords: keywords ?? [],
      listingRecommendations: isEmpty(listing)
        ? '[]'
        : listing.filter(Boolean).map((recommendation) => recommendation.code),
      marketingRecommendations: isEmpty(marketing)
        ? '[]'
        : marketing.filter(Boolean).map((recommendation) => recommendation.code),
      trcMarketingRecommendations: isEmpty(marketingTrc)
        ? '[]'
        : marketingTrc.filter(Boolean).map((recommendation) => recommendation.code),
      mediaIndex,
      note,
      orderQuantityUnit,
      packagingEan,
      packagingUnit,
      recommendedOrderAmount,
      recommendedOrderAmountMarketingExpectation,
      recommendedOrderAmountPotential,
      seasonCode,
      tags: tags ?? [],
      trcCategoryOfGoods,
      type,
    };
  }

  // overwrite abstract method from base service
  getValidationsForChangeset() {
    return {
      recommendedOrderAmount: [positiveIntegerWithDecimal(), validateLength(this.maxLengthValidation)],
      recommendedOrderAmountMarketingExpectation: [
        positiveIntegerWithDecimal(),
        validateLength(this.maxLengthValidation),
      ],
      recommendedOrderAmountPotential: [positiveIntegerWithDecimal(), validateLength(this.maxLengthValidation)],
      packagingUnit: [
        validateThaliaPackagingUnit(),
        validateLength({
          max: 5,
          description: this.intl.t('previewAdditionalInformationThalia.label.packagingUnit'),
        }),
      ],
    };
  }

  // overwrite abstract method from base service
  getValidationsForComparativeProductChangeset() {
    let validator = [positiveIntegerWithDecimal(), validateLength(this.maxLengthValidation)];
    return {
      salesLast1Month: validator,
      salesLast2Months: validator,
      salesLast3Months: validator,
      salesLast6Months: validator,
      acquisition3Months: validator,
      fullAcquisition: validator,
    };
  }

  // overwrite abstract task from base service
  @task
  @waitFor
  *saveRelatedEntitiesTask(model, recordsToSave) {
    //TODO should be done in chunks --> see ticket #30151
    let cleanedUpRecordsToSave = this.cleanupRecordsToSave(recordsToSave);
    yield all(
      cleanedUpRecordsToSave.map((record) => (record.get('markedForDeletion') ? record.destroyRecord() : record.save()))
    );
  }

  // overwrite abstract task from base service
  @task
  @waitFor
  *updateRecordsAndModelForSavingTask(changeset, model, recordsToSave) {
    try {
      for (let change of changeset.changes) {
        let { key, value } = change;

        switch (key) {
          case CUSTOM_MARKETING_CONTENT_KEY.DISPLAY:
          case CUSTOM_MARKETING_CONTENT_KEY.MEDIA_INDEX:
          case CUSTOM_MARKETING_CONTENT_KEY.PACKAGING_EAN:
          case CUSTOM_MARKETING_CONTENT_KEY.PACKAGING_UNIT:
          case CUSTOM_MARKETING_CONTENT_KEY.ORDER_QUANTITY_UNIT:
          case CUSTOM_MARKETING_CONTENT_KEY.RECOMMENDED_ORDER_AMOUNT_MARKETING_EXPECTATION:
          case CUSTOM_MARKETING_CONTENT_KEY.RECOMMENDED_ORDER_AMOUNT_POTENTIAL:
          case CUSTOM_MARKETING_CONTENT_KEY.SEASON_CODE:
          case CUSTOM_MARKETING_CONTENT_KEY.TRC_CATEGORY_OF_GOODS: {
            // here the key is named the same way as the fields in the model
            // therefore we can set them directly as key
            model.set(key, value);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.CATEGORY: {
            let category = this.getCategoryCode(value);
            model.set('category', isPresent(category) ? category : null);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.KEYWORDS: {
            let keywords = Array.isArray(value) ? value : value?.split(',');
            model.set(key, keywords);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.LISTING_RECOMMENDATIONS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'listing-recommendation'
            );
            recordsToSave.push(...recommendations);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.MARKETING_RECOMMENDATIONS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'marketing-recommendation'
            );
            recordsToSave.push(...recommendations);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.MARKETING_RECOMMENDATIONS_TRC: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'marketing-recommendation-trc',
              'trcMarketingRecommendations'
            );
            recordsToSave.push(...recommendations);
            break;
          }
          default: {
            break;
          }
        }
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
