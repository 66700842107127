import { attr } from '@ember-data/model';
import Model from './-base';

export default class ErpModel extends Model {
  @attr() qualifier;
  @attr() name;
  @attr() setupType; // ERP_SETUP_TYPES
  @attr('boolean') manageOrderChannels;
  @attr() orderFileFormat;
}
