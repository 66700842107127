import * as Sentry from '@sentry/browser';
import { action } from '@ember/object';
import { runInDebug } from '@ember/debug';
import ENV from '@mvb/tix-ui/config/environment';
import Service, { service } from '@ember/service';

export default class ErrorsService extends Service {
  @service intl;
  @service notifications;
  @service session;

  // we make this a property of the service so that it can be overridden in tests if needed
  sentry = Sentry;

  // log non-intentional/unrecognized backend errors, show generic notification & log to Sentry
  handleGenericError(error, { showNotification, skipSentry } = {}) {
    showNotification = showNotification ?? true;
    skipSentry = skipSentry ?? false;

    if (showNotification) {
      this.notifications.error(this.intl.t('error.generic'), { autoClear: false });
    }

    if (!skipSentry) {
      this.sentry.captureException(error);
    }

    /* eslint-disable no-console */
    runInDebug(() => ENV.environment !== 'test' && console.error('errors.handleGenericError', error));
    /* eslint-enable no-console */
  }

  @action
  handle(error, { showNotification, skipSentry } = {}) {
    // much easier test debugging
    if (ENV.environment === 'test') {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    error = error ?? new Error('Unknown error');
    showNotification = showNotification ?? true;
    skipSentry = skipSentry ?? false;

    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    // terminate session on unauthenticated requests
    if (error.status === 401) {
      return this.session.invalidate();
    }

    if (error.messages?.length) {
      try {
        let triggerNotification = showNotification;

        for (let { code, detail, id, meta } of error.messages) {
          let key = [code, detail, id].find((k) => this.intl.exists(k));

          if (key) {
            if (triggerNotification) {
              this.notifications.error(this.intl.t(key, meta ?? {}), { autoClear: false });
            }
          } else {
            this.handleGenericError(error, { showNotification: triggerNotification });
            triggerNotification = false;
          }
        }

        return;
      } catch (error_) {
        // handle gracefully in case something goes wrong with our error handling
        this.handleGenericError(error_, { skipSentry, showNotification });
      }
    }

    this.handleGenericError(error, { skipSentry, showNotification });
  }

  /**
   *
   * @param error
   * @param condition Function which returns a boolean
   * @param cb Callback fired if condition is true
   */
  @action
  handleConditionally(error, condition, callback) {
    if (condition) {
      return callback();
    }

    this.handle(error);
  }
}
