export default {
  "wrapper": "_wrapper_8nb0oo",
  "content": "_content_8nb0oo",
  "actions": "_actions_8nb0oo",
  "product-row": "_product-row_8nb0oo",
  "delete-wrapper": "_delete-wrapper_8nb0oo",
  "delete-button": "_delete-button_8nb0oo",
  "loading": "_loading_8nb0oo",
  "loading-sug-prod": "_loading-sug-prod_8nb0oo",
  "not-found-error": "_not-found-error_8nb0oo",
  "product-wrapper": "_product-wrapper_8nb0oo",
  "title": "_title_8nb0oo"
};
