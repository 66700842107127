import { service } from '@ember/service';
import Ability from './-base';

export default class NoteAbility extends Ability {
  @service features;
  @service user;

  get access() {
    return this.user.current?.hasAssignedParties ?? false;
  }

  get accessNotesOverview() {
    return this.features.isEnabled('notes-overview') && this.access;
  }

  get edit() {
    return this.user.current?.id === this.model.get('creator.id');
  }
}
