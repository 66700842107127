import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { TIX_LIST_TYPE } from '@mvb/tix-ui/constants';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import ModalCreateAndEdit from '@mvb/tix-ui/components/lists/modal/create-and-edit';

export default class ProductListsIndexController extends Controller {
  @service intl;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service('search-product-lists') searchService;
  @service store;
  // used in template
  @service user;

  queryParams = ['qp'];
  @tracked qp = '';

  @action
  async createList() {
    let newList = this.store.createRecord('list', { listType: TIX_LIST_TYPE.PRODUCT_LIST });
    let result = await this.modals.open(ModalCreateAndEdit, {
      list: newList,
      title: this.intl.t('productLists.modal.titleCreate'),
      labelForName: this.intl.t('productLists.modal.labelForName'),
    });

    if (!result) {
      newList.unloadRecord();
      return;
    }

    let progress = this.progress.add({
      message: this.intl.t('productLists.modal.textProgress'),
    });

    try {
      await result.changeset.save();

      progress.remove();
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }

    await this.router.refreshCurrentRoute();
  }

  @action
  deleteAllLists() {}

  @task({ drop: true })
  @waitFor
  *updateListDefaultStateTask(list, setAsDefault) {
    list.set('defaultByUser', setAsDefault);

    yield list.save();
    yield this.router.refreshCurrentRoute();

    let notificationMessage = setAsDefault
      ? this.intl.t('productLists.notification.setAsDefaultSuccess')
      : this.intl.t('productLists.notification.unsetAsDefaultSuccess');

    this.notifications.success(notificationMessage);
  }

  @action
  reloadPage() {
    return this.router.refreshCurrentRoute();
  }
}
