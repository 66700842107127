import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::ChangesetErrors\n  @changeset={{@changeset}}\n  @errorsClass={{@errorsClass}}\n  @name={{@name}}\n  @path={{@path}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n>\n  {{#if this.showCaptcha}}\n    <GRecaptcha\n      ...attributes\n      data-test-captcha\n      id={{this.nameWithGuid}}\n      name={{@name}}\n      @onExpired={{this.onCaptchaExpired}}\n      @onRender={{this.onCaptchaRendered}}\n      @onSuccess={{this.onCaptchaResolved}}\n      @size={{@size}}\n      @skip={{not this.showCaptcha}}\n    />\n  {{/if}}\n</Ui::Input::ChangesetErrors>", {"contents":"<Ui::Input::ChangesetErrors\n  @changeset={{@changeset}}\n  @errorsClass={{@errorsClass}}\n  @name={{@name}}\n  @path={{@path}}\n  @required={{@required}}\n  @showErrorsForUnchanged={{@showErrorsForUnchanged}}\n>\n  {{#if this.showCaptcha}}\n    <GRecaptcha\n      ...attributes\n      data-test-captcha\n      id={{this.nameWithGuid}}\n      name={{@name}}\n      @onExpired={{this.onCaptchaExpired}}\n      @onRender={{this.onCaptchaRendered}}\n      @onSuccess={{this.onCaptchaResolved}}\n      @size={{@size}}\n      @skip={{not this.showCaptcha}}\n    />\n  {{/if}}\n</Ui::Input::ChangesetErrors>","moduleName":"@mvb/tix-ui/components/ui/input/captcha.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/input/captcha.hbs"}});
import { action } from '@ember/object';
import { assert, runInDebug } from '@ember/debug';
import ENV from '@mvb/tix-ui/config/environment';
import UiInputBaseComponent from './base';

export default class UiInputCaptchaComponent extends UiInputBaseComponent {
  constructor() {
    super(...arguments);

    runInDebug(() => {
      assert(
        `Please pass the required @changeset argument to the UiInputCaptchaComponent component`,
        this.args.changeset
      );
      assert(`Please pass the required @path argument to the UiInputCaptchaComponent component`, this.args.path);
    });
  }

  get showCaptcha() {
    return ENV.environment !== 'test' && ENV.APP.TARGET !== 'e2e';
  }

  @action
  onCaptchaResolved(reCaptchaResponse) {
    if (this.args.changeset) {
      this.args.changeset[this.args.path] = reCaptchaResponse;
    }
  }

  @action
  onCaptchaExpired() {
    if (this.args.changeset) {
      this.args.changeset[this.args.path] = null;
    }
  }

  @action
  onCaptchaRendered(gRecaptcha) {
    gRecaptcha.reset();
  }
}
