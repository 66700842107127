import { isEmpty } from '@ember/utils';

export default function saveBlob(blob, filename = '') {
  if (!(blob instanceof Blob)) {
    return false;
  }

  let a = document.createElement('a');
  let url = window.URL.createObjectURL(blob);

  a.style = 'display: none';
  a.href = url;

  if (!isEmpty(filename)) {
    a.download = filename;
  }

  document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}
