import { action } from '@ember/object';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ProductListsProductListRoute extends Route {
  @service router;
  @service store;
  @service user;

  async model({ list_id: listId }) {
    try {
      return await this.store.findRecord('list', listId);
    } catch {
      await this.router.transitionTo('product-lists', {
        queryParams: {
          page: 1,
        },
      });
      return undefined;
    }
  }

  activate() {
    this.user.on('selectedPartyWillChange', this._selectedPartyDidChange);
  }

  deactivate() {
    this.user.off('selectedPartyWillChange', this._selectedPartyDidChange);
  }

  @action
  _selectedPartyDidChange() {
    this.router.transitionTo('product-lists', {
      queryParams: {
        page: 1,
      },
    });
  }
}
