import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Input::Select\n  @disabled={{not this.selectableGroups.length}}\n  @hideSelected={{true}}\n  @label={{t (concat \"notesOverview.search.label.\" this.filterName)}}\n  @multiple={{true}}\n  @name={{this.filterName}}\n  @onChange={{this.onChange}}\n  @options={{this.selectableGroups}}\n  @tooltipSelect={{if (not this.selectableGroups.length) (t \"notesOverview.text.notesGroupsTooltip\")}}\n  @value={{this.value}}\n  {{did-update this.updateValueFromCurrentQueryParams this.selectedGroupsFromQueryParams}}\n  data-test-notes-group-filter\n/>", {"contents":"<Ui::Input::Select\n  @disabled={{not this.selectableGroups.length}}\n  @hideSelected={{true}}\n  @label={{t (concat \"notesOverview.search.label.\" this.filterName)}}\n  @multiple={{true}}\n  @name={{this.filterName}}\n  @onChange={{this.onChange}}\n  @options={{this.selectableGroups}}\n  @tooltipSelect={{if (not this.selectableGroups.length) (t \"notesOverview.text.notesGroupsTooltip\")}}\n  @value={{this.value}}\n  {{did-update this.updateValueFromCurrentQueryParams this.selectedGroupsFromQueryParams}}\n  data-test-notes-group-filter\n/>","moduleName":"@mvb/tix-ui/components/search/filter/notes-group/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/notes-group/index.hbs"}});
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterNotesGroupComponent extends SearchFilterBaseComponent {
  @service errors;
  @service store;
  @service queryParams;
  @service user;
  @service eventBus;

  filterName = 'showGroupNotes';

  get selectableGroups() {
    return this.searchGroupsWithNotesTask.lastSuccessful?.value ?? [];
  }

  get selectedGroupsFromQueryParams() {
    return this.queryParams.currentQueryParams[this.filterName];
  }

  constructor() {
    super(...arguments);

    this.eventBus.on('reloadNotesGroupFilter', this, this.reloadSearchGroups);
    this.searchGroupsWithNotesTask.perform();

    next(this, () => {
      // initialize the value with the data from the queryParams
      this.updateValueFromCurrentQueryParams();
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.eventBus.off('reloadNotesGroupFilter', this, this.reloadSearchGroups);
  }

  reloadSearchGroups() {
    this.searchGroupsWithNotesTask.perform();
  }

  @action
  onChange(value) {
    this.value = value;

    this.search({
      [this.filterName]: Array.isArray(this.value) ? this.value.join(',') : this.value,
    });
  }

  @action
  updateValueFromCurrentQueryParams() {
    this.value = this.selectedGroupsFromQueryParams;
  }

  @task({ restartable: true })
  @waitFor
  *searchGroupsWithNotesTask() {
    try {
      let partyId = this.user.selectedParty.party.id;
      let groupsForCurrentUser = this.user.currentGroups.map((group) => group.id);
      let groupsWithNotes = yield this.store.query('group', {
        filter: {
          withNotes: true,
          'party.id': partyId,
          unpaged: true,
        },
      });

      return groupsWithNotes
        .filter((group) => groupsForCurrentUser.includes(group.id))
        .map((group) => ({ text: group.name, value: group.id }));
    } catch (error) {
      this.errors.handle(error);
    }

    return [];
  }
}
