import { action } from '@ember/object';
import { service } from '@ember/service';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Route from '@ember/routing/route';

export default class PartyGroupsGroupRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service('search-users-with-groups') searchService;

  queryParams = {
    qp: {
      refreshModel: true,
    },
  };

  @action
  didTransition() {
    if (this.abilities.can('party.viewGroups')) {
      this.search();
    }
  }

  async model({ group_id: groupId }) {
    try {
      if (this.abilities.cannot('party.viewGroups')) {
        return;
      }

      let group = await this.store.findRecord('group', groupId);

      return { group };
    } catch {
      this.router.replaceWith('party.groups.index');
    }
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.searchService.reset();
    }
  }

  search() {
    let model = this.modelFor(this.routeName).group;
    let params = this.paramsFor(this.routeName);
    this.searchService.search(null, { model, replaceRoute: true }, queryParamsUnpack(params));
  }

  serialize(model) {
    return { group_id: model.group.id };
  }
}
