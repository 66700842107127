import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { TIX_ITEM_SOURCE_TYPE } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';

export default class DispoListProductEntriesService extends Service {
  @service abilities;
  @service branches;
  @service dispoLists;
  @service errors;
  @service store;

  @task
  @waitFor
  *createForDefaultDispoListTask({
    collectionOfGoods = null,
    hugListingRecommendationCodes = [],
    onChange,
    preview,
    productId,
    productIsbn,
  }) {
    try {
      let dispoList = this.dispoLists.defaultDispoList;

      if (this.abilities.can('dispoList.useThaliaOrderGrid') || !dispoList || !productId || !productIsbn) {
        return;
      }

      let dispoListProducts = yield this.store.query('dispo-list-product', {
        filter: {
          'dispoList.id': dispoList.id,
          productIsbn,
        },
        include: 'dispoListProductEntries',
      });
      let dispoListProduct = dispoListProducts[0];

      if (!dispoListProduct) {
        dispoListProduct = yield this.store.createRecord('dispo-list-product', {
          dispoList,
          productIsbn,
          sourceId: preview?.id,
          sourceName: preview?.name,
          sourceType: preview ? TIX_ITEM_SOURCE_TYPE.PREVIEW : undefined,
        });
        yield dispoListProduct.save();
      }

      if (dispoListProduct.dispoListProductEntries.length === 0) {
        let dispoListProductEntry = this.store.createRecord('dispo-list-product-entry', {
          collectionOfGoods,
          dispoList,
          dispoListProduct,
          hugListingRecommendationCodes,
          quantity: 0,
        });
        yield dispoListProductEntry.save();
        yield onChange?.();
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  cleanup() {
    let dlpes = [
      ...this.store.peekAll('dispo-list-product-entry'),
      ...this.store.peekAll('thalia-dispo-list-product-entry'),
    ];

    for (let dlpe of dlpes.filter((record) => record.isDeleted || record.isNew)) {
      dlpe.unloadRecord();
    }
  }

  async fetchByIsbns(isbns) {
    if (isBlank(isbns)) {
      return [];
    }

    if (Array.isArray(isbns)) {
      isbns = isbns.join(',');
    }

    if (this.abilities.can('dispoList.useThaliaOrderGrid')) {
      return await this.store.query('thalia-dispo-list-product-entry', {
        filter: { isbn: isbns },
        include: 'dispoListProduct,dispoListProduct.dispoList',
      });
    }

    return await this.store.query('dispo-list-product-entry', {
      filter: { isbn: isbns },
      include: 'bookstoreBranch,collectionOfGoods,dispoListProduct,dispoListProduct.dispoList',
    });
  }

  mapSearchProduct({ dlpes, searchProduct }) {
    let dispoListProductEntries = dlpes.filter(
      (dlpe) => dlpe.dispoListProduct.get('productIsbn') === searchProduct?.gtin
    );

    if (!this.abilities.can('dispoList.useThaliaOrderGrid')) {
      for (let bookstoreBranch of this.branches.current) {
        if (dispoListProductEntries.every((dlpe) => dlpe.bookstoreBranch !== bookstoreBranch)) {
          dispoListProductEntries.push(this.store.createRecord('dispo-list-product-entry', { bookstoreBranch }));
        }
      }

      if (dispoListProductEntries.length === 0) {
        dispoListProductEntries.push(this.store.createRecord('dispo-list-product-entry'));
      }
    }

    return { dispoListProductEntries, searchProduct };
  }

  mapSearchProducts({ dlpes, searchProducts }) {
    return searchProducts.map((searchProduct) => this.mapSearchProduct({ dlpes, searchProduct }));
  }
}
