import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper {{if @inline 'inline'}}\" data-test-copy-link ...attributes>\n  <div local-class=\"input\">\n    <Ui::Input::Text @name=\"url\" @value={{@url}} @label={{@label}} readonly title={{@url}} />\n  </div>\n  {{#if @withIcon}}\n    <Ui::Button\n      @icon=\"copy\"\n      @label={{t \"uiCopyLink.action.copy\"}}\n      @onClick={{this.onCopyLink}}\n      @style=\"link\"\n      local-class=\"button icon\"\n    />\n  {{else}}\n    <Ui::Button @label={{t \"uiCopyLink.label.copy\"}} @onClick={{this.onCopyLink}} local-class=\"button\" />\n  {{/if}}\n</div>", {"contents":"<div local-class=\"wrapper {{if @inline 'inline'}}\" data-test-copy-link ...attributes>\n  <div local-class=\"input\">\n    <Ui::Input::Text @name=\"url\" @value={{@url}} @label={{@label}} readonly title={{@url}} />\n  </div>\n  {{#if @withIcon}}\n    <Ui::Button\n      @icon=\"copy\"\n      @label={{t \"uiCopyLink.action.copy\"}}\n      @onClick={{this.onCopyLink}}\n      @style=\"link\"\n      local-class=\"button icon\"\n    />\n  {{else}}\n    <Ui::Button @label={{t \"uiCopyLink.label.copy\"}} @onClick={{this.onCopyLink}} local-class=\"button\" />\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/ui/copy-link/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/copy-link/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class UiCopyLinkComponent extends Component {
  @service intl;
  @service notifications;

  @action
  async onCopyLink() {
    await parent?.navigator?.clipboard?.writeText(this.args.url);
    this.notifications.success(this.intl.t('uiCopyLink.notification.copySuccess'));
  }
}
