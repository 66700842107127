import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import window from 'ember-window-mock';

export default function validateAssetFileImageRatio({ ratioWidth, ratioHeight }) {
  return async (key, newValue /* , oldValue, changes, content */) => {
    let isString = typeof newValue === 'string';
    let isImageFile = newValue instanceof window.File && newValue.type?.startsWith('image');

    if (!newValue || !(isString || isImageFile) || !ratioWidth || !ratioHeight) {
      return true;
    }

    // this will also correctly validate pdf strings in safari
    let { actualWidth, actualHeight } = await new Promise((resolve) => {
      let img = new Image();

      img.addEventListener('load', ({ target: { naturalWidth, naturalHeight } }) => {
        URL.revokeObjectURL(img.src);

        resolve({
          actualWidth: naturalWidth,
          actualHeight: naturalHeight,
        });
      });

      img.addEventListener('error', () => {
        resolve({});
      });

      if (newValue instanceof window.File) {
        img.src = URL.createObjectURL(newValue);
      } else if (typeof newValue === 'string') {
        img.src = newValue;
      }
    });

    if (!actualWidth || !actualHeight) {
      return true;
    }

    let ratio = Number.parseFloat(ratioWidth / ratioHeight).toFixed(3);
    let actualRatio = Number.parseFloat(actualWidth / actualHeight).toFixed(3);

    if (ratio !== actualRatio) {
      return buildMessage(key, { type: 'hasValidImageRatio', value: newValue, context: { ratioWidth, ratioHeight } });
    }

    return true;
  };
}
