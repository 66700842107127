import { PARTY_TYPE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class MyProductsRoute extends Route {
  @service abilities;
  @service api;
  @service products;
  @service router;
  @service store;
  @service user;

  queryParams = {
    page: {
      refreshModel: true,
    },
  };

  async model({ page }) {
    let assignedParties = await this.store.query('assigned-party', {
      filter: {
        'user.id': this.user.current?.id,
        'party.type': [PARTY_TYPE.PUBLISHER, PARTY_TYPE.BOOK_GROUP, PARTY_TYPE.SALES_COOP],
      },
      sort: 'party.name',
      include: 'party,party.imprints,permissionSet',
      page: {
        number: page,
      },
    });

    let products = this.products;

    return { assignedParties, products };
  }

  beforeModel() {
    if (this.abilities.cannot('party.viewMyProducts')) {
      this.router.transitionTo('/');
    }
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }
}
