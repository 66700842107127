import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class BackofficeUsersIndexRoute extends Route {
  @service store;

  queryParams = {
    page: {
      refreshModel: false,
    },
    sort: {
      refreshModel: false,
    },
  };

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    // don't clear filters when coming from a subroute of this route
    if (transition.from?.name?.startsWith('backoffice.users')) {
      controller.update();
    } else {
      controller.clearFilters();
    }
  }
}
