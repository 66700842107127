export default {
  "advertising": "_advertising_ostpqn",
  "content": "_content_ostpqn",
  "image": "_image_ostpqn",
  "count": "_count_ostpqn",
  "info": "_info_ostpqn",
  "description": "_description_ostpqn",
  "prices": "_prices_ostpqn",
  "preview-wrapper": "_preview-wrapper_ostpqn",
  "preview": "_preview_ostpqn",
  "headline-amount": "_headline-amount_ostpqn",
  "headline-price": "_headline-price_ostpqn",
  "hint": "_hint_ostpqn",
  "source": "_source_ostpqn",
  "error-list": "_error-list_ostpqn"
};
