/**
 * Find the next number, looping around to the min value if the max value was reached
 *
 * @param {Number} position zero-based
 * @param {Number} direction positive or negative integer
 * @param {Number} total number of items
 * @param {Number} min=0 minimum value
 * @returns
 */
function loop(position, direction, total, min = 0) {
  let next = position + direction;

  if (next > total) {
    next = min + direction;
  }

  if (next < min) {
    next = total + direction;
  }

  if (next === total) {
    next = min;
  }

  return next;
}

/**
 * Find the next index in an array, looping around to the beginning if necessary
 *
 * @param {Arrray} array
 * @param {Number} index
 * @param {Number} direction
 * @returns
 */
const loopArray = (array, index, direction) => loop(index, direction, array.length, 0);

/**
 * Find the distance (previous/next) to the current index
 *
 * @param {Number} position
 * @param {Number} index
 * @param {Number} max
 * @param {Number} direction
 * @returns
 */
function loopDistance(position, index, max, direction) {
  let distance = (index - position) * direction;

  if (distance < 0) {
    distance = distance + max;
  }

  return distance;
}

export { loop, loopArray, loopDistance };
export default loop;
