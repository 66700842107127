import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function positiveIntegerWithDecimal() {
  return (key, newValue) => {
    let matchPositiveNumbersWithDecimal = new RegExp('^\\d+(\\.\\d+)?(,\\d+)?$');
    if (
      newValue === null ||
      newValue === undefined ||
      newValue.length === 0 ||
      matchPositiveNumbersWithDecimal.test(newValue)
    ) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let message = buildMessage(key, {
      message: intlService.t('validation.numbers.positive'),
    });

    return message;
  };
}
