import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-update this.onChangeEan @ean}}>\n  {{#if this.isVisible}}\n    <Ui::Table\n      data-test-sales-figures-hugendubel\n      local-class=\"table\"\n      @columns={{this.columns}}\n      @rows={{this.rows}}\n      as |data row|\n    >\n      <row.tr>\n        {{#each data as |column|}}\n          <td>\n            {{column}}\n          </td>\n        {{/each}}\n      </row.tr>\n    </Ui::Table>\n  {{/if}}\n</div>", {"contents":"<div {{did-update this.onChangeEan @ean}}>\n  {{#if this.isVisible}}\n    <Ui::Table\n      data-test-sales-figures-hugendubel\n      local-class=\"table\"\n      @columns={{this.columns}}\n      @rows={{this.rows}}\n      as |data row|\n    >\n      <row.tr>\n        {{#each data as |column|}}\n          <td>\n            {{column}}\n          </td>\n        {{/each}}\n      </row.tr>\n    </Ui::Table>\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/product/sales-figures/hugendubel.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/sales-figures/hugendubel.hbs"}});
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import config from '@mvb/tix-ui/config/environment';
import fetch from 'fetch';

export default class ProductSalesFiguresHugendubelComponent extends Component {
  @service intl;
  @service notifications;

  @tracked columns;
  @tracked rows;

  columnsDefault = null;
  lastFetchedEan = null;
  rowsDefault = null;

  constructor() {
    super(...arguments);

    this.reset();
    this.onChangeEan();
  }

  get isVisible() {
    return this.columns?.length > 0 && this.rows?.length > 0;
  }

  @action onChangeEan() {
    if (this.lastFetchedEan !== this.args.ean) {
      this.lastFetchedEan = this.args.ean;
      this.fetchSalesFiguresTask.perform({ ean: this.args.ean });
    }
  }

  @task({ restartable: true })
  @waitFor
  *fetchSalesFiguresTask({ ean }) {
    try {
      if (isBlank(ean)) {
        this.reset();
        return;
      }

      // fetch sales figures from hugendubel api
      let result = yield fetch(`${config.APP.hugendubel.salesFiguresApiUrl}${ean}`);
      let resultJson = yield result?.json?.();

      // show error message if request could be done but result contains any error
      // note: the api also return meta.authenticated; this is deliberately ignored as it is unused in production
      if (resultJson?.meta?.errors?.length > 0 || resultJson?.meta?.ean !== ean) {
        if (resultJson?.meta?.errors?.[0]?.status === 404) {
          this.notifications.info(this.intl.t('productSalesFiguresHugendubel.text.eanNotFound', { ean }));
        } else {
          this.notifications.error(this.intl.t('productSalesFiguresHugendubel.text.error', { ean }));
        }
        this.reset();
        return;
      }

      // map result to format of ui::table component
      this.columns =
        resultJson?.rows?.[0]?.map((name) => {
          return { align: 'right', name };
        }) ?? this.columnsDefault;
      this.rows = resultJson?.rows?.slice?.(1) ?? this.rowsDefault;
    } catch {
      // it is silently ignored if the fetch request fails
      // (this happens if the user is not in the hugendubel vpn)
      this.reset();
    }
  }

  reset() {
    this.columns = this.columnsDefault;
    this.rows = this.rowsDefault;
  }
}
