import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { sortNatural } from '@mvb/tix-ui/utils/sort';
import Model from './-base';

export default class DispoListProductEntryModel extends Model {
  @service intl;

  @attr('dateTime') created;
  @attr('dateTime', { defaultValue: null }) deliveryDate;
  @attr('number', { defaultValue: null }) quantity;
  @attr('string', { defaultValue: '' }) note;
  @attr('string', { defaultValue: '' }) orderCode;
  @attr({
    defaultValue() {
      return [];
    },
  })
  hugListingRecommendationCodes;

  @belongsTo('bookstore-branch', { async: false, inverse: null }) bookstoreBranch;
  @belongsTo('collection-of-goods', { async: false, inverse: null }) collectionOfGoods;
  @belongsTo('dispo-list-product', { async: false, inverse: 'dispoListProductEntries' }) dispoListProduct;

  get hugListingRecommendationCodesTranslated() {
    return sortNatural(this.hugListingRecommendationCodes?.map((code) => this.intl.t(`cl_513_${code}`)) ?? []);
  }
}
