import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class PreviewsAddController extends Controller {
  @service router;

  queryParams = ['page', 'type'];

  @tracked page = 1;
  @tracked type = '';

  @action
  setPage(page) {
    this.page = page || 1;
  }
}
