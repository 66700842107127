import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.maxSeries}}\n  <div local-class=\"series\" data-test-list-view-bibliography-series ...attributes>\n    {{#each this.maxSeries as |collection|}}\n      <p data-test-list-view-bib-collection>\n        {{~collection.title~}}\n        {{#if collection.partNumber}}\n          {{t \"listView.text.partNumber\"}}\n          {{collection.partNumber~}}\n        {{/if~}}\n      </p>\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if this.maxSeries}}\n  <div local-class=\"series\" data-test-list-view-bibliography-series ...attributes>\n    {{#each this.maxSeries as |collection|}}\n      <p data-test-list-view-bib-collection>\n        {{~collection.title~}}\n        {{#if collection.partNumber}}\n          {{t \"listView.text.partNumber\"}}\n          {{collection.partNumber~}}\n        {{/if~}}\n      </p>\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/list-view/bibliography/series.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/list-view/bibliography/series.hbs"}});
import Component from '@glimmer/component';
import sortBy from 'lodash-es/sortBy';

const MAX_SERIES = 5;

export default class ProductListViewBibliographySeriesComponent extends Component {
  get maxSeriesCount() {
    return Number(this.args.max) || MAX_SERIES;
  }

  get maxSeries() {
    let allCollections = this.args.searchProduct?.collections ?? [];
    let sortedCollections = sortBy(allCollections, 'sequence');
    if (sortedCollections.length < this.maxSeriesCount) {
      return sortedCollections;
    }
    return sortedCollections.slice(0, this.maxSeriesCount);
  }
}
