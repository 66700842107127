import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class PartyWkzController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service notifications;

  get changesetsHaveErrors() {
    let changesetsWithErrors = this.model.filter((changeset) => changeset.errors.length > 0);
    return !isEmpty(changesetsWithErrors);
  }

  get changesetsWithChanges() {
    return this.model.filter((changeset) => changeset.changes.length > 0);
  }

  @action
  onSave() {
    if (isEmpty(this.changesetsWithChanges)) {
      return;
    }
    let data = this.model.map((changeset) => changeset.pendingData);
    for (let changeset of this.changesetsWithChanges) {
      // because the changesets are not getting saved (because we send the data to the REST endpoint instead) we need to trick the changeset into thinking it no longer has changes so that the button will become disabled again
      changeset.execute().rollback();
    }
    return this.saveChangesTask.perform(data);
  }

  @task({ restartable: true })
  @waitFor
  *saveChangesTask(data) {
    try {
      yield this.api.postJSON('/marketing-subsidies/hugendubel', data);

      return this.notifications.success(this.intl.t('partyWkz.notification.success'));
    } catch {
      return this.notifications.error(this.intl.t('partyWkz.notification.error'));
    }
  }
}
