import { isBlank } from '@ember/utils';
import Service from 'ember-intl/services/intl';

export default class IntlService extends Service {
  formatNumber(value) {
    if (isBlank(value)) {
      return '';
    }

    return super.formatNumber(...arguments);
  }
}
