export default {
  "color-text": "var(--gray-100)",
  "label": "_label_fam08e _label_laqy2u",
  "label-hidden": "_label-hidden_fam08e",
  "input": "_input_fam08e",
  "has-error": "_has-error_fam08e _has-error_laqy2u",
  "disabled": "_disabled_fam08e",
  "readonly": "_readonly_fam08e",
  "wrapper": "_wrapper_fam08e",
  "with-clear": "_with-clear_fam08e",
  "clear": "_clear_fam08e"
};
