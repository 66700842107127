import { action } from '@ember/object';
import { service } from '@ember/service';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Route from '@ember/routing/route';

export default class OrderDispoListsIndexRoute extends Route {
  @service abilities;
  @service('search-dispo-lists') searchService;

  queryParams = {
    qp: {
      refreshModel: true,
    },
  };

  @action
  didTransition() {
    if (this.abilities.can('dispoList.viewDispoListsPage')) {
      this.search();
    }
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.searchService.reset();
    }
  }

  search() {
    let params = this.paramsFor(this.routeName);
    this.searchService.search(null, { replaceRoute: true }, queryParamsUnpack(params));
  }
}
