import { USER_ROLES } from '@mvb/tix-ui/constants';
import { validateFormat, validateLength, validatePresence } from 'ember-changeset-validations/validators/index';
import ENV from '@mvb/tix-ui/config/environment';
import mvbIdExists from '@mvb/tix-ui/validators/mvbid-exists';
import validateTixUserDoesNotExist from '@mvb/tix-ui/validators/tix-user-does-not-exist';

export default {
  username: [
    validateFormat({ type: 'email', description: 'intl:validation.user.description.usernameShort' }),
    validateTixUserDoesNotExist({ role: USER_ROLES.TIX_USER }),
  ],
  type: [validatePresence({ presence: true, description: 'intl:validation.user.description.type' })],
  gender: [validatePresence({ presence: true, description: 'intl:validation.user.description.gender' })],
  party: {
    mvbId: [
      mvbIdExists({ description: 'intl:validation.party.description.mvbId' }),
      validateLength({
        ignoreBlank: true,
        min: ENV.APP.minMvbIdLength,
        max: ENV.APP.maxMvbIdLength,
        description: 'intl:validation.party.description.mvbId',
      }),
    ],
  },
};
