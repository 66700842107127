export default {
  "recommendation-wrapper": "_recommendation-wrapper_1bgqbp",
  "button": "_button_1bgqbp",
  "icon-accepted": "_icon-accepted_1bgqbp",
  "icon-rejected": "_icon-rejected_1bgqbp",
  "path": "_path_1bgqbp",
  "rejected": "_rejected_1bgqbp",
  "actions": "_actions_1bgqbp",
  "muted": "_muted_1bgqbp"
};
