import { packArray, packSingle } from '@mvb/tix-ui/utils/linked-parties';
import { PARTY_TYPE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyLinkedPartiesRoute extends Route {
  @service abilities;
  @service features;
  @service router;
  @service store;
  @service user;

  beforeModel() {
    if (!this.features.isEnabled('linkedParties')) {
      return this.router.replaceWith('party.index');
    }
  }

  async model() {
    if (this.abilities.can('party.viewLinkedParties')) {
      let arrangedParties = [];
      let parties = await this.store.query('party', {
        filter: {
          mvbId: this.user.selectedParty.party.mvbId,
        },
        include: 'affiliates,affiliations,parent,imprints',
      });
      let party = parties?.[0];
      let includeStatus = party?.type === PARTY_TYPE.PUBLISHER;

      // We do not expect that all parties have all of these relationships.
      // But the decision was made to attempt to render a table for every relationship
      // that might be passed from the backend, rather than explicitly rendering only
      // the relationships we expect.
      packSingle({ name: 'parent', party: party?.parent, arrangedParties, includeStatus });
      packArray({ name: 'imprints', parties: party?.imprints, arrangedParties, includeStatus });
      packArray({ name: 'affiliations', parties: party?.affiliations, arrangedParties });
      packArray({ name: 'affiliates', parties: party?.affiliates, arrangedParties });

      return {
        arrangedParties,
        type: party.type,
      };
    }
  }
}
