import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-linked-party-wrapper={{@party.name}}>\n  <h2 data-test-table-title local-class=\"table-title\">{{t (concat \"partyLinkedPartyTable.\" @type \".\" @party.name)}}</h2>\n\n  <Ui::Table\n    data-test-linked-party-table\n    local-class=\"linked-party-table\"\n    @columns={{this.columns}}\n    @rows={{@party.rows}}\n    as |party row|\n  >\n    {{yield row party}}\n  </Ui::Table>\n</div>", {"contents":"<div data-test-linked-party-wrapper={{@party.name}}>\n  <h2 data-test-table-title local-class=\"table-title\">{{t (concat \"partyLinkedPartyTable.\" @type \".\" @party.name)}}</h2>\n\n  <Ui::Table\n    data-test-linked-party-table\n    local-class=\"linked-party-table\"\n    @columns={{this.columns}}\n    @rows={{@party.rows}}\n    as |party row|\n  >\n    {{yield row party}}\n  </Ui::Table>\n</div>","moduleName":"@mvb/tix-ui/components/party/linked-party-table.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/party/linked-party-table.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class PartyLinkedPartyTableComponent extends Component {
  @service intl;

  get columns() {
    let columns = [];
    for (let key of this.args.columns) {
      columns.push({ name: this.intl.t(`partyLinkedPartyTable.label.${key}`) });
    }
    return columns;
  }
}
