import {
  PARTY_ADDITIONAL_RIGHTS,
  PARTY_PERMISSIONS,
  PREVIEW_CMC_TYPES,
  PREVIEW_TYPE,
  PREVIEW_TYPES_INDIVIDUAL,
  USER_TYPE,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class PreviewAbility extends Ability {
  @service features;
  @service user;
  @service store;

  get assignedPartyCanCreate() {
    let assignedPartyPermissions = this.model.assignedParty?.permissionSet.permissions ?? [];
    let party = this.model.assignedParty?.party;

    let allowedForBookstore =
      [PREVIEW_TYPE.BOOKSTORE_PREVIEW].includes(this.model.previewType) &&
      party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.MANAGE_BOOKSTORE_PREVIEWS) &&
      assignedPartyPermissions.includes(PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS);
    let allowedForIndividual =
      PREVIEW_TYPES_INDIVIDUAL.includes(this.model.previewType) &&
      (assignedPartyPermissions.includes(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW) ||
        assignedPartyPermissions.includes(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW));
    let allowedForStandard =
      [PREVIEW_TYPE.STANDARDVORSCHAU].includes(this.model.previewType) &&
      (assignedPartyPermissions.includes(PARTY_PERMISSIONS.CREATE_OWN_STANDARD_PREVIEW) ||
        assignedPartyPermissions.includes(PARTY_PERMISSIONS.EDIT_PEER_STANDARD_PREVIEW));
    let allowedForWebToPrint =
      [PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model.previewType) &&
      party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
      (assignedPartyPermissions.includes(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW) ||
        assignedPartyPermissions.includes(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW));

    return (
      party?.isPremium &&
      (assignedPartyPermissions.includes(PARTY_PERMISSIONS.MANAGE_PREVIEWS) ||
        allowedForBookstore ||
        allowedForIndividual ||
        allowedForStandard ||
        allowedForWebToPrint)
    );
  }

  get buy() {
    return this.user.current?.hasAssignedParties;
  }

  get copy() {
    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.sender
    );

    // we do not want to copy bookstore previews ever
    if ([PREVIEW_TYPE.BOOKSTORE_PREVIEW].includes(this.model?.type)) {
      return false;
    }

    // we need to make an early return here for webToPrint previews, because we need the check for the additionalRights and the check for PARTY_PERMISSIONS.MANAGE_PREVIEWS permission would return true for webToPrint previews even though the user does not have any additionalRights for webToPrint
    if ([PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model?.type)) {
      return (
        this.user.selectedPartyHasPremiumStatus &&
        assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
        ((this.model?.creator === this.user.current?.id &&
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.COPY_PEER_INDIVIDUAL_PREVIEW))
      );
    }

    let allowedForIndividual =
      PREVIEW_TYPES_INDIVIDUAL.includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.COPY_PEER_INDIVIDUAL_PREVIEW));
    let allowedForStandard =
      [PREVIEW_TYPE.STANDARDVORSCHAU].includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_STANDARD_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.COPY_PEER_STANDARD_PREVIEW));

    return (
      this.features.isEnabled('clonePreviewAction') &&
      this.user.selectedPartyHasPremiumStatus &&
      (assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_PREVIEWS) ||
        allowedForIndividual ||
        allowedForStandard)
    );
  }

  get create() {
    return (
      this.user.selectedPartyHasPremiumStatus &&
      (this.user.selectedParty?.party?.isBookstore
        ? this._allowBookstorePreview(this.user.selectedParty)
        : this.user.anyPremiumPartyHasAnyPermission([
            PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW,
            PARTY_PERMISSIONS.CREATE_OWN_STANDARD_PREVIEW,
            PARTY_PERMISSIONS.MANAGE_PREVIEWS,
          ]))
    );
  }

  get selectedPartyCanCreateBookstorePreview() {
    return this._allowBookstorePreview(this.model);
  }

  get delete() {
    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.sender
    );

    // early return for bookstore previews, because of additionalRights and permissions
    if ([PREVIEW_TYPE.BOOKSTORE_PREVIEW].includes(this.model?.type)) {
      return (
        this.features.isEnabled('bookstorePreviews') &&
        this.user.selectedPartyHasPremiumStatus &&
        assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.MANAGE_BOOKSTORE_PREVIEWS) &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS)
      );
    }

    // we need to make an early return here for webToPrint previews, because we need the check for the additionalRights and the check for PARTY_PERMISSIONS.MANAGE_PREVIEWS permission would return true for webToPrint previews even though the user does not have any additionalRights for webToPrint
    if ([PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model?.type)) {
      return (
        this.user.selectedPartyHasPremiumStatus &&
        assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
        ((this.model?.creator === this.user.current?.id &&
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.DELETE_PEER_INDIVIDUAL_PREVIEW))
      );
    }

    let allowedForIndividual =
      PREVIEW_TYPES_INDIVIDUAL.includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.DELETE_PEER_INDIVIDUAL_PREVIEW));
    let allowedForStandard =
      [PREVIEW_TYPE.STANDARDVORSCHAU].includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_STANDARD_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.DELETE_PEER_STANDARD_PREVIEW));

    return (
      this.user.selectedPartyHasPremiumStatus &&
      (assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_PREVIEWS) ||
        allowedForIndividual ||
        allowedForStandard)
    );
  }

  get edit() {
    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.sender
    );

    // early return for bookstore previews, because of additionalRights and permissions
    if ([PREVIEW_TYPE.BOOKSTORE_PREVIEW].includes(this.model?.type)) {
      return (
        this.features.isEnabled('bookstorePreviews') &&
        this.user.selectedPartyHasPremiumStatus &&
        assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.MANAGE_BOOKSTORE_PREVIEWS) &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS)
      );
    }

    // we need to make an early return here for webToPrint previews, because we need the check for the additionalRights and the check for PARTY_PERMISSIONS.MANAGE_PREVIEWS permission would return true for webToPrint previews even though the user does not have any additionalRights for webToPrint
    if ([PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model?.type)) {
      return (
        this.user.selectedPartyHasPremiumStatus &&
        assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
        ((this.model?.creator === this.user.current?.id &&
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW))
      );
    }

    let allowedForIndividual =
      PREVIEW_TYPES_INDIVIDUAL.includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW));
    let allowedForStandard =
      [PREVIEW_TYPE.STANDARDVORSCHAU].includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_STANDARD_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_STANDARD_PREVIEW));

    return (
      this.user.selectedPartyHasPremiumStatus &&
      (assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_PREVIEWS) ||
        allowedForIndividual ||
        allowedForStandard)
    );
  }

  get editCustomMarketingContent() {
    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.sender
    );

    let allowedForSender =
      assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_PREVIEWS) ||
      (this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
      assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW);
    let allowedForBookstore =
      [PREVIEW_TYPE.BOOKSTORE_PREVIEW].includes(this.model?.type) &&
      this.features.isEnabled('bookstorePreviews') &&
      assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.MANAGE_BOOKSTORE_PREVIEWS) &&
      assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS);

    return (
      this.viewCustomMarketingContent &&
      ((this.model.isReceivedByCurrentUser && this.user.current?.hasAssignedParties) ||
        (this.user.selectedPartyHasPremiumStatus && (allowedForSender || allowedForBookstore)))
    );
  }

  get editStatus() {
    return (
      this.user.current?.isBlogger ||
      this.user.current?.isReader ||
      this.user.current?.isJournalist ||
      this.user.selectedParty?.party?.isBookstore
    );
  }

  get exportExcelHugendubel() {
    return this.model?.isHugendubel && this.user.hasPermission(PARTY_PERMISSIONS.HUGENDUBEL_PREVIEW_EXPORT);
  }

  get exportWebToPrint() {
    return (
      [PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model.type) &&
      this.model?.sender?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
      this.user.current?.isBackofficeUser
    );
  }

  get history() {
    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.party?.mvbId === this.model?.sender
    );
    let selectedParty = this.user.selectedParty;

    let allowedForBookstore = selectedParty?.party?.isBookstore;
    let allowedForCreator = selectedParty?.isPremium && this.user.current?.id === this.model?.creator;
    let allowedForManagePermission =
      selectedParty?.isPremium && selectedParty?.hasPermission(PARTY_PERMISSIONS.MANAGE_PREVIEWS);
    let allowedForPreviewTypeStandard =
      assignedPartyForSender &&
      assignedPartyForSender?.isPremium &&
      assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.SEND_PEER_STANDARD_PREVIEW) &&
      [PREVIEW_TYPE.STANDARDVORSCHAU].includes(this.model?.type);
    let allowedForPreviewTypeIndividual =
      assignedPartyForSender &&
      assignedPartyForSender?.isPremium &&
      assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.SEND_PEER_INDIVIDUAL_PREVIEW) &&
      PREVIEW_TYPES_INDIVIDUAL.includes(this.model?.type);
    let allowedForUnassignedSender = selectedParty?.isPremium && !assignedPartyForSender;

    return (
      this.model?.sentOnBehalfOfSelectedParty &&
      (allowedForBookstore ||
        allowedForCreator ||
        allowedForManagePermission ||
        allowedForPreviewTypeStandard ||
        allowedForPreviewTypeIndividual ||
        allowedForUnassignedSender)
    );
  }

  get selectWebToPrintTemplate() {
    if (this.model?.type !== PREVIEW_TYPE.WEB_TO_PRINT) {
      return false;
    }

    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.party?.mvbId === this.model?.sender
    );

    return (
      assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW))
    );
  }

  get send() {
    return this.user.current?.hasAssignedParties;
  }

  get sendIsEnabled() {
    return this.user.selectedPartyHasPremiumStatus || this.user.selectedParty?.party?.isBookstore;
  }

  get viewAndEditHugendubelCentralFields() {
    return PREVIEW_TYPE.HUGENDUBEL === this.model?.type && this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_HUG_CMC);
  }

  get isTRCPreview() {
    return PREVIEW_TYPE.THALIA === this.model?.type && PREVIEW_CMC_TYPES.TT_TRC === this.model?.cmcType;
  }

  get viewAndEditTRCFields() {
    return (
      this.isTRCPreview &&
      (this.user.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW) ||
        this.user.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW) ||
        this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_TRC_CMC))
    );
  }

  get viewAndEditTRCCategoryOfGoods() {
    return this.isTRCPreview && this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_TRC_CMC);
  }

  get viewCustomMarketingContent() {
    return PREVIEW_TYPES_INDIVIDUAL.includes(this.model?.type);
  }

  get viewPublishedPage() {
    let assignedPartyForSender = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.sender
    );

    // early return when general checks don't apply
    if (
      !this.features.isEnabled('publishedPageForPreviews') ||
      this.model?.isDraft || // a preview in draft does not have any published sections to show
      !this.user.selectedPartyHasPremiumStatus
    ) {
      return false;
    }

    // we need to make an early return here for webToPrint previews, because we need the check for the additionalRights and the check for PARTY_PERMISSIONS.MANAGE_PREVIEWS permission would return true for webToPrint previews even though the user does not have any additionalRights for webToPrint
    if ([PREVIEW_TYPE.WEB_TO_PRINT].includes(this.model?.type)) {
      return (
        assignedPartyForSender?.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.WEB_TO_PRINT) &&
        ((this.model?.creator === this.user.current?.id &&
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
          assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW))
      );
    }

    let allowedForIndividual =
      PREVIEW_TYPES_INDIVIDUAL.includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_INDIVIDUAL_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_INDIVIDUAL_PREVIEW));
    let allowedForStandard =
      [PREVIEW_TYPE.STANDARDVORSCHAU].includes(this.model?.type) &&
      ((this.model?.creator === this.user.current?.id &&
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.CREATE_OWN_STANDARD_PREVIEW)) ||
        assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.EDIT_PEER_STANDARD_PREVIEW));

    return (
      assignedPartyForSender?.hasPermission(PARTY_PERMISSIONS.MANAGE_PREVIEWS) ||
      allowedForIndividual ||
      allowedForStandard
    );
  }

  get viewStatus() {
    return this.user.assignedPartiesMvbIds?.includes(this.model?.sender);
  }

  _allowBookstorePreview(selectedParty) {
    return (
      selectedParty.party?.type === USER_TYPE.BOOKSTORE &&
      selectedParty.isPremium &&
      selectedParty.party?.additionalRights?.includes(PARTY_ADDITIONAL_RIGHTS.MANAGE_BOOKSTORE_PREVIEWS) &&
      selectedParty.hasPermission(PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS) &&
      this.features.isEnabled('bookstorePreviews')
    );
  }
}
