import { validateLength, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  file: [
    validatePresence({
      presence: true,
      ignoreBlank: true,
      message: 'intl:validation.marketing.message.file',
    }),
  ],
  caption: validateLength({
    max: 255,
    allowBlank: true,
    description: 'intl:validation.marketing.description.caption',
  }),
  copyright: validateLength({
    max: 255,
    allowBlank: true,
    description: 'intl:validation.marketing.description.copyright',
  }),
};
