import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.mappedDlpes key=\"dispoListProductEntry.id\" as |mappedDlpe mappedDlpeIndex|}}\n  <DispoLists::List::Thalia::Table\n    @clearOrRemoveMappedDlpeByIndex={{this.clearOrRemoveMappedDlpeByIndex}}\n    @createMappedDlpe={{this.createMappedDlpe}}\n    @dataColumns={{this.dataColumns}}\n    @dataRows={{this.dataRows}}\n    @dispoListSelectOptions={{@dispoListSelectOptions}}\n    @isEditable={{@isEditable}}\n    @isLoading={{@isLoading}}\n    @mappedDlpe={{mappedDlpe}}\n    @mappedDlpeIndex={{mappedDlpeIndex}}\n    @onRemoveRow={{@onRemoveRow}}\n    @searchProduct={{@searchProduct}}\n    @source={{@source}}\n    @tableColumns={{this.tableColumns}}\n  />\n{{/each}}", {"contents":"{{#each this.mappedDlpes key=\"dispoListProductEntry.id\" as |mappedDlpe mappedDlpeIndex|}}\n  <DispoLists::List::Thalia::Table\n    @clearOrRemoveMappedDlpeByIndex={{this.clearOrRemoveMappedDlpeByIndex}}\n    @createMappedDlpe={{this.createMappedDlpe}}\n    @dataColumns={{this.dataColumns}}\n    @dataRows={{this.dataRows}}\n    @dispoListSelectOptions={{@dispoListSelectOptions}}\n    @isEditable={{@isEditable}}\n    @isLoading={{@isLoading}}\n    @mappedDlpe={{mappedDlpe}}\n    @mappedDlpeIndex={{mappedDlpeIndex}}\n    @onRemoveRow={{@onRemoveRow}}\n    @searchProduct={{@searchProduct}}\n    @source={{@source}}\n    @tableColumns={{this.tableColumns}}\n  />\n{{/each}}","moduleName":"@mvb/tix-ui/components/dispo-lists/list/thalia/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/dispo-lists/list/thalia/index.hbs"}});
import { action } from '@ember/object';
// eslint-disable-next-line import/named
import { cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { THALIA_GRID_COLUMNS, THALIA_GRID_ROWS } from '@mvb/tix-ui/constants';
import { tracked } from 'tracked-built-ins';
import { validatePresence } from 'ember-changeset-validations/validators/index';
import Component from '@glimmer/component';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import styles from './index.css';

export default class DispoListsListThaliaComponent extends Component {
  @service dispoLists;
  @service intl;

  dataColumns = THALIA_GRID_COLUMNS;
  dataRows = THALIA_GRID_ROWS;

  @tracked mappedDlpes;

  @cached
  get tableColumns() {
    let tableColumns = [{ class: styles['column-grid-row'], name: '' }];

    for (let [index, column] of this.dataColumns.entries()) {
      index < this.dataColumns.length - 1
        ? tableColumns.push({ class: styles['column-grid-column'], name: column })
        : tableColumns.push({ class: styles['column-grid-total'], name: column });
    }

    tableColumns.push(
      { class: styles['column-dispo-list'], name: this.intl.t('dispoListsList.th.dispoList') },
      { class: styles['column-delete'], name: '' }
    );

    return tableColumns;
  }

  constructor() {
    super(...arguments);
    this.initMappedDlpes();
  }

  createMappedDlpeChangesetFromEntry(entry) {
    let mappedDlpe = {
      dispoList: entry?.dispoListProduct?.dispoList ?? this.args.dispoList ?? this.dispoLists.defaultDispoList ?? null,
      dispoListProductEntry: entry ?? null,
      grid: {},
    };

    for (let row of this.dataRows) {
      mappedDlpe.grid[row] = {};

      for (let column of this.dataColumns) {
        mappedDlpe.grid[row][column] =
          entry?.data?.find?.((entryData) => entryData.thaliaGridColumn === column && entryData.thaliaGridRow === row)
            ?.quantity ?? '';
      }
    }

    return createChangeset(
      mappedDlpe,
      { dispoList: [validatePresence({ description: this.intl.t('dispoListsList.th.dispoList'), presence: true })] },
      { skipValidate: true }
    );
  }

  @action
  clearOrRemoveMappedDlpeByIndex(index) {
    let mappedDlpes = [...this.mappedDlpes];

    if (mappedDlpes.length === 1) {
      mappedDlpes[index] = this.createMappedDlpeChangesetFromEntry();
    } else {
      mappedDlpes.splice(index, 1);
    }

    this.mappedDlpes = mappedDlpes;
  }

  @action
  initMappedDlpes() {
    let entries = Array.isArray(this.args.entries) ? this.args.entries : [];
    let mappedDlpes = [];

    for (let index = 0; index < Math.max(1, entries.length); index++) {
      let entry = entries[index];
      mappedDlpes.push(this.createMappedDlpeChangesetFromEntry(entry));
    }

    this.mappedDlpes = mappedDlpes;
  }
}
