import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes data-test-upload>\n  <h4 local-class=\"header\">\n    {{t \"sectionsEditProducts.text.uploadHeader\"}}\n  </h4>\n\n  <div local-class=\"upload-wrapper\">\n    <Ui::Input::File::Excel\n      data-test-isbn-upload\n      @disabled={{@disabled}}\n      @fileName={{this.filename}}\n      @onChange={{this.onFileUpload}}\n      @onError={{this.onFileUploadError}}\n      @onUpload={{fn @setIsRunning true}}\n      @url={{this.uploadExcelUrl}}\n    />\n    <Ui::Tooltip @side=\"top\" local-class=\"upload-tooltip\">\n      {{t (concat \"sectionsEditProducts.text.\" @translationPrefix \".uploadTooltip\") htmlSafe=true}}\n    </Ui::Tooltip>\n  </div>\n</div>", {"contents":"<div ...attributes data-test-upload>\n  <h4 local-class=\"header\">\n    {{t \"sectionsEditProducts.text.uploadHeader\"}}\n  </h4>\n\n  <div local-class=\"upload-wrapper\">\n    <Ui::Input::File::Excel\n      data-test-isbn-upload\n      @disabled={{@disabled}}\n      @fileName={{this.filename}}\n      @onChange={{this.onFileUpload}}\n      @onError={{this.onFileUploadError}}\n      @onUpload={{fn @setIsRunning true}}\n      @url={{this.uploadExcelUrl}}\n    />\n    <Ui::Tooltip @side=\"top\" local-class=\"upload-tooltip\">\n      {{t (concat \"sectionsEditProducts.text.\" @translationPrefix \".uploadTooltip\") htmlSafe=true}}\n    </Ui::Tooltip>\n  </div>\n</div>","moduleName":"@mvb/tix-ui/components/sections/edit/products/upload/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/products/upload/index.hbs"}});
import { action } from '@ember/object';
import { PRODUCTS_UPLOAD_TARGET, URL_ISBN_EXCEL_UPLOAD } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from '@mvb/tix-ui/config/environment';
import escape from 'lodash-es/escape';

export default class SectionsEditProductsUploadComponent extends Component {
  @service intl;
  @service notifications;

  @tracked filename;

  uploadTarget = this.args.uploadTarget ?? PRODUCTS_UPLOAD_TARGET.PREVIEW;
  uploadExcelUrl = this.args.targetPreviewType
    ? `${ENV.APP.api}${URL_ISBN_EXCEL_UPLOAD}?target=${this.uploadTarget}&targetPreviewType=${this.args.targetPreviewType}`
    : `${ENV.APP.api}${URL_ISBN_EXCEL_UPLOAD}?target=${this.uploadTarget}`;

  @action
  onFileUpload(fileResult, dataResult) {
    this.filename = fileResult.name;
    this.args.updateResult(dataResult, escape(fileResult.name));
    this.args.setIsRunning(false);
  }

  @action
  onFileUploadError(response) {
    let message = response.errors?.find((m) => m.code.includes('error'));

    if (message && message.code === 'error.promotionalPackageOrSpecial.tooManyProducts') {
      let errorMessage = this.intl.t('sectionsEditProducts.error.tooManyProducts');
      this.notifications.error(errorMessage, {
        autoClear: false,
      });
    } else {
      this.notifications.error(this.intl.t('sectionsEditProducts.text.uploadError'));
    }

    this.args.updateLastUploadResult(undefined, undefined);
    this.args.setIsRunning(false);
  }
}
