import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class IsAuthenticatedHelper extends Helper {
  @service session;

  compute() {
    return this.session.isAuthenticated;
  }
}
