import { action } from '@ember/object';
import { all, task, timeout } from 'ember-concurrency';
import {
  SECTION_CONTENT_TYPES,
  SECTION_TYPES,
  URL_PREVIEW_PUBLISH,
  URL_PREVIEW_UNPUBLISH,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import arrayToChunks from '@mvb/tix-ui/utils/array-to-chunks';
import SectionContentsBaseService, { SectionContentValidationError } from '@mvb/tix-ui/services/section-contents-base';

const CHUNK_SIZE = 4;

export default class SectionContentsPreviewService extends SectionContentsBaseService {
  @service customMarketingContent;

  get customMarketingContentService() {
    return this.customMarketingContent.getCMCServiceForPreviewType(this.model?.type);
  }

  setupController(model, intlNamespace) {
    this.model = model;
    this.intlNamespace = intlNamespace;
  }

  /**
   * Validate preview info section before validating all other sections
   * This prevents the user from saving a preview with invalid or missing preview info
   * @returns {Promise<boolean>}
   */
  @task
  @waitFor
  *validateAllTask() {
    try {
      // find a child component that contains the preview-info changeset
      let previewInfoSection = yield this.parentMachine?.state.context.sections.find((section) => {
        let childComponentChangeset = section.state.context.component?.changeset;
        let modelName = childComponentChangeset?.data.constructor.modelName;
        return modelName === 'preview-info';
      });
      let previewInfoChangeset = previewInfoSection?.state.context.component?.changeset;
      yield previewInfoChangeset.validate();
      if (previewInfoChangeset.isInvalid) {
        this.parentMachine?.send('VALIDATE');
        throw new SectionContentValidationError();
      }
    } catch (error) {
      this.handleErrors(error);
      return false;
    }

    return yield super.validateAllTask.perform();
  }

  async save() {
    let modelIsNew = this.model.isNew;
    await this.saveTask.perform();

    if (modelIsNew) {
      this.router.transitionTo('previews.preview.edit', this.model.id);
    } else {
      this.reloadCurrentRoute();
    }
  }

  async saveAndClose() {
    await this.saveTask.perform();
    this.router.transitionTo('previews.preview', this.model.id);
  }

  @task({ drop: true })
  @waitFor
  *saveTask() {
    if (this.model.isNew || this.model.hasDirtyAttributes) {
      yield this.model.save();
    }

    for (let section of this.modifiedSections) {
      yield this.saveSection.perform(section);
    }

    let previewInfoToSave;
    let remainingRecords = yield this.saveProductSectionTask.perform();
    let chunks = arrayToChunks(remainingRecords, CHUNK_SIZE);
    let allSkippedRecords = [];

    for (let records of chunks) {
      let skippedRecords = yield all(records.map((record) => this.saveSectionContent.perform(record)));

      // timeout so that the browser doesn't get overloaded with the requests and doesn't kill them
      yield timeout(100 + Math.random() * 100);

      // collect all skipped records in a single array, otherwise productInfo might not be saved (when creating a new preview)
      allSkippedRecords = [...allSkippedRecords, ...skippedRecords];
    }

    // save customMarketingContents
    yield this.customMarketingContentService.saveTask.perform({
      previewId: this.model.id,
    });

    // if allSkippedRecords contains some record, it is preview-info and it has to be saved as the last one
    previewInfoToSave = allSkippedRecords.find(
      (skippedRecord) => skippedRecord?.data?.constructor.modelName === 'preview-info'
    );
    if (previewInfoToSave) {
      yield this.savePreviewInfo.perform(previewInfoToSave);
    }

    yield this.finalize.perform();
  }

  @action
  async publish() {
    await this.publishTask.perform();
    await this.reloadModel();
    this.router.transitionTo('previews.preview', this.model.id);
  }

  @task
  @waitFor
  *publishTask() {
    if (this.hasChanges || !!this.dirtyMachineSections) {
      yield this.saveTask.perform();
    }

    let url = URL_PREVIEW_PUBLISH.replace('{ID}', this.model.id);
    yield this.api.postJSON(url, {
      id: this.model.id,
    });
  }

  @action
  async unpublish() {
    await this.unpublishTask.perform();
    await this.reloadModel();
    this.reloadCurrentRoute();
  }

  @task
  @waitFor
  *unpublishTask() {
    if (this.hasChanges || !!this.dirtyMachineSections) {
      yield this.saveTask.perform();
    }

    let url = URL_PREVIEW_UNPUBLISH.replace('{ID}', this.model.id);
    yield this.api.postJSON(url, {
      id: this.model.id,
    });
  }

  @task
  @waitFor
  *saveProductSectionTask() {
    let productSectionContents = [];
    let remaining = [];
    let productSection = null;
    for (let record of this.modifiedRecords) {
      let isTix = record.contentType === SECTION_CONTENT_TYPES.TIX;
      let isProductSection = record.section?.get('type') === SECTION_TYPES.REFERENCETITLE;

      if (isTix && isProductSection) {
        if (!productSection) {
          productSection = record.section;
        }
        if (record.isDeleted) {
          yield record.save();
          continue;
        }
        productSectionContents.push(record);
      } else {
        remaining.push(record);
      }
    }

    if (!productSection) {
      return remaining;
    }

    let products = [];

    for (let record of productSectionContents) {
      products.push({
        referencedProductId: record.referencedProductId,
        referencedProductIsbn: record.referencedProductIsbn,
        webToPrintTemplate: record.webToPrintTemplate,
        position: record.position,
      });
    }

    // Save sectionContents with products in a single request
    let updatedReferenceTitles = yield this.api.postJSON('/section-content/referenced-titles', {
      sectionId: productSection.get('id'),
      products,
    });

    let previewId = productSection.preview?.get('id');
    if (previewId != null && products.length > 0) {
      // Save w2pTemplates with products in a single request
      yield this.api.postJSON('/section-content/web-to-print-template', {
        previewId: productSection.preview.get('id'),
        products: products.map((record) => {
          return {
            referencedProductId: record.referencedProductId,
            webToPrintTemplate: record.webToPrintTemplate,
          };
        }),
      });
    }

    for (let record of productSectionContents) {
      if (record.isNew) {
        yield record.unloadRecord();

        let records = new Set(this.modifiedRecords);
        records.delete(record);
        this.modifiedRecords = records;
      }
    }

    this.store.pushPayload(updatedReferenceTitles);

    return remaining;
  }

  @task({
    enqueue: true,
  })
  @waitFor
  *savePreviewInfo(changeset) {
    if (!changeset) {
      return;
    }

    if (changeset.isPristine) {
      return;
    }

    if (changeset.get('markedForDeletion')) {
      this.modifiedRecords.delete(changeset);
      changeset.execute();
      yield changeset.data.destroyRecord();
      return;
    }

    this.modifiedRecords.delete(changeset);
    yield changeset.save();
  }

  @task({
    drop: true,
  })
  @waitFor
  *finalize() {
    yield this.api.post(`/previews/${this.model.id}/finalize`);
  }

  @action
  cancel() {
    if (this.model?.id) {
      this.router.transitionTo('previews.preview', this.model.id);
    } else {
      this.router.transitionTo('previews', { queryParams: { type: this.model.type } });
    }
  }

  async reloadModel() {
    return this.store.findRecord(this.model.constructor.modelName, this.model.id, {
      include: 'sender,sections.contents.file,sections.previewInfo',
    });
  }

  undoChanges() {
    // Skip undo if store is already going to be destroyed
    if (this.store.isDestroying || this.store.isDestroyed) {
      return;
    }

    this.customMarketingContentService.unload();
    super.undoChanges();
  }
}
