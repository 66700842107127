import { EXPECTED_VIDEO_SOURCES, URL_VIDEO_VIMEO, URL_VIDEO_YOUTUBE } from '@mvb/tix-ui/constants';
import { isEmpty } from '@ember/utils';

const getYoutubeCode = (url) => {
  let videoParam = url.searchParams.get('v');

  if (!videoParam) {
    // pathname can look like this /<code> or this /embed/<code>
    let lastIndexOfSlash = url.pathname.lastIndexOf('/');
    videoParam = url.pathname.slice(lastIndexOfSlash + 1);
  }

  let indexOfAuth = videoParam?.indexOf('?') ?? 0;
  let code = indexOfAuth > 0 ? videoParam.slice(0, indexOfAuth) : videoParam;

  return code;
};

const getYoutubeStartTime = (url) => {
  let startParam = url.searchParams.get('t');
  if (isEmpty(startParam)) {
    return 0;
  }

  let indexOfAuth = startParam.indexOf('?');
  return indexOfAuth > 0 ? startParam.slice(0, indexOfAuth) : startParam;
};

const getVimeoCode = (path) => {
  let indexOfAuth = path.indexOf('?');
  let code = indexOfAuth > 0 ? path.slice(1, indexOfAuth) : path.slice(1);
  return code;
};

/**
 * Gets the code from the url for youtube or vimeo.
 * Returns either an url for youtube or vimeo wich is DSGVO conform.
 *
 * @param {string} url the url of the video (contains the code for youtube or vimeo, e.g. https://www.youtube.com/watch?v=jn9vYCPN5oE&t=10 or https://vimeo.com/162350842)
 */
export function getVideoUrl(url) {
  let newUrl = new URL(url);

  if (url.includes(EXPECTED_VIDEO_SOURCES.YOUTUBE)) {
    let start = getYoutubeStartTime(newUrl);
    let code = getYoutubeCode(newUrl);
    return `${URL_VIDEO_YOUTUBE}${code}?start=${start}&autoplay=1`;
  }

  if (url.includes(EXPECTED_VIDEO_SOURCES.VIMEO)) {
    let code = getVimeoCode(newUrl.pathname);
    return `${URL_VIDEO_VIMEO}${code}?dnt=1&autoplay=1`;
  }
}
