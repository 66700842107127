export default {
  "wrapper": "_wrapper_qdu5u8",
  "element": "_element_qdu5u8",
  "element-no-border": "_element-no-border_qdu5u8",
  "check": "_check_qdu5u8",
  "top": "_top_qdu5u8",
  "top-right": "_top-right_qdu5u8",
  "content": "_content_qdu5u8",
  "hidden": "_hidden_qdu5u8 _hidden-aria_u001hh",
  "position": "_position_qdu5u8"
};
