import { all } from 'ember-concurrency';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyErpRoute extends Route {
  @service abilities;
  @service branches;
  @service erp;
  @service user;

  async model() {
    if (this.abilities.can('party.viewErp')) {
      await all([this.branches.load(), this.erp.load()]);

      let branches = this.branches.currentByCreated;
      let erps = this.erp.current;
      let party = this.user.selectedParty?.party;

      return { branches, erps, party };
    }
  }
}
