import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class OrderSuccessRoute extends Route {
  @service userState;
  @service abilities;

  async model({ order_channel_type: orderChannelType }) {
    if (this.abilities.cannot('order.order')) {
      return;
    }
    await this.userState.load();

    return { orderChannelType };
  }
}
