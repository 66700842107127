import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class SectionTitleHelper extends Helper {
  @service intl;

  compute([section]) {
    let isImageAndTextWithHeadline = section.type === SECTION_TYPES.IMAGE_TEXT && section.firstContent?.headline;

    let title = isImageAndTextWithHeadline
      ? section.firstContent.headline
      : this.intl.t(`sections.text.type.${section.type}`);

    return title;
  }
}
