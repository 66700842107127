import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Search::Filter\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{@title}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <Ui::SearchBar\n      @name={{@filter}}\n      @noLabel={{true}}\n      @onSearch={{this.onFilterChange}}\n      @placeholder={{@placeholder}}\n      @style=\"default\"\n      @value={{this.value}}\n    />\n  </:content>\n</Search::Filter>", {"contents":"<Search::Filter\n  @count={{this.count}}\n  @filter={{@filter}}\n  @filterComponents={{@filterComponents}}\n  @onChange={{this.onChange}}\n  @onReset={{this.onReset}}\n  @title={{@title}}\n  {{did-update this.onValueArgumentChange @value}}\n>\n  <:content>\n    <Ui::SearchBar\n      @name={{@filter}}\n      @noLabel={{true}}\n      @onSearch={{this.onFilterChange}}\n      @placeholder={{@placeholder}}\n      @style=\"default\"\n      @value={{this.value}}\n    />\n  </:content>\n</Search::Filter>","moduleName":"@mvb/tix-ui/components/search/filter/search-term/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/search-term/index.hbs"}});
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterSearchTermComponent extends SearchFilterBaseComponent {
  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? '';
    this.onValueArgumentChange();
  }
}
