export default {
  "item": "_item_1itp2p",
  "label": "_label_1itp2p",
  "thumbnail": "_thumbnail_1itp2p",
  "active": "_active_1itp2p",
  "flags": "_flags_1itp2p",
  "flag": "_flag_1itp2p",
  "flag-hr": "_flag-hr_1itp2p",
  "index": "_index_1itp2p",
  "listing-info": "_listing-info_1itp2p",
  "hug": "_hug_1itp2p",
  "truncated": "_truncated_1itp2p _one-line-truncated_1ju3hu"
};
