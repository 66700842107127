import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class PartyGroupsGroupController extends Controller {
  @service groups;
  @service('search-users-with-groups') searchService;

  queryParams = ['qp'];
  @tracked qp = '';

  @action
  editGroup() {
    this.groups.openModalAndManageGroupResults({ mode: 'edit', record: this.model.group });
  }
}
