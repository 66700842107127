import { library } from '@fortawesome/fontawesome-svg-core';

let icons = [
  {
    prefix: 'fal',
    iconName: 'file-audio-download',
    icon: [
      588,
      512,
      [],
      'f0001',
      'M405.1 0c12.498 0 24.513 4.94 33.469 13.674l.431.426L522.9 98c8.857 8.857 13.937 20.91 14.1 33.396V464c0 26.235-21.072 47.57-47.207 47.994L489 512H201c-26.235 0-47.57-21.072-47.994-47.207L153 464V304c-8.022 0-16.045-2.998-22.242-8.994l-.385-.379-120-120c-19.958-19.957-6.164-53.94 21.776-54.617L33 120h52V32C85 14.679 98.762.572 115.949.017l.522-.017H117zM377 32.1H221V120h52c27.942 0 42.212 33.11 23.79 53.407l-.568.611-.595.61L185 285.253V464.1c0 8.695 7.03 15.828 15.686 16H489c8.695 0 15.828-7.03 16-15.686V160.1H401a23.94 23.94 0 01-23.997-23.602L377 136.1zM189 32h-72v120H33l120 120 120-120h-84zm220 .6v95.5h95.5a15.704 15.704 0 00-3.874-7.065l-.326-.335-83.9-83.9a15.723 15.723 0 00-6.96-4.084zm2.245 371.145c26-17.3 41.5-46.2 41.5-77.4 0-32.9-17.7-63.7-46.2-80.3-7.2-4.2-16.3-1.7-20.5 5.4-4.2 7.2-1.7 16.3 5.4 20.5 19.3 11.2 31.3 32 31.3 54.3 0 21.1-10.5 40.7-28.1 52.4-6.9 4.6-8.8 13.9-4.2 20.8 2.9 4.4 7.7 6.7 12.5 6.7 2.666.095 5.424-.626 7.926-2.164zm-27.761-39.161c13.7-8.5 21.8-23.1 21.8-39.2 0-17-9.3-32.5-24.2-40.6-7.3-3.9-16.4-1.2-20.3 6.1-3.847 7.201-1.272 16.154 5.806 20.139l.294.16c5.2 2.8 8.5 8.3 8.5 14.2 0 5.6-2.9 10.8-7.6 13.7-7 4.4-9.2 13.6-4.8 20.7 2.8 4.6 7.7 7.1 12.8 7.1 2.375 0 4.93-.63 7.323-2.066zM317 287v74.7l-26-19.4h-18v-36h18zm17.9-50c-2.9 0-6 1.1-8.4 3.5L283 277h-28c-6.6 0-12 5.4-12 12v72c0 6.6 5.4 12 12 12h28l43.5 36.5c2.4 2.4 5.4 3.5 8.4 3.5 6.2 0 12.1-4.8 12.1-12V249c0-7.2-5.9-12-12.1-12z',
    ],
  },
  {
    prefix: 'far',
    iconName: 'file-audio-download',
    icon: [
      588,
      512,
      [],
      'f0002',
      'M421.118 0a48 48 0 0133.51 13.633l.431.426 83.882 83.882A48.02 48.02 0 01553 131.276V464c0 26.245-21.062 47.57-47.206 47.994L505 512H217c-26.245 0-47.57-21.062-47.994-47.206L169 464V336c-12.086 0-24.172-4.536-33.485-13.609l-.456-.45-120-120c-29.936-29.936-9.246-80.91 32.665-81.926L49 120h36V48C85 22.018 105.644.857 131.423.025l.783-.019L133 0zM377 48H253v72h36c41.912 0 63.319 49.666 35.685 80.111l-.852.917-.892.913L217 307.882V464h288V176H401c-13.122 0-23.785-10.531-23.997-23.603L377 152zm-172 0h-72v120H49l120 120 120-120h-84zm220 3.882V128h76.118zm-64 357.094c0 10.691-12.926 16.045-20.485 8.485L305 381.486h-28c-6.627 0-12-5.373-12-12v-56c0-6.627 5.373-12 12-12h28l35.515-36.947c7.56-7.56 20.485-2.206 20.485 8.485zm41.201-47.13c9.051-9.297 9.06-24.133.001-33.439-22.149-22.752 12.235-56.246 34.395-33.481 27.198 27.94 27.212 72.444.001 100.401-21.575 22.162-56.245-9.666-35.056-32.783z',
    ],
  },
  {
    prefix: 'far',
    iconName: 'book-search',
    icon: [
      512,
      512,
      [],
      'f0003',
      'M158.473 60h.066l4.492-.001c-.13.34-.259.682-.387 1.023a9.002 9.002 0 014.56 10.16l-7.5 30a9.001 9.001 0 01-7.268 6.699.888.888 0 00-.014.118L80 108c-17.7 0-32 14.3-32 32v218.7c9.8-4.3 20.6-6.7 32-6.7h260v-65.183h.001l-.001.07a9 9 0 016.647-8.687l30-8.125A9 9 0 01388 278.762V384c0 7.8-5.5 14.2-12.9 15.7-4.2 13-4.2 51.6 0 64.6 7.3 1.4 12.9 7.9 12.9 15.7v16c0 8.8-7.2 16-16 16H80c-44.2 0-80-35.8-80-80V140c0-44.2 35.8-80 80-80h70zM334 400H80c-17.6 0-32 14.4-32 32 0 17.7 14.3 32 32 32h254c-2.7-17.3-2.7-46.7 0-64zM320 0c66.274 0 120 53.726 120 120 0 23.145-6.553 44.76-17.904 63.09.15-.032.195.048.123.253l-.052.124-.075.15-.047.084-.112.188-.136.214-.162.242-.292.412-.352.476-.413.54-.648.823-.562.696a117.503 117.503 0 01-3.36 4.709L428.6 192c3.081 0 5.978 1.113 8.242 3.249l.258.251 71.4 71.4a12.01 12.01 0 01.231 16.763l-.231.237-22.6 22.6a12.01 12.01 0 01-16.763.231l-.237-.231-71.4-71.4c-2.119-2.215-3.402-5.079-3.495-8.145L394 226.6l.001-12.127C373.614 230.465 347.921 240 320 240c-66.274 0-120-53.726-120-120S253.726 0 320 0zm0 48c-39.765 0-72 32.235-72 72s32.235 72 72 72c16.121 0 31.316-5.257 43.793-14.842l.583-.453 7.517-5.896 5.736-7.636a70.131 70.131 0 001.509-2.087l.48-.7.93-1.374.74-1.193c6.846-11.056 10.572-23.729 10.708-37.063L392 120c0-39.765-32.235-72-72-72z',
    ],
  },
  {
    prefix: 'far',
    iconName: 'tag-search',
    icon: [
      512,
      512,
      [],
      'f0004',
      'M387.941 295.941c18.745 18.745 18.745 49.137 0 67.882L253.823 497.941c-18.745 18.746-49.137 18.745-67.882 0L14.059 326.059A47.999 47.999 0 010 292.118V158c0-26.51 21.49-48 48-48l105.293-.002A170.628 170.628 0 00153 120c0 12.864 1.962 25.601 5.59 38H48v134.118l171.886 171.878L354 329.882l-19.956-19.957-.044.016-20.997-20.962c-3.909-3.902-3.914-10.234-.012-14.142a10 10 0 015.569-2.82l34.438-5.254a10 10 0 018.58 2.815zM321 0c66.274 0 120 53.726 120 120 0 23.145-6.553 44.76-17.904 63.09.15-.032.195.048.123.253l-.052.124-.075.15-.047.084-.112.188-.136.214-.162.242-.292.412-.352.476-.413.54-.648.823-.562.696a117.503 117.503 0 01-3.36 4.709L429.6 192c3.081 0 5.978 1.113 8.242 3.249l.258.251 71.4 71.4a12.01 12.01 0 01.231 16.763l-.231.237-22.6 22.6a12.01 12.01 0 01-16.763.231l-.237-.231-71.4-71.4c-2.119-2.215-3.402-5.079-3.495-8.145L395 226.6l.001-12.127C374.614 230.465 348.921 240 321 240c-66.274 0-120-53.726-120-120S254.726 0 321 0zM144 206c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.49-48 48-48zM321 48c-39.765 0-72 32.235-72 72s32.235 72 72 72c16.121 0 31.316-5.257 43.793-14.842l.583-.453 7.517-5.896 5.736-7.636a70.131 70.131 0 001.509-2.087l.48-.7.93-1.374.74-1.193c6.846-11.056 10.572-23.729 10.708-37.063L393 120c0-39.765-32.235-72-72-72z',
    ],
  },
  {
    prefix: 'far',
    iconName: 'list-add',
    icon: [
      640,
      512,
      [],
      'f0005',
      'M488 208c83.947 0 152 68.053 152 152s-68.053 152-152 152-152-68.053-152-152 68.053-152 152-152zm24-176c35.3 0 64 28.65 64 64v111.546a174.88 174.88 0 00-47.998-18.98L528 96c0-8.84-7.2-16-16-16H64c-8.84 0-16 7.16-16 16v320c0 8.8 7.16 16 16 16l263.355.003a176.254 176.254 0 0031.9 48L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 224c-57.438 0-104 46.562-104 104s46.562 104 104 104 104-46.562 104-104-46.562-104-104-104zm0 24c13.255 0 24 10.745 24 24v31.999l32 .001c13.255 0 24 10.745 24 24s-10.745 24-24 24l-32-.001V416c0 13.255-10.745 24-24 24s-24-10.745-24-24v-32.001L432 384c-13.255 0-24-10.745-24-24s10.745-24 24-24l32-.001V304c0-13.255 10.745-24 24-24zm-360-56c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm239.204 7.998a176.597 176.597 0 00-36.012 48L224 280c-13.3 0-24-10.7-24-24s10.7-24 24-24zM128 128c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm320 8c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24s10.7-24 24-24z',
    ],
  },
  {
    prefix: 'fal',
    iconName: 'list-add',
    icon: [
      640,
      512,
      [],
      'f0005',
      'M488 208c83.947 0 152 68.053 152 152s-68.053 152-152 152-152-68.053-152-152 68.053-152 152-152zm24-176c35.3 0 64 28.65 64 64l.004 108.465A175.44 175.44 0 00544.003 188L544 96c0-17.67-14.3-32-32-32H64c-17.67 0-32 14.33-32 32v320c0 17.7 14.33 32 32 32l268.465.004a176.882 176.882 0 0026.74 31.999L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 208c-66.274 0-120 53.726-120 120s53.726 120 120 120 120-53.726 120-120-53.726-120-120-120zm0 24c8.837 0 16 7.163 16 16v63.999l64 .001c8.837 0 16 7.163 16 16s-7.163 16-16 16l-64-.001V440c0 8.837-7.163 16-16 16s-16-7.163-16-16v-64.001L408 376c-8.837 0-16-7.163-16-16s7.163-16 16-16l64-.001V280c0-8.837 7.163-16 16-16zm-360-32c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm216.23 7.999a176.241 176.241 0 00-21.038 32L208 272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM128 136c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm336 8c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16z',
    ],
  },
  {
    prefix: 'far',
    iconName: 'list-remove',
    icon: [
      640,
      512,
      [],
      'f0006',
      'M488 208c83.947 0 152 68.053 152 152s-68.053 152-152 152-152-68.053-152-152 68.053-152 152-152zm24-176c35.3 0 64 28.65 64 64v111.546a174.88 174.88 0 00-47.998-18.98L528 96c0-8.84-7.2-16-16-16H64c-8.84 0-16 7.16-16 16v320c0 8.8 7.16 16 16 16l263.355.003a176.254 176.254 0 0031.9 48L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 224c-57.438 0-104 46.562-104 104s46.562 104 104 104 104-46.562 104-104-46.562-104-104-104zm-360 64c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm416 16c13.255 0 24 10.745 24 24s-10.745 24-24 24H432c-13.255 0-24-10.745-24-24s10.745-24 24-24zm-229.098-8A177.01 177.01 0 00312 360c0 5.394.243 10.732.718 16.002L224 376c-13.3 0-24-10.7-24-24s10.7-24 24-24zM128 224c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm239.204 7.998a176.597 176.597 0 00-36.012 48L224 280c-13.3 0-24-10.7-24-24s10.7-24 24-24zM128 128c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm320 8c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24s10.7-24 24-24z',
    ],
  },
  {
    prefix: 'fas',
    iconName: 'list-remove',
    icon: [
      640,
      512,
      [],
      'f0006',
      'M512 32c35.3 0 64 28.65 64 64l.001 140.05C614.734 263.6 640 308.85 640 360c0 83.947-68.053 152-152 152-35.17 0-67.55-11.944-93.304-31.998L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 212c-64.065 0-116 51.935-116 116s51.935 116 116 116 116-51.935 116-116-51.935-116-116-116zm-360 76c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm416 16c13.255 0 24 10.745 24 24s-10.745 24-24 24H432c-13.255 0-24-10.745-24-24s10.745-24 24-24zm-204.626-8H224c-13.3 0-24 10.7-24 24s10.7 24 24 24l112.832.002A153.745 153.745 0 01336 360c0-10.977 1.164-21.683 3.374-32zM128 224c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm277.99 8H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h134.731a152.8 152.8 0 0147.259-48zM128 128c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm320 8H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h224c13.3 0 24-10.7 24-24s-10.7-24-24-24z',
    ],
  },
  {
    prefix: 'fal',
    iconName: 'list-remove',
    icon: [
      640,
      512,
      [],
      'f0006',
      'M488 208c83.947 0 152 68.053 152 152s-68.053 152-152 152-152-68.053-152-152 68.053-152 152-152zm24-176c35.3 0 64 28.65 64 64l.004 108.465A175.44 175.44 0 00544.003 188L544 96c0-17.67-14.3-32-32-32H64c-17.67 0-32 14.33-32 32v320c0 17.7 14.33 32 32 32l268.465.004a176.882 176.882 0 0026.74 31.999L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 208c-66.274 0-120 53.726-120 120s53.726 120 120 120 120-53.726 120-120-53.726-120-120-120zm-360 88c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm440 16c8.837 0 16 7.163 16 16s-7.163 16-16 16H408c-8.837 0-16-7.163-16-16s7.163-16 16-16zm-263.282-8.001A178.24 178.24 0 00304 352c0 5.394.243 10.732.718 16.002L208 368c-8.8 0-16-7.2-16-16s7.2-16 16-16zM128 232c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm216.23 7.999a176.241 176.241 0 00-21.038 32L208 272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM128 136c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm336 8c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16z',
    ],
  },
  {
    prefix: 'fal',
    iconName: 'list-edit',
    icon: [
      640,
      512,
      [],
      'f0007',
      'M488 208c83.947 0 152 68.053 152 152s-68.053 152-152 152-152-68.053-152-152 68.053-152 152-152zm24-176c35.3 0 64 28.65 64 64l.004 108.465A175.44 175.44 0 00544.003 188L544 96c0-17.67-14.3-32-32-32H64c-17.67 0-32 14.33-32 32v320c0 17.7 14.33 32 32 32l268.465.004a176.882 176.882 0 0026.74 31.999L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 208c-66.274 0-120 53.726-120 120s53.726 120 120 120 120-53.726 120-120-53.726-120-120-120zm.653 77.131l42.156 42.097-62.462 62.463a19.091 19.091 0 01-8.847 4.987l-35.684 8.966a9.697 9.697 0 01-9.025-2.494c-2.375-2.375-3.325-5.819-2.494-9.56l8.906-35.209c.831-3.325 2.553-6.353 4.988-9.322zm26.184-26.184c9.263-9.263 24.285-9.263 33.607 0l8.49 8.55c9.322 9.262 9.322 24.284 0 33.606l-12.706 12.706-42.097-42.156zM128 232c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm216.23 7.999a176.241 176.241 0 00-21.038 32L208 272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM128 136c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm336 8c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16z',
    ],
  },
  {
    prefix: 'far',
    iconName: 'list-edit',
    icon: [
      640,
      512,
      [],
      'f0007',
      'M488 208c83.956 0 152 68.044 152 152s-68.044 152-152 152-152-68.044-152-152 68.044-152 152-152zm24-176c35.3 0 64 28.65 64 64v111.546a174.88 174.88 0 00-47.998-18.98L528 96c0-8.84-7.2-16-16-16H64c-8.84 0-16 7.16-16 16v320c0 8.8 7.16 16 16 16l263.355.003a176.254 176.254 0 0031.9 48L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-23.347 285.131l-62.462 61.928c-2.435 2.97-4.157 5.997-4.988 9.322l-8.906 35.21c-.831 3.74.119 7.184 2.494 9.559a9.697 9.697 0 009.025 2.494l35.684-8.966a19.091 19.091 0 008.847-4.987l62.462-62.463zm26.184-26.184l-12.706 12.706 42.097 42.156 12.706-12.706c9.322-9.322 9.322-24.344 0-33.606l-8.49-8.55c-9.322-9.263-24.344-9.263-33.607 0zM128 224c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm239.204 7.998a176.597 176.597 0 00-36.012 48L224 280c-13.3 0-24-10.7-24-24s10.7-24 24-24zM128 128c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm320 8c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24s10.7-24 24-24z',
    ],
  },
  {
    prefix: 'fas',
    iconName: 'list-edit',
    icon: [
      640,
      512,
      [],
      'f0007',
      'M512 32c35.3 0 64 28.65 64 64l.001 140.05C614.734 263.6 640 308.85 640 360c0 83.947-68.053 152-152 152-35.17 0-67.55-11.944-93.304-31.998L64 480c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64zm-24 212c-64.065 0-116 51.935-116 116s51.935 116 116 116 116-51.935 116-116-51.935-116-116-116zm.653 73.131l42.156 42.097-62.462 62.463a19.091 19.091 0 01-8.847 4.987l-35.684 8.966a9.697 9.697 0 01-9.025-2.494c-2.375-2.375-3.325-5.819-2.494-9.56l8.906-35.209c.831-3.325 2.553-6.353 4.988-9.322zm26.184-26.184c9.263-9.263 24.285-9.263 33.607 0l8.49 8.55c9.322 9.262 9.322 24.284 0 33.606l-12.706 12.706-42.097-42.156zM128 224c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm277.99 8H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h134.731a152.8 152.8 0 0147.259-48zM128 128c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm320 8H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h224c13.3 0 24-10.7 24-24s-10.7-24-24-24z',
    ],
  },
];

for (let icon of icons) {
  library.add(icon);
}
