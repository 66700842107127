import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrap\">\n  <Layout::JumpToTop::Button @stick={{true}} />\n</div>", {"contents":"<div local-class=\"wrap\">\n  <Layout::JumpToTop::Button @stick={{true}} />\n</div>","moduleName":"@mvb/tix-ui/components/layout/jump-to-top/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/jump-to-top/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class LayoutJumpToTopComponent extends Component {
  @service jump;

  @action jumpToTop() {
    this.jump.scrollTo(0, 0);
  }

  @tracked showButton = false;

  @action toggle(state) {
    this.showButton = state;
  }
}
