import { isTesting, macroCondition } from '@embroider/macros';
import Service from '@ember/service';

export default class DarkmodeService extends Service {
  darkreader = null;

  enable() {
    if (this.darkreader?.isEnabled() === false) {
      this.darkreader.enable(
        { brightness: 100, contrast: 90, sepia: 10 },
        { invert: ['div::after', 'footer', 'img[alt="Aktuell kein Cover vorhanden"]', 'svg'] }
      );
    }
  }

  async setup() {
    // only import in non-test environments
    if (macroCondition(isTesting())) {
      // need this for macroCondition to work
    } else {
      try {
        this.darkreader = await import('darkreader');
        this.darkreader?.auto(false);
      } catch {
        // just to be sure in case importing fails for some reason
      }
    }
  }
}
