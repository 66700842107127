import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class ListingRecommendationHugendubelModel extends Model {
  @attr('string') code;
  @attr('number') position;
  @attr('string') text;

  @belongsTo('custom-marketing-content', { async: true, inverse: 'hugListingRecommendations' }) customMarketingContent;
}
