import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class GroupOfRecipientsModel extends Model {
  @attr('date-time') created;
  @attr('string') name;
  @attr() emails;

  @belongsTo('user', { async: false, inverse: null }) owner;
}
