import { getOwner } from '@ember/application';
import { service } from '@ember/service';
import Base from 'ember-simple-auth/authenticators/base';
import ENV from '@mvb/tix-ui/config/environment';
import fetch from 'fetch';
import ModalAgreeTos from '@mvb/tix-ui/components/user/modal-agree-tos';

class AuthenticatorError extends Error {
  constructor(message, status, statusText, detail) {
    super(message);
    this.status = status;
    this.statusText = statusText;
    this.detail = detail;
  }
}

export default class TixAuthenticator extends Base {
  @service modals;
  @service api;
  @service session;
  authenticateUrl = `${ENV.APP.api}/authenticate`;
  unauthenticateUrl = `${ENV.APP.api}/unauthenticate`;
  impersonateUrl = `${ENV.APP.api}/impersonate`;
  unimpersonateUrl = `${ENV.APP.api}/unimpersonate`;
  identifyUrl = `${ENV.APP.api}/me`;

  async fetch(url, method = 'GET', body = undefined) {
    let options = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method,
      mode: 'cors',
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    let response = await fetch(url, options);

    if (response.status === 204) {
      return {};
    }

    let formattedResponse = response.headers.get('Content-Type').includes('json')
      ? await response.json()
      : await response.text();

    if (response.status < 200 || response.status > 204) {
      let { status, statusText } = response;
      throw new AuthenticatorError(
        `${status} ${statusText}`,
        status,
        statusText,
        formattedResponse?.errors?.[0].detail
      );
    }

    return formattedResponse;
  }

  async requestUser() {
    try {
      return await this.fetch(this.identifyUrl);
    } catch (error) {
      if (error.status === 451) {
        let confirmed = await this.modals.open(ModalAgreeTos);
        if (confirmed) {
          await this.acceptTos();
          return await this.fetch(this.identifyUrl);
        }
      }
      throw error;
    }
  }

  async acceptTos() {
    this.api.post('/accept-tos');
  }

  /**
   * @typedef AuthenticationParameters
   * @property {string} [username] The username to authenticate with.
   * @property {string} [password] Required when `username` is present.
   * @property {string} [userId] User ID to impersonate
   * @property {string} [rememberMe] request long-lived session
   *
   * Calling this method with username and password will authenticate the session
   * Calling this method with a userId while authenticated will impersonate that user
   * Calling this method while impersonated without any arguments will stop impersonation
   *
   * @param {AuthenticationParameters} options
   * @returns
   */
  async authenticate({ username, password, userId, rememberMe = false } = {}) {
    if (username && password) {
      return this._authenticate(username, password, rememberMe);
    }

    if (userId) {
      return this._impersonate(userId);
    }

    return this._unimpersonate();
  }

  async _authenticate(username, password, rememberMe) {
    try {
      let body = await this.fetch(this.authenticateUrl, 'POST', {
        username,
        password,
        rememberMe,
      });

      let user = await this.requestUser();

      return { ...body, ...user };
    } catch (error) {
      error.message = 'Unable to authenticate';
      throw error;
    }
  }

  async _impersonate(userId) {
    try {
      let body = await this.fetch(this.impersonateUrl, 'POST', {
        userId,
      });
      let user = await this.requestUser();

      this._markSessionAsUnauthenticated();

      return { ...body, ...user };
    } catch (error) {
      error.message = 'Unable to impersonate';
      throw error;
    }
  }

  async _unimpersonate() {
    try {
      await this.fetch(this.unimpersonateUrl, 'POST');

      this._markSessionAsUnauthenticated();

      return await this.requestUser();
    } catch (error) {
      error.message = 'Unable to unimpersonate';
      throw error;
    }
  }

  async restore() {
    try {
      return await this.fetch(this.identifyUrl);
    } catch (error) {
      if (error.status === 451) {
        this.session.invalidate();
      }
      throw error;
    }
  }

  async invalidate() {
    return this.fetch(this.unauthenticateUrl, 'POST');
  }

  /**
   * This method is necessary as trying re-authenticate an already authenticated session will not trigger the necessary
   * events, because the _setup() method of the internal session checks for isAuthenticated and will not re-authenticate
   * if it is already authenticated.
   *
   * In our case we *do* want to re-authenticate to override the active user.
   *
   * Source: https://github.com/simplabs/ember-simple-auth/blob/32f4cce4267ede86f23ff9845bb66ee756b758fe/packages/ember-simple-auth/addon/internal-session.js#L100
   */
  _markSessionAsUnauthenticated() {
    getOwner(this).lookup('service:session').set('session.isAuthenticated', false);
  }
}
