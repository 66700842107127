import { attr, belongsTo, hasMany } from '@ember-data/model';
import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Model from './-base';
import sortBy from 'lodash-es/sortBy';

export const generatePseudoIdentifier = () => {
  // Generate a temporary url compatible pseudo-randomized and pseudo-collision-free identifier
  // to be used to reference the section in the JumpNavigation
  return btoa(Math.random() * Date.now()).replaceAll(/\W/g, '');
};

export default class SectionModel extends Model {
  @service features;

  @attr('string', {
    defaultValue() {
      return generatePseudoIdentifier();
    },
  })
  anchorId;
  @attr() changes;
  @attr('number') position;
  @attr('string', { defaultValue: SECTION_STATUS.DRAFT }) status;
  @attr('string') type;

  @belongsTo('delivery-info', { async: false, inverse: 'section' }) deliveryInfo;
  @belongsTo('preview', { async: false, inverse: 'sections' }) preview;
  @belongsTo('preview-info', { async: false, inverse: 'section' }) previewInfo;
  @belongsTo('product', { async: false, inverse: 'sections' }) product;
  @belongsTo('product-info', { async: false, inverse: 'section' }) productInfo;

  @hasMany('section-content', { async: false, inverse: 'section' }) contents;

  get ean() {
    return this.product?.get('mostSignificantIdentifier') ?? this.preview?.get('id');
  }

  get firstContent() {
    return this.sortedContents?.[0];
  }

  get hasChanges() {
    return this.features.isEnabled('changesInSection') && (this.isNew || this.changes === true);
  }

  get isDraft() {
    return this.status === SECTION_STATUS.DRAFT;
  }

  get isPublished() {
    return this.status === SECTION_STATUS.PUBLISHED;
  }

  get sortedContents() {
    return sortBy(this.contents ?? [], 'position');
  }
}
