import { validateLength } from 'ember-changeset-validations/validators/index';
import validateOrderRowDispoList from '@mvb/tix-ui/validators/order-row-dispo-list';
import validateOrderRowQuantity from '@mvb/tix-ui/validators/order-row-quantity';

export default {
  // no validation rules for collectionOfGoods and deliveryDate
  dispoList: [validateOrderRowDispoList({ description: 'intl:validation.dispolist.description.dispolist' })],
  note: [validateLength({ description: 'intl:validation.dispolist.description.note', max: 35 })],
  orderCode: [validateLength({ description: 'intl:validation.dispolist.description.orderCode', max: 15 })],
  quantity: [validateOrderRowQuantity({ description: 'intl:validation.dispolist.description.quantity' })],
};
