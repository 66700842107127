import { DateTime } from 'luxon';
import { PREVIEW_SEASON } from '@mvb/tix-ui/constants';
import Service, { service } from '@ember/service';

export default class SeasonsService extends Service {
  @service codelists;
  @service intl;

  get list() {
    return this.codelists.getCodeList('seasonTypes').map((seasonType) => {
      let entry = {
        code: seasonType.code,
        intervals: [],
        text: this.intl.t(`cl_503_${seasonType.code}`),
        value: PREVIEW_SEASON[seasonType.code],
      };

      switch (seasonType.code) {
        case '00': {
          entry.intervals.push({
            end: DateTime.fromObject({ month: 12, day: 31 }),
            start: DateTime.fromObject({ month: 1, day: 1 }),
          });
          break;
        }

        case 'Q1': {
          entry.intervals.push(
            {
              end: DateTime.fromObject({ month: 2, day: 14 }),
              start: DateTime.fromObject({ year: DateTime.local().year - 1, month: 9, day: 15 }),
            },
            {
              end: DateTime.fromObject({ year: DateTime.local().year + 1, month: 2, day: 14 }),
              start: DateTime.fromObject({ month: 9, day: 15 }),
            }
          );
          break;
        }

        case 'Q3': {
          entry.intervals.push({
            end: DateTime.fromObject({ month: 6, day: 30 }),
            start: DateTime.fromObject({ month: 2, day: 15 }),
          });
          break;
        }

        case 'Q4': {
          entry.intervals.push({
            end: DateTime.fromObject({ month: 9, day: 14 }),
            start: DateTime.fromObject({ month: 7, day: 1 }),
          });
          break;
        }
      }

      return entry;
    });
  }

  get currentSeason() {
    let currentDate = DateTime.local();
    let currentSeason = this.list.find((season) => {
      let isWithinSeasonInterval = season.intervals.some((interval) => {
        let isOnOrAfterIntervalStart = currentDate.startOf('day') >= interval.start.startOf('day');
        let isOnOrBeforeIntervalEnd = currentDate.endOf('day') <= interval.end.endOf('day');

        return isOnOrAfterIntervalStart && isOnOrBeforeIntervalEnd;
      });

      return season.value !== 'ALL_YEAR' && isWithinSeasonInterval;
    });

    return currentSeason ? currentSeason.value : 'ALL_YEAR';
  }

  get currentSeasonYear() {
    let currentDate = DateTime.local();
    let followingYearBeginning = DateTime.fromObject({ month: 9, day: 15 });

    return `${
      currentDate.startOf('day') >= followingYearBeginning.startOf('day') ? currentDate.year + 1 : currentDate.year
    }`;
  }

  get selectOptionsForSeasonCodes() {
    return this.list.map((season) => {
      return { text: season.text, value: season.code };
    });
  }

  get selectOptionsForSeasonValues() {
    return this.list.map((season) => {
      return { text: season.text, value: season.value };
    });
  }

  get selectOptionsForYears() {
    let currentYear = DateTime.local().year;

    return [
      { text: String(currentYear - 2), value: String(currentYear - 2) },
      { text: String(currentYear - 1), value: String(currentYear - 1) },
      { text: String(currentYear), value: String(currentYear) },
      { text: String(currentYear + 1), value: String(currentYear + 1) },
    ];
  }
}
