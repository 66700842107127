import { action } from '@ember/object';
import { isChangeset } from 'validated-changeset';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SearchFilterBaseComponent extends Component {
  @service manageList;

  @tracked value;

  // returns displayed count number
  get count() {
    if (Array.isArray(this.value)) {
      return this.value.length;
    }

    return isPresent(this.value) ? 1 : 0;
  }

  get defaultValue() {
    return this.args.searchService?.queryParamsDefaults?.[this.args.filter];
  }

  // extends search query to reset page and list and starts search call in search service
  search(filterQuery) {
    let query = { page: 1 };

    if (filterQuery) {
      query = { ...query, ...filterQuery };
    } else {
      query[this.args.filter] = Array.isArray(this.value) ? this.value.join(',') : this.value;
    }

    this.manageList.reset();
    this.args.searchService?.search(query);
  }

  // prepares search query if necessary and starts new search
  @action
  onChange() {
    this.search();
  }

  // sets internal value if user changes filter values
  @action
  onFilterChange(option, checked) {
    if (Array.isArray(this.value)) {
      let value = new Set(this.value);
      value[checked ? 'add' : 'delete'](option.value);

      this.value = [...value];
    } else {
      this.value = option;
    }

    this.onChange();
  }

  // resets internal value on reset and reset-all
  @action
  onReset() {
    if (isChangeset(this.value)) {
      this.value.rollback();
    } else {
      this.value = this.defaultValue ?? null;
    }
  }

  // set internal value based on value argument
  @action
  onValueArgumentChange() {
    if (undefined !== this.args.value) {
      this.value = this.args.value;
    }
  }
}
