import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateOrderListFinalizeOrderChannelId(options = {}) {
  return (key, newValue, _oldValue, changes, content) => {
    let erpName = changes?.erpName;
    let selectedOrderChannelType = changes?.selectedOrderChannelType ?? content?.selectedOrderChannelType;

    if (
      erpName === options.dependentErpName &&
      selectedOrderChannelType === options.dependentOrderChannelType &&
      !newValue
    ) {
      return buildMessage(key, { context: options, type: 'present', value: newValue });
    }

    return true;
  };
}
