import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { PREVIEW_TYPE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import ModalPreviewNewPreview from '@mvb/tix-ui/components/preview/new-preview-modal';

export default class PreviewsIndexController extends Controller {
  @service abilities;
  @service modals;
  @service('previews') previewsService;
  @service previewList;
  @service router;
  @service user;
  @service('search-previews') searchService;

  queryParams = ['qp'];
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  @action
  onAddToList() {
    this.previewList.addPreviewsToList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter,
      this.searchService.searchQuery?.sort
    );
  }

  @action
  addPreview() {
    return this.addPreviewTask.perform();
  }

  @action
  onSendPreview() {
    this.previewsService.onSendPreview(this.searchService.meta.total, this.searchService.searchQuery?.filter);
  }

  @task({ drop: true })
  *addPreviewTask() {
    let queryParams;

    queryParams = this.abilities.can('preview.selectedPartyCanCreateBookstorePreview', this.user.selectedParty)
      ? {
          cmcType: null,
          type: PREVIEW_TYPE.BOOKSTORE_PREVIEW,
        }
      : yield this.modals.open(ModalPreviewNewPreview);

    if (queryParams) {
      this.router.transitionTo('previews.add', { queryParams });
    }
  }
}
