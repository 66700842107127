import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import BackofficePartiesPartyController from './party';

export default class BackofficePartiesPublisherController extends BackofficePartiesPartyController {
  queryParams = ['page', 'premiumStatus'];

  @tracked page = 1;
  @tracked premiumStatus = false;

  @action
  onSetPremiumStatus(status) {
    this.premiumStatus = status;
  }
}
