import { isPresent } from '@ember/utils';
import { toUint8Array } from 'js-base64';
import { unpack } from 'msgpackr';
import { unzlibSync } from 'fflate';

export default function queryParamsUnpack(queryParams = {}) {
  try {
    if (isPresent(queryParams?.qp)) {
      let compressed = toUint8Array(queryParams.qp);
      let packed = unzlibSync(compressed);
      let qp = unpack(packed);

      return qp;
    }
  } catch {
    //
  }

  return {};
}
