import { helper } from '@ember/component/helper';
import { TABLE_SORT_ORDERS } from '@mvb/tix-ui/constants';

/**
 * Helper to set default sortKey and sortOrder for UI-tables
 * @param {Object} query - The query params containing current sort setting
 */
export default helper(function tableSort([query]) {
  let isSortedDescending = query?.sort?.slice(0, 1) === '-';

  let sortKey = isSortedDescending ? query?.sort?.slice(1) : query?.sort;
  let sortOrder = isSortedDescending ? TABLE_SORT_ORDERS.DESCENDING : TABLE_SORT_ORDERS.ASCENDING;
  return { sortKey, sortOrder };
});
