import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class BackofficeSettingsRoute extends Route {
  @service features;
  @service banner;

  async model() {
    await this.banner.load();

    return { banner: this.banner.current, features: this.features.features };
  }
}
