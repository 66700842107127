export default {
  "border": "_border_178fdr",
  "creator": "_creator_178fdr",
  "edit": "_edit_178fdr",
  "header": "_header_178fdr",
  "note": "_note_178fdr",
  "selected": "_selected_178fdr",
  "avatar": "_avatar_178fdr",
  "text": "_text_178fdr",
  "type": "_type_178fdr"
};
