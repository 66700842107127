export default function validateBoolean(options = {}) {
  if (typeof options === 'boolean') {
    options = { expected: options };
  }

  options = {
    ...options,
    expected: options.expected ?? undefined,
  };

  return (key, newValue /*, oldValue, changes, content */) => {
    if (newValue === options.expected) {
      return true;
    }

    return options.message || `Not a valid boolean, must be ${options.expected ? 'true' : 'false'}`;
  };
}
