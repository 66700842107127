import { isPresent } from '@ember/utils';
import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import ApplicationSerializer from './application';

const REQUIRED_FIELDS_FOR_CONTENT = {
  [SECTION_TYPES.ADVERTISING]: new Set([
    'advertisingMediumArticleNumber',
    'advertisingMediumIsbn',
    'body',
    'copyright',
    'count',
    'file',
    'headline',
  ]),
  [SECTION_TYPES.CONTAINED_PRICES]: new Set(['prices']),
  [SECTION_TYPES.CONTAINED_TITLES]: new Set(['referencedProductId', 'referencedProductIsbn']),
  [SECTION_TYPES.EXTENT]: new Set(['resourceContentType', 'file']),
  [SECTION_TYPES.IMAGE_TEXT]: new Set(['resourceMode', 'resourceContentType', 'file']),
  [SECTION_TYPES.INFO]: new Set(['file']),
  [SECTION_TYPES.KEYWORDS]: new Set(['body']),
  [SECTION_TYPES.MARKETING]: new Set(['file', 'caption', 'copyright']),
  [SECTION_TYPES.STAGE]: new Set(['file', 'urlContent']),
  [SECTION_TYPES.PACKAGE_SIZES]: new Set(['referencedProductId', 'referencedProductIsbn']),
  [SECTION_TYPES.VIDEO]: new Set(['resourceContentType', 'urlContent']),
};

/**
 * checks if the section-content is worth saving
 *
 * @param {*} sectionType the type of the section the content belongs to
 * @param {*} data the data of the section-content
 * @returns an object: either empty, when no required fields for the passed sectionType were set at the section-content; or the actual section-content data
 */
function checkForEmptyContentBySectionType(sectionType, data) {
  let requiredFields = REQUIRED_FIELDS_FOR_CONTENT[sectionType];

  for (let field of requiredFields) {
    if (isPresent(data[field])) {
      return data;
    }
  }

  return {};
}

export default class SectionSerializer extends ApplicationSerializer {
  attrs = {
    anchorId: { serialize: false },
  };

  normalize() {
    let payload = super.normalize(...arguments);

    // Adding a unique and url safe anchor name for the JumpNavigation
    payload.data.attributes.anchorId = `a-${payload.data.id}`;

    return payload;
  }

  serializeBelongsTo(snapshot, json, relationship) {
    let product = snapshot.belongsTo('product');
    let key = relationship.key;

    if (product?.record?.isProductCollection && ['deliveryInfo', 'productInfo'].includes(key)) {
      let keySnapshot = snapshot.belongsTo(key);

      if (isPresent(keySnapshot)) {
        let serializedKey = keySnapshot.serialize({ includeId: true });
        let serializedKeyAttributes = serializedKey.data.attributes;
        serializedKeyAttributes.id = keySnapshot.id;
        json.attributes[key] = serializedKeyAttributes;
      }
    } else {
      super.serializeBelongsTo(...arguments);
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    let key = relationship.key;
    let product = snapshot.belongsTo('product');
    let sectionType = snapshot.attr('type');

    if (product && product.record.isProductCollection && key === 'contents') {
      let contentSnapshots = snapshot.hasMany(key);
      let serializedContents = [];

      if (!contentSnapshots) {
        return;
      }

      for (let contentSnapshot of contentSnapshots) {
        let serializedContent = contentSnapshot.serialize({ includeId: true });

        let serializedContentAttributes = serializedContent.data.attributes;
        serializedContentAttributes.id = contentSnapshot.id;
        let contentData = contentSnapshot.isNew
          ? checkForEmptyContentBySectionType(sectionType, serializedContentAttributes)
          : serializedContentAttributes;

        // the BE will delete all contents that are not passed in the JSON payload
        if (Object.keys(contentData).length > 0 && !contentData.markedForDeletion) {
          // we do not want to persist this property, only needed to check for elements that should be deleted
          delete contentData.markedForDeletion;
          serializedContents.push(contentData);
        }
      }

      if (isPresent(serializedContents)) {
        json.attributes[key] = serializedContents;
      }
    } else {
      super.serializeHasMany(...arguments);
    }
  }
}
