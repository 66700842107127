import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import {
  PREMIUM_STATUS,
  URL_USER_REGISTER,
  USER_GENDER,
  USER_GENRE,
  USER_PLATFORMS,
  USER_TYPE,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import BlogInfoValidations from '@mvb/tix-ui/validations/blog-info';
import Controller from '@ember/controller';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import createOptions from '@mvb/tix-ui/utils/create-options';
import ENV from '@mvb/tix-ui/config/environment';
import UserPartyValidations from '@mvb/tix-ui/validations/user-party';
import UserRegistrationValidations from '@mvb/tix-ui/validations/user-registration';

const createListForEnum = (enumerable) => {
  let list = {};

  for (let key of Object.values(enumerable)) {
    list[key] = false;
  }

  return list;
};

const prepareCheckboxValuesForSaving = (object) => {
  let list = [];

  for (let [key, value] of Object.entries(object)) {
    if (value) {
      list.push(key);
    }
  }

  return list.length === 0 ? null : list;
};

export default class RegistrationController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service jump;
  @service notifications;
  @service router;
  @service store;

  get showForm() {
    return !!this.changesetBasic.type;
  }

  get showBookstoreFields() {
    return this.changesetBasic.type === USER_TYPE.BOOKSTORE;
  }

  get showPublisherFields() {
    return this.changesetBasic.type === USER_TYPE.PUBLISHER;
  }

  get showSalesCoopFields() {
    return this.changesetBasic.type === USER_TYPE.SALES_COOP;
  }

  get showJournalistFields() {
    return this.changesetBasic.type === USER_TYPE.JOURNALIST;
  }

  get showBloggerFields() {
    return this.changesetBasic.type === USER_TYPE.BLOGGER;
  }

  changesetBasic = createChangeset(
    {
      type: null,
      gender: null,
      firstname: null,
      lastname: null,
      username: null,
      address: null,
      genres: createListForEnum(USER_GENRE),
      agb: null,
      privacy: null,
      password: null,
      passwordConfirmed: null,
      premiumStatus: PREMIUM_STATUS.BASIC,
      reCaptchaResponse:
        ENV.environment !== 'test' && ENV.APP.TARGET !== 'e2e'
          ? null
          : // this is the string that is expected by wiremock to allow the recaptcha to work during e2e tests
            '03ANYolquXUt6vsF8ZwrXnacDcHMlyeC0T4IkNHzNnaSxQpfl58XpAyMkfgmyRIRAWZZO4RWKWHvlraQZ2acfSjJnvDB1k_fWetXWVnfoBEq9U9K2iDSvnQxq8zt1kyVt7nIDCiUE-_2CRFjDwrh_TvsWGyXqrX5Lh5KwUD6GshAmDd9_eKY26onc2x-4JYjV80P4C6NUTpJUJA5Bo2c9GD-XLKT7BYuvLEm6qzSzC1j4i_HPiKnbllPBqnTbechHPIRgukJOaxQBCQ834VNlbLdyHCrnMnmIIAAsG5MNrLuGMv-0OUQafPxLOqAk-EmKgq-OZL2V6ZqdiuTLwLlPFpm7sD2K4pPP7mjbULhpQJBshVGkvHewx7FQ0U6WrR56ZN_B2syw8e3GfSNTP1ZF8K1G_t1M3aSgjxwZkeks4XQjyUeVxYHpnxKAzYUmzSrBX8_yXu60EznWw',
    },
    UserRegistrationValidations
  );

  changesetAddress = createChangeset(
    {
      street: null,
      streetNumber: null,
      zipcode: null,
      city: null,
      country: null,
    },
    {}
  );

  changesetParty = createChangeset(
    {
      // FIXME: Do not use nested objects unless necessary
      party: {
        name: null,
        boevId: null,
        mvbId: null,
      },
      additional: null,
    },
    UserPartyValidations
  );

  changesetBlogger = createChangeset(
    {
      name: null,
      url: null,
      platforms: createListForEnum(USER_PLATFORMS),
    },
    BlogInfoValidations
  );

  get userTypeOptions() {
    return createOptions(this.intl, USER_TYPE, 'registration.text.userType');
  }

  get genderOptions() {
    return createOptions(this.intl, USER_GENDER, 'modelUser.text.gender');
  }

  get countryOptions() {
    return this.model.countries.map((country) => {
      return {
        value: country.code,
        text: country.translation,
      };
    });
  }

  get genreOptions() {
    return createOptions(this.intl, USER_GENRE, 'modelUser.text.genres').sort((a, b) => a.text.localeCompare(b.text));
  }

  get platformOptions() {
    return createOptions(this.intl, USER_PLATFORMS, 'modelBlogInfo.text.platforms').sort((a, b) => {
      if (a.value === USER_PLATFORMS.OTHERS) {
        return 1;
      }

      if (b.value === USER_PLATFORMS.OTHERS) {
        return -1;
      }

      return a.text.localeCompare(b.text);
    });
  }

  @action
  onSelectUserType(type) {
    if (!type) {
      this.changesetBasic.rollback();
      this.changesetParty.rollback();
      this.changesetBlogger.rollback();
    } else if (type === USER_TYPE.READER || type === USER_TYPE.JOURNALIST) {
      this.changesetParty.rollback();
      this.changesetBlogger.rollback();
    } else if (type === USER_TYPE.SALES_COOP || type === USER_TYPE.BOOKSTORE || type === USER_TYPE.PUBLISHER) {
      this.changesetBlogger.rollback();
    } else if (type === USER_TYPE.BLOGGER) {
      this.changesetParty.rollback();
    }
  }

  @action
  registerUser(event) {
    event.preventDefault();

    this.registerUserTask.perform();
  }

  @task({ drop: true })
  @waitFor
  *registerUserTask() {
    try {
      yield this.changesetBasic.validate();
      let additionalFieldsAreValid = true;

      if (this.showBookstoreFields || this.showPublisherFields) {
        yield this.changesetParty.validate();
        additionalFieldsAreValid = this.changesetParty.isValid;
      }

      if (this.showSalesCoopFields) {
        yield this.changesetParty.validate();
        additionalFieldsAreValid = this.changesetParty.isValid;
      }

      if (this.showBloggerFields) {
        yield this.changesetBlogger.validate();
        additionalFieldsAreValid = this.changesetBlogger.isValid;
      }

      if (this.changesetBasic.isValid && additionalFieldsAreValid) {
        let registrationModel = {
          type: this.changesetBasic.type,
          gender: this.changesetBasic.gender,
          title: null,
          firstname: this.changesetBasic.firstname,
          lastname: this.changesetBasic.lastname,
          username: this.changesetBasic.username,
          address: {
            street: this.changesetAddress.street,
            streetNumber: this.changesetAddress.streetNumber,
            zipcode: this.changesetAddress.zipcode,
            city: this.changesetAddress.city,
            country: this.changesetAddress.country === '' ? null : this.changesetAddress.country,
          },
          genres: prepareCheckboxValuesForSaving(this.changesetBasic.genres),
          password: this.changesetBasic.password,
          premiumStatus: this.changesetBasic.premiumStatus,
          party: {
            name: null,
            boevId: null,
            mvbId: null,
          },
          blogInfo: {
            name: null,
            url: null,
            platforms: null,
          },
        };

        if (this.showBookstoreFields || this.showPublisherFields || this.showSalesCoopFields) {
          registrationModel.party.name = this.changesetParty.party.name;
          registrationModel.party.boevId = this.changesetParty.party.boevId;
          registrationModel.party.mvbId = this.changesetParty.party.mvbId;
        }

        if (this.showBloggerFields) {
          registrationModel.blogInfo.name = this.changesetBlogger.get('name');
          registrationModel.blogInfo.url = this.changesetBlogger.get('url');
          registrationModel.blogInfo.platforms = prepareCheckboxValuesForSaving(this.changesetBlogger.get('platforms'));
        } else {
          registrationModel.blogInfo = null;
        }

        let successMessage = yield this.api.postJSON(
          `${URL_USER_REGISTER}?g-recaptcha-response=${this.changesetBasic.reCaptchaResponse}`,
          registrationModel
        );
        return htmlSafe(successMessage);
      }
    } catch (error) {
      if (error.messages) {
        for (let { code } of error.messages) {
          // set the error if there is an issue with the recaptcha
          if (code.includes('captcha')) {
            if (code.includes('captcha.invalid') || code.includes('validation.failed')) {
              this.changesetBasic.pushErrors('reCaptchaResponse', this.intl.t('registration.error.recaptchaInvalid'));
            } else if (code.includes('maxFailedAttempts.exceeded')) {
              this.changesetBasic.pushErrors(
                'reCaptchaResponse',
                this.intl.t('registration.error.maximumRecaptchaAttemptsExceeded')
              );
            }
            return window.grecaptcha.reset();
          }
          if (code.includes('email.alreadyInUse')) {
            this.router.transitionTo('registration-info');
          }

          if (code.endsWith('registration.party.mvbId.notFound') || code.endsWith('registration.party.type.invalid')) {
            this.changesetParty.pushErrors('party.mvbId', this.intl.t('registration.error.mvbIdInvalid'));

            let scrollToTarget = document.querySelector('[name="mvbId"]')?.previousElementSibling;

            if (scrollToTarget) {
              this.jump.scrollIntoView({ element: scrollToTarget });
            }
          }
        }
      }

      this.errors.handle(error);
    }
  }
}
