import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.promotionalPackageInfo}}\n  <div data-test-promotional-package-info>\n    <Layout::AsList @list={{this.promotionalPackageInfo}} as |detail|>\n      {{#if detail.icon}}\n        <Product::Icon @code={{detail.icon}} />\n      {{/if}}\n      {{~detail.text~}}\n    </Layout::AsList>\n  </div>\n{{/if}}", {"contents":"{{#if this.promotionalPackageInfo}}\n  <div data-test-promotional-package-info>\n    <Layout::AsList @list={{this.promotionalPackageInfo}} as |detail|>\n      {{#if detail.icon}}\n        <Product::Icon @code={{detail.icon}} />\n      {{/if}}\n      {{~detail.text~}}\n    </Layout::AsList>\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/bibliography/product-collection.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/bibliography/product-collection.hbs"}});
import { getProductFormTypeTranslationPath } from '@mvb/tix-ui/utils/internal-codelist-services';
import { PRODUCT_FORM_TYPES, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import filter from 'lodash-es/filter';

export default class ProductBibliographyProductCollectionComponent extends Component {
  @service intl;

  get productFormType() {
    return getProductFormTypeTranslationPath(
      this.args.product.form?.typeO3,
      this.args.product.form?.detailO3,
      this.args.product.productComposition
    );
  }

  get numberOfAdvertising() {
    let filteredSections = filter(this.args.product?.sections ?? [], { type: SECTION_TYPES.ADVERTISING });

    return filteredSections?.[0]?.sortedContents?.length ?? 0;
  }

  get numberOfProducts() {
    return this.args.containedTitles?.length || 0;
  }

  get promotionalPackageInfo() {
    let info = [];
    if (this.productFormType) {
      info.push({
        icon: PRODUCT_FORM_TYPES.PACKAGE,
        text: this.intl.t(this.productFormType),
      });
    }

    if (this.numberOfProducts) {
      info.push({
        text: this.intl.t('productBibliography.text.numberOfProducts', { number: this.numberOfProducts }),
      });
    }

    if (this.numberOfAdvertising) {
      info.push({
        text: this.intl.t('productBibliography.text.numberOfAdvertising', { ads: this.numberOfAdvertising }),
      });
    }

    return info;
  }
}
