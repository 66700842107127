export default {
  "info": "_info_1uwx2c",
  "special": "_special_1uwx2c",
  "with-stage": "_with-stage_1uwx2c",
  "info-wrapper": "_info-wrapper_1uwx2c",
  "content-wrapper": "_content-wrapper_1uwx2c",
  "bibliography": "_bibliography_1uwx2c",
  "order-lines": "_order-lines_1uwx2c",
  "marketing": "_marketing_1uwx2c",
  "marketing-collapsible": "_marketing-collapsible_1uwx2c",
  "excerpt": "_excerpt_1uwx2c",
  "labels": "_labels_1uwx2c",
  "locked": "_locked_1uwx2c",
  "content": "_content_1uwx2c",
  "cover": "_cover_1uwx2c",
  "prices": "_prices_1uwx2c",
  "price-block": "_price-block_1uwx2c",
  "stage": "_stage_1uwx2c",
  "divider": "_divider_1uwx2c",
  "cover-content": "_cover-content_1uwx2c",
  "netgalley": "_netgalley_1uwx2c",
  "without-stage": "_without-stage_1uwx2c"
};
