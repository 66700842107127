import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateUniqueBranchName() {
  return (key, newValue /* oldValue, changes, content*/) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let branchesService = applicationUtil.lookup('service:branches');

    if (branchesService.current.map((branch) => branch.name).includes(newValue.trim().replaceAll(/\s+/g, ' '))) {
      return buildMessage(key, { message: intlService.t('validation.party.branches.name.unique') });
    }

    return true;
  };
}
