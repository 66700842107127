import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.flags as |flag index|}}\n  <Ui::Label\n    data-test-flag-group={{flag.flagGroup}}\n    data-test-flag={{index}}\n    @flagGroup={{flag.flagGroup}}\n    @label={{flag.value}}\n  />\n{{/each}}\n\n{{#unless this.user.currentUserSettings.hideHighlightsBookGroups}}\n  <Product::Labels::Bookgroup @elements={{@product.highlightingBookGroups}} />\n{{/unless}}", {"contents":"{{#each this.flags as |flag index|}}\n  <Ui::Label\n    data-test-flag-group={{flag.flagGroup}}\n    data-test-flag={{index}}\n    @flagGroup={{flag.flagGroup}}\n    @label={{flag.value}}\n  />\n{{/each}}\n\n{{#unless this.user.currentUserSettings.hideHighlightsBookGroups}}\n  <Product::Labels::Bookgroup @elements={{@product.highlightingBookGroups}} />\n{{/unless}}","moduleName":"@mvb/tix-ui/components/product/labels/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/labels/index.hbs"}});
import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ProductLabelsComponent extends Component {
  @service productFlags;
  @service user;

  get flags() {
    return this.productFlags.getProductFlags({
      isPromotionalPackage: this.args.product?.isPromotionalPackage,
      isThemenspecial: this.args.product?.isThemenspecial,
      highlighting: this.highlighting,
      productFlags: this.args.product?.productFlags,
    });
  }

  get highlighting() {
    let highlightsSection = this.args.product?.sortedSections?.find(
      (section) => section.type === SECTION_TYPES.HIGHLIGHTS
    );

    if (!highlightsSection) {
      return undefined;
    }

    let sectionContent = highlightsSection.firstContent;
    return sectionContent?.body;
  }
}
