import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper\">\n  <div local-class=\"products\" data-test-contained-products-list>\n    {{#each @containedTitles as |containedTitle index|}}\n      <Sections::View::ContainedProducts::Card\n        @actions={{@actions}}\n        @index={{index}}\n        @searchProduct={{containedTitle.searchProduct}}\n      />\n    {{/each}}\n  </div>\n  {{#if (and @containedTitles @product.isThemenspecial (can \"dispoList.doMassOperations\"))}}\n    <Ui::Button\n      data-test-button-special-dispolist\n      local-class=\"dispolist-button\"\n      @style=\"primary\"\n      @onClick={{this.onAddAllToDispoList}}\n    >\n      {{t \"sectionsViewContainedProducts.action.toDispoList\"}}\n    </Ui::Button>\n  {{/if}}\n</div>", {"contents":"<div local-class=\"wrapper\">\n  <div local-class=\"products\" data-test-contained-products-list>\n    {{#each @containedTitles as |containedTitle index|}}\n      <Sections::View::ContainedProducts::Card\n        @actions={{@actions}}\n        @index={{index}}\n        @searchProduct={{containedTitle.searchProduct}}\n      />\n    {{/each}}\n  </div>\n  {{#if (and @containedTitles @product.isThemenspecial (can \"dispoList.doMassOperations\"))}}\n    <Ui::Button\n      data-test-button-special-dispolist\n      local-class=\"dispolist-button\"\n      @style=\"primary\"\n      @onClick={{this.onAddAllToDispoList}}\n    >\n      {{t \"sectionsViewContainedProducts.action.toDispoList\"}}\n    </Ui::Button>\n  {{/if}}\n</div>","moduleName":"@mvb/tix-ui/components/sections/view/contained-products/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/view/contained-products/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SectionsViewContainedProductsIndexComponent extends Component {
  @service errors;
  @service intl;
  @service dispoLists;

  @action
  onAddAllToDispoList() {
    try {
      this.dispoLists.addToDispoList({
        specialId: this.args.product?.id,
        successMessage: this.intl.t('modalAddToDispoList.notification.success'),
        title: this.intl.t('modalAddToDispoList.text.title'),
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
