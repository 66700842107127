import { CURRENCIES_DACH, NOTE_TYPES, SUGGEST_CMC_TYPES, URL_SUGGEST_CMC } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import createOptions from '@mvb/tix-ui/utils/create-options';

export default class ProfileSettingsController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service user;

  get currencyCountryOptions() {
    return createOptions(this.intl, CURRENCIES_DACH, 'profileSettings.options');
  }

  get selectedCurrencyCountry() {
    return `${this.model.user.currency}_${this.model.user.currencyCountry}`;
  }

  get noteTypeOptions() {
    return createOptions(
      this.intl,
      [NOTE_TYPES.PARTY, NOTE_TYPES.GROUPS, NOTE_TYPES.PRIVATE],
      'profileSettings.noteTypes'
    );
  }

  get suggestTypes() {
    return Object.keys(SUGGEST_CMC_TYPES);
  }

  @task({ restartable: true })
  @waitFor
  *changeCurrencyCountryTask(currencyCountry) {
    try {
      let ccAry = currencyCountry.split('_');

      // update the selection
      this.model.user.currency = ccAry[0];
      this.model.user.currencyCountry = ccAry[1];

      // save the model
      yield this.model.user.save();
      yield this.user.load();

      this.notifications.success(this.intl.t('profileSettings.notification.currency'));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  *deleteSuggestsForTypeTask(type) {
    let typeConstant = SUGGEST_CMC_TYPES[type];

    try {
      let typeLabel = this.intl.t(`profileSettings.suggest.type.${typeConstant}`);

      let confirmed = yield this.modals.confirm({
        title: this.intl.t('profileSettings.modal.title'),
        message: this.intl.t('profileSettings.modal.message', { type: typeLabel }),
        confirm: this.intl.t('profileSettings.modal.confirm'),
        cancel: this.intl.t('profileSettings.modal.cancel'),
      });

      if (!confirmed) {
        return;
      }

      let urlForType = URL_SUGGEST_CMC.replace('{type}', typeConstant);
      yield this.api.delete(urlForType);

      this.notifications.success(this.intl.t('profileSettings.notification.hugendubelSuggests', { type: typeLabel }));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *toggleEditDispoListEntriesTask(value) {
    try {
      this.user.currentUserSettings.editDispoListEntries = value;
      yield this.user.currentUserSettings.save();

      this.notifications.success(this.intl.t('profileSettings.notification.editDispoListEntries'));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *toggleHideHighlightsBookGroupsTask(value) {
    try {
      this.user.currentUserSettings.hideHighlightsBookGroups = value;
      yield this.user.currentUserSettings.save();

      this.notifications.success(this.intl.t('profileSettings.notification.highlightsBookGroups'));
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *changeNotesDefaultTask(noteType) {
    try {
      // update the selection
      this.user.currentUserSettings.notesDefault = noteType;

      // save the model
      yield this.user.currentUserSettings.save();

      this.notifications.success(this.intl.t('profileSettings.notification.notesDefault'));
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
