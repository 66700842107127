import { isChangeset } from 'validated-changeset';
import { isPresent } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateErpSetupBranchIdUniqueness(options = {}) {
  return async (key, newValue, _oldValue, _changes, content) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');

    let branchSetupChangesets = options?.branchSetupChangesets || [];
    let value = String(newValue).trim().toLowerCase();

    if (
      branchSetupChangesets
        .filter((branchChangeset) => {
          if (isChangeset(branchChangeset)) {
            return branchChangeset.data !== content && isPresent(branchChangeset.get('identifier'));
          }
          return branchChangeset !== content && isPresent(branchChangeset.identifier);
        })
        .map((branchChangeset) => {
          if (isChangeset(branchChangeset)) {
            return String(branchChangeset.get('identifier')).trim().toLowerCase();
          }
          return String(branchChangeset.identifier).trim().toLowerCase();
        })
        .includes(value)
    ) {
      return buildMessage(key, {
        message: intlService.t('validation.uniqueness.notUnique', {
          gender: 'female',
          value: intlService.t('validation.party.erp.branch.identifier.description'),
        }),
      });
    }

    return true;
  };
}
