import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.readCopyUrl}}\n  {{#if @withJumpAnchor}}\n    <Layout::JumpAnchor @title={{t \"productsProduct.anchor.netgalley\"}} @flag={{true}} />\n  {{/if}}\n\n  <div local-class=\"wrapper\">\n    <p data-test-netgalley-read-copy>\n      {{html-safe (t \"productNetgalleyReadCopy.text.readCopy\" url=this.readCopyUrl title=@product.mainTitle.title)}}\n    </p>\n  </div>\n{{/if}}", {"contents":"{{#if this.readCopyUrl}}\n  {{#if @withJumpAnchor}}\n    <Layout::JumpAnchor @title={{t \"productsProduct.anchor.netgalley\"}} @flag={{true}} />\n  {{/if}}\n\n  <div local-class=\"wrapper\">\n    <p data-test-netgalley-read-copy>\n      {{html-safe (t \"productNetgalleyReadCopy.text.readCopy\" url=this.readCopyUrl title=@product.mainTitle.title)}}\n    </p>\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/netgalley-read-copy/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/netgalley-read-copy/index.hbs"}});
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export default class ProductNetgalleyReadCopyComponent extends Component {
  @service api;

  get readCopyUrl() {
    return this.loadReadCopyUrl.lastSuccessful?.value ?? null;
  }

  constructor() {
    super(...arguments);
    this.loadReadCopyUrl.perform();
  }

  @task
  @waitFor
  *loadReadCopyUrl() {
    try {
      return yield this.api.get(`/read_copy/${this.args.product.id}`);
    } catch {
      // no error handling here, as we do not want to show any error messages and the component will just not render
      // 403 - user is not allowed to see the read copy - will be handled via ability in template (product/view-page/info)
      return null;
    }
  }
}
