import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.highlights key=\"position\" as |entry index|}}\n  <Sections::Edit::Highlights::Highlight\n    @changeset={{this.changeset}}\n    @highlightTypes={{this.highlightTypes}}\n    @index={{index}}\n    @onDeleteItem={{this.onDeleteItem}}\n    @onUpdateItem={{this.onUpdateItem}}\n    @selectedHighlight={{entry}}\n  />\n{{/each}}\n\n{{#if this.canAddHighlight}}\n  <div local-class=\"add-button\">\n    <Ui::Button data-test-add-highlight @style=\"link-secondary\" @icon=\"circle-plus\" @onClick={{this.onAddItem}}>\n      {{t \"sectionsEditHighlight.action.add\"}}\n    </Ui::Button>\n  </div>\n{{/if}}", {"contents":"{{#each this.highlights key=\"position\" as |entry index|}}\n  <Sections::Edit::Highlights::Highlight\n    @changeset={{this.changeset}}\n    @highlightTypes={{this.highlightTypes}}\n    @index={{index}}\n    @onDeleteItem={{this.onDeleteItem}}\n    @onUpdateItem={{this.onUpdateItem}}\n    @selectedHighlight={{entry}}\n  />\n{{/each}}\n\n{{#if this.canAddHighlight}}\n  <div local-class=\"add-button\">\n    <Ui::Button data-test-add-highlight @style=\"link-secondary\" @icon=\"circle-plus\" @onClick={{this.onAddItem}}>\n      {{t \"sectionsEditHighlight.action.add\"}}\n    </Ui::Button>\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/sections/edit/highlights/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/highlights/index.hbs"}});
import { action } from '@ember/object';
import { audienceAlreadyUsed } from './highlight';
import { HIGHLIGHT_AUDIENCES, HIGHLIGHT_AUDIENCES_ORDERED, SECTION_CONTENT_TYPES } from '@mvb/tix-ui/constants';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import MachineEditModeChildComponent from '@mvb/tix-ui/components/machine-edit-mode/child';
import sortBy from 'lodash-es/sortBy';

export default class SectionsEditHighlightsIndexComponent extends MachineEditModeChildComponent {
  @service codelists;
  @service intl;
  @service store;

  @tracked changeset = null;
  @tracked content = null;

  get canAddHighlight() {
    return (
      !audienceAlreadyUsed(HIGHLIGHT_AUDIENCES.ALL, this.changeset) &&
      this.highlights.length < HIGHLIGHT_AUDIENCES_ORDERED.length - 1
    ); //at least 1 unused audience (except "all")
  }

  get highlightTypes() {
    let options = this.codelists.getCodeListOptions('highlightTypes');
    return sortBy(options, 'text');
  }

  constructor() {
    super(...arguments);
    next(() => {
      this.initialize();
    });
  }

  async initialize() {
    this.content = await this.args.section?.firstContent;

    if (!this.content) {
      this.content = this.store.createRecord('section-content', {
        body: '{}',
        contentType: SECTION_CONTENT_TYPES.TIX,
        section: this.args.section,
        position: 0,
      });

      this.content.highlights = JSON.parse(this.content.body);
    }

    this.changeset = createChangeset(this.content);

    if (this.content?.isNew) {
      this.changeset.set(`highlights.${HIGHLIGHT_AUDIENCES.ALL}.position`, 0);
      this.changeset.set(`highlights.${HIGHLIGHT_AUDIENCES.ALL}.code`, this.highlightTypes[0].value);

      this.sendInitialChange();
    }
  }

  get highlights() {
    if (!this.changeset) {
      return [];
    }

    let highlights = [];

    for (let audience of HIGHLIGHT_AUDIENCES_ORDERED) {
      let code = this.changeset.get(`highlights.${audience}.code`);
      let position = this.changeset.get(`highlights.${audience}.position`);

      if (code) {
        highlights.push({
          audience,
          code,
          position,
        });
      }
    }
    return sortBy(highlights, 'position');
  }

  @action
  onAddItem() {
    this.machine.send('UPDATE');
    let firstAvailableAudience = HIGHLIGHT_AUDIENCES_ORDERED.find(
      (audience) => !audienceAlreadyUsed(audience, this.changeset) && audience !== HIGHLIGHT_AUDIENCES.ALL
    );
    let codeOfLastHighlight = this.highlights[this.highlights.length - 1]?.code ?? this.highlightTypes[0];

    this.changeset.set(`highlights.${firstAvailableAudience}.position`, this.highlights.length);
    this.changeset.set(`highlights.${firstAvailableAudience}.code`, codeOfLastHighlight);

    this.machine.send(this.changeset.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  @action
  onUpdateItem({ currentPosition, currentAudience, nextAudience, nextValue }) {
    this.machine.send('UPDATE');
    this.changeset.set(`highlights.${currentAudience}.position`, null);
    this.changeset.set(`highlights.${currentAudience}.code`, null);
    this.changeset.set(`highlights.${nextAudience}.position`, currentPosition);
    this.changeset.set(`highlights.${nextAudience}.code`, nextValue);

    this.machine.send(this.changeset.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  @action
  onDeleteItem({ currentPosition, currentAudience }) {
    this.machine.send('UPDATE');

    this.changeset.set(`highlights.${currentAudience}.position`, null);
    this.changeset.set(`highlights.${currentAudience}.code`, null);

    for (let highlight of this.highlights) {
      if (highlight.position > currentPosition) {
        this.changeset.set(`highlights.${highlight.audience}.position`, --highlight.position);
      }
    }

    this.machine.send(this.changeset.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  willDestroy() {
    if (this.store.isDestroyed || this.store.isDestroying) {
      return;
    }

    if (this.content?.isNew) {
      this.args.section.unloadRecord();
      this.content.unloadRecord();
    }

    super.willDestroy(...arguments);
  }
}
