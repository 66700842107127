import { addOnce, doesNotIncludeVLBContent, doNotRemove } from '@mvb/tix-ui/utils/apply-rule';
import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Controller from '@ember/controller';

const availableTypesDefault = new Map([
  [
    SECTION_TYPES.STAGE,
    {
      icon: 'presentation-screen',
      sortable: false,
      position: () => 0,
      add: addOnce,
      remove: doesNotIncludeVLBContent,
    },
  ],
  [
    SECTION_TYPES.INFO,
    {
      icon: 'table-columns',
      sortable: false,
      required: true,
      position: () => 1,
      add: addOnce,
      remove: doNotRemove,
    },
  ],
  [
    SECTION_TYPES.HIGHLIGHTS,
    {
      icon: 'highlighter',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.BIBLIOGRAPHY,
    {
      icon: 'file-lines',
      sortable: true,
      add: addOnce,
      remove: doNotRemove,
    },
  ],
  [
    SECTION_TYPES.SALES_NOTES,
    {
      icon: 'ballot',
      sortable: true,
      required: true,
      position: () => 4,
      add: addOnce,
      remove: doNotRemove,
    },
  ],
  [
    SECTION_TYPES.REFERENCETITLE,
    {
      icon: 'book',
      sortable: true,
      add: addOnce,
      remove: doNotRemove,
    },
  ],
  [
    SECTION_TYPES.CONTRIBUTOR,
    {
      icon: 'user',
      sortable: true,
      add: addOnce,
      remove: doNotRemove,
    },
  ],
  [
    SECTION_TYPES.MARKETING,
    {
      icon: 'ad',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.ADVERTISING,
    {
      icon: 'megaphone',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.VIDEO,
    {
      icon: 'video',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.EXTENT,
    {
      icon: 'images',
      sortable: true,
      add: addOnce,
      remove: doesNotIncludeVLBContent,
    },
  ],
  [
    SECTION_TYPES.IMAGE_TEXT,
    {
      icon: 'address-card',
      sortable: true,
      add: () => true,
    },
  ],
  [
    SECTION_TYPES.REVIEW,
    {
      icon: 'comment-quote',
      iconPrefix: 'fa',
      sortable: true,
      add: addOnce,
      required: true,
      remove: doNotRemove,
    },
  ],
]);

export const availableTypesPromotionalPackage = new Map([
  [
    SECTION_TYPES.STAGE,
    {
      fixed: true,
      required: true,
      position: () => 0,
    },
  ],
  [
    SECTION_TYPES.INFO,
    {
      fixed: true,
      required: true,
      position: () => 1,
    },
  ],
  [
    SECTION_TYPES.DATE,
    {
      fixed: true,
      required: true,
      position: () => 2,
    },
  ],
  [
    SECTION_TYPES.PACKAGE_SIZES,
    {
      fixed: true,
      required: true,
      position: () => 3,
    },
  ],
  [
    SECTION_TYPES.CONTAINED_TITLES,
    {
      fixed: true,
      required: true,
      position: () => 4,
    },
  ],
  [
    SECTION_TYPES.ADVERTISING,
    {
      fixed: true,
      required: true,
      position: () => 5,
    },
  ],
  [
    SECTION_TYPES.CONTAINED_PRICES,
    {
      fixed: true,
      required: true,
      position: () => 6,
    },
  ],
  [
    SECTION_TYPES.EXTENT,
    {
      fixed: true,
      required: true,
      position: () => 7,
    },
  ],
  [
    SECTION_TYPES.KEYWORDS,
    {
      fixed: true,
      required: true,
      position: () => 8,
    },
  ],
]);

export const availableTypesSpecial = new Map([
  [
    SECTION_TYPES.STAGE,
    {
      fixed: true,
      required: true,
      position: () => 0,
    },
  ],
  [
    SECTION_TYPES.INFO,
    {
      fixed: true,
      required: true,
      position: () => 1,
    },
  ],
  [
    SECTION_TYPES.CONTAINED_TITLES,
    {
      fixed: true,
      required: true,
      position: () => 2,
    },
  ],
  [
    SECTION_TYPES.MARKETING,
    {
      icon: 'ad',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.ADVERTISING,
    {
      icon: 'megaphone',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.VIDEO,
    {
      icon: 'video',
      sortable: true,
      add: addOnce,
    },
  ],
  [
    SECTION_TYPES.EXTENT,
    {
      icon: 'images',
      sortable: true,
      add: addOnce,
      remove: doesNotIncludeVLBContent,
    },
  ],
  [
    SECTION_TYPES.IMAGE_TEXT,
    {
      icon: 'address-card',
      sortable: true,
      add: () => true,
    },
  ],
]);

export default class ProductsProductEditController extends Controller {
  @service('section-contents-product') sectionContentsProduct;
  @service('section-contents-promotional-package') sectionContentsPromotionalPackage;
  @service('section-contents-special') sectionContentsSpecial;

  get sectionContentService() {
    if (this.model?.isPromotionalPackage) {
      return this.sectionContentsPromotionalPackage;
    }

    if (this.model?.isThemenspecial) {
      return this.sectionContentsSpecial;
    }

    return this.sectionContentsProduct;
  }

  get availableTypesMap() {
    if (this.model.isPromotionalPackage) {
      return availableTypesPromotionalPackage;
    }

    if (this.model.isThemenspecial) {
      return availableTypesSpecial;
    }

    return availableTypesDefault;
  }
}
