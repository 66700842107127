import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class BlacklistRoute extends Route {
  @service api;

  model({ partyId }) {
    return this.api.fetch(`/party/${partyId}/name`);
  }
}
