import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (changeset @model this.validations) as |changeset|}}\n  <Ui::Input::Text\n    @name=\"description\"\n    @label={{t \"sections.edit.description.label\"}}\n    @changeset={{changeset}}\n    @path={{this.path}}\n    @required={{@required}}\n    @disabled={{@disabled}}\n  />\n{{/let}}", {"contents":"{{#let (changeset @model this.validations) as |changeset|}}\n  <Ui::Input::Text\n    @name=\"description\"\n    @label={{t \"sections.edit.description.label\"}}\n    @changeset={{changeset}}\n    @path={{this.path}}\n    @required={{@required}}\n    @disabled={{@disabled}}\n  />\n{{/let}}","moduleName":"@mvb/tix-ui/components/sections/edit/description/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/description/index.hbs"}});
import { service } from '@ember/service';
import { validatePresence } from 'ember-changeset-validations/validators/index';
import Component from '@glimmer/component';

export default class SectionsEditDescriptionIndexComponent extends Component {
  @service intl;

  get path() {
    return this.args.path ?? 'description';
  }

  validations = {
    [this.path]: [
      validatePresence({ presence: true, description: this.intl.t('validation.general.description.subtitle') }),
    ],
  };
}
