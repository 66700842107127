import { action } from '@ember/object';
import { camelize } from '@ember/string';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class BackofficeSendMailController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service notifications;
  @service router;

  queryParams = ['template', 'uid'];

  @tracked mailBody = this.model.message;
  @tracked template = '';
  @tracked uid = '';

  get title() {
    let template = camelize(this.template);
    return this.intl.t(`sendMail.title.${template}`);
  }

  @action
  onTextChange(text) {
    this.mailBody = text;
  }

  @action
  async sendMail(event) {
    event.preventDefault();

    try {
      await this.api.postJSON(`/user/${this.uid}/${this.template}`, {
        mailText: this.mailBody,
      });

      this.notifications.success(this.intl.t('sendMail.notification.success'));

      // transition back to last page after successfully sending the mail
      this.toPreviousRoute();
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  cancel(event) {
    event.preventDefault();
    this.toPreviousRoute();
  }

  @action
  toPreviousRoute() {
    if (this.previousPageTransition) {
      let { name, params, paramNames, queryParams } = this.previousPageTransition;

      let orderedParams = paramNames.map((n) => params[n]);
      this.router.transitionTo(name, ...orderedParams, {
        queryParams,
      });
    } else {
      this.router.transitionTo('backoffice');
    }
  }
}
