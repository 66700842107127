import { validateFormat, validatePresence } from 'ember-changeset-validations/validators/index';

export default {
  name: [
    validatePresence({ presence: true, ignoreBlank: true, description: 'intl:validation.user.description.blogName' }),
  ],
  url: [
    validatePresence({ presence: true, ignoreBlank: true, description: 'intl:validation.user.description.blogUrl' }),
    validateFormat({ type: 'url', description: 'intl:validation.user.description.blogUrl' }),
  ],
};
