import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import Model from './-base';

export default class CustomerContactModel extends Model {
  @service intl;

  @attr('string') comment;
  @attr('string') contactType;

  @belongsTo('party', { async: false, inverse: 'customerContacts' }) party;
  @belongsTo('user', { async: false, inverse: 'customerContacts' }) user;

  get contactTypeTranslated() {
    return this.contactType ? this.intl.t(`modelCustomerContact.text.contactType.${this.contactType}`) : '';
  }
}
