import { action } from '@ember/object';
import ENV from '@mvb/tix-ui/config/environment';
import Service, { service } from '@ember/service';
import window from 'ember-window-mock';

export default class MetricsService extends Service {
  @service router;
  @service session;
  @service user;

  wasTrackSpecific = false;

  constructor() {
    super(...arguments);

    let { piwikUrl, siteId, dimensions } = ENV.metricsAdapters?.[0]?.config ?? {};

    this.piwikUrl = piwikUrl ?? false;
    this.siteId = siteId ?? false;
    this.dimensions = dimensions ?? {};
    this.dimensionKeys = Object.keys(this.dimensions);
    this.push(['enableLinkTracking']);

    let isTesting = ENV.APP?.TARGET === 'e2e' || ENV.environment === 'development' || ENV.environment === 'test';
    if (isTesting) {
      // ignoring user consent, injecting Matomo if url and id are set
      if (this.piwikUrl && this.siteId) {
        this._injectMatomoScript(this.piwikUrl, this.siteId);
      }
    } else {
      // injecting cookie consent
      this._injectCFScript();

      // watching for consent updates
      // https://support.cookiefirst.com/hc/de/articles/360011568738-Javascript-API
      window.addEventListener('cf_consent', this.onConsentUpdate);

      // checking cookies, if consent is already there
      try {
        let cookiePreferencesURIEncoded = document.cookie.split('; ').find((c) => c.match('cookiefirst')) ?? '%7B%7D'; // '{}'
        let cookiePreferencesString = decodeURIComponent(
          cookiePreferencesURIEncoded.replace('cookiefirst-consent=', '')
        );
        let cookiePreferences = JSON.parse(cookiePreferencesString);

        this.allowPerformanceTracking = cookiePreferences.performance || false;
      } catch {
        // do nothing
      }

      if (this.piwikUrl && this.siteId && this.allowPerformanceTracking) {
        this._injectMatomoScript(this.piwikUrl, this.siteId);
      }
    }
  }

  push() {
    window._paq = window._paq || [];
    return window._paq.push(...arguments);
  }

  _injectMatomoScript(piwikUrl, siteId) {
    window._paq = window._paq || [];
    window._paq.push(['setTrackerUrl', `${piwikUrl}/piwik.php`], ['setSiteId', siteId]);
    let d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = `${piwikUrl}/piwik.js`;
    s.parentNode.insertBefore(g, s);
  }

  _injectCFScript() {
    let d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.src = 'https://consent.cookiefirst.com/sites/vlbtix.de-6e677cec-3c66-488e-9ecc-e36327c64b7c/consent.js';
    s.parentNode.insertBefore(g, s);
  }

  @action
  onConsentUpdate(event) {
    // inject Matomo script, if user opts in and matomo has not been initialized, yet
    this.allowPerformanceTracking = event.detail?.performance || false;
    let isMatomoInitialized = window.Matomo?.initialzied || false;
    if (this.allowPerformanceTracking && !isMatomoInitialized) {
      this._injectMatomoScript(this.piwikUrl, this.siteId);
    }
  }

  identify(options = {}) {
    this.push(['setUserId', options.userId]);
  }

  unidentify() {
    this.push(['resetUserId']);
  }

  trackPage(options) {
    // Skip tracking while impersonated
    if (this.session.isImpersonated) {
      return;
    }

    // skip generic trackPage() when trackSpecific() was called
    if (this.wasTrackSpecific) {
      this.wasTrackSpecific = false;
      return;
    }

    window._paq = window._paq || [];
    let { page, title } = options ?? {};

    page = page || this.router.currentURL;
    title = this._buildTitle(title);

    this.push(['setCustomUrl', page], ['trackPageView', title]);
  }

  trackSpecific(options = {}) {
    // Skip tracking while impersonated
    if (this.session.isImpersonated) {
      return;
    }

    options.MVBID = this.user.selectedParty?.party?.mvbId;
    options.usertype = this.user.current?.type ?? 'unknown';

    let { MVBID, title } = options;
    let titleSegments = [];

    if (MVBID && title !== undefined) {
      titleSegments.push(`MVBID=${MVBID}`);
    }

    titleSegments.push(this._buildTitle(title));
    title = titleSegments.join(';');

    let dimensions = {};
    for (let key of this.dimensionKeys) {
      dimensions[`dimension${this.dimensions[key]}`] = options[key] ?? '';
    }

    this.push(['trackPageView', title, dimensions]);
    this.wasTrackSpecific = true;
  }

  _buildTitle(title) {
    if (typeof title === 'object') {
      title = Object.entries(title)
        .map(([key, value]) => `${key}=${value}`)
        .join(';');
    }

    return title || document.title || this.router.currentRouteName || 'unknown';
  }
}
