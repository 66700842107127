import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class BackofficeRoute extends Route {
  @service abilities;
  @service router;
  @service session;

  beforeModel(transition) {
    if (!this.session.requireAuthentication(transition)) {
      return;
    }

    if (this.abilities.cannot('backoffice.access')) {
      return this.router.replaceWith('index');
    }
  }
}
