import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Layout::Loading ...attributes local-class=\"loading\" data-test-progress-simple @message={{this.message}} />", {"contents":"<Layout::Loading ...attributes local-class=\"loading\" data-test-progress-simple @message={{this.message}} />","moduleName":"@mvb/tix-ui/components/layout/progress/simple.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/layout/progress/simple.hbs"}});
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import shuffle from 'lodash-es/shuffle';

export default class LayoutProgressSimpleComponent extends Component {
  @service intl;

  @tracked _message = undefined;

  messages = shuffle(this.intl.t('layoutProgress.text.messages').split(';'));

  get message() {
    return htmlSafe(this.args.progress?.title || this.args.progress?.message || this._message || '');
  }

  constructor() {
    super(...arguments);
    this.cycleMessages();
  }

  willDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    super.willDestroy(...arguments);
  }

  cycleMessages() {
    this.interval = setInterval(() => {
      if (this.args.progress?.title || this.args.progress?.message) {
        return;
      }

      let message = this.messages.shift();
      this.messages.push(message);
      this._message = message;
    }, 1500);
  }
}
