import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from './-base';

export default class ErpSetupModel extends Model {
  @attr() identifier;
  @attr() target;
  @attr('string') username;

  @belongsTo('erp', { async: false, inverse: null }) erp;
  @belongsTo('party-setting', { async: false, inverse: 'erpSetup' }) partySettings;

  @hasMany('erp-branch-setup', { async: false, inverse: 'erpSetup' }) erpBranchSetups;
  @hasMany('order-channel', { async: false, inverse: 'erpSetup' }) orderChannels;
}
