import { assert, runInDebug } from '@ember/debug';

export const addOnce = ({ sections, type }) => {
  for (let section of sections ?? []) {
    if (section.type === type && !section.isDeleted) {
      return false;
    }
  }

  return true;
};

export const doNotRemove = () => false;

export const doesNotIncludeVLBContent = ({ section }) => {
  return section.contents.every((content) => !content.isVLBContent);
};

export default function applyRule(rule, availableTypes, { sections, section, type, desiredValue }) {
  let sectionType = availableTypes.get(type);
  let ruleCallback = sectionType?.[rule];

  runInDebug(() => {
    assert(`desiredValue cannot be undefined`, desiredValue !== undefined);
    assert(
      `ruleCallback must be either a function or undefined`,
      ruleCallback === undefined || typeof ruleCallback === 'function'
    );
  });

  if (typeof ruleCallback === 'function') {
    return ruleCallback({ sections, section, type, desiredValue });
  }

  return desiredValue;
}
