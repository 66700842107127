import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class OrderChannelModel extends Model {
  @attr() created;
  @attr() description;
  @attr() identifier;

  @belongsTo('erp-setup', { async: false, inverse: 'orderChannels' }) erpSetup;
}
