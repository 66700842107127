export default {
  "color-success": "var(--teal-100)",
  "color-error": "var(--red-100)",
  "color-primary": "var(--orange-100)",
  "indicator-wrapper": "_indicator-wrapper_rijy6i",
  "indicator": "_indicator_rijy6i",
  "available": "_available_rijy6i",
  "limited": "_limited_rijy6i",
  "unavailable": "_unavailable_rijy6i"
};
