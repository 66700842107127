import { isPresent } from '@ember/utils';
import { SEARCH_MODE } from '@mvb/tix-ui/constants';
import SearchProductsService from './search-products';

export default class SearchNotesOverviewService extends SearchProductsService {
  get queryParamsDefaults() {
    return {
      ...this.queryParamsDefaultsForProducts,
      ...this.queryParamsDefaultsForNotes,
      withNotes: true,
    };
  }

  createSearchQuery({ queryParams }) {
    let { backlist, page, q, size, sort } = queryParams;
    let query = {
      filter: {
        backlist,
        onList: true,
        searchMode: SEARCH_MODE.ADVANCED,
        notesOverview: true,
        withNotes: true,
      },
      include: 'notes',
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersOfNotesToSearchQuery({ query, queryParams });
    this.addFiltersToSearchQuery({ query, queryParams });

    if (this.features.isEnabled('changesInPromotionalPackage')) {
      query.filter.changes = true;
    }

    if (isPresent(q)) {
      query.filter.searchTerm = q;
    }

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }
}
