export default {
  "color-text": "var(--gray-100)",
  "color-error": "var(--red-100)",
  "label": "_label_wfzhnr _label_laqy2u",
  "hidden-label": "_hidden-label_wfzhnr hidden-aria",
  "input-wrapper": "_input-wrapper_wfzhnr",
  "input": "_input_wfzhnr _input_fam08e",
  "has-error": "_has-error_wfzhnr _has-error_laqy2u",
  "toggle": "_toggle_wfzhnr"
};
