import Inflector from 'ember-inflector';

export function initialize(/* application */) {
  let inflector = Inflector.inflector;
  // irregulars
  inflector.irregular('listing-recommendation-hugendubel', 'listing-recommendations-hugendubel');
  inflector.irregular('marketing-recommendation-hugendubel', 'marketing-recommendations-hugendubel');
  inflector.irregular('marketing-recommendation-trc', 'marketing-recommendations-trc');
  // uncountables
  inflector.uncountable('collection-of-goods');
  inflector.uncountable('category-of-goods');
  inflector.uncountable('group-of-recipients');
}

export default {
  name: 'custom-inflector-rules',
  initialize,
};
