import { validatePresence } from 'ember-changeset-validations/validators/index';

export default function validateErpSalesSetup(branches) {
  return branches?.length > 0
    ? {}
    : {
        username: [
          validatePresence({
            presence: true,
            description: 'intl:validation.party.erpSales.branch.username.description',
          }),
        ],
        password: [
          validatePresence({
            presence: true,
            description: 'intl:validation.party.erpSales.branch.password.description',
          }),
        ],
      };
}
