import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class ErpBranchSetupModel extends Model {
  @attr() created;
  @attr() identifier;

  @belongsTo('bookstore-branch', { async: false, inverse: null }) branch;
  @belongsTo('erp-setup', { async: false, inverse: 'erpBranchSetups' }) erpSetup;
}
