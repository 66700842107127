import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Modal::Base @title={{@data.title}} @onClose={{@close}} data-test-excerpt-modal local-class=\"excerpt\">\n  {{#if this.showIframe}}\n    <iframe src={{@data.excerpt}} title={{@data.title}}></iframe>\n  {{else}}\n    <div data-test-excerpt-url>\n      {{@data.excerpt}}\n    </div>\n  {{/if}}\n  {{yield}}\n</Ui::Modal::Base>", {"contents":"<Ui::Modal::Base @title={{@data.title}} @onClose={{@close}} data-test-excerpt-modal local-class=\"excerpt\">\n  {{#if this.showIframe}}\n    <iframe src={{@data.excerpt}} title={{@data.title}}></iframe>\n  {{else}}\n    <div data-test-excerpt-url>\n      {{@data.excerpt}}\n    </div>\n  {{/if}}\n  {{yield}}\n</Ui::Modal::Base>","moduleName":"@mvb/tix-ui/components/product/excerpt/modal.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/excerpt/modal.hbs"}});
import Component from '@glimmer/component';
import config from '@mvb/tix-ui/config/environment';

export default class ProductExcerptModalComponent extends Component {
  get showIframe() {
    return config.environment !== 'test';
  }
}
