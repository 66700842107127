import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyBookstoreBranchesRoute extends Route {
  @service abilities;
  @service branches;

  model() {
    if (this.abilities.can('party.viewBookstoreBranches')) {
      return this.branches.load();
    }
  }
}
