import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasStage}}\n  {{#if this.isAnimated}}\n    <div local-class=\"stage\" {{on-resize this.scale}}>\n      <iframe\n        data-test-party-stage-iframe\n        local-class=\"iframe\"\n        sandbox=\"allow-scripts\"\n        src={{this.stageSrc}}\n        style={{this.scalingFactor}}\n        title={{t \"sectionsViewPartyStage.text.altStage\" text=@party.name}}\n      ></iframe>\n    </div>\n  {{else}}\n    <img\n      data-test-party-stage-image\n      local-class=\"stage-img\"\n      src={{this.stageSrc}}\n      alt={{t \"sectionsViewPartyStage.text.altStage\" text=@party.name}}\n      {{prevent-contextmenu}}\n    />\n  {{/if}}\n{{/if}}", {"contents":"{{#if this.hasStage}}\n  {{#if this.isAnimated}}\n    <div local-class=\"stage\" {{on-resize this.scale}}>\n      <iframe\n        data-test-party-stage-iframe\n        local-class=\"iframe\"\n        sandbox=\"allow-scripts\"\n        src={{this.stageSrc}}\n        style={{this.scalingFactor}}\n        title={{t \"sectionsViewPartyStage.text.altStage\" text=@party.name}}\n      ></iframe>\n    </div>\n  {{else}}\n    <img\n      data-test-party-stage-image\n      local-class=\"stage-img\"\n      src={{this.stageSrc}}\n      alt={{t \"sectionsViewPartyStage.text.altStage\" text=@party.name}}\n      {{prevent-contextmenu}}\n    />\n  {{/if}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/sections/view/party-stage/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/view/party-stage/index.hbs"}});
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SectionsViewPartyStageIndexComponent extends Component {
  @tracked hasStage = false;
  @tracked isAnimated;
  @tracked stageSrc;
  @tracked _scalingFactor = 1;
  @tracked stageHeight = 300;

  constructor() {
    super(...arguments);

    let partyInfo = this.args.partyInfo;

    this.hasStage = partyInfo?.stageUrl || partyInfo?.stage;
    this.isAnimated = isPresent(partyInfo?.stageUrl);
    this.stageSrc = partyInfo?.stageUrl || partyInfo?.stage?.downloadUrl;
  }

  get scalingFactor() {
    return htmlSafe(`--scaling-factor: ${this._scalingFactor}`);
  }

  @action
  scale({ contentRect: { width, height }, target }) {
    let lineHeight = Number.parseInt(getComputedStyle(target).lineHeight, 10);

    this.stageHeight = height * 0.85 - lineHeight * 2;

    if (!this.isAnimated) {
      return;
    }

    this._scalingFactor = width / 1200;
  }
}
