import { getOwner } from '@ember/application';
import { isPresent, typeOf } from '@ember/utils';
import { ROUTES_WITH_PACKED_QUERY_PARAMS } from '@mvb/tix-ui/constants';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Service, { service } from '@ember/service';

export default class QueryParamsService extends Service {
  @service router;

  // Returns the actual unpacked query params of the current route (without defaults).
  get currentQueryParams() {
    let { usePackedQueryParams } = this.currentRouteForPackedQueryParams;
    let route = this.router.currentRoute;
    let queryParams = route?.queryParams ?? {};

    return usePackedQueryParams ? queryParamsUnpack(queryParams) : queryParams;
  }

  // Returns the actual unpacked query params of the current route (including defaults).
  get currentQueryParamsWithDefaults() {
    let { route } = this.currentRouteForPackedQueryParams;
    route = route ? getOwner(this).lookup(`route:${route.name}`) : null;

    return {
      ...route?.searchService?.queryParamsDefaults,
      ...this.currentQueryParams,
    };
  }

  get currentRouteForPackedQueryParams() {
    let usePackedQueryParams = false;
    let route = this.router.currentRoute;

    while (isPresent(route) && !usePackedQueryParams) {
      usePackedQueryParams =
        ROUTES_WITH_PACKED_QUERY_PARAMS.includes(route.name) ||
        ROUTES_WITH_PACKED_QUERY_PARAMS.some((packedQpRouteName) => `${packedQpRouteName}_loading` === route.name);
      route = usePackedQueryParams ? route : route.parent;
    }

    return { route, usePackedQueryParams };
  }

  get hasActiveFilters() {
    let currentQueryParams = this.currentQueryParams;
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
    let queryParamsFilters = route?.searchService?.queryParamsFilters ?? [];

    for (let queryParamFilter of queryParamsFilters) {
      if (currentQueryParams[queryParamFilter]) {
        return true;
      }
    }

    return this.hasActiveAdditionalInformationFilters || this.hasActiveNotesFilters;
  }

  get hasActiveAdditionalInformationFilters() {
    let currentQueryParams = this.currentQueryParams;
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
    let queryParamsAdditionalInformation = route?.searchService?.queryParamsDefaultsForAdditionalInformation ?? {};

    for (let queryParamAdditionalInformation in queryParamsAdditionalInformation) {
      if (undefined !== currentQueryParams[queryParamAdditionalInformation]) {
        return true;
      }
    }

    return false;
  }

  get hasActiveNotesFilters() {
    let currentQueryParams = this.currentQueryParams;
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
    let queryParamsNotes = route?.searchService?.queryParamsDefaultsForNotes ?? {};

    for (let queryParamNotes in queryParamsNotes) {
      if (currentQueryParams[queryParamNotes]) {
        return true;
      }
    }

    return false;
  }

  // Returns the last used unpacked query params of the given route (without defaults).
  getQueryParamsOfRoute({ route }) {
    if (isPresent(route) && typeOf(route) === 'string') {
      route = getOwner(this).lookup(`route:${route}`);
    }

    return route?.searchService?.queryParams || {};
  }

  // Returns the last used unpacked user specific query params of the given route.
  getQueryParamsOfRouteUserSpecific({ route }) {
    if (isPresent(route) && typeOf(route) === 'string') {
      route = getOwner(this).lookup(`route:${route}`);
    }

    return route?.searchService?.queryParamsUserSpecific || {};
  }

  // Returns the last used unpacked query params of the given route (including defaults).
  getQueryParamsOfRouteWithDefaults({ route }) {
    if (isPresent(route) && typeOf(route) === 'string') {
      route = getOwner(this).lookup(`route:${route}`);
    }

    return {
      ...route?.searchService?.queryParamsDefaults,
      ...route?.searchService?.queryParams,
    };
  }
}
