import { action } from '@ember/object';
import { isEmpty, isNone } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { USER_GENDER, USER_ROLES, USER_TITLE, USER_TYPE } from '@mvb/tix-ui/constants';
import { validateLength } from 'ember-changeset-validations/validators/index';
import { waitFor } from '@ember/test-waiters';
import BlogInfoValidations from '@mvb/tix-ui/validations/blog-info';
import checkFieldValidity from '@mvb/tix-ui/utils/check-field-validity';
import Controller from '@ember/controller';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import createOptions from '@mvb/tix-ui/utils/create-options';
import ENV from '@mvb/tix-ui/config/environment';
import UserAddValidations from '@mvb/tix-ui/validations/user-add';
import UserValidations from '@mvb/tix-ui/validations/user';

export default class BackofficeUsersAddController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service notifications;
  @service router;
  @service store;

  @tracked showForm = false;
  @tracked showMvbId = false;
  @tracked emailDisabled = false;
  @tracked fieldsDisabled = false;
  @tracked permissionSetOptions = [];

  @tracked blogInfoChangeset;
  @tracked permissionSetChangeset;

  permissionSetValidations = {
    mvbId: [
      validateLength({
        min: ENV.APP.minMvbIdLength,
        max: ENV.APP.maxMvbIdLength,
        allowBlank: true,
        description: this.intl.t('validation.assignedParties.description'),
      }),
    ],
  };

  createBlogInfoChangeset() {
    this.blogInfoChangeset = createChangeset({ name: null, url: null }, BlogInfoValidations);
  }

  createPermissionSetChangeset() {
    this.permissionSetChangeset = createChangeset(
      { mvbId: null, party: null, permissionSet: null },
      this.permissionSetValidations
    );
  }

  constructor() {
    super(...arguments);

    this.createBlogInfoChangeset();
    this.createPermissionSetChangeset();
  }

  get currentValidations() {
    return { ...UserValidations, ...UserAddValidations };
  }

  get genderOptions() {
    return createOptions(this.intl, USER_GENDER, 'modelUser.text.gender');
  }

  get isDropdownDisabled() {
    return isEmpty(this.permissionSetOptions);
  }

  get typeOptions() {
    return createOptions(this.intl, USER_TYPE, 'modelUser.text.type');
  }

  get titleOptions() {
    return createOptions(this.intl, USER_TITLE, 'modelUser.text.title');
  }

  @action
  onCheckEmail(changeset) {
    return this.autofillFieldsTask.perform(changeset);
  }

  @task({ restartable: true })
  @waitFor
  *autofillFieldsTask(changeset) {
    let validationResult = yield changeset.validate('username');
    let usernameIsValid = checkFieldValidity(validationResult);
    if (!usernameIsValid) {
      this.showForm = false;
      return;
    }
    changeset.execute('username');
    changeset.rollback();
    this.fieldsDisabled = false;
    this.emailDisabled = true;
    this.showForm = true;
  }

  @action
  onEnableEmail() {
    this.emailDisabled = false;
    this.showForm = false;
  }

  @action
  onSelectType(changeset) {
    this.showMvbId = [USER_TYPE.BOOKSTORE, USER_TYPE.BOOK_GROUP, USER_TYPE.PUBLISHER, USER_TYPE.SALES_COOP].includes(
      changeset.type
    );
  }

  @action
  onSave(changeset, permissionSetChangeset, blogInfoChangeset, event) {
    event.preventDefault();

    if (!this.showForm) {
      return this.onCheckEmail(changeset);
    }

    this.saveTask.perform(changeset, permissionSetChangeset, blogInfoChangeset);
  }

  @task({ restartable: true })
  @waitFor
  *saveTask(changeset, permissionSetChangeset, blogInfoChangeset) {
    try {
      yield changeset.validate();

      if (changeset.type === USER_TYPE.BLOGGER) {
        yield blogInfoChangeset.validate();
        if (!blogInfoChangeset.isValid) {
          return false;
        }
      }

      if (changeset.isValid) {
        changeset.roles = [USER_ROLES.TIX_USER];
        let user = yield changeset.save();

        if (changeset.type === USER_TYPE.BLOGGER) {
          let blogInfoRecord = this.store.createRecord('blog-info', {
            name: blogInfoChangeset.get('name'),
            url: blogInfoChangeset.get('url'),
            user,
          });
          yield blogInfoRecord.save();
        }

        let permissionSet = permissionSetChangeset.get('permissionSet');
        let party = permissionSetChangeset.get('party');
        if (this.showMvbId && permissionSet && party) {
          let assignedParty = this.store.createRecord('assigned-party', {
            permissionSet,
            user,
            party,
          });
          yield assignedParty.save();
        }

        this.notifications.success(this.intl.t('backofficeUsersAdd.notification.success'));

        // reset all tracked properties
        this.showForm = false;
        this.showMvbId = false;
        this.emailDisabled = false;
        this.fieldsDisabled = false;

        // transition to overview
        this.router.transitionTo('backoffice.users');
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  onCancel() {
    // reset all tracked properties
    this.showForm = false;
    this.showMvbId = false;
    this.emailDisabled = false;
    this.fieldsDisabled = false;

    // transition to overview
    this.router.transitionTo('backoffice.users');
  }

  @action
  onSearchMvbId() {
    return this.onSetupPermissionSetsTask.perform(this.permissionSetChangeset);
  }

  @task({ restartable: true })
  @waitFor
  *onSetupPermissionSetsTask(permissionSetChangeset) {
    yield permissionSetChangeset.validate();
    if (!permissionSetChangeset.isValid) {
      return;
    }
    try {
      let mvbId = permissionSetChangeset.get('mvbId');

      let party = (yield this.store.query('party', { filter: { mvbId } }))?.[0];
      // show error if there is no party for that mvbId
      if (isNone(party)) {
        return this.permissionSetChangeset.pushErrors(
          'mvbId',
          this.intl.t('error.assignedParty.notFound', { id: mvbId })
        );
      }
      this.permissionSetChangeset.set('party', party);
      let permissionsSets = yield this.store.query('permission-set', { filter: { partyId: party.id } });
      // create options for permissions dropdown
      this.permissionSetOptions = permissionsSets?.map((permissionsSet) => {
        return {
          text: permissionsSet.label,
          value: permissionsSet,
        };
      });
    } catch (error) {
      return this.errors.handle(error);
    }
  }
}
