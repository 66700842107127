import { assert, runInDebug } from '@ember/debug';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateErpSetupOrderChannelsIdUniqueness(options = {}) {
  let erpSetup = options?.erpSetup;

  runInDebug(() => {
    assert('`erpSetup` must be passed in as an option', erpSetup);
  });

  return async (key, newValue /* oldValue, changes, content */) => {
    if (!applicationUtil.hasInstance) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');

    if (newValue?.length === 0) {
      return buildMessage(key, { type: 'present', newValue, context: options });
    }
    if (newValue?.length !== 2) {
      return buildMessage(key, { message: intlService.t('validation.party.erp.orderChannel.identifier.charLimit') });
    }
    let orderChannels = await erpSetup?.get('orderChannels');
    let existingIds = new Set(orderChannels?.map((orderChannel) => orderChannel.identifier));
    if (existingIds.has(newValue?.toUpperCase())) {
      return buildMessage(key, {
        message: intlService.t('validation.uniqueness.notUnique', {
          gender: 'female',
          value: options?.description ?? key,
        }),
      });
    }
    return true;
  };
}
