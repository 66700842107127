import { isEmpty, isPresent } from '@ember/utils';
import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import SearchSerializer from './search';

export default class SectionContentSerializer extends SearchSerializer {
  attrs = {
    anchorId: { serialize: false },
  };

  normalize() {
    let payload = super.normalize(...arguments);

    // deserialize HIGHLIGHTS section's SectionContent
    try {
      let parsedBody = JSON.parse(payload.data.attributes.body);
      let position = 0;
      let highlights = {};

      for (let [audience, code] of Object.entries(parsedBody)) {
        highlights[audience] = {
          code,
          position: code ? position++ : null,
        };
      }

      //section-content model has a property specially for this step
      payload.data.attributes.highlights = highlights;
    } catch {
      // This section-content does not belong to a HIGHLIGHTS section. No error handling needed.
    }

    // Adding a unique id for the section content (needed for the sortable module)
    payload.data.attributes.anchorId = `sc-${payload.data.id}`;

    return payload;
  }

  serialize(snapshot) {
    let payload = super.serialize(...arguments);
    let section = snapshot.belongsTo('section');
    let product = section?.belongsTo('product');

    // for productCollections we need to enhance the sectionContent with the markedForDeletion flag
    // in the section serializer we will then remove the sectionContent from the section if it is marked for deletion (markedForDeletion === true)
    if (product?.record.isProductCollection) {
      payload.data.attributes.markedForDeletion = snapshot.record.markedForDeletion;
    }

    // section-content.highlights is a property only known in the FE model and needs to be moved to the section-content's body for persisting
    if (section?.record.type === SECTION_TYPES.HIGHLIGHTS && typeof payload.data.attributes.highlights === 'object') {
      let body = {};
      for (let [audience, { code }] of Object.entries(payload.data.attributes.highlights)) {
        body[audience] = code;
      }
      delete payload.data.attributes.highlights;
      payload.data.attributes.body = JSON.stringify(body);
    }

    return payload;
  }

  serializeBelongsTo(snapshot, json, relationship) {
    let section = snapshot.belongsTo('section');
    let product = section?.belongsTo('product');
    let key = relationship.key;

    if (product && product?.record?.isProductCollection && key === 'file') {
      let fileSnapshot = snapshot.belongsTo(key);

      if (isPresent(fileSnapshot)) {
        let attributes = fileSnapshot.attributes();
        let createdAt = attributes.createdAt;

        attributes.id = fileSnapshot.id;
        if (isPresent(createdAt) && typeof createdAt === 'object') {
          attributes.createdAt = createdAt.toUTC().toISO();
        }

        json.attributes[key] = attributes;
      }
    } else {
      super.serializeBelongsTo(...arguments);
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    let section = snapshot.belongsTo('section');
    let product = section?.belongsTo('product');
    let key = relationship.key;

    if (product?.record?.isProductCollection && key === 'prices') {
      let priceSnapshots = snapshot.hasMany(key);
      let serializedPrices = [];

      if (!priceSnapshots) {
        return;
      }

      for (let priceSnapshot of priceSnapshots) {
        let serializedPrice = priceSnapshot.serialize({ includeId: true });
        let serializedPriceAttributes = serializedPrice.data.attributes;
        serializedPriceAttributes.id = priceSnapshot.id;

        //Actions are displayed as free, even though paid titles are included.
        //The reason for this is that the discount is delivered with null if nothing is entered.
        //We replace this null with 0 here and the price is displayed.
        if (section?.record.type === SECTION_TYPES.CONTAINED_PRICES && isEmpty(serializedPriceAttributes.discount)) {
          serializedPriceAttributes.discount = 0;
        }

        if (
          Object.keys(serializedPriceAttributes).length > 0 &&
          (isPresent(serializedPriceAttributes.value) || isPresent(serializedPriceAttributes.discount))
        ) {
          serializedPrices.push(serializedPriceAttributes);
        }
      }

      if (isPresent(serializedPrices)) {
        json.attributes[key] = serializedPrices;
      }

      return;
    } else {
      super.serializeHasMany(...arguments);
    }
  }
}
