import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class RegistrationRoute extends Route {
  @service api;
  @service features;
  @service router;
  @service store;

  beforeModel() {
    if (!this.features.isEnabled('registration')) {
      return this.router.replaceWith('index');
    }
  }

  async model() {
    let address = this.store.createRecord('address');
    let user = this.store.createRecord('user');
    let countries = await this.api.get('/i18n/countries');

    return { user, address, countries };
  }
}
