export default {
  "actions": "_actions_9xanf2",
  "button-edit": "_button-edit_9xanf2",
  "form": "_form_9xanf2",
  "form-saving": "_form-saving_9xanf2",
  "table": "_table_9xanf2",
  "table-container": "_table-container_9xanf2",
  "table-loading": "_table-loading_9xanf2",
  "table-wrapper": "_table-wrapper_9xanf2",
  "td": "_td_9xanf2",
  "delete": "_delete_9xanf2",
  "truncated": "_truncated_9xanf2"
};
