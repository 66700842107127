import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-section-keywords>\n  <Ui::Input::Tags::ToArray\n    @changeset={{this.changeset}}\n    @onChange={{this.onChange}}\n    @label={{t \"productsPromotionalPackagesEdit.label.keywords\"}}\n    @name=\"keywords\"\n    @path=\"keywords\"\n  />\n</div>", {"contents":"<div data-test-section-keywords>\n  <Ui::Input::Tags::ToArray\n    @changeset={{this.changeset}}\n    @onChange={{this.onChange}}\n    @label={{t \"productsPromotionalPackagesEdit.label.keywords\"}}\n    @name=\"keywords\"\n    @path=\"keywords\"\n  />\n</div>","moduleName":"@mvb/tix-ui/components/sections/edit/keywords/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/sections/edit/keywords/index.hbs"}});
import { action } from '@ember/object';
import { SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import MachineEditModeChildComponent from '@mvb/tix-ui/components/machine-edit-mode/child';

export default class SectionsEditKeywordsComponent extends MachineEditModeChildComponent {
  @service store;

  @tracked keywordsChangeset = null;

  // needed so the parent machine registers all the changes
  get changeset() {
    return this.keywordsChangeset;
  }

  constructor() {
    super(...arguments);
    this.initializeKeywords();
  }

  @action
  async onChange() {
    this.machine.send('UPDATE');
    this.machine.send(this.changeset.isDirty ? 'CHANGE' : 'REVERT');
    this.machine.send('RESOLVE');
  }

  async initializeKeywords() {
    let sectionContents = this.args.section.sortedContents;
    let keywordsArray = [];

    for (let keyword of sectionContents) {
      keywordsArray.push(keyword.body);
    }

    this.keywordsChangeset = createChangeset(
      { keywords: keywordsArray, type: SECTION_TYPES.KEYWORDS, section: this.args.section },
      {}
    );
  }

  willDestroy() {
    this.keywordsChangeset = null;

    if (this.store.isDestroyed || this.store.isDestroying) {
      return;
    }

    super.willDestroy(...arguments);
  }
}
