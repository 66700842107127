import config from '@mvb/tix-ui/config/environment';
import EmberRouter from '@ember/routing/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('backoffice', function () {
    this.route('admins', function () {
      this.route('add');
      this.route('detail', { path: ':admin_id' });
    });
    this.route('users', function () {
      this.route('add');
      this.route('detail', { path: ':user_id' });
    });
    this.route('imprints', function () {});
    this.route('send-mail', function () {});
    this.route('parties', function () {
      this.route('bookstore', { path: 'bookstore/:party_id' });
      this.route('publisher', { path: 'publisher/:party_id' });
    });
    this.route('exports', function () {});
    this.route('settings');
    this.route('agb');
  });
  this.route('blacklist');
  this.route('companies', function () {
    this.route('company', { path: ':party_id' }, function () {
      this.route('edit');
    });
  });
  this.route('login');
  this.route('order', function () {
    this.route('dispo-lists', function () {
      this.route('dispo-list', { path: ':dispo_list_id' }, function () {});
    });
    this.route('order-list', function () {
      this.route('finalize');
    });
    this.route('order-history', function () {
      this.route('order', { path: ':order_id' });
    });
    this.route('success', { path: 'success/:order_channel_type' });
  });
  this.route('party', function () {
    this.route('bookstore-branches');
    this.route('category-of-goods');
    this.route('central-list');
    this.route('collection-of-goods');
    this.route('customer-contacts', function () {
      this.route('user', { path: ':user_id' });
    });
    this.route('erp');
    this.route('groups', function () {
      this.route('group', { path: ':group_id' });
    });
    this.route('linked-parties');
    this.route('permissions');
    this.route('settings');
    this.route('webshop-link');
    this.route('title-upload');
    this.route('users', function () {
      this.route('user', { path: ':user_id' });
    });
    this.route('wkz');
    this.route('companies');
  });
  this.route('presentations', function () {
    this.route('preview', { path: 'preview/:presentation_preview_id' }, function () {
      this.route('cover');
      this.route('product', { path: ':presentation_product_id' });
    });
    this.route('dispo-list', { path: 'dispo-list/:presentation_dispolist_id' }, function () {
      this.route('product', { path: ':presentation_product_id' });
    });
    this.route('product-list', { path: 'product-list/:presentation_productlist_id' }, function () {
      this.route('product', { path: ':presentation_product_id' });
    });
  });
  this.route('previews', function () {
    this.route('add');
    this.route('preview', { path: ':preview_id' }, function () {
      this.route('edit');
    });
  });
  this.route('preview-lists', function () {
    this.route('preview-list', { path: ':list_id' }, function () {});
  });
  this.route('product-lists', function () {
    this.route('product-list', { path: ':list_id' }, function () {});
  });
  this.route('products', function () {
    this.route('product', { path: ':product_id' }, function () {
      this.route('edit');
    });
    this.route('promotional-package', function () {
      this.route('add');
    });
  });
  this.route('profile', function () {
    this.route('settings');
    this.route('user', function () {
      this.route('change-password');
      this.route('edit');
    });
  });
  this.route('registration-info');
  this.route('registration');
  this.route('reset-password');
  this.route('set-password');
  this.route('specials', function () {
    this.route('product', { path: ':product_id' }, function () {
      this.route('edit');
    });
    this.route('add');
  });
  this.route('user', function () {});
  this.route('my-products');
  this.route('not-found', { path: '/*path' });
  this.route('notes-overview', function () {});
});
