import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class extends Ability {
  @service user;

  get requestExcelWithOrderDataExport() {
    return (
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_DISPOLISTS) &&
      !this.user.hasPermission(PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT) &&
      !this.requestThaliaExport
    );
  }

  get requestThaliaExport() {
    return this.user.hasAnyPermission([
      PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
      PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
    ]);
  }
}
