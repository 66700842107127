import { service } from '@ember/service';
import Service from 'ember-can/services/abilities';

export class PermissionError extends Error {
  constructor(abilityString, transition) {
    super(abilityString);

    this.transition = transition;
  }
}

export default class AbilitiesService extends Service {
  @service errors;

  parse(string) {
    let [abilityName, propertyName] = string.split('.');
    return { abilityName, propertyName };
  }

  accessRoute(transition, abilityString) {
    if (this.cannot(abilityString)) {
      throw new PermissionError(abilityString, transition);
    }
  }
}
