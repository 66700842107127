import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.value}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{fn this.onFilterChange null}}\n    {{did-update this.onValueArgumentChange @value}}\n  >\n    <:content>\n      {{t \"searchFilterPreviewProductIsbn.text.active\" identifier=this.value}}\n    </:content>\n  </Search::Filter>\n{{/if}}", {"contents":"{{#if this.value}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{fn this.onFilterChange null}}\n    {{did-update this.onValueArgumentChange @value}}\n  >\n    <:content>\n      {{t \"searchFilterPreviewProductIsbn.text.active\" identifier=this.value}}\n    </:content>\n  </Search::Filter>\n{{/if}}","moduleName":"@mvb/tix-ui/components/search/filter/preview-product-isbn/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/preview-product-isbn/index.hbs"}});
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterPreviewProductIsbnComponent extends SearchFilterBaseComponent {
  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();
  }
}
