import { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import Model from './-base';

export default class MarketingRecommendationHugendubelModel extends Model {
  @attr('string') code;
  @attr('string') editedBy;
  @attr('date-time') editedAt;
  @attr('string') info;
  @attr('number') position;
  @attr('string') status;
  @attr('string') text;

  @belongsTo('custom-marketing-content', { async: true, inverse: 'hugMarketingRecommendations' })
  customMarketingContent;
  @belongsTo('marketing-recommendation', { async: true, inverse: null }) parentMarketingRecommendation;

  @tracked markedForDeletion = false;
}
