import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (or this.bic this.bisac this.ddc)}}\n  <h4 data-test-classifications>\n    {{t \"productAdvancedBib.label.classifications\"}}\n  </h4>\n\n  {{#if this.bic}}\n    <Layout::AsList data-test-bic @textPre={{t \"productAdvancedBib.text.bic\"}} @list={{this.bic}} as |bic|>\n      {{bic.code~}}\n    </Layout::AsList>\n  {{/if}}\n\n  {{#if this.bisac}}\n    <Layout::AsList data-test-bisac @textPre={{t \"productAdvancedBib.text.bisac\"}} @list={{this.bisac}} as |bisac|>\n      {{bisac.code~}}\n    </Layout::AsList>\n  {{/if}}\n\n  {{#if this.ddc}}\n    <Layout::AsList data-test-ddc @textPre={{t \"productAdvancedBib.text.ddc\"}} @list={{this.ddc}} as |ddc|>\n      {{cl \"309\" ddc.code~}}\n    </Layout::AsList>\n  {{/if}}\n\n  {{yield}}\n{{/if}}", {"contents":"{{#if (or this.bic this.bisac this.ddc)}}\n  <h4 data-test-classifications>\n    {{t \"productAdvancedBib.label.classifications\"}}\n  </h4>\n\n  {{#if this.bic}}\n    <Layout::AsList data-test-bic @textPre={{t \"productAdvancedBib.text.bic\"}} @list={{this.bic}} as |bic|>\n      {{bic.code~}}\n    </Layout::AsList>\n  {{/if}}\n\n  {{#if this.bisac}}\n    <Layout::AsList data-test-bisac @textPre={{t \"productAdvancedBib.text.bisac\"}} @list={{this.bisac}} as |bisac|>\n      {{bisac.code~}}\n    </Layout::AsList>\n  {{/if}}\n\n  {{#if this.ddc}}\n    <Layout::AsList data-test-ddc @textPre={{t \"productAdvancedBib.text.ddc\"}} @list={{this.ddc}} as |ddc|>\n      {{cl \"309\" ddc.code~}}\n    </Layout::AsList>\n  {{/if}}\n\n  {{yield}}\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/advanced-bib/classifications.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/advanced-bib/classifications.hbs"}});
import Component from '@glimmer/component';

import { CLASSIFICATION_TYPES } from '@mvb/tix-ui/constants';

export default class ProductAdvancedBibClassificationsComponent extends Component {
  get bic() {
    return this.args.product?.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.BIC) ?? [];
  }

  get bisac() {
    return this.args.product?.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.BISAC) ?? [];
  }

  get ddc() {
    return this.args.product?.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.DDC) ?? [];
  }
}
