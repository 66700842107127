import { all } from 'ember-concurrency';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

/**
 * When changing the url of this route, remember to adapt the link that is generated for preview-emails in the BE!
 */
export default class PresentationsRoute extends Route {
  @service branches;
  @service abilities;
  @service codelists;
  @service collectionOfGoods;
  @service dispoLists;

  async beforeModel() {
    if (this.abilities.can('dispoList.viewDispoListsInProductPages')) {
      await all([this.dispoLists.load(), this.branches.load(), this.collectionOfGoods.load()]);
    }
  }
}
