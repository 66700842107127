import { action } from '@ember/object';
import { Changeset } from 'ember-changeset';
import { DateTime } from 'luxon';
import { isChangeset } from 'validated-changeset';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { URL_USERS_EXPORT, USER_ROLES, USER_STATUS } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import saveBlob from '@mvb/tix-ui/utils/save-blob';

// should be extracted to constants if used elsewhere
const UserStatusActive = [USER_STATUS.CONFIRMED];
const UserStatusInactive = [USER_STATUS.INVITED, USER_STATUS.DISABLED, USER_STATUS.PENDING];

export const UserStatusDropdown = {
  BOTH: 'BOTH',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const UserTypes = new Set(['publisher', 'sales_coop', 'bookstore', 'book_group', 'journalist', 'blogger', 'reader']);

/**
 * Copies the given attribute from the given source to the given target object. This will only happen if the trimmed value of the attribute is not empty.
 * @param sourceObject The source object
 * @param attribute The attribute to copy
 * @param targetObject The target object
 * @returns {boolean} If the source object contained any non-empty string for the attribute. This does not reflect the outcome of the copy operation! E.g., if the string only has spaces, this method will return true, but still not copy the attribute, since the trimmed version of the string is empty.
 */
function copyTextParameter(sourceObject, attribute, targetObject) {
  let value = sourceObject[attribute];
  if (!value) {
    return false;
  }
  let trimmedValue = value.trim();
  if (trimmedValue !== '') {
    targetObject[attribute] = trimmedValue;
  }
  return true;
}

/**
 * Applies the given user frontend state filter to the given list of user backend state filters.
 * @param frontendFilter The user state as displayed in the frontend ({@link UserStatusDropdown})
 * @param backendFilters The backend filters as defined in {@link UserStatusActive} and {@link UserStatusInactive}
 * @return If the frontend filter deviates from the default or not
 */
function applyUserState(frontendFilter, backendFilters) {
  if (frontendFilter === UserStatusDropdown.ACTIVE) {
    addAll(backendFilters, UserStatusActive);
    return true;
  } else if (frontendFilter === UserStatusDropdown.INACTIVE) {
    addAll(backendFilters, UserStatusInactive);
    return true;
  } else {
    // Both (no additional filters needed!)
    return false;
  }
}

/**
 * Adds all elements of the given array to the given set.
 * @param targetSet The set to add the elements to
 * @param sourceArray The array containing the elements to add
 */
function addAll(targetSet, sourceArray) {
  for (let element of sourceArray) {
    targetSet.add(element);
  }
}

export default class BackOfficeUsersIndexController extends Controller {
  @service api;
  @service intl;
  @service store;

  UserStatusDropdown = UserStatusDropdown;

  searchModel = new Changeset({
    searchTerm: '',
    status: UserStatusDropdown.BOTH,
    mvbId: '',
    firstname: '',
    lastname: '',
    username: '',
    roles: '',
    expiration: '',

    publisher: false,
    sales_coop: false,
    bookstore: false,
    book_group: false,
    journalist: false,
    blogger: false,
    reader: false,

    pending: false,
    invited: false,
  });

  queryParams = ['page', 'sort'];

  @tracked page = 1;
  @tracked showFilters = false;
  @tracked sort = '';
  @tracked users;

  // needs to be a getter as controller can might be initialized before locale is set, rendering always happens after
  get statusOptions() {
    return [
      {
        value: UserStatusDropdown.BOTH,
        text: this.intl.t('backofficeUsersIndex.search.status.all'),
      },
      {
        value: UserStatusDropdown.ACTIVE,
        text: this.intl.t('backofficeUsersIndex.search.status.active'),
      },
      {
        value: UserStatusDropdown.INACTIVE,
        text: this.intl.t('backofficeUsersIndex.search.status.inactive'),
      },
    ];
  }

  /**
   * Analyzes the given search parameters. On the one hand, those parameters are filtered and transformed to prepare for sending them to the backend. On the other hand, it is determined if there are any values in the additional filters deviating from the default values.
   * @param model The filter model to analyze
   * @returns {{filter: {}, additionalFilters: boolean}} The transformed filter parameters and a boolean describing if any of the additional filters deviate from default values
   */
  analyzeSearchParams(model) {
    let _model = isChangeset(model) ? model.pendingData : model;

    let filterParams = {};
    let filterUserStates = new Set();
    let filterUserTypes = new Set();
    let additionalFilters = false;

    // Apply search term parameter
    copyTextParameter(_model, 'searchTerm', filterParams);

    // Copy additional text parameters
    for (let attribute of ['mvbId', 'firstname', 'lastname', 'username', 'expiration']) {
      if (copyTextParameter(_model, attribute, filterParams)) {
        additionalFilters = true;
      }
    }

    // Apply status parameter
    if (applyUserState(_model.status, filterUserStates)) {
      additionalFilters = true;
    }

    // Apply pending parameter
    if (_model.pending) {
      filterUserStates.add(USER_STATUS.PENDING);
      additionalFilters = true;
    }

    // Apply invited parameter
    if (_model.invited) {
      filterUserStates.add(USER_STATUS.INVITED);
      additionalFilters = true;
    }

    // Copy user types
    for (let userType of UserTypes) {
      if (_model[userType]) {
        filterUserTypes.add(userType.toUpperCase());
        additionalFilters = true;
      }
    }

    if (filterUserStates.size > 0) {
      filterParams.status = [...filterUserStates].join(',');
    }

    if (filterUserTypes.size > 0) {
      filterParams.type = [...filterUserTypes].join(',');
    }

    filterParams.roles = [USER_ROLES.TIX_USER].join(',');

    return {
      filter: filterParams,
      additionalFilters,
    };
  }

  @task({ drop: true })
  @waitFor
  *searchTask(params, showFilters) {
    let query = {
      filter: params,
      page: {
        number: this.page,
      },
      include: 'assignedParties',
    };

    if (this.sort) {
      query.sort = this.sort;
    }

    this.users = yield this.store.query('user', query);

    this.showFilters = showFilters;
  }

  @action
  search(event) {
    event.preventDefault();
    this.page = 1;
    this.update();
  }

  /**
   * Updates this controller based on the currently set filters. The users will be fetched and, depending on if any filter deviates from the default, the filters will be shown or hidden.
   */
  @action
  update() {
    let result = this.analyzeSearchParams(this.searchModel);
    this.loadData(result.filter, result.additionalFilters);
  }

  @action
  loadData(searchParams, showFilters) {
    this.searchTask.perform(searchParams, showFilters);
  }

  @action
  clearFilters() {
    this.searchModel.rollback();
    this.loadData(
      {
        roles: [USER_ROLES.TIX_USER].join(','),
      },
      false
    );
  }

  @action
  async exportUsers() {
    let currentDate = DateTime.local().toISODate();
    let filename = this.intl.t('backofficeUsersIndex.text.filename', { date: currentDate });
    let { blob } = await this.api.get(URL_USERS_EXPORT);

    saveBlob(blob, filename);
  }
}
