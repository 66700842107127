import { validateConfirmation, validateFormat } from 'ember-changeset-validations/validators/index';

// Regex taken from https://riptutorial.com/de/regex/example/18996/ein-passwort--das-mindestens-1-gro-buchstabe--1-kleinbuchstabe--1-ziffer--1-sonderzeichen-enthalt-und-eine-lange-von-mindestens-10-hat
const REGEX_PW = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)/;

export default {
  password: validateFormat({
    regex: REGEX_PW,
    message: 'intl:validation.password.formatSpecific',
  }),
  passwordConfirmed: validateConfirmation({
    on: 'password',
    message: 'intl:validation.password.confirm',
  }),
};
