import { attr, belongsTo } from '@ember-data/model';
import Model from './-base';

export default class SupportingResourceModel extends Model {
  @attr('string') assetFileId;
  @attr('number') imageHeight;
  @attr('number') imageWidth;
  @attr('string') caption;
  @attr('string') contributorPropId;
  @attr('string') copyright;
  @attr('string') copyrightHolder;
  @attr('string') credit;
  @attr('string') exportedLink;
  @attr('string') fileFormat;
  @attr('string') resourceContentType;
  @attr('string') resourceForm;
  @attr('string') resourceMode;
  @attr('number') sequence;
  @attr('string') thumbnailUrl;
  @attr() contentAudience;
  @attr() backgroundColor;

  @belongsTo('product', { async: false, inverse: 'supportingResources' }) product;
}
