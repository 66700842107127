import { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import Model from './-base';

export default class PriceModel extends Model {
  @attr('number') discount;
  @attr('string') type;
  @attr() currency;
  @attr() country;
  @attr('number') value;
  @attr('boolean', { defaultValue: true }) visible;

  @belongsTo('section-content', { async: false, inverse: 'prices' }) sectionContent;

  // needed for section edit component
  @tracked valueDiscount = null;
  @tracked valueGross = null;
}
