import { all, didCancel, task } from 'ember-concurrency';
import { isEmpty, isPresent } from '@ember/utils';
import { waitFor } from '@ember/test-waiters';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import Service, { service } from '@ember/service';
import validateErpBranchSetup from '@mvb/tix-ui/validations/erp-branch-setup';
import validateErpSetup from '@mvb/tix-ui/validations/erp-setup';

export default class ErpService extends Service {
  @service api;
  @service branches;
  @service errors;
  @service store;
  @service user;

  get current() {
    return this.loadTask.lastSuccessful?.value ?? [];
  }

  get currentDetails() {
    return this.loadErpDetailsTask.lastSuccessful?.value ?? [];
  }

  get areCurrentBranchSetupsInvalid() {
    return this.checkInvalidErpBranchSetupsTask.lastSuccessful?.value ?? [];
  }

  @task({ drop: true })
  @waitFor
  *loadTask() {
    return yield this.store.findAll('erp');
  }

  @task({ drop: true })
  @waitFor
  *loadErpDetailsTask() {
    try {
      let partySettings = yield this.store.findRecord('party-setting', this.user.selectedParty.party.id, {
        include: 'erpSetup.erpBranchSetups.branch,erpSetup.orderChannels,erpSetup.erp',
      });
      let erpSetup = partySettings?.erpSetup;
      let erp = erpSetup?.erp;
      let isErpSetupValid;
      let isErpPresent = isPresent(erp);

      if (isErpPresent) {
        let users = yield this.api.get(`/party/${this.user.selectedParty.party.id}/users`);
        let userOptions = [];
        for (let user of users) {
          userOptions.push({ value: user.toLowerCase(), text: user });
        }
        let erpSetupChangeset = createChangeset(erpSetup, validateErpSetup(erpSetup, userOptions));
        yield erpSetupChangeset.validate();
        isErpSetupValid = erpSetupChangeset.isValid;
      } else {
        isErpSetupValid = true;
      }

      return { erpSetup, isErpSetupValid, isErpPresent };
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *checkInvalidErpBranchSetupsTask(erpSetup) {
    yield this.branches.load();
    let branches = this.branches.current;

    if (isEmpty(branches)) {
      // bail out early when user has no branches
      return false;
    }

    let branchSetups = (yield erpSetup?.get('erpBranchSetups')) ?? [];

    for (let branch of branches) {
      let branchSetup = branchSetups.find((bs) => {
        return bs.branch.get('id') === branch.id;
      });

      if (!isPresent(branchSetup)) {
        // bail out early when any branch is missing an erpBranchSetup
        return true;
      }
    }

    let branchSetupChangesets = branchSetups.map((branchSetup) => {
      return createChangeset(branchSetup, validateErpBranchSetup(this));
    });

    yield all(branchSetupChangesets.map((branchSetup) => branchSetup.validate()));

    return !branchSetupChangesets.every((branchSetup) => branchSetup.isValid);
  }

  async load() {
    if (this.loadTask.last?.isRunning) {
      return this.loadTask.last;
    }
    try {
      return await this.loadTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  async loadDetails() {
    if (this.loadErpDetailsTask.last?.isRunning) {
      return this.loadErpDetailsTask.last;
    }
    try {
      return await this.loadErpDetailsTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  async loadInvalidErpBranchSetups() {
    if (this.checkInvalidErpBranchSetupsTask.last?.isRunning) {
      return this.checkInvalidErpBranchSetupsTask.last;
    }
    try {
      return await this.checkInvalidErpBranchSetupsTask.perform(...arguments);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }
}
