import { didCancel, task } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import Service, { service } from '@ember/service';
import validationsGroup from '@mvb/tix-ui/validations/group';

export default class GroupsService extends Service {
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service store;

  @task({ restartable: true })
  *saveGroupTask({ changeset, mode, record, searchAction }) {
    try {
      yield changeset.save();
      this.notifications.success(this.intl.t('partyGroups.notification.saveSuccess'));
      // needed to reload the results so the new entry will be shown directly
      searchAction?.();
    } catch (error) {
      if (!didCancel(error)) {
        if (error.errors?.[0]?.code === 'error.group.nameAlreadyUsed') {
          changeset.pushErrors('name', this.intl.t('error.group.nameAlreadyUsed'));
          yield this.openModalAndManageGroupResults({ changeset, mode, record, searchAction });
        } else {
          this.errors.handle(error);
        }
      }
    }
  }

  async openModalAndManageGroupResults({ changeset, mode, record, searchAction }) {
    if (isEmpty(changeset)) {
      changeset = createChangeset(record, validationsGroup);
    }

    let title = mode === 'add' ? this.intl.t('partyGroups.modal.titleAdd') : this.intl.t('partyGroups.modal.titleEdit');
    let selectedParty = record.party;

    let result = await this.modals.prompt({
      title,
      intro: htmlSafe(this.intl.t('partyGroups.modal.intro', { party: selectedParty.get('name') })),
      outro: this.intl.t('partyGroups.modal.outro'),
      confirm: this.intl.t('partyGroups.modal.confirm'),
      label: this.intl.t('partyGroups.modal.label'),
      changeset,
      changesetPath: 'name',
      clear: true,
    });

    // validate again after modal has been closed (just to be sure…)
    await changeset.validate();

    // if the user cancels the modal, we don't want to save the data
    if (result === false || !changeset.isValid) {
      if (changeset.isNew) {
        this.store.unloadRecord(record);
      } else {
        record.rollbackAttributes();
      }
      changeset.rollback();
      return;
    }

    // no awaiting here, as we would otherwise get task cancellation errors
    this.saveGroupTask.perform({ changeset, mode, record, searchAction });
  }
}
