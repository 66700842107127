import { PRODUCT_COMPOSITION_TYPES, PRODUCT_FORM_TYPES, PUBLISHING_STATUS, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class SpecialsAddRoute extends Route {
  @service('section-contents-special') sectionContents;
  @service session;
  @service store;
  @service user;

  beforeModel(transition) {
    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }

  model() {
    let product = this.store.createRecord('product', {
      form: {
        typeO3: PRODUCT_FORM_TYPES.PACKAGE,
      },
      productComposition: PRODUCT_COMPOSITION_TYPES.SPECIAL,
      publishingStatus: PUBLISHING_STATUS.INACTIVE,
    });

    // for the basic infos we need the productInfo section with the data from the current user
    let productInfo = this.store.createRecord('product-info', {
      createdBy: this.user.current?.fullName,
    });

    this.store.createRecord('section', {
      type: SECTION_TYPES.INFO,
      position: 1,
      product,
      productInfo,
    });

    return product;
  }

  setupController(_, model) {
    super.setupController(...arguments);

    this.sectionContents.setupController(model);
  }

  resetController() {
    this.sectionContents.resetController(...arguments);
  }

  activate() {
    super.activate();
    this.sectionContents.activate(...arguments);
  }

  deactivate() {
    super.deactivate();
    this.sectionContents.deactivate(...arguments);
  }
}
